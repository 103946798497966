import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
// Компоненты клиента
import ClientsPage from '../../../../../views/clients';
import ClientsAddPage from '../../../../../views/clients-add';
import ClientsEditPage from '../../../../../views/clients-edit';
// Форма клиента
import RepairFormClient from '../clients-form';
// Форма закза
import RepairForm from '../forma';

interface Layout {
  id;
}
@Component({
  template: require('./index.html'),
  components: {
    'client-list': ClientsPage,
    'client-edit': ClientsEditPage,
    'client-create': ClientsAddPage,
    'repair-form': RepairForm,
    'repair-form-client': RepairFormClient,
  },
})

export default class RepairFormTemplate extends Vue {
    @Prop({ required: true }) layout!: object[];

    @Prop({ required: true }) repair!: object;

    @Prop({ required: true }) client!: object;

    @Prop({ required: true }) clientCheckBlackList!: object[];

    @Prop({ required: true }) errorRepairForm!: object;

    @Prop() cloneRepair!: object;

    // Модалки клиента
    open_modal_client_list = false;

    open_modal_edit_client = false;

    open_modal_create_client = false;

    get templateForm() {
      return this.layout.length !== 0
        ? this.layout.map(
          (column) => (column as Layout[]).filter((item) => item.id !== 15 && item.id !== 16),
        )
        : [];
    }

    rowClickClientList(client) {
      this.open_modal_client_list = false;
      this.$emit('sendClientCheckBlacklist', { phone: client.phone });
      this.$emit('updateClient', client);
    }

    refreshViewModalClients() {
      this.open_modal_client_list = false;
      this.open_modal_edit_client = false;
      this.open_modal_create_client = false;
    }

    @Watch('$route')
    watchRoute() {
      this.refreshViewModalClients();
    }
}
