import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  SellComment,
  SellPayment,
  SellWorkRevert,
  SellPartRevert,
  SellDiscount,
  Breadcrumb,
  User,
  Cashbox,
  Document,
  Sell,
  Client,
} from '@/types';
import BaseProvider from '../../api/base-provider';
import CashProvider from '../../api/providers/cash';
import ClientsProvider, { ClientUniversalSearch } from '../../api/providers/clients';
import DocumentProvider from '../../api/providers/document';
import SellProvider, { SellAddForm } from '../../api/providers/sell';
import StorageProvider from '../../api/providers/storage';
import StuffProvider from '../../api/providers/stuff';
import ErrorAccessRights from '../../components/error-access-rights';
import ExpressAddProducts from '../../components/express-add-products';
import CancelForm from '../../components/forms/cancel';
import ViewDate from '../../components/forms/date';
import Numbers from '../../components/forms/numbers';
import ViewUserName from '../../components/forms/user-name';
import ModalAddPart from '../../components/modal-add-part';
import ClientsAddPage from '../clients-add';

interface DataItem {
  date;
}
interface NewForm {
  client_id;
  seller_id;
  parts;
  works;
  payments;
  discounts;
  id?;
  amount?;
  name?;
}

interface Pay {
  sell_id;
  sum;
  cash_id;
  user_id;
  cashbox_id;
}

interface ExpressForm {
  name;
  price;
  posting_price;
  amount;
  category_id;
  storage_id;
  seller_id;
}

interface DiscountType {
  value;
  id;
}

interface Discount {
  type_id;
  name;
  sell_id;
  amount;
  user_id;
}
interface RevertPart {
  reason;
  amount;
  sell_part_id?;
  sell_work_id?;
  work_id?;
  part_id?;
}

interface SellerForm {
  id?;
}

interface SerialForm {
  name?;
  serial?;
}

@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    date: ViewDate,
    'user-name': ViewUserName,
    'modal-add-part': ModalAddPart,
    'cancel-form': CancelForm,
    'client-add': ClientsAddPage,
    'error-access-rights': ErrorAccessRights,
    'express-add-products': ExpressAddProducts,
  },
})
export class SellAddPage extends Vue {
    @Getter user;

    @Getter settings;

    @Getter clientPersonValid;

    @Getter clientCompanyValid;

    @Action addBreadcrumb;

    @Action addActionBtn;

    @Getter balance;

    @Prop() clientProp!: User;

    get mastersWithDeleted() {
      const masters = this.stuff_list;
      if (this.sell.seller
        && this.sell) {
        const foundedUser = masters.find(
          (employee) => employee.id === ((this.sell as Sell).seller as User).id,
        );
        if (foundedUser === undefined) {
          masters.push(this.sell.seller);
        }
      }

      return masters;
    }

    get sortedStuff() {
      return this.mastersWithDeleted.map((x) => x).sort((a, b) => {
        if (a.last_name && b.last_name) {
          if (a.last_name > b.last_name) {
            return 1;
          } if (a.last_name < b.last_name) {
            return -1;
          }
        }
        if (a.first_name && b.first_name) {
          if (a.first_name > b.first_name) {
            return 1;
          } if (a.first_name < b.first_name) {
            return -1;
          }
        }

        if (a.middle_name && b.middle_name) {
          if (a.middle_name > b.middle_name) {
            return 1;
          } if (a.middle_name < b.middle_name) {
            return -1;
          }
        }

        return 0;
      });
    }

    get sortedCashList() {
      return this.cash_list.map((x) => x).sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
    }

    get sellDataList() {
      const data: (SellPartRevert| SellWorkRevert)[] = [...this.sell_part_list,
        ...this.sell_work_list];
      return data.sort(
        (a, b) => ((a as unknown as DataItem).date > (b as unknown as DataItem).date ? 1 : -1),
      );
    }

    get isFastInsertAllowed() {
      return this.settings.allow_part_auto_create || this.settings.allow_work_auto_create;
    }

    token: string | null = null;

    printer: Document[] = [];

    print_value: number | null = null;

    loading = true;

    revert_reason = false;

    revert_part: RevertPart = {
      reason: '',
      amount: null,
    };

    active_send = false;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Магазин', link: '/sell' }, { id: 2, section: '', link: null }];

    new_form: NewForm = {
      client_id: null,
      seller_id: null,
      parts: [],
      works: [],
      payments: [],
      discounts: [],
    };

  data: (SellPartRevert | SellWorkRevert | Sell)[] = [];

    pay: Pay = {
      sell_id: null,
      sum: 0,
      cash_id: 1,
      user_id: null,
      cashbox_id: null,
    };

    validateSum = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error('Значение «Сумма» должно быть не меньше 0.'));
      } else {
        callback();
      }
    }

    validateDiscounts = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error('Значение «Скидка» должно быть не меньше 0.'));
      } else {
        callback();
      }
    }

    rules_pay: object = {
      sum: [
        { validator: this.validateSum, trigger: 'change', type: 'number' },
        { required: true, message: 'Необходимо заполнить поле «Сумма оплаты»' },
      ],
      cashbox_id: { required: true, message: 'Необходимо заполнить поле «Выберите кассу»' },
    };

    discount: Discount = {
      type_id: null,
      name: '',
      sell_id: null,
      amount: null,
      user_id: null,
    };

    discount_type: DiscountType[] = [
      { value: '%', id: 1 },
      { value: 'сумма', id: 2 },
    ];

    serial_form: SerialForm = {};

    seller_form: SellerForm = {};

    flags_serial_modal = false;

    flags_seller_modal = false;

    title_page = '';

    modal_active = false;

    count_cash = 1;

    part_list: object[] = [];

    work_list: object[] = [];

    seller_id: number | null = null;

    client_id: number | null = null;

    loading_page = false;

    open_modal_client_add = false;

    isPopoverSurrender = false;

    loading_clients = false;

    documents_sold_goods: object[] = [];

    documents_sold_works: object[] = [];

    page_document_list = 2;

    stuff_list: User[] = [];

    storage_list: object[] = [];

    client: Client[] = [];

    cash_list: Cashbox[] = [];

    sell: Sell = {};

    sell_part_list: SellPartRevert[] = [];

    sell_work_list: SellWorkRevert[] = [];

    sell_payment_list: SellPayment[] = [];

    sell_discount_list: SellDiscount[] = [];

    sell_part_revert_list: object[] = [];

    sell_work_revert_list: object[] = [];

    // Флаг загрузки удаленных комментариев
    includeDeletedComments = false;

    sell_comments: SellComment[] = [];

    sell_comment_form: SellCommentForm = { content: '' };

    sell_comment_form_rules = {
      content: [
        {
          required: true, message: 'Обязательно для заполнения', whitespace: true, trigger: 'change',
        },
      ],
    };

    loading_add_discount = false;

    loading_add_payments = false;

    loading_sell_revert = false;

    loading_sell_apply = false;

    loading_all_product = false;

    view_modal_express_form = false;

    express_form: ExpressForm = {
      name: '',
      price: null,
      posting_price: null,
      amount: 1,
      category_id: 1,
      storage_id: null,
      seller_id: null,
    };

    /**
  * Может удалять чужие комментарии
  * @returns boolean
  */
    get canDeleteForeignComments(): boolean {
      return this.user?.rights?.can_delete_foreign_comments
        ? this.user?.rights?.can_delete_foreign_comments : false;
    }

    /**
    * Может удалять свои комментарии
    * @returns boolean
    */
    get canDeleteSelfComments(): boolean {
      return this.user?.rights?.can_delete_self_comments
        ? this.user?.rights?.can_delete_self_comments : false;
    }

    // api

    // sell-comments

    submitSellCommentForm() {
      (this.$refs.commentForm as Form).validate((valid) => {
        if (valid) {
          this.sendSellComment();
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    }

    canDeleteComment(comment) {
      if (comment.is_deleted) {
        return false;
      }
      if (comment.user_id === this.user.id) {
        return this.canDeleteSelfComments;
      }
      if (this.canDeleteForeignComments) {
        return true;
      }
      return false;
    }

    deleteComment(comment) {
      SellProvider.deleteSellComment(comment.id)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Комментарий удален' });
          this.getAllComments()
            .then((resp) => {
              this.sell_comments = resp;
            });
        })
        .catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось удалить комментарий' });
          throw new Error(err);
        });
    }

    toogleFlagIncludeDeletedComments() {
      this.getAllComments()
        .then((resp) => {
          this.sell_comments = resp;
        });
    }

    async sendSellComment() {
      const form = {
        sell_id: this.idSell,
        content: this.sell_comment_form.content,
      } as SellAddForm;
      try {
        const result = await SellProvider.sendSellComment(form);
        this.sell_comments = [(result.data as SellComment), ...this.sell_comments];
        this.sell_comment_form.content = '';
        (this.$refs.commentForm as Form).resetFields();
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Комментарий успешно сохранен', item: '' });
      } catch (e) {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка сохранения комментария', item: '' });
      }
    }

    async getAllComments(page = 1) {
      const pack = await SellProvider.getSellComments(
        { sell_id: this.idSell, include_deleted: this.includeDeletedComments },
      );
      let result = pack.data;
      if (page < pack.pageCount) {
        const nextPack = await this.getAllComments(page + 1);
        result = result.concat(nextPack);
      }
      return result;
    }

    // sell-payments

    deleteSellPayments(id) {
      SellProvider.deleteSellPayments(id)
        .then((res) => {
          const index = this.sell_payment_list.findIndex((element) => element.id === res.data.id);
          this.sell_payment_list.splice(index, 1);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Оплата успешно удалена', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Удалить оплату не удалось', item: '' });
        });
    }

    sendSellPayment(id) {
      this.loading_add_payments = true;
      SellProvider.sendSellPayment(id)
        .then((res) => {
          this.sell_payment_list.push(res.data);
          const total = Number(this.totalSum);
          if (res.data.sum >= 0) {
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Оплата успешно добавлена', item: '' });
            if (total < 0) {
              this.isPopoverSurrender = true;
            } else {
              this.isPopoverSurrender = false;
            }
          } else {
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Деньги успешно возвращены', item: '' });
            if (total >= 0) {
              this.isPopoverSurrender = false;
            }
          }
          this.loading_add_payments = false;
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка оплаты', item: '' });
          this.loading_add_payments = false;
        });
    }

    // sell-discount

    sendDiscount(item) {
      this.loading_add_discount = true;
      SellProvider.sendDiscount(item)
        .then((res) => {
          this.sell_discount_list.push(res.data);
          this.loading_add_discount = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Скидка успешна добавлена', item: '' });

          this.resetFormDiscount();
        })
        .catch((err) => {
          console.error(err);
          this.loading_add_discount = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    deleteSellDiscount(id) {
      SellProvider.deleteSellDiscount(id)
        .then((res) => {
          const index = this.sell_discount_list.findIndex((element) => element.id === res.data.id);
          this.sell_discount_list.splice(index, 1);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Скидка успешно удалена', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Удалить оплату не удалось', item: '' });
        });
    }

    // sell

    sendSell(item) {
      SellProvider.sendSell(item)
        .then((res) => {
          this.updateSell(res.data);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Создана продажа №${res.data.id}`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    sendSellCancel(item) {
      SellProvider.sendSellCancel(item)
        .then((res) => {
          const dataUpdate = {
            id: res.data.id,
            data: {
              seller_id: res.data.seller_id,
              client_id: res.data.client_id,
            },
          };

          this.loading_page = true;
          this.changeSell(dataUpdate);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Продажа №${res.data.id} готова для редактирования`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Отмненить продажу не возможно', item: '' });
        });
    }

    sendSellRevert(item) {
      this.loading_sell_revert = true;
      SellProvider.sendSellRevert(item)
        .then((res) => {
          this.updateSell(res.data);
          this.revert_reason = false;
          this.loading_sell_revert = false;
          this.clearRevert();
          this.resetFormRevert();
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Совершен возврат продажи №${res.data.id}`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.loading_sell_revert = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Вернуть продажу не возможно', item: '' });
        });
    }

    sendSellApply(id) {
      this.loading_sell_apply = true;
      SellProvider.sendSellApply(id)
        .then((res) => {
          this.updateSell(res.data);
          this.loading_sell_apply = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Продажа №${id} успешно проведена`, item: '' });
        })
        .catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: err.response.data.sell_id[0], item: '' });
          this.loading_sell_apply = false;
          this.loading_page = false;
        });
    }

    changeSell(item) {
      SellProvider.changeSell(item.id, item.data)
        .then((res) => {
          this.updateSell(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Продажа обновлена', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка обновления', item: '' });
        });
    }

    deleteSell(id) {
      SellProvider.deleteSell(id)
        .then(() => {
          this.$router.push({ path: '/sell' });
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Продажа №${id} удалена`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Продажа не найдена или проведена', item: '' });
        });
    }

    // sell-works

    sendSellWork(item) {
      SellProvider.sendSellWork(item)
        .then((res) => {
          this.sell_work_list.push(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Услуга «${res.data.work.name}» добавлена в продажу`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    changSellWork(item) {
      this.loading_all_product = true;
      SellProvider.changSellWork(item.id, item)
        .then((res) => {
          this.loading_all_product = false;
          const index = this.sell_work_list.findIndex((i) => i.id === res.data.id);
          (this.sell_work_list[index] as object) = this.transformItem(item, 'work');

          this.flags_seller_modal = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Услуга обновлена', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.loading_all_product = false;
          this.flags_seller_modal = false;
        });
    }

    sendSellWorkRevert(item) {
      this.loading_sell_revert = true;
      SellProvider.sendSellWorkRevert(item)
        .then((res) => {
          const indexWork = this.sell_work_list.findIndex((i) => i.work.id === res.data.work_id);
          if (indexWork >= 0) this.sell_work_list.splice(indexWork, 1);

          this.sell_work_revert_list.push(res.data);

          this.loading_sell_revert = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Услуга «${res.data.work.name}» возвращена`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.loading_sell_revert = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Вернуть товар не возможно', item: '' });
        });
    }

    sendSellWorkUnrevert(item) {
      SellProvider.sendSellWorkUnrevert(item.idWork)
        .then(() => {
          this.getViewSell(item.id);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Возврат услуги успешно отменён', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Отменить возврат услуги не удалось', item: '' });
        });
    }

    deleteSellWork(item) {
      SellProvider.deleteSellWork(item.id)
        .then((res) => {
          const index = this.sell_work_list.findIndex((i) => i.id === res.data.id);
          this.sell_work_list.splice(index, 1);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Услуга «${item.work.name}» удалена из продажи`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    // sell-parts

    sendSellPart(item) {
      SellProvider.sendSellPart(item)
        .then((res) => {
          this.sell_part_list.push(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Товар «${res.data.part.name}» добавлен в продажу`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    changSellPart(item) {
      this.loading_all_product = true;
      SellProvider.changSellPart(item.id, item)
        .then((res) => {
          this.loading_all_product = false;

          this.flags_serial_modal = false;
          this.flags_seller_modal = false;

          const index = this.sell_part_list.findIndex((i) => i.id === res.data.id);
          (this.sell_part_list[index] as object) = this.transformItem(item, 'part');

          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар обновлен', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.loading_all_product = false;
        });
    }

    sendSellPartUnrevert(item) {
      SellProvider.sendSellPartUnrevert(item.idPart)
        .then(() => {
          this.getViewSell(item.id);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Возврат товара успешно отменён', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Отменить возврат товара не удалось', item: '' });
        });
    }

    sendSellPartRevert(item) {
      this.loading_sell_revert = true;
      SellProvider.sendSellPartRevert(item)
        .then((res) => {
          const indexPart = this.sell_part_list.findIndex((i) => i.part.id === res.data.part_id);
          if (indexPart >= 0) this.sell_part_list.splice(indexPart, 1);

          this.sell_part_revert_list.push(res.data);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Товар «${res.data.part.name}» возвращен`, item: '' });

          this.loading_sell_revert = false;
          this.revert_reason = false;
          this.clearRevert();
          this.resetFormRevert();
        })
        .catch((err) => {
          console.error(err);
          this.loading_sell_revert = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Вернуть товар не возможно', item: '' });
        });
    }

    deleteSellPart(item) {
      SellProvider.deleteSellPart(item.id)
        .then((res) => {
          const index = this.sell_part_list.findIndex((i) => i.id === res.data.id);
          this.sell_part_list.splice(index, 1);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Товар «${item.part.name}» удален из продажи`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    getViewSell(id) {
      SellProvider.getViewSell(id)
        .then((res) => {
          this.updateSell(res.data);

          this.getAllComments()
            .then((resp) => {
              this.sell_comments = resp;
            })
            .catch((err) => console.error(err));
        }).catch((err) => console.error(err));
    }

    getDocumentList(item) {
      DocumentProvider.getDocumentList(item.page, item, item['per-page'])
        .then((res) => {
          const list = res.data;

          const documentSell = list.filter((doc) => doc.type === 3);

          documentSell.forEach((documentItem) => this.printer.push(documentItem));
          list.forEach((document) => {
            switch (document.type) {
              case 4: this.documents_sold_goods.push(document); break;
              case 5: this.documents_sold_works.push(document); break;
              default: break;
            }
          });

          if (this.page_document_list <= Number(res.pageCount)) {
            this.getDocumentList({ page: this.page_document_list, 'per-page': 20 });
          }

          this.page_document_list += 1;
        }).catch((err) => {
          console.error(err);
        });
    }

    getStuffList() {
      StuffProvider.getFullStuffList()
        .then((res) => {
          this.stuff_list = res;
        }).catch((err) => console.error(err));
    }

    getStorageList(page) {
      StorageProvider.getStorageList(page)
        .then((res) => {
          this.storage_list = res.data;
        }).catch((err) => console.error(err));
    }

    getClientList(page) {
      ClientsProvider.getClientList(page)
        .then((res) => {
          this.client = res.data;

          this.loading_clients = false;
        }).catch((err) => {
          console.error(err);
          this.loading_clients = false;
        });
    }

    filterClient(item: ClientUniversalSearch, page: number) {
      this.loading_clients = true;
      ClientsProvider.universalSearch(page, item)
        .then((res) => {
          this.client = res.data;

          this.loading_clients = false;
        })
        .catch((err) => {
          this.loading_clients = false;
          console.error(err);
        });
    }

    getCashList() {
      CashProvider.getAllCashes()
        .then((res) => {
          this.cash_list = res;
          const filterCashListOnPoint = res.find(
            (cash) => cash.point_id === this.user.point_id,
          );
          this.pay.cashbox_id = filterCashListOnPoint ? filterCashListOnPoint.id : null;
        })
        .catch((err) => console.error(err));
    }

    // clear data api

    clearRevertList() {
      this.sell_part_revert_list = [];
      this.sell_work_revert_list = [];
    }

    clearProductList() {
      this.sell_part_list = [];
      this.sell_work_list = [];
    }

    clearStateDiscountList() {
      this.sell_discount_list = [];
    }

    clearStatePaymentList() {
      this.sell_payment_list = [];
    }

    clearExpressForm() {
      this.express_form.name = '';
      this.express_form.price = null;
      this.express_form.posting_price = null;
      this.express_form.amount = 1;
      this.express_form.category_id = 1;
    }

    mounted() {
      document.title = 'Продажа';
      this.pageName();
      this.getStorageList(1);
      this.getStuffList();
      this.getCashList();
      this.getClientList(1);
      this.addInNewPartList();
      this.addInNewWorkList();
      this.delInNewPartList();
      const baseProvider = new BaseProvider();
      baseProvider.checkExpiresIn()
        .then(() => {
          this.token = baseProvider.access;
        })
        .catch((err) => console.error(err));

      if (!this.modal_active) {
        this.modal_active = !!this.page;
      }
      if (this.clientProp) {
        this.new_form.seller_id = this.user.id;
        this.new_form.client_id = Number(this.clientProp.id);
      }
    }

    destroyed() {
      this.$root.$off('addInNewPartList');
      this.$root.$off('addInNewWorkList');
      this.$root.$off('delInNewPartList');
      this.clearProductList();
    }

    get idSell() {
      return Number(this.$route.params.id);
    }

    get rightsUserCanCreateSell() {
      return this.user.rights ? this.user.rights.can_create_sell : false;
    }

    get rightsUserCanDeleteSell() {
      return this.user.rights ? this.user.rights.can_delete_sell : false;
    }

    get rightsUserCanRemoveAppliedSellPayment() {
      return this.user.rights ? this.user.rights.can_remove_applied_sell_payment : false;
    }

    get rightsUserCanUnapplySell() {
      return this.user.rights ? this.user.rights.can_unapply_sell : false;
    }

    get rightsUserCanRevertSell() {
      return this.user.rights ? this.user.rights.can_revert_sell : false;
    }

    get partsReverts() {
      if (this.sell_part_revert_list) return this.sell_part_revert_list.length;
      return 0;
    }

    get worksReverts() {
      if (this.sell_work_revert_list) return this.sell_work_revert_list.length;
      return 0;
    }

    get page() {
      return this.$route.name === 'sell-add';
    }

    get partCount() {
      return this.page ? 0 : this.sell_part_list.length;
    }

    get workCount() {
      return this.page ? 0 : this.sell_work_list.length;
    }

    get partSum() {
      return this.page ? 0 : this.sell_part_list.reduce((sum, i) => sum + (i.price * i.amount), 0);
    }

    get workSum() {
      return this.page ? 0 : this.sell_work_list.reduce((sum, i) => sum + (i.price * i.amount), 0);
    }

    get vatSum() {
      return this.page ? 0 : this.sellDataList.reduce((sum: number, i) => {
        let vat = 0;

        if ('work' in i) {
          vat = i.work.vat === 0 ? 1 : i.work.vat;
        }

        if ('part' in i) {
          vat = i.part.vat as number === 0 ? 1 : i.part.vat as number;
        }

        switch (vat) {
          case 1:
          case 2: return sum + 0;
          case 3: return sum + ((i.price / 1.1) * 0.1 * i.amount);
          case 4: return sum + ((i.price / 1.18) * 0.18 * i.amount);
          case 5: return sum + (i.price * 0.1 * i.amount);
          case 6: return sum + (i.price * 0.18 * i.amount);
          default: return sum;
        }
      }, 0);
    }

    get vatSumPlus() {
      return this.page ? 0 : this.sellDataList.reduce((sum: number, i) => {
        let vat = 0;

        if ('work' in i) {
          vat = i.work.vat === 0 ? 1 : i.work.vat;
        }

        if ('part' in i) {
          vat = i.part.vat as number === 0 ? 1 : i.part.vat as number;
        }

        switch (vat) {
          case 1:
          case 2:
          case 3:
          case 4: return sum + (i.price * i.amount);
          case 5: return sum + (i.price * 1.1 * i.amount);
          case 6: return sum + (i.price * 1.18 * i.amount);
          default: return sum;
        }
      }, 0);
    }

    get saleSum() {
      if (this.sell_discount_list.length !== 0) {
        const discount: number = this.sell_discount_list.filter((item) => item.type_id === 1)
          .reduce((sum, i) => sum + this.resultDiscount(i), 0);
        const money: number = this.sell_discount_list.filter((item) => item.type_id === 2)
          .reduce((sum, i) => sum + i.amount, 0);
        return this.vatSumPlus - (discount + money);
      }
      return this.vatSumPlus;
    }

    get totalSum() {
      const total: number = this.sell_payment_list.reduce((sum: number, i) => sum + i.sum, 0);
      const sum = this.page ? 0 : this.saleSum - total;
      const totalSum = (Math.round(sum * 100) / 100);
      this.pay.sum = Math.abs(totalSum);
      return totalSum.toFixed(2);
    }

    updateSell(sell) {
      this.sell = sell;
      this.sell_part_list = sell.parts;
      this.sell_work_list = sell.works;
      this.sell_payment_list = sell.payments;
      this.sell_discount_list = sell.discounts;
      this.sell_part_revert_list = sell.parts_reverts;
      this.sell_work_revert_list = sell.works_reverts;

      this.printer = [];

      if (this.page) {
        this.$router.push(`/sell/view/${sell.id}`);
        this.pay.sell_id = Number(this.$route.params.id);
      } else {
        this.new_form.seller_id = sell.seller_id;
        this.pay.user_id = sell.seller_id;
        this.discount.user_id = sell.seller_id;
        this.pay.sell_id = sell.id;

        if (sell.works.length !== 0) {
          sell.works.forEach((element) => this.work_list.push(element));
        }

        if (sell.parts.length !== 0) {
          sell.parts.forEach((element) => this.part_list.push(element));
        }

        if (sell.payments.length !== 0) {
          sell.payments.forEach((payment) => {
            if (!payment?.cashbox?.cashless) {
              this.printer.push({ title: `Кассовый ордер № ${payment.cashoperation_id}`, id: payment.cashoperation_id });
            }
          });
        }

        if (sell.client) {
          const client_name = sell.client.type === 2
            ? sell.client.name
            : sell.client.last_name || sell.client.first_name || sell.client.middle_name;

          this.new_form.client_id = JSON.parse(JSON.stringify(sell.client_id));

          this.queryNamesСlient(client_name);
        }

        this.clearDocuments();
        this.page_document_list = 2;
        this.getDocumentList({ page: 1, 'per-page': 20 });
      }

      this.loading_page = false;
    }

    transformItem = (item, key) => ({
      id: item.id,
      [`${key}_id`]: item[key].id,
      name: item[key].name,
      amount: 1,
      remain: item.amount,
      price: item.price,
      date: item.created_at,
      storage_id: item.storage_id,
      seller_id: item.seller_id,
      serial: item.serial,
      vat: item[key].vat,
    })

    methodDisabledButtons() {
      this.$notify.error({
        title: 'Вам не разрешено удалять продажи',
        message: '',
        position: 'bottom-right',
      });
    }

    methodDisabledButtonsRevertSell() {
      this.$notify.error({
        title: 'Вам не разрешено совершать возврат продаж',
        message: '',
        position: 'bottom-right',
      });
    }

    sendPayment(formName, type) {
      (this.$refs[formName] as Form).validate((valid) => {
        if (type === 'return') this.pay.sum = -this.pay.sum;
        return valid ? this.sendSellPayment(this.pay) : false;
      });
    }

    pageName() {
      if (this.page) {
        this.title_page = 'Новая продажа';
        this.data = [];
        this.part_list = [];
        this.work_list = [];
        this.clearRevertList();
        this.clearProductList();
        this.clearDataForm();
        this.clearStateDiscountList();
        this.clearStatePaymentList();
      } else {
        this.title_page = `Продажа №${this.$route.params.id}`;
        this.data = this.sellDataList;
        this.part_list = this.sell_part_list;
        this.work_list = this.sell_work_list;
        this.loading_page = true;
        this.getViewSell(Number(this.$route.params.id));
      }
      document.title = this.title_page;
      this.breadcrumb[1].section = this.title_page;
      this.addBreadcrumb(this.breadcrumb);
    }

    addInNewPartList() {
      this.$root.$on('addInNewPartList', (i) => {
        if (this.page) {
          this.new_form.parts[0] = i;
          this.new_form.parts[0].price = 'part' in i ? i.part.sell_price : i.price;

          this.new_form.id = i.part_id;
          this.new_form.amount = i.amount;
          this.sendSell(this.new_form);
        } else {
          const iCopy = { ...i };
          iCopy.price = 'part' in i ? i.part.sell_price : i.price;
          iCopy.sell_id = this.$route.params.id;
          this.sendSellPart(iCopy);
        }
      });
    }

    addInNewWorkList() {
      this.$root.$on('addInNewWorkList', (i) => {
        if (this.page) {
          this.new_form.works[0] = i;
          this.new_form.works[0].price = 'work' in i ? i.work.sell_price : i.price;

          this.new_form.id = i.works_id;
          this.new_form.name = i.name;
          this.sendSell(this.new_form);
        } else {
          const iCopy = { ...i };
          iCopy.price = 'work' in i ? i.work.sell_price : i.price;
          iCopy.sell_id = Number(this.$route.params.id);
          this.sendSellWork(iCopy);
        }
      });
    }

    conductSale() {
      this.sendSellApply(Number(this.$route.params.id));
      this.loading_page = true;
    }

    viewConfirmUnrevert(i, title) {
      const id = Number(this.$route.params.id);

      this.$confirm(`Вы уверены что хотите вернуть ${title} в продажу?`, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => {
        if ('work_id' in i.item) {
          this.sendSellWorkUnrevert({ id, idWork: i.item.id });
        } else {
          this.sendSellPartUnrevert({ id, idPart: i.item.id });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Отмена возврата товара',
        });
      });
    }

    viewConfirmDelete(i, title) {
      this.$confirm(`Вы уверены что хотите удалить ${title} из продажи?`, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => {
        this.active_send = i.active;
        const type = i.item.part_id !== undefined;

        if ('part' in i.item || 'work' in i.item) {
          if (type) {
            this.deleteSellPart(i.item);
          } else {
            this.deleteSellWork(i.item);
          }
        } else {
          this.data.forEach((product) => {
            if ('work' in product && product.work.id === i.item.work_id) {
              this.deleteSellWork(product);
            }

            if ('part' in product && product.part.id === i.item.part_id) {
              this.deleteSellPart(product);
            }
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Отмена удаления',
        });
      });
    }

    fillRevertParts(i) {
      if ('work_id' in i.item) {
        delete this.revert_part.sell_part_id;
        this.revert_part.sell_work_id = i.item.id;
        this.revert_part.work_id = i.item.work_id;
      }

      if ('part_id' in i.item) {
        delete this.revert_part.sell_work_id;
        this.revert_part.sell_part_id = i.item.id;
        this.revert_part.part_id = i.item.part_id;
      }
    }

    delInNewPartList() {
      this.$root.$on('delInNewPartList', (i) => {
        if (i.active) {
          this.fillRevertParts(i);
          this.revert_part.amount = i.item.amount;
          this.revert_reason = i.active;
          this.active_send = i.active;
        } else {
          const title = 'work_id' in i.item ? 'услугу' : 'товар';
          if ('reason' in i.item) {
            this.viewConfirmUnrevert(i, title);
          } else {
            this.viewConfirmDelete(i, title);
          }
        }
      });
    }

    updateForm() {
      this.sendSellCancel(Number(this.$route.params.id));
    }

    handleSellRevert() {
      this.revert_reason = true;
    }

    closeModalReason() {
      this.clearRevert();
      this.resetFormRevert();
    }

    clearRevert() {
      delete this.revert_part.work_id;
      delete this.revert_part.part_id;
      this.revert_part.reason = '';
      this.revert_part.amount = null;
      this.active_send = false;
    }

    clearDocuments() {
      this.documents_sold_goods = [];
      this.documents_sold_works = [];
    }

    clearDataForm() {
      this.new_form = {
        client_id: null,
        seller_id: null,
        parts: [],
        works: [],
        payments: [],
        discounts: [],
      };
    }

    openSerialModal(serial) {
      const name = 'name' in serial ? serial.name : serial.part.name;
      this.serial_form = serial;
      this.serial_form.name = name;
      this.flags_serial_modal = true;
    }

    openSellerModal(product: object) {
      this.seller_form = product;
      this.flags_seller_modal = true;
    }

    changeSeller(id: number) {
      const part_index = this.data.findIndex((part) => part.id === this.seller_form.id);
      const stuff = this.stuff_list.find((stuffItem) => stuffItem.id === id);
      (this.data[part_index] as Sell).seller = stuff;
    }

    changeAllProduct() {
      if ('work_id' in this.seller_form) {
        this.changSellWork(this.seller_form);
      } else {
        this.changSellPart(this.seller_form);
      }
    }

    closeSerialModal() {
      this.flags_serial_modal = false;
    }

    changeSerial(value) {
      this.serial_form.serial = value;
    }

    sendRemoveOrRevert(formName) {
      (this.$refs[formName] as Form).validate((valid) => {
        if (valid) {
          if (this.active_send) {
            if ('work_id' in this.revert_part) {
              delete this.revert_part.work_id;
              this.sendSellWorkRevert(this.revert_part);
            } else {
              delete this.revert_part.part_id;
              this.sendSellPartRevert(this.revert_part);
            }
          } else {
            this.loading_page = true;
            this.sendSellRevert({
              sell_id: Number(this.$route.params.id),
              reason: this.revert_part.reason,
            });
          }
          return true;
        }
        return false;
      });
    }

    newClient(client) {
      this.client.push(client);
      this.new_form.client_id = client.id;
      this.sell.client = client;
      this.selectedClientId(client.id);
    }

    openClientPageInNewTab() {
      const route = this.$router.resolve({
        name: 'clients-view',
        params: {
          id: String((this.sell.client as User).id),
        },
      });
      window.open(route.href, '_blank');
    }

    resetFormRevert() {
      (this.$refs.formRevert as Form).resetFields();
    }

    resetFormDiscount() {
      (this.$refs.discountForm as Form).resetFields();
    }

    resetClientsForm() {
      const clientsAdd = this.$refs.clientsAdd as ClientsAddPage;
      switch (clientsAdd.activeTabs) {
        case 1: clientsAdd.clientUser.resetFields(); break;
        case 2: clientsAdd.clientCompany.resetFields(); break;
        default: break;
      }
    }

    formatNameClients = (client) => {
      const lastName: string = client.last_name ? client.last_name : '';
      const firstName: string = client.first_name ? client.first_name : '';
      const middleName: string = client.middle_name ? client.middle_name : '';
      const { phone } = client;
      return client.type === 2 ? client.name : `${lastName} ${firstName} ${middleName} ${phone}`;
    }

    queryNamesСlient(query) {
      this.loading_clients = true;
      this.filterClient({ query }, 1);
    }

    delSellPayment(index) {
      this.deleteSellPayments(this.sell_payment_list[index].id);
    }

    discountAdd(formName) {
      (this.$refs[formName] as Form).validate((valid) => {
        if (valid) {
          this.discount.sell_id = Number(this.$route.params.id);
          this.discount.amount = Number(this.discount.amount);
          this.sendDiscount(this.discount);
          return true;
        }
        return false;
      });
    }

    resultDiscount(discount: Discount) {
      return this.vatSumPlus * (discount.amount / 100);
    }

    addPay(value) {
      this.pay.sum = Number(value);
    }

    changeDocuments(value) {
      const printOperation: Document | undefined = this.printer.find((print) => print.id === value);
      if (printOperation) {
        const id = Number(this.$route.params.id);
        this.print_value = value;
        const domain = process.env.NODE_ENV === 'production' ? 'printing.servix.io' : 'printing-dev.servix.io';
        if (printOperation.type === undefined) {
          window.open(`https://${domain}/print/cashoperation?token=${this.token}&operationId=${value}`);
        } else {
          window.open(`https://${domain}/print/document?token=${this.token}&documentId=${value}&entityId=${id}`);
        }
      } else {
        throw new Error('printOperation is undefined');
      }
    }

    selectedSellerId(value) {
      const dataUpdate = {
        id: this.idSell,
        data: {
          seller_id: value,
        },
      };
      this.changeSell(dataUpdate);
    }

    selectedClientId(value) {
      if (!value) {
        this.sell.client = {};
      } else {
        const client = this.client.find((x) => x.id === value);
        if (client) {
          this.sell.client = client;
        }
      }
      const dataUpdate = {
        id: this.idSell,
        data: {
          seller_id: this.new_form.seller_id,
          client_id: value,
        },
      };
      if (value === '') {
        return;
      }
      this.changeSell(dataUpdate);
    }

    @Watch('user', { immediate: true })
    onLoadUser(val: Sell) {
      if (Object.keys(val).length !== 0) {
        this.new_form.seller_id = val.id;
        this.express_form.seller_id = val.id;
      }
    }

    @Watch('$route.name')
    onChangeName() {
      this.pageName();
    }

    @Watch('sellDataList')
    onAddNew(val: object[]) {
      this.data = val;
      this.part_list = this.sell_part_list;
      this.work_list = this.sell_work_list;
    }

    @Watch('clientPersonValid')
    addClientPerson() {
      this.resetClientsForm();
      this.open_modal_client_add = false;
    }

    @Watch('clientCompanyValid')
    addClientCompany() {
      this.resetClientsForm();
      this.open_modal_client_add = false;
    }

    @Watch('balance')
    getBalance(balance) {
      if (balance.current_license === null && this.balance.payment_balance <= 0) {
        this.$alert(
          'У вас нет активных лицензий. Для создания новой продажи необходимо купить лицензию на программу.',
          'Нет лицензии',
          {
            type: 'error',
            confirmButtonText: 'Купить',
            confirmButtonClass: 'el-button el-button--default el-button--small el-button--success',
            callback: (action) => {
              if (action === 'confirm') {
                this.$router.push({ name: 'buy-balance' });
              }
            },
          },
        );
      } else {
        this.modal_active = !!this.page;
      }
    }
}

export interface SellCommentForm {
    content: string;
}
