import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Config } from '@/types/index';
import IntegrationsProvider from '../../api/providers/integrations';

@Component({
  template: require('./index.html'),
})
export default class IntegrationEditBsg extends Vue {
    @Prop() provider!: object;

    newConfig: Config = {};

    rules= {
      sender_name: [
        { required: true, message: 'Введите Имя Отправителя', trigger: 'blur' },
      ],
      config: [
        { required: true, message: 'Введите Ключ API', trigger: 'blur' },
      ],
    };

    mounted() {
      this.newConfig = { ...this.provider };
      delete this.newConfig.description;
      delete this.newConfig.info_url;
      delete this.newConfig.logo_url;
      delete this.newConfig.name;
    }

    saveProviderConfig() {
      if (this.newConfig.sender_name) {
        if (this.newConfig.sender_name.length === 0) {
          return;
        }
      } else {
        return;
      }
      if (this.newConfig.config) {
        if (this.newConfig.config.length === 0) {
          return;
        }
      } else {
        return;
      }
      if (this.newConfig.id === null) {
        IntegrationsProvider.addConfig(this.newConfig)
          .then(() => {
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Провайдер настрон', item: '' });
            this.$emit('updateList');
            this.$emit('closeEditModal');
          })
          .catch((err) => {
            console.error('err', err);
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось настроить провайдера', item: '' });
          });
      } else {
        IntegrationsProvider.updateConfig(this.newConfig)
          .then(() => {
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Провайдер обновлен', item: '' });
            this.$emit('updateList');
            this.$emit('closeEditModal');
          })
          .catch((err) => {
            console.error('err', err);
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось обновить настройки провайдера', item: '' });
          });
      }
    }
}
