export default class Link {
    name: string;

    path: string;

    constructor(name: string, path: string) {
      this.name = name;
      this.path = path;
    }
}
