import BaseProvider from '../base-provider';

class ServiceProvider extends BaseProvider {
  async getLicenseList(page) {
    const expand: string[] = ['tariff'];
    const res = await this.request('GET', `/service/licenselist?page=${page}&expand=${expand}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getPaymentList(page) {
    const res = await this.request('GET', `/service/paymentlist?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getStickerSettings() {
    const res = await this.request('GET', '/service/stickersettings');
    return res;
  }

  async sendUploadlogo(item) {
    const data: object = { LogoUploadForm: item };
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/service/uploadlogo', data, contentType);
    return res;
  }

  async sendStickerSettings(item) {
    const data: object = { StickerSettings: item };
    const res = await this.request('POST', '/service/stickersettings', data);
    return res;
  }
}

export default new ServiceProvider();
