import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  template: require('./addition.html'),
})

export default class FormAddition extends Vue {
  @Action sendRepairAddition;

  @Prop({ default: () => 'success' }) typeBtn!: string;

  @Prop({ default: () => 'Сохранить' }) actionsBtn!: string;

  @Prop() data!: object;

  @Prop() saveAddition!: Function;

  @Getter addition;

  @Getter btnloader;

  validateSum = (rule, value, callback) => {
    if (value === null || value === '') {
      callback(new Error('Заполните поле «Наценка»'));
    } else if (value < 0) {
      callback(new Error('Значение «Наценка» должно быть не меньше 0.'));
    } else {
      callback();
    }
  }

  validateTypeAddition = (rule, value, callback) => {
    if (value === null || value === '') {
      callback(new Error('Заполните поле «Тип наценки»'));
    } else {
      callback();
    }
  }

  rules: object = {
    amount: [
      {
        required: true, validator: this.validateSum, trigger: 'change', type: 'number',
      },
    ],
    type_id: [
      { required: true, validator: this.validateTypeAddition },
    ],
  };

  clearFormValidation() {
    (this.$refs.formAddition as Form).clearValidate();
  }

  resetForm() {
    (this.$refs.formAddition as Form).resetFields();
  }

  additionType: object[] = [
    { value: '% (от полной стоимости заказа)', id: 1 },
    { value: 'сумма', id: 2 },
  ];

  additionAdd(formName) {
    const refForm = (this.$refs[formName] as Form);
    refForm.validate((valid) => (valid ? this.saveAddition(this.data) : false));
  }

  @Watch('addition')
  resultFormAddition() { this.resetForm(); }
}
