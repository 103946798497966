import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VueBarcode from 'vue-barcode';
import { Getter } from 'vuex-class';
import { Work, Part } from '@/types';

interface Category {
  id: number;
  value: string;
}

enum DataType {
  PART = 'PART',
  WORK = 'WORK',
}

@Component({
  template: require('./index.html'),
  components: {
    barcode: VueBarcode,
  },
})

export default class InfoAboutPartOrWork extends Vue {
  @Getter user;

  @Getter vatlist;

  @Getter warrantTypeList;

  @Getter rewardTypeList;

  @Getter categoriesMainList;

  @Prop() data!: Work | Part;

  get userRigthCanEditDeletePartsAndCategories() {
    return this.user.rights ? this.user.rights.can_edit_delete_parts_and_categories : false;
  }

  get typeOfData(): DataType {
    const type = (this.data as Part).amounts ? DataType.PART : DataType.WORK;
    return type;
  }

  get getDescription(): string {
    if ((this.data as Part).description) {
      return (this.data as Part).description as string;
    }
    return 'Описание отсутствует';
  }

  get getArt(): string {
    if ((this.data as Part).art) {
      return (this.data as Part).art as string;
    }
    return 'Артикул отсутствует';
  }

  get getName(): string {
    if (this.data.name) {
      return this.data.name;
    }
    return 'Название отсутствует';
  }

  get getMinimumAmount(): string {
    if ((this.data as Part).minimum_amount) {
      return String((this.data as Part).minimum_amount);
    }
    return 'Минимальный остаток отсутствует';
  }

  get category(): string {
    const founded = this.categorySelectList().find((cat) => cat.id === this.data.cat_id);
    if (founded) {
      return founded.value;
    }
    return 'Категория не найдена';
  }

  get getCell(): string {
    if ((this.data as Part).cell) {
      return (this.data as Part).cell as string;
    }
    return 'Ячейка отсутствует';
  }

  get getRepairPrice(): string {
    if (this.data.repair_price) {
      return String(this.data.repair_price);
    }
    return 'Цена для ремонта отсутствует';
  }

  get getSellPrice(): string {
    if (this.data.sell_price) {
      return String(this.data.sell_price);
    }
    return 'Цена для продажи отсутствует';
  }

  get getRepairRewardSize(): string {
    if (this.data.repair_reward_size) {
      return String(this.data.repair_reward_size);
    }
    return 'Вознаграждение за ремонт отсутствует';
  }

  get getSelectedRepairReward(): string {
    const founded = this.rewardTypeList.find(
      (rewardType) => rewardType.id === this.data.repair_reward_type,
    );
    if (founded) {
      return founded.name;
    }
    return 'Тип вознаграждения неизвестен';
  }

  get getSellRewardSize(): string {
    if (this.data.sell_reward_size) {
      return String(this.data.sell_reward_size);
    }
    return 'Вознаграждение за продажу отсутствует';
  }

  get getSelectedSellReward(): string {
    const founded = this.rewardTypeList.find(
      (rewardType) => rewardType.id === this.data.sell_reward_type,
    );
    if (founded) {
      return founded.name;
    }
    return 'Тип вознаграждения неизвестен';
  }

  get getVat(): string {
    const founded = this.vatlist.find(
      (vatItem) => vatItem.id === this.data.vat,
    );
    if (founded) {
      return founded.name;
    }
    return 'НДС неизвестен';
  }

  get getWarrantDuration(): string {
    if (this.data.warrant_duration) {
      return String(this.data.warrant_duration);
    }
    return 'Гарантия отсутствует';
  }

  get getWarrantType(): string {
    const founded = this.warrantTypeList.find(
      (warrantType) => warrantType.id === this.data.warrant_type,
    );
    if (founded) {
      return founded.name;
    }
    return 'Тип гарантии неизвестен';
  }

  categorySelectList(): Category[] {
    return this.flattery(this.categoriesMainList) as Category[];
  }

  methodDisabledButtons() {
    this.$notify.error({
      title: 'Вам не разрешено изменять и удалять товары, услуги и категории',
      message: '',
      position: 'bottom-right',
    });
  }

  /**
   * Преобразует массив объектов в плоский набор
   * @param parent Массив объектов категорий
   * @param previousRoute строка предыдущего пути
   */
  private flattery(parent, previousRoute = '') {
    const result: object[] = [];
    parent.forEach((element) => {
      const route = (previousRoute !== '') ? `${previousRoute} / ${element.title}` : element.title;
      result.push({ id: element.key, value: route });
      if (element.children.length > 0) {
        result.push(...this.flattery(element.children, route));
      }
    });
    return result;
  }
}
