import { GetterTree } from 'vuex';
import { StateParts } from './types';

const getters: GetterTree<StateParts, object> = {
  parts: (state) => state.parts,
  viewPart: (state) => state.viewPart,
  vatlist: (state) => state.vatlist,
  warrantTypeList: (state) => state.warrantTypeList,
  rewardTypeList: (state) => state.rewardTypeList,
  partPageCount: (state) => state.partPageCount,
  partPageCurrent: (state) => state.partPageCurrent,
  partAmountControl: (state) => state.partAmountControl,
  partPriceTagNewItem: (state) => state.partPriceTagNewItem,

  barcode: (state) => state.barcode,
  barcodeList: (state) => state.barcodeList,
  validPart: (state) => state.validPart,

  logs: (state) => state.logs,
  logsPageCount: (state) => state.logsPageCount,

  heads: (state) => state.heads,

  headsPart: (state) => state.headPart,
  headNomenkl: (state) => state.headNomenkl,

  postingPartsList: (state) => state.parts.map((i) => ({
    id: i.id,
    name: i.name,
    part_id: i.id,
    amount: 1,
    price: i.posting_price === null ? 0 : i.posting_price,
  })),

  cancelPartsList: (state) => state.parts.map((i) => ({
    id: i.id,
    name: i.name,
    part_id: i.id,
    amount: 1,
    price: i.posting_price === null ? 0 : i.posting_price,
    amounts: i.amounts,
  })),

  transferPartsList: (state) => state.parts.map((i) => ({
    id: i.id,
    name: i.name,
    part_id: i.id,
    amount: 1,
    price: i.posting_price === null ? 0 : i.posting_price,
    amounts: i.amounts,
  })),

  PartsPriceTagList: (state) => state.parts.map((i) => ({
    id: i.id,
    name: i.name,
    part_id: i.id,
    amount: 1,
    price: i.sell_price === null ? 0 : i.sell_price,
    amounts: i.amounts,
  })),
};

export default getters;
