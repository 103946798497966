import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  template: require('./index.html'),
  components: {

  },

})
export default class LicensesMessage extends Vue {
    @Getter balance;

    get licenses() {
      if (Object.keys(this.balance).length !== 0) return this.balance;
      return false;
    }

    get notLicenses() {
      if (this.licenses) {
        return this.licenses.current_license === null
          && this.licenses.reserved_license.length === 0;
      }
      return false;
    }

    get warningEndLicenses() {
      if (this.licenses) {
        if (this.dayEndLicenses) {
          return this.dayEndLicenses <= 7 && this.licenses.reserved_license.length === 0;
        }
      }
      return false;
    }

    get warningEndPending(): boolean {
      if (this.licenses) {
        if (this.notLicenses) {
          return this.pendingDays < 7;
        }
      }
      return false;
    }

    get titleWarningMessageLicenses() {
      if (this.warningEndPending) return `Средств на вашем балансе хватит еще примерно на ${this.licenses.pending_days} дней. Рекомендуем пополнить баланс во избежание блокировки работы программы`;
      if (this.warningEndLicenses) return `Ваша лицензия истечет через ${this.dayEndLicenses} дней. После истечения лицензии, оплата программы будет проводиться с баланса. Убедитесь что на балансе есть средства, иначе работа в программе может быть заблокирована.`;
      return '';
    }

    get dayEndLicenses() {
      if (this.licenses) {
        const now = Math.round(new Date().getTime() / 1000);
        const stay = this?.licenses?.current_license?.expired_at - now;
        const day = Math.ceil(stay / 60 / 24 / 60);

        return day;
      }
      return 0;
    }

    get pendingDays(): number {
      return this.licenses.pending_days ? this.licenses.pending_days : 0;
    }

    redirectOnLicensesPage() {
      this.$router.push({ name: 'buy-balance' });
    }
}
