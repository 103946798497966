import { ActionTree } from 'vuex';
import {
  StateBug,
} from './types';

const actions: ActionTree<StateBug, object> = {

  getBugList({ commit }, item) {
    commit('GET_BUG', item);
  },

  sendBug({ commit }, item) {
    commit('INSERT_BUG', item);
  },

  changeBug({ commit }, item) {
    commit('EDIT_BUG', item);
  },

  deleteBug({ commit }, item) {
    commit('DELETE_BUG', item);
  },

};

export default actions;
