import { GetterTree } from 'vuex';
import { StatePosting } from './types';

const getters: GetterTree<StatePosting, object> = {
  posting: (state) => state.posting,
  postingAdd: (state) => state.postingAdd,
  postingView: (state) => state.postingView,
  postingValid: (state) => state.postingValid,
  postingPageCount: (state) => state.postingPageCount,
  postingNewItemList: (state) => state.postingNewItemList,
  postingChangePart: (state) => state.postingChangePart,
  postingRevert: (state) => state.postingRevert,
};

export default getters;
