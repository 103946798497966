import { ActionTree } from 'vuex';
import { LogRequest, LogForm } from '@/types';
import LogRequestProvider from '@/api/providers/log-request';

const actions: ActionTree<any, object> = {

  /**
   * Отправка логов на для метрики
   * @param  {LogForm} item
   */
  sendLog({ getters }, item: LogForm) {
    const log: LogRequest = {
      name: item.name,
      date: item.date,
      payload: item.payload,
      userId: getters.user.id ?? null,
      serviceId: getters.company.id ?? null,
    };
    LogRequestProvider.req(log)
      .catch((err) => {
        throw new Error(err);
      });
  },
};

export default actions;
