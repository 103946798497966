const state = {
  smsOffers: [],
  acquirers: [],
  buySms: {},
  buyLicense: {},
  tariffLicense: {},
  balance: {},
};

export default state;
