import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Numbers from '../../forms/numbers';
import SalaryOperationTable from '../salary-operatiorn-table';
import SalaryTransferTable from '../salary-transfer-table';

@Component({
  template: require('./index.html'),
  components: {
    SalaryOperationTable,
    SalaryTransferTable,
    Numbers,
  },
})

export default class SalaryViewPrevious extends Vue {
    @Getter salaryTransferListByUserId;

    @Getter salaryTransferListPageCount;

    @Action setSalaryTransferListByUserId;

    loadingSalaryTransfer = true;

    currentPage = 1;
    // hooks

    created() {
      const { page } = this.$route.params;
      this.setSalaryTransferListByUserId({
        user_id: this.$route.params.user_id,
        page,
      });
      this.currentPage = Number(page);
      this.$router.push(`/salary/previous/${this.$route.params.user_id}/${page}`);
    }
    // methods

    changePage(page) {
      this.$nextTick(() => {
        this.loadingSalaryTransfer = false;
      });
      this.setSalaryTransferListByUserId({
        user_id: this.$route.params.user_id,
        page,
      });
      this.$router.push(`/salary/previous/${this.$route.params.user_id}/${page}`);
      this.$nextTick(() => {
        this.loadingSalaryTransfer = true;
      });
      window.scroll(0, 0);
    }
    // watchers

    @Watch('salaryTransferListByUserId')
    onSalaryTransferListByUserIdChange() {
      this.$nextTick(() => {
        this.loadingSalaryTransfer = false;
      });
    }

    @Watch('$route.params')
    reloadPage() {
      const { page } = this.$route.params;
      this.setSalaryTransferListByUserId({
        user_id: this.$route.params.user_id,
        page,
      });
      this.currentPage = Number(page);
      this.$router.push(`/salary/previous/${this.$route.params.user_id}/${page}`);
    }
}
