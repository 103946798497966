const state = {
  client: [],
  clientInn: [],
  clientPersonValid: {},
  clientCompanyValid: {},
  viewClient: {},
  pageCurrent: null,
  pageCount: null,
  clientVip: {},
  clientRegular: {},
  clientBlackList: {},
  clientCheckBlackList: [],
};

export default state;
