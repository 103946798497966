import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  template: require('./404.html'),
})
class Error404Component extends Vue {

}

export { Error404Component as default };
