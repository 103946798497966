export const GET_CATEGORY = 'GET_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const GATEGORIES_VALIDATION = 'GATEGORIES_VALIDATION';
export const CLEAR_VALIDATE = 'CLEAR_VALIDATE';
export const CLEAR_CATEGORIES_LIST = 'CLEAR_CATEGORIES_LIST';

// Store

export interface StateCategory {
    categories: object[];
    categoriesMainList: object[];
    newCategory: object;
    categoriesValidate: object;
}
