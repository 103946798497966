import { Form } from 'element-ui';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import InfoAboutPartOrWork from '@/components/info-about-part-or-work';
import { Breadcrumb, Part } from '@/types';
import InventoryProvider, {
  Inventory, InventoryForm, InventoryPartForm, InventoryPartSearchForm, InventoryStatuses,
} from '../../api/providers/inventory';
import PartProvider from '../../api/providers/part';
import StorageProvider from '../../api/providers/storage';
import ViewDate from '../forms/date';
import ViewUserName from '../forms/user-name';
import ModalCatalog from '../modal-catalog';
import WarehouseTable from '../warehouse-table';

interface InventoryComments {
  content: string;
  created_at: number;
  inventory_id: number;
  id: number;
  user: object;
  user_id: number;
}

interface TableItem {
  amount?;
  id: number;
  inventory_id?: number;
  number?;
  part?: object;
  part_id: number;
}

@Component({
  template: require('./index.html'),
  components: {
    date: ViewDate,
    'user-name': ViewUserName,
    'warehouse-table': WarehouseTable,
    'modal-catalog': ModalCatalog,
    InfoAboutPartOrWork,
  },
})
export default class InventoryView extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action getVatList;

    @Action getRewardTypeList;

    @Action getWarrantTypeList;

    @Action getPartCategory;

    dataForModalView = {};

    showModalViewAboutWorkOrPart = false;

    titleInfoAboutPartOrWork = '';

    btnloader = false;

    @Getter user;

    load_page = false;

    inventoryId: number | null= null;

    inventory: Inventory | null= null;

    inventoryFromApi: Inventory | null = null;

    optionsOfStorage: object[] = [];

    tableData: TableItem [] = [];

    currentData: object [] = [];

    totalPageCount = 0;

    perPage = 20;

    searchPart = '';

    lastInputing: number | null= null;

    isApplyInventoryModal = false;

    isRevertInventoryModal = false;

    isDeleteInventoryModal = false;

    inventoryComents: InventoryComments[] = [];

    iventoryCommentForm = { content: '' };

    iventoryCommentFormRules = {
      content: [
        {
          required: true, message: 'Обязательно для заполнения', whitespace: true, trigger: 'change',
        },
        {
          max: 3000,
          trigger: 'change',
          message: 'Комментарий должен быть не более 3000 символов',
        },
      ],
    };

    loading = false;

    posting_parts_list: object[] = [];

    get rightsUserCanCreateAndFill() {
      return this.user.rights ? this.user.rights.can_create_fill_inventory : false;
    }

    get rightsUserCanApply() {
      return this.user.rights ? this.user.rights.can_apply_inventory : false;
    }

    get rightsUserCanRevert() {
      return this.user.rights ? this.user.rights.can_revert_inventory : false;
    }

    get inventoryType(): boolean {
      return this.inventory === null || this.inventory.status === InventoryStatuses.DRAFT;
    }

    get isIventoryChange() {
      if (this.inventory && this.inventoryFromApi) {
        return this.inventory.not_listed_strategy === this.inventoryFromApi.not_listed_strategy
          && this.inventory.storage_id === this.inventoryFromApi.storage_id;
      }
      return false;
    }

    mounted() {
      this.inventoryId = Number(this.$route.params.id);
      document.title = `Инвентаризация № ${this.inventoryId}`;

      const breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Инвентаризация', link: '/inventory/1' }, { id: 3, section: `Инвентаризация № ${this.inventoryId}`, link: null }];
      this.addBreadcrumb(breadcrumb);

      this.getVatList();
      this.getRewardTypeList();
      this.getWarrantTypeList();
      this.getPartCategory('part');

      this.getInventory(this.inventoryId);
      this.getListOfInventorypart(this.inventoryId, 1);
      this.getInventoryComments(this.inventoryId);
      this.getStorageList(1);
    }

    destroyed() {
      this.addActionBtn([]);
    }

    viewingPart(row) {
      try {
        this.btnloader = true;
        PartProvider.getViewPart(row.part.id)
          .then((res) => {
            this.titleInfoAboutPartOrWork = 'Информация о товаре';
            this.dataForModalView = res.data;
            this.btnloader = false;
            this.getVatList();
            this.getRewardTypeList();
            this.getWarrantTypeList();
            this.getPartCategory('part');

            this.showModalViewAboutWorkOrPart = true;
          })
          .catch((error) => {
            this.btnloader = false;
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о товаре', item: '' });
            throw new Error(error);
          });
      } catch (err) {
        this.btnloader = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        throw new Error(err);
      }
    }

    closeInfoAboutPartOrWork() {
      this.showModalViewAboutWorkOrPart = false;
    }

    getArtAndBarcodes = (item) => {
      const result: string[] = [];

      if (item.art !== null && item.art.length > 0) {
        result.push(item.art);
      }
      item.barcodes.forEach((element) => {
        result.push(element.value);
      });
      return result;
    }

    getInventory(inventoryId) {
      InventoryProvider.getInventory(inventoryId)
        .then((res) => {
          this.inventory = res.data;
          this.inventoryFromApi = { ...res.data };
        })
        .catch((err) => {
          console.error(err);
        });
    }

    getListOfInventorypart(inventoryId: number, page) {
      this.load_page = true;
      const searchForm: InventoryPartSearchForm = {
        inventory_id: inventoryId,
        part_id: null,
      };
      InventoryProvider.getListOfInventorypart(searchForm, page, this.perPage)
        .then((res) => {
          const resCopy = { ...res };
          resCopy.data.forEach((element, i) => {
            resCopy.data[i].amount = element.number;
          });
          this.tableData = this.tableData.concat(resCopy.data);
          this.totalPageCount = resCopy.pageCount;
          if (Number(resCopy.pageCount) > 0) {
            if (resCopy.pageCount !== resCopy.pageCurrent) {
              this.getListOfInventorypart(inventoryId, Number(resCopy.pageCurrent) + 1);
            } else {
              this.load_page = false;
            }
          } else {
            this.load_page = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.load_page = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о инвентаризации', item: '' });
        });
    }

    getStorageList(page) {
      StorageProvider.getStorageList(page)
        .then((res) => {
          this.optionsOfStorage = res.data;
          const { pageCount } = res;
          if (pageCount > 1) {
            for (let i = 2; i <= pageCount; i++) {
              StorageProvider.getStorageList(i)
                .then((getStorageListRes) => {
                  this.optionsOfStorage = this.optionsOfStorage.concat(getStorageListRes.data);
                }).catch((err) => {
                  throw (err);
                });
            }
          }
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные', item: '' });
        });
    }

    getInventoryComments(inventoryId: number) {
      InventoryProvider.getInventoryComments(inventoryId)
        .then((res) => {
          this.inventoryComents = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить комментарии', item: '' });
        });
    }

    updateInventory() {
      if (this.inventory && this.inventoryId) {
        const form: InventoryForm = {
          storage_id: this.inventory.storage_id,
          not_listed_strategy: this.inventory.not_listed_strategy,
        };
        InventoryProvider.updateInventory(this.inventoryId, form)
          .then(() => {
            if (this.inventory) {
              this.inventoryFromApi = { ...this.inventory };
              this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Изменения сохранены', item: '' });
            }
          })
          .catch((err) => {
            console.error(err);
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось сохранить изменения', item: '' });
          });
      }
    }

    async updateInvventoryPart(index: number, item: TableItem) {
      if (this.inventoryId) {
        if (this.tableData[index].number === '') {
          this.tableData[index].number = 0;
        } else {
          this.tableData[index].number = Math.floor(Number(this.tableData[index].number));
        }
        const form: InventoryPartForm = {
          part_id: item.part_id,
          number: this.tableData[index].number,
          inventory_id: this.inventoryId,
        };
        InventoryProvider.updateInventoryPart(this.tableData[index].id, form)
          .then(() => {
            this.tableData[index].amount = this.tableData[index].number;
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар обновлен', item: '' });
          })
          .catch((err) => {
            console.error(err);
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось обновить товар', item: '' });
          });
      } else {
        throw new Error();
      }
    }

    loadingPartsList(parts) {
      this.posting_parts_list = parts.map((i) => ({
        id: i.id,
        name: i.name,
        part_id: i.id,
        amount: 1,
        price: i.posting_price === null
          ? 0
          : i.posting_price,
        art: i.art,
        barcodes: i.barcodes,
      }));
    }

    async addNewPart(part) {
      try {
        const nomenklaturePart = await PartProvider.getViewPart(part.id) as Part;
        if (this.inventoryId && nomenklaturePart.id) {
          const inventoryPartForm: InventoryPartForm = {
            inventory_id: this.inventoryId,
            part_id: nomenklaturePart.id,
            number: 1,
          };
          await this.addNewInventoryPart(inventoryPartForm);
        } else {
          throw new Error();
        }
      } catch (err) {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось создать товар', item: '' });
      }
    }

    async addInventoryComments() {
      if (this.iventoryCommentForm.content.length !== 0) {
        try {
          if (this.inventoryId) {
            const comment = await InventoryProvider.addNewInventoryComment(
              this.inventoryId,
              this.iventoryCommentForm.content,
            );
            this.inventoryComents.unshift(comment.data);
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Комментарий добавлен', item: '' });
            this.iventoryCommentForm.content = '';
          } else {
            throw new Error();
          }
        } catch (err) {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось добавить комментарий', item: '' });
        }
      }
    }

    async addNewInventoryPart(item) {
      try {
        if (item && this.inventoryId) {
          const form: InventoryPartForm = {
            inventory_id: this.inventoryId,
            number: item.amount,
            part_id: item.part_id,
          };
          const res = await InventoryProvider.addNewInventoryPart(form);
          res.data.amount = res.data.number;
          this.tableData.push(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар добавлен', item: '' });
        } else {
          throw new Error();
        }
      } catch (err) {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось добавить товар', item: '' });
      }
    }

    async applyInventory() {
      try {
        if (this.inventoryId) {
          const inventory = await InventoryProvider.applyInventory(this.inventoryId);
          this.inventory = inventory.data as Inventory;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Инвентаризация проведена', item: '' });
        } else {
          throw new Error();
        }
      } catch (err) {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось провести инвентаризацию', item: '' });
      } finally {
        this.isApplyInventoryModal = false;
      }
    }

    async revertInventory() {
      try {
        if (this.inventoryId) {
          const inventory = await InventoryProvider.revertInventory(this.inventoryId);
          this.inventory = inventory.data as Inventory;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Инвентаризация отменена', item: '' });
        } else {
          throw new Error();
        }
      } catch (err) {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось отменить инвентаризацию', item: '' });
      } finally {
        this.isRevertInventoryModal = false;
      }
    }

    async deleteInventory() {
      try {
        if (this.inventoryId) {
          await InventoryProvider.deleteInventory(this.inventoryId);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Инвентаризация удалена', item: '' });
          this.$router.replace({ name: 'inventory' });
        } else {
          throw new Error();
        }
      } catch (err) {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось отменить инвентаризацию', item: '' });
        this.isDeleteInventoryModal = false;
      }
    }

    async deleteInventoryPart(inventoryPartId: number) {
      try {
        await InventoryProvider.deleteInventoryPart(inventoryPartId);
        this.tableData = this.tableData.filter((elem) => elem.id !== inventoryPartId);
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар удален', item: '' });
      } catch (err) {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось удалить товар', item: '' });
      }
    }

    querySearch(queryString, cd) {
      const now = new Date().getTime();
      this.lastInputing = now;
      setTimeout(() => {
        PartProvider.getPartUniversalSearch(1, { query: queryString, category: 1 }, 10)
          .then((res) => {
            if (this.lastInputing === now) {
              const result = res.data.map((elem) => ({ value: elem.name, data: elem }));
              if (result.length === 0) {
                cd([]);
              } else if (result.length === 1) {
                this.selectPartFromAutocomplete(result[0].data);
                cd([]);
              } else {
                cd(result);
              }
            }
          }).catch((err) => {
            console.error(err);
          });
      }, 500);
    }

    async selectPartFromAutocomplete(part) {
      const selectedPartArr: TableItem[] = [part].map((i) => ({
        id: i.id,
        name: i.name,
        part_id: i.id,
        amount: 1,
        price: i.posting_price === null
          ? 0
          : i.posting_price,
        art: i.art,
        barcodes: i.barcodes,
      }));
      const selectedPart: TableItem = selectedPartArr[0];
      const index = this.tableData.findIndex((elem) => elem.part_id === selectedPart.part_id);
      if (index > -1) {
        this.tableData[index].number = Number(this.tableData[index].number) + 1;
        await this.updateInvventoryPart(index, selectedPart);
      } else if (selectedPart) {
        await this.addNewInventoryPart(selectedPart);
      }
      this.searchPart = '';
    }

    async sendPostingPart(item) {
      const index = this.tableData.findIndex((elem) => elem.part_id === item.part_id);
      if (index > -1) {
        this.tableData[index].number = Number(this.tableData[index].number) + Number(item.amount);
        await this.updateInvventoryPart(index, item);
      } else {
        await this.addNewInventoryPart(item);
      }
    }

    submitIventoryCommentForm() {
      (this.$refs.commentForm as Form).validate((valid) => {
        if (valid) {
          this.addInventoryComments();
          return true;
        }
        console.error('error submit!!');
        return false;
      });
    }
}
