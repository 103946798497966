import { MutationTree } from 'vuex';
import {
  ADD_CASH_TYPES,

  DELETE_CASH_TYPES, EDIT_CASH_TYPES, GET_CASH_TYPES,

  StateCashTypes,
} from './types';

const mutations: MutationTree<StateCashTypes> = {

  [GET_CASH_TYPES](state, { item, pageCount }) {
    state.cashTypeUser = item;
    state.cashTypePageCount = pageCount;
  },

  [ADD_CASH_TYPES](state, { item }) {
    state.cashTypeUser.push(item);
  },

  [EDIT_CASH_TYPES](state, { item }) {
    const index = state.cashTypeUser.findIndex((i) => i.id === item.id);
    state.cashTypeUser.splice(index, 1, item);
  },

  [DELETE_CASH_TYPES](state, { item }) {
    const index = state.cashTypeUser.findIndex((i) => i.id === item.id);
    state.cashTypeUser.splice(index, 1);
  },
};

export default mutations;
