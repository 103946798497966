import BaseProvider from '../base-provider';

class ClientsProvider extends BaseProvider {
  async getClientList(page: number, form: ClientSearchFrom = {
    name: '', status_id: null, phone: '', source_id: null,
  }) {
    const searchForm: string = this.buildUrl({ ClientSearchForm: form });
    const res = await this.request('GET', `/client/index?page=${page}${searchForm}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getViewClient(id) {
    const expand: string[] = ['feedback'];
    const res = await this.request('GET', `/client/view?id=${id}&expand=${expand}`);
    return res;
  }

  async universalSearch(page, form: ClientUniversalSearch) {
    const searchForm: string = this.buildUrl({ ClientUniversalSearchForm: form });
    const res = await this.request('GET', `/client/universalsearch?page=${page}${searchForm}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async filterClient(page, item) {
    const searchForm: string = this.buildUrl({ ClientSearchForm: item });
    const res = await this.request('GET', `/client/index?page=${page}${searchForm}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getInnClient(inn) {
    const res = await this.request('GET', `/client/findinn?inn=${inn}`);
    return res;
  }

  async sendClientSetVip(item) {
    const data: object = { ClientVip: { client_id: item } };
    const res = await this.request('POST', '/client/setvip', data);
    return res;
  }

  async sendClientSetRegular(item) {
    const data: object = { ClientRegular: { client_id: item } };
    const res = await this.request('POST', '/client/setregular', data);
    return res;
  }

  async sendClientSetBlacklist(item) {
    const data: object = { ClientBlackList: item };
    const res = await this.request('POST', '/client/setblacklist', data);
    return res;
  }

  async sendClientCheckBlacklist(item) {
    const data: object = { CheckClientBlackListForm: item };
    const res = await this.request('POST', '/client/checkblacklisted', data);
    return res;
  }

  async sendClientPerson(item) {
    const data: object = { ClientPerson: item };
    const res = await this.request('POST', '/client/addperson', data);
    return res;
  }

  async sendClientCompany(item) {
    const data: object = { ClientCompany: item };
    const res = await this.request('POST', '/client/addcompany', data);
    return res;
  }

  async changeClientPerson(item) {
    const data: object = { ClientPerson: item };
    const res = await this.request('POST', `/client/updateperson?id=${item.id}`, data);
    return res;
  }

  async changeClientCompany(item) {
    const data: object = { ClientCompany: item };
    const res = await this.request('POST', `/client/updatecompany?id=${item.id}`, data);
    return res;
  }

  async deleteClient(id) {
    const res = await this.request('POST', `/client/delete?id=${id}`);
    return res;
  }
}

export default new ClientsProvider();

export interface ClientUniversalSearch {
    query: string;
}

export interface ClientSearchFrom {
    name: string;
    status_id: number | null;
    phone: string;
    source_id: number | null;
}
