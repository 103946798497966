import Vddl from 'vddl';
import Vue from 'vue';
import { Form } from 'element-ui';
import { Component, Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { Action, Getter } from 'vuex-class';
import Child from './child';
import DialogsCategory from './dialog';
import { DataForm, ViewDialog, Category as NewCategory } from './types';

Vue.use(Vddl);

@Component({
  template: require('./category.html'),
  components: {
    childTree: Child,
    draggable,
    'dialog-category': DialogsCategory,
  },
})

class Category extends Vue {
    @Prop({ default: () => false }) controlBtn!: boolean;

    @Prop({ default: () => false }) switchVericalScroll!: boolean;

    @Prop({ default: () => 'part' }) typeCategory!: string;

    @Prop() openCategories!: string[];

    @Prop() categoryList!: object[];

    @Action sendPartCategory;

    @Action changePartCategory;

    @Action deletePartCategory;

    @Action getPartCategory;

    @Action sendWorkCategory;

    @Action changeWorkCategory;

    @Action deleteWorkCategory;

    @Getter categoriesMainList;

    @Getter newCategory;

    @Getter btnloader;

    dialogAdd = false;

    dialogEdit = false;

    dialogDelete = false;

    view_dialog: ViewDialog = { view: false, type: '' };

    itemActive = 1;

    paddingItem = 10;

    category: object[] = [];

    dataQuery = '';

    dataForm: DataForm = { name: '', parent_id: null };

    listCategories: object[] = [];

    valueOpenCategories: string[] = [];

    mounted() {
      this.$root.$on('categoryItemActive', (i) => { this.itemActive = this.itemActive === i ? 1 : i; });
      if (this.$route.query.category_id) this.itemActive = Number(this.$route.query.category_id);
      this.viewDialogAddCategory();
      this.viewDialogEditCategory();
      this.viewDialogDeleteCategory();

      if (this.openCategories) {
        this.valueOpenCategories = this.openCategories;
      } else if (window.innerWidth >= 1000) {
        this.valueOpenCategories.push('1');
        this.$emit('openCategories', true);
      }
    }

    get categorySelectList() {
      return this.flattery(this.categoriesMainList);
    }

    /**
     * Преобразует массив объектов в плоский набор
     * @param parent Массив объектов категорий
     * @param previousRoute строка предыдущего пути
     */
    private flattery(parent, previousRoute = '') {
      const result: object[] = [];
      parent.forEach((element) => {
        const route = (previousRoute !== '') ? `${previousRoute} / ${element.title}` : element.title;
        if (this.dataForm.id !== element.key) {
          result.push({ id: element.key, value: route });
          if (element.children && element.children.length > 0) {
            result.push(...this.flattery(element.children, route));
          }
        }
      });
      return result;
    }

    searchCategory(value, text) {
      const searchList: object[] = [];
      value.forEach((item) => {
        if (item.title.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          searchList.push(item);
        } else if (item.children.length) {
          const childItem = this.searchCategory(item.children, text);
          const parentItem = {};
          if (childItem.length) {
            Object.keys(item).forEach((key) => {
              if (key) {
                switch (key) {
                  case 'expanded':
                    parentItem[key] = true;
                    break;
                  case 'children':
                    parentItem[key] = childItem;
                    break;
                  default:
                    parentItem[key] = item[key];
                    break;
                }
              }
            });
            searchList.push(parentItem);
          }
        }
      });
      return searchList;
    }

    requestCategory() {
      const formCategory = this.$refs.formCategory as DialogsCategory;
      const refForm = formCategory.formCategory as Form;
      refForm.validate((valid) => {
        if (valid) {
          switch (this.typeCategory) {
            case 'part':
              switch (this.view_dialog.type) {
                case 'add': this.sendPartCategory(this.dataForm); break;
                case 'edit': this.changePartCategory(this.dataForm); break;
                case 'remove': {
                  const form = { ...this.dataForm };
                  form.deleteInternal = this.dataForm.deleteInternal ? 1 : 0;
                  this.deletePartCategory(form);
                  break; }
                default: break;
              }
              break;
            case 'work':
              switch (this.view_dialog.type) {
                case 'add': this.sendWorkCategory(this.dataForm); break;
                case 'edit': this.changeWorkCategory(this.dataForm); break;
                case 'remove': {
                  const form = { ...this.dataForm };
                  form.deleteInternal = this.dataForm.deleteInternal ? 1 : 0;
                  this.deleteWorkCategory(form);
                  break; }
                default: break;
              }
              break;
            default: break;
          }
          return true;
        }
        return false;
      });
    }

    clearFormChangeCategory() {
      this.dataForm = { name: '', deleteInternal: false };
    }

    viewDialogChangeCategory(type) {
      this.view_dialog.type = type;
      this.view_dialog.view = true;
    }

    addSubgroupCategory() {
      this.dataForm = { name: '', parent_id: 1 };
      this.viewDialogChangeCategory('add');
    }

    viewDialogAddCategory() {
      this.$root.$on('dialogAddCategory', (item) => {
        this.dataForm = { name: '', parent_id: item.key };
        this.viewDialogChangeCategory('add');
      });
    }

    viewDialogEditCategory() {
      this.$root.$on('dialogEditCategory', (item) => {
        const parent_id = item.parent_id ? item.parent_id : null;
        this.dataForm = { name: item.title, parent_id, id: item.key };
        this.listCategories = this.flattery(this.categoriesMainList);
        this.viewDialogChangeCategory('edit');
      });
    }

    viewDialogDeleteCategory() {
      this.$root.$on('dialogDeleteCategory', (item) => {
        this.dataForm = { id: item.key, deleteInternal: false };
        this.viewDialogChangeCategory('remove');
      });
    }

    closeDialogCategory() {
      this.listCategories = [];
      this.clearFormChangeCategory();
    }

    @Watch('newCategory')
    onChange(category: NewCategory) {
      if (category.type === 'remove') this.$emit('deleteInternal');
      if (category.type === 'edit') this.$emit('editCategory');
      this.view_dialog.view = false;
    }
}

export { Category as default };
