import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Cashbox } from '@/classes';
import DialogCashOperation from '../../views/cash-view/dialog/cash-operation';
import Numbers from '../forms/numbers';

interface Item {
  users;
  point;
  sum;
  id;
  commission;
}
interface Form {
  from_cashbox_id?;
  cashbox_id?;
  comment: string;
}
@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    'dialog-cash-operation': DialogCashOperation,
  },
})
export default class CashListItem extends Vue {
    @Prop({ default: () => ({}) }) item!: Item;

    @Prop({ default: () => [] }) point!: object[];

    @Prop() cash_list!: Cashbox[];

    @Action deleteCashItem;

    @Getter cashValidate;

    @Getter btnloader;

    @Getter user;

    dialogDelete = false;

    form: Form = {
      comment: '',
    };

    typeBtn: number | null = null;

    label = '';

    dialog = false;

    get userHasAccessToCashbox() {
      return this.item.users.filter((x) => x.id === this.user.id).length > 0;
    }

    get point_name() {
      if (this.item.point !== undefined) return this.item.point.name;
      return '';
    }

    get controlEditButtuon() {
      return this.user.rights.can_manage_cashboxes;
    }

    get controlDeleteButtuon() {
      return !(!this.user.rights.can_manage_cashboxes || this.item.sum < 0 || this.item.sum > 0);
    }

    get filterCashList() {
      if (this.cash_list) {
        return this.cash_list.filter((item) => item.id !== this.item.id);
      }
      return [];
    }

    get commissionDescription() {
      if (this.item.commission > 0) {
        return `Комиссия ${this.item.commission}%`;
      }
      return 'Без комиссии';
    }

    onDialogDelete() { this.dialogDelete = true; }

    onDialog(label, type) {
      this.typeBtn = type;
      this.label = label;
      switch (this.typeBtn) {
        case 1: this.form.from_cashbox_id = this.item.id; break;
        default: this.form.cashbox_id = this.item.id;
      }

      this.dialog = true;
    }

    closeDialogCash() {
      this.dialog = false;
    }

    handleUpdateCashList() {
      this.closeDialogCash();
      this.$emit('updateSumCashList');
    }
}
