import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RepairColumn as Column } from '@/types';

@Component({
  template: require('./index.html'),
  components: {

  },
})
export default class SettingTableRepairs extends Vue {
    @Prop() activeColumn!: Column[];

    @Prop() inactiveColumn!: Column[];

    selectedActiveСolumn: object[] = [];

    selectedInActiveColumn: object[] = [];

    onChangeSelectedActiveColumn() {
      this.selectedActiveСolumn = this.activeColumn.filter((active) => active.active);
    }

    sortColumnsAsTransfer = (a, b) => {
      const nameA = a.label.toLowerCase();
      const nameB = b.label.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    }

    onChangeSelectedInactiveColumn() {
      this.selectedInActiveColumn = this.inactiveColumn.filter((active) => active.active);
    }

    transferColumnAtActive() {
      this.$emit('transferColumnAtActive', this.selectedInActiveColumn);
      this.selectedInActiveColumn = [];
    }

    transferColumnAtInactive() {
      this.$emit('transferColumnAtInactive', this.selectedActiveСolumn);
      this.inactiveColumn.sort(this.sortColumnsAsTransfer);
      this.selectedActiveСolumn = [];
    }

    transferColumnUp(key) {
      const elementSelectedIndex = this.activeColumn.findIndex((element) => element.key === key);
      const elementUp = elementSelectedIndex === 0
        ? this.activeColumn[this.activeColumn.length - 1]
        : this.activeColumn[elementSelectedIndex - 1];
      const elementSelected = this.activeColumn[elementSelectedIndex];

      const elementUpIndex = this.activeColumn.findIndex(
        (element) => element.key === elementUp.key,
      );
      this.activeColumn.splice(elementUpIndex, 1, elementSelected);
      this.activeColumn.splice(elementSelectedIndex, 1, elementUp);
    }

    transferColumnDown(key) {
      const elementSelectedIndex = this.activeColumn.findIndex((element) => element.key === key);
      const elementDown = elementSelectedIndex === this.activeColumn.length - 1
        ? this.activeColumn[0]
        : this.activeColumn[elementSelectedIndex + 1];
      const elementSelected = this.activeColumn[elementSelectedIndex];

      const elementDownIndex = this.activeColumn.findIndex(
        (element) => element.key === elementDown.key,
      );
      this.activeColumn.splice(elementDownIndex, 1, elementSelected);
      this.activeColumn.splice(elementSelectedIndex, 1, elementDown);
    }
}
