import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Breadcrumb } from '@/types';
import StuffForm from '../../components/stuff-form';

@Component({
  template: require('./index.html'),
  components: {
    'stuff-form': StuffForm,
  },
})
export default class StuffAddPage extends Vue {
    @Action addBreadcrumb;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Компания', link: '/settings' }, { id: 2, section: 'Сотрудники', link: '/stuff' }, { id: 3, section: 'Новый сотрудник', link: null }];

    item: object = {
      point_id: 1,
      salary_period: 2,
      repair_income_salary_type: 0,
      repair_part_salary_type: 1,
      sell_part_salary_type: 1,
      rights: {},
      cashboxes: [],
      storages: [],
      points: [],
    };

    mounted() {
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Создание сотрудника';
    }
}
