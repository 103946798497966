import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
  StorageTransferSearchForm,
  Response,
  Storage,
  User,
} from '@/types';
import StorageProvider from '@/api/providers/storage';
import StuffProvider from '@/api/providers/stuff';

@Component({
  template: require('./index.html'),
})

export default class StorageTransferFilterForm extends Vue {
  choosedDate: number[] = [];

  filterForm: StorageTransferSearchForm = {
    user_id: null,
    in_storage_id: null,
    out_storage_id: null,
  }

  storages: Storage[] = [];

  stuff: User[] = [];

  range_date: object = {
    shortcuts: [
      {
        text: 'За вчера',
        onClick(picker) {
          const end = new Date();
          end.setHours(23, 59, 59, 0);
          end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
          const start = new Date();
          start.setHours(0, 0, 0, 0);
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'За неделю',
        onClick(picker) {
          const end = new Date();
          end.setHours(23, 59, 59, 0);
          const start = new Date();
          start.setHours(0, 0, 0, 0);
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'За месяц',
        onClick(picker) {
          const end = new Date();
          end.setHours(23, 59, 59, 0);
          const start = new Date();
          start.setHours(0, 0, 0, 0);
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit('pick', [start, end]);
        },
      }, {
        text: 'За 3 месяца',
        onClick(picker) {
          const end = new Date();
          end.setHours(23, 59, 59, 0);
          const start = new Date();
          start.setHours(0, 0, 0, 0);
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit('pick', [start, end]);
        },
      },
    ],
  };

  mounted() {
    this.getStorageList();
    this.getSuffList();
  }

  async getStorageList() {
    let page = 1;
    let storages: object[] = [];
    let pageCount = 1;

    await StorageProvider.getStorageList(page)
      .then((res) => {
        storages = res.data;
        pageCount = res.pageCount;
      })
      .catch((err) => {
        throw new Error(err);
      });

    if (pageCount > 1) {
      const promiseResults: object[] = [];
      for (page; page <= pageCount; page++) {
        promiseResults.push(StorageProvider.getStorageList(page));
      }

      await Promise.all(promiseResults)
        .then((results) => {
          (results as Response[]).forEach((result) => {
            storages = [...storages, ...result.data as object[]];
          });
        });
    }

    this.storages = storages as Storage[];
  }

  async getSuffList() {
    let page = 1;
    let stuff: object[] = [];
    let pageCount = 1;

    await StuffProvider.getStuffList(page)
      .then((res) => {
        stuff = res.data;
        pageCount = res.pageCount;
      })
      .catch((err) => {
        throw new Error(err);
      });

    if (pageCount > 1) {
      const promiseResults: object[] = [];
      for (page; page <= pageCount; page++) {
        promiseResults.push(StuffProvider.getStuffList(page));
      }

      await Promise.all(promiseResults)
        .then((results) => {
          (results as Response[]).forEach((result) => {
            stuff = [...stuff, ...result.data as object[]];
          });
        });
    }

    this.stuff = stuff as User[];
  }

  async searchTransfer() {
    this.filterForm.date_start = null;
    this.filterForm.date_end = null;
    if (this.choosedDate?.length > 0) {
      const start = new Date(this.choosedDate[0]);
      start.setHours(0, 0, 0, 0);
      const end = new Date(this.choosedDate[1]);
      end.setHours(23, 59, 59, 0);
      this.filterForm.date_start = Math.trunc(start.getTime() / 1000);
      this.filterForm.date_end = Math.trunc(end.getTime() / 1000);
    }
    this.$emit('changeFilter', this.filterForm);
  }

  clearFilterForm() {
    Object.keys(this.filterForm).forEach((key) => {
      this.filterForm[key] = null;
    });
    this.choosedDate = [];
    this.searchTransfer();
  }
}
