import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RepairPayment } from '@/types';

@Component({
  template: require('./payments.html'),
})
export default class FormPayments extends Vue {
    @Prop() dataPayments!: RepairPayment;

    @Prop() cashboxes!: object[];

    validateSum = (rule, value, callback) => {
      if (value === null || value === undefined) {
        callback();
      } else {
        const floatVal = Number.parseFloat(value);
        if (floatVal < 0) {
          callback(new Error('Сумма к оплате не может быть меньше 0'));
        } else {
          callback();
        }
      }
    }

    rulesPay: object = {
      sum: [
        {
          required: false, validator: this.validateSum, trigger: 'change', type: 'number',
        },
      ],
      cashbox_id: [{ required: true, validator: this.validateCashbox, trigger: 'change' }],
    };

    get refPayments() { return this.$refs.formPayments; }

    validateCashbox(rule, value, callback) {
      if (this.dataPayments.sum === 0) callback();
      if (value === null || value === '') {
        callback(new Error('Заполните поле «Касса»'));
      } else {
        callback();
      }
    }

    resetForm() {
      (this.$refs.formPayments as Form).resetFields();
    }
}
