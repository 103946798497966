import RepairProvider from '../../../api/providers/repair';

export default function sendRepairStart(this, id) {
  RepairProvider.sendRepairStart(id)
    .then((res) => {
      this.updatedRepairAfterChangeStatus(res.data);
      this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Заказ переведён в статус "В работе"', item: '' });
    }).catch((err) => {
      console.error(err);
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !', item: '' });
    });
}
