const state = {
  stuff: [],
  viewStuff: {},
  validStuff: {},
  stuffPageCount: null,
  stuffPageCurrent: null,
  salaryPeriodTypeList: {},
  sellPartSalaryTypeList: {},
  repairPartSalaryType: {},
};

export default state;
