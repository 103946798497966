const state = {
  cashList: [],
  cashListPageCount: null,
  cashbox: null,
  cashValidate: {},
  cashOperationsList: [],
  cashOperationPageCount: null,
  cashOperationCountIn: null,
  cashOperationCountOut: null,
  cashOperationTotalIn: null,
  cashOperationTotalOut: null,
  cashViewDetail: '',
  cashOperationIn: {},
  cashOperationOut: {},
  cashOperationTransfer: {},
  cashOperationDelete: {},
};

export default state;
