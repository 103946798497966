import { MutationTree } from 'vuex';
import {
  DELETE_BUGOPTION_LIST, GET_BUGOPTION_LIST,
  SEND_BUGOPTION_LIST,

  SEND_BUGOPTION_LIST_PRIORITY, StateBugOption,

  UPDATE_BUGOPTION_LIST,
} from './types';

const mutations: MutationTree<StateBugOption> = {
  [GET_BUGOPTION_LIST](state, { item }) {
    state.bugoptionList = item;
  },

  [SEND_BUGOPTION_LIST](state, { item }) {
    state.addBugOptionList = item;
  },

  [SEND_BUGOPTION_LIST_PRIORITY](state, { item }) {
    state.sendBugOptionPriority = item;
  },

  [UPDATE_BUGOPTION_LIST](state, { item }) {
    state.updateBugOptionList = item;
  },

  [DELETE_BUGOPTION_LIST](state, { item }) {
    state.deleteBugOptionList = item;
  },
};

export default mutations;
