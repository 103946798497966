import RepairProvider from '../../../api/providers/repair';

export default function sendRepairDeclineAgreement(this, id) {
  RepairProvider.sendRepairDeclineAgreement(id)
    .then((res) => {
      this.updatedRepairAfterChangeStatus(res.data);
      this.$store.commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} переведён в статус "Готово" (отказ клиент)`, item: '' });
    }).catch((err) => {
      console.error(err);
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !', item: '' });
    });
}
