import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import ViewDate from '@/components/forms/date';
import ViewUserName from '@/components/forms/user-name';
import RepairLog from '@/types/repair-log';

@Component({
  template: require('./log-item.html'),
  components: {
    'repair-date': ViewDate,
    'user-name': ViewUserName,
  },
})
export default class LogItem extends Vue {
    @Prop({ required: true })
    private logItem!: RepairLog;

    get title() {
      if (this.logItem.is_transfer_completed) {
        return `Находится в ${this.logItem.transfer_from_point?.name}`;
      }
      return `Перемещается из ${this.logItem.transfer_from_point?.name} в ${this.logItem.transfer_to_point?.name}`;
    }

    get styles() {
      const borderStyle = this.logItem.is_transfer_completed ? 'solid' : 'dotted';
      const color = this.logItem.transfer_to_point?.color
            ?? this.logItem.transfer_from_point?.color;
      return {
        borderRadius: this.logItem.is_transfer_completed ? '6px' : '0',
        borderLeftWidth: this.logItem.is_transfer_completed ? null : '6px',
        borderLeftStyle: borderStyle,
        borderLeftColor: color,
        backgroundColor: this.logItem.is_transfer_completed ? color : null,
      };
    }
}
