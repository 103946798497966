import { ActionTree } from 'vuex';
import BugOptionProvider from '../../api/providers/noteoption';
import { NoteStateOption } from './types';

const actions: ActionTree<NoteStateOption, object> = {

  getNoteOptionList({ commit }, page) {
    BugOptionProvider.getNoteOptionList(page)
      .then((res) => {
        commit('GET_NOTEOPTION_LIST', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  sendNoteOptionList({ commit }, item) {
    BugOptionProvider.sendNoteOptionList(item)
      .then((res) => {
        commit('SEND_NOTEOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendNoteOptionSetPrioroty({ commit }, item) {
    BugOptionProvider.sendNoteOptionSetPrioroty(item)
      .then((res) => {
        commit('SEND_NOTEOPTION_LIST_PRIORITY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Раскладка успешно сохранена', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  changeNoteOptionList({ commit }, item) {
    BugOptionProvider.changeNoteOptionList(item)
      .then((res) => {
        commit('UPDATE_NOTEOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно обновлен', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  deleteNoteOptionList({ commit }, item) {
    BugOptionProvider.deleteNoteOptionList(item)
      .then((res) => {
        commit('DELETE_NOTEOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно удалено', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

};

export default actions;
