import { ActionTree } from 'vuex';
import BillingProvider from '../../api/providers/billing';
import {
  StateBilling,
} from './types';

const actions: ActionTree<StateBilling, object> = {

  getSmsOffers({ commit }) {
    BillingProvider.getSmsOffers()
      .then((res) => {
        commit('GET_SMS_OFFERS', { item: res.data });
      })
      .catch((err) => console.log(err.response.data));
  },

  getBalance({ commit }) {
    BillingProvider.getBalance()
      .then((res) => {
        commit('GET_BALANCE', { item: res.data });
      })
      .catch((err) => console.log(err.response.data));
  },

  getBillingAcquirers({ commit }) {
    BillingProvider.getBillingAcquirers()
      .then((res) => {
        commit('GET_ACQUIRERS', { item: res.data });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  },

  getBillingTariff({ commit }) {
    BillingProvider.getBillingTariff()
      .then((res) => {
        commit('GET_TARIFF', { item: res.data });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  },

  sendBuySms({ commit }, item) {
    commit('BTN_LOADER', true);
    BillingProvider.sendBuySms(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('SEND_BUY_SMS', { item: res.data });
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        console.log(err.response.data);
      });
  },

  sendBuyLicense({ commit }, item) {
    commit('BTN_LOADER', true);
    BillingProvider.sendBuyLicense(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('SEND_BUY_LICENSE', { item: res.data });
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        console.log(err.response.data);
      });
  },

};

export default actions;
