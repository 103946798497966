import { PartAmountCellForm } from '@/types';
import BaseProvider from '../base-provider';

class PartProvider extends BaseProvider {
  async getPartList(page, item, perPage) {
    const searchForm: string = this.buildUrl({ PartSearchForm: item });
    const expand: string[] = ['posting_price', 'amounts', 'barcodes', 'images'];
    const res = await this.request('GET', `/part/index?page=${page}&expand=${expand}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-current-page'],
      amountControl: res.headers['x-storage-amount-control'],
    };
  }

  async getPartUniversalSearch(page, item, perPage) {
    const searchForm: string = this.buildUrl({ UniversalPartSearchForm: item });
    const expand: string[] = ['posting_price', 'amounts', 'barcodes', 'images'];

    const res = await this.request('GET', `/part/universalsearch?page=${page}&expand=${expand}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-current-page'],
      amountControl: res.headers['x-storage-amount-control'],
    };
  }

  async getPartAmount(page, item, perPage) {
    const searchForm: string = this.buildUrl({ PartAmountSearchForm: item });
    const expand: string[] = ['part.posting_price'];
    const res = await this.request('GET', `/partamount/index?page=${page}&expand=${expand}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-page-current'],
      amountControl: res.headers['x-storage-amount-control'],
    };
  }

  async setPartAmount(partAmount: PartAmountCellForm) {
    const data = {
      PartAmountCellForm: partAmount,
    };
    const res = await this.request('POST', '/partamount/setcell', data);
    return {
      data: res.data,
    };
  }

  async getViewPart(id) {
    const expand: string[] = ['posting_price', 'amounts', 'barcodes', 'images'];
    const res = await this.request('GET', `/part/view?id=${id}&expand=${expand}`);
    return res;
  }

  async getVatList() {
    const res = await this.request('GET', '/part/vatlist');
    return res;
  }

  async getWarrantTypeList() {
    const res = await this.request('GET', '/part/warranttypelist');
    return res;
  }

  async getRewardTypeList() {
    const res = await this.request('GET', '/part/rewardtypelist');
    return res;
  }

  async getBarcode() {
    const res = await this.request('GET', '/part/generatebarcode');
    return res;
  }

  async getPartLogList(item) {
    const res = await this.request('GET', `/part/log?part_id=${item.part}&storage_id=${item.storage}&page=${item.page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendPart(item) {
    const data: object = { PartForm: item };
    const expand: string[] = ['posting_price'];
    const res = await this.request('POST', `/part/add?expand=${expand}`, data);
    return res;
  }

  async sendCopyPart(item) {
    const data: object = { PartCloneForm: item };
    const expand: string[] = ['posting_price', 'amounts', 'barcodes', 'images'];
    const res = await this.request('POST', `/part/clone?expand=${expand}`, data);
    return res;
  }

  async sendPartImage(item) {
    const data = new FormData();
    data.append('PartImageUploadForm[part_id]', item.part_id);
    data.append('PartImageUploadForm[file]', item.file);
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/part/setimage', data, contentType);
    return res;
  }

  async sendBarcode(item) {
    const data: object = { PartBarcode: item };
    const res = await this.request('POST', '/part/addbarcode', data);
    return res;
  }

  async changePart(item) {
    const data: object = { Part: item };
    const expand: string[] = ['posting_price', 'amounts', 'barcodes', 'images'];
    const res = await this.request('POST', `/part/update?id=${item.id}&expand=${expand}`, data);
    return res;
  }

  async deleteBarcode(id) {
    const res = await this.request('POST', `/part/deletebarcode?id=${id}`);
    return res;
  }

  async deletePart(id) {
    const res = await this.request('POST', `/part/delete?id=${id}`);
    return res;
  }

  async revertPart(id) {
    const res = await this.request('POST', `/part/revertdelete?id=${id}`);
    return res;
  }
}

export default new PartProvider();
