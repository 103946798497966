import { ActionTree } from 'vuex';
import BugOptionProvider from '../../api/providers/stateoption';
import { StateStateOption } from './types';

const actions: ActionTree<StateStateOption, object> = {

  getStateOptionList({ commit }, page) {
    BugOptionProvider.getStateOptionList(page)
      .then((res) => {
        commit('GET_STATEOPTION_LIST', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  sendStateOptionList({ commit }, item) {
    BugOptionProvider.sendStateOptionList(item)
      .then((res) => {
        commit('SEND_STATEOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendStateOptionSetPrioroty({ commit }, item) {
    BugOptionProvider.sendStateOptionSetPrioroty(item)
      .then((res) => {
        commit('SEND_STATEOPTION_LIST_PRIORITY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Раскладка успешно сохранена', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  changeStateOptionList({ commit }, item) {
    BugOptionProvider.changeStateOptionList(item)
      .then((res) => {
        commit('UPDATE_STATEOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно обновлен', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  deleteStateOptionList({ commit }, item) {
    BugOptionProvider.deleteStateOptionList(item)
      .then((res) => {
        commit('DELETE_STATEOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно удалено', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

};

export default actions;
