import { AxiosResponse } from 'axios/index.d';
import { Document } from '@/types';
import BaseProvider from '../base-provider';

class DocumentProvider extends BaseProvider {
  async getAllDocuments(item, perPage = 50): Promise<Array<Document>> {
    let page = 1;
    let totalPages = 1;
    let resultPack: Array<Document> = [];

    const pack = await this.getDocumentList(page, item, perPage);
    resultPack = [...resultPack, ...pack.data];
    page += 1;
    totalPages = Number(pack.pageCount);

    if (totalPages > 1) {
      const promiseRequests: object[] = [];
      for (page; page <= totalPages; page++) {
        promiseRequests.push(this.getDocumentList(page, item, perPage));
      }
      await Promise.all(promiseRequests)
        .then((results) => {
          (results as AxiosResponse[]).forEach((documentPage) => {
            resultPack = [...resultPack, ...documentPage.data];
          });
        });
    }
    return resultPack;
  }

  async getDocumentList(page, item, perPage): Promise<ApiDocumentListResponse> {
    const searchForm: string = this.buildUrl({ DocumentSearchForm: item });
    const res = await this.request('GET', `/document/index?page=${page}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getViewDocument(id) {
    const res = await this.request('GET', `/document/view?id=${id}`);
    return res;
  }

  async addDocument(item) {
    const data: object = { DocumentTemplate: item };
    const res = await this.request('POST', '/document/add', data);
    return res;
  }

  async updateDocument(item) {
    const data: object = { DocumentTemplate: item };
    const res = await this.request('POST', `/document/update?id=${item.id}`, data);
    return res;
  }

  async deleteDocument(id) {
    const res = await this.request('POST', `/document/delete?id=${id}`);
    return res;
  }
}

export default new DocumentProvider();

export interface ApiDocumentListResponse {
    data: Array<Document>;
    pageCount: number;
}
