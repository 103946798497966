import Vue from 'vue';
import { Form } from 'element-ui';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb } from '@/types';
import ClientSourceProvider from '../../api/providers/client-source';
import ClientsProvider from '../../api/providers/clients';
import ClientCompanyForm from '../../components/client-company-form';
import ClientUserForm from '../../components/client-user-form';

interface Client {
  sms_agreement: boolean;
  phone?;
}
interface Company {
  address;
  inn;
  kpp;
  manager_name;
  manager_post;
  name;
  ogrn;
  okpo;
  sms_agreement;
  phone?;
}
@Component({
  template: require('./index.html'),
  components: {
    'client-user-form': ClientUserForm,
    'client-company-form': ClientCompanyForm,
  },
})
export default class ClientsAddPage extends Vue {
    @Prop({ default: () => false }) redirectionClients!: boolean;

    @Prop({ default: () => false }) activeBreadcrumb!: boolean;

    @Prop({ default: () => false }) flagSelectedDuplicateClient!: boolean;

    @Action addBreadcrumb;

    @Getter settings;

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Клиенты', link: '/clients' },
      { id: 3, section: 'Новый клиент', link: null },
    ];

    active_type = '1';

    user: Client = {
      sms_agreement: false,
    };

    company: Company = {
      address: '',
      inn: null,
      kpp: '',
      manager_name: '',
      manager_post: '',
      name: '',
      ogrn: '',
      okpo: '',
      sms_agreement: false,
    };

    client_source_list: object[] = [];

    loading = false;

    loading_client: object = {};

    user_duplicate_clients: object[] = [];

    company_duplicate_clients: object[] = [];

    // api

    getClientSourceList(page = 1) {
      ClientSourceProvider.getClientSourceList(page)
        .then((res) => {
          this.client_source_list = [...this.client_source_list, ...res.data];
          if (page < res.pageCount) {
            this.getClientSourceList(page + 1);
          }
        })
        .catch((err) => console.error(err));
    }

    sendClientPerson(item) {
      this.loading = true;
      ClientsProvider.sendClientPerson(item)
        .then((res) => {
          this.loading = false;
          this.loading_client = res.data;
          this.createNewClient(res.data, 1);

          if (!this.redirectionClients) {
            if (Object.keys(res.data).length) {
              this.$router.push(`/clients/view/${res.data.id}`);
            } else {
              this.$router.push('/clients');
            }
          }
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: `${res.data.last_name} ${res.data.first_name} ${res.data.middle_name}` });
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.$emit('closeModal', false);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    sendClientCompany(item) {
      this.loading = true;
      ClientsProvider.sendClientCompany(item)
        .then((res) => {
          this.loading = false;
          this.loading_client = res.data;

          this.createNewClient(res.data, 2);

          if (!this.redirectionClients) {
            if (Object.keys(res.data).length) {
              this.$router.push(`/clients/view/${res.data.id}`);
            } else {
              this.$router.push('/clients');
            }
          }
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: res.data.name });
        })
        .catch((err) => {
          console.error(err);
          this.$emit('closeModal', false);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading = false;
        });
    }

    createNewClient(data, type) {
      this.$emit('newClient', data);

      if (type === 1) {
        this.clearPerson();
        this.user_duplicate_clients = [];
      } else {
        this.clearCompany();
        this.company_duplicate_clients = [];
      }

      this.$emit('closeModal', false);
    }

    get clientCompany() {
      return (this.$refs.companyForm as ClientCompanyForm).clientUserCompany as Form;
    }

    get clientUser() {
      return (this.$refs.userForm as ClientUserForm).clientUserForm as Form;
    }

    get activeTabs() {
      return Number(this.active_type);
    }

    clearCompany() {
      this.clientCompany.resetFields();
    }

    clearPerson() {
      this.clientUser.resetFields();
    }

    mounted() {
      if (Object.keys(this.settings).length !== 0 && this.settings.client_default_sms_agreement) {
        this.company.sms_agreement = this.settings.client_default_sms_agreement;
        this.user.sms_agreement = this.settings.client_default_sms_agreement;
      }
      document.title = 'Новый клиент';
      this.client_source_list = [];
      this.getClientSourceList(1);
      if (!this.activeBreadcrumb) this.addBreadcrumb(this.breadcrumb);
    }

    checkClientOnDublicates(type) {
      const phone = type === 1 ? this.user.phone : this.company.phone;

      ClientsProvider.getClientList(1, {
        name: '', status_id: null, phone, source_id: null,
      })
        .then((res) => {
          if (res.data.length) {
            if (type === 1) this.user_duplicate_clients = res.data.slice(0, 5);
            if (type === 2) this.company_duplicate_clients = res.data.slice(0, 5);
          } else if (type === 1) {
            this.sendClientUserForm();
          } else {
            this.sendClientCompanyForm();
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }

    sendClientUserForm() {
      ((this.$refs.userForm as ClientUserForm)
        .clientUserForm as Form)
        .validate((valid) => (valid ? this.sendClientPerson(this.user) : false));
    }

    sendClientCompanyForm() {
      ((this.$refs.companyForm as ClientCompanyForm)
        .clientUserCompany as Form)
        .validate((valid) => (valid ? this.sendClientCompany(this.company) : false));
    }
}
