export default function addCartridges(this) {
  this.$root.$on('addCartridges', (cartridges) => {
    if (this.data_repair.objects.length !== 0 && this.data_repair.objects[0].device_id === null) {
      Object.keys(cartridges).forEach((key) => {
        if (key) {
          this.data_repair.objects[0][key] = cartridges[key];
        }
      });
    } else if (cartridges.vendor && cartridges.model) {
      this.data_repair.objects.push(cartridges);
    }
  });
}
