import { ActionTree } from 'vuex';
import CategoryProvider from '../../api/providers/category';
import { GET_CATEGORY, StateCategory } from './types';

const actions: ActionTree<StateCategory, object> = {

  async getPartCategory({ commit }, type) {
    try {
      const cats = await CategoryProvider.getPartCategory();
      // commit(GET_CATEGORY, {item: options['viewMainCategory']
      // ? cats.data : cats.data[0].children, main: options['viewMainCategory']});
      commit(GET_CATEGORY, { item: cats.data, type });
      return cats.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },

  getWorkCategory({ commit }, type) {
    CategoryProvider.getWorkCategory()
      .then((res) => {
        commit('GET_CATEGORY', { item: res.data, type });
      })
      .catch((err) => console.error(err));
  },

  sendPartCategory({ commit }, item) {
    commit('BTN_LOADER', true);
    CategoryProvider.sendPartCategory(item)
      .then((res) => {
        commit('ADD_CATEGORY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Группа «${res.data.name}» добавлена`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  sendWorkCategory({ commit }, item) {
    commit('BTN_LOADER', true);
    CategoryProvider.sendWorkCategory(item)
      .then((res) => {
        commit('ADD_CATEGORY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Группа «${res.data.name}» добавлена`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  changePartCategory({ commit }, item) {
    commit('BTN_LOADER', true);
    CategoryProvider.changePartCategory(item)
      .then((res) => {
        commit('EDIT_CATEGORY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Группа «${res.data.name}» изменена`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  changeWorkCategory({ commit }, item) {
    commit('BTN_LOADER', true);
    CategoryProvider.changeWorkCategory(item)
      .then((res) => {
        commit('EDIT_CATEGORY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Группа «${res.data.name}» изменена`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  deletePartCategory({ commit }, item) {
    commit('BTN_LOADER', true);
    CategoryProvider.deletePartCategory(item.id, item.deleteInternal)
      .then((res) => {
        commit('DELETE_CATEGORY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Группа «${res.data.name}» удалена`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('GATEGORIES_VALIDATION', err.response.data);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  deleteWorkCategory({ commit }, item) {
    commit('BTN_LOADER', true);
    CategoryProvider.deleteWorkCategory(item.id, item.deleteInternal)
      .then((res) => {
        commit('DELETE_CATEGORY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Группа «${res.data.name}» удалена`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  clearValidate({ commit }) {
    commit('CLEAR_VALIDATE', {});
  },

  clearListCategories({ commit }) {
    commit('CLEAR_CATEGORIES_LIST', {});
  },

};

export default actions;
