import Vue from 'vue';
import { facade } from 'vue-input-facade';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SuggestionProvider from '../../api/providers/suggestion';
import ClientDuplicate from '../client-duplicate';

@Component({
  template: require('./index.html'),
  components: {
    'client-duplicate': ClientDuplicate,
  },
  directives: {
    facade,
  },
})
export default class ClientUserForm extends Vue {
    @Prop({ default: () => true }) redirectOnMainClients!: boolean;

    @Prop() item!: object;

    @Prop() clientSource!: object[];

    @Prop() client!: object;

    @Prop() duplicateClients!: object[];

    @Getter settings;

    value_phone = '';

    get isMaskEnabled(): boolean {
      return typeof this.settings.phone_mask === 'string' && this.settings.phone_mask.length > 0;
    }

    get maskPlaceholder(): string {
      if (this.isMaskEnabled) {
        return this.settings.phone_mask;
      }
      return '';
    }

    get clientUserForm() {
      return this.$refs.formClientUser;
    }

    querySearchSuggestionSurnameClient = (name, cb) => {
      if (name) {
        SuggestionProvider.getSuggestionNameClients(name, 'SURNAME')
          .then((res) => cb(res.data))
          .catch((err) => {
            console.error(err);
            cb([]);
          });
      } else {
        cb([]);
      }
    }

    querySearchSuggestionNameClient = (name, cb) => {
      if (name) {
        SuggestionProvider.getSuggestionNameClients(name, 'NAME')
          .then((res) => cb(res.data))
          .catch((err) => {
            console.error(err);
            cb([]);
          });
      } else {
        cb([]);
      }
    }

    querySearchSuggestionPatronymicClient = (name, cb) => {
      if (name) {
        SuggestionProvider.getSuggestionNameClients(name, 'PATRONYMIC')
          .then((res) => cb(res.data))
          .catch((err) => {
            console.error(err);
            cb([]);
          });
      } else {
        cb([]);
      }
    }

    capitalizeFirstLetter(word, str) {
      if (word) {
        this.item[str] = word[0].toUpperCase() + word.slice(1);
      }
    }

    @Watch('client')
    clients() {
      if (this.redirectOnMainClients) { this.$router.push({ name: 'clients' }); }
    }

    @Watch('item.last_name')
    viewLastNameClient(last_name) {
      this.capitalizeFirstLetter(last_name, 'last_name');
    }

    @Watch('item.first_name')
    viewFirstNameClient(first_name) {
      this.capitalizeFirstLetter(first_name, 'first_name');
    }

    @Watch('item.middle_name')
    viewMiddleNameClient(middle_name) {
      this.capitalizeFirstLetter(middle_name, 'middle_name');
    }
}
