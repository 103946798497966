import { GetterTree } from 'vuex';
import { StateExport } from './types';

const getters: GetterTree<StateExport, object> = {
  export: (state) => state.export,
  exportPageCount: (state) => state.exportPageCount,
  exportValid: (state) => state.exportValid,
};

export default getters;
