import RepairProvider from '../../../../api/providers/repair';
import TemplateFormProvider from '../../../../api/providers/template-form';

interface FilteredField {
  id;
}
/* eslint-disable no-param-reassign */
function filteringFieldsByName(template) {
  template.forEach((column) => column.forEach((fields) => {
    const { id } = fields;
    switch (id) {
      case 1: fields.name = 'serial'; break;
      case 2: fields.name = 'imei'; break;
      case 4: fields.name = 'express'; break;
      case 5: fields.name = 'maintenance'; break;
      case 6: fields.name = 'planned_price'; break;
      case 7: fields.name = 'planned_out_date'; break;
      case 10: fields.name = 'warranty'; break;
      case 11: fields.name = 'outbound'; break;
      case 13: fields.name = 'master_id'; break;
      case 14: fields.name = 'send_master_notification'; break;
      default: break;
    }
  }));
}
/* eslint-enable no-param-reassign */

function trimNamesСustomFields(this, form) {
  this.data_repair.bug = form.bug.split(',').map((element) => element.trim()).join(',');
  this.data_repair.equipment = form.equipment.split(',').map((element) => element.trim()).join(',');
  this.data_repair.state = form.state.split(',').map((element) => element.trim()).join(',');
  this.data_repair.acceptor_notes = form.acceptor_notes.split(',').map((element) => element.trim()).join(',');
}

function createCustomFields(this) {
  this.template_form.layout.filter((column) => column.find((item) => {
    if ((item.id !== 15 && item.id !== 16) && item.id >= 1000) {
      this.data_repair.custom_attributes.push({ field_id: item.id, value: '' });
    }
    return false;
  }));
}
function totalFuncLoadingRepair(this, repair, type) {
  this.repair_client = repair.client;
  this.data_repair.client_id = repair.client_id;
  this.data_repair.imei = repair.imei;
  this.data_repair.serial = repair.serial;

  if (type !== 'clone') {
    Object.keys(this.data_repair).forEach((key) => {
      if (key !== 'custom_attributes'
        && key !== 'send_master_notification'
        && key !== 'prepayment') this.data_repair[key] = repair[key];
    });
    if (repair.planned_out_date !== null) {
      this.data_repair.planned_out_date = repair.planned_out_date * 1000;
    }
  }

  if (repair.repair_objects.length !== 0) {
    this.data_repair.objects = repair.repair_objects;
  }

  if (type !== 'clone') {
    trimNamesСustomFields.call(this, repair);

    if (repair.repair_payments.length !== 0) {
      const payments = repair.repair_payments.find((item) => item.is_prepayment);
      this.data_repair.prepayment = payments || { cashbox_id: null, sum: null };
    }

    if (repair.repair_attributes.length !== 0) {
      /* eslint-disable no-param-reassign */
      repair.repair_attributes.forEach((item) => {
        if (item.value === 'true') item.value = true;
      });
      /* eslint-enable no-param-reassign */
      if (repair.repair_attributes.length !== this.template_form.layout.length) {
        this.data_repair.custom_attributes = repair.repair_attributes;

        const filtered_fields: FilteredField[] = [];

        this.template_form.layout.forEach((column) => {
          column.forEach((item) => {
            if ((item.id !== 15 && item.id !== 16) && item.id >= 1000) {
              filtered_fields.push(item);
            }
          });
        });

        const result = filtered_fields.filter((item) => {
          if ((item.id !== 15 && item.id !== 16) && item.id >= 1000) {
            return !repair.repair_attributes.some((attributes) => item.id === attributes.field_id);
          }
          return false;
        });

        result.forEach((item) => this.data_repair.custom_attributes.push({ field_id: item.id, value: '' }));
      } else {
        this.data_repair.custom_attributes = repair.repair_attributes;
      }
    }
  }

  this.sendClientCheckBlacklist({ phone: repair.client.phone });

  this.clone_repair = { ...this.data_repair };
}
export default function requestOnTemplateForm(this, name, type, id) {
  const type_form = name.search('repair') === 0 ? 1 : 2;
  const data = type === 'add'
    ? [TemplateFormProvider.getTemplateForm(type_form)]
    : [TemplateFormProvider.getTemplateForm(type_form), RepairProvider.getViewRepair(id)];

  this.loading_form = true;

  Promise.all(data)
    .then((res) => {
      this.template_form = res[0].data;
      filteringFieldsByName(res[0].data.layout);

      this.loading_form = false;

      if (type === 'add') this.clearRepairForm();
      if (type === 'add' || type === 'clone') createCustomFields.call(this);
      if (type === 'add') this.checkSaveDraftRepair();

      if (res[1]) {
        const repair = res[1].data;

        this.$store.commit('GET_REPAIR_VIEW', { item: repair });
        totalFuncLoadingRepair.call(this, repair, type);
        if (type !== 'clone') this.addBreadcrumbOnPageEditRepair(repair.id, repair.title);
      }
    })
    .catch((err) => {
      console.error(err);
      this.loading_form = false;
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !', item: '' });
    });
}
