const state = {
  repair: [],
  repairTransfer: [],
  newRepairTransfer: {},
  newRepairWork: {},
  updatedTransferRepair: {},
  pageCountRepair: null,
  pageCurrentRepair: null,
  pageCountRepairTransfer: null,
  pageCurrentRepairTransfer: null,
  addRepair: {},
  viewRepair: {},
  repairAssignMaster: {},
  deleteRepair: {},
  repairComment: {},
  repairCommentsLog: [],
  viewComment: {},
  repairUpdate: {},
  repairPaymentAdd: {},
  delPayment: {},
  repairParts: [],
  discount: {},
  addition: {},
  delDiscount: {},
  delAddition: {},
  repairAgreement: {},
  repairApproveAgreement: {},
  repairDeclineAgreement: {},
  repairDismissJob: {},
  repairDone: {},
  repairRevision: {},
  repairEject: {},
  repairRestart: {},
  repairDelay: {},
  repairStart: {},
  repairContinue: {},
  repairLogs: [],
  pageCountLogs: null,
  repairFiles: [],
  pageCountFiles: null,
  repairAddFiles: {},

  repairValid: {},

  ListRepairTableLayout: {},

  repairAllColumn: [
    { key: 1, label: '№', value: 'title' },
    { key: 2, label: 'Клиент', value: 'client' },
    { key: 3, label: 'Обнаруженная неисправность', value: 'masterNotes' },
    { key: 4, label: 'Устройство', value: 'device' },
    { key: 5, label: 'Ожидаемая стоимость', value: 'plannedPrice' },
    { key: 6, label: 'Статус', value: 'status' },
    { key: 7, label: 'Заметки', value: 'acceptorNotes' },
    { key: 8, label: 'Дата выдачи', value: 'outDate' },
    { key: 9, label: 'Состояние', value: 'state' },
    { key: 10, label: 'Контактное лицо', value: 'clientContactName' },
    { key: 11, label: 'Email клиента', value: 'clientEmail' },
    { key: 12, label: 'Ожидаемая дата выдачи', value: 'plannedOutDate' },
    { key: 13, label: 'Тип устройства', value: 'deviceType' },
    { key: 14, label: 'Дата готовности', value: 'done_at' },
    { key: 15, label: 'Дата приема', value: 'incomeDate' },
    { key: 16, label: 'Адрес клиента', value: 'clientAddress' },
    { key: 17, label: 'К оплате', value: 'toPay' },
    { key: 18, label: 'Источник клиентов', value: 'clientSource' },
    { key: 19, label: 'Серийный номер/IMEI', value: 'serial' },
    { key: 20, label: 'Пункт приема', value: 'point' },
    { key: 21, label: 'Инженер', value: 'master' },
    { key: 22, label: 'Телефон клиента', value: 'phone' },
    { key: 23, label: 'Предоплата', value: 'prepayment' },
    { key: 24, label: 'Итого за ремонт', value: 'totalSum' },
    { key: 25, label: 'Приемщик', value: 'acceptor' },
    { key: 26, label: 'Неисправность', value: 'bug' },
    { key: 27, label: 'Гарантия до', value: 'warrant_until' },
    { key: 28, label: 'Срок гарантии', value: 'warrantSize' },
    { key: 29, label: 'Комплектация', value: 'equipment' },
    { key: 30, label: 'Выдал', value: 'ejector' },
    { key: 31, label: 'Местоположение', value: 'currentLocation' },
  ],
  repairActiveColumn: [],
  newRepairLayout: {},
};

export default state;
