import { MutationTree } from 'vuex';
import {
  GET_REPORTS,
  StateReport,
} from './types';

const mutations: MutationTree<StateReport> = {

  [GET_REPORTS](state, { item }) {
    state.reports = item;
  },
};

export default mutations;
