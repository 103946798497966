import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RepairObjectForm } from '@/types';

@Component({
  template: require('./index.html'),
  components: {},
})

export default class FieldCartridges extends Vue {
    @Prop() device!: RepairObjectForm[];

    get checkOnEmptyDevice() {
      return this.deviceRepair.length !== 0 && this.deviceRepair[0].device_id !== null;
    }

    get deviceRepair() {
      return this.device;
    }
}
