import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PointForm from '../../components/point-form';

@Component({
  template: require('./index.html'),
  components: {
    'point-form': PointForm,
  },
})
export default class PointAddPage extends Vue {
  @Getter point;

  @Getter newPoint;

  @Action addBreadcrumb;

  item: object = {};

  color = '#1976d2';

  get pointHasAccess() {
    return this.point.filter((p) => p.has_access);
  }

  resetForm() {
    (this.$refs.form as PointForm).resetForm();
  }

  mounted() {
    this.addBreadcrumb([
      { id: 1, section: 'Компания', link: null },
      { id: 2, section: 'Настройки', link: null },
      { id: 3, section: 'Филиалы', link: '/settings/point' },
      { id: 4, section: 'Новый филиал', link: null },
    ]);
    document.title = 'Создание филиала';
  }

  @Watch('newPoint')
  addNewPoint() {
    this.resetForm();
    this.$router.push({ path: '/settings/point', name: 'point' });
  }
}
