const state = {
  transfer: [],
  transferNewItemList: [],
  transferPageCurrent: null,
  transferPageCount: null,
  transferValid: [],
  viewTransfer: {},
};

export default state;
