import Vue from 'vue';
import { RawLocation } from 'vue-router/types/router.d';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { User, Client } from '@/types';
import ClientSourceProvider from '../../api/providers/client-source';
import ClientsProvider from '../../api/providers/clients';
import ErrorAccessRights from '../../components/error-access-rights';
import NumberPhone from '../../components/forms/number-phone';
import ViewUserName from '../../components/forms/user-name';

@Component({
  template: require('./index.html'),
  components: {
    'user-name': ViewUserName,
    phone: NumberPhone,
    'error-access-rights': ErrorAccessRights,
  },
})
export default class ClientsPage extends Vue {
  @Prop({ default: () => false }) cancelGoTo!: boolean;

  @Prop({ default: () => false }) activeBreadcrumb!: boolean;

  @Prop({ default: () => false }) viewPage!: boolean;

  @Getter user;

  @Action addBreadcrumb;

  @Action addActionBtn;

  client: Client[] = [];

  clientSource: object[] = [];

  pageCount = 0;

  clientSourcePageCount = 0;

  loading = true;

  filter: Ifilter = {
    name: '',
    phone: '',
    source_id: '',
    status_id: '',
    page: 1,
  };

  get currentPage() {
    return this.filter.page;
  }

  get rightsUserCanSeeClients() {
    return this.user.rights ? this.user.rights.can_see_clients : false;
  }

  mounted() {
    this.getClientSourceList(1);
    this.checkUrl();
    if (!this.activeBreadcrumb) {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: '/settings' },
        { id: 2, section: 'Клиенты', link: null },
      ]);
    }
    document.title = 'Клиенты';

    if (!this.cancelGoTo) {
      if (this.user.rights && this.user.rights.can_create_clients) {
        this.addActionBtn([
          {
            id: 1, type: 'add', title: 'Новый клиент', link: '/clients/add', icon: 'fa-plus',
          },
        ]);
      }
    }
  }

  async getClientList(page: number) {
    if (!this.cancelGoTo) {
      const query: Ifilter = {};
      if (this.filter.page) {
        query.page = Number(page);
      }
      const location = { query };
      this.$router.push('');
      this.$router.push(location as RawLocation);
    }
    await ClientsProvider.getClientList(page)
      .then((res) => {
        this.client = res.data;
        this.pageCount = Number(res.pageCount);
      })
      .catch((err) => {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка загрузки клиентов', item: '' });
        console.error(err);
      });
  }

  async filterClient(item: Ifilter) {
    await ClientsProvider.filterClient(item.page, item)
      .then((res) => {
        this.client = res.data;
        this.pageCount = Number(res.pageCount);
        this.filter.page = item.page;
      })
      .catch((err) => {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка загрузки клиентов', item: '' });
        console.error(err);
      });
  }

  async getClientSourceList(page: number) {
    await ClientSourceProvider.getClientSourceList(page)
      .then((res) => {
        this.clientSource = res.data;
        this.clientSourcePageCount = res.pageCount;
      })
      .catch((err) => console.error(err));
  }

  beforeDestroy() {
    this.addActionBtn([]);
  }

  loadPage(val: number) {
    this.loading = true;
    this.filter.page = val;
    this.changeUrl();
  }

  onFilter() {
    this.filter.page = 1;
    this.filterClient(this.filter);
  }

  goTo(id) {
    const сlient: Client = this.client.filter((item) => item.id === id)[0];
    this.$emit('row-click', сlient);
    if (this.cancelGoTo) return;
    this.$router.push(`/clients/view/${id}`);
  }

  clearFilterField(value, field) {
    if (value === null) this.filter[field] = '';
  }

  changeUrl() {
    if (!this.cancelGoTo) {
      const query: Ifilter = {};
      if (this.filter.name) {
        query.name = this.filter.name;
      }
      if (this.filter.phone) {
        query.phone = this.filter.phone;
      }
      if (this.filter.source_id) {
        query.source_id = this.filter.source_id;
      }
      if (this.filter.status_id) {
        query.status_id = this.filter.status_id;
      }
      if (this.filter.page) {
        query.page = this.filter.page;
      }
      const location = { query };
      this.$router.push('');
      this.$router.push(location as RawLocation);
    } else {
      this.getClientList(this.filter.page as number);
    }
  }

  checkUrl() {
    if (this.$route.query) {
      const filter: Ifilter = this.$route.query;
      filter.page = filter.page ? Number(filter.page) : 1;
      this.filter = filter;
      this.filterClient(this.filter);
    } else {
      this.getClientList(1);
    }
  }

  onNameChanged(val: object) {
    this.clearFilterField(val, 'name');
    this.onFilter();
    this.changeUrl();
  }

  onPhoneChanged(val: object) {
    this.clearFilterField(val, 'phone');
    this.onFilter();
    this.changeUrl();
  }

  onStatusChanged(val: object) {
    this.clearFilterField(val, 'status_id');
    this.onFilter();
    this.changeUrl();
  }

  onSourceChanged(val: object) {
    this.clearFilterField(val, 'source_id');
    this.changeUrl();
    this.onFilter();
  }

  @Watch('$route.query')
  routeWasChange(val, oldVal) {
    if (Number(val.page) === oldVal.page) {
      return;
    }
    this.checkUrl();
  }

  @Watch('client')
  onLoad() {
    window.scroll(0, 0);
    this.$nextTick(() => {
      this.loading = false;
    });
  }

  @Watch('filter.page')
  onChildChanged() {
    this.changeUrl();
  }

  @Watch('user')
  meUser(user: User) {
    if (user.rights) {
      if (user.rights.can_create_clients) {
        this.addActionBtn([
          {
            id: 1, type: 'add', title: 'Новый клиент', link: '/clients/add', icon: 'fa-plus',
          },
        ]);
      } else {
        this.addActionBtn([]);
      }
    } else {
      this.addActionBtn([]);
    }
  }
}

interface Ifilter {
  name?: string;
  phone?: string;
  source_id?: string;
  status_id?: string;
  page?: number;
}
