import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import UserStatusAdd from '../../components/user-status-add';

@Component({
  template: require('./index.html'),
  components: {
    'user-status-add': UserStatusAdd,
  },
})
export default class UserStatusPage extends Vue {
  @Action getUserStatusList;

  @Action changeUserStatus;

  @Action deleteUserStatus;

  @Action addBreadcrumb;

  @Getter userStatus;

  @Getter userStatusPageCount;

  @Getter repairStatus;

  @Getter validStatus;

  loading = true;

  dialogEdit = false;

  dialogDelete = false;

  editItem: object = {};

  loadingOnChangeStatus = false;

  mounted() {
    this.getUserStatusList(1);
    this.addBreadcrumb([
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Справочники', link: null },
      { id: 3, section: 'Пользовательские статусы', link: null },
    ]);
    document.title = 'Пользовательские статусы';
  }

  validate = (obj: object, key: string) => {
    if (Object.keys(obj).some((v) => v === key)) {
      return obj[key][0];
    }
    return null;
  }

  loadPage(page: number) {
    this.getUserStatusList(page);
  }

  editUserStatus(formName) {
    (this.$refs[formName] as Form).validate((valid) => {
      if (valid) {
        this.loadingOnChangeStatus = true;
        this.changeUserStatus(this.editItem);
        return true;
      }
      return false;
    });
  }

  onDialogEdit(item: object) {
    this.editItem = JSON.parse(JSON.stringify(item));
    this.dialogEdit = true;
  }

  onDialogDelete(item: object) {
    this.editItem = JSON.parse(JSON.stringify(item));
    this.dialogDelete = true;
  }

  @Watch('userStatus')
  onChanged() {
    this.$nextTick(() => {
      this.loading = false;
    });
  }

  @Watch('validStatus')
  onChange(val: object) {
    if (Object.keys(val).length === 0) {
      this.dialogEdit = false;
      this.loadingOnChangeStatus = false;
    }
  }
}
