import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { FEEDBACK_TIME_LOCAL_STORAGE_KEY } from '@/components/repair/types';

import Repair from '@/components/repair';
import ModalRewiew from '@/components/rewiew';
import ModalPointPicker from '@/components/modal-point-picker';

import FeedbackProvider from '@/api/providers/feedback';

import { Point } from '@/types';

@Component({
  template: require('./index.html'),
  components: {
    Repair,
    ModalRewiew,
    ModalPointPicker,
  },
})

export default class RepairPage extends Vue {
  view_modal_rewiew = false;

  view_modal_points = false;

  @Action getPointList;

  @Getter btnloader;

  @Getter point;

  /**
   * геттера отсотритовранных филиалов
   * @returns Point
   */
  get sortedPoints() {
    if (this.pointHasAccess.length) {
      const pointHasAccess = this.pointHasAccess.filter((p) => p.has_access);
      return pointHasAccess.map((x) => x).sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });
    }
    return [];
  }

  /**
   * Геттер есть ли доступ к филиалу
   * @returns Point
   */
  get pointHasAccess(): Point[] {
    return this.point.filter((p) => p.has_access);
  }

  mounted() {
    this.getPointList({ page: 1 });
  }

  /**
   * Проверяет можно ли показывать модальное окно "Оставьте отызв"
   */
  tryShowFeedbackForm() {
    if (this.btnloader) return;
    const now = new Date().getTime() / 1000;
    const localStorageTime = localStorage.getItem(FEEDBACK_TIME_LOCAL_STORAGE_KEY);
    if (localStorageTime !== null && now <= Number.parseFloat(localStorageTime)) {
      return;
    }

    FeedbackProvider.readyForFeedback()
      .then((res) => {
        const server_t = res.next_poll_timestamp;
        if (now > server_t) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.view_modal_rewiew = true;
            });
          }, 0);
        } else {
          localStorage.setItem(FEEDBACK_TIME_LOCAL_STORAGE_KEY, server_t);
        }
      }).catch((err) => console.error(err));
  }

  /**
   * Меняет значение view_modal_points
   * @param  {boolean} isShow
   */
  changeShowingModalPoint(isShow: boolean) {
    this.view_modal_points = isShow;
  }

  /**
   * Закрываем модальное окно "Оставьте отзыв"
   */
  closeRewiewPage() {
    this.view_modal_rewiew = false;
  }

  /**
   * Показываем модальные окна
   */
  viewModal() {
    if (this.point.length > 1 && this.$route.query.timestamp) {
      const score_for_modal_points = +(new Date().getTime() / 1000).toFixed(0)
        - Number(this.$route.query.timestamp);

      if (score_for_modal_points / 1000 < 3) {
        this.view_modal_points = true;
      }
    }
    if (!this.view_modal_points && this.point.length > 0) {
      this.tryShowFeedbackForm();
    }
  }
}
