import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ExportType, Cashbox } from '@/classes';

interface Model {
  email: string;
  cashbox_ids?: number[];
}
@Component({
  template: require('./index.html'),
})

export default class ExportForm extends Vue {
  @Action getFullCashList;

  @Action requestRepairExport;

  @Action requestClientExport;

  @Action requestPartNomenklaturaExport;

  @Action requestWorkNomenklaturaExport;

  @Action requestPartAmountExport;

  @Action requestСashoperationsExport;

  @Action requestSmsExport;

  @Getter exportValid;

  @Getter cashList;

  @Getter btnloader;

  model: Model = { email: '' };

  exportTypeId = 1;

  mounted() {
    this.getFullCashList(false);
  }

  clearForm() {
    this.model.email = '';
    this.exportTypeId = 1;
  }

  validate = (obj: object, key: string) => {
    if (Object.keys(obj).some((v) => v === key)) {
      return obj[key][0];
    }
    return null;
  }

  changeStatus(status) {
    if (status > 7) {
      this.model.cashbox_ids = [];
      this.model.cashbox_ids.push(status - 7);
    } else if ('cashbox_ids' in this.model) delete this.model.cashbox_ids;
  }

  public exportList: ExportType[] = [
    new ExportType(ExportType.EXPORT_REPAIR, 'Заказы'),
    new ExportType(ExportType.EXPORT_PARTS_NOMENKLATURE, 'Номенклатура товаров'),
    new ExportType(ExportType.EXPORT_PARTS_AMOUNT, 'Остатки товаров'),
    new ExportType(ExportType.EXPORT_WORKS, 'Услуги'),
    new ExportType(ExportType.EXPORT_CLIENTS, 'Клиенты'),
    new ExportType(ExportType.EXPORT_SMS, 'SMS'),
  ];

  public onSaveExportClick() {
    switch (this.exportTypeId) {
      case ExportType.EXPORT_REPAIR: {
        this.requestRepairExport(this.model); break;
      }
      case ExportType.EXPORT_PARTS_NOMENKLATURE: {
        this.requestPartNomenklaturaExport(this.model); break;
      }
      case ExportType.EXPORT_PARTS_AMOUNT: {
        this.requestPartAmountExport(this.model); break;
      }
      case ExportType.EXPORT_WORKS: {
        this.requestWorkNomenklaturaExport(this.model); break;
      }
      case ExportType.EXPORT_CLIENTS: {
        this.requestClientExport(this.model); break;
      }
      case ExportType.EXPORT_SMS: {
        this.requestSmsExport(this.model); break;
      }
      default: break;
    }
    if (this.exportTypeId > ExportType.EXPORT_SMS) this.requestСashoperationsExport(this.model);
  }

  @Watch('exportValid')
  valdationForm(error: object[]) {
    if (error.length === 0) {
      this.clearForm();
      this.$emit('switchHistory', '1');
    }
  }

  @Watch('cashList')
  listCash(list: Cashbox[]) {
    list.forEach((cash) => {
      if (cash.id) {
        this.exportList.push(new ExportType(ExportType.EXPORT_SMS + cash.id, `Кассовые операции ${cash.name}`));
      }
    });
  }
}
