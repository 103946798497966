import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { User, RepairForm as Repair, RepairLayoutCell } from '@/types';
import BugOptionProvider from '../../../../../api/providers/bugoption';
import DeviceProvider from '../../../../../api/providers/device';
import EquipmentOptionProvider from '../../../../../api/providers/equipmentoption';
import NoteOptionProvider from '../../../../../api/providers/noteoption';
import StateOptionProvider from '../../../../../api/providers/stateoption';
import StuffProvider from '../../../../../api/providers/stuff';
// interface
import { Cartridges } from '../../interface';
import FieldBug from '../fields/bug';
import FieldCartridges from '../fields/cartridges';
import FieldCustom from '../fields/custom';
import FieldEquipment from '../fields/equipment';
import FieldModel from '../fields/model';
import FieldNote from '../fields/note';
import FieldPrepayment from '../fields/prepayment';
import FieldRegular from '../fields/reqular';
import FieldState from '../fields/state';
// components
import FieldType from '../fields/type';
import FieldVendor from '../fields/vendor';

interface SearchType {
  id;
}

interface Model {
  typeId;
  type;
  vendor;
  vendorId;
  id;
}

@Component({
  template: require('./index.html'),
  components: {
    'field-type': FieldType,
    'field-vendor': FieldVendor,
    'field-model': FieldModel,
    'field-cartridges': FieldCartridges,
    'field-prepayment': FieldPrepayment,
    'field-regular': FieldRegular,
    'field-custom': FieldCustom,
    'field-bug': FieldBug,
    'field-equipment': FieldEquipment,
    'field-note': FieldNote,
    'field-state': FieldState,
  },
})

export default class RepairForm extends Vue {
    @Prop() repair!: Repair;

    @Prop() cloneRepair!: object;

    @Prop() layout!: RepairLayoutCell[][];

    @Prop({ required: true }) errorRepairForm!: object;

    @Getter btnloader;

    objects: Record<string, object | null> = { type_id: null, vendor_id: null, model_id: null };

    data_cartridges: Cartridges = {
      id: 1, amount: 1, model: null, type: null, vendor: null, device_id: null,
    };

    masters: object[] = [];

    device_type_list: object[] = [];

    device_vendor_list: object = [];

    device_model_list: object[] = [];

    plain_device_model_list: Model[] = [];

    rules: object = {
      imei: { validator: this.validateImei, trigger: 'change', type: 'number' },
      bug: [
        { required: true, message: 'Введите несправность', trigger: 'change' },
      ],
      'prepayment.sum': { validator: this.validationPrepaymentSum, trigger: 'change', type: 'number' },
    };

    bug_list: object[] = [];

    equipment_list: object[] = [];

    note_list: object[] = [];

    state_list: object[] = [];

    stuff_list: object[] = [];

    page_stuff = 1;

    // api

    sendDeviceType(item) {
      DeviceProvider.sendDeviceType(item)
        .then((res) => res.data).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    async getDevicePlain(model) {
      try {
        return await DeviceProvider.getDevicePlain(model)
          .then((res) => {
            if (res.data.length !== 0) {
              return res.data;
            }
            this.device_model_list = [];
            this.plain_device_model_list = [];

            return [];
          }).catch((err) => {
            console.error(err.response.data);
          });
      } catch (err) {
        throw new Error(err);
      }
    }

    getStuffList() {
      StuffProvider.getFullStuffList()
        .then((res) => {
          (res as User[]).forEach((element) => {
            if (element.rights) {
              if (element.rights.can_work_on_repair) this.masters.push(element);
            }
          });
        }).catch((err) => console.error(err));
    }

    get typeForm() {
      const { name } = this.$route;
      if (name) {
        return name.search('repair') !== -1;
      }
      throw new Error('name is null or undefined');
    }

    get flagCloneForm() {
      const { name } = this.$route;
      if (name) {
        return name.search('clone') !== -1;
      }
      throw new Error('name is null or undefined');
    }

    get flagAddForm() {
      const { name } = this.$route;
      if (name) {
        return name.search('add') !== -1;
      }
      throw new Error('name is null or undefined');
    }

    get flagEditForm() {
      const { name } = this.$route;
      if (name) {
        return name.search('edit') !== -1;
      }
      throw new Error('name is null or undefined');
    }

    created() {
      this.getStuffList();
      document.addEventListener('click', this.focusCustomFields);
    }

    destroyed() {
      document.removeEventListener('click', this.focusCustomFields);
    }

    validationDevice(type) {
      const validation_objects = this.validate(this.errorRepairForm, 'objects');
      const objects = this.repair.objects[0];

      const str_type = 'Поле «Тип» является обязательным';
      const str_vendor = 'Поле «Производитель» является обязательным';
      const str_model = 'Поле «Модель» является обязательным';

      if (this.typeForm) {
        if (validation_objects) {
          if (!objects.type && type === 'type') return str_type;
          if (!objects.vendor && type === 'vendor') return str_vendor;
          if (!objects.model && type === 'model') return str_model;
        } else {
          return '';
        }
      } else if (validation_objects) {
        if (!objects.vendor && type === 'vendor') return str_vendor;
        if (!objects.model && type === 'model') return str_model;
      } else {
        return '';
      }
      return '';
    }

    validationForm(field) {
      let key: string;
      switch (field.id) {
        case 3: key = 'state'; break;
        case 8: key = 'acceptor_notes'; break;
        case 9: key = 'equipment'; break;
        default: key = field.name; break;
      }
      return this.validate(this.errorRepairForm, key);
    }

    validateImei(rule, value, callback) {
      if (value || value === null) {
        if (!this.isIMEIValid(value)) {
          callback(new Error('IMEI должен состоять из 15 цифр.'));
        } else {
          this.getValidationDeviceImei(value);
          callback();
        }
      } else {
        callback();
      }
    }

    validationPrepaymentSum(rule, value, callback) {
      const { repair } = this;

      if (value) {
        if (!repair.prepayment.cashbox_id) {
          callback(new Error('Необходимо выбрать кассу.'));
        }
      } else {
        callback();
      }
    }

    isIMEIValid = (imei) => {
      if (!/^[0-9]{15}$/.test(imei)) {
        return false;
      }

      let sum = 0; let factor = 2; let checkDigit: number| null = null; let
        multipliedDigit;

      for (let i = 13, li = 0; i >= li; i--) {
        multipliedDigit = parseInt(imei.charAt(i), 10) * factor;
        sum += (multipliedDigit >= 10 ? ((multipliedDigit % 10) + 1) : multipliedDigit);
        if (factor === 1) {
          factor += 1;
        } else {
          factor -= 1;
        }
      }
      checkDigit = ((10 - (sum % 10)) % 10);

      return !(checkDigit !== parseInt(imei.charAt(14), 10));
    }

    displayFieldsRegular = (field) => field.type !== 998 && field.id !== 12 && field.id < 1000

    displayLabelFields = (field) => (field.type === 3 ? null : field.label)

    createFilter = (queryString) => (link) => link.name.trim().toLowerCase().match(
      queryString.trim().toLowerCase(),
    );

    searchFilterDevice = (item, str) => item.find(
      (elem) => elem.name.trim().toLowerCase() === str.trim().toLowerCase(),
    )

    clearIdObjects() {
      this.objects = { type_id: null, vendor_id: null, model_id: null };
    }

    clearCartridges() {
      this.data_cartridges = {
        id: 1, amount: 1, model: null, type: null, vendor: null, device_id: null,
      };
    }

    loadingCustomField(field) {
      const custom_field = this.repair.custom_attributes.find((item) => item.field_id === field.id);
      return custom_field || { field_id: null, value: '' };
    }

    clearEmptyType() {
      this.objects.type_id = null;
      this.objects.vendor_id = null;
      this.objects.model_id = null;
      this.clearStateDevice();
    }

    clearEmptyVendor() {
      this.objects.vendor_id = null;
      this.objects.model_id = null;
      this.clearStateDevice('model');
    }

    clearEmptyModel() {
      this.objects.model_id = null;
    }

    clearStateDevice(from = '') {
      if (from === 'model') {
        this.device_model_list = [];
      } else {
        this.device_model_list = [];
        this.device_vendor_list = [];
      }
    }

    getDeviceTypeList() {
      return DeviceProvider.getDeviceTypeList()
        .then((res) => {
          this.device_type_list = res.data;
          return res.data;
        }).catch((err) => {
          console.error(err.response.data);
          return err.response.data;
        });
    }

    getDeviceVendorList(id) {
      return DeviceProvider.getDeviceVendorList(id)
        .then((res) => {
          this.device_vendor_list = res.data;
          return res.data;
        }).catch((err) => {
          console.error(err.response.data);
          return err.response.data;
        });
    }

    getDeviceModelList(id) {
      return DeviceProvider.getDeviceList(id)
        .then((res) => {
          this.device_model_list = res.data;
          return res.data;
        }).catch((err) => {
          console.error(err.response.data);
          return err.response.data;
        });
    }

    getValidationDeviceImei(imei) {
      DeviceProvider.getValidationDeviceImei(imei)
        .then((res) => {
          const { type } = res.data.vendor;
          const { vendor } = res.data;
          const device = res.data;

          this.loadType(type);

          this.getDeviceVendorList(type.id)
            .then(() => {
              this.loadVendor(vendor);
              this.getDeviceModelList(vendor.id).then(() => this.loadDevice(device));
            });
        }).catch((err) => console.error(err.response.data));
    }

    searchType(queryString, cb) {
      const items = this.device_type_list;
      const results: object[] = queryString ? items.filter(this.createFilter(queryString)) : items;

      if (results.length !== 0 && queryString) {
        const search_type: SearchType = this.searchFilterDevice(results, queryString);
        this.objects.type_id = search_type ? search_type.id : null;

        if (search_type) {
          this.getDeviceVendorList(search_type.id);
        } else {
          this.clearEmptyType();
        }
      } else {
        this.clearEmptyType();
      }

      cb(results);
    }

    searchVendor(queryString, cb) {
      const items = this.device_vendor_list;
      const results = queryString
        ? (items as object[]).filter(this.createFilter(queryString))
        : items;

      if ((results as object[]).length !== 0 && queryString) {
        const search_vendor: SearchType = this.searchFilterDevice(results, queryString);
        this.objects.vendor_id = search_vendor ? search_vendor.id : null;

        if (search_vendor) {
          this.getDeviceModelList(search_vendor.id);
        } else {
          this.clearEmptyVendor();
        }
      } else {
        this.clearEmptyVendor();
      }

      cb(results);
    }

    async searchModel(queryString, cb) {
      const items = this.device_model_list;
      const check_type_and_vendor = this.typeForm
        ? !this.repair.objects[0].type && !this.repair.objects[0].vendor
        : !this.data_cartridges.type && !this.data_cartridges.vendor;

      let results = queryString ? items.filter(this.createFilter(queryString)) : items;

      if (check_type_and_vendor) {
        if (queryString) {
          await this.getDevicePlain(queryString)
            .then((res) => {
              results = res.map((model) => ({
                name: model.model,
                id: model.id,
                vendor_id: Number(model.vendorId),
              }));

              this.plain_device_model_list = res;
            });
        } else {
          this.plain_device_model_list = [];
        }
      } else if (results.length !== 0 && queryString) {
        const search_model: SearchType = this.searchFilterDevice(results, queryString);
        this.objects.model_id = search_model ? search_model.id : null;

        if (search_model) {
          if (!this.typeForm) {
            this.data_cartridges.device_id = search_model.id;
          } else {
            this.$root.$emit('modelSelection', { item: search_model, type: 'search' });
          }
        } else {
          this.clearEmptyModel();
        }
      } else {
        if (!this.typeForm) {
          this.data_cartridges.device_id = null;
        } else {
          this.$root.$emit('modelSelection', { item: null, type: 'search' });
        }

        this.clearEmptyModel();
      }

      cb(results);
    }

    loadType(item) {
      this.$root.$emit('typeSelection', item);
      this.objects.type_id = item.id;
    }

    loadVendor(item) {
      if (!this.typeForm) {
        this.data_cartridges.vendor = item.name.trim();
      } else {
        this.$root.$emit('vendorSelection', item);
      }
      this.objects.vendor_id = item.id;
    }

    loadDevice(item) {
      this.objects.model_id = item.id;

      if (!this.typeForm) {
        this.data_cartridges.model = item.name.trim();
        this.data_cartridges.device_id = item.id;
      } else {
        this.$root.$emit('modelSelection', { item, type: null });

        if (!this.repair.objects[0].type && !this.repair.objects[0].vendor) {
          const model: Model | undefined = this.plain_device_model_list.find(
            (modelItem) => modelItem.id === item.id,
          );

          if (model) {
            this.typeSelect({ id: Number(model.typeId), name: model.type });
            this.loadVendor(
              {
                id: Number(model.vendorId),
                name: model.vendor,
                type_id: Number(model.typeId),
              },
            );
          }
        }
      }
    }

    typeSelect(item) {
      this.loadType(item);
      this.getDeviceVendorList(item.id);
    }

    vendorSelect(item) {
      this.loadVendor(item);
      this.getDeviceModelList(item.id);
    }

    modelSelect(item) {
      this.loadDevice(item);
    }

    downloadTypeInCatridges(type) {
      const cartridge = type.find((item) => item.name === 'Картридж');
      this.device_model_list = [];

      if (cartridge) {
        this.objects.type_id = cartridge.id;
        this.data_cartridges.type = cartridge.name;
        this.getDeviceVendorList(cartridge.id);
      } else {
        this.sendDeviceType({ name: 'Картридж' });
      }
    }

    async addCartridges() {
      const cartridges = this.data_cartridges;

      await this.checkOnNewDeviceItem();

      this.$root.$emit('addCartridges', cartridges);

      const id = this.repair.objects[this.repair.objects.length - 1].id + 1;

      this.data_cartridges = {
        id, amount: 1, model: null, type: cartridges.type, vendor: null, device_id: null,
      };
      this.objects.vendor_id = null;
      this.objects.model_id = null;
      this.clearStateDevice('model');
    }

    async addCartridgesByEmptyFields() {
      const { vendor, model, amount } = this.data_cartridges;

      if (vendor && model && amount) await this.addCartridges();
    }

    focusCustomFields(e) {
      const FOCUSED_ADD_BUG = e.target.parentElement === null
        ? e.target.classList.contains('focused-custom-bug')
        : e.target.classList.contains('focused-custom-bug') || e.target.parentElement.classList.contains('item-complete__input-bug');
      const FOCUSED_ADD_EQUIPMENT = e.target.parentElement === null
        ? e.target.classList.contains('focused-custom-equipment')
        : e.target.classList.contains('focused-custom-equipment') || e.target.parentElement.classList.contains('item-complete__input-equipment');
      const FOCUSED_ADD_STATE = e.target.parentElement === null
        ? e.target.classList.contains('focused-custom-state')
        : e.target.classList.contains('focused-custom-state') || e.target.parentElement.classList.contains('item-complete__input-state');
      const FOCUSED_ADD_NOTE = e.target.parentElement === null
        ? e.target.classList.contains('focused-custom-note')
        : e.target.classList.contains('focused-custom-note') || e.target.parentElement.classList.contains('item-complete__input-note');

      if (this.$refs.bugField) {
        if (FOCUSED_ADD_EQUIPMENT) {
          this.unFocusCustomFieldsEquipment();
        } else if (FOCUSED_ADD_BUG) {
          this.unFocusCustomFieldsBug();
        } else if (FOCUSED_ADD_STATE) {
          this.unFocusCustomFieldsState();
        } else if (FOCUSED_ADD_NOTE) {
          this.unFocusCustomFieldsNote();
        } else {
          const {
            stateField, noteField, bugField, equipmentField,
          } = this.$refs;

          if (stateField) stateField[0].unfocusCustomFieldsState();
          if (noteField) noteField[0].unfocusCustomFieldsNote();
          if (equipmentField) equipmentField[0].unfocusCustomFieldsEquipment();

          (bugField as FieldBug).unfocusCustomFieldsBug();
        }
      }
    }

    unFocusCustomFieldsBug() {
      const { stateField, noteField, equipmentField } = this.$refs;

      if (stateField) stateField[0].unfocusCustomFieldsState();
      if (noteField) noteField[0].unfocusCustomFieldsNote();
      if (equipmentField) equipmentField[0].unfocusCustomFieldsEquipment();
    }

    unFocusCustomFieldsEquipment() {
      const { stateField, noteField, bugField } = this.$refs;

      if (stateField) stateField[0].unfocusCustomFieldsState();
      if (noteField) noteField[0].unfocusCustomFieldsNote();
      (bugField as FieldBug).unfocusCustomFieldsBug();
    }

    unFocusCustomFieldsState() {
      const { equipmentField, noteField, bugField } = this.$refs;

      if (equipmentField) equipmentField[0].unfocusCustomFieldsEquipment();
      if (noteField) noteField[0].unfocusCustomFieldsNote();
      (bugField as FieldBug).unfocusCustomFieldsBug();
    }

    unFocusCustomFieldsNote() {
      const { equipmentField, stateField, bugField } = this.$refs;

      if (equipmentField) equipmentField[0].unfocusCustomFieldsEquipment();
      if (stateField) stateField[0].unfocusCustomFieldsState();
      (bugField as FieldBug).unfocusCustomFieldsBug();
    }

    // todo
    validate = (obj: object, key: string) => {
      try {
        if (obj) {
          /* eslint-disable guard-for-in */
          /* eslint-disable no-restricted-syntax */
          const varForDeletingObserver = JSON.parse(JSON.stringify(obj));
          for (const propertyName in varForDeletingObserver) {
            if (propertyName === key) {
              return varForDeletingObserver[propertyName][0];
            }
          }
          /* eslint-enable  guard-for-in */
          /* eslint-enable  no-restricted-syntax */
        }
        return null;
      } catch {
        return null;
      }
    }

    clearCustomFields() {
      const {
        stateField, noteField, equipmentField, bugField,
      } = this.$refs;

      if (stateField) stateField[0].clearStateItem();
      if (noteField) noteField[0].clearNoteItem();
      if (equipmentField) equipmentField[0].clearEquipmentItem();
      if (bugField) (bugField as FieldBug).clearBugItem();
    }

    loadingDataRepairInCustomField(repair) {
      const {
        stateField, noteField, equipmentField, bugField,
      } = this.$refs;

      if (stateField) stateField[0].loadingDataRepairInCustomField(repair);
      if (noteField) noteField[0].loadingDataRepairInCustomField(repair);
      if (equipmentField) equipmentField[0].loadingDataRepairInCustomField(repair);
      (bugField as FieldBug).loadingDataRepairInCustomField(repair);
    }

    loadingPrepaymentData(prepayment) {
      this.$refs.fieldPrepayment[0].loadingPrepaymentData(prepayment);
    }

    requestOnDateCustomFields() {
      BugOptionProvider.getBugOptionList(1)
        .then((res) => {
          this.bug_list = res.data;
        })
        .catch((err) => console.error(err.response.data));
      EquipmentOptionProvider.getEquipmentOptionList(1)
        .then((res) => {
          this.equipment_list = res.data;
        })
        .catch((err) => console.error(err.response.data));
      NoteOptionProvider.getNoteOptionList(1)
        .then((res) => {
          this.note_list = res.data;
        })
        .catch((err) => console.error(err.response.data));
      StateOptionProvider.getStateOptionList(1)
        .then((res) => {
          this.state_list = res.data;
        })
        .catch((err) => console.error(err.response.data));
    }

    async checkOnNewDeviceItem() {
      const objectDevice = this.typeForm ? this.repair.objects[0] : this.data_cartridges;
      try {
        if (objectDevice.type && objectDevice.vendor && objectDevice.model) {
          await DeviceProvider.sendDeviceType({ name: objectDevice.type })
            .then((res_type) => {
              this.objects.type_id = res_type.data.id;
            });

          await DeviceProvider.sendDeviceVendor(
            {
              name: objectDevice.vendor,
              type_id: this.objects.type_id,
            },
          )
            .then((res_vendor) => {
              this.objects.vendor_id = res_vendor.data.id;
            });

          await DeviceProvider.sendDeviceModel(
            {
              name: objectDevice.model,
              vendor_id: this.objects.vendor_id,
            },
          ).then((res_model) => {
            this.modelSelect(res_model.data);
          });
        } else {
          return;
        }
      } catch (err) {
        throw new Error(err);
      }
    }

    async handlerUpdateRepair() {
      await this.checkOnNewDeviceItem();

      if (!this.typeForm) {
        await this.addCartridgesByEmptyFields();
      }

      this.$root.$emit('handlerUpdateRepair');
    }

    async handlerAddRepair() {
      this.$store.commit('BTN_LOADER', true);
      try {
        this.checkFormValidate();
        await this.checkOnNewDeviceItem();
        if (!this.typeForm) {
          await this.addCartridgesByEmptyFields();
        }
        this.$root.$emit('handlerAddRepair');
      } catch {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка' });
        this.$store.commit('BTN_LOADER', false);
      }
    }

    requestToEnter() {
      if (this.flagEditForm) {
        this.handlerUpdateRepair();
      } else {
        this.handlerAddRepair();
      }
    }

    checkFormValidate() {
      if (this.repair.objects[0].type) {
        this.repair.objects[0].type = this.repair.objects[0].type.trim();
      }
      if (this.repair.objects[0].vendor) {
        this.repair.objects[0].vendor = this.repair.objects[0].vendor.trim();
      }
      if (this.repair.objects[0].model) {
        this.repair.objects[0].model = this.repair.objects[0].model.trim();
      }
    }

    checkingLayot() {
      this.layout.forEach((column) => {
        column.forEach((cell) => {
          if (!cell.system) {
            const findAtributied = this.repair.custom_attributes.find(
              (attribute) => attribute.field_id === cell.id,
            );
            if (!findAtributied) {
              this.repair.custom_attributes.push({
                field_id: cell.id,
                value: '',
              });
            }
          }
        });
      });
    }

    @Watch('device_type_list')
    typeList(type_list) {
      if (!this.typeForm) this.downloadTypeInCatridges(type_list);
    }

    @Watch('device_vendor_list')
    vendorList(vendor_list: object[]) {
      const { vendor } = this.repair.objects[0];

      if (this.typeForm && vendor && vendor_list.length !== 0) {
        const found_vendor = this.searchFilterDevice(vendor_list, vendor);
        if (found_vendor) this.vendorSelect(found_vendor);
      }
    }

    @Watch('device_model_list')
    modelList(model_list) {
      const device = this.repair.objects[0].model;

      if (this.typeForm && device && model_list.length !== 0) {
        const found_device = this.searchFilterDevice(model_list, device);
        if (found_device) this.modelSelect(found_device);
      }
    }

    @Watch('cloneRepair')
    watchRepair(repair) {
      const device = repair.objects;

      if (this.typeForm && device[0].type) {
        this.typeSelect(this.searchFilterDevice(this.device_type_list, device[0].type));
      } else {
        this.downloadTypeInCatridges(this.device_type_list);
      }

      this.$nextTick(() => this.loadingDataRepairInCustomField(repair));
    }

    @Watch('$route', { immediate: true })
    watchRoute() {
      this.getDeviceTypeList();

      this.clearIdObjects();
      this.clearCartridges();
    }

    @Watch('layout', { deep: true })
    watchTemplateForm() {
      this.checkingLayot();
      this.$nextTick(() => {
        if (this.flagAddForm) {
          (this.$refs.repairForm as Form).clearValidate();
          this.$root.$emit('clearValidateRepair', {});
          this.clearCustomFields();
        }
        this.requestOnDateCustomFields();
      });
    }
}
