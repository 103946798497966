import BaseProvider from '../base-provider';

class SuggestionProvider extends BaseProvider {
  async getSuggestionNameClients(name, flag_suggestion) {
    const res = await this.request('GET', `/suggestion/name?NameSuggestionSearchForm[query]=${name}&NameSuggestionSearchForm[part]=${flag_suggestion}`);
    return { data: res.data };
  }
}

export default new SuggestionProvider();
