import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  User, Breadcrumb, ActionBtn, NavigationPage, Part,
} from '@/types';
import DocumentProvider from '../../api/providers/document';
import PartProvider from '../../api/providers/part';
import StorageProvider from '../../api/providers/storage';
import ErrorAccessRights from '../../components/error-access-rights';
import Numbers from '../../components/forms/numbers';
import PartItem from '../../components/part-item';
import PartNav from '../../components/part-nav';
import Category from '../../components/__category';

interface HeadsPart {
  number: boolean;
  name: boolean;
  art_and_barcode: boolean;
  price: Price[];
  amounts: Amount[];
}

interface Amount {
  active: boolean;
  name: string;
  storage_id: number;
}
interface Price {
  name: string;
  title: string;
  active: boolean;
}

interface Content {
  page: number;
  name: string;
  barcode: string;
  art: string;
  category_id: number;
  include_internal: boolean;
  include_deleted: boolean;
  amount_control: number;
  only_positive: boolean;
  storage_ids: number[];
  'per-page': number;
}

@Component({
  template: require('./index.html'),
  components: {
    'category-tree': Category,
    'part-nav': PartNav,
    numbers: Numbers,
    'part-item': PartItem,
    'error-access-rights': ErrorAccessRights,
  },
})

export default class PartPage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action getStorageList;

    @Action getPartCategory;

    @Action clearListCategories;

    @Getter categoryList;

    @Getter user!: User;

    @Getter storages;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: null }, { id: 2, section: 'Остатки товаров', link: null }];

    actionBtn: ActionBtn[] = [{
      id: 1, type: 'add', title: 'Новое поступление', link: '/part/posting/add', icon: 'fa-plus',
    }];

    content: Content = {
      page: 1,
      name: '',
      barcode: '',
      art: '',
      category_id: 1,
      include_internal: false,
      include_deleted: false,
      only_positive: false,
      amount_control: 0,
      storage_ids: [],
      'per-page': 20,
    };

    navigation_page: NavigationPage = {};

    loading_part = true;

    loading_page = false;

    loadingCategory = true;

    flag_active_collapse = true;

    visible_image_part_on_hover = false;

    parts_list: Part[] = [];

    parts_list_page_count: number | null = null;

    parts_list_page_current: number | null = null;

    part_amount_control: number | null = null;

    private stickerDocumentId!: number;

    partRaceCondition = 0;

    heads_part: HeadsPart =
        {
          number: true,
          name: true,
          art_and_barcode: true,
          price: [
            { name: 'posting', title: 'Закуп', active: true },
            { name: 'repair', title: 'Ремонт', active: true },
            { name: 'shop', title: 'Продажа', active: true },
          ],
          amounts: [],
        };

    getPartList(item) {
      const raceCondition = this.getRandomRaceCondition();
      this.partRaceCondition = raceCondition;
      setTimeout(() => {
        if (this.partRaceCondition === raceCondition) {
          PartProvider.getPartList(item.page, item, item['per-page'])
            .then((res) => {
              this.parts_list = res.data;
              this.parts_list_page_count = Number(res.pageCount);
              this.parts_list_page_current = Number(res.pageCurrent);
              this.part_amount_control = Number(res.amountControl);

              this.loading_page = false;
              this.loading_part = false;
            }).catch((err) => {
              this.loading_page = false;
              this.loading_part = false;
              console.error(err);
            });
        }
      }, 500);
    }

    getRandomRaceCondition() {
      let string = '';
      for (let i = 0; i < 10; i += 1) {
        string += this.getRandomString();
      }
      return Number(string);
    }

    getRandomString = () => {
      const random = `${Math.floor(Math.random() * 10)}`;
      return random;
    }

    getHead(stor, local) {
      if (stor.length >= local.length) {
        const addArrItems = stor.filter((storItem) => local.map(
          (localItem) => localItem.storage_id,
        ).indexOf(storItem.id) < 0);
        addArrItems.forEach((item) => {
          local.push(
            { storage_id: item.id, name: item.name, active: true },
          );
        });
        return local;
      }
      const delArrItems = local.filter((localItem) => stor.map(
        (storItem) => storItem.id,
      ).indexOf(localItem.storage_id) < 0);
      if (delArrItems.length) {
        delArrItems.forEach((item) => {
          const indx = local.indexOf(item);
          local.splice(indx, 1);
        });
        return this.getHead(stor, local);
      }
      return [];
    }

    getAvailableList(page) {
      StorageProvider.getAvailableList(page)
        .then((res) => {
          const localStor = JSON.parse(localStorage.getItem('headPart') as string);

          if (localStor) {
            localStor.amounts = this.getHead(res.data, localStor.amounts);
            Object.keys(localStor).forEach((key) => {
              if (key) {
                if (key === 'amounts') {
                  this.heads_part.amounts = localStor.amounts;
                  this.syncContentStorageIds();
                } else {
                  this.heads_part[key] = localStor[key];
                }
              }
            });
          } else {
            const arr: Amount[] = [];
            res.data.forEach((item) => {
              arr.push(
                { storage_id: item.id, name: item.name, active: true },
              );
            });
            this.heads_part.amounts = arr;
            this.syncContentStorageIds();
          }
        }).catch((err) => console.error(err));
    }

    get rightsUser() {
      return this.user.rights ? this.user.rights.can_create_parts_and_categories : true;
    }

    onAmountControlParts(res) {
      this.parts_list = res.data;
      this.parts_list_page_count = Number(res.pageCount);
      this.parts_list_page_current = Number(res.pageCurrent);
      this.part_amount_control = Number(res.amountControl);
    }
    // end api

    mounted() {
      document.title = 'Остатки товаров';
      this.getStorageList();
      this.loadingPageByRoute();
      this.getAvailableList(1);
      this.getPartCategory('part');
      this.addBreadcrumb(this.breadcrumb);
      if (this.user.rights
        && this.user.rights.can_create_parts_and_categories) this.addActionBtn(this.actionBtn);
      this.changeCategory();
      this.filterLoading();
      this.filterParts();

      DocumentProvider.getDocumentList(1, { type: 8 }, 20)
        .then((resp) => {
          if (resp.data && resp.data.length > 0) {
            this.stickerDocumentId = resp.data[0].id;
          } else {
            console.warn('Не нашли стикер для товара на сервере');
          }
        }).catch((err) => {
          console.error(err);
        });
    }

    destroyed() {
      this.addActionBtn([]);
      this.content.page = 1;
      this.clearListCategories();
      this.$root.$off('categoryItemActive');
    }

    columnActive = (props) => props.some((i) => i.active)

    changeCategory() {
      this.$root.$on('categoryItemActive', (i) => {
        const val = this.content.category_id === i ? 1 : i;
        this.content.category_id = val;

        if (val === 1) {
          delete this.navigation_page.category_id;
        } else {
          this.navigation_page.category_id = val;
        }

        this.methodAddRouteNavigation();
      });
    }

    filterLoading() {
      this.$root.$on('filterLoading', ({ loading, data }) => {
        const empty_fields: string [] = [];
        this.loading_part = loading;
        Object.keys(data).forEach((key) => {
          if (data[key]) {
            this.navigation_page[key] = data[key];
          } else {
            delete this.navigation_page[key];
            empty_fields.push(key);
          }
        });

        this.methodAddRouteNavigation();
      });
    }

    copyPart(part, id) {
      const index = this.parts_list.findIndex((item) => item.id === id);
      this.parts_list.splice(index + 1, 0, part);
    }

    deletePart(part) {
      const index = this.parts_list.findIndex((i) => i.id === part.id);
      if (this.content.include_deleted === true) {
        this.parts_list[index].enabled = false;
      } else {
        this.parts_list.splice(index, 1);
      }
    }

    revertPart(part) {
      const index = this.parts_list.findIndex((i) => i.id === part.id);
      this.parts_list[index].enabled = true;
    }

    filterParts() {
      this.$root.$on('filterParts', (parts) => {
        this.parts_list = parts;
        this.loading_part = false;
      });
    }

    search() {
      this.loading_part = true;
      this.getPartList(this.content);
    }

    methodAddRouteNavigation() {
      this.$router.push({ query: { ...this.navigation_page } });
    }

    loadingPageByRoute() {
      const query = Object.keys(this.$route.query);

      if (query.length !== 0) {
        query.forEach((key) => {
          this.content[key] = key === 'per-page' || key === 'category_id' ? Number(this.$route.query[key]) : this.$route.query[key];
        });
        this.navigation_page = { ...this.$route.query };

        this.content.include_internal = !!this.navigation_page.include_internal;

        this.content.only_positive = !!this.navigation_page.only_positive;

        this.content.include_deleted = !!this.navigation_page.include_deleted;

        this.search();
      } else {
        this.getPartList(this.content);
      }
    }

    printSticker(partId) {
      if (this.stickerDocumentId) {
        const domain = process.env.NODE_ENV === 'production' ? 'printing.servix.io' : 'printing-dev.servix.io';
        const token = DocumentProvider.access;
        window.open(`https://${domain}/print/document?token=${token}&documentId=${this.stickerDocumentId}&entityId=${partId}&pdf=1`, '_blank');
      } else {
        console.error('Отсутствует stickerDocumentId');
      }
    }

    typePerPage(count) {
      return count === this.content['per-page'] ? 'primary' : '';
    }

    changePage(val) {
      this.content.page = val;
      this.navigation_page.page = String(val);
      this.methodAddRouteNavigation();
      window.scroll(0, 0);
    }

    changePerPage(val) {
      this.content['per-page'] = val;
      this.navigation_page['per-page'] = val;
      this.methodAddRouteNavigation();
    }

    updatePartAtRemoval() {
      this.content.category_id = 1;
      this.navigation_page.category_id = 1;
      delete this.navigation_page.category_id;
      this.methodAddRouteNavigation();
    }

    updateValueCategoryId(query) {
      if (query.category_id) {
        this.content.category_id = Number(query.category_id);
        this.navigation_page.category_id = Number(query.category_id);
      } else {
        this.content.category_id = 1;
        delete this.navigation_page.category_id;
      }
    }

    updateValuePage(query) {
      if (query.page) {
        this.content.page = Number(query.page);
        this.navigation_page.page = Number(query.page);
      } else {
        this.content.page = 1;
        delete this.navigation_page.page;
      }
    }

    updateValueSearchForm(query) {
      const form = {
        name: query.name,
        art: query.art,
        barcode: query.barcode,
      };

      const empty_fields: string [] = [];
      Object.keys(form).forEach((key) => {
        if (!form[key]) {
          delete this.navigation_page[key];
          this.content[key] = '';
          empty_fields.push(key);
        }
      });
    }

    openCategories(value) {
      this.flag_active_collapse = value.length !== 0;
    }

    @Watch('categoryList')
    listCategories() {
      this.loadingCategory = false;
    }

    @Watch('user')
    meUser(user: User) {
      if (user.rights) {
        if (user.rights.can_create_parts_and_categories) {
          this.addActionBtn(this.actionBtn);
        } else {
          this.addActionBtn([]);
        }
      } else {
        throw new Error('User.rights is undefined');
      }
    }

    @Watch('$route.query')
    viewRouteQuery(query) {
      this.updateValueCategoryId(query);
      this.updateValuePage(query);
      this.updateValueSearchForm(query);

      this.search();
    }

  @Watch('heads_part.amounts')
    syncContentStorageIds() {
      const enableAmount: Amount[] = this.heads_part.amounts.filter((amount) => amount.active);
      this.content.storage_ids = enableAmount.map((amount) => amount.storage_id);
      this.search();
    }
}
