import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Point } from '@/types';

interface Form {
  repair_id;
  target_point_id;
  comment;
}

interface View {
  id;
  current_point: {
    name;
  };
}

@Component({
  template: require('./index.html'),
})
export default class RepairCreateTransfer extends Vue {
  @Action addBreadcrumb;

  @Action getViewRepair;

  @Action getPointList;

  @Action sendRepairTransfer;

  @Getter btnloader;

  @Getter viewRepair;

  @Getter point;

  @Getter newRepairTransfer;

  form: Form = {
    repair_id: '',
    target_point_id: '',
    comment: '',
  };

  currentPoint = '';

  points: Point[] = [];

  mounted() {
    this.addBreadcrumb([
      { id: 1, section: 'Ремонт', link: null },
      { id: 2, section: 'Перемещения заказов', link: null },
      { id: 3, section: 'Новое перемещение', link: null },
    ]);
    this.getViewRepair(this.repairId);
  }

  get repairId() {
    return Number(this.$route.query.repairid);
  }

  get destPointId() {
    return Number(this.$route.query.destpointid);
  }

  get titleRepair() {
    return this.viewRepair.title;
  }

  @Watch('viewRepair')
  repair(view: View) {
    this.form.repair_id = view.id;
    this.currentPoint = view.current_point.name;
    this.getPointList({ page: 1 });
  }

  @Watch('point')
  dataPoints() {
    if (Object.keys(this.viewRepair).length !== 0) {
      this.points = this.point;
      this.form.target_point_id = this.destPointId;
    }
  }

  @Watch('newRepairTransfer')
  createTransfer() {
    this.$router.push(`/repair/view/${this.repairId}`);
  }
}
