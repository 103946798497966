import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import CompanyProvider from '../../api/providers/company';
import Iphone from '../../components/iphone';
import SmsTemplatesForm from '../../components/sms-templates-form';
import { NotificationMethodOption } from './types';

@Component({
  template: require('./index.html'),
  components: {
    'sms-form': SmsTemplatesForm,
    iphone: Iphone,
  },
})
export default class SmsTemplatesPage extends Vue {
  @Getter smsTemplate;

  @Getter btnloader;

  @Getter company;

  @Action getSmsTemplateList;

  @Action getSettings;

  @Action changeSmsTemplate;

  @Action addBreadcrumb;

  @Action getCompanyInfo;

  sms1 = '';

  sms1Method: string | null = null;

  sms2 = '';

  sms2Method: string | null = null;

  sms3 = '';

  sms3Method: string | null = null;

  sms4 = '';

  sms4Method: string | null = null;

  sender = '–';

  sms: object = {};

  loading = true;

  notificationMethods: NotificationMethodOption[] = [
    {
      key: 'auto',
      name: 'Любой',
    },
    {
      key: 'sms',
      name: 'SMS',
    },
    {
      key: 'voice',
      name: 'Роботизированный звонок',
    },
  ];

  beforeMount() {
    this.getSmsTemplateList();
    this.getCompanyInfo();
  }

  mounted() {
    this.addBreadcrumb([
      { id: 1, section: 'Компания', link: null },
      { id: 2, section: 'Настройки', link: null },
      { id: 3, section: 'Шаблоны уведомлений', link: null },
    ]);
    this.getSettings();
    this.$root.$on('Sms-Template', (value) => {
      this.sms[`${value.key}_template`] = value.template;
      this[`sms${value.id}`] = value.text;
    });
    document.title = 'Шаблоны уведомлений';
    CompanyProvider.getSettings()
      .then((result) => {
        this.sms1Method = result.data.repair_income_notification_method;
        this.sms2Method = result.data.repair_done_notification_method;
        this.sms3Method = result.data.repair_feedback_notification_method;
        this.sms4Method = result.data.repair_master_notification_method;
      })
      .catch((error) => {
        console.error(error); // todo: handle error
      });
  }

  changeSms1Method(value) {
    this.sms1Method = value;
  }

  changeSms2Method(value) {
    this.sms2Method = value;
  }

  changeSms3Method(value) {
    this.sms3Method = value;
  }

  changeSms4Method(value) {
    this.sms4Method = value;
  }

  saveNotifications() {
    CompanyProvider.updateSettings({
      repair_done_notification_method: this.sms2Method,
      repair_feedback_notification_method: this.sms3Method,
      repair_income_notification_method: this.sms1Method,
      repair_master_notification_method: this.sms4Method,
    })
      .catch((err) => {
        console.error(err);
      });
    return this.changeSmsTemplate(this.sms);
  }

  @Watch('smsTemplate')
  onLoad(val: object) {
    this.sms = val;
    this.$nextTick(() => {
      this.loading = false;
    });
  }

  @Watch('company')
  getSenderCompany(company) {
    this.sender = company.sms_sender;
  }
}
