import isEqual from 'lodash/isEqual';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { FormLayout } from '@/types';
import ClientsProvider from '../../../../api/providers/clients';
// components
import ErrorAccessRights from '../../../error-access-rights';
import handlerAddRepair from '../api/handlerAddRepair';
// api
import requestOnTemplateForm from '../api/requestOnTemplateForm';
import RepairForm from '../components/forma';
import RepairFormTemplate from '../components/template';
// interface
import { Repair, RepairClient } from '../interface';
// methods
import addBreadcrumbFormAdd from '../methods/addBreadcrumbFormAdd';
import addCartridges from '../methods/addCartridges';
import clearValidateRepair from '../methods/clearValidateRepair';
import modelSelection from '../methods/modelSelection';
import removeCartridges from '../methods/removeCartridges';
import removeCustomFieldItem from '../methods/removeCustomFieldItem';
import selectionAmountForPrepayment from '../methods/selectionAmountForPrepayment';
import typeSelection from '../methods/typeSelection';
import updateClient from '../methods/updateClient';
import valueCustomFields from '../methods/valueCustomFields';
import vendorSelection from '../methods/vendorSelection';

@Component({
  template: require('./index.html'),
  components: {
    'repair-form-template': RepairFormTemplate,
    'error-access-rights': ErrorAccessRights,
  },
})
class RepairFormAdd extends Vue {
    @Action addBreadcrumb;

    @Action getBalance;

    @Getter user;

    @Getter balance;

    client_check_black_list: object[] = [];

    repair_client: RepairClient = {
      name: '',
      contact_phone: null,
      contact_name: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      phone: null,
      status: null,
      address: null,
      repair_discount_type: 0,
      repair_discount: 0,
      id: null,
    };

    data_repair: Repair = {
      bug: '',
      state: '',
      equipment: '',
      acceptor_notes: '',
      objects: [{
        amount: 1, model: '', type: '', vendor: '', device_id: null,
      }],
      client_id: null,
      custom_attributes: [],
      prepayment: { cashbox_id: null, sum: null },
      serial: null,
      imei: null,
      warranty: false,
      master_id: null,
      outbound: false,
      planned_out_date: null,
      send_master_notification: false,
      maintenance: false,
      warrant_size: null,
      planned_price: null,
      express: false,
    };

    error_repair_form: object = {};

    // api

    sendClientCheckBlacklist(item) {
      ClientsProvider.sendClientCheckBlacklist(item)
        .then((res) => { this.client_check_black_list = res.data; });
    }

    getViewClient(id) {
      ClientsProvider.getViewClient(id)
        .then((res) => {
          this.repair_client = res.data;
          this.checkClientOnBlackList({ phone: res.data.phone });
        });
    }

  template_form: FormLayout = { layout: [] };

    loading_form = false;

    get typeFormAdd() {
      const { name } = this.$route;
      if (name) {
        return name.search('repair') !== -1;
      }
      throw new Error('name is null or undefined');
    }

    mounted() {
      this.getBalance();
      typeSelection.call(this);
      vendorSelection.call(this);
      modelSelection.call(this);
      addCartridges.call(this);
      removeCartridges.call(this);
      selectionAmountForPrepayment.call(this);
      valueCustomFields.call(this);
      removeCustomFieldItem.call(this);
      handlerAddRepair.call(this);
      clearValidateRepair.call(this);
      this.idCashBox();
    }

    destroyed() {
      this.$root.$off('typeSelection');
      this.$root.$off('vendorSelection');
      this.$root.$off('modelSelection');
      this.$root.$off('addCartridges');
      this.$root.$off('removeCartridges');
      this.$root.$off('selectionAmountForPrepayment');
      this.$root.$off('valueCustomFields');
      this.$root.$off('removeCustomFieldItem');
      this.$root.$off('handlerAddRepair');
      this.$root.$off('clearValidateRepair');
      this.$root.$off('idCashBox');
    }

    get rightsUserCreateUpdateRepair() {
      return this.user.rights ? this.user.rights.can_create_update_repair : false;
    }

    idCashBox() {
      this.$root.$on('idCashBox', (id) => {
        this.data_repair.prepayment.cashbox_id = id;
      });
    }

    updatingClient(client) {
      const id = Number(this.$route.query.id);

      if (!id) this.data_repair.client_id = client.id;
      updateClient.call(this, client);
    }

    clearRepairClient() {
      this.repair_client = {
        name: '',
        contact_phone: null,
        contact_name: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        phone: null,
        status: null,
        address: null,
        repair_discount_type: 0,
        repair_discount: 0,
        id: null,
      };
    }

    clearRepairForm() {
      this.data_repair = {
        bug: '',
        objects: [{
          amount: 1, model: '', type: '', vendor: '', device_id: null,
        }],
        client_id: null,
        custom_attributes: [],
        prepayment: { cashbox_id: null, sum: null },
        serial: null,
        imei: null,
        warranty: false,
        master_id: null,
        outbound: false,
        planned_out_date: null,
        send_master_notification: false,
        maintenance: false,
        warrant_size: null,
        planned_price: null,
        state: '',
        equipment: '',
        acceptor_notes: '',
        express: false,
      };
    }

    saveDraftRepair(repair) {
      const type = this.typeFormAdd;

      const draft_repair = JSON.parse(localStorage.getItem('draft_repair') as string);

      if (draft_repair) {
        if (!isEqual(draft_repair[`${type ? 'type_1' : 'type_2'}`], repair)) {
          draft_repair[`${type ? 'type_1' : 'type_2'}`] = repair;
          localStorage.setItem('draft_repair', JSON.stringify(draft_repair));
        } else {
          this.removeDraftRepair(type ? 1 : 2);
        }
      } else {
        localStorage.setItem('draft_repair', JSON.stringify({ [`${type ? 'type_1' : 'type_2'}`]: repair }));
      }
    }

    removeDraftRepair = (type) => {
      const draft_repair = JSON.parse(localStorage.getItem('draft_repair') as string);

      switch (type) {
        case 1:
          delete draft_repair.type_1;
          break;
        case 2:
          delete draft_repair.type_2;
          break;
        default: break;
      }

      if (Object.keys(draft_repair).length === 0) {
        localStorage.removeItem('draft_repair');
      } else {
        localStorage.setItem('draft_repair', JSON.stringify(draft_repair));
      }
    }

    checkSaveDraftRepair() {
      const type = this.typeFormAdd;

      const draft_repair = JSON.parse(localStorage.getItem('draft_repair') as string);

      if (draft_repair) {
        if (type && 'type_1' in draft_repair && !isEqual(draft_repair.type_1, this.data_repair)) {
          this.$confirm('У вас есть несохраненный черновик заполнения формы. Загрузить данные из черновика?', {
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
            type: 'info',
          }).then(() => {
            this.data_repair = draft_repair.type_1;
            this.loadClientsBySaveRepair(this.data_repair.client_id);
            this.loadingDataRepairInCustomField(this.data_repair);
            this.loadingPrepaymentData(this.data_repair.prepayment);
          }).catch(() => {
            this.removeDraftRepair(1);
          });
        }

        if (!type && 'type_2' in draft_repair && !isEqual(draft_repair.type_2, this.data_repair)) {
          this.$confirm('У вас есть несохраненный черновик заполнения формы. Загрузить данные из черновика?', {
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
            type: 'info',
          }).then(() => {
            this.data_repair = draft_repair.type_2;
            this.loadClientsBySaveRepair(this.data_repair.client_id);
            this.loadingDataRepairInCustomField(this.data_repair);
            this.loadingPrepaymentData(this.data_repair.prepayment);
          }).catch(() => {
            this.removeDraftRepair(2);
          });
        }
      }
    }

    loadClientsBySaveRepair(id) {
      const query_client_id = this.$route.query.id;

      if (id && !query_client_id) this.getViewClient(id);
    }

    checkClientOnBlackList(phone: object) {
      this.sendClientCheckBlacklist(phone);
    }

    loadingDataRepairInCustomField(repair) {
      const firestRef: RepairFormTemplate = this.$refs.repairFormTemplate as RepairFormTemplate;
      const secondRef = firestRef.$refs.repairForm as RepairForm;
      secondRef.loadingDataRepairInCustomField(repair);
    }

    loadingPrepaymentData(prepayment) {
      const firestRef: RepairFormTemplate = this.$refs.repairFormTemplate as RepairFormTemplate;
      const secondRef = firestRef.$refs.repairForm as RepairForm;
      secondRef.loadingPrepaymentData(prepayment);
    }

    @Watch('$route', { immediate: true })
    watchRoute(route) {
      const { name } = route;

      this.addBreadcrumb(addBreadcrumbFormAdd(name));
      requestOnTemplateForm.call(this, name, 'add', null);
    }

    @Watch('$route.query', { immediate: true })
    watchQuery(query) {
      const id = Number(query.id);
      const draft_repair = JSON.parse(localStorage.getItem('draft_repair') as string);

      if (id) {
        if (draft_repair) {
          draft_repair.client = true;
          localStorage.setItem('draft_repair', JSON.stringify(draft_repair));

          if ('type_1' in draft_repair) {
            if (draft_repair.type_1.client_id !== id) this.removeDraftRepair(1);
          }

          if ('type_2' in draft_repair) {
            if (draft_repair.type_2.client_id !== id) this.removeDraftRepair(2);
          }
        } else {
          localStorage.setItem('draft_repair', JSON.stringify({ client: true }));
        }

        this.getViewClient(id);
      } else {
        if (draft_repair && draft_repair.client) localStorage.removeItem('draft_repair');
        this.clearRepairClient();
      }
    }

    @Watch('data_repair', { deep: true })
    watchDataRepair(repair: Repair) {
      const id = Number(this.$route.query.id);
      const data_repair = repair;

      if (id) data_repair.client_id = id;
      this.saveDraftRepair(data_repair);
    }
}

export default RepairFormAdd;
