export default function addBreadcrumbFormAdd(name) {
  if (name === 'refill-form-add' || name === 'refill-form-clone') {
    return [
      { id: 1, section: 'Ремонт', link: '/repair' },
      { id: 2, section: 'Приём в ремонт', link: null },
      { id: 3, section: 'Новая заправка картриджа', link: null },
    ];
  }
  return [
    { id: 1, section: 'Ремонт', link: '/repair' },
    { id: 2, section: 'Приём в ремонт', link: null },
    { id: 3, section: 'Новый ремонт', link: null },
  ];
}
