import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PricesLevelTable from '@/components/prices-level-table';
import PricesLevelForm from '@/components/prices-level-form';
import ErrorAccessRights from '../../components/error-access-rights';

@Component({
  template: require('./index.html'),
  components: {
    PricesLevelTable,
    PricesLevelForm,
    ErrorAccessRights,
  },
})

export default class PricesPage extends Vue {
    @Action addBreadcrumb;

    @Getter user;

    get userCanUpdateCompanyDetails(): boolean {
      return this.user.rights ? this.user.rights.can_update_company_details : false;
    }

    isAddFormActive = false;

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: null },
        { id: 2, section: 'Настройки', link: null },
        { id: 3, section: 'Цены', link: null },
      ]);
      document.title = 'Цены';
    }

    newPriceLevelAdded() {
      (this.$refs.pricesLevelTable as PricesLevelTable).getAllPriceLevel();
      this.changeIsAddFormActive();
    }

    changeIsAddFormActive() {
      this.isAddFormActive = !this.isAddFormActive;
    }
}
