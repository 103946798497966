import SellProvider from '../../../api/providers/sell';

export default function getSellList(this, data) {
  this.loading_table_sell = true;
  SellProvider.getSellList(data.page, data, data['per-page'])
    .then((res) => {
      const list = res.data;
      const page_count = Number(res.pageCount);
      const page_current = Number(res.pageCurrent);

      if (list.length > 0) {
        list.forEach((item) => this.data_sell.push(item));
      }

      if (page_count !== 0 && page_count !== page_current) {
        const new_page_current = page_current + 1;
        getSellList.call(this, { page: new_page_current, 'per-page': 20, client_id: Number(this.$route.params.id) });
      } else {
        const totalSum = this.data_sell.reduce(
          (sum, sum2) => sum + (Number(sum2.parts_total) + Number(sum2.works_total)),
          0,
        );
        this.data_sell.push({ id: 'Итого:', total: totalSum });
        this.loading_table_sell = false;
      }
    }).catch((err) => {
      console.error(err);
      this.loading_table_sell = false;
    });
}
