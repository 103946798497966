import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface ItemFault {
  label;
}
@Component({
  template: require('./fault.html'),
})
class Fault extends Vue {
    @Prop() fault!: object[];

    @Prop() newfault!: ItemFault;

    dialogEdit = false;

    dialogDelete = false;

    itemFault: ItemFault = { label: null };

    get isDisable() {
      return this.newfault.label <= '';
    }

    isItem(item) {
      this.itemFault = item;
    }

    isAdd(item) {
      this.$root.$emit('addItemBug', item);
    }

    isEdit(item) {
      this.$root.$emit('editItemBug', item);
    }

    isDelete(item) {
      this.$root.$emit('delItemBug', item);
    }
}

export { Fault as default };
