import { MutationTree } from 'vuex';
import {
  GET_STICKER_SETTINGS,
  StateService,
} from './types';

const mutations: MutationTree<StateService> = {

  [GET_STICKER_SETTINGS](state, { item }) {
    state.settingsSticker = item;
  },

};

export default mutations;
