import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import TaskStatusProvider from '@/api/providers/task-status';
import { TaskStatus } from '@/store/task-status/types';
import { PriorityForm } from '@/types';

@Component({
  template: require('./index.html'),
})

export default class TaskStatuses extends Vue {
  @Action addBreadcrumb;

  @Getter btnloader;

  allTaskStatus: TaskStatus[] = []

  fieldUpdateStatus: TaskStatus | object = {}

  newTaskStatus: TaskStatus = {
    name: '',
    color: '',
  };

  updateTaskStatusItem: TaskStatus = {
    name: '',
    color: '',
  };

  isModalUpdateTaskStatusShow = false;

  updateTaskStatusesError = {}

  mounted() {
    this.getAllTaskStatuses();
    this.changePageInterface();
  }

  changePageInterface() {
    this.addBreadcrumb([
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Справочники', link: null },
      { id: 3, section: 'Статусы задач', link: null },
    ]);
    document.title = 'Статусы задач';
  }

  getAllTaskStatuses() {
    TaskStatusProvider.getAllStatuses()
      .then((allResStatus) => {
        this.allTaskStatus = allResStatus;
      })
      .catch((err) => {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось поулчить список статусов' });
        throw new Error(err);
      });
  }

  addNewTaskStatus() {
    this.$store.commit('BTN_LOADER', true);
    TaskStatusProvider.addNewTaskStatus(this.newTaskStatus as TaskStatus)
      .then((res) => {
        this.allTaskStatus.unshift(res);
        this.clearNewTaskStatus();
        this.$store.commit('BTN_LOADER', false);
      })
      .catch((err) => {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось добавить статус' });
        this.$store.commit('BTN_LOADER', false);
        this.updateTaskStatusesError = err.response.data;
        throw new Error(err);
      });
  }

  showModalUpdateTaskStatus(taskStatus: TaskStatus) {
    this.updateTaskStatusesError = {};
    this.isModalUpdateTaskStatusShow = true;
    this.updateTaskStatusItem = { ...taskStatus };
  }

  updateTaskStatus() {
    if (this.updateTaskStatusItem.id) {
      this.$store.commit('BTN_LOADER', true);
      TaskStatusProvider.updateTaskStatus(this.updateTaskStatusItem.id, this.updateTaskStatusItem)
        .then((res) => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Статус задачи обновлен' });
          this.allTaskStatus = this.allTaskStatus.map((taskStatus) => {
            if (taskStatus.id === res.data.id) {
              return res.data;
            }
            return taskStatus;
          });
          this.$store.commit('BTN_LOADER', false);
          this.isModalUpdateTaskStatusShow = false;
        })
        .catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось обновить статус задачи' });
          this.$store.commit('BTN_LOADER', false);
          this.updateTaskStatusesError = err.response.data;
          throw new Error(err);
        });
    }
  }

  removeTaskStatus(taskStatus: TaskStatus, index: number) {
    this.$store.commit('BTN_LOADER', true);
    TaskStatusProvider.deleteTaskStatus(taskStatus.id as number)
      .then(() => {
        this.allTaskStatus.splice(index, 1);
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Статус задачи удален' });
        this.$store.commit('BTN_LOADER', false);
      })
      .catch((err) => {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось удалить статус задачи' });
        this.$store.commit('BTN_LOADER', false);
        throw new Error(err);
      });
  }

  modalUpdateTaskStutsClose() {
    this.updateTaskStatusesError = {};
  }

  validateUpdateForm = (obj: object, key: string) => {
    if (Object.keys(obj).some((v) => v === key)) {
      return obj[key][0];
    }
    return null;
  }

  arrowUp(taskStatus: TaskStatus, lastPosition: number) {
    const statusId = taskStatus.id as number;
    const position = this.allTaskStatus.length - lastPosition + 1;

    const form: PriorityForm = {
      item_id: statusId,
      position,
    };
    this.changeTaskStatusPriority(form);
  }

  arrowDown(taskStatus: TaskStatus, lastPosition: number) {
    const statusId = taskStatus.id as number;
    const position = this.allTaskStatus.length - lastPosition - 1;

    const form: PriorityForm = {
      item_id: statusId,
      position,
    };
    this.changeTaskStatusPriority(form);
  }

  changeTaskStatusPriority(form: PriorityForm) {
    TaskStatusProvider.changeTaskStatusPriority(form)
      .then(() => {
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Приоритет у задачи обновлен' });
        this.getAllTaskStatuses();
      })
      .catch((err) => {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Приоритет у задачи не обновлен' });
        this.$store.commit('BTN_LOADER', false);
        throw new Error(err);
      });
  }

  clearNewTaskStatus() {
    this.newTaskStatus = {
      name: '',
      color: '',
      completed: false,
    };
  }
}
