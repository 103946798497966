// Mutations Type

import { PostingPart } from '@/types';

export const GET_POSTING = 'GET_POSTING';
export const GET_PAGE_POSTING = 'GET_PAGE_POSTING';
export const GET_POSTING_VIEW = 'GET_POSTING_VIEW';
export const SEND_POSTING_ADD = 'SEND_POSTING_ADD';
export const CHANGE_POSTING_PART = 'CHANGE_POSTING_PART';
export const POSTING_VALIDATION = 'POSTING_VALIDATION';

export const SEND_POSTING_ITEM = 'SEND_POSTING_ITEM';
export const DELETE_POSTING_ITEM = 'DELETE_POSTING_ITEM';
export const DELETE_POSTING_PART_ITEM = 'DELETE_POSTING_PART_ITEM';
export const REVERT_POSTING_ITEM = 'REVERT_POSTING_ITEM';

export const CLEAR_POSTING_ITEM = 'CLEAR_POSTING_ITEM';
export const REVERT_POSTING = 'REVERT_POSTING';
// Store

export interface StatePosting {
    posting: object[];
    postingAdd: object;
    postingView: object;
    postingPageCurrent: number;
    postingPageCount: number;
    postingValid: object[];
    postingNewItemList: PostingPart[];
    postingChangePart: object;
    postingRevert: object;
}
