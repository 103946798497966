import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AnalyticsWidget } from '@/types';

@Component({
  template: require('./index.html'),
  components: {

  },
})
export default class SettingAnalyticsWidgets extends Vue {
    @Prop() widgetsInDashboard!: AnalyticsWidget[];

    @Prop() widgetsOutDashboard!: AnalyticsWidget[];

    selectedWidgetsInDashboard: object[] = [];

    selectedWidgetsOutDashboard: object[] = [];

    onChangeSelectedWidgetsInDashboard() {
      this.selectedWidgetsInDashboard = this.widgetsInDashboard.filter((active) => active.active);
    }

    sortWidgetsAsTransfer = (a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    }

    onChangeSelectedWidgetsOutDashboard() {
      this.selectedWidgetsOutDashboard = this.widgetsOutDashboard.filter((active) => active.active);
    }

    transferWidgetInDashboard() {
      this.$emit('transferWidgetInDashboard', this.selectedWidgetsOutDashboard);
      this.selectedWidgetsOutDashboard = [];
    }

    transferWidgetOutDashboard() {
      this.$emit('transferWidgetOutDashboard', this.selectedWidgetsInDashboard);
      this.widgetsOutDashboard.sort(this.sortWidgetsAsTransfer);
      this.selectedWidgetsInDashboard = [];
    }

    transferWidgetUp(id) {
      const elementSelectedIndex = this.widgetsInDashboard.findIndex(
        (element) => element.id === id,
      );
      const elementUp = elementSelectedIndex === 0
        ? this.widgetsInDashboard[this.widgetsInDashboard.length - 1]
        : this.widgetsInDashboard[elementSelectedIndex - 1];
      const elementSelected = this.widgetsInDashboard[elementSelectedIndex];

      const elementUpIndex = this.widgetsInDashboard.findIndex(
        (element) => element.id === elementUp.id,
      );
      this.widgetsInDashboard.splice(elementUpIndex, 1, elementSelected);
      this.widgetsInDashboard.splice(elementSelectedIndex, 1, elementUp);
    }

    transferWidgetDown(id) {
      const elementSelectedIndex = this.widgetsInDashboard.findIndex(
        (element) => element.id === id,
      );
      const elementDown = elementSelectedIndex === this.widgetsInDashboard.length - 1
        ? this.widgetsInDashboard[0]
        : this.widgetsInDashboard[elementSelectedIndex + 1];
      const elementSelected = this.widgetsInDashboard[elementSelectedIndex];

      const elementDownIndex = this.widgetsInDashboard.findIndex(
        (element) => element.id === elementDown.id,
      );
      this.widgetsInDashboard.splice(elementDownIndex, 1, elementSelected);
      this.widgetsInDashboard.splice(elementSelectedIndex, 1, elementDown);
    }
}
