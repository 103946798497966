import { ActionTree } from 'vuex';
import {
  PushInterface, PUSH_CALL_ERROR, PUSH_CALL_INFO, PUSH_CALL_SUCCESS, PUSH_CALL_WARNING, StatePush,
} from './types';

const actions: ActionTree<StatePush, object> = {

  showErrorPush({ commit }, push: PushInterface) {
    commit(PUSH_CALL_ERROR, push);
  },

  showSuccessPush({ commit }, push: PushInterface) {
    commit(PUSH_CALL_SUCCESS, push);
  },

  showWarningPush({ commit }, push: PushInterface) {
    commit(PUSH_CALL_WARNING, push);
  },

  showInfoPush({ commit }, push: PushInterface) {
    commit(PUSH_CALL_INFO, push);
  },

};

export default actions;
