import { ActionTree } from 'vuex';
import CompanyProvider from '../../api/providers/company';
import {
  EDIT_BILLING, EDIT_COMPANY, EDIT_SETTINGS, GET_BILLING, GET_COMPANY, GET_SETTINGS, StateCompany,
} from './types';

const actions: ActionTree<StateCompany, object> = {

  getCompanyInfo({ commit }) {
    CompanyProvider.getCompanyInfo()
      .then((res) => {
        commit(GET_COMPANY, res.data);
      })
      .catch();
  },

  changeCompanyInfo({ commit }, item) {
    CompanyProvider.changeCompanyInfo(item)
      .then(() => {
        commit(EDIT_COMPANY, item);
      })
      .catch();
  },

  getBilling({ commit }) {
    CompanyProvider.getBilling()
      .then((res) => {
        commit(GET_BILLING, res.data);
      })
      .catch();
  },

  updateBilling({ commit }, item) {
    commit('BTN_LOADER', true);
    CompanyProvider.changeBilling(item)
      .then(() => {
        commit(EDIT_BILLING, item);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: 'Информация о компании сохранена', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  getSettings({ commit }) {
    CompanyProvider.getSettings()
      .then((res) => {
        commit(GET_SETTINGS, res.data);
      })
      .catch();
  },

  updateSettings({ commit }, item) {
    commit('BTN_LOADER', true);
    CompanyProvider.updateSettings(item)
      .then(() => {
        commit(EDIT_SETTINGS, item);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: 'Настройка успешно сохранена!', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },
};

export default actions;
