export const AUTH_IN = 'AUTH_IN';
export const AUTH_OUT = 'AUTH_OUT';
export const AUTH_ERROR = 'AUTH_ERROR';

// Store

export interface StateAuth {
    auth: boolean;
    authError: {};
}
