import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Numbers from '../forms/numbers';

@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
  },

})

export default class SalaryByShares extends Vue {
    @Prop() stuffItem;

    @Prop(String) mode;

    @Getter sellPartSalaryTypeList;

    @Getter repairPartSalaryType;

    selectedRepairIncomeSalaryType = (type) => {
      switch (type) {
        case 0: return '₽';
        case 1: return '(% от стоимости)';
        case 2: return '(% от прибыли)';
        default: return '–';
      }
    }
}
