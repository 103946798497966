import BaseProvider from '../base-provider';

class CancelProvider extends BaseProvider {
  async getCancelList(page, form = null) {
    const expand: string[] = ['total_sum', 'total_count', 'user', 'storage', 'provider', 'last_comment.user'];
    let res;
    if (form) {
      const searchForm: string = this.buildUrl({ CancelSearchForm: form });
      res = await this.request('GET', `/cancel/index?page=${page}&expand=${expand}&${searchForm}`);
    } else {
      res = await this.request('GET', `/cancel/index?page=${page}&expand=${expand}`);
    }
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getViewCancel(id) {
    const expand: string[] = ['user', 'parts', 'penalty_user', 'storage', 'parts.part', 'parts.part.cancel_price'];
    const res = await this.request('GET', `/cancel/view?id=${id}&expand=${expand}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendCancel(item) {
    const data: object = { CancelForm: item };
    const res = await this.request('POST', '/cancel/apply', data);
    return res;
  }

  async sendRevert(item) {
    const data: object = { CancelRevertForm: item };
    const res = await this.request('POST', '/cancel/revert', data);
    return res;
  }

  async sendRevertPart(item) {
    const data: object = { CancelPartRevertForm: item };
    const res = await this.request('POST', '/cancel/revertpart', data);
    return res;
  }

  async getCancelComments(item: CancelCommentSearchForm, page = 1) {
    const expand: string[] = ['user'];
    const searchForm: string = this.buildUrl({ CancelCommentSearchForm: item });

    const res = await this.request('GET', `/cancelcomment/index?page=${page}&expand=${expand}${searchForm}`);
    return {
      data: res.data as CancelComment[],
      pageCount: Number(res.headers['x-pagination-page-count']),
    };
  }

  async sendCancelComment(form: CancelCommentAddForm) {
    try {
      return await this.request('POST', '/cancelcomment/add?expand=user', { CancelCommentForm: form });
    } catch (error) {
      throw new Error(error);
    }
  }

  async deleteCancelComment(id: number) {
    try {
      return await this.request('POST', `/cancelcomment/delete?id=${id}`);
    } catch (err) {
      throw new Error(err);
    }
  }
}

export default new CancelProvider();

export interface CancelCommentSearchForm {
    cancel_id: number;
    include_deleted: boolean;
}
export interface CancelComment {
    id: number;
    cancel_id: number;
    user_id: number;
    comment: string;
    created_at: number;
    user: object | null;
    is_deleted: boolean;
}

export interface CancelCommentAddForm {
    cancel_id: number;
    content: string;
}
