import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Repair, SmsMessageSearchForm, Response } from '@/types';
import SmsTable from '@/components/sms-table';
import SmsProvider from '@/api/providers/sms';
import RepairProvider from '../../../api/providers/repair';
import ViewDate from '../../forms/date';
import Numbers from '../../forms/numbers';

export interface FormUpdateRepairAccepting {
  repair_id: number | null;
  acceptor_id: number | null;
  accepted_at: number | null;
}

@Component({
  template: require('./info.html'),
  components: {
    'repair-date': ViewDate,
    'total-price': Numbers,
    SmsTable,
  },
})
export default class RepairViewInfo extends Vue {
  @Prop() acceptorName!: string;

  @Prop() totalPriceWithVat!: number;

  @Prop() StuffList!: object[];

  @Prop() repair!: Repair;

  @Getter user;

  view_modal_edit_repair_accepting = false;

  form_repair_accepting: FormUpdateRepairAccepting = {
    repair_id: null,
    acceptor_id: null,
    accepted_at: null,
  };

  form_repair_accepting_rules = {
    acceptor_id: [
      { required: true, message: 'Необходимо выбрать приемщика', trigger: 'blur' },
    ],
    accepted_at: [
      { required: true, message: 'Необходимо выбрать дату приема', trigger: 'blur' },
    ],
  };

  loading_form_repair_accepting = false;

  smsTableData: object[] = [];

  smsTableLoading = false;

  get flagsRepairAttributes() {
    if (this.repair.repair_attributes !== undefined) return this.repair.repair_attributes.filter((x) => x.value !== null && x.value !== '').length !== 0;
    return [];
  }

  get rightsUpdateRepairAccepting() {
    if (this.user.rights) return this.user.rights.can_update_repair_accepting;
    return false;
  }

  get totalPrice() {
    if (this.totalPriceWithVat !== undefined) return this.totalPriceWithVat;
    return 0;
  }

  get repairAttributes() {
    if (this.repair.repair_attributes !== undefined) return this.repair.repair_attributes;
    return [];
  }

  get repairPoint() {
    try {
      return this.repair.accepted_point.name;
    } catch {
      return '---';
    }
  }

  get repairPlannedOutDateIntervalTS(): number {
    if (!this.repair || !this.repair.planned_out_date) return 0;
    const doneAtTS = Math.round((new Date()).getTime() / 1000);
    return doneAtTS - this.repair.planned_out_date - 86400;
  }

  get repairPlannedOutDateIntervalStyle(): object {
    if (this.repairPlannedOutDateIntervalTS > 0) {
      return {
        color: '#b60000',
      };
    }
    return {};
  }

  get repairPlannedOutDateInterval(): string {
    const diff = this.repairPlannedOutDateIntervalTS;
    if (diff === 0) return '';
    let result = '';
    if (diff < 0) {
      result += '(';
      result += this.getRelativeDateDescription(Math.abs(diff));
      result += ')';
    } else {
      result += '(просрочен на ';
      result += this.getRelativeDateDescriptionExpired(diff);
      result += ')';
    }
    return result;
  }

  /**
   * Возвращает текстовое описание разницы во времени
   * @param timeOffset разница между двумя метками времени в секундах
   */
  getRelativeDateDescription = (timeOffset: number) => {
    const days = Math.ceil(timeOffset / (60 * 60 * 24));
    if (days > 0) {
      if (days === 1) return 'сегодня';
      if (days === 2) return 'завтра';
      return `через ${days - 1} дня(ей)`;
    }
    return '';
  }

  getRelativeDateDescriptionExpired = (timeOffset: number) => {
    const days = Math.ceil(timeOffset / (60 * 60 * 24));
    if (days > 0) {
      if (days === 1) return '1 день';
      return `${days} дня(ей)`;
    }
    return '';
  }

  handleUpdateRepairAccepting() {
    (this.form_repair_accepting.accepted_at as number) = (
      this.form_repair_accepting.accepted_at as number
    ) / 1000;
    this.loading_form_repair_accepting = true;

    RepairProvider.sendRepairUpdateAccepting(this.form_repair_accepting)
      .then((res) => {
        this.$emit('updateRepairAccpting', res.data);

        this.loading_form_repair_accepting = false;
        this.view_modal_edit_repair_accepting = false;
      }).catch((err) => {
        this.loading_form_repair_accepting = false;
        console.log(err.response.data);
      });
  }

  async getSmsList() {
    const smsMessageSearchForm: SmsMessageSearchForm = {
      repair_id: this.repair.id,
    };
    let page = 1;

    try {
      this.smsTableLoading = true;
      const firstRes = await SmsProvider.getSmsList(page, smsMessageSearchForm);
      this.smsTableData = firstRes.data;
      const { pageCount } = firstRes;
      if (pageCount > 1) {
        page += 1;
        const promiseResponse: object[] = [];
        for (page; page <= pageCount; page++) {
          promiseResponse.push(SmsProvider.getSmsList(page, smsMessageSearchForm));
        }
        await Promise.all(promiseResponse)
          .then((result) => {
            result.forEach((res) => {
              const { data } = (res as Response);
              this.smsTableData = [...this.smsTableData, ...data as object[]];
            });
          });
      }
      this.smsTableLoading = false;
    } catch (err) {
      this.smsTableLoading = false;
      throw new Error(err);
    }
  }

  @Watch('repair')
  viewRepair(repair) {
    this.form_repair_accepting.accepted_at = repair.income_date * 1000;
    this.form_repair_accepting.repair_id = repair.id;
    this.form_repair_accepting.acceptor_id = repair.acceptor_id;
    this.getSmsList();
  }
}
