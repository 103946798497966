import { MutationTree } from 'vuex';
import {
  BTN_LOADER, PRELOADER_ACTIVE,

  StatePreloader,
} from './types';

const mutations: MutationTree<StatePreloader> = {

  [PRELOADER_ACTIVE](state, active) {
    state.preloader = active;
  },

  [BTN_LOADER](state, active) {
    state.btnloader = active;
  },
};

export default mutations;
