import { AxiosResponse } from 'axios/index.d';
import { RepairStatus } from '@/types';
import BaseProvider from '../base-provider';

class UserStatusProvider extends BaseProvider {
  async getAllUserStatuses(): Promise<Array<RepairStatus>> {
    let result: Array<RepairStatus> = [];
    let page = 1;
    let pageCount = 1;
    const batch = await this.getUserStatusList(page);
    result = [...result, ...batch.data];
    pageCount = batch.pageCount;
    page += 1;
    if (pageCount > 1) {
      const promiseRequest: object[] = [];
      for (page; page <= pageCount; page++) {
        promiseRequest.push(this.getUserStatusList(page));
      }
      await Promise.all(promiseRequest)
        .then((results) => {
          (results as AxiosResponse[]).forEach((elem) => {
            result = [...result, ...elem.data];
          });
          result.sort((a, b) => b.id - a.id);
        });
    }
    return result;
  }

  async getUserStatusList(page): Promise<ApiCustomRepairStatusListResponse> {
    const res = await this.request('GET', `/repairstatus/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      currentPage: res.headers['x-pagination-current-page'],
    };
  }

  async getViewUserStatus(id) {
    const res = await this.request('GET', `/repairstatus/view?id=${id}`);
    return res;
  }

  async sendUserStatus(item) {
    const data: object = { RepairStatus: item };
    const res = await this.request('POST', '/repairstatus/add', data);
    return res;
  }

  async changeUserStatus(item) {
    const data: object = { RepairStatus: item };
    const res = await this.request('POST', `/repairstatus/update?id=${item.id}`, data);
    return res;
  }

  async deleteUserStatus(id) {
    const res = await this.request('POST', `/repairstatus/delete?id=${id}`);
    return res;
  }
}

export default new UserStatusProvider();

export interface ApiCustomRepairStatusListResponse {
    data: Array<RepairStatus>;
    pageCount: number;
    currentPage: number;
}
