import { GetterTree } from 'vuex';
import { StateStorage } from './types';

const getters: GetterTree<StateStorage, object> = {
  storages: (state) => state.storages,
  validStorage: (state) => state.validStorage,
  pageStorCount: (state) => state.pageStorCount,
  pageStorCurrent: (state) => state.pageStorCurrent,
};

export default getters;
