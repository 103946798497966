import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

interface Field {
  type;
}
@Component({
  template: require('./index.html'),
  components: {},
})

export default class FieldCustom extends Vue {
    @Prop({ required: true }) field!: Field;

    @Prop({ required: true }) customField!: object;

    @Prop({ required: true }) repair!: object;

    @Prop({ required: true }) masters!: object[];

    get typeInput() {
      const { field } = this;

      switch (field.type) {
        case 1: return 'text';
        case 2: return 'number';
        default: return 'text';
      }
    }

    get displayInput() {
      return this.field.type === 1 || this.field.type === 2;
    }

    get displayCheckbox() {
      return this.field.type === 3;
    }

    get displayDate() {
      return this.field.type === 4;
    }

    get displaySelect() {
      return this.field.type === 999;
    }
}
