import { GetterTree } from 'vuex';
import { StateUser } from './types';

const getters: GetterTree<StateUser, object> = {
  user: (state) => state.user,
  validNewPassword: (state) => state.validNewPassword,
  updateUser: (state) => state.updateUser,
};

export default getters;
