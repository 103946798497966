import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ViewDate from '../../forms/date/index';
import Numbers from '../../forms/numbers';

@Component({
  template: require('./index.html'),
  components: {
    ViewDate,
    Numbers,
  },
})

export default class SalaryTransferTable extends Vue {
    @Prop(Array) data;

    @Prop(Boolean) loading;

    onView(row) {
      if ((window.getSelection() as Selection).toString().trim().length !== 0) return;
      this.$router.push(`/salary/transfer/${row.user_id}/${row.id}`);
    }
}
