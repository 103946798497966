import { GetterTree } from 'vuex';
import { StateWork } from './types';

const getters: GetterTree<StateWork, object> = {
  work: (state) => state.work,
  viewWork: (state) => state.viewWork,
  workValid: (state) => state.workValid,
  workPageCount: (state) => state.workPageCount,
  workPageCurrent: (state) => state.workPageCurrent,
  updateWork: (state) => state.updateWork,
};

export default getters;
