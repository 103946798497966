import { GetterTree } from 'vuex';
import { TaskState } from './types';

const getters: GetterTree<TaskState, object> = {
  taskList: (state) => state.taskList,
  currentTask: (state) => state.currentTask,
  validTask: (state) => state.validTask,
  lastSavedTaskId: (state) => state.lastSavedTaskId,
  pageTaskCount: (state) => state.pageTaskCount,
  pageTaskCurrent: (state) => state.pageTaskCurrent,
};

export default getters;
