import Vue from 'vue';
import { RawLocation } from 'vue-router/types/router.d';
import { DatePickerOptions } from 'element-ui/types/date-picker.d';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import LogRequestProvider from '@/api/providers/log-request';
import RepairTableRow from '@/components/repair-table-row';
import {
  UserForm, RepairForm, RepairColumn as Column, LogRequest, Point,
} from '@/types';
import RepairProvider from '../../api/providers/repair';
import ModalRewiew from '../rewiew';
import SettingTableRepairs from '../setting-table-repairs';
// api
import getRepairList from './api/getRepairList';
import getRepairStat from './api/getRepairStat';
import getUserStatusList from './api/getUserStatusList';
import sendRepairApproveAgreement from './api/sendRepairApproveAgreement';
import sendRepairContinue from './api/sendRepairContinue';
import sendRepairCustomStatus from './api/sendRepairCustomStatus';
import sendRepairDeclineAgreement from './api/sendRepairDeclineAgreement';
import sendRepairDelay from './api/sendRepairDelay';
import sendRepairDone from './api/sendRepairDone';
import sendRepairEject from './api/sendRepairEject';
import sendRepairRestart from './api/sendRepairRestart';
import sendRepairRevision from './api/sendRepairRevision';
import sendRepairStart from './api/sendRepairStart';
import sendRepairToAgreement from './api/sendRepairToAgreement';
import {
  REPAIR_TABLE_SORT,
  Content,
  FilterQuery,
  StatusButtons,
} from './types';

@Component({
  template: require('./repair.html'),
  components: {
    'setting-table-repairs': SettingTableRepairs,
    'modal-rewiew': ModalRewiew,
    RepairTableRow,
  },
})
export default class Repair extends Vue {
    @Prop({ default: () => [] }) pointHasAccess!: Point[];

    @Action getStuffList;

    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action getRepairTableLayout;

    @Action sendRepairTableLayout;

    @Getter point;

    @Getter btnloader;

    @Getter stuff;

    @Getter user;

    @Getter company;

    @Getter repairNoActiveColumn;

    @Getter repairActiveColumn;

    @Getter newRepairLayout;

    dateTimePickerOptions: DatePickerOptions = {
      firstDayOfWeek: 1,
      shortcuts: [
        {
          text: 'Сегодня',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            picker.$emit('pick', [start, end]);
          },
        },
        {
          text: 'Вчера',
          onClick(picker) {
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            const end = new Date();
            end.setHours(0, 0, 0, 0);
            picker.$emit('pick', [start, end]);
          },
        },
        {
          text: 'С начала недели',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            let dayOfWeek = start.getDay();
            if (dayOfWeek === 0) dayOfWeek = 7;
            start.setHours(0, 0, 0, 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * (dayOfWeek - 1));
            picker.$emit('pick', [start, end]);
          },
        },
        {
          text: 'Последние 7 дней',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        },
        {
          text: 'С начала месяца',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setDate(1);
            picker.$emit('pick', [start, end]);
          },
        },
        {
          text: 'Последние 30 дней',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        },
        {
          text: 'В прошлом месяце',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setDate(1);
            start.setMonth(start.getMonth() - 1);
            end.setHours(0, 0, 0, 0);
            end.setDate(1);
            picker.$emit('pick', [start, end]);
          },
        },
      ],
    };

    dropdown_for_custom_status_name_1: object[] = [];

    dropdown_for_custom_status_name_2: object[] = [];

    dropdown_for_custom_status_name_3: object[] = [];

    dropdown_for_custom_status_name_4: object[] = [];

    dropdown_for_custom_status_name_5: object[] = [];

    dropdown_for_custom_status_name_6: object[] = [];

    content: Content = {
      'per-page': 50,
      accepted_point_id: '',
      current_point_id: '',
      acceptor_id: '',
      search_string: '',
      master_id: '',
      status_ids: '',
      created_at_start: '',
      created_at_end: '',
      done_at_start: '',
      done_at_end: '',
      ejected_at_start: '',
      ejected_at_end: '',
      planned_out_date_start: '',
      planned_out_date_end: '',
      sort: REPAIR_TABLE_SORT.INCOME_DESC,
    };

    createdAtDateTimeForm: Date[] = [];

    doneAtDateTimeForm: Date[] = [];

    ejectedAtDateTimeForm: Date[]= [];

    plannedOutDateTimeForm: Date[] = [];

    showAdditionalFilters = false;

    shortcotsOfTable: object[] = [
      { name: 'Все заказы', shortcut: 7 },
      { name: 'Принято сегодня', shortcut: 1 },
      { name: 'Выдано сегодня', shortcut: 2 },
      { name: 'Должны быть выданы сегодня', shortcut: 3 },
      { name: 'Должны быть выданы в течении 3 дней', shortcut: 4 },
      { name: 'Должны быть выданы в течении 7 дней', shortcut: 5 },
      { name: 'Ожидают выдачи более 3 дней', shortcut: 6 },
    ];

    status_buttons: StatusButtons[] = [
      {
        name: 'Актуальные', icon: 'fas fa-table', status: null, modiff: 'all',
      },
      {
        name: 'Принято', icon: 'fas fa-arrow-down', status: 1, modiff: '1',
      },
      {
        name: 'В работе', icon: 'fas fa-wrench', status: 2, modiff: '2',
      },
      {
        name: 'На согласовании', icon: 'fas fa-comments', status: 6, modiff: '6',
      },
      {
        name: 'Ожидает', icon: 'fas fa-clock', status: 3, modiff: '3',
      },
      {
        name: 'Готово', icon: 'fas fa-check', status: 4, modiff: '4',
      },
      {
        name: 'Выдано', icon: 'fas fa-plane repair__icons_rotate', status: 5, modiff: '5',
      },
    ];

    isStatusBtnActive(btnId) {
      const selectedStatuses = (this.content.status_ids as string).split(',') as Array<string>;
      return selectedStatuses.indexOf(String(btnId)) !== -1;
    }

    masters: UserForm[] = [];

    acceptor: object[] = [];

    button_no_engineer = false;

    active = 0;

    scroll_active = true;

    active_column: Column[] = [];

    noactive_column: Column[] = [];

    data_repairs: RepairForm[] = [];

    draw_repair_table = true;

    page_current: number | null= null;

    page_count_repair: number | null = null;

    page_user_status_list = 1;

    open_dialog_settings = false;

    amount_without_master: number | null = null;

    filter_query: FilterQuery = {};

    searchDelayTimer: number | null = null;

    repairLoadPromiseId: number | null = null;

    expands_repairs: string[] = ['accepted_point', 'current_point', 'custom_status', 'client', 'last_transfer'];

    get anyAdditionalFilterIsSet() {
      return this.content.created_at_start
          || this.content.created_at_end
          || this.content.done_at_start
          || this.content.done_at_end
          || this.content.ejected_at_start
            || this.content.ejected_at_end
          || this.content.planned_out_date_start
          || this.content.planned_out_date_end;
    }

    timeStartMounting = 0;

    tableWasUpdate = false;

    beforeMount() {
      this.timeStartMounting = new Date().getTime();
    }

    mounted() {
      document.title = 'Список заказов - программа для сервисных центров Servix';
      this.addBreadcrumb([
        { id: 1, section: 'Ремонт', link: null },
        { id: 2, section: 'Текущие заказы в сервисе', link: null },
      ]);
      this.addActionBtn([
        {
          id: 1, type: 'add', title: 'Новый ремонт', link: '/repair/form/add', icon: 'fa-plus',
        },
        {
          id: 2, type: 'edt', title: 'Новая продажа', link: '/sell/add', icon: 'fa-plus',
        },
      ]);
      this.getStuffList(1);
      getUserStatusList.call(this, 1);
      if ('status' in this.$route.query) {
        const query: string = this.activateStatus(String(this.$route.query.status)).join(',');
        this.filter_query.status = query;
        this.content.status_ids = query;
        this.changeStatus(query);
      } else {
        this.filter_query.status = '1,2,3,4,6';
        this.content.status_ids = '1,2,3,4,6';
        this.changeStatus('1,2,3,4,6');
      }
    }

    destroyed() {
      this.addActionBtn([]);
    }

    updated() {
      if (this.data_repairs.length > 0 && !this.btnloader && !this.tableWasUpdate) {
        this.pageAlready();
      }
    }

    pageAlready() {
      this.tableWasUpdate = true;
      const now: number = new Date().getTime();
      const name = 'OPEN_PAGE';
      const date = now;
      const payload = {
        url: this.$route.fullPath,
        elapsed: now - this.timeStartMounting,
      };
      const userId = this.user.id ?? null;
      const serviceId = this.company.id ?? null;

      const log: LogRequest = {
        name,
        date,
        payload,
        userId,
        serviceId,
      };

      LogRequestProvider.req(log);
    }

    async distributionExpandsRepairs() {
      await this.getRepairTableLayout();
      this.active_column.forEach((column) => {
        if (column.value === 'clientSource') {
          this.expands_repairs.push('client.source');

          this.expands_repairs = this.expands_repairs.filter((item) => item !== 'client');
        }

        if (column.value === 'device') this.expands_repairs.push('repair_objects');
        if (column.value === 'master') this.expands_repairs.push('master');
        if (column.value === 'acceptor') this.expands_repairs.push('acceptor');
        if (column.value === 'ejector') this.expands_repairs.push('ejector');
        if (column.value === 'prepayment') this.expands_repairs.push('repair_payments');
      });
    }

    updatedRepairAfterChangeStatus(item) {
      if (this.data_repairs.length !== 0) {
        const indexRepair = this.data_repairs.findIndex((element) => element.id === item.id);
        this.data_repairs.splice(indexRepair, 1, item);
      }
    }

    buildTableColumnClass = (active_column) => {
      let className = '';

      if (active_column.value === 'phone') {
        className += 'td_nowrap';
      }
      return className;
    }

    formattingStuff = (stuff: UserForm) => {
      if (stuff) {
        const lastName: string = stuff.last_name ? stuff.last_name : '';
        const firstName: string = stuff.first_name ? `${stuff.first_name.slice(0, 1).toUpperCase()}.` : '';
        const middleName: string = stuff.middle_name ? `${stuff.middle_name.slice(0, 1).toUpperCase()}.` : '';
        return `${lastName} ${firstName} ${middleName}`;
      }
      return '-';
    }

    clearContent() {
      Object.keys(this.content).forEach((key) => {
        if (this.content[key] === null) this.content[key] = '';
      });
    }

    clearForm() {
      this.content = {
        'per-page': 50,
        accepted_point_id: '',
        current_point_id: '',
        acceptor_id: '',
        search_string: '',
        master_id: '',
        status_ids: '1,2,3,4,6',
        created_at_start: '',
        created_at_end: '',
        done_at_start: '',
        done_at_end: '',
        ejected_at_start: '',
        ejected_at_end: '',
        planned_out_date_start: '',
        planned_out_date_end: '',
        sort: REPAIR_TABLE_SORT.INCOME_DESC,
      };
      this.page_current = 1;
      this.createdAtDateTimeForm = [];
      this.doneAtDateTimeForm = [];
      this.plannedOutDateTimeForm = [];
      this.ejectedAtDateTimeForm = [];

      if (this.filter_query.status) delete this.filter_query.status;
      const location = { query: { ...this.$route.query, ...this.filter_query } };
      this.$router.push(location as RawLocation);

      this.clearDataRepairs();
      this.scroll_active = false;
      this.search();
    }

    clearDataRepairs() {
      this.data_repairs = [];
    }

    selectedStatusAmount = (button) => (button.amount ? `(${button.amount})` : '–')

    loadDataRepairsOnScroll() {
      if ((Number(this.page_count_repair) !== this.page_current) && this.scroll_active) {
        (this.page_current as number) += 1;
        this.loadRepairList();
        this.scroll_active = false;
      }
    }

    transferColumnAtInactive(active) {
      this.active_column = this.active_column.filter((column) => !column.active);
      active.forEach((column) => this.noactive_column.push(column));

      this.noactive_column.forEach((column, i) => {
        if (column.active) delete this.noactive_column[i].active;
      });
    }

    transferColumnAtActive(inactive) {
      this.noactive_column = this.noactive_column.filter((column) => !column.active);
      inactive.forEach((column) => this.active_column.push(column));

      this.active_column.forEach((column, i) => {
        if (column.active) delete this.active_column[i].active;
      });
    }

    saveSettingsTable() {
      const activeTable = this.active_column.map((element) => element.value);
      this.expands_repairs = ['accepted_point', 'current_point', 'custom_status', 'client', 'last_transfer'];
      this.draw_repair_table = false;
      this.sendRepairTableLayout({ layout: JSON.stringify(activeTable) });
      this.search();
    }

    resetFilters() {
      this.clearDataRepairs();
      this.page_current = 1;
      this.clearContent();
      this.scroll_active = false;
    }

    search() {
      this.resetFilters();
      this.loadRepairList();
    }

    loadRepairList() {
      if (this.searchDelayTimer != null) {
        clearTimeout(this.searchDelayTimer);
        this.repairLoadPromiseId = Math.round(Math.random() * 10_000_000);
      }
      this.searchDelayTimer = window.setTimeout(() => {
        const contentClone = { ...this.content, page: this.page_current };
        getRepairList.call(this, contentClone, this.repairLoadPromiseId);
      }, 330);
    }

    changeMastersActiveClass(id, active) {
      if (active) {
        this.masters.forEach((master, i) => this.masters[i].activeClass = false);
        this.button_no_engineer = false;
      } else {
        this.masters.forEach((master, i) => this.masters[i].activeClass = master.id === id);
        this.button_no_engineer = id === 0;
      }
    }

    changeMaster(id: number, active: boolean) {
      this.filter_query.master = id;

      if (active) {
        delete this.filter_query.master;
        this.content.master_id = '';
      } else {
        this.content.master_id = id;
      }

      const location = { query: { ...this.$route.query, ...this.filter_query } };
      this.$router.push(location as RawLocation);
      this.changeMastersActiveClass(id, active);
      this.search();
    }

    changeActiveButton(status) {
      this.status_buttons.forEach(
        (button, i) => this.status_buttons[i].active = button.status === status,
      );
    }

    changeStatusNumber(status: number) {
      this.changeStatus(String(status));
    }

    changeStatus(status: string) {
      let statusCopy = status;
      if (status === null) statusCopy = '1,2,3,4,6';
      this.filter_query.status = statusCopy;
      this.content.status_ids = statusCopy;
    }

    activateStatus(status: string) {
      const query: string[] = (String(status)).split(',');
      query.forEach((element) => {
        const index = this.status_buttons.findIndex((button) => button.status === Number(element));
        if (index > -1) {
          this.status_buttons[`${index}`].active = true;
        }
      });
      return query;
    }

    editStatus(status: number) {
      let selectedStatuses = (this.content.status_ids as string).split(',') as Array<string>;
      if (this.content.status_ids === '') {
        selectedStatuses = [];
      }
      if (selectedStatuses.indexOf(String(status)) !== -1) {
        this.filter_query.status = selectedStatuses.filter((x) => x !== String(status)).join(',');
        this.content.status_ids = selectedStatuses.filter((x) => x !== String(status)).join(',');
      } else {
        selectedStatuses.push(String(status));
        this.filter_query.status = selectedStatuses.sort((a, b) => (Number(a) - Number(b))).join(',');
        this.content.status_ids = selectedStatuses.sort((a, b) => (Number(a) - Number(b))).join(',');
      }
    }

    usingShortcut(shortcut: number) {
      this.clearForm();
      switch (shortcut) {
        case 7: {
          this.resetFilters();
          this.changeStatus('1,2,3,4,5,6');
          break;
        }
        case 1: {
          this.resetFilters();
          const end: Date = new Date();
          const start: Date = new Date();
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          (this.createdAtDateTimeForm[1] as Date) = end;
          (this.createdAtDateTimeForm[0] as Date) = start;
          this.content.created_at_end = Math.round(end.getTime() / 1000);
          this.content.created_at_start = Math.round(start.getTime() / 1000);
          this.changeStatus('1,2,3,4,5,6');
          break;
        }
        case 2: {
          this.resetFilters();
          const end = new Date();
          const start = new Date();
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          this.ejectedAtDateTimeForm[1] = end;
          this.ejectedAtDateTimeForm[0] = start;
          this.content.ejected_at_end = Math.round(end.getTime() / 1000);
          this.content.ejected_at_start = Math.round(start.getTime() / 1000);
          this.changeStatus('5');
          break;
        }
        case 3: {
          this.resetFilters();
          const end: Date = new Date();
          const start: Date = new Date();
          start.setHours(0, 0, 0, 0);
          end.setHours(23, 59, 59, 999);
          (this.plannedOutDateTimeForm[1] as Date) = end;
          (this.plannedOutDateTimeForm[0] as Date) = start;
          this.content.planned_out_date_end = Math.round(end.getTime() / 1000);
          this.content.planned_out_date_start = Math.round(start.getTime() / 1000);
          this.changeStatus('1,2,3,4,6');
          break;
        }
        case 4: {
          this.resetFilters();
          const end: Date = new Date();
          const start: Date = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 3);
          end.setHours(0, 0, 0, 0);
          start.setHours(0, 0, 0, 0);
          (this.plannedOutDateTimeForm[1] as Date) = end;
          (this.plannedOutDateTimeForm[0] as Date) = start;
          this.content.planned_out_date_end = Math.round(end.getTime() / 1000);
          this.content.planned_out_date_start = Math.round(start.getTime() / 1000);
          this.changeStatus('1,2,3,4,6');
          break;
        }
        case 5: {
          this.resetFilters();
          const end: Date = new Date();
          const start: Date = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
          end.setHours(0, 0, 0, 0);
          start.setHours(0, 0, 0, 0);
          (this.plannedOutDateTimeForm[1] as Date) = end;
          (this.plannedOutDateTimeForm[0] as Date) = start;
          this.content.planned_out_date_end = Math.round(end.getTime() / 1000);
          this.content.planned_out_date_start = Math.round(start.getTime() / 1000);
          this.changeStatus('1,2,3,4,6');
          break;
        }
        case 6: {
          this.resetFilters();
          const end: Date = new Date();
          const start: Date = new Date(0);
          end.setTime(end.getTime() - 3600 * 1000 * 24 * 2);
          end.setHours(0, 0, 0, 0);
          this.doneAtDateTimeForm[1] = end;
          this.doneAtDateTimeForm[0] = start;
          this.content.done_at_start = Math.round(start.getTime() / 1000);
          this.content.done_at_end = Math.round(end.getTime() / 1000);
          this.changeStatus('4');
          break;
        }
        default: break;
      }
    }

    selectedStatusOnRow(status_name, repair) {
      const { id } = repair;

      if (status_name === 'На согласовании') {
        sendRepairToAgreement.call(this, id);
      } else if (status_name === 'Ожидает') {
        sendRepairDelay.call(this, id);
      } else if (status_name === 'Готово') {
        sendRepairDone.call(this, id);
      } else if (status_name === 'В работе') {
        if (repair.master_id === null) {
          const master = { repair_id: id, master_id: Number(this.user.id) };

          RepairProvider.sendRepairAssignmaster(master)
            .then(() => sendRepairStart.call(this, id))
            .catch((err) => {
              console.error(err);
              this.$store.commit('PUSH_CALL_ERROR', { title: 'Мастер не установлен !', item: '' });
            });
        } else {
          sendRepairStart.call(this, id);
        }
      } else if (status_name === 'Согласовано') {
        sendRepairApproveAgreement.call(this, id);
      } else if (status_name === 'Отказ от ремонта') {
        sendRepairDeclineAgreement.call(this, id);
      } else if (status_name === 'В работе' && repair.status_id === 3) {
        sendRepairContinue.call(this, id);
      } else if (status_name === 'В работе' && repair.status_id === 4) {
        sendRepairRevision.call(this, id);
      } else if (status_name === 'Принято' && repair.status_id === 5) {
        sendRepairRestart.call(this, id);
      } else if (status_name === 'Выдано') {
        sendRepairEject.call(this, id);
      }
    }

    handlerSelectedStatus(repair, status) {
      const { id } = repair;

      if (status.custom_status_id === null) {
        sendRepairCustomStatus.call(this, { repair_id: id, custom_status_id: null });
      } else {
        this.selectedStatusOnRow(status.name, repair);
      }
    }

    handleSelectedCustomStatus(repair, status) {
      const { id } = repair;

      sendRepairCustomStatus.call(this, { repair_id: id, custom_status_id: status.id });
    }

    onViewPart(val) {
      if ((window.getSelection() as Selection).toString().trim().length === 0) {
        this.$router.push(`/repair/view/${val.id}`);
      }
    }

    /**
     * Возвращает флаг, действует сортировка по колонке или нет
     * @param column колонка
     */
    isColumnSortable = (column) => (column.key === 15 // дата приема
            || column.key === 14 // дата готовности
            || column.key === 12 // ожидаемая дата выдачи
            || column.key === 8 // дата выдачи
            || column.key === 2 // Клиент

    )

    getSortClassForColumn(column) {
      let sortStatus: boolean|null = null;
      switch (column.key) {
        case 15: {
          if (this.content.sort === REPAIR_TABLE_SORT.INCOME_ASK) {
            sortStatus = true;
          } else if ((this.content.sort === REPAIR_TABLE_SORT.INCOME_DESC)) {
            sortStatus = false;
          } else {
            sortStatus = null;
          }
          break;
        }
        case 14: {
          if (this.content.sort === REPAIR_TABLE_SORT.DONE_ASK) {
            sortStatus = true;
          } else if (this.content.sort === REPAIR_TABLE_SORT.DONE_DESC) {
            sortStatus = false;
          } else {
            sortStatus = null;
          }
          break;
        }
        case 12: {
          if (this.content.sort === REPAIR_TABLE_SORT.PLANNED_OUT_ASK) {
            sortStatus = true;
          } else if (this.content.sort === REPAIR_TABLE_SORT.PLANNED_OUT_DESC) {
            sortStatus = false;
          } else {
            sortStatus = null;
          }
          break;
        }
        case 8: {
          if (this.content.sort === REPAIR_TABLE_SORT.OUT_ASK) {
            sortStatus = true;
          } else if (this.content.sort === REPAIR_TABLE_SORT.OUT_DESC) {
            sortStatus = false;
          } else {
            sortStatus = null;
          }
          break;
        }
        case 2: {
          if (this.content.sort === REPAIR_TABLE_SORT.CLIENT_NAME_UP) {
            sortStatus = true;
          } else if (this.content.sort === REPAIR_TABLE_SORT.CLIENT_NAME_DOWN) {
            sortStatus = false;
          } else {
            sortStatus = null;
          }
          break;
        }
        default: break;
      }
      if (sortStatus) {
        return 'fa fa-sort-up';
      }
      if (sortStatus === false) {
        return 'fa fa-sort-down';
      }
      return 'fa fa-sort';
    }

    changeSort(column) {
      switch (column.key) {
        case 15:
          this.content.sort = this.content.sort === REPAIR_TABLE_SORT.INCOME_DESC
            ? REPAIR_TABLE_SORT.INCOME_ASK
            : REPAIR_TABLE_SORT.INCOME_DESC;
          break;
        case 14:
          this.content.sort = this.content.sort === REPAIR_TABLE_SORT.DONE_DESC
            ? REPAIR_TABLE_SORT.DONE_ASK
            : REPAIR_TABLE_SORT.DONE_DESC;
          break;
        case 12:
          this.content.sort = this.content.sort === REPAIR_TABLE_SORT.PLANNED_OUT_DESC
            ? REPAIR_TABLE_SORT.PLANNED_OUT_ASK
            : REPAIR_TABLE_SORT.PLANNED_OUT_DESC;
          break;
        case 8:
          this.content.sort = this.content.sort === REPAIR_TABLE_SORT.OUT_DESC
            ? REPAIR_TABLE_SORT.OUT_ASK
            : REPAIR_TABLE_SORT.OUT_DESC;
          break;
        case 2:
          this.content.sort = this.content.sort === REPAIR_TABLE_SORT.CLIENT_NAME_DOWN
            ? REPAIR_TABLE_SORT.CLIENT_NAME_UP
            : REPAIR_TABLE_SORT.CLIENT_NAME_DOWN;
          break;
        default: break;
      }
    }

    @Watch('stuff')
    dataMasters(masters) {
      const { query } = this.$route;
      this.masters = [];
      this.acceptor = [];

      masters.forEach((master) => {
        const masterCopy = { ...master };
        masterCopy.activeClass = false;
        if (masterCopy.rights.can_work_on_repair) this.masters.push(masterCopy);
        if (masterCopy.rights.can_create_update_repair) this.acceptor.push(masterCopy);
      });

      this.masters = this.masters.sort((a, b) => {
        const nameA = (this.formattingStuff(a) as string).toLowerCase();
        const nameB = (this.formattingStuff(b) as string).toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      if ('master' in query) {
        const master_id = Number(query.master);
        this.changeMaster(master_id, false);
      }

      getRepairStat.call(this);
    }

    @Watch('repairActiveColumn')
    repairTableLayout() {
      if (this.active_column.length === 0) {
        this.active_column = this.repairActiveColumn;
        this.noactive_column = this.repairNoActiveColumn;
      }
    }

    @Watch('newRepairLayout')
    newLayout() {
      this.open_dialog_settings = false;
      this.draw_repair_table = true;
    }

    @Watch('content', { deep: true })
    watchContentObject() {
      const location = { query: { ...this.$route.query, ...this.filter_query } };
      this.$router.push(location as RawLocation);
      this.search();
    }

    @Watch('createdAtDateTimeForm')
    watchCreatedAtPickerChanged() {
      if (this.createdAtDateTimeForm && this.createdAtDateTimeForm.length === 2) {
        this.content.created_at_start = Math.round(this.createdAtDateTimeForm[0].getTime() / 1000);
        this.content.created_at_end = Math.round(this.createdAtDateTimeForm[1].getTime() / 1000);
      } else {
        this.content.created_at_start = '';
        this.content.created_at_end = '';
      }
      this.search();
    }

    @Watch('plannedOutDateTimeForm')
    watchPlannedOutPickerChanged() {
      if (this.plannedOutDateTimeForm && this.plannedOutDateTimeForm.length === 2) {
        this.content.planned_out_date_start = Math.round(
          this.plannedOutDateTimeForm[0].getTime() / 1000,
        );
        this.content.planned_out_date_end = Math.round(
          this.plannedOutDateTimeForm[1].getTime() / 1000,
        );
      } else {
        this.content.planned_out_date_start = '';
        this.content.planned_out_date_end = '';
      }
      this.search();
    }

    @Watch('doneAtDateTimeForm')
    watchDoneAtPickerChanged() {
      if (this.doneAtDateTimeForm && this.doneAtDateTimeForm.length === 2) {
        this.content.done_at_start = Math.round(this.doneAtDateTimeForm[0].getTime() / 1000);
        this.content.done_at_end = Math.round(this.doneAtDateTimeForm[1].getTime() / 1000);
      } else {
        this.content.done_at_start = '';
        this.content.done_at_end = '';
      }
      this.search();
    }

    @Watch('ejectedAtDateTimeForm')
    watchEjectedAtPickerChanged() {
      if (this.ejectedAtDateTimeForm && this.ejectedAtDateTimeForm.length === 2) {
        this.content.ejected_at_start = Math.round(this.ejectedAtDateTimeForm[0].getTime() / 1000);
        this.content.ejected_at_end = Math.round(this.ejectedAtDateTimeForm[1].getTime() / 1000);
      } else {
        this.content.ejected_at_start = '';
        this.content.ejected_at_end = '';
      }
      this.search();
    }
}
