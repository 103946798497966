import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Cashbox } from '@/classes';
import { Breadcrumb, ActionBtn } from '@/types';
import CashProvider from '@/api/providers/cash';

import StuffProvider from '../../api/providers/stuff';
import CashListItem from '../../components/cash-list-item';
import CashboxForm from '../../components/cashbox-form/cashbox-form';

@Component({
  template: require('./index.html'),
  components: {
    'cash-item': CashListItem,
    'cashbox-form': CashboxForm,
  },
})
export default class CashListPage extends Vue {
  @Action addBreadcrumb;

  @Action addActionBtn;

  @Action getPointList;

  @Action getAllCashTypeList;

  @Getter point;

  @Getter user;

  get pointHasAccess() {
    return this.point.filter((p) => p.has_access);
  }

  cashList: Cashbox[] = [];

  fullCashList: Cashbox[] = [];

  cashListPageCount = 1;

  breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Финансы', link: null }, { id: 2, section: 'Кассы', link: null }];

  actionBtn: ActionBtn[] = [{
    id: 1, type: 'add', title: 'Создать кассу', link: 'onDialog', icon: 'fa-plus',
  }];

  loading = true;

  view_modal = false;

  form: Cashbox = new Cashbox();

  type_form = 'add';

  stuff_list: object[] = [];

  page_stuff = 1;

  page_cash = 1;

  getStuffList() {
    this.stuff_list = [];
    StuffProvider.getFullStuffList()
      .then((res) => {
        this.stuff_list = res;
      }).catch((err) => console.error(err));
  }

  getCashList(page) {
    CashProvider.getCashList(page, { ignore_visibility_filter: true })
      .then((resp) => {
        this.cashList = (resp.data as unknown as Cashbox[]);
        this.cashListPageCount = resp.pageCount;
      });
  }

  getFullCashList() {
    CashProvider.getAllCashes(false)
      .then((resp) => {
        this.fullCashList = (resp as unknown as Cashbox[]);
      });
  }

  loadData() {
    this.getCashList(this.page_cash);
    this.getFullCashList();
    this.getPointList({ page: 1 });
    this.getStuffList();
    this.getAllCashTypeList();
  }

  mounted() {
    this.loadData();
    this.addBreadcrumb(this.breadcrumb);
    if (this.user.rights) {
      if (this.user.rights.can_manage_cashboxes) {
        this.addActionBtn(this.actionBtn);
      }
    }
    document.title = 'Кассы';

    this.$root.$on('onDialog', () => {
      this.type_form = 'add';
      this.view_modal = true;
    });
  }

  get convertedCashboxes() {
    const arr: Cashbox[] = [];
    this.cashList.forEach((e) => {
      const item = Cashbox.buildFromApiResponse(e);
      arr.push(item);
    });
    return arr;
  }

  editCash(item: object) {
    this.type_form = 'edit';
    this.view_modal = true;
    Object.keys(this.form).forEach((key) => {
      if (key) {
        this.form[key] = item[key];
      }
    });
  }

  clear() { this.form.resetFields(); }

  closeDialog() {
    this.loadData();
    this.form.users = [];
    this.view_modal = false;
    this.clear();
  }

  destroyed() { this.addActionBtn([]); }

  loadPage(val) {
    this.page_cash = val;
    this.getCashList(this.page_cash);
  }

  @Watch('cashList')
  onLoad() {
    this.clear();
    this.view_modal = false;
    this.$nextTick(() => this.loading = false);
  }
}
