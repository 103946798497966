import Vue from 'vue';
import axios from 'axios';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Document } from '@/types';
import ErrorAccessRights from '../../components/error-access-rights';

@Component({
  template: require('./index.html'),
  components: {
    'error-access-rights': ErrorAccessRights,
  },
})
export default class PapersPage extends Vue {
    @Action addBreadcrumb;

    @Action getDocumentList;

    @Action getStickerSettings;

    @Action newStickerSettings;

    @Action sendUploadlogo;

    @Action getCompanyInfo;

    @Getter documentList;

    @Getter pageDocumentList;

    @Getter btnloader;

    @Getter user;

    @Getter settingsSticker;

    @Getter company;

    activeTabs = '';

    settingsTemplateForm: object = {
      width: '',
      height: '',
    };

    imageUrl = '';

  documnets: object = { 'per-page': 20, showDrafts: true, page: 1 };

    documentsRepair: object[] = [];

    documentsCartrdiges: object[] = [];

    documentsSales: object[] = [];

    documentsSoldGoods: object[] = [];

    documentsSoldWorks: object[] = [];

    documentsTagsAndStickers: object[] = [];

    page_document_list = 2;

    loader = true;

    get rightsUserCanManagePapers() {
      return this.user.rights ? this.user.rights.can_manage_papers : false;
    }

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: '/settings' },
        { id: 2, section: 'Шаблоны документов', link: null },
      ]);
      document.title = 'Шаблоны документов';
      this.getDocumentList(this.documnets);
      this.getStickerSettings();
      this.getCompanyInfo();
    }

    cardStyle = (isDraft: boolean) => {
      if (isDraft) {
        return {
          padding: '0px',
          filter: 'grayscale(1)',
        };
      }
      return {
        padding: '0px',
      };
    }

    created() {
      this.activeTabs = this.$route.name === 'paper-settings' ? '1' : '0';
    }

    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    }

    editDocuments(id) {
      this.$router.push({ name: 'edit-paper', params: { id } });
    }

    editTagsAndStickers(type) {
      switch (type) {
        case 6: this.$router.push({ name: 'edit-paper-pricesticker', path: '/service/pricesticker' }); break;
        case 7: this.$router.push({ name: 'edit-paper-repairsticker', path: '/service/repairsticker' }); break;
        case 8: this.$router.push({ name: 'edit-paper-partsticker', path: '/service/partsticker' }); break;
        case 9: this.$router.push({ name: 'edit-paper-worksticker', path: '/service/worksticker' }); break;
        default: break;
      }
    }

    addDocuments(type) {
      this.$router.push({ name: 'add-paper', params: { type } });
    }

    methodDisabledButtons() {
      this.$notify.error({
        title: 'Вам не разрешено управлять разделом «Шаблоны документов»',
        message: '',
        position: 'bottom-right',
      });
    }

    handlerAddLogo(files) {
      const form = new FormData();
      const access = localStorage.getItem('access');

      form.append('LogoUploadForm[file]', files.file);
      const domain = process.env.NODE_ENV === 'production' ? 'api.servix.io' : 'api-dev.servix.io';
      axios.post(`https://${domain}/v1/service/uploadlogo`, form, {
        headers: {
          'x-access-token': access,
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        this.imageUrl = res.data.logo_url;
      }).catch((err) => {
        console.error(err);
      });
    }

    @Watch('documentList')
    documents(documents: Document[]) {
      this.loader = false;

      documents.forEach((document) => {
        if (document.type === 1) this.documentsRepair.push(document);
      });
      documents.forEach((document) => {
        if (document.type === 2) this.documentsCartrdiges.push(document);
      });
      documents.forEach((document) => {
        if (document.type === 3) this.documentsSales.push(document);
      });
      documents.forEach((document) => {
        if (document.type === 4) this.documentsSoldGoods.push(document);
      });
      documents.forEach((document) => {
        if (document.type === 5) this.documentsSoldWorks.push(document);
      });
      documents.forEach((document) => {
        if (document.type as number > 5
          && document.type as number < 10) this.documentsTagsAndStickers.push(document);
      });

      if (this.page_document_list <= this.pageDocumentList) {
        this.getDocumentList({ page: this.page_document_list, showDrafts: true, 'per-page': 20 });
      }
      this.page_document_list += 1;
    }

    @Watch('settingsSticker')
    sizeStickers(size) {
      this.settingsTemplateForm = size;
    }

    @Watch('company')
    getLogoCompany(company) {
      this.imageUrl = company.logo_url;
    }
}
