import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PartProvider from '../../api/providers/part';
import Numbers from '../forms/numbers';
import ModalAddProduct from '../modal-add-product';
import Category from '../__category';

interface Content {
  page: number;
  name: string;
  in_storage: number;
  category_id: number;
  'per-page': number;
}

interface SearchParts {
  'per-page': number;
  page: number;
  query: string;
  category: number;
}
@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    'category-tree': Category,
    'modal-add-product': ModalAddProduct,
  },
})

export default class ModalCatalog extends Vue {
    @Action getPartCategory;

    @Getter categoryList;

    @Prop() storage!: number;

    @Prop() formPart!: object[];

    @Prop({ default: () => false }) viewDialog!: boolean;

    @Prop({ default: () => true }) viewDialogNewPart!: boolean;

    dialog = false;

    loading_table = true;

    dialog_part_add = false;

    query = '';

    loading_categ = true;

    page_count = 1;

    content: Content = {
      page: 1,
      name: '',
      in_storage: 1,
      category_id: 1,
      'per-page': 20,
    };

    flag_active_collapse = false;

    search_parts: SearchParts = {
      'per-page': 20,
      page: 1,
      query: '',
      category: 1,
    };

    // api
    getPartList(item) {
      PartProvider.getPartList(item.page, item, item['per-page'])
        .then((res) => {
          this.$emit('partsList', res.data);
          this.loading_table = false;
          this.$emit('loadingTable', this.loading_table);
          this.page_count = Number(res.pageCount);
        }).catch((err) => {
          this.loading_table = false;
          this.$emit('loadingTable', this.loading_table);
          console.error(err);
        });
    }

    getPartUniversalSearch(item) {
      const { query } = item;
      this.$emit('loadingTable', true);
      PartProvider.getPartUniversalSearch(item.page, item, item['per-page'])
        .then((res) => {
          if (query === this.content.name) {
            this.$emit('partsList', res.data);
            this.$emit('loadingTable', false);
            this.page_count = Number(res.pageCount);
          }
        }).catch((err) => {
          this.$emit('loadingTable', false);
          console.error(err);
        });
    }

    get storage_id() {
      if (this.storage) return this.storage;
      return null;
    }

    get dataPartsForm() {
      if (this.formPart) return this.formPart;
      return [];
    }

    mounted() {
      this.changeCategory();
      this.getPartCategory('part');
      this.getPartList(this.content);
    }

    typePerPage(count) {
      return count === this.content['per-page'] ? 'primary' : '';
    }

    changePage(val) {
      this.content.page = val;
      this.search_parts.page = val;
      this.search();
    }

    dialogFlags(view: boolean) {
      this.dialog_part_add = view;
      this.dialog = view;
    }

    openCategories(value) {
      this.flag_active_collapse = value.length !== 0;
    }

    changeCategory() {
      this.$root.$on('categoryItemActive', (i) => {
        const val = this.content.category_id === i ? 1 : i;
        this.content.category_id = val;
        this.search();
      });
    }

    search() {
      this.loading_table = true;
      this.$emit('loadingTable', this.loading_table);
      this.getPartList(this.content);
    }

    changePerPage(val) {
      this.content['per-page'] = val;
      this.search_parts['per-page'] = val;
      this.search();
    }

    @Watch('query')
    onChangeSearch(val: string) {
      this.search_parts.query = val;
      this.search_parts.category = this.content.category_id;
      this.content.name = val;

      this.getPartUniversalSearch(this.search_parts);
    }

    @Watch('categoryList')
    onLoadCat() {
      this.$nextTick(() => {
        this.loading_categ = false;
      });
    }

    @Watch('viewDialog', { immediate: true })
    openDialog(view: boolean) {
      this.dialog = view;
    }
}
