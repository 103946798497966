import Vue from 'vue';
import { facade } from 'vue-input-facade';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ClientsProvider from '../../api/providers/clients';
import ClientDuplicate from '../client-duplicate';

interface Item {
  address;
  inn;
  kpp;
  manager_name;
  manager_post;
  name;
  ogrn;
  okpo;
  note;
}

@Component({
  template: require('./index.html'),
  components: {
    'client-duplicate': ClientDuplicate,
  },
  directives: {
    facade,
  },
})
export default class ClientCompanyForm extends Vue {
    @Prop({ default: () => true }) redirectOnMainClients!: boolean;

    @Prop() clientSource!: object[];

    @Prop() client!: object;

    @Prop() duplicateClients!: object[];

    @Getter settings;

    @Prop() item!: Item;

    client_inn: object[] = [];

    // api

    getInnClient(inn) {
      ClientsProvider.getInnClient(inn)
        .then((res) => {
          this.client_inn = res.data;
        })
        .catch((err) => console.error(err));
    }

    get isMaskEnabled(): boolean {
      return typeof this.settings.phone_mask === 'string' && this.settings.phone_mask.length > 0;
    }

    get maskPlaceholder(): string {
      if (this.isMaskEnabled) {
        return this.settings.phone_mask;
      }
      return '';
    }

    get clientUserCompany() {
      return this.$refs.formClientCompany;
    }

    select(item) {
      this.item.address = item.address;
      this.item.inn = item.inn;
      this.item.kpp = item.kpp;
      this.item.manager_name = item.manager_name;
      this.item.manager_post = item.manager_post;
      this.item.name = item.name;
      this.item.ogrn = item.ogrn;
      this.item.okpo = item.okpo;
    }

    inn(queryString, cb) {
      const result: object[] = this.client_inn;
      cb(result);
    }

    validate = (obj: object, key: string) => {
      if (Object.keys(obj).some((v) => v === key)) {
        return obj[key][0];
      }
      return null;
    }

    @Watch('item.inn')
    onChange(val: number) {
      this.getInnClient(Number(val));
    }

    @Watch('client')
    clients() {
      if (this.redirectOnMainClients) { this.$router.push({ name: 'clients' }); }
    }
}
