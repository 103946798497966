import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import TaskFormComponent from '../../components/task-form';
import { Task, TaskForm } from '../../store/task/types';

@Component({
  template: require('./index.html'),
  components: {
    'task-form': TaskFormComponent,
  },
})
export default class TaskAddPage extends Vue {
  @Action addBreadcrumb;

  @Action addActionBtn;

  @Action clearLastSavedTaskId;

  @Action addTask;

  @Getter btnloader;

  @Getter lastSavedTaskId;

  loading = true;

  task: Task = new Task();

  mounted() {
    this.addActionBtn([]);
    this.addBreadcrumb([
      { id: 1, section: 'Задачи', link: '/task' },
      { id: 2, section: 'Список задач', link: '/task' },
      { id: 3, section: 'Новая задача', link: null },
    ]);
    document.title = 'Новая задача';
  }

  submitTask() {
    ((this.$refs.form as TaskFormComponent).elementTaskForm as Form).validate((valid) => {
      if (valid) {
        const taskFormModel = new TaskForm();
        taskFormModel.title = this.task.title;
        taskFormModel.description = this.task.description;
        taskFormModel.responsible_account_id = this.task.responsible_account_id;
        taskFormModel.priority = this.task.priority;
        taskFormModel.status_id = this.task.status_id;
        if (this.task.deadline) {
          taskFormModel.deadline = Math.round(this.task.deadline / 1000);
        }
        this.addTask(taskFormModel);
        return true;
      }
      return false;
    });
  }

  @Watch('lastSavedTaskId')
  redirect() {
    if (this.lastSavedTaskId !== null) {
      this.$router.replace({ name: 'task-view', params: { id: this.lastSavedTaskId } });
      this.clearLastSavedTaskId();
    }
  }
}
