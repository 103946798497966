const state = {
  point: [],
  changePoint: {},
  newPoint: {},
  typePoint: [
    { id: 1, name: 'Сервисный центр' },
    { id: 2, name: 'Пункт приема/выдачи' },
    { id: 3, name: 'Магазин' },
    { id: 4, name: 'Склад' },
  ],
  pointPageCount: null,
  pointPageCurrent: null,
  viewPoint: {},
  validPoint: {},
};

export default state;
