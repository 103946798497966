export default function modelSelection(this) {
  this.$root.$on('modelSelection', ({ item, type }) => {
    if (item === null) {
      this.data_repair.objects[0].device_id = null;
    } else {
      this.data_repair.objects[0].device_id = item.id;

      if (type === null) {
        this.data_repair.objects[0].model = item.name.trim();
      }
    }
  });
}
