import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import PartProvider from '@/api/providers/part';
import InfoAboutPartOrWork from '@/components/info-about-part-or-work';
import TransferProvider from '../../api/providers/transfer';

@Component({
  template: require('./index.html'),
  components: {
    InfoAboutPartOrWork,
  },
})
export default class TransferView extends Vue {
    loading = true;

    @Action getVatList;

    @Action getRewardTypeList;

    @Action getWarrantTypeList;

    @Action getPartCategory;

    @Prop() id!: number;

    transfer: object = {};

    showModalViewAboutWorkOrPart = false;

    btnloader = false;

    dataForModalView = {};

    titleInfoAboutPartOrWork = '';

    // api

    getViewTransfer(id) {
      TransferProvider.getViewTransfer(id)
        .then((res) => {
          this.transfer = res.data;
          this.loading = false;
        }).catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }

    viewingPart(row) {
      try {
        this.btnloader = true;
        PartProvider.getViewPart(row.part_id)
          .then((res) => {
            this.titleInfoAboutPartOrWork = 'Информация о товаре';
            this.dataForModalView = res.data;
            this.btnloader = false;
            this.getVatList();
            this.getRewardTypeList();
            this.getWarrantTypeList();
            this.getPartCategory('part');

            this.showModalViewAboutWorkOrPart = true;
          })
          .catch((error) => {
            this.btnloader = false;
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о товаре', item: '' });
            throw new Error(error);
          });
      } catch (err) {
        this.btnloader = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        throw new Error(err);
      }
    }

    closeInfoAboutPartOrWork() {
      this.showModalViewAboutWorkOrPart = false;
    }

    mounted() {
      this.getViewTransfer(this.id);
      this.getVatList();
      this.getRewardTypeList();
      this.getWarrantTypeList();
      this.getPartCategory('part');
    }

    date = (value) => {
      const options: object = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
      };
      const date: string = new Date(value * 1000).toLocaleString('ru-RU', options).replace(',', '');
      return date;
    }

    get view() {
      return new Array(this.transfer);
    }

    currentUser = (user) => {
      if (user) {
        const lastName: string = user.last_name ? user.last_name : '';
        const firstName: string = user.first_name ? user.first_name : '';
        return `${lastName} ${firstName}`;
      }
      return '-';
    }

    currentName = (val) => ((val && val.name) ? val.name : '')
}
