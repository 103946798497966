import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import NumberPhone from '../../forms/number-phone';

@Component({
  template: require('./summary.html'),
  components: {
    phone: NumberPhone,
  },
})
export class RepairViewSummary extends Vue {
    @Prop({ default: '' }) repairTitle!: string;

    @Prop() clientId!: number;

    @Prop({ default: '' }) clientName!: string;

    @Prop({ default: '' }) clientPhone!: string;

    @Prop({ default: '' }) unrestrictedClientPhone!: string;

    @Prop({ default: 1 }) clientStatus!: number;

    @Prop({ default: false }) clientSmsAgreement!: boolean;

    @Prop({ default: false }) isClientEnabled!: boolean;

    @Prop({ default: () => [] }) badClientReasons!: string[];

    @Prop() onClientChangeStatusClick!: Function;

    @Prop({ default: () => [] }) devices!: RepairDeviceDescription[];

    @Prop({ default: '' }) deviceImei!: string;

    @Prop({ default: '' }) deviceSerial!: string;

    @Prop({ default: '' }) bug!: string;

    @Prop({ default: '' }) state!: string;

    @Prop({ default: '' }) equipment!: string;

    @Prop({ default: '' }) acceptorNotes!: string;

    @Prop({ default: '' }) masterNotes!: string;

    @Prop() onRepairChangeTitleClick!: Function;

    @Prop() onCreateNewClinentRepairClick!: Function;

    @Prop() onSendSmsClick!: Function;

    @Getter user;

    get rightsUserCanSeeClients() {
      return this.user.rights ? this.user.rights.can_see_clients : false;
    }

    get statusContent(): string {
      switch (this.clientStatus) {
        case 1:
          return 'Обычный';
        case 2:
          return 'Любимый';
        case 3:
          return 'В черном списке';
        default:
          return '-';
      }
    }

    changeRepairTitle() {
      this.onRepairChangeTitleClick();
    }

    setClientVip() {
      this.onClientChangeStatusClick(2);
    }

    setClientRegular() {
      this.onClientChangeStatusClick(1);
    }

    setClientBlacklist() {
      this.onClientChangeStatusClick(3);
    }

    createRepairWithSameClient() {
      this.onCreateNewClinentRepairClick();
    }

    sendSms() {
      this.onSendSmsClick();
    }
}

export interface RepairDeviceDescription {
    type: string;
    vendor: string;
    model: string;
    amount: number;
}
