import BaseProvider from '../base-provider';

class SalaryOperation extends BaseProvider {
  constructor() {
    super();
    this.resource = '/salaryoperation';
  }

  async salaryOperationIndex(request) {
    const req = {
      method: 'GET',
      nestedResources: '/index',
      formName: 'SalaryOperationSearchForm',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      total: res.headers['x-pagination-total-count'],
      currentPage: res.headers['x-pagination-current-page'],
      links: res.headers.link,
    };
  }

  async salaryOperationView(request) {
    const req = {
      method: 'GET',
      nestedResources: '/view',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
    };
  }

  async salaryOperationBonus(request) {
    const req = {
      method: 'POST',
      nestedResources: '/bonus',
      formName: 'SalaryBonusForm',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
    };
  }

  async salaryOperationPenalty(request) {
    const req = {
      method: 'POST',
      nestedResources: '/penalty',
      formName: 'SalaryPenaltyForm',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
    };
  }

  async salaryOperationUpdate(request) {
    const req = {
      method: 'POST',
      nestedResources: '/update',
      formName: 'SalaryOperation',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
    };
  }

  async salaryOperationDelete(request) {
    const req = {
      method: 'POST',
      nestedResources: '/delete',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
    };
  }
}

export default new SalaryOperation();
