import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  template: require('./discount.html'),
})

export default class FormDiscount extends Vue {
  @Prop({ default: () => 'success' }) typeBtn!: string;

  @Prop({ default: () => 'Сохранить' }) actionsBtn!: string;

  @Prop() data!: object;

  @Prop() saveDiscount!: Function;

  @Getter discount;

  @Getter btnloader;

  validateSum = (rule, value, callback) => {
    if (value === null || value === '') {
      callback(new Error('Заполните поле «Скидка»'));
    } else if (value < 0) {
      callback(new Error('Значение «Скидка» должно быть не меньше 0.'));
    } else {
      callback();
    }
  }

  validateTypeDiscount = (rule, value, callback) => {
    if (value === null || value === '') {
      callback(new Error('Заполните поле «Тип скидки»'));
    } else {
      callback();
    }
  }

  rules: object = {
    amount: [
      {
        required: true, validator: this.validateSum, trigger: 'change', type: 'number',
      },
    ],
    type_id: [
      { required: true, validator: this.validateTypeDiscount },
    ],
  };

  discountType: object[] = [
    { value: '% (от полной стоимости заказа)', id: 1 },
    { value: 'сумма', id: 2 },
    { value: '% (от суммы услуг)', id: 3 },
  ];

  clearFormValidation() {
    (this.$refs.formDiscounts as Form).clearValidate();
  }

  resetForm() {
    (this.$refs.formDiscounts as Form).resetFields();
  }

  discountAdd(formName) {
    const refForm = (this.$refs[formName] as Form);
    refForm.validate((valid) => (valid ? this.saveDiscount(this.data) : false));
  }

  @Watch('discount')
  resultFormDiscount() { this.resetForm(); }
}
