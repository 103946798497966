import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import SalaryTable from '../../components/salary-table';

@Component({
  template: require('./index.html'),
  components: {
    SalaryTable,
  },
})

export default class SalaryPage extends Vue {
    loading = true;

    @Getter stuff;

    @Getter user;

    @Action getSalaryPeriodTypeList;

    @Action getSellPartSalaryTypeList;

    @Action getRepairPartSalaryType;

    @Action addBreadcrumb;

    @Action getStuffList;

    stuff_list: object[] = [];

    async created() {
      this.getStuffList(1);
      this.getSalaryPeriodTypeList();
      this.getSellPartSalaryTypeList();
      this.getRepairPartSalaryType();
      this.addBreadcrumb([{
        id: 1, section: 'Финансы', link: null, textContent: 'Зарплата сотрудников',
      }]);
      document.title = 'Зарплата сотрудников';
    }

    get rightsUserCanManageStuff() {
      return this.user.rights ? this.user.rights.can_manage_stuff : false;
    }

    @Watch('stuff')
    onLoad(list) {
      this.stuff_list = !this.rightsUserCanManageStuff
        ? list.filter((item) => item.id === this.user.id)
        : list;
      this.$nextTick(() => {
        this.loading = false;
      });
    }
}
