/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  template: require('./index.html'),
})

export default class LimitBalanceMessage extends Vue {
  @Getter settings;

  @Getter balance;

  /**
   * больше ли лимит баланса текущего баланса
   * @returns false
   */
  get isLimitReady(): boolean {
    return this?.settings?.balance_notification_limit && this?.balance?.payment_balance
      ? this?.settings?.balance_notification_limit > this?.balance?.payment_balance
      : false;
  }

  /**
   * нужно показать alert?
   * @returns boolean
   */
  get isShowMessage(): boolean {
    return this.isLimitReady && this.isMessageReadyToShow;
  }

  /**
   * геттер пора показывать сообщение о лимите
   */
  get isMessageReadyToShow(): boolean {
    const now = new Date().getTime();
    const nextMessageTime = Number(localStorage.getItem('next-limit-balance-messege'));
    return now > nextMessageTime;
  }

  /**
   * Закрытие alert
   */
  closeMessage(): void {
    const now = new Date().getTime();
    const nextDay = now + 1000 * 60 * 60 * 24;
    localStorage.setItem('next-limit-balance-messege', String(nextDay));
  }

  /**
   * Перенаправление на страницу поплонения баланса
   */
  redirectOnLicensesPage() {
    this.$router.push({ name: 'buy-balance' });
  }
}
