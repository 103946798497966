import { GetterTree } from 'vuex';
import { StateEquipmentOption } from './types';

const getters: GetterTree<StateEquipmentOption, object> = {
  equipmentoptionList: (state) => state.equipmentoptionList,
  addEquipmentOptionList: (state) => state.addEquipmentOptionList,
  updateEquipmentOptionList: (state) => state.updateEquipmentOptionList,
  deleteEquipmentOptionList: (state) => state.deleteEquipmentOptionList,
  sendEquipmentOptionPriority: (state) => state.sendEquipmentOptionPriority,
};

export default getters;
