import { TaskStatus } from '@/store/task-status/types';
import { TaskInterface, TaskPriority } from '@/types';

export default class Task implements TaskInterface {
  get priorityName(): string {
    switch (this.priority) {
      case TaskPriority.NORMAL:
        return 'Обычный';
      case TaskPriority.HIGH:
        return 'Высокий';
      case TaskPriority.LOW:
        return 'Низкий';
      default: break;
    }
    return '???';
  }

  completed_at!: number;

  created_at!: number;

  creator_id!: number;

  deadline!: number;

  description!: string;

  id!: number;

  priority!: TaskPriority;

  responsible_account_id!: number;

  status_id!: number;

  title!: string;

  status!: TaskStatus;
}
