import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Getter } from 'vuex-class/lib/bindings';

@Component({
  template: require('./auth.html'),
})

class Auth extends Vue {
    e1 = 'login';

    userIn = {
      login: '',
      password: '',
      saveUser: false,
    };

    loading = false;

    @Action signIn;

    @Getter auth;

    @Getter authError;

    isAuth(item) {
      this.loading = true;
      this.signIn(item).then((res) => {
        this.loading = false;

        if (res.data.user.rights.can_move_between_points) {
          this.$router.push({ name: 'repair', query: { timestamp: (new Date().getTime() / 1000).toFixed(0) } });
        } else {
          this.$router.push('/repair');
        }
      }, () => {
        this.loading = false;
        console.error('Not authorized');
      });
    }

    openForgot = () => {
      window.location.href = process.env.NODE_ENV === 'production' ? 'https://auth.servix.io/forgot' : 'https://auth-dev.servix.io/forgot';
    }

    validate = (obj, key, text) => {
      if (obj !== undefined && typeof (obj[key]) !== undefined) {
        if (typeof (obj[key]) !== 'boolean' && obj[key] !== undefined) {
          return text ? obj[key][0] : ' ';
        }
      }
      return '';
    }

    isDevelopment = window.location.hostname !== 'app.servix.io';
}

export { Auth as default };
