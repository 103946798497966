import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
  props: ['value'],
})

export default class ViewUserName extends Vue {
  user = (user) => {
    if (user) {
      if (user.name) {
        return user.name;
      }
      const lastName: string = user.last_name !== null ? user.last_name : '';
      const firstName: string = user.first_name !== null ? user.first_name : '';
      const middleName: string = user.middle_name !== null ? user.middle_name : '';
      return `${lastName} ${firstName} ${middleName}`;
    }
    return '';
  }
}
