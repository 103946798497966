// Mutations Type

export const GET_EXPORT = 'GET_EXPORT';
export const EXPORT_VALIDATION = 'EXPORT_VALIDATION';

// Store

export interface StateExport {
  export: object[];
  exportPageCount: number;
  exportValid: object[];
}

// Models
