import { ActionTree } from 'vuex';
import AuthProvider from '../../api/providers/auth';
import { BTN_LOADER } from '../preloader/types';
import {
  AUTH_ERROR, AUTH_IN, AUTH_OUT, StateAuth,
} from './types';

const actions: ActionTree<StateAuth, object> = {

  signIn({ commit, dispatch }, item) {
    return new Promise((resolve, reject) => {
      commit(BTN_LOADER, true);
      AuthProvider.signIn(item)
        .then((res) => {
          if (res.data.user.service_id === null) {
            commit(AUTH_ERROR, { item: { login: ['Вам закрыт доступ к данным компании.'] } });
          } else {
            commit(AUTH_IN, { saveUser: item.saveUser, auth: true, item: res.data });
            commit(AUTH_ERROR, { item: {} });
            dispatch('getUserMe');
          }

          commit(BTN_LOADER, false);
          resolve(res);
        })
        .catch((err) => {
          commit(AUTH_ERROR, { item: err.response.data });
          commit(BTN_LOADER, false);
          reject();
        });
    });
  },

  signOut({ commit, dispatch }, item) {
    commit(AUTH_OUT, item);
    dispatch('clearUser');
  },
};

export default actions;
