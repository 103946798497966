import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import ProviderProvider from '../../api/providers/provider';
import ProviderForm from '../../components/provider-form';

@Component({
  template: require('./index.html'),
  components: {
    'provider-form': ProviderForm,
  },
})
export default class ProviderAddPage extends Vue {
    @Action addBreadcrumb;

    item: object = {};

    loading = false;

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: '/settings' },
        { id: 2, section: 'Справочники', link: null },
        { id: 3, section: 'Поставщики', link: '/reference/provider' },
        { id: 4, section: 'Новый поставщик', link: null },
      ]);
      document.title = 'Новый поставщик';
    }

    sendProvider(item) {
      this.loading = true;
      ProviderProvider.sendProvider(item)
        .then((res) => {
          this.loading = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `${res.data.name} успешно добавлен`, item: '' });

          this.$router.push({ path: '/reference/provider' });
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }
}
