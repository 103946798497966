import { GetterTree } from 'vuex';
import { StateCashTypes } from './types';

const getters: GetterTree<StateCashTypes, object> = {
  cashTypeUser: (state) => state.cashTypeUser,
  cashTypeUserOn: (state) => state.cashTypeUser.filter((i) => i.increase),
  cashTypeUserOff: (state) => state.cashTypeUser.filter((i) => !i.increase),
  cashTypeDefault: (state) => state.cashTypeDefault,
  cashTypePageCount: (state) => state.cashTypePageCount,
};

export default getters;
