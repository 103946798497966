import { ActionTree } from 'vuex';
import BugOptionProvider from '../../api/providers/equipmentoption';
import { StateEquipmentOption } from './types';

const actions: ActionTree<StateEquipmentOption, object> = {

  getEquipmentOptionList({ commit }, page) {
    BugOptionProvider.getEquipmentOptionList(page)
      .then((res) => {
        commit('GET_EQUIPMENT_LIST', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  sendEquipmentOptionList({ commit }, item) {
    BugOptionProvider.sendEquipmentOptionList(item)
      .then((res) => {
        commit('SEND_EQUIPMENTOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendEquipmentOptionSetPrioroty({ commit }, item) {
    BugOptionProvider.sendEquipmentOptionSetPrioroty(item)
      .then((res) => {
        commit('SEND_EQUIPMENTOPTION_LIST_PRIORITY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Раскладка успешно сохранена', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  changeEquipmentOptionList({ commit }, item) {
    BugOptionProvider.changeEquipmentOptionList(item)
      .then((res) => {
        commit('UPDATE_EQUIPMENTOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно обновлен', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  deleteEquipmentOptionList({ commit }, item) {
    BugOptionProvider.deleteEquipmentOptionList(item)
      .then((res) => {
        commit('DELETE_EQUIPMENTOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно удалено', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

};

export default actions;
