import BaseProvider from '../base-provider';

class IntegrationsProvider extends BaseProvider {
  async getListProviders() {
    const res = await this.request('GET', '/smssenderconfig/listproviders');
    return {
      data: res.data,
    };
  }

  async addConfig(config) {
    const res = await this.request('POST', `/smssenderconfig/add?id=${config.id}`, { SmsSenderConfig: config });
    return {
      data: res.data,
    };
  }

  async updateConfig(config) {
    const res = await this.request('POST', `/smssenderconfig/update?id=${config.id}`, { SmsSenderConfig: config });
    return {
      data: res.data,
    };
  }

  async getSmsSenders(page) {
    const res = await this.request('GET', `/smssenderconfig/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async activateSmsSenders(id) {
    const res = await this.request('POST', `/smssenderconfig/activate?id=${id}`);
    return res;
  }

  async deactivateSmsSenders(id) {
    const res = await this.request('POST', `/smssenderconfig/deactivate?id=${id}`);
    return res;
  }
}

export default new IntegrationsProvider();
