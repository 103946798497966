// Mutations Type

import { Work } from '@/types';

export const GET_WORK = 'GET_WORK';
export const GET_VIEW_WORK = 'GET_VIEW_WORK';
export const DELETE_WORK = 'DELETE_WORK';
export const UPDATE_WORK = 'UPDATE_WORK';

// Store

export interface StateWork {
    work: Work[];
    workPageCurrent: number;
    workPageCount: number;
    workValid: object;
    viewWork: object;
    updateWork: object;
}

// Models
