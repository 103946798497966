const state = {
  shop: [],
  shopPageCurrent: null,
  shopPageCount: null,
  shopValid: [],
  viewSell: {},
  sellRevert: {},
  sellPartRevert: {},
  sellPart: {},
  sellWork: {},
  sellDiscount: {},
  sellCancel: {},
  sellPartList: [],
  sellWorkList: [],
  sellPaymentList: [],
  sellDiscountList: [],
  sellDelete: null,
  sellUpdatePart: {},
  sellUpdateWork: {},
  sellPartRevertList: [],
  sellWorkRevertList: [],
};

export default state;
