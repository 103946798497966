import { GetterTree } from 'vuex';
import { StateShop, DataItem } from './types';

const getters: GetterTree<StateShop, object> = {
  shop: (state) => state.shop,
  viewSell: (state) => state.viewSell,
  shopValid: (state) => state.shopValid,
  shopPageCount: (state) => state.shopPageCount,
  sellDelete: (state) => state.sellDelete,
  sellRevert: (state) => state.sellRevert,
  sellPartRevert: (state) => state.sellPartRevert,
  sellCancel: (state) => state.sellCancel,
  sellUpdatePart: (state) => state.sellUpdatePart,
  sellUpdateWork: (state) => state.sellUpdateWork,
  sellDiscountList: (state) => state.sellDiscountList,

  sellPart: (state) => state.sellPart,
  sellWork: (state) => state.sellWork,
  sellDiscount: (state) => state.sellDiscount,
  sellPartList: (state) => state.sellPartList,
  sellWorkList: (state) => state.sellWorkList,
  sellDataList: (state) => {
    const data: object[] = [...state.sellPartList, ...state.sellWorkList];
    return data.sort((a, b) => ((a as DataItem).date > (b as DataItem).date ? 1 : -1));
  },
  sellPaymentList: (state) => state.sellPaymentList,
  sellPartRevertList: (state) => state.sellPartRevertList,
  sellWorkRevertList: (state) => state.sellWorkRevertList,
};

export default getters;
