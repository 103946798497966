import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Content from '@/views/nomenklatura/types';
import PartProvider from '../../api/providers/part';
import PartFilter from '../part-filter';
import PartSettingTable from '../part-setting-table';

@Component({
  template: require('./index.html'),
  components: {
    'part-setting-table': PartSettingTable,
    'part-filter': PartFilter,
  },
})

export default class PartNav extends Vue {
    @Prop() filter!: Content;

    @Prop({ default: () => null }) amountControl!: number;

    @Prop() headNomenkl!: object;

    @Prop() headsPart!: object;

    loading_amount = true;

    amount_control_active = false;

    part_lists: object[] = [];

    part_amount_control: number | null = null;

    // api

    getPartList(item) {
      this.loading_amount = true;

      PartProvider.getPartList(item.page, item, item['per-page'])
        .then((res) => {
          this.part_amount_control = Number(res.amountControl);

          this.$emit('amountControlParts', res);

          this.loading_amount = false;
        }).catch((err) => {
          console.error(err);
          this.loading_amount = false;
        });
    }

    // end api

    get isPage() {
      return this.$route.name === 'part';
    }

    get amountControlType() {
      return this.amount_control_active ? 'danger' : 'warning';
    }

    onAmount() {
      this.amount_control_active = !this.amount_control_active;
      this.filter.amount_control = this.amount_control_active ? 1 : 0;
      this.filter.page = 1;
      this.getPartList(this.filter);
    }

    @Watch('amount_control_active')
    onChangeAmount(val: boolean) {
      this.$root.$emit('amountControl', val);
    }

    @Watch('amountControl')
    flagLoadAmount(amount) {
      this.part_amount_control = amount;
      this.loading_amount = false;
    }
}
