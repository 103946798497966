import axios, { Method } from 'axios';
import isPlainObject from 'lodash/isPlainObject';
import { Response } from '@/types';

class BaseProvider {
    host;

    resource;

    expires;

    refresh;

    access;

    defaultCollectable = { pageNum: '1', perPage: '10' };

    constructor() {
      this.host = process.env.NODE_ENV === 'production' ? 'https://api.servix.io/v1' : 'https://api-dev.servix.io/v1';
    }

    static checkLastSymbol(string: string) {
      const lastStringSymbol = string[string.length - 1];
      if (lastStringSymbol !== '?') {
        return `${string}?`;
      }
      return string;
    }

    async request(method = 'GET', url = '/', data = {}, contentType = 'application/json') {
      try {
        await this.checkExpiresIn();

        const headers: object = {
          'x-access-token': this.access,
          'content-type': contentType,
        };

        let formattedData: object | null = data;
        if (method === 'POST') {
          if (contentType === 'application/json') {
            formattedData = JSON.parse(JSON.stringify(data));
          }
        } else {
          formattedData = null;
        }

        const res = await axios({
          method: method as Method,
          headers,
          url: `${this.host}${url}`,
          data: formattedData,
        });
        return res;
      } catch (err) {
        if (err.response.status === 401) {
          await this.refreshToken();
        }
        throw err;
      }
    }

    async allPageReuest(method = 'GET', url = '/', data = {}, contentType = 'application/json') {
      try {
        let currentPage = 1;
        let result: object[] = [];
        const trueUrl = BaseProvider.checkLastSymbol(url);

        const request = (page) => this.request(method, `${trueUrl}&page=${page}`, data, contentType);
        const firstRes = await request(currentPage);
        result = firstRes.data;
        const pageCount = Number(firstRes.headers['x-pagination-page-count']);

        if (pageCount > 1) {
          currentPage += 1;
          const promiseRequest: object[] = [];

          for (currentPage; currentPage <= pageCount; currentPage++) {
            promiseRequest.push(request(currentPage));
          }

          await Promise.allSettled(promiseRequest)
            .then((promiseResponds) => {
              const fulfilledResponds: PromiseFulfilledResult<Response>[] = promiseResponds.filter((res) => res.status === 'fulfilled') as PromiseFulfilledResult<Response>[];
              fulfilledResponds.forEach((respond) => {
                const respondData = respond.value.data;
                result = [...result, ...respondData as object[]];
              });
            })
            .catch((err) => {
              throw new Error(err);
            });
        }
        return result;
      } catch (err) {
        if (err.response.status === 401) {
          await this.refreshToken();
        }
        throw err;
      }
    }

    async checkExpiresIn() {
      this.access = localStorage.getItem('access');
      this.expires = localStorage.getItem('expires_in');
      this.refresh = localStorage.getItem('refresh');

      const currentTime = Math.round(new Date().getTime() / 1000);
      const expires = Number(this.expires);

      if (expires && expires <= currentTime) {
        await this.refreshToken();
      }
    }

    async refreshToken() {
      const data: object = { 'x-refresh-token': this.refresh };
      try {
        if (this.refresh === null) {
          throw new Error('this.refresh is null');
        }
        await axios.post(`${this.host}/site/updatetoken`, data)
          .then((res) => this.setStorage(res.data));
      } catch (err) {
        this.redirect();
        throw err;
      }
    }

    async setStorage(res) {
      const save: boolean = localStorage.getItem('access') !== undefined;

      this.access = res.access_token;
      this.refresh = res.refresh_token;
      this.expires = res.expiration_in;

      if (save) {
        localStorage.setItem('access', res.access_token);
        localStorage.setItem('refresh', res.refresh_token);
        localStorage.setItem('expires_in', res.expiration_in);
      }
    }

    redirect = () => {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      localStorage.removeItem('expires_in');
      window.location.replace('/');
    }

    buildUrl = (param) => {
      const key: string = Object.keys(param)[0];
      const elems: string[] = Object.keys(param[key]);

      let url = '';

      elems.forEach((i) => {
        if (param[key][i] === null) return;
        const value = `${key}[${i}]`;
        url += `&${encodeURIComponent(value)}=${
          encodeURIComponent(typeof (param[key][i]) === 'boolean' ? Number(param[key][i]) : param[key][i])}`;
      });
      return url;
    }

    superBuildUrl({
      method,
      nestedResources,
      formName,
      form,
      vars,
      collectable,
      expand,
    }) {
      const resourceUrl = this.resource + nestedResources;
      let varsUrl: string[] = [];
      let formUrl: string [] = [];
      const collectableUrl: string[] = [];
      const expandUrl: string[] = [];

      if (vars) {
        varsUrl = this.objectToVariableUrlArray(vars);
      }

      if (method === 'GET') {
        if (collectable) {
          const collectableCopy = { ...this.defaultCollectable, ...collectable };
          collectableUrl.push(`page=${collectableCopy.pageNum}`);
          collectableUrl.push(`per-page=${collectableCopy.perPage}`);
        }
        if (formName !== undefined) {
          formUrl = this.objectToVariableUrlArray({ [formName]: form });
        }
      }

      if (expand) {
        expandUrl[0] = `expand=${expand}`;
      }

      return (`${resourceUrl}?${
        formUrl.concat(varsUrl, collectableUrl, expandUrl).join('&')}`);
    }

    async superRequest({
      method,
      nestedResources,
      formName,
      form,
      vars,
      collectable,
      expand,
      headers,
    }) {
      const url = this.superBuildUrl({
        method,
        nestedResources,
        formName,
        form,
        vars,
        collectable,
        expand,
      });

      try {
        await this.checkExpiresIn();

        const defaultHeaders = {
          'x-access-token': this.access,
          'content-type': 'application/json',
        };

        const res = await axios({
          method,
          headers: { ...defaultHeaders, ...headers },
          url: `${this.host}${url}`,
          data: method === 'POST' && formName !== undefined
            ? { [formName]: form } : null,
        });
        return res;
      } catch (err) {
        console.error(err);
        throw err;
      }
    }

    private objectToVariableUrlArray = (obj) => {
      const res: string [] = [];
      if (isPlainObject(obj)) {
        Object.keys(obj).forEach((varName) => {
          if (typeof obj[varName] === 'object') {
            const fields = Object.keys(obj[varName]);
            fields.forEach((fieldName) => {
              res.push(
                `${encodeURIComponent(`${varName}[${fieldName}]`)
                }=${
                  encodeURIComponent(obj[varName][fieldName])}`,
              );
            });
          } else {
            res.push(`${encodeURIComponent(`${varName}`)}=${
              encodeURIComponent(obj[varName])}`);
          }
        });
        return res;
      }
      throw new Error('Invalid argument, mast be type of Object');
    }
}

export default BaseProvider;

export interface ApiErrorResponse {
    code: number;
    message: string;
    name: string;
    status: number;
    type: string;
}
