import { ActionTree } from 'vuex';
import StorageProvider from '../../api/providers/storage';
import { StateStorage } from './types';

const actions: ActionTree<StateStorage, object> = {

  getStorageList({ commit }, page) {
    StorageProvider.getStorageList(page)
      .then((res) => {
        commit('GET_STORAGE', { item: res.data, pageCount: res.pageCount });
        commit('GET_HEAD_PART', { storage: res.data });
      })
      .catch((err) => console.error(err));
  },

  getAvailableList({ commit }, page) {
    StorageProvider.getAvailableList(page)
      .then((res) => commit('GET_HEAD_PART', { storage: res.data }))
      .catch((err) => console.error(err));
  },

  sendStorage({ commit }, item) {
    commit('BTN_LOADER', true);
    StorageProvider.sendStorage(item)
      .then((res) => {
        commit('INSERT_STORAGE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Склад «${item.name}» успешно добавлен`, item: '' });
        commit('BTN_LOADER', false);
        commit('GET_VALID_STORAGE', { item: {} });
      })
      .catch((err) => {
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
        commit('GET_VALID_STORAGE', { item: err.response.data });
      });
  },

  changeStorage({ commit }, item) {
    commit('BTN_LOADER', true);
    StorageProvider.changeStorage(item.id, item)
      .then((res) => {
        commit('EDIT_STORAGE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Склад «${item.name}» успешно изменён`, item: '' });
        commit('BTN_LOADER', false);
        commit('GET_VALID_STORAGE', { item: {} });
      })
      .catch((err) => {
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
        commit('GET_VALID_STORAGE', { item: err.response.data });
      });
  },

  deleteStorage({ commit }, id) {
    commit('BTN_LOADER', true);
    StorageProvider.deleteStorage(id)
      .then((res) => {
        commit('DELETE_STORAGE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Склад «${res.data.name}» успешно удалён`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },
};

export default actions;
