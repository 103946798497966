import BaseProvider from '../base-provider';

class DeviceProvider extends BaseProvider {
  async getDevicePlain(model) {
    const res = await this.request('GET', `/device/plain?FullDeviceDescriptionSearch[model]=${model}`);
    return res;
  }

  async getDeviceTypeList() {
    const res = await this.request('GET', '/device/typelist');
    return res;
  }

  async getDeviceVendorList(id) {
    const res = await this.request('GET', `/device/vendorlist?typeId=${id}`);
    return res;
  }

  async getDeviceList(id) {
    const res = await this.request('GET', `/device/devicelist?vendorId=${id}`);
    return res;
  }

  async getValidationDeviceImei(imei) {
    const expand: string[] = ['vendor.type'];
    const res = await this.request('GET', `/device/imei?imei=${imei}&expand=${expand}`);
    return res;
  }

  async sendDeviceType(item) {
    const data: object = { DeviceType: item };
    const res = await this.request('POST', '/device/adddevicetype', data);
    return res;
  }

  async sendDeviceVendor(item) {
    const data: object = { DeviceVendor: item };
    const res = await this.request('POST', '/device/adddevicevendor', data);
    return res;
  }

  async sendDeviceModel(item) {
    const data: object = { Device: item };
    const res = await this.request('POST', '/device/adddevice', data);
    return res;
  }

  async changeDeviceType(item) {
    const data: object = { DeviceType: item };
    const res = await this.request('POST', `/device/updatedevicetype?id=${item.id}`, data);
    return res;
  }

  async changeDeviceVendor(item) {
    const data: object = { DeviceVendor: item };
    const res = await this.request('POST', `/device/updatedevicevendor?id=${item.id}`, data);
    return res;
  }

  async changeDeviceModel(item) {
    const data: object = { Device: item };
    const res = await this.request('POST', `/device/updatedevice?id=${item.id}`, data);
    return res;
  }

  async deleteDeviceType(item) {
    const data: object = { DeviceTypeDeleteForm: item };
    const res = await this.request('POST', '/device/deletedevicetype', data);
    return res;
  }

  async deleteDeviceVendor(item) {
    const data: object = { DeviceVendorDeleteForm: item };
    const res = await this.request('POST', '/device/deletedevicevendor', data);
    return res;
  }

  async deleteDeviceModel(id) {
    const data: object = { DeviceDeleteForm: { id } };
    const res = await this.request('POST', '/device/deletedevice', data);
    return res;
  }
}

export default new DeviceProvider();
