import Vue from 'vue';
import { Form } from 'element-ui';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb } from '@/types';
import BillingProvider from '../../api/providers/billing';

interface FormBuyPackage {
  acquirer_id: number;
  sum: number;
}
@Component({
  template: require('./index.html'),
  components: {
  },
})
export default class BalanceBuy extends Vue {
    @Action addBreadcrumb;

    @Action getBillingAcquirers;

    @Action getStuffList;

    @Getter acquirers;

    @Getter btnloader;

    @Getter balance;

    @Getter stuff;

    get stuffLength(): number {
      return this.stuff.length;
    }

    get stuffString(): string {
      let stuffString = `${this.stuffLength} `;
      switch (this.stuffLength) {
        case 1: stuffString += 'сотрудник'; break;
        case 2:
        case 3:
        case 4: stuffString += 'сотрудника'; break;
        default: stuffString += 'сотрудников'; break;
      }

      return stuffString;
    }

    get balanceAfterPayment(): number {
      if (this.formBuyPackage.sum) {
        return Math.floor((
          Number(this.formBuyPackage.sum) + Number(this.balance.payment_balance)
        ) * 100) / 100;
      }
      return Number(this.balance.payment_balance);
    }

    get paymentPerStuff(): number {
      switch (this.stuffLength) {
        case 1:
        case 2: return 400; break;
        case 3:
        case 4: return 380; break;
        case 5:
        case 6: return 360; break;
        case 7:
        case 8:
        case 9: return 330; break;
        case 10:
        case 11:
        case 12: return 330; break;
        default: return Math.floor((4200 / this.stuffLength) * 100) / 100; break;
      }
    }

    get previewDaysWork(): number {
      return Math.floor(
        (this.balanceAfterPayment / (this.paymentPerStuff * this.stuffLength)) * 30,
      );
    }

    get previewSms(): number {
      return Math.floor(this.balanceAfterPayment / this.smsPrice);
    }

    smsPrice = -1;

    errors = {
      sum: [''],
    }

    formRules = {
      sum: [
        { required: true, message: 'Сумма обязательна', trigger: 'blur' },
        { type: 'number', message: 'Сумма должна быть числом', trigger: 'blur' },
      ],
    };

    formBuyPackage: FormBuyPackage = {
      acquirer_id: 6,
      sum: 500,
    };

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Биллинг', link: '/service/billing/' },
      { id: 3, section: 'Баланс', link: '/service/billing/balance/all/1' },
      { id: 4, section: 'Пополнение баланса', link: null },
    ];

    mounted() {
      this.addBreadcrumb(this.breadcrumb);
      this.getBillingAcquirers();
      this.getStuffList();
      BillingProvider.getBillingTariff();
      document.title = 'Пополнение баланса';
      this.getSmsPrice();
    }

    distributionIcon = (id) => {
      switch (id) {
        case 1: return { newpayment__payment_bank: true };
        case 2: return { newpayment__payment_yandex: true };
        case 3: return { newpayment__payment_qiwi: true };
        case 4: return { newpayment__payment_sber: true };
        case 5: return { newpayment__payment_alfa: true };
        case 6: return { newpayment__payment_visa: true };
        case 8: return { newpayment__payment_googlepay: true };

        default: return null;
      }
    }

    getSmsPrice() {
      BillingProvider.getBillingTariff()
        .then((res) => {
          this.smsPrice = res.data.smsPrice;
        })
        .catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить цену SMS', item: '' });
          throw new Error(err);
        });
    }

    sendPaymentForm() {
      (this.$refs.formNewPayment as Form).validate((valid) => {
        if (valid) {
          const sum = Number(this.formBuyPackage.sum);
          BillingProvider.sendBalancePayment(
            sum,
            this.formBuyPackage.acquirer_id,
          )
            .then((resp) => {
              window.open(resp.data.payment_url);
            })
            .catch((err) => {
              this.errors = err.response.data;
              console.error(err);
            });
        }
      });
    }
}
