import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PointProvider from '@/api/providers/point';

import {
  Repair,
  RepairWork,
  RepairPart,
  RepairDiscount,
  RepairAddition,
  RepairPayment,
  RepairStatus,
  RepairTransfer,
  Document as ApiDocument,
  Point,
  Response,
  User,
} from '@/types';
import RepairViewSalary from '@/components/repair-view-salary';
import BaseProvider from '../../api/base-provider';
import CashProvider, { ApiCashbox } from '../../api/providers/cash';
import ClientsProvider from '../../api/providers/clients';
import DocumentProvider from '../../api/providers/document';
import RepairProvider, { ApiRepairComment } from '../../api/providers/repair';
import SmsProvider, { SendSmsForm } from '../../api/providers/sms';
import UserStatusProvider from '../../api/providers/user-status';
import ViewDate from '../forms/date';
import Numbers from '../forms/numbers';
import ViewUserName from '../forms/user-name';
import RepairPrice from '../repair-price';
import RepairViewComments from './__comments';
import DialogCloseOrder from './__dialog-close-order';
import RepairViewFiles from './__files';
import RepairViewInfo from './__info';
import RepairVeiwLog from './__log';
import { RepairDiscountAdditionRowVM, RepairPaymentRowVM, RepairViewPayments } from './__payments';
import DialogDiscount from './__payments/dialog-discounts';
import DialogPayments from './__payments/dialog-payments';
import RepairViewStatusBlock from './__status-block';
import { RepairDeviceDescription, RepairViewSummary } from './__summary';
import RepairViewWork from './__worksandparts';

enum RepairViewActiveTab {
  'info' = 'info',
  'work' = 'work',
  'pay' = 'pay',
  'salary' = 'salary',
}
interface SendSmsError {
  text?: string;
  method?: string;
  client_id?: string;
}

@Component({
  template: require('./view.html'),
  components: {
    'repair-view-summary': RepairViewSummary,
    'repair-view-info': RepairViewInfo,
    'repair-view-work': RepairViewWork,
    'repair-view-payments': RepairViewPayments,
    'repair-view-comments': RepairViewComments,
    'repair-view-status': RepairViewStatusBlock,
    'repair-view-log': RepairVeiwLog,
    'repair-date': ViewDate,
    'dialog-pay': DialogPayments,
    'dialog-discount': DialogDiscount,
    numbers: Numbers,
    'dialog-close-order': DialogCloseOrder,
    'repair-view-files': RepairViewFiles,
    'repair-price': RepairPrice,
    'user-name': ViewUserName,
    RepairViewSalary,
  },
})

export default class RepairView extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action getStuffList;

    @Action getSettings;

    @Action showErrorPush;

    @Action showSuccessPush;

    @Getter btnloader;

    @Getter stuff;

    @Getter stuffPageCount;

    @Getter pointPageCount;

    @Getter settings;

    @Getter user;

    /**
     * TODO: по какой-то прчине в this.user может быть массив.
     * Очень тупо.
     */

    point: Point[] = [];

    sendSmsMethod = 'auto';

    sendSmsMethodOptions = [{
      value: 'auto',
      label: 'Автоматически',
    }, {
      value: 'sms',
      label: 'SMS',
    }, {
      value: 'voice',
      label: 'Голосовой звонок',
    }];

    get userAsObject() {
      if (Array.isArray(this.user)) return {};
      return this.user;
    }

    // массив касс
    cashboxes: Array<ApiCashbox> = [];

    // массив комментариев к заказу
    comments: Array<ApiRepairComment> = [];

    // Флаг загрузки удаленных комментариев
    includeDeletedComments = false;

    get sortedComments(): Array<ApiRepairComment> {
      return this.comments.sort((a, b) => {
        if (a.created_at > b.created_at) return -1;
        if (a.created_at < b.created_at) return 1;
        return 0;
      });
    }

    // экземпляр текущего заказа.
    viewRepair: Repair = {};

    // флаг отображения индикатора загрузки данных
    loading_full_page = false;

    // обработчик смены пользовательского статуса
    changeCustomRepairStatus(custom_status_id: number) {
      const item = {
        repair_id: Number(this.viewRepair.id),
        custom_status_id: custom_status_id === undefined ? null : custom_status_id,
      };

      this.loading_full_page = true;
      RepairProvider.sendRepairCustomStatus(item)
        .then((result) => {
          this.viewRepair.status_id = result.data.status_id;
          this.viewRepair.custom_status_id = result.data.custom_status_id;
          this.loading_full_page = false;
          this.showSuccessPush({ title: 'Статус заказа изменен.' });
        })
        .catch((err) => {
          this.loading_full_page = false;
          this.showErrorPush({ title: 'Не удалось изменить статус заказа.' });
          console.error('Ошибка при изменении статуса заказа:', err);
        });
    }

    // Значение из базы ненадежных клиентов
    badClientReasons: string[] = [];

    // значение из формы причины помещения клиента в черный список
    blackListReason = '';

    // режим активной сейчас вкладки
    contentActive: RepairViewActiveTab = RepairViewActiveTab.info;

    // Флаг отображения модального окна переименования заказа
    modalName = false;

    // Поле для хранения значения имени заказа из формы переименования.
    nameOrder = '';

    // Флаг отображения модалки отправки СМС
    showSendSmsModal = false;

    // текст смс для формы
    sendSmsText = '';

    // ошибка отправки смс
    sendSmsError: SendSmsError = {};

    refreshForm = '';

    get clientName(): string {
      try {
        if (this.viewRepair.client) {
          if (this.viewRepair.client.type === 1) {
            // физ.лицо
            return `${this.viewRepair.client.last_name} ${this.viewRepair.client.first_name} ${this.viewRepair.client.middle_name}`;
          }
          // юр.лицо
          return this.viewRepair.client.name as string;
        }
        return '';
      } catch {
        return '';
      }
    }

    get clientPhone(): string {
      try {
        const phone = this.viewRepair.client?.phone ? this.viewRepair.client.phone : '';
        return phone;
      } catch {
        return '';
      }
    }

    get clientUnrestrictedPhone(): string {
      try {
        const unrestrictedPhone = this.viewRepair.client?.unrestricted_phone
          ? this.viewRepair.client.unrestricted_phone.toString()
          : '';
        return unrestrictedPhone;
      } catch {
        return '';
      }
    }

    get clientStatus(): number {
      try {
        const status = this.viewRepair.client ? this.viewRepair.client.status : 1;
        return status as number;
      } catch {
        return 1;
      }
    }

    get mastersWithDeleted() {
      const masters = this.stuff;
      if (this.viewRepair.master
        && this.viewRepair) {
        const foundedUser = masters.find(
          (employee) => employee.id === ((this.viewRepair as Repair).master as User).id,
        );
        if (foundedUser === undefined) {
          masters.push(this.viewRepair.master);
        }
      }

      return masters;
    }

    /**
     * Геттер выделяет мастеров из общего списка сотрудников
     * и сортирует их по ФИО
     */
    get sortedWorkers() {
      return this.mastersWithDeleted.filter(
        (element) => element.rights.can_work_on_repair,
      ).sort((a, b) => {
        if (a.last_name > b.last_name) {
          return 1;
        } if (a.last_name < b.last_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        } if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.middle_name > b.middle_name) {
          return 1;
        } if (a.middle_name < b.middle_name) {
          return -1;
        }
        return 0;
      });
    }

    // оставлен геттером для обратной совместимости, но вообще он бесполезен.
    get workValue(): number | null {
      const masterId = this.viewRepair.master_id ? this.viewRepair.master_id : null;
      return masterId;
    }

    pageStuff = 1;

    get acceptorNameOnRepair(): string {
      if (this.viewRepair.acceptor) {
        return `${this.viewRepair.acceptor.last_name} ${this.viewRepair.acceptor.first_name} ${this.viewRepair.acceptor.middle_name}`;
      }
      return 'Не известно';
    }

    printer: Array<ApiDocument> = [];

    get sortedPrinter() {
      return this.printer.map((x) => x).sort((a, b) => {
        if (a.title > b.title) {
          return 1;
        } if (a.title < b.title) {
          return -1;
        }
        return 0;
      });
    }

    printValue: number | null= null;

    page_document_list = 2;

    modalBlackList = false;

    // Список всех пользовательских статусов
    allCustomRepairStatuses: Array<RepairStatus> = [];

    // объект-флаг открытия модалки платежей и возвратов
    openModalPayments: object = { pay: false };

    // объект-флаг открытия модалки скидок и наценок
    openModalDiscountOrAddition: object = { btn: false };

    // флаг открытия окна закрытия заказа
    openDialogCloseOrder = false;

    // оставлен в виде геттера чтобы сохранить обратную совместимость с другими компонентами
    get works(): object[] {
      try {
        return (this.viewRepair.repair_works !== undefined)
          ? this.viewRepair.repair_works
          : [];
      } catch {
        return [];
      }
    }

    // оставлен в виде геттера чтобы сохранить обратную совместимость с другими компонентами
    get parts(): object[] {
      try {
        return (this.viewRepair.repair_parts !== undefined)
          ? this.viewRepair.repair_parts
          : [];
      } catch {
        return [];
      }
    }

    // оставлен в виде геттера чтобы сохранить обратную совместимость с другими компонентами
    get additions(): RepairAddition[] {
      try {
        return (this.viewRepair.repair_additions !== undefined)
          ? this.viewRepair.repair_additions
          : [];
      } catch {
        return [];
      }
    }

    // оставлен в виде геттера чтобы сохранить обратную совместимость с другими компонентами
    get discounts(): RepairDiscount[] {
      try {
        return (this.viewRepair.repair_discounts !== undefined)
          ? this.viewRepair.repair_discounts
          : [];
      } catch {
        return [];
      }
    }

    get listPayments(): RepairPayment[] {
      try {
        return (this.viewRepair.repair_payments !== undefined)
          ? this.viewRepair.repair_payments
          : [];
      } catch {
        return [];
      }
    }

    token: string | null = null;

    get id() {
      return this.$route.params.id;
    }

    get isClientEnabled(): boolean {
      try {
        const enabled = this.viewRepair.client && this.viewRepair.client.enabled
          ? this.viewRepair.client.enabled === true
          : false;
        return enabled;
      } catch {
        return false;
      }
    }

    get isClientSmsAgreement(): boolean {
      try {
        const smsAgreement = this.viewRepair.client ? this.viewRepair.client.sms_agreement : false;
        return smsAgreement ?? false;
      } catch {
        return false;
      }
    }

    get devices(): RepairDeviceDescription[] {
      try {
        const result: RepairDeviceDescription[] = [];
        this.viewRepair.repair_objects.forEach((object) => {
          const item: RepairDeviceDescription = {
            type: object.type,
            vendor: object.vendor,
            model: object.model,
            amount: object.amount,
          };
          result.push(item);
        });
        return result;
      } catch (e) {
        return [];
      }
    }

    get statusContent() {
      switch (this.clientStatus) {
        case 1: return 'Рядовой клиент';
        case 2: return 'Ваш любимый клиент';
        default: return 'Клиент в чёрном списке';
      }
    }

    get repairProducts(): RepairWork[] & RepairPart[] {
      return [...this.works, ...this.parts] as RepairWork[] & RepairPart[];
    }

    get totalProductsPrice() {
      let result = 0;
      if (Array.isArray(this.repairProducts)) {
        result = this.repairProducts.reduce((sum, part) => sum + (part.price * part.amount), 0);
      }
      return Math.round(result * 100) / 100;
    }

    /**
     * Вычисляет сумму НДС по всем товарам и услугам в заказе.
     * 1 - без ндс
     * 2 - ндс 0%
     * 3 - ндс 10 в т.ч.
     * 4 - ндс 20 в т.ч.
     * 5 - ндс 10 сверху
     * 6 - ндс 20 сверху
     */
    get totalProductsVat() {
      if (this.repairProducts.length !== 0) {
        const result = this.repairProducts.reduce((sum, product) => {
          const vatId = 'work' in product ? product.work.vat : (product as RepairPart).part.vat;

          switch (vatId) {
            case 1: return sum + 0;
            case 2: return sum + 0;
            case 3: return sum + (((product.price * product.amount) / 1.1) * 0.1);
            case 4: return sum + (((product.price * product.amount) / 1.2) * 0.2);
            case 5: return sum + (product.price * 0.1 * product.amount);
            case 6: return sum + (product.price * 0.2 * product.amount);
            default: return sum + 0;
          }
        }, 0);
        return Math.round(result * 100) / 100;
      }
      return 0;
    }

    get totalWorksWithVat() {
      if (this.repairProducts.length !== 0) {
        const allWorks = this.repairProducts.filter((item) => 'work' in item);

        const resultSumWorks = allWorks.reduce((sum, works) => {
          switch (works.work.vat) {
            case 5: return sum + (works.price * 1.1 * works.amount);
            case 6: return sum + (works.price * 1.2 * works.amount);
            default: return sum + (works.price * works.amount);
          }
        }, 0);

        return Math.round(resultSumWorks * 100) / 100;
      }
      return 0;
    }

    get totalPriceWithAdditionsAndDiscounts() {
      const vat = Number(this.totalProductsWithVat);
      const costWorks = Number(this.totalWorksWithVat);
      let discount = 0;
      let addition = 0;
      if (Array.isArray(this.discounts)) {
        discount = this.discounts
          .reduce((sum, item) => {
            // Добавил   default: return 0;
            switch (item.type_id) {
              case 1: return sum + (vat * (Number(item.amount) / 100));
              case 2: return sum + Number(item.amount);
              case 3: return sum + (costWorks * (Number(item.amount) / 100));
              default: return 0;
            }
          }, 0);
      }

      if (Array.isArray(this.additions)) {
        addition = this.additions
          .reduce((sum, item) => {
          // Добавил   default: return 0;
            switch (item.type_id) {
              case 1: return sum + (vat * (Number(item.amount) / 100));
              case 2: return sum + Number(item.amount);
              default: return 0;
            }
          }, 0);
      }
      const result = (vat - discount + addition);
      return Math.round(result * 100) / 100;
    }

    get totalProductsWithVat() {
      if (this.repairProducts.length !== 0) {
        const price = this.repairProducts.reduce((sum, part) => {
          const product = 'work' in part ? part.work.vat : (part as RepairPart).part.vat;

          switch (product) {
            case 5: return sum + (part.price * 1.1 * part.amount);
            case 6: return sum + (part.price * 1.2 * part.amount);
            default: return sum + (part.price * part.amount);
          }
        }, 0);
        return Math.round(price * 100) / 100;
      }
      return 0;
    }

    get toPaySum() {
      let result = Number(this.totalPriceWithAdditionsAndDiscounts);

      if (Array.isArray(this.listPayments)) {
        this.listPayments.forEach((item) => {
          result -= item.sum;
        });
        return Math.round(result * 100) / 100;
      }
      return 0;
    }

    get totalSumWorks() {
      if (this.viewRepair.repair_works) {
        return this.viewRepair.repair_works.reduce(
          (total, work) => total + (work.price * work.amount),
          0,
        );
      }
      return 0;
    }

    created() {
      const hash = this.$route.hash.replace('#', '');
      if (this.$route.hash === '') {
        this.pathInfoRepair('Информация');
      } else {
        this.activeTab(hash);
      }
    }

    mounted() {
      RepairProvider.getViewRepair(this.id)
        .then((res) => {
          this.viewRepair = res.data;
          document.title = `${this.viewRepair.title} - программа для сервисных центров Servix`;
          this.getStuffList(1);
          this.getPointList({ page: 1 });
          this.breadcrumb(this.viewRepair.title);
          DocumentProvider.getAllDocuments({ type: this.viewRepair.type === 1 ? 1 : 2 })
            .then((result) => {
              this.printer = [...this.printer, ...result];
              return DocumentProvider.getAllDocuments({ type: 7 });
            })
            .then((result) => {
              this.printer = [...this.printer, ...result];
            })
            .catch((err) => {
              console.error('Ошибка при получении списка документов.', err);
              this.showErrorPush({ title: 'Не удалось получить список документов.' });
            });
          if (this.viewRepair.client) {
            ClientsProvider.sendClientCheckBlacklist({ phone: `${this.viewRepair.client.unrestricted_phone}` })
              .then((response) => {
                if (Array.isArray(response.data) && response.data.length > 0) {
                  this.badClientReasons = response.data.map((x) => x.reason);
                }
              })
              .catch((err) => {
                console.error('Не удалось проверить по базе ненадежных клиентов', err);
              });
          } else {
            throw new Error('this.viewRepair.client is undefined');
          }
        })
        .catch((err) => {
          console.error(err);
          this.showErrorPush({ title: 'Не удалось получить данные о заказе.' });
        });
      UserStatusProvider.getAllUserStatuses()
        .then((statuses) => {
          this.allCustomRepairStatuses = statuses;
        })
        .catch((err) => {
          console.error(err);
          this.showErrorPush({ title: 'Не удалось получить список пользовательских статусов.' });
        });
      CashProvider.getAllCashes()
        .then((cashboxes) => {
          this.cashboxes = cashboxes;
        })
        .catch(() => {
          this.showErrorPush({ title: 'Не удалось получить список касс.' });
        });
      this.getRepairComments(Number(this.id));
      this.getSettings();
      const baseProvider = new BaseProvider();
      baseProvider.checkExpiresIn()
        .then(() => {
          this.token = baseProvider.access;
        })
        .catch((err) => console.error(err));
    }

    /**
     * Получение комментариев
     * @param  {number} id
     * @param  {} include_deleted=false
     */
    getRepairComments(id: number) {
      this.loading_full_page = true;
      RepairProvider.getAllRepairComments(id, this.includeDeletedComments)
        .then((comments) => {
          this.comments = comments;
          this.loading_full_page = false;
        })
        .catch(() => {
          this.loading_full_page = false;
          this.showErrorPush({ title: 'Не удалось загрузить комментарии к заказу.' });
        });
    }

    async getPointList(item) {
      try {
        let { page } = item;
        let results: object[] = [];
        let firstResponse;
        await PointProvider.getPointList(item.page)
          .then((res) => {
            firstResponse = res;
            return res;
          })
          .catch((err) => console.error(err));
        const { pageCount } = firstResponse;
        results = firstResponse.data;
        if (pageCount > 1) {
          page += 1;
          const promiseResults: object[] = [];
          for (page; page <= pageCount; page++) {
            promiseResults.push(PointProvider.getPointList(page));
          }
          await Promise.all(promiseResults)
            .then((responses) => {
              (responses as Response[]).forEach((response) => {
                results = [...results, ...response.data as object[]];
              });
            });
        }
        this.point = results as Point[];
      } catch (err) {
        throw new Error(err);
      }
    }

    ctrlInfoRepair(e) {
      switch (e.target.textContent.trim()) {
        case 'Информация': this.contentActive = RepairViewActiveTab.info; break;
        case 'Работы и запчасти': this.contentActive = RepairViewActiveTab.work; break;
        case 'Платежи': this.contentActive = RepairViewActiveTab.pay; break;
        case 'Зарплата': this.contentActive = RepairViewActiveTab.salary; break;
        default: break;
      }
      this.pathInfoRepair(e.target.textContent);
    }

    closeDialogOrder(flags: boolean) { this.openDialogCloseOrder = flags; }

    pathInfoRepair(text) {
      switch (text.trim()) {
        case 'Информация': this.$router.push({ hash: this.contentActive }); break;
        case 'Работы и запчасти': this.$router.push({ hash: this.contentActive }); break;
        case 'Платежи': this.$router.push({ hash: this.contentActive }); break;
        case 'Зарплата': this.$router.push({ hash: this.contentActive }); break;
        default: break;
      }
    }

    activeTab(hash) {
      if (hash === 'info' || hash === 'work' || hash === 'pay' || hash === 'salary') {
        this.contentActive = hash;
      } else {
        this.$router.push({ name: 'repair' });
      }
    }

    methodDisabledButtons() {
      this.$notify.error({
        title: 'Вам не разрешено управлять заказами',
        message: '',
        position: 'bottom-right',
      });
    }

    changeStuff(e) {
      const master = { repair_id: this.viewRepair.id, master_id: Number(e) };

      RepairProvider.sendRepairAssignmaster(master)
        .then((res) => {
          this.viewRepair.status_id = res.data.status_id;
          this.viewRepair.master_id = res.data.master_id;
        })
        .catch((err) => {
          console.error('Не удалось назначить мастера', err);
          this.showErrorPush({ title: 'Не удалось назначить мастера' });
        });
    }

    printPaper(id) {
      const printOperation: ApiDocument = (this.printer.find(
        (print) => print.id === id,
      ) as ApiDocument);
      const domain = process.env.NODE_ENV === 'production' ? 'printing.servix.io' : 'printing-dev.servix.io';
      if (printOperation.type === undefined) {
        window.open(`https://${domain}/print/cashoperation?token=${this.token}&operationId=${printOperation.id}`);
      } else if (printOperation.type === 7) {
        window.open(`https://${domain}/print/document?token=${this.token}&documentId=${id}&entityId=${this.id}&pdf=1`);
      } else {
        window.open(`https://${domain}/print/document?token=${this.token}&documentId=${id}&entityId=${this.id}`);
      }
    }

    /**
     * Перемещает пользователя на страницу приема нового аналогичного заказа,
     * с выбранным клиентом.
     */
    onCreateNewClinentRepairClick() {
      if (this.viewRepair.type === 1) {
        this.$router.push({ name: 'repair-form-add', query: { id: this.viewRepair.client_id } });
      } else {
        this.$router.push({ name: 'refill-form-add', query: { id: this.viewRepair.client_id } });
      }
    }

    /**
     * Перемещает пользователя на страницу редактирования заказа или заправки.
     */
    updateRepair() {
      if (this.viewRepair.type === 1) {
        this.$router.push({ name: 'repair-form-edit', params: { id: this.$route.params.id } });
      } else {
        this.$router.push({ name: 'refill-form-edit', params: { id: this.$route.params.id } });
      }
    }

    /**
     * Перемещает заказмежду филиалами
     * @param pointId Идентификатор пункта назначения
     */
    changePoint(pointId) {
      const transfer = { repair_id: Number(this.id), target_point_id: pointId };
      const point = this.point.filter((pointItem) => pointItem.id === pointId)[0];

      if (this.settings.easy_repair_transfer) {
        this.$confirm(`Вы уверены что хотите переместить заказ в филиал "${point.name}"?`, {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'info',
        }).then(() => RepairProvider.sendRepairTransfer(transfer))
          .then(({ data }) => {
            if (data.status_id === 3) { // при простом перемещении, трансфер сразу встает в статус 3
              this.viewRepair.current_point_id = pointId;
              this.showSuccessPush({ title: 'Заказ успешно перемещен' });
            } else {
              this.showSuccessPush({ title: 'Заказ отправлен на перемещение' });
            }
            (this.viewRepair.repair_transfers as RepairTransfer[]).push(data);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Отмена',
            });
          });
      } else {
        this.$router.push({ name: 'repair-createtransfer', query: { repairid: this.id, destpointid: pointId } });
      }
    }

    // метод открытия окна переименования заказа.
    openRepairTitleDialog() {
      this.modalName = true;
    }

    /**
     * Метод переименовывания заказа
     */
    saveName() {
      const patch = { id: this.viewRepair.id, title: this.nameOrder };
      this.loading_full_page = true;
      RepairProvider.sendRepairUpdate(patch)
        .then((resp) => {
          this.viewRepair.title = resp.data.title;
          this.breadcrumb(this.viewRepair.title);
          this.loading_full_page = false;
          this.modalName = false;
          document.title = `${this.viewRepair.title} - программа для сервисных центров Servix`;
        })
        .catch((err) => {
          console.error(err);
          this.loading_full_page = false;
          this.modalName = false;
        });
    }

    // начало геттеров для раздела платежей и скидок
    get repairDiscountRows(): RepairDiscountAdditionRowVM[] {
      const result: RepairDiscountAdditionRowVM[] = [];
      if (Array.isArray(this.discounts)) {
        this.discounts
          .forEach((item) => {
            if (item.amount && item.name) {
              let discountSum = 0;
              if (item.type_id === 1) { // % от стоимости
                discountSum = (this.totalProductsWithVat * item.amount) / 100;
              } else if (item.type_id === 2) { // в рублях
                discountSum = item.amount;
              } else if (item.type_id === 3) { // % от стоимости услуг
                discountSum = (this.totalWorksWithVat * item.amount) / 100;
              }
              const vmItem: RepairDiscountAdditionRowVM = {
                id: item.id,
                is_discount: true,
                name: item.name,
                size: item.amount,
                sum: discountSum,
                type: item.type_id,
              };
              result.push(vmItem);
            }
          });
      }
      return result;
    }

    get repairAdditionRows(): RepairDiscountAdditionRowVM[] {
      const result: RepairDiscountAdditionRowVM[] = [];
      if (Array.isArray(this.additions)) {
        this.additions
          .forEach((item) => {
            let additionSum = 0;
            if (item.type_id === 1) { // % от стоимости
              additionSum = (this.totalProductsWithVat * item.amount) / 100;
            } else if (item.type_id === 2) { // в рублях
              additionSum = item.amount;
            }
            const vmItem: RepairDiscountAdditionRowVM = {
              id: item.id,
              is_discount: false,
              name: item.name,
              size: item.amount,
              sum: additionSum,
              type: item.type_id,
            };
            result.push(vmItem);
          });
      }
      return result;
    }

    get repairDiscountAndAdditionRows(): RepairDiscountAdditionRowVM[] {
      return [...this.repairDiscountRows, ...this.repairAdditionRows];
    }

    get repairPaymentRows(): RepairPaymentRowVM[] {
      const result: RepairPaymentRowVM[] = [];
      if (Array.isArray(this.listPayments)) {
        this.listPayments
          .forEach((item) => {
            const cashbox = this.cashboxes.filter((x) => x.id === item.cashbox_id);
            const vmItem: RepairPaymentRowVM = {
              id: item.id,
              cashbox_id: item.cashbox_id,
              is_prepayment: item.is_prepayment,
              cashbox_name: cashbox.length > 0 ? cashbox[0].name : '(неизвестная касса)',
              created_at: item.created_at,
              is_cashbox_cashless: cashbox.length > 0 && cashbox[0].cashless === true,
              sum: item.sum,
            };
            result.push(vmItem);
          });
      }
      return result;
    }

    // конец геттеров для раздела платежей и скидок
    // начало обработчиков кликов раздела платежей и скидок
    onCreatePayment() {
      this.openModalPayments = { pay: true, btn: 'add' };
    }

    onCreatePaymentReturn() {
      this.openModalPayments = { pay: true, btn: 'reset' };
    }

    onDeletePayment(id) {
      this.$confirm('Вы уверены что хотите удалить платеж?', {
        confirmButtonText: 'Ок',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => RepairProvider.deleteRepairPayments(id))
        .then(() => {
          this.showSuccessPush({ title: 'Оплата успешно удалена' });
          if (this.viewRepair.repair_payments) {
            this.viewRepair.repair_payments = this.viewRepair.repair_payments.filter(
              (x) => x.id !== id,
            );
          }
        })
        .catch(() => {
          this.showErrorPush({ title: 'Не удалось удалить оплату' });
        });
    }

    openDiscountModal() {
      this.openModalDiscountOrAddition = { btn: true, mode: 'discount' };
    }

    onSendDiscount(item) {
      const itemCopy = { ...item };
      itemCopy.repair_id = this.viewRepair.id;
      RepairProvider.sendRepairDiscount(itemCopy)
        .then((resp) => {
          if (this.viewRepair.repair_discounts) {
            this.viewRepair.repair_discounts.push(resp.data);
          } else {
            this.viewRepair.repair_discounts = [];
            this.viewRepair.repair_discounts.push(resp.data);
          }
          this.showSuccessPush({ title: 'Скидка сохранена' });
          this.openModalDiscountOrAddition = { btn: null, mode: 'discount' };
        })
        .catch(() => {
          this.showErrorPush({ title: 'Не удалось сохранить скидку' });
          this.openModalDiscountOrAddition = { btn: null, mode: 'discount' };
        });
    }

    openAdditionModal() {
      this.openModalDiscountOrAddition = { btn: true, mode: 'addition' };
    }

    onSendAddition(item) {
      const itemCopy = { ...item };
      itemCopy.repair_id = this.viewRepair.id;
      RepairProvider.sendRepairAddition(itemCopy)
        .then((resp) => {
          if (this.viewRepair.repair_additions) {
            this.viewRepair.repair_additions.push(resp.data);
          } else {
            this.viewRepair.repair_additions = [];
            this.viewRepair.repair_additions.push(resp.data);
          }
          this.showSuccessPush({ title: 'Скидка сохранена' });
          this.openModalDiscountOrAddition = { btn: null, mode: 'addition' };
        })
        .catch(() => {
          this.showErrorPush({ title: 'Не удалось сохранить скидку' });
          this.openModalDiscountOrAddition = { btn: null, mode: 'addition' };
        });
    }

    onDeleteDiscount(id: number) {
      this.$confirm('Вы уверены что хотите удалить скидку?', {
        confirmButtonText: 'Ок',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => RepairProvider.deleteRepairDiscount(id))
        .then(() => {
          this.showSuccessPush({ title: 'Скидка успешно удалена' });
          if (this.viewRepair.repair_discounts) {
            this.viewRepair.repair_discounts = this.viewRepair.repair_discounts.filter(
              (x) => x.id !== id,
            );
          }
        })
        .catch(() => {
          this.showErrorPush({ title: 'Не удалось удалить скидку' });
        });
    }

    onDeleteAddition(id: number) {
      this.$confirm('Вы уверены что хотите удалить наценку?', {
        confirmButtonText: 'Ок',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => RepairProvider.deleteRepairAddition(id))
        .then(() => {
          this.showSuccessPush({ title: 'Наценка успешно удалена' });
          if (this.viewRepair.repair_additions) {
            this.viewRepair.repair_additions = this.viewRepair.repair_additions.filter(
              (x) => x.id !== id,
            );
          }
        })
        .catch(() => {
          this.showErrorPush({ title: 'Не удалось удалить наценку' });
        });
    }
    // конец обработчиков кликов раздела платежей и скидок

    // начало обработчиков модалки оплаты заказа
    onSendRepairPayment(form) {
      const formCopy = { ...form };
      formCopy.repair_id = this.viewRepair.id;
      RepairProvider.sendRepairPayment(formCopy)
        .then((resp) => {
          if (resp.status === 200) {
            this.showSuccessPush({ title: 'Оплата успешно сохранена!' });
            if (this.viewRepair.repair_payments) {
              this.viewRepair.repair_payments.push(resp.data);
            } else {
              this.viewRepair.repair_payments = [];
              this.viewRepair.repair_payments.push(resp.data);
            }
          } else {
            this.showErrorPush({ title: 'Не удалось сохранить оплату заказа' });
          }
          this.openModalPayments = { pay: false, btn: 'add' };
        })
        .catch(() => {
          this.showErrorPush({ title: 'Не удалось сохранить оплату заказа' });
          this.openModalPayments = { pay: false, btn: 'add' };
        });
    }
    // конец обработчиков модалки оплаты заказа

    /**
     * Обработчик смены статусов клиента
     * @param {Number} newStatus идентификатор нового статуса клиента.
     */
    changeClientStatus(newStatus: number) {
      if (this.viewRepair.client) {
        if (this.viewRepair.client.status === newStatus) return;
        switch (newStatus) {
          case 1:
            this.loading_full_page = true;
            ClientsProvider.sendClientSetRegular(this.viewRepair.client.id)
              .then((resp) => {
                if (this.viewRepair.client) {
                  this.viewRepair.client.status = resp.data.status;
                }
                this.loading_full_page = false;
              })
              .catch((err) => {
                console.error(err);
                this.loading_full_page = false;
              });
            break;
          case 2:
            this.loading_full_page = true;
            ClientsProvider.sendClientSetVip(this.viewRepair.client.id)
              .then((resp) => {
                if (this.viewRepair.client) {
                  this.viewRepair.client.status = resp.data.status;
                }
                this.loading_full_page = false;
              })
              .catch((err) => {
                console.error(err);
                this.loading_full_page = false;
              });
            break;
          case 3:
            this.modalBlackList = true;
            break;
          default: break;
        }
      }
    }

    /**
     * Помещает клиента в черный список
     */
    sendClientSetBlacklist() {
      if (this.viewRepair.client) {
        ClientsProvider.sendClientSetBlacklist(
          {
            client_id: this.viewRepair.client.id,
            reason: this.blackListReason,
          },
        )
          .then((resp) => {
            if (this.viewRepair.client) {
              this.viewRepair.client.status = resp.data.status;
            }
            this.modalBlackList = false;
            this.loading_full_page = false;
          })
          .catch((err) => {
            console.error(err);
            this.loading_full_page = false;
          });
      }
    }

    /**
     * Обработчик изменения статуса согласования заказа
     * @param success флаг успешности переименования заказа
     */
    repairAgreementChange(success: boolean) {
      if (success) {
        RepairProvider.sendRepairApproveAgreement(this.viewRepair.id)
          .then((res) => {
            this.viewRepair.status_id = res.data.status_id;
            this.viewRepair.custom_status_id = res.data.custom_status_id;
          })
          .catch((err) => {
            console.error('Не удалось согласовать заказ', err);
            this.showErrorPush({ title: 'Не удалось согласовать заказ' });
          });
      } else {
        RepairProvider.sendRepairDeclineAgreement(this.viewRepair.id)
          .then((res) => {
            this.viewRepair.status_id = res.data.status_id;
            this.viewRepair.custom_status_id = res.data.custom_status_id;
          })
          .catch((err) => {
            console.error('Не удалось отметить отказ от ремонта', err);
            this.showErrorPush({ title: 'Не удалось отметить отказ от ремонта' });
          });
      }
    }

    /**
     * Метод коррекции хлебных крошек при смене названия заказа.
     * @param title Название заказа
     */
    breadcrumb(title) {
      this.addBreadcrumb([
        { id: 1, section: 'Ремонт', link: '/repair' },
        { id: 3, section: title, link: null },
      ]);
    }

    /**
     * Обработчик клика по кнопке "Работать над заказом"
     */
    workOnRepair() {
      // Если заказ в статусе ПРИНЯТО, то вероятно нужно назначить мастера.
      if (this.viewRepair.status_id === 1) {
        // Если у заказа нет мастера - назначаем его.
        if (this.viewRepair.master_id === null) {
          const master = { repair_id: this.viewRepair.id, master_id: Number(this.user.id) };

          RepairProvider.sendRepairAssignmaster(master)
            .then(() => RepairProvider.sendRepairStart(this.viewRepair.id))
            .then(() => {
              this.$router.push({ name: 'repair-workrepair', params: { id: this.id } });
            })
            .catch((error) => {
              this.showErrorPush({ title: 'Не удалось установить мастера для заказа.' });
              console.error('Не удалось установить мастера для заказа.', error);
            });
        } else {
          // У заказа есть мастер, но он в статусе ПРИНЯТО. Вызываем перевод в статус "В работе"
          RepairProvider.sendRepairStart(this.viewRepair.id)
            .then(() => {
              this.$router.push({ name: 'repair-workrepair', params: { id: this.id } });
            })
            .catch((err) => {
              this.showErrorPush({ title: 'Не удалось перейти на страницу заказа.' });
              console.error('Не удалось установить мастера для заказа.', err);
            });
        }
        return;
      }
      this.$router.push({ name: 'repair-workrepair', params: { id: this.id } });
    }

    /**
     * Обработчик клика по "Запчасти поступили"
     */
    continueRepair() {
      RepairProvider.sendRepairContinue(this.viewRepair.id)
        .then((res) => {
          this.viewRepair.status_id = res.data.status_id;
          this.viewRepair.custom_status_id = res.data.custom_status_id;
        })
        .catch((err) => {
          console.error('Не удалось вывести заказ из статуса "Ожидает"', err);
          this.showErrorPush({ title: 'Не удалось вывести заказ из статуса "Ожидает"' });
        });
    }

    /**
     * Обработчик клика по "Вернуть на доработку"
     */
    returnRepairToWork() {
      RepairProvider.sendRepairRevision(this.viewRepair.id)
        .then((res) => {
          this.viewRepair.status_id = res.data.status_id;
          this.viewRepair.custom_status_id = res.data.custom_status_id;
        })
        .catch((err) => {
          console.error('Не удалось вернуть заказ на доработку', err);
          this.showErrorPush({ title: 'Не удалось вернуть заказ на доработку' });
        });
    }

    /**
     * Обработчик клика по "Удалить"
     */
    deleteRepair() {
      this.$confirm('Вы уверены что хотите удалить заказ?', 'Предупреждение', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning',
      })
        .then(() => RepairProvider.sendRepairDelete(this.viewRepair.id))
        .then(() => {
          this.$router.push({ name: 'repair' });
          this.showSuccessPush({ title: 'Заказ успешно удален' });
        })
        .catch((err) => {
          if (err !== 'cancel') {
            console.error('Не удалось удалить заказ', err);
            this.showErrorPush({ title: 'Не удалось удалить заказ' });
          }
        });
    }

    /**
     * Обработчик клика по "Принять устройство заново"
     */
    cloneRepair() {
      if (this.viewRepair.type === 1) {
        this.$router.push({ name: 'repair-form-clone', query: { id: this.viewRepair.id } });
      } else {
        this.$router.push({ name: 'refill-form-clone', query: { id: this.viewRepair.id } });
      }
    }

    /**
     * Обработчик по "Вернуть на доработку" для выданного заказа
     */
    restartRepair() {
      RepairProvider.sendRepairRestart(this.viewRepair.id)
        .then((res) => {
          this.viewRepair.status_id = res.data.status_id;
          this.viewRepair.custom_status_id = res.data.custom_status_id;
        })
        .catch((err) => {
          console.error('Не удалось вернуть заказ в работу', err);
          this.showErrorPush({ title: 'Не удалось вернуть заказ в работу' });
        });
    }

    /**
     * Геттер последнего перемещения заказа.
     * Нужен для корректной отрисовки кнопок управления заказом
     */
    get lastTransfer(): RepairTransfer | null{
      if (this.viewRepair.repair_transfers && this.viewRepair.repair_transfers.length > 0) {
        return this.viewRepair.repair_transfers[this.viewRepair.repair_transfers.length - 1];
      }
      return null;
    }

    /**
     * Обработчик клика начала перемещения
     */
    startTransfer() {
      this.changeStatusInTransfer({ id: (this.lastTransfer as RepairTransfer).id, status_id: 1 });
    }

    /**
     * Обработчик клика подтверждения перемещения
     */
    completeTransfer() {
      this.changeStatusInTransfer({ id: (this.lastTransfer as RepairTransfer).id, status_id: 2 });
    }

    /**
     * Обработчик клика отмены перемещения
     */
    cancelTransfer() {
      this.changeStatusInTransfer({ id: (this.lastTransfer as RepairTransfer).id, status_id: 3 });
    }

    /**
     * Обработчик изменений параметров перемещения.
     * Используется при кликах на любую кнопку управления перемещением (в продвинутом режиме)
     * Взывает нужное действие API и обновляет информацию о перемещении в объекте заказа.
     * @param status объект модификации статуса перемещения
     */
    changeStatusInTransfer(status) {
      let title: string;
      if (status.status_id === 1) {
        title = 'начать перемещение';
      } else if (status.status_id === 2) {
        title = 'подтвердить получение';
      } else {
        title = 'отменить перемещение';
      }
      this.$confirm(`Вы уверены что хотите ${title} заказа?`, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        type: 'warning',
      })
        .then(() => {
          if (status.status_id === 1) {
            return RepairProvider.sendRepairTransferStart({ transfer_id: status.id });
          } if (status.status_id === 2) {
            return RepairProvider.sendRepairTransferComplete({ transfer_id: status.id });
          }
          return RepairProvider.sendRepairTransferCancel({ transfer_id: status.id });
        })
        .then((resp) => {
          if (this.viewRepair.repair_transfers) {
            const transfer = resp.data;
            const loadedTransfers = this.viewRepair.repair_transfers.map((x) => x);
            const existingTransfer = loadedTransfers.filter((x) => x.id === transfer.id);
            if (existingTransfer.length > 0) {
              const existingTransferIndex = loadedTransfers.indexOf(existingTransfer[0]);
              loadedTransfers[existingTransferIndex] = transfer;
            } else {
              loadedTransfers.push(transfer);
            }
            this.viewRepair.repair_transfers = loadedTransfers;
            // при завершении перемещения переносим заказ в новый филиал
            if (transfer.status_id === 3) {
              this.viewRepair.current_point_id = transfer.target_point_id;
            }
          } else {
            throw new Error('this.viewRepair.repair_transfers is null');
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Отмена',
          });
        });
    }

    /**
     * Обработчик клика по "Выдать заказ"
     */
    ejectRepair() {
      this.loading_full_page = true;
      RepairProvider.sendRepairEject(this.viewRepair.id)
        .then((resp) => {
          this.viewRepair.status_id = resp.data.status_id;
          this.viewRepair.custom_status_id = resp.data.custom_status_id;
          this.viewRepair.out_date = resp.data.out_date;
          this.loading_full_page = false;
          this.openDialogCloseOrder = false;
          this.showSuccessPush({ title: 'Заказ успешно выдан!' });
        })
        .catch((err) => {
          console.error(err);
          this.loading_full_page = false;
          this.openDialogCloseOrder = false;
          this.showErrorPush({ title: 'Не удалось выдать заказ.' });
        });
    }

    /**
     * Обработчик отправки нового комментария к заказу
     * @param content Текст комментария
     */
    sendRepairComment(content: string) {
      this.loading_full_page = true;
      const form = {
        repair_id: this.viewRepair.id,
        content,
      };
      RepairProvider.sendRepairComment(form)
        .then((resp) => {
          (this.$refs.repairCommentsComponent as RepairViewComments).resetFormComment();
          this.loading_full_page = false;
          this.comments.push(resp.data);
        })
        .catch((err) => {
          this.loading_full_page = false;
          console.error('Не удалось сохранить комментарий.', err);
          this.showErrorPush({ title: 'Не удалось сохранить комментарий.' });
        });
    }

    /**
    * Удаление комментария
    * @param  {ApiRepairComment} comment
    */
    deleteComment(comment: ApiRepairComment) {
      this.loading_full_page = true;
      RepairProvider.deleteRepairComment(comment.id)
        .then((res) => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Комментарий удален' });
          this.loading_full_page = false;
          this.getRepairComments(Number(this.id));
        })
        .catch((err) => {
          this.loading_full_page = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось удалить комментарий' });
          throw new Error(err);
        });
    }

    toogleFlagIncludeDeletedComments() {
      this.includeDeletedComments = !this.includeDeletedComments;
      this.getRepairComments(Number(this.id));
    }

    /**
     * Метод открытия модалки отправки смс
     */
    openSmsModal() {
      this.showSendSmsModal = true;
    }

    /**
     * Метод отправки SMS
     */
    sendSms() {
      if (this.viewRepair.client?.id) {
        if (!this.viewRepair.client.sms_agreement) {
          this.$store.commit('PUSH_CALL_ERROR', { tittle: 'Клиент не давал согласия на получение SMS', item: '' });
          this.refreshForm += '1';
          return;
        }
        if (this.sendSmsText.trim().length === 0) {
          this.sendSmsError.text = 'Введите текст';
          this.refreshForm += '1';
          return;
        }
        const form: SendSmsForm = {
          client_id: this.viewRepair.client.id,
          text: this.sendSmsText,
          method: this.sendSmsMethod,
        };
        this.loading_full_page = true;
        SmsProvider.sendSms(form)
          .then((res) => {
            if (res.status === 204) {
              this.showSuccessPush({ title: 'SMS отправлена' });
              this.loading_full_page = false;
              this.showSendSmsModal = false;
              this.sendSmsText = '';
            }
          })
          .catch((err) => {
            this.loading_full_page = false;
            const errorData = err?.response?.data;
            console.log(err.response);
            if (err.response.data) {
              if (errorData.message) {
                this.$store.commit('PUSH_CALL_ERROR', { tittle: errorData.message, item: '' });
              } else if (err.response.status && err.response.status === 422) {
                if (err.response.data.client_id) {
                  this.$store.commit('PUSH_CALL_ERROR', { tittle: errorData.client_id[0], item: '' });
                }
                if (err.response.data.text) {
                  [this.sendSmsError.text] = errorData.text;
                }
                if (err.response.data.method) {
                  [this.sendSmsError.method] = err.response.data.method;
                }
              }
            }
          });
      }
    }

    // Watсher для загрузки всех сотрудников. Туповато, но сойдет.
    @Watch('stuff')
    getStaff() {
      if (this.pageStuff <= this.stuffPageCount) {
        if (Number(this.stuffPageCount) !== 1) {
          this.getStuffList(this.pageStuff);
        }
      }
      this.pageStuff += 1;
    }

    // Watсher на route вроде бы нужен, а вроде и нет
    @Watch('$route')
    viewRoute(route) {
      const hash = route.hash.replace('#', '');
      this.activeTab(hash);
    }

    // Watсher оставлен для обратной совместимости. В целом выглядит очень странно.
    @Watch('btnloader')
    loaderPage(value) {
      if (!value) this.loading_full_page = value;
    }

    @Watch('listPayments')
    addPaymentToPrint(payments: RepairPayment[]) {
      if (payments.length !== 0) {
        payments.forEach((payment) => {
          const findCashbox = this.cashboxes.find((cashbox) => cashbox.id === payment.cashbox_id);
          if (findCashbox?.cashless === false || this.cashboxes.length === 0) {
            const findedPrinter = this.printer.find(
              (printer) => printer.id === payment.cash_operation_id,
            );
            if (!findedPrinter) {
              this.printer.push({ title: `Кассовый ордер № ${payment.cash_operation_id}`, id: payment.cash_operation_id });
            }
          }
        });
      }
    }
}

/**
 * Перечисление различных вариантов активных вкладок.
 */
