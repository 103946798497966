import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import BaseProvider from '../../api/base-provider';
import ErrorAccessRights from '../../components/error-access-rights';

@Component({
  template: require('./index.html'),
  components: {
    'error-access-rights': ErrorAccessRights,
  },
})
export default class ReportViewPage extends Vue {
    @Action addBreadcrumb;

    @Getter user;

    report_id: number | null = null;

    token: string | null = null;

    loading = true;

    get rightsUserCanBuildReports() {
      return this.user.rights ? this.user.rights.can_build_reports : false;
    }

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Отчеты', link: '/report' },
        { id: 2, section: 'Просмотр отчета', link: '' },
      ]);
      document.title = 'Отчет';
      const baseProvider = new BaseProvider();
      baseProvider.checkExpiresIn()
        .then(() => {
          this.token = baseProvider.access;
          this.report_id = parseInt(this.$route.params.report_id, 10);
          this.loading = false;
        })
        .catch((err) => console.error(err));
    }

    @Watch('$route.params.report_id', { deep: true })
    updateReportId() {
      this.report_id = parseInt(this.$route.params.report_id, 10);
    }

    get reportUrlString() {
      const domain = process.env.NODE_ENV === 'production' ? 'report.servix.io' : 'report-dev.servix.io';
      return `https://${domain}/report/generate?token=${this.token}&report_id=${this.report_id}`;
    }
}
