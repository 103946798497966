// Mutations Type

export const GET_TRANSFER = 'GET_TRANSFER';
export const GET_VIEW_TRANSFER = 'GET_VIEW_TRANSFER';
export const GET_PAGE_TRANSFER = 'GET_PAGE_TRANSFER';
export const GET_VALID_TRANSFER = 'GET_VALID_TRANSFER';

export const SEND_TRANSFER_ITEM = 'SEND_TRANSFER_ITEM';
export const DELETE_TRANSFER_ITEM = 'DELETE_TRANSFER_ITEM';
export const CLEAR_TRANSFER_ITEM = 'CLEAR_TRANSFER_ITEM';
// Store
interface TransferNewItem {
  id;
}
export interface StateTransfer {
    transfer: object[];
    viewTransfer: {};
    transferNewItemList: TransferNewItem[];
    transferPageCurrent: number;
    transferPageCount: number;
    transferValid: object[];
}
