import { Table } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { RepairPart } from '@/types';
import PartProvider from '../../api/providers/part';
import WorkProvider from '../../api/providers/work';
import Numbers from '../forms/numbers';
import ModalAddProduct from '../modal-add-product';
import Category from '../__category';

interface TableWraperr {
  bodyWrapper;
}

interface Content {
    page;
    query;
    storage_id;
    category;
    only_positive;
    'per-page';
}
interface NewFormWorks {
  work_id: number;
  amount;
  remain;
  price;
}

interface PartAmount {
  amount;
  id;
  name;
  part_id;
  posting;
  price;
  remain;
  storage_id;
}

@Component({
  template: require('./modal.html'),
  components: {
    'category-tree': Category,
    'modal-add-product': ModalAddProduct,
    numbers: Numbers,
  },
})

export default class ModalAddPart extends Vue {
    @Action getWorkCategory;

    @Action getPartCategory;

    @Action clearListCategories;

    @Getter categoryList;

    @Getter user;

    @Getter balance;

    @Prop({ default: () => [] }) newFormParts!: RepairPart[];

    @Prop({ default: () => [] }) newFormWorks!: NewFormWorks[];

    @Prop({ default: () => [] }) storageList!: object[];

    @Prop({ default: () => '' }) type!: string;

    @Prop({ default: () => false }) cancelWorkModal!: boolean;

    @Prop() openDefault!: boolean;

    dialog_сatalog = false;

    dialog_part_add = false;

    loading_table = true;

    loading_categ = true;

    value_storage = 1;

    query = '';

    tab_position = 'parts';

    data: PartAmount[] | NewFormWorks[] = [];

    page_count = 1;

    oldStorage = 1;

    content: Content = {
      page: 1,
      query: '',
      storage_id: 1,
      category: 1,
      only_positive: 0,
      'per-page': 20,
    };

    flag_active_collapse = false;

    title_column_price = '';

    get titleProduct() {
      return this.tab_position === 'parts' ? 'товар' : 'услугу';
    }

    get switchTabs() { return this.tab_position; }

    get titleDialogPart() {
      return this.tab_position === 'parts' ? 'Добавление нового товара/запчасти' : 'Добавление новой услуги';
    }

    get rightsCanSeePostingPrice() {
      if (this.user.rights) return this.user.rights.can_see_posting_price;
      return false;
    }

    // api

    getPartAmount(item) {
      const { query } = item;
      PartProvider.getPartAmount(item.page, item, item['per-page'])
        .then((res) => {
          if (query === this.query) {
            this.data = this.transformDataPart(res.data);
            this.page_count = Number(res.pageCount);

            this.updateOfPart();
            this.$nextTick(() => {
              this.loading_table = false;
              this.updateLayoutTable();
            });
          }
        }).catch((err) => console.error(err));
    }

    getWorkList(item) {
      WorkProvider.getWorkList(item.page, item, item['per-page'])
        .then((res) => {
          this.data = this.transformDataWork(res.data);
          this.page_count = Number(res.pageCount);
          this.updateOfWork();

          this.$nextTick(() => {
            this.loading_table = false;
            this.updateLayoutTable();
          });
        }).catch((err) => console.error(err));
    }

    updateOfPart() {
      try {
        this.data.forEach((part, index) => {
          const id = this.newFormParts.findIndex((propPart) => part.part_id === propPart.part_id);
          if (id > -1) {
            if (this.newFormParts[id].remain) {
              this.data[index].amount = this.newFormParts[id].remain;
            } else {
              this.data[index].amount = this.newFormParts[id].amount;
            }
            this.data[index].price = this.newFormParts[id].price;
          }
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    updateOfWork() {
      try {
        this.data.forEach((work, index) => {
          const id = this.newFormWorks.findIndex((propWork) => work.work_id === propWork.work_id);
          if (id > -1) {
            if (this.newFormWorks[id].remain) {
              this.data[index].amount = this.newFormWorks[id].remain;
            } else {
              this.data[index].amount = this.newFormWorks[id].amount;
            }
            this.data[index].price = this.newFormWorks[id].price;
          }
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    mounted() {
      this.titleColumnPrice();
      this.getPartCategory('part');
      this.changeCategory();
      this.getPartAmount(this.content);
      this.dialog_сatalog = this.openDefault;

      if (this.storageList.length !== 0) {
        this.dataStorageList(this.storageList);
      }
    }

    destroyed() {
      this.clearListCategories();
    }

    titleColumnPrice() {
      const { name } = this.$route;
      this.title_column_price = name === 'repair-workrepair' ? 'Цена в ремонт' : 'Цена в продажу';
    }

    closeAddProduct() {
      (this.$refs.ModalAddProduct as ModalAddProduct).close();
    }

    openCategories(value) {
      this.flag_active_collapse = value.length !== 0;
    }

    addInNewList(val) {
      const item = val;
      if (item.price < 0) {
        item.price = Math.abs(item.price);
      }
      if (item.amount < 0) {
        item.amount = 1;
      }
      if (this.tab_position === 'parts') {
        this.$root.$emit('addInNewPartList', val);
      } else {
        this.$root.$emit('addInNewWorkList', val);
      }
    }

    delInNewList(val) {
      if (this.tab_position === 'parts') {
        this.$root.$emit('delInNewPartList', val);
      } else {
        this.$root.$emit('delInNewWorkList', val);
      }
    }

    dialogFlags(bool) {
      this.dialog_part_add = bool;
    }

    search() {
      this.loading_table = true;
      if (this.tab_position === 'parts') {
        this.getPartAmount(this.content);
      } else {
        const workContent = {
          page: this.content.page, 'per-page': this.content['per-page'], name: this.content.query, category_id: this.content.category,
        };
        this.getWorkList(workContent);
      }
    }

    changeCategory() {
      this.$root.$on('categoryItemActive', (i) => {
        const val = this.content.category === i ? 1 : i;
        this.content.category = val;
        this.search();
      });
    }

    changeStorage(val) {
      this.content.storage_id = val;
      this.oldStorage = val;
      this.search();
    }

    changeOnlyPositive(val) {
      this.content.only_positive = val;
      this.search();
    }

    changePage(val) {
      this.content.page = val;
      this.search();
      ((this.$refs.table as Table) as unknown as TableWraperr).bodyWrapper.scrollTop = 0;
    }

    changePerPage(val) {
      this.content['per-page'] = val;
      this.search();
    }

    typePerPage(count) {
      return count === this.content['per-page'] ? 'primary' : '';
    }

    checkItemList(item) {
      if (this.tab_position === 'parts') {
        return this.newFormParts.findIndex((i) => {
          const bool = i.part_id === item.part_id && i.storage_id === item.storage_id;
          return bool;
        });
      }
      return this.newFormWorks.findIndex((i) => i.work_id === item.work_id);
    }

    indexItem(val) {
      const key: string = val.part_id ? 'part_id' : 'work_id';
      const index: number = this.data.findIndex((i) => i[key] === val[key]) + 1;
      const page: number = this.content.page - 1;
      const position: number = page * this.content['per-page'];
      return position + index;
    }

    transformDataPart(data) {
      return data.map((i) => ({
        part_id: i.part.id,
        storage_id: this.content.storage_id,
        name: i.part.name,
        amount: 1,
        remain: i.amount,
        art: i.part.art,
        price: this.type === 'shop' ? i.part.sell_price : i.part.repair_price,
        posting_price: i.part.posting_price,
        id: i.part.id,
      }));
    }

    transformDataWork(data) {
      return data.map((i) => ({
        work_id: i.id,
        name: i.name,
        amount: 1,
        remain: 1,
        price: this.type === 'shop' ? i.sell_price : i.repair_price,
        id: i.id,
      }));
    }

    updateLayoutTable() {
      if (this.$refs.table) (this.$refs.table as Table).doLayout();
    }

    getNewPart(part) {
      const transformParts: object = {
        part,
        storage_id: this.value_storage,
        part_id: part.id,
        price: part.repair_price,
        amount: 1,
      };
      this.$root.$emit('addInNewPartList', transformParts);
      this.dialog_part_add = false;
    }

    getNewWork(work) {
      const transformWorks: object = {
        work,
        work_id: work.id,
        price: work.repair_price === null ? 0 : work.repair_price,
        amount: 1,
        name: work.name,
      };
      this.$root.$emit('addInNewWorkList', transformWorks);
      this.dialog_part_add = false;
    }

    @Watch('value_storage')
    onChangeStorage(val: number) {
      this.changeStorage(val);
    }

    @Watch('query')
    onChangeSearch(val: string) {
      this.content.query = val;
      this.search();
    }

    @Watch('categoryList')
    onLoadCat() {
      this.loading_categ = false;
    }

    @Watch('tab_position')
    onChangeCat(val: string) {
      this.query = '';
      this.loading_categ = true;
      this.loading_table = true;
      this.content.page = 1;
      this.content.query = '';
      this.content.category = 1;
      if (val === 'parts') {
        this.getPartCategory('part');
        this.getPartAmount(this.content);
      } else {
        this.getWorkCategory('work');
        const workContent = {
          page: this.content.page, 'per-page': this.content['per-page'], name: this.content.query, category_id: this.content.category,
        };
        this.getWorkList(workContent);
      }
      this.clearListCategories();
    }

    @Watch('openDefault')
    onChangeActive(val: boolean) {
      this.dialog_сatalog = val;
    }

    @Watch('dialog_сatalog')
    updatingAmountAfterOpenningDialog() {
      if (this.dialog_сatalog) {
        if (this.tab_position === 'parts') {
          this.updateOfPart();
        } else if (this.tab_position === 'works') {
          this.updateOfWork();
        }
      }
    }

    @Watch('storageList')
    dataStorageList(list) {
      const default_storage = list.find((storage) => {
        if (storage.point_id) {
          return storage.point_id === this.user.point_id;
        }
        return undefined;
      });

      if (default_storage) this.value_storage = default_storage.id;
    }
}
