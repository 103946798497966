import { ActionTree } from 'vuex';
import ReportProvider from '../../api/providers/report';
import { GET_REPORTS, StateReport } from './types';

const actions: ActionTree<StateReport, object> = {

  getReports({ commit }) {
    ReportProvider.getAllAvaliableReportList()
      .then((res) => {
        commit(GET_REPORTS, { item: res });
      })
      .catch((err) => console.error(err));
  },
};

export default actions;
