import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb, ActionBtn, User } from '@/types';
import ErrorAccessRights from '../../components/error-access-rights';
import NumberPhone from '../../components/forms/number-phone';
import ViewUserName from '../../components/forms/user-name';

@Component({
  template: require('./index.html'),
  components: {
    'user-name': ViewUserName,
    phone: NumberPhone,
    'error-access-rights': ErrorAccessRights,
  },
})
export default class StuffPage extends Vue {
    @Action getStuffList;

    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action deleteStuff;

    @Getter stuff;

    @Getter stuffPageCount;

    @Getter user;

    loading = true;

    dialogView = false;

    dialogLock = false;

    actionBtn: ActionBtn[] = [{
      id: 1, type: 'add', title: 'Новый сотрудник', link: '/stuff/add', icon: 'fa-plus',
    }];

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Компания', link: '/settings' }, { id: 2, section: 'Сотрудники', link: null }];

    stuffView: object = {};

    get rightsUserCanManageStuff() {
      return this.user.rights ? this.user.rights.can_manage_stuff : false;
    }

    mounted() {
      this.getStuffList(1);
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Сотрудники';
      if (this.user.rights && this.user.rights.can_manage_stuff) this.addActionBtn(this.actionBtn);
    }

    destroyed() {
      this.addBreadcrumb([]);
      this.addActionBtn([]);
    }

    loadPage(val) {
      this.loading = false;
      this.getStuffList(val);
    }

    getPoint = (item) => (item.point === undefined ? '' : item.point.name)

    onDialogLock(item) {
      this.stuffView = item;
      this.dialogLock = true;
    }

    @Watch('stuff')
    onLoad() {
      this.$nextTick(() => {
        this.loading = false;
      });
    }

    @Watch('user')
    meUser(user: User) {
      if (user.rights) {
        if (user.rights.can_manage_stuff) {
          this.addActionBtn(this.actionBtn);
        } else {
          this.addActionBtn([]);
        }
      } else {
        this.addActionBtn([]);
      }
    }
}
