import { ActionTree } from 'vuex';
import SellProvider from '../../api/providers/sell';
import { StateShop } from './types';

const actions: ActionTree<StateShop, object> = {

  getSellList({ commit }, item) {
    commit('BTN_LOADER', true);
    SellProvider.getSellList(item.page, item, item['per-page'])
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('GET_SHOP', { item: res.data, pageCount: res.pageCount });
      }).catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
      });
  },

  getViewSell({ commit }, id) {
    SellProvider.getViewSell(id)
      .then((res) => {
        commit('VIEW_SELL', { item: res.data });
        commit('GET_SHOP', { item: res.data });
      })
      .catch((err) => console.error(err));
  },

  sendSell({ commit }, item) {
    SellProvider.sendSell(item)
      .then((res) => {
        commit('VIEW_SELL', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Создана продажа №${res.data.id}`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendDiscount({ commit }, item) {
    commit('BTN_LOADER', true);
    SellProvider.sendDiscount(item)
      .then((res) => {
        commit('ADD_SELL_DISCOUNT', { item: res.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: 'Скидка успешна добавлена', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendSellPart({ commit }, item) {
    SellProvider.sendSellPart(item)
      .then((res) => {
        commit('ADD_SELL_PART', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Товар «${res.data.part.name}» добавлен в продажу`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendSellWork({ commit }, item) {
    SellProvider.sendSellWork(item)
      .then((res) => {
        commit('ADD_SELL_WORK', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Услуга «${res.data.work.name}» добавлена в продажу`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendSellApply({ commit }, id) {
    commit('BTN_LOADER', true);
    SellProvider.sendSellApply(id)
      .then((res) => {
        commit('VIEW_SELL', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Продажа №${id} успешно проведена`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Продажа не найдена или проведена', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  sendSellPayment({ commit }, id) {
    commit('BTN_LOADER', true);
    SellProvider.sendSellPayment(id)
      .then((res) => {
        commit('ADD_SELL_PAYMENT', { item: res.data });
        if (res.data.sum >= 0) {
          commit('PUSH_CALL_SUCCESS', { title: 'Оплата успешно добавлена', item: '' });
        } else {
          commit('PUSH_CALL_SUCCESS', { title: 'Деньги успешно возвращены', item: '' });
        }
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка оплаты', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  changSellPart({ commit }, item) {
    commit('BTN_LOADER', true);
    SellProvider.changSellPart(item.id, item)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          commit('BTN_LOADER', false);
          commit('UPDATE_SELL_PART', { item: res.data });
          commit('PUSH_CALL_SUCCESS', { title: 'Товар обновлен', item: '' });
        }
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
      });
  },

  changSellWork({ commit }, item) {
    commit('BTN_LOADER', true);
    SellProvider.changSellWork(item.id, item)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          commit('BTN_LOADER', false);
          commit('UPDATE_SELL_WORK', { item: res.data });
          commit('PUSH_CALL_SUCCESS', { title: 'Услуга обновлена', item: '' });
        }
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
      });
  },

  changeSell({ commit }, item) {
    SellProvider.changeSell(item.id, item.data)
      .then((res) => {
        // commit('UPDATE_SELL', { item: res.data });
        commit('VIEW_SELL', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Продажа обновлена', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка обновления', item: '' });
      });
  },

  sendSellRevert({ commit }, item) {
    commit('BTN_LOADER', true);
    SellProvider.sendSellRevert(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('VIEW_SELL', { item: res.data });
        commit('SELL_REVERT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Совершен возврат продажи №${res.data.id}`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Вернуть продажу не возможно', item: '' });
      });
  },

  sendSellPartRevert({ commit }, item) {
    commit('BTN_LOADER', true);
    SellProvider.sendSellPartRevert(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('SELL_PART_REVERT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Товар «${res.data.part.name}» возвращен`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Вернуть товар не возможно', item: '' });
      });
  },

  sendSellWorkRevert({ commit }, item) {
    commit('BTN_LOADER', true);
    SellProvider.sendSellWorkRevert(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('SELL_PART_REVERT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Услуга «${res.data.work.name}» возвращена`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Вернуть товар не возможно', item: '' });
      });
  },

  sendSellPartUnrevert({ commit }, item) {
    SellProvider.sendSellPartUnrevert(item.idPart)
      .then(() => {
        this.dispatch('getViewSell', item.id);
        commit('PUSH_CALL_SUCCESS', { title: 'Возврат товара успешно отменён', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Отменить возврат товара не удалось', item: '' });
      });
  },

  sendSellWorkUnrevert({ commit }, item) {
    SellProvider.sendSellWorkUnrevert(item.idWork)
      .then(() => {
        this.dispatch('getViewSell', item.id);
        commit('PUSH_CALL_SUCCESS', { title: 'Возврат услуги успешно отменён', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Отменить возврат услуги не удалось', item: '' });
      });
  },

  // Костыли

  clearRevertList({ commit }) {
    commit('CLEAR_REVERT_LIST');
  },

  clearProductList({ commit }) {
    commit('CLEAR_PRODUCTS_LIST');
  },

  clearStateDeleteProducts({ commit }) {
    commit('CLEAR_STATE_DELETE_PRODUCTS');
  },

  clearStateDiscountList({ commit }) {
    commit('CLEAR_STATE_DISCOUNT_LIST');
  },

  clearStatePaymentList({ commit }) {
    commit('CLEAR_STATE_PAYMENT_LIST');
  },

  sendSellCancel({ commit }, item) {
    SellProvider.sendSellCancel(item)
      .then((res) => {
        commit('SELL_CANCEL', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Продажа №${res.data.id} готова для редактирования`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Отмненить продажу не возможно', item: '' });
      });
  },

  deleteSell({ commit }, id) {
    SellProvider.deleteSell(id)
      .then((res) => {
        commit('DEL_SELL', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Продажа №${id} удалена`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Продажа не найдена или проведена', item: '' });
      });
  },

  deleteSellPart({ commit }, item) {
    SellProvider.deleteSellPart(item.id)
      .then((res) => {
        commit('DEL_SELL_PART', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Товар «${item.part.name}» удален из продажи`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  deleteSellPayments({ commit }, id) {
    SellProvider.deleteSellPayments(id)
      .then((res) => {
        commit('DEL_SELL_PAYMENT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Оплата успешно удалена', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Удалить оплату не удалось', item: '' });
      });
  },

  deleteSellDiscount({ commit }, id) {
    SellProvider.deleteSellDiscount(id)
      .then((res) => {
        commit('DEL_SELL_DISCOUNT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Скидка успешно удалена', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Удалить оплату не удалось', item: '' });
      });
  },

  deleteSellWork({ commit }, item) {
    SellProvider.deleteSellWork(item.id)
      .then((res) => {
        commit('DEL_SELL_WORK', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Услуга «${item.work.name}» удалена из продажи`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },
};

export default actions;
