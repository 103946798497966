import { ActionTree } from 'vuex';
import { DISMISS_INCOMING_FROM_NUMBER, SET_INCOMING, StateVOIP } from './types';

const actions: ActionTree<StateVOIP, object> = {

  setIncoming({ commit }, call) {
    commit(SET_INCOMING, call);
  },

  removeIncomingByPhone({ commit }, phone) {
    commit(DISMISS_INCOMING_FROM_NUMBER, phone);
  },
};

export default actions;
