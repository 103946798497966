export const GET_STICKER_SETTINGS = 'GET_STICKER_SETTINGS';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';

// Store

export interface StateService {
  settingsSticker: object;
}

// Models
