export const GET_EQUIPMENT_LIST = 'GET_EQUIPMENT_LIST';
export const SEND_EQUIPMENTOPTION_LIST = 'SEND_EQUIPMENTOPTION_LIST';
export const UPDATE_EQUIPMENTOPTION_LIST = 'UPDATE_EQUIPMENTOPTION_LIST';
export const DELETE_EQUIPMENTOPTION_LIST = 'DELETE_EQUIPMENTOPTION_LIST';
export const SEND_EQUIPMENTOPTION_LIST_PRIORITY = 'SEND_EQUIPMENTOPTION_LIST_PRIORITY';

// Store

export interface StateEquipmentOption {
    equipmentoptionList: object[];
    addEquipmentOptionList: object;
    updateEquipmentOptionList: object;
    deleteEquipmentOptionList: object;
    sendEquipmentOptionPriority: object;
}
