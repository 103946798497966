import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Numbers from '../forms/numbers';

@Component({
  template: require('./price.html'),
  components: {
    numbers: Numbers,
  },
})
export default class RepairPrice extends Vue {
    @Prop({ default: 0 }) toPaySum!: number;

    @Prop({ default: 0 }) totalWithDiscounts!: number;

    @Prop({ default: 0 }) totalWithVat!: number;

    @Prop({ default: 0 }) totalProducts!: number;

    @Prop({ default: 0 }) vat!: number;
}
