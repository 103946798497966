import { ActionTree } from 'vuex';
import DeviceProvider from '../../api/providers/device';
import { StateDevice } from './types';

const actions: ActionTree<StateDevice, object> = {

  getValidationDeviceImei({ commit }, imei) {
    DeviceProvider.getValidationDeviceImei(imei)
      .then((res) => {
        commit('VALIDATION_IMEI', { item: res.data });
      })
      .catch((err) => console.error(err));
  },

  getDeviceTypeList({ commit }) {
    DeviceProvider.getDeviceTypeList()
      .then((res) => {
        commit('GET_DEVICE_TYPE', { item: res.data });
      })
      .catch((err) => console.error(err));
  },

  getDeviceVendorList({ commit }, id) {
    DeviceProvider.getDeviceVendorList(id)
      .then((res) => {
        commit('GET_DEVICE_VENDOR', { item: res.data });
        return res.data;
      })
      .catch((err) => console.error(err));
  },

  getDeviceModelList({ commit }, id) {
    DeviceProvider.getDeviceList(id)
      .then((res) => {
        commit('GET_DEVICE_MODEL', { item: res.data });
      })
      .catch((err) => console.error(err));
  },

  sendDeviceType({ commit }, item) {
    DeviceProvider.sendDeviceType(item)
      .then((res) => {
        commit('ADD_DEVICE_TYPE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `«${res.data.name}» успешно добавлен`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendDeviceVendor({ commit }, item) {
    DeviceProvider.sendDeviceVendor(item)
      .then((res) => {
        commit('ADD_DEVICE_VENDOR', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `«${res.data.name}» успешно добавлен`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendDeviceModel({ commit }, item) {
    commit('BTN_LOADER', true);
    DeviceProvider.sendDeviceModel(item)
      .then((res) => {
        commit('ADD_DEVICE_MODEL', { item: res.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: `«${res.data.name}» успешно добавлена`, item: '' });
        commit('DEVICE_VALIDATION', {});
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('DEVICE_VALIDATION', err.response.data);
      });
  },

  changeDeviceType({ commit }, item) {
    commit('BTN_LOADER', true);
    DeviceProvider.changeDeviceType(item)
      .then((res) => {
        commit('EDIT_DEVICE_TYPE', { item: res.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: `«${item.name}» успешно изменён`, item: '' });
        commit('DEVICE_VALIDATION', {});
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: err.response.data.name[0] });
        commit('DEVICE_VALIDATION', err.response.data);
      });
  },

  changeDeviceVendor({ commit }, item) {
    commit('BTN_LOADER', true);
    DeviceProvider.changeDeviceVendor(item)
      .then((res) => {
        commit('EDIT_DEVICE_VENDOR', { item: res.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: `«${item.name}» успешно изменён`, item: '' });
        commit('DEVICE_VALIDATION', {});
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: err.response.data.name[0] });
        commit('DEVICE_VALIDATION', err.response.data);
      });
  },

  changeDeviceModel({ commit }, item) {
    commit('BTN_LOADER', true);
    DeviceProvider.changeDeviceModel(item)
      .then((res) => {
        commit('EDIT_DEVICE_MODEL', { item: res.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: `«${item.name}» успешно изменена`, item: '' });
        commit('DEVICE_VALIDATION', {});
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: err.response.data.name[0] });
        commit('DEVICE_VALIDATION', err.response.data);
      });
  },

  deleteDeviceType({ commit }, item) {
    commit('BTN_LOADER', true);
    DeviceProvider.deleteDeviceType(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('DELETE_DEVICE_TYPE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `«${res.data.name}» успешно удалён`, item: '' });
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: err.response.data.recursive[0], item: '' });
      });
  },

  deleteDeviceVendor({ commit }, item) {
    commit('BTN_LOADER', true);
    DeviceProvider.deleteDeviceVendor(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('DELETE_DEVICE_VENDOR', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `«${res.data.name}» успешно удалён`, item: '' });
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: err.response.data.recursive[0], item: '' });
      });
  },

  deleteDeviceModel({ commit }, id) {
    commit('BTN_LOADER', true);
    DeviceProvider.deleteDeviceModel(id)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('DELETE_DEVICE_MODEL', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `«${res.data.name}» успешно удалена`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  searchDeviceList({ commit }, item) {
    commit('SEARCH_DEVICE', { type: item.type, vendor: item.vendor, model: item.model });
  },

  clearStateDevice({ commit }, from = '') {
    commit('CLEAR_STATE_DEVICE', from);
  },
};

export default actions;
