import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Image } from '@/types';
import PartProvider from '../../api/providers/part';
import Numbers from '../forms/numbers';
import LazyLoadImage from '../lazy-load-image';
import PartItemStorageCell from '../part-item-storage-cell';

interface Item {
  images: Image;
  id: number;
}
@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    'lazy-load-image': LazyLoadImage,
    'part-item-storage-cell': PartItemStorageCell,
  },
})
export default class PartItem extends Vue {
    @Prop() item!: Item;

    @Prop() index!: number;

    @Prop() headsPart!: object;

    @Getter user;

    dialog = false;

    view_image_on_hover = false;

    loading_copy_form = false;

    // api

    sendCopyPart(item) {
      this.loading_copy_form = true;
      PartProvider.sendCopyPart(item)
        .then((res) => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Копия создана успешно', item: '' });
          this.$emit('copyPart', res.data, this.index);

          this.dialog = false;
          this.loading_copy_form = false;
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading_copy_form = false;
        });
    }

    deletePart(id) {
      PartProvider.deletePart(id)
        .then((res) => {
          this.$emit('deletePart', res.data);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Товар ${res.data.name} успешно удалён`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    revertPart(id) {
      PartProvider.revertPart(id)
        .then((res) => {
          this.$emit('revertPart', res.data);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Товар ${res.data.name} успешно возвращен`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    get imagesItem() {
      return this.item.images ? this.item.images.small : null;
    }

    get rightsUser() {
      return this.user.rights ? this.user.rights.can_edit_delete_parts_and_categories : false;
    }

    get rightsUserCanSeePostingPrice() {
      return this.user.rights ? this.user.rights.can_see_posting_price : false;
    }

    methodDisabledButtons() {
      this.$notify.error({
        title: 'Вам не разрешено изменять и удалять товары, услуги и категории',
        message: '',
        position: 'bottom-right',
      });
    }

    getArtAndBarcodes = (item) => {
      const result: string[] = [];

      if (item.art !== null && item.art.length > 0) {
        result.push(item.art);
      }
      item.barcodes.forEach((element) => {
        result.push(element.value);
      });
      return result;
    }

    activeBtnDelete = (prop) => {
      if (prop?.enabled === false) {
        return false;
      }
      if (prop) {
        return prop.amounts.every((i) => i.amount === 0);
      }
      return false;
    }

    activeBtnRevert = (prop) => !prop?.enabled;

    copy() {
      const item = { part_id: this.item.id };
      this.sendCopyPart(item);
    }

    sticker() {
      this.$emit('onStickerClick', this.item.id);
    }
}
