import BaseProvider from '../base-provider';

class SalaryTransfer extends BaseProvider {
  constructor() {
    super();
    this.resource = '/salarytransfer';
  }

  async salaryTransferIndex(request) {
    const req = {
      method: 'GET',
      nestedResources: '/index',
      formName: 'SalaryTransferSearchForm',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async salaryTransferView(request) {
    const req = {
      method: 'GET',
      nestedResources: '/view',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
    };
  }

  async salaryTransferAdd(request) {
    const req = {
      method: 'POST',
      nestedResources: '/add',
      formName: 'SalaryTransferCreateForm',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
    };
  }

  async salaryTransferDelete(request) {
    const req = {
      method: 'POST',
      nestedResources: '/delete',
      ...request,
    };
    const res = await this.superRequest(req);
    return {
      data: res.data,
    };
  }
}

export default new SalaryTransfer();
