import {
  HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel,
} from '@aspnet/signalr';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import BaseProvider from '../../api/base-provider';

const INCOME_CALL = 'Voicecall.Incoming';
const AUTHORIZE_SERVER_METHOD_NAME = 'Authorize';
const HUB_URL = 'https://microservices.servix.io:23168/vats-hub';

@Component({
  template: require('./index.html'),
})
export default class SignalrManager extends Vue {
    @Action setIncoming;

    @Getter auth;

    private hub: HubConnection | null= null;

    mounted() {
      this.hub = new HubConnectionBuilder()
        .withUrl(HUB_URL)
        .configureLogging(LogLevel.Information)
        .build();
      this.hub.on(INCOME_CALL, this.incomeCallHandler);
      this.start().catch(() => this.start());
    }

    private async start() {
      try {
        const provider = new BaseProvider();
        await provider.checkExpiresIn();
        const token = provider.access;
        if (!token) {
          return;
        }
        await (this.hub as HubConnection).start();
        await provider.checkExpiresIn();
        const authResult = await (this.hub as HubConnection).invoke(AUTHORIZE_SERVER_METHOD_NAME,
          token);
        if (!authResult.is_success) {
          console.error('SignalR auth failed, token:', token);
        }
      } catch (e) {
        console.error('SignalR connection error:', e);
      }
    }

    @Watch('auth')
    onAuthChanged() {
      if (this.auth !== true) {
        if ((this.hub as HubConnection).state === HubConnectionState.Connected) {
          (this.hub as HubConnection).stop();
        }
      } else {
        this.start();
      }
    }

    private async incomeCallHandler(caller_id, internal = null) {
      if (caller_id !== null) {
        const call = { internalNumber: internal, callerNumber: caller_id };
        this.setIncoming(call);
      }
    }
}
