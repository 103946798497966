import { MutationTree } from 'vuex';
import {
  ADD_CATEGORY,

  CLEAR_CATEGORIES_LIST, CLEAR_VALIDATE, DELETE_CATEGORY, EDIT_CATEGORY,

  GATEGORIES_VALIDATION, GET_CATEGORY,

  StateCategory,
} from './types';

interface Cat {
  index?;
  title?;
  folder?;
  expanded?;
  children?;
}
let cat: Cat = {};
function searchCategory(value, key) {
  value.forEach((item, i) => {
    if (item.key === key) {
      cat = item;
      cat.index = i;
    } else if (item.children) {
      searchCategory(item.children, key);
    }
  });
}

const mutations: MutationTree<StateCategory> = {

  // [GET_CATEGORY](state, { item, main }) {
  //   main ? state.categoriesMainList = item : state.categories = item;
  // },

  [GET_CATEGORY](state, { item }) {
    state.categories = item[0].children;
    state.categoriesMainList = item;
  },

  [EDIT_CATEGORY](state, { item }) {
    state.newCategory = { type: 'edit', item };
    cat = {};
    searchCategory(state.categories, item.id);
    cat.title = item.name;
  },

  [ADD_CATEGORY](state, { item }) {
    state.newCategory = { type: 'add', item };
    const newItem: object = {
      key: item.id,
      title: item.name,
      expanded: false,
      folder: false,
      parent_id: item.parent_id,
      children: [],
    };
    if (item.parent_id === 1) {
      state.categories.unshift(newItem);
    } else {
      cat = {};
      searchCategory(state.categories, item.parent_id);
      cat.folder = true;
      cat.expanded = true;
      cat.children.push(newItem);
    }
  },

  [DELETE_CATEGORY](state, { item }) {
    state.newCategory = { type: 'remove', item };
    function getIndex(array) {
      return array.findIndex((i) => i.key === item.id);
    }

    if (item.parent_id === 1) {
      state.categories.splice(getIndex(state.categories), 1);
    } else {
      cat = {};
      searchCategory(state.categories, item.parent_id);
      cat.children.splice(getIndex(cat.children), 1);
    }
  },

  [GATEGORIES_VALIDATION](state, item) {
    state.categoriesValidate = item;
  },

  [CLEAR_VALIDATE](state, item) {
    state.categoriesValidate = item;
  },

  [CLEAR_CATEGORIES_LIST](state) {
    state.categories = [];
  },

};

export default mutations;
