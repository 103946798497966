import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

const push = {
  state,
  actions,
  mutations,
  getters,
};

export default push;
