import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { StateOption } from '@/types';

@Component({
  template: require('./index.html'),
})
export default class CustomFieldUpdate extends Vue {
    @Action addBreadcrumb;

    @Action getBugOptionList;

    @Action getEquipmentOptionList;

    @Action getNoteOptionList;

    @Action getStateOptionList;

    @Action deleteEquipmentOptionList;

    @Action deleteNoteOptionList;

    @Action deleteBugOptionList;

    @Action deleteStateOptionList;

    @Action changeBugOptionList;

    @Action changeEquipmentOptionList;

    @Action changeNoteOptionList;

    @Action changeStateOptionList;

    @Action sendEquipmentOptionList;

    @Action sendNoteOptionList;

    @Action sendBugOptionList;

    @Action sendStateOptionList;

    @Action sendBugOptionSetPrioroty;

    @Action sendNoteOptionSetPrioroty;

    @Action sendEquipmentOptionSetPrioroty;

    @Action sendStateOptionSetPrioroty;

    @Getter stateoptionList;

    @Getter noteoptionList;

    @Getter equipmentoptionList;

    @Getter bugoptionList;

    @Getter updateBugOptionList;

    @Getter updateNoteOptionList;

    @Getter updateEquipmentOptionList;

    @Getter updateStateOptionList;

    @Getter addBugOptionList;

    @Getter addNoteOptionList;

    @Getter addStateOptionList;

    @Getter addEquipmentOptionList;

    fieldData: StateOption[] = [];

    fieldAddItem = '';

    openModalAddItem = false;

    fieldUpdateItem: object = {};

    loadingEdit = false;

    get checkFieldRoute() {
      const params = this.$route.params.field;

      switch (params) {
        case 'bug':
          this.getBugOptionList(1);
          return 'Неисправности';
        case 'state':
          this.getStateOptionList(1);
          return 'Состояние';
        case 'note':
          this.getNoteOptionList(1);
          return 'Заметки';
        case 'equipment':
          this.getEquipmentOptionList(1);
          return 'Комплектация';
        default: return null;
      }
    }

    removeItemField(item, index) {
      const params = this.$route.params.field;

      switch (params) {
        case 'bug':
          this.fieldData.splice(index, 1);
          this.deleteBugOptionList(item);
          break;
        case 'state':
          this.fieldData.splice(index, 1);
          this.deleteStateOptionList(item);
          break;
        case 'note':
          this.fieldData.splice(index, 1);
          this.deleteNoteOptionList(item);
          break;
        case 'equipment':
          this.fieldData.splice(index, 1);
          this.deleteEquipmentOptionList(item);
          break;
        default: break;
      }
    }

    arrowUp(index) {
      const params = this.$route.params.field;
      const newItem = this.fieldData[index];
      newItem.priority += 1;
      this.fieldData.splice(index, 1);

      if (index === 0) {
        this.fieldData.push(newItem);
      } else {
        this.fieldData.splice(index - 1, 0, newItem);
      }

      switch (params) {
        case 'bug': this.sendBugOptionSetPrioroty({ item_id: newItem.id, position: newItem.priority }); break;
        case 'state': this.sendStateOptionSetPrioroty({ item_id: newItem.id, position: newItem.priority }); break;
        case 'note': this.sendNoteOptionSetPrioroty({ item_id: newItem.id, position: newItem.priority }); break;
        case 'equipment': this.sendEquipmentOptionSetPrioroty({ item_id: newItem.id, position: newItem.priority }); break;
        default: break;
      }
    }

    arrowDown(index) {
      const params = this.$route.params.field;
      const newItem = this.fieldData[index];
      newItem.priority -= 1;
      this.fieldData.splice(index, 1);

      if (index === this.fieldData.length) {
        this.fieldData.unshift(newItem);
      } else {
        this.fieldData.splice(index + 1, 0, newItem);
      }

      switch (params) {
        case 'bug': this.sendBugOptionSetPrioroty({ item_id: newItem.id, position: newItem.priority }); break;
        case 'state': this.sendStateOptionSetPrioroty({ item_id: newItem.id, position: newItem.priority }); break;
        case 'note': this.sendNoteOptionSetPrioroty({ item_id: newItem.id, position: newItem.priority }); break;
        case 'equipment': this.sendEquipmentOptionSetPrioroty({ item_id: newItem.id, position: newItem.priority }); break;
        default: break;
      }
    }

    updateItemField(item) {
      this.openModalAddItem = true;
      this.fieldUpdateItem = item;
    }

    saveItem() {
      const params = this.$route.params.field;
      this.loadingEdit = true;

      switch (params) {
        case 'bug':
          this.changeBugOptionList(this.fieldUpdateItem);
          break;
        case 'state':
          this.changeStateOptionList(this.fieldUpdateItem);
          break;
        case 'note':
          this.changeNoteOptionList(this.fieldUpdateItem);
          break;
        case 'equipment':
          this.changeEquipmentOptionList(this.fieldUpdateItem);
          break;
        default: break;
      }
    }

    addItemField() {
      const params = this.$route.params.field;
      const item = {
        text: this.fieldAddItem,
      };

      switch (params) {
        case 'bug':
          this.sendBugOptionList(item);
          break;
        case 'state':
          this.sendStateOptionList(item);
          break;
        case 'note':
          this.sendNoteOptionList(item);
          break;
        case 'equipment':
          this.sendEquipmentOptionList(item);
          break;
        default: break;
      }
    }

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: null },
        { id: 2, section: this.checkFieldRoute, link: null },
      ]);
    }

    // List parametrs
    @Watch('stateoptionList')
    state(value) {
      this.fieldData = value;
    }

    @Watch('noteoptionList')
    note(value) {
      this.fieldData = value;
    }

    @Watch('equipmentoptionList')
    equipment(value) {
      this.fieldData = value;
    }

    @Watch('bugoptionList')
    bug(value) {
      this.fieldData = value;
    }

    // Update parametrs
    @Watch('updateStateOptionList')
    stateUpdate() {
      this.loadingEdit = false;
      this.openModalAddItem = false;
    }

    @Watch('updateNoteOptionList')
    noteUpdate() {
      this.loadingEdit = false;
      this.openModalAddItem = false;
    }

    @Watch('updateEquipmentOptionList')
    equipmentUpdate() {
      this.loadingEdit = false;
      this.openModalAddItem = false;
    }

    @Watch('updateBugOptionList')
    bugUpdate() {
      this.loadingEdit = false;
      this.openModalAddItem = false;
    }

    // Add parametrs

    @Watch('addStateOptionList')
    stateAdd(value) {
      this.fieldData.unshift(value);
      this.fieldAddItem = '';
    }

    @Watch('addNoteOptionList')
    noteAdd(value) {
      this.fieldAddItem = '';
      this.fieldData.unshift(value);
    }

    @Watch('addEquipmentOptionList')
    equipmentAdd(value) {
      this.fieldAddItem = '';
      this.fieldData.unshift(value);
    }

    @Watch('addBugOptionList')
    bugAdd(value) {
      this.fieldAddItem = '';
      this.fieldData.unshift(value);
    }
}
