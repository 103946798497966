export default class Point {
  address: string | null = null;

  cash_sum: number | null = null;

  color: string | null = null;

  enabled: boolean | null = null;

  id: number | null = null;

  name: string | null = null;

  phone: string | null = null;

  prefix: string | null = null;

  type: number | null = null;

  type_name: string | null = null;

  static buildFromApiResponse(apiResponse: Point) {
    const result = new this();
    result.id = apiResponse.id;
    result.name = apiResponse.name;
    result.address = apiResponse.address;
    result.color = apiResponse.color;
    result.enabled = apiResponse.enabled;
    result.phone = apiResponse.phone;
    result.prefix = apiResponse.prefix;
    result.type = apiResponse.type;
    result.type_name = apiResponse.type_name;
    if (typeof (apiResponse.cash_sum) !== 'undefined') {
      result.cash_sum = apiResponse.cash_sum;
    }
    return result;
  }
}
