import { ActionTree } from 'vuex';
import SalaryOperationProvider from '../../api/providers/salaryoperation';
import SalaryTransferProvider from '../../api/providers/salarytransfer';
import StuffProvider from '../../api/providers/stuff';
import {
  SalaryState,
  SET_SALARY_OPERATION_LIST_BY_TRANSFER_ID,
  SET_SALARY_TRANSFER_LIST_BY_USER_ID,
  SET_STUFF_WITH_SALARY_BALANCE,
  UserWithSalaryBalance,
} from './types';

const actions: ActionTree<SalaryState, object> = {
  async setSalaryOperationListByTransferId(
    { commit }, { transfer_id },
  ) {
    try {
      const res = await SalaryOperationProvider.salaryOperationIndex({
        form: {
          transfer_id,
        },
      });
      let items = res.data;
      if (res.pageCount > 1) {
        const promiseRequests: object[] = [];

        for (let page = 2; page <= res.pageCount; page++) {
          promiseRequests.push(SalaryOperationProvider.salaryOperationIndex({
            form: {
              transfer_id,
            },
            collectable: {
              pageNum: page,
            },
          })
            .then((resFromPage) => resFromPage.data));
        }

        await Promise.all(promiseRequests)
          .then((results) => {
            results.forEach((result) => {
              items += result;
              commit(
                SET_SALARY_OPERATION_LIST_BY_TRANSFER_ID,
                {
                  data: items,
                },
              );
            });
          });
      }
      return 0;
    } catch (err) {
      console.error(err);
      throw (err);
    }
  },

  async deleteSalaryOperation({ commit }, { id }) {
    try {
      const res = await SalaryOperationProvider.salaryOperationDelete({
        vars: {
          id,
        },
      });
      commit('CHANGE_DETAIL_SALARY_OPERATION', { data: res.data });
      commit('PUSH_CALL_SUCCESS', { title: 'Операция успешно удалена', item: '' });
      return 0;
    } catch (err) {
      commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      throw (err);
    }
  },

  async setSalaryTransferListByUserId(
    { commit }, { user_id, page },
  ) {
    try {
      const res = await SalaryTransferProvider.salaryTransferIndex({
        form: {
          user_id,
        },
        collectable: {
          pageNum: page,
        },
        expand: ['total_sum,cashoperation.cashbox'],
      });
      commit(
        SET_SALARY_TRANSFER_LIST_BY_USER_ID,
        {
          data: res,
        },
      );
      return 0;
    } catch (err) {
      console.error(err);
      throw (err);
    }
  },

  async addSalaryTransfer({ commit }, { form }) {
    commit('BTN_LOADER', true);
    try {
      await SalaryTransferProvider.salaryTransferAdd({
        form,
      });
      commit('BTN_LOADER', false);
      commit('PUSH_CALL_SUCCESS', { title: 'Выплата ЗП успешно проведена!', item: '' });
      return 0;
    } catch (err) {
      commit('BTN_LOADER', false);
      commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      throw (err);
    }
  },

  async deleteSalaryTransfer({ commit }, { id }) {
    try {
      await SalaryTransferProvider.salaryTransferDelete({
        vars: {
          id,
        },
      });
      commit('PUSH_CALL_SUCCESS', { title: 'Выплата успешно отменена', item: '' });
      return 0;
    } catch (err) {
      commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      throw (err);
    }
  },

  async setStuffWithSalaryBalance({ commit }) {
    try {
      const res = await Promise.all(
        [
          SalaryOperationProvider.salaryOperationIndex({
            form: {
              payed: '0',
            },
          }),
          StuffProvider.getFullStuffList(),
        ],
      );
      const [{ data: operations }, stuffWithSalaryBalance] = res;
      const mapSalaryBalanceToUserId = {};
      for (let i = 0; i < operations.length; ++i) {
        mapSalaryBalanceToUserId[operations[i].user_id] = 0;
      }
      for (let i = 0; i < operations.length; ++i) {
        mapSalaryBalanceToUserId[operations[i].user_id]
          += operations[i].sum;
      }
      for (let i = 0; i < stuffWithSalaryBalance.length; ++i) {
        (stuffWithSalaryBalance[i] as UserWithSalaryBalance)
          .salaryBalance = mapSalaryBalanceToUserId[
            (stuffWithSalaryBalance[i] as UserWithSalaryBalance).id
          ] || 0;
      }
      commit(SET_STUFF_WITH_SALARY_BALANCE,
        { data: stuffWithSalaryBalance });
      return 0;
    } catch (err) {
      console.error(err);
      throw (err);
    }
  },
};

export default actions;
