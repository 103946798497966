import { MutationTree } from 'vuex';
import { Part, Work } from '@/types';
import {
  ADD_SELL_DISCOUNT, ADD_SELL_PART,

  ADD_SELL_PAYMENT, ADD_SELL_WORK,

  CLEAR_PRODUCTS_LIST, CLEAR_REVERT_LIST,

  CLEAR_STATE_DELETE_PRODUCTS,
  CLEAR_STATE_DISCOUNT_LIST,
  CLEAR_STATE_PAYMENT_LIST, DEL_SELL,

  DEL_SELL_DISCOUNT, DEL_SELL_PART,

  DEL_SELL_PAYMENT, DEL_SELL_WORK, GET_SHOP,

  SELL_CANCEL,
  SELL_PART_REVERT, SELL_REVERT, SHOP_VALIDATION,

  StateShop, UPDATE_SELL, UPDATE_SELL_PART,

  UPDATE_SELL_WORK, VIEW_SELL,
} from './types';

const transformItem = (item, key) => ({
  id: item.id,
  [`${key}_id`]: item[key].id,
  name: item[key].name,
  amount: 1,
  remain: item.amount,
  price: item.price,
  date: item.created_at,
  storage_id: item.storage_id,
  seller_id: item.seller_id,
  serial: item.serial,
  vat: item[key].vat,
});

const mutations: MutationTree<StateShop> = {
  [GET_SHOP](state, { item, pageCurrent, pageCount }) {
    state.shop = item;
    state.shopPageCurrent = pageCurrent;
    state.shopPageCount = pageCount;
  },

  [SHOP_VALIDATION](state, item) {
    state.shopValid = item;
  },

  [VIEW_SELL](state, { item }) {
    state.viewSell = item;
    state.sellPartList = item.parts;
    state.sellWorkList = item.works;
    state.sellPaymentList = item.payments;
    state.sellDiscountList = item.discounts;
    state.sellPartRevertList = item.parts_reverts;
    state.sellWorkRevertList = item.works_reverts;
  },

  [ADD_SELL_PART](state, { item }) {
    state.sellPartList.push(item);
  },

  [ADD_SELL_WORK](state, { item }) {
    state.sellWorkList.push(item);
  },

  [ADD_SELL_PAYMENT](state, { item }) {
    state.sellPaymentList.push(item);
  },

  [ADD_SELL_DISCOUNT](state, { item }) {
    state.sellDiscount = item;
    state.sellDiscountList.push(item);
  },

  [SELL_REVERT](state, { item }) {
    state.sellRevert = item;
  },

  [SELL_PART_REVERT](state, { item }) {
    state.sellPartRevert = item;
    const indexPart = state.sellPartList.findIndex((i) => (i.part as Part).id === item.part_id);
    const indexWork = state.sellWorkList.findIndex((i) => (i.work as Work).id === item.work_id);

    if (indexPart >= 0) state.sellPartList.splice(indexPart, 1);
    if (indexWork >= 0) state.sellWorkList.splice(indexWork, 1);

    if ('part_id' in item) {
      state.sellPartRevertList.push(item);
    } else {
      state.sellWorkRevertList.push(item);
    }
  },

  [CLEAR_REVERT_LIST](state) {
    state.sellPartRevertList = [];
    state.sellWorkRevertList = [];
  },

  [CLEAR_PRODUCTS_LIST](state) {
    state.sellPartList = [];
    state.sellWorkList = [];
  },

  [CLEAR_STATE_DELETE_PRODUCTS](state) {
    state.sellDelete = null;
  },

  [CLEAR_STATE_PAYMENT_LIST](state) {
    state.sellPaymentList = [];
  },

  [CLEAR_STATE_DISCOUNT_LIST](state) {
    state.sellDiscountList = [];
  },

  [UPDATE_SELL_PART](state, { item }) {
    state.sellUpdatePart = item;
    const index = state.sellPartList.findIndex((i) => i.id === item.id);
    (state.sellPartList[index] as object) = transformItem(item, 'part');
  },

  [UPDATE_SELL_WORK](state, { item }) {
    state.sellUpdateWork = item;
    const index = state.sellWorkList.findIndex((i) => i.id === item.id);
    (state.sellWorkList[index] as object) = transformItem(item, 'work');
  },

  [UPDATE_SELL](state, { item }) {
    state.viewSell.status_id = item.status_id;
  },

  [SELL_CANCEL](state, { item }) {
    state.sellCancel = item;
  },

  [DEL_SELL_PART](state, { item }) {
    const index = state.sellPartList.findIndex((i) => i.id === item.id);
    state.sellPartList.splice(index, 1);
  },

  [DEL_SELL](state, { item }) {
    state.sellDelete = item;
  },

  [DEL_SELL_PAYMENT](state, { item }) {
    const index = state.sellPaymentList.findIndex((element) => element.id === item.id);
    state.sellPaymentList.splice(index, 1);
  },

  [DEL_SELL_DISCOUNT](state, { item }) {
    const index = state.sellDiscountList.findIndex((element) => element.id === item.id);
    state.sellDiscountList.splice(index, 1);
  },

  [DEL_SELL_WORK](state, { item }) {
    const index = state.sellWorkList.findIndex((i) => i.id === item.id);
    state.sellWorkList.splice(index, 1);
  },
};

export default mutations;
