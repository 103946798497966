import { ActionTree } from 'vuex';
import TransferProvider from '../../api/providers/transfer';
import { StateTransfer } from './types';

const actions: ActionTree<StateTransfer, object> = {

  getTransfer({ commit }, page) {
    TransferProvider.getTransfer(page)
      .then((res) => commit('GET_TRANSFER', {
        item: res.data,
        pageCount: res.pageCount,
      }))
      .catch((err) => console.error(err));
  },

  getViewTransfer({ commit }, id) {
    TransferProvider.getViewTransfer(id)
      .then((res) => commit('GET_VIEW_TRANSFER', { item: res.data }))
      .catch((err) => console.error(err));
  },

  sendTransferItem({ commit }, item) {
    commit('SEND_TRANSFER_ITEM', { item });
    commit('PUSH_CALL_SUCCESS', { title: 'Товар добавлен в перемещение' });
  },

  deleteTransferItem({ commit }, item) {
    commit('DELETE_TRANSFER_ITEM', item);
    commit('PUSH_CALL_SUCCESS', { title: 'Товар убран из перемещения' });
  },

  clearTransferItem({ commit }) {
    commit('CLEAR_TRANSFER_ITEM');
  },

  sendTransfer({ commit }, item) {
    commit('BTN_LOADER', true);
    TransferProvider.sendTransfer(item)
      .then((res) => {
        commit('GET_VIEW_TRANSFER', { item: res.data });
        commit('GET_VALID_TRANSFER', { valid: {} });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('GET_VALID_TRANSFER', { item: err.response.data });
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

};

export default actions;
