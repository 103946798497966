import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Point } from '@/types';
import ViewDate from '@/components/forms/date';
import Numbers from '@/components/forms/numbers';

@Component({
  template: require('./index.html'),
  components: {
    'repair-date': ViewDate,
    'repair-price': Numbers,
  },
})

export default class RepairTableCell extends Vue {
  @Prop() item;

  @Prop() dropdown_for_custom_status_name_1;

  @Prop() dropdown_for_custom_status_name_2;

  @Prop() dropdown_for_custom_status_name_3;

  @Prop() dropdown_for_custom_status_name_4;

  @Prop() dropdown_for_custom_status_name_5;

  @Prop() dropdown_for_custom_status_name_6;

  @Prop() active_column;

  @Prop() handlerSelectedStatus!: Function;

  @Prop() handleSelectedCustomStatus!: Function;

  @Getter point;

  dropdown_for_system_status_name_1: object[] = [
    {
      name: 'Принято', status_id: 1, custom_status_id: null, icon: 'fas fa-arrow-down',
    },
    { name: 'В работе', status_id: 2, icon: 'fas fa-wrench' },
  ];

  dropdown_for_system_status_name_2: object[] = [
    {
      name: 'В работе', status_id: 2, custom_status_id: null, icon: 'fas fa-wrench',
    },
    { name: 'На согласовании', status_id: 6, icon: 'fa fa-comments' },
    { name: 'Ожидает', status_id: 3, icon: 'fas fa-clock' },
    { name: 'Готово', status_id: 4, icon: 'fa fa-check' },
  ];

  dropdown_for_system_status_name_3: object[] = [
    {
      name: 'Ожидает', status_id: 3, custom_status_id: null, icon: 'fas fa-clock',
    },
    { name: 'В работе', status_id: 2, icon: 'fas fa-wrench' },
  ];

  dropdown_for_system_status_name_4: object[] = [
    {
      name: 'Готово', status_id: 4, custom_status_id: null, icon: 'fa fa-check',
    },
    { name: 'В работе', status_id: 2, icon: 'fas fa-wrench' },
    { name: 'Выдано', status_id: 5, icon: 'fas fa-plane repair__icons_rotate' },
  ];

  dropdown_for_system_status_name_5: object[] = [
    {
      name: 'Выдано', status_id: 5, custom_status_id: null, icon: 'fas fa-plane repair__icons_rotate',
    },
    { name: 'Принято', status_id: 1, icon: 'fas fa-arrow-down' },
  ];

  dropdown_for_system_status_name_6: object[] = [
    {
      name: 'На согласовании', status_id: 6, custom_status_id: null, icon: 'fa fa-comments',
    },
    { name: 'Согласовано', type: 'success', icon: 'fa fa-thumbs-up' },
    { name: 'Отказ от ремонта', type: 'danger', icon: 'fa fa-thumbs-down' },
  ];

  selectedStylesStatus = (item) => (item.custom_status
    ? { background: item.custom_status.color, 'border-color': item.custom_status.color, color: '#fff' }
    : {})

  selectedClassStatus = (item) => (item.custom_status
    ? { repair__status: true }
    : {
      repair__status_1: item.status_id === 1,
      repair__status_2: item.status_id === 2,
      repair__status_3: item.status_id === 3,
      repair__status_4: item.status_id === 4,
      repair__status_5: item.status_id === 5,
      repair__status_6: item.status_id === 6,
    })

  doneAt = (item) => {
    const now = Math.ceil(new Date().getTime() / 1000);

    if (item.planned_out_date) {
      return item.done_at ? item.planned_out_date < item.done_at : item.planned_out_date < now;
    }
    return false;
  }

  /**
   * Возвращает строку в какой филиал происходит отправка заказа
   * @param  {number} pointId - id филиала, в который происходит перемещение
   * @returns {string} - маркер и название филиала назначения, если не нашел, то НЕИЗВЕСТНО
   */
  getLastTransferPont(pointId: number): string {
    const findedPoint: Point = this.point.find((point) => point.id === pointId);
    if (findedPoint) {
      const pointString = `${findedPoint.prefix} ${findedPoint.name}`;
      return pointString;
    }
    return 'НЕИЗВЕСТНО';
  }

  formatPhoneHref = (phone) => `tel:${phone}`;

  formantionPrepayment = (payment) => {
    if (payment && payment.length !== 0) {
      const filteredPay = payment.filter((pay) => pay.is_prepayment)
        .reduce((sum1, sum2) => sum1 + sum2.sum, 0);
      return filteredPay;
    }
    return 0;
  }

  selecetedNameStatus = (item) => {
    if (item.custom_status) {
      return item.custom_status.name;
    }
    switch (item.status_id) {
      case 1: return 'Принято';
      case 2: return 'В работе';
      case 3: return 'Ожидает';
      case 4: return 'Готово';
      case 5: return 'Выдано';
      case 6: return 'На согласовании';
      default: return '-';
    }
  }

  formationName = (row) => {
    if (row) {
      const user_name = [row.last_name, row.first_name, row.middle_name]
        .filter((user) => !!user);
      if (user_name.length === 0) {
        return '-';
      }
      return user_name.length === 3 ? `${user_name[0]} ${user_name[1][0]}. ${user_name[2][0]}.` : user_name.join(' ');
    }
    return '—';
  }

  buildRepairUrl = (item) => `${window.location.origin}/#/repair/view/${item.id}#info`

  openRepairInNewTab(item) {
    window.open(this.buildRepairUrl(item), '_blank');
  }
}
