export default function removeCartridges(this) {
  this.$root.$on('removeCartridges', (id) => {
    const index = this.data_repair.objects.findIndex((i) => i.id === id);

    if (this.data_repair.objects.length === 1) {
      const empty_device = {
        id: 1, amount: 1, model: null, type: null, vendor: null, device_id: null,
      };
      this.data_repair.objects.splice(index, 1, empty_device);
    } else {
      this.data_repair.objects.splice(index, 1);
    }
  });
}
