import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb } from '@/types';
import ViewDate from '../forms/date';

@Component({
  template: require('./index.html'),
  components: {
    date: ViewDate,
  },
})
export default class Licenses extends Vue {
    @Action getLicenseList;

    @Action addBreadcrumb;

    @Action addActionBtn;

    @Getter licensesList;

    @Getter licensesListPageCount;

    loading = true;

    page = 1;

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Биллинг', link: null },
      { id: 3, section: 'Лицензии', link: null },
    ];

    mounted() {
      this.addBreadcrumb(this.breadcrumb);
      if (this.$route.params.page) {
        this.page = Number(this.$route.params.page);
      }
      this.loadPage(this.page);
      document.title = 'Лицензии';
    }

    destroyed() {
      this.addActionBtn([]);
    }

    loadPage(val) {
      this.loading = true;
      this.$router.push(`/service/billing/licenses/${val}`);
      this.getLicenseList(val);
    }

    countDay = (end) => {
      const value: number = end - Math.round(new Date().getTime() / 1000);
      const day: number = Math.ceil(value / 86400);
      return day;
    }

    @Watch('$route.params')
    reloadPage() {
      if (this.$route.params.page) {
        this.page = Number(this.$route.params.page);
        this.loadPage(this.page);
      }
    }

    @Watch('licensesList')
    onLoad() {
      window.scroll(0, 0);
      this.loading = false;
    }
}
