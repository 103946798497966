import { MutationTree } from 'vuex';
import {
  DELETE_DOCUMENT, GET_DOCUMENTS,
  GET_VIEW_DOCUMENT,

  NEW_DOCUMENT,
  StateDocument, UPDATE_DOCUMENT,
} from './types';

const mutations: MutationTree<StateDocument> = {

  [GET_DOCUMENTS](state, { item, pageCount }) {
    state.pageDocumentList = Number(pageCount);
    state.documentList = item;
  },

  [GET_VIEW_DOCUMENT](state, { item }) {
    state.document = item;
  },

  [NEW_DOCUMENT](state, { item }) {
    state.newDocument = item;
  },

  [UPDATE_DOCUMENT](state, { item }) {
    state.editDocument = item;
  },

  [DELETE_DOCUMENT](state, { item }) {
    state.delDocument = item;
  },
};

export default mutations;
