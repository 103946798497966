import { ActionTree } from 'vuex';
import UserProvider from '@/api/providers/user';
import { ChangePointForm } from '@/types';
import { CLEAR_USER, StateUser } from './types';

const actions: ActionTree<StateUser, object> = {

  getUserMe({ commit }) {
    UserProvider.getUserMe()
      .then((res) => commit('GET_USER_ME', res.data))
      .catch((err) => console.error(err));
  },

  sendUserChangePoint({ commit }, item: ChangePointForm) {
    UserProvider.sendUserChangePoint(item)
      .then(() => {
        commit('UPDATE_USER', { item: {} });
        commit('PUSH_CALL_SUCCESS', { title: 'Филиал успешно изменен', item: '' });
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 422) {
          if (err.response.data.point_id) {
            commit('PUSH_CALL_ERROR', { title: 'У вас нет доступа к этому филиалу', item: '' });
            return;
          }
        }
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendUserUpdatePassword({ commit }, item) {
    commit('BTN_LOADER', true);
    UserProvider.sendUserUpdatePassword(item)
      .then(() => {
        commit('GET_VALID_NEW_PASSWORD', { item: {} });
        commit('PUSH_CALL_SUCCESS', { title: 'Пароль успешно изменён', item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('GET_VALID_NEW_PASSWORD', { item: err.response.data });
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  addRepairActiveColumn({ commit }, item) {
    commit('ADD_REPAIR_ACTIVE_COLUMN', item);
  },

  clearUser({ commit }) {
    commit(CLEAR_USER);
  },
};

export default actions;
