import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  template: require('./index.html'),
})
export default class StorageAdd extends Vue {
  @Action sendStorage;

  @Getter point;

  @Getter btnloader;

  @Getter storages;

  get pointHasAccess() {
    return this.point.filter((p) => p.has_access);
  }

  newItem: object = {
    name: '',
    point_id: '',
  };

  resetForm() {
    (this.$refs.formStorage as Form).resetFields();
  }

  requestOnNewStorage(formName) {
    const refForm = this.$refs[formName] as Form;
    refForm.validate((valid) => (valid ? this.sendStorage(this.newItem) : false));
  }

  @Watch('storages')
  onChange() { this.resetForm(); }
}
