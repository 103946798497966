import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { User, Storage } from '@/types';
import PointProvider from '../../api/providers/point';
import StorageProvider from '../../api/providers/storage';
import StuffProvider from '../../api/providers/stuff';
import ViewUserName from '../../components/forms/user-name';
import SettingsStorage from '../../components/settings-storage';
import StorageAdd from '../../components/storage-add';

interface FormType {
  id;
  name;
  point_id;
  users: User[];
}
@Component({
  template: require('./index.html'),
  components: {
    'storage-add': StorageAdd,
    'settings-storage': SettingsStorage,
    'user-name': ViewUserName,
  },
})
export default class StoragePage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Getter user;

    page = 1;

    dialog_edit = false;

    dialog_delete = false;

    form: FormType = {
      id: null, name: '', point_id: null, users: [],
    };

    type_form = '';

    loading = true;

    loading_btn = false;

    storage_list: Storage[] = [];

    storage_list_page_count: number | null = null;

    point_list: object[] = [];

    stuff_list: User[] = [];

    get sortedStuffList() {
      return this.stuff_list.sort((a, b) => {
        const aFullName = `${a.last_name} ${a.first_name} ${a.middle_name}`;
        const bFullName = `${b.last_name} ${b.first_name} ${b.middle_name}`;
        return aFullName.localeCompare(bFullName);
      });
    }

    page_stuff = 1;

    page_storage = 1;

    valid_storage: object = {};

    cashbox_add_user_form: object = {
      id: null,
    };

    modalUserTableData: StorageModalUserTableRow[] = [];

    get excludedStuff() {
      if (this.stuff_list && this.stuff_list.length !== 0) {
        return this.diffStuffList(this.stuff_list, this.form.users);
      }
      return [];
    }

    getStuffList() {
      this.stuff_list = [];
      StuffProvider.getFullStuffList()
        .then((res) => {
          res.forEach((element) => {
            this.stuff_list.push(element);
          });
        }).catch((err) => console.error(err));
    }

    getStorageList(page = 1) {
      StorageProvider.getStorageList(page, { ignore_visibility_filter: true })
        .then((res) => {
          this.storage_list = res.data;
          this.storage_list_page_count = Number(res.pageCount);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    }

    deleteStorage(id) {
      this.loading_btn = true;
      StorageProvider.deleteStorage(id)
        .then((res) => {
          const index = this.storage_list.findIndex((i) => i.id === res.data.id);
          this.storage_list.splice(index, 1);

          this.loading_btn = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Склад «${res.data.name}» успешно удалён`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading_btn = false;
        });
    }

    // todo исправить на запрос полного списка филиалов. больше 20 филиалов не отдаст.
    getPointList(page = 1) {
      PointProvider.getPointList(page)
        .then((res) => {
          this.point_list = res.data;
        })
        .catch((err) => console.error(err));
    }

    loadData() {
      this.getStuffList();
      this.getStorageList(this.page_storage);
      this.getPointList();
    }

    mounted() {
      this.loadData();
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: null },
        { id: 2, section: 'Настройки', link: null },
        { id: 3, section: 'Склады', link: null },
      ]);
      document.title = 'Склады';

      this.addActionBtn([
        {
          id: 1, type: 'add', title: 'Добавить склад', link: 'onDialog', icon: 'fa-plus',
        },
      ]);

      this.$root.$on('onDialog', () => {
        this.modalUserTableData = [];
        this.sortedStuffList.forEach((element) => {
          this.modalUserTableData.push({
            user: element,
            selected: false,
          });
        });
        this.type_form = 'add';
        this.dialog_edit = true;
      });
    }

    destroyed() {
      this.addBreadcrumb([]);
      this.addActionBtn([]);
    }

    diffStuffList = (stuff_list1, stuff_list2) => stuff_list1.filter(
      (a1) => !stuff_list2.some((a2) => a1.id === a2.id),
    ).filter((item) => item)

    requestOnEditStorage(formName) {
      (this.$refs[formName] as Form).validate(async (valid) => {
        if (valid) {
          let apiStorage;
          let storageUsers = [];
          if (this.type_form === 'add') {
            this.loading_btn = true;
            try {
              apiStorage = (await StorageProvider.sendStorage(this.form)).data;
              this.storage_list.push(apiStorage);

              this.$store.commit('PUSH_CALL_SUCCESS', { title: `Склад «${this.form.name}» успешно добавлен`, item: '' });
              this.loading_btn = false;
              this.valid_storage = {};
            } catch (e) {
              console.error('Ошибка создания кассы', e);
              this.valid_storage = e.response.data;
              this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
              this.loading_btn = false;
              return false;
            }
          } else {
            this.loading_btn = true;
            try {
              apiStorage = (await StorageProvider.changeStorage(this.form.id, this.form)).data;
              const index = this.storage_list.findIndex((i) => i.id === apiStorage.id);
              this.storage_list.splice(index, 1, apiStorage);

              this.$store.commit('PUSH_CALL_SUCCESS', { title: `Склад «${this.form.name}» успешно изменён`, item: '' });
              this.loading_btn = false;
              this.valid_storage = {};
            } catch (e) {
              console.error('Ошибка изменения кассы', e);
              this.valid_storage = e.response.data;
              this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
              this.loading_btn = false;
              return false;
            }
            storageUsers = apiStorage.users;
          }
          // формируем два списка пользователей. в одном будут новые пользователи кассы,
          // в другом - удаленные
          const storageSelectedUsers = this.modalUserTableData.filter((x) => x.selected);
          const newUsersInStorage = this.diffStuffList(
            storageSelectedUsers.map((x) => x.user),
            storageUsers,
          );
          const removedUsersInStorage = this.diffStuffList(
            storageUsers,
            storageSelectedUsers.map((x) => x.user),
          );
          // проходимся по каждому элементу списков и сохраняем данные
          const userApiPromises: object[] = [];
          newUsersInStorage.forEach((x) => {
            try {
              userApiPromises.push(
                StorageProvider.StorageAddUserForm({ user_id: x.id, storage_id: apiStorage.id }),
              );
            } catch (e) {
              console.error('Ошибка добавления сотрудника к складу', e);
            }
          });
          removedUsersInStorage.forEach((x) => {
            try {
              userApiPromises.push(
                StorageProvider.StorageRemoveUserForm({ user_id: x.id, storage_id: apiStorage.id }),
              );
            } catch (e) {
              console.error('Ошибка отвязки сотрудника от склада', e);
            }
          });
          await Promise.all(userApiPromises);
          this.loadData();
          return true;
        }
        return false;
      });
    }

    loadPage(val) {
      this.page_storage = val;
      this.getStorageList(this.page_storage);
    }

    onDialogEdit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.modalUserTableData = [];
      this.sortedStuffList.forEach((element) => {
        this.modalUserTableData.push({
          user: element,
          selected: item.users.some((x) => x.id === element.id),
        });
      });
      this.type_form = 'edit';
      this.dialog_edit = !this.dialog_edit;
    }

    onDialogDelete(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.dialog_delete = !this.dialog_delete;
    }

    closeFormStorage() {
      this.form = {
        id: null, name: '', point_id: null, users: [],
      };
      (this.$refs.editFormStorage as Form).resetFields();

      this.dialog_edit = false;
    }

    @Watch('valid_storage')
    onChange(val: object) {
      if (Object.keys(val).length === 0) {
        this.dialog_edit = false;
      }
    }
}

interface StorageModalUserTableRow {
    user: object;
    selected: boolean;
}
