import { GetterTree } from 'vuex';
import { StateBugOption } from './types';

const getters: GetterTree<StateBugOption, object> = {
  bugoptionList: (state) => state.bugoptionList,
  addBugOptionList: (state) => state.addBugOptionList,
  updateBugOptionList: (state) => state.updateBugOptionList,
  deleteBugOptionList: (state) => state.deleteBugOptionList,
  sendBugOptionPriority: (state) => state.sendBugOptionPriority,
};

export default getters;
