import { StorageSearchForm } from '@/types';
import BaseProvider from '../base-provider';

class StorageProvider extends BaseProvider {
  async getStorageList(page, search: StorageSearchForm = { ignore_visibility_filter: null }) {
    const expand: string[] = ['point', 'users'];
    const searchForm: string = this.buildUrl({ StorageSearchForm: search });
    const res = await this.request('GET', `/storage/index?expand=${expand}&page=${page}${searchForm}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getAvailableList(page) {
    const res = await this.request('GET', `/storage/availablelist?page=${page}`);
    return res;
  }

  async sendStorage(item) {
    const expand: string[] = ['point', 'users'];
    const data: object = { Storage: item };
    const res = await this.request('POST', `/storage/add?expand=${expand}`, data);
    return res;
  }

  async changeStorage(id, item) {
    const expand: string[] = ['point', 'users'];
    const data: object = { Storage: item };
    const res = await this.request('POST', `/storage/update?id=${id}&expand=${expand}`, data);
    return res;
  }

  async deleteStorage(id) {
    const res = await this.request('POST', `/storage/delete?id=${id}`);
    return res;
  }

  async StorageAddUserForm(item: ApiStorageUserActionForm) {
    const data: object = { StorageAddUserForm: item };
    const res = await this.request('POST', '/storage/adduser', data);
    return res;
  }

  async StorageRemoveUserForm(item: ApiStorageUserActionForm) {
    const data: object = { StorageRemoveUserForm: item };
    const res = await this.request('POST', '/storage/removeuser', data);
    return res;
  }
}

export default new StorageProvider();

export interface ApiStorageUserActionForm {
    user_id: number;
    storage_id: number;
}
