import Vue from 'vue';
import { Table } from 'element-ui';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Numbers from '../forms/numbers';

interface TableWraperr {
  bodyWrapper;
}

interface NewItem {
  part_id;
  amount;
  id;
}
@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
  },
})
// PostingItem
export default class WarehouseTable extends Vue {
    @Prop() item!: NewItem[];

    @Prop() storage!: number;

    @Prop() addPart!: Function;

    @Prop() delPart!: Function;

    @Prop() apiAddPart!: Function;

    @Prop() apiDelPart!: Function;

    @Prop() newItemList!: NewItem[];

    @Prop({ default: () => false }) checkIdPosting!: boolean;

    @Prop({ default: () => false }) viewAmountsParts!: boolean;

    @Prop({ default: () => '' }) type!: string;

    @Prop({ default: () => false }) loadingPostingItem!: boolean;

    @Prop({ default: () => false }) flagsPriceColumnChanges!: boolean;

    get viewWarehouse() {
      return this.$route.name;
    }

    created() {
      this.updateAmounts();
    }

    updateAmounts() {
      this.item.forEach((elem) => {
        this.newItemList.forEach((item) => {
          if (item.part_id) {
            if (item.part_id === elem.part_id) {
              /* eslint-disable-next-line no-param-reassign */
              elem.amount = item.amount;
            }
          }
        });
      });
    }

    amounts(amounts) {
      const amounts_filters = amounts.filter((amount) => amount.storage_id === this.storage)[0];
      return amounts_filters.amount;
    }

    checkItem(item) {
      if (this.newItemList) {
        return this.type === 'viewModal' || this.checkIdPosting
          ? this.newItemList.filter((i) => i.part_id === item.id).length
          : this.newItemList.filter((i) => i.id === item.id).length;
      }
      return 0;
    }

    sendItemPart(item) {
      const itemCopy = item;
      if (Number(itemCopy.price) < 0 || itemCopy.price === '') {
        itemCopy.price = 0;
      }
      if (itemCopy.amount < 1) {
        itemCopy.amount = 1;
      } else {
        itemCopy.amount = Math.floor(itemCopy.amount);
      }

      if (this.addPart || this.apiAddPart) {
        if (this.type === 'view' || this.type === 'viewModal') {
          itemCopy.posting_id = Number(this.$route.params.id);
          this.apiAddPart(itemCopy);
        } else {
          this.addPart(itemCopy);
        }
      }
    }

    deleteItemPart(item) {
      if (this.delPart || this.apiDelPart) {
        if (this.type === 'view' || this.type === 'viewModal') {
          const delPart = this.newItemList.filter((part) => part.part_id === item.id)[0];
          this.apiDelPart(delPart.id);
        } else {
          this.delPart(item);
        }
      }
    }

    @Watch('item')
    updateItem() {
      this.updateAmounts();
      ((this.$refs.table as Table) as unknown as TableWraperr).bodyWrapper.scrollTop = 0;
    }
}
