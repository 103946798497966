import getters from './getters';
import mutations from './mutations';
import state from './state';

const preloader = {
  state,
  mutations,
  getters,
};

export default preloader;
