import { ActionTree } from 'vuex';
import ServiceProvider from '../../api/providers/service';
import { StateService } from './types';

const actions: ActionTree<StateService, object> = {

  getStickerSettings({ commit }) {
    ServiceProvider.getStickerSettings()
      .then((res) => {
        commit('GET_STICKER_SETTINGS', { item: res.data });
      })
      .catch((err) => {
        console.error(err);
      });
  },

  newStickerSettings({ commit }, data) {
    commit('BTN_LOADER', true);
    ServiceProvider.sendStickerSettings(data)
      .then((res) => {
        commit('GET_STICKER_SETTINGS', { item: res.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: 'Изменения успешно сохранены' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendUploadlogo({ commit }, data) {
    ServiceProvider.sendUploadlogo(data)
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

};

export default actions;
