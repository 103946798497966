import { MutationTree } from 'vuex';
import {
  DELETE_TASK, EDIT_TASK, GET_TASK, GET_TASK_LIST,

  INSERT_TASK,
  TaskState, UPDATE_LAST_SAVED_TASK_ID,
} from './types';

const mutations: MutationTree<TaskState> = {

  [GET_TASK_LIST](state, { item, pageCount, pageCurrent }) {
    state.taskList = item;
    state.pageTaskCount = pageCount;
    state.pageTaskCurrent = pageCurrent;
  },

  [GET_TASK](state, { item }) {
    state.currentTask = item;
  },

  // [GET_VALID_STORAGE](state, { item }) {
  //     state.validStorage = item;
  // },
  //
  [INSERT_TASK](state, { item }) {
    state.taskList.push(item);
  },

  [EDIT_TASK](state, { item }) {
    const index = state.taskList.findIndex((i) => i.id === item.id);
    state.taskList.splice(index, 1);
    state.taskList.splice(index, 0, item);
  },

  [UPDATE_LAST_SAVED_TASK_ID](state, { id }) {
    state.lastSavedTaskId = id;
  },

  [DELETE_TASK](state, { item }) {
    const index = state.taskList.findIndex((i) => i.id === item.id);
    state.taskList.splice(index, 1);
  },

};

export default mutations;
