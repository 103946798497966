import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { SellWorkRevert } from '@/types';
import PartProvider from '@/api/providers/part';
import WorkProvider from '@/api/providers/work';
import InfoAboutPartOrWork from '@/components/info-about-part-or-work';
import Numbers from '../numbers';
import ViewUserName from '../user-name';

@Component({
  template: require('./cancel.html'),
  components: {
    numbers: Numbers,
    'user-name': ViewUserName,
    InfoAboutPartOrWork,
  },
})
export default class CancelForm extends Vue {
  /**
   * @todo переименовать компонент. Он никак не связан со списанием.
   */
    @Getter user;

    @Prop({ default: () => ({}) }) valid!: object;

    @Prop() data!: object[];

    @Prop({ default: () => '' }) title!: string;

    @Prop({ default: () => 1 }) status!: number;

    @Prop({ default: () => 'sell' }) type!: string;

    @Prop() storageList!: object[];

    @Prop() document_sold_part!: object[];

    @Prop() document_sold_work!: object[];

    @Prop() token_for_documents!: string;

    @Action getVatList;

    @Action getRewardTypeList;

    @Action getWarrantTypeList;

    @Action getPartCategory;

    btnloader = false;

    showModalViewAboutWorkOrPart = false;

    dataForModalView = {};

    titleInfoAboutPartOrWork= '';

    get rightsUserCanRevertSell(): boolean {
      return this.user.rights ? this.user.rights.can_revert_sell : false;
    }

    changSellPart(item) {
      this.$emit('changSellPart', item);
    }

    changSellWork(item) {
      this.$emit('changSellWork', item);
    }

    validate = (obj, key, index) => {
      if (obj) {
        if (typeof (obj[index]) !== 'boolean' && obj[index]) {
          return obj[index][key][0];
        }
      }
      return null;
    }

    page(page) {
      if (this.$route.name) {
        const pageName: string = this.$route.name;
        return pageName === page;
      }
      return false;
    }

    viewingWork(row) {
      try {
        this.btnloader = true;
        WorkProvider.getViewWork(row.work.id)
          .then((res) => {
            this.titleInfoAboutPartOrWork = 'Информация об услуге';
            this.dataForModalView = res.data;
            this.btnloader = false;

            this.getVatList();
            this.getRewardTypeList();
            this.getWarrantTypeList();
            this.getPartCategory('work');

            this.showModalViewAboutWorkOrPart = true;
          })
          .catch((error) => {
            this.btnloader = false;
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные об услуге', item: '' });
            throw new Error(error);
          });
      } catch (err) {
        this.btnloader = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        throw new Error(err);
      }
    }

    viewingPart(row) {
      try {
        this.btnloader = true;
        PartProvider.getViewPart(row.part.id)
          .then((res) => {
            this.titleInfoAboutPartOrWork = 'Информация о товаре';
            this.dataForModalView = res.data;
            this.btnloader = false;
            this.getVatList();
            this.getRewardTypeList();
            this.getWarrantTypeList();
            this.getPartCategory('part');

            this.showModalViewAboutWorkOrPart = true;
          })
          .catch((error) => {
            this.btnloader = false;
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о товаре', item: '' });
            throw new Error(error);
          });
      } catch (err) {
        this.btnloader = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        throw new Error(err);
      }
    }

    modalSerial(event, item) {
      this.$emit('openSerialModal', item);
    }

    closeInfoAboutPartOrWork() {
      this.showModalViewAboutWorkOrPart = false;
    }

    /* eslint-disable no-param-reassign */
    handlerValuePrice = (value, row) => {
      if (!Number.isNaN(value)) row.price = value.replace(/[^0-9/.]/g, '');
    }
    /* eslint-enable no-param-reassign */

    titleBtn(period) {
      if (this.$route.name) {
        const pageName: string = this.$route.name;
        let title = '';
        switch (pageName) {
          case 'transfer-add': title = ' перемещени'; break;
          case 'cancel-add': title = ' списани'; break;
          case 'cancel-view': title = ' списани'; break;
          default: break;
        }
        title += period === 'past' ? 'я' : 'e';
        return title;
      }
      return '';
    }

    selectedDocument(product, document) {
      const domain = process.env.NODE_ENV === 'production' ? 'printing.servix.io' : 'printing-dev.servix.io';
      window.open(`https://${domain}/print/document?token=${this.token_for_documents}&documentId=${document.id}&entityId=${product.id}`);
    }

    checkItemType = (val) => !!val.part_id

    changeItem(product: SellWorkRevert) {
      const productCopy: SellWorkRevert = { ...product };
      productCopy.price = Number(Number(productCopy.price).toFixed(2));
      if ('work_id' in productCopy) {
        this.changSellWork(productCopy);
      } else {
        this.changSellPart(productCopy);
      }
    }
}
