import { Report } from '@/store/report/types';
import BaseProvider from '../base-provider';

class ReportProvider extends BaseProvider {
  async getAvaliableReportList(page) {
    const res = await this.request('GET', `/report/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getAllAvaliableReportList() {
    const result = await this.getAvaliableReportList(1);
    const reports: Report[] = result.data;
    const promiseRequest: object[] = [];
    for (let i = 2; i <= result.pageCount; i++) {
      promiseRequest.push(this.getAvaliableReportList(i)
        .then((res) => res.data));
    }
    await Promise.all(promiseRequest)
      .then((AvaliableReportList) => {
        (AvaliableReportList as Report[]).sort((a, b) => b.id - a.id);
        (AvaliableReportList as Report[]).forEach((item) => {
          reports.push(item);
        });
      });
    return reports;
  }

  /**
     * @deprecated
     */
  async getEconomy(start: string, end: string, cashbox_ids: number[]) {
    const expand: string[] = ['items.custom_type'];
    const res = await this.request('GET', `/report/economy?start=${start}&end=${end}&cashbox_ids=${(cashbox_ids !== null && cashbox_ids.length > 0) ? cashbox_ids.join(',') : ''}&expand=${expand}`);
    return {
      data: res.data,
    };
  }
}

export default new ReportProvider();
