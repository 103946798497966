import { MutationTree } from 'vuex';
import {
  DELETE_POINT, EDIT_POINT, GET_POINT,

  GET_VALID_POINT, GET_VIEW_POINT,

  INSERT_POINT,

  Point, StatePoint,
} from './types';

const mutations: MutationTree<StatePoint> = {

  [GET_POINT](state, { item, pageCount, pageCurrent }) {
    state.point = item;
    state.pointPageCount = pageCount;
    state.pointPageCurrent = pageCurrent;
  },

  [GET_VIEW_POINT](state, { item }) {
    state.viewPoint = item;
  },

  [GET_VALID_POINT](state, { valid }) {
    state.validPoint = valid;
  },

  [INSERT_POINT](state, item) {
    state.point.push(item.item);
    state.newPoint = item;
  },

  [EDIT_POINT](state, item) {
    const index = state.point.indexOf(state.point.find((i) => i.id === item.id) as Point);
    state.point.splice(index, 1, item);
    state.changePoint = item;
  },

  [DELETE_POINT](state, { item }) {
    const index = state.point.findIndex((i) => i.id === item.id);
    state.point.splice(index, 1);
  },
};

export default mutations;
