import Point from '@/classes/point';

export default class Cashbox {
  id: number | null = null;

  name: string | null = null;

  point_id: number | null = null;

  point: Point | null = null;

  cashless = false;

  commission = 0;

  sum: number | null = null;

  users: object[] = [];

  public resetFields() {
    this.id = null;
    this.name = null;
    this.point_id = null;
    this.cashless = false;
    this.commission = 0;
    this.sum = null;
    this.point = null;
  }

  static buildFromApiResponse(apiResponse: Cashbox) {
    const result = new this();
    result.id = apiResponse.id;
    result.name = apiResponse.name;
    result.point_id = apiResponse.point_id;
    result.cashless = apiResponse.cashless;
    result.commission = apiResponse.commission;
    result.users = apiResponse.users;
    if (typeof (apiResponse.sum) !== 'undefined') {
      result.sum = Number(apiResponse.sum);
    }
    if ((typeof (apiResponse.point) !== 'undefined') && apiResponse.point !== null) {
      result.point = Point.buildFromApiResponse(apiResponse.point);
    }
    return result;
  }
}
