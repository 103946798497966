import BaseProvider from '../base-provider';

class WorkProvider extends BaseProvider {
  async getWorkList(page, item, perPage) {
    const searchForm: string = this.buildUrl({ WorkSearchForm: item });
    const res = await this.request('GET', `/work/index?page=${page}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-current-page'],
    };
  }

  async getWorkSearchList(page, item, perPage) {
    const searchForm: string = this.buildUrl({ UniversalWorkSearchForm: item });
    const res = await this.request('GET', `/work/universalsearch?page=${page}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getViewWork(id) {
    const res = await this.request('GET', `/work/view?id=${id}`);
    return res;
  }

  async changeWork(item) {
    const itemCopy = { ...item };
    if (itemCopy.repair_reward_size === '') itemCopy.repair_reward_size = null;
    if (itemCopy.sell_reward_size === '') itemCopy.sell_reward_size = null;
    if (itemCopy.warrant_duration === '') itemCopy.warrant_duration = null;
    const data: object = { Work: itemCopy };
    const res = await this.request('POST', `/work/update?id=${itemCopy.id}`, data);
    return res;
  }

  async sendWork(item) {
    const data: object = { Work: item };
    const res = await this.request('POST', '/work/add', data);
    return res;
  }

  async deleteWork(id) {
    const res = await this.request('POST', `/work/delete?id=${id}`);
    return res;
  }

  async revertWork(id) {
    const res = await this.request('POST', `/work/revertdelete?id=${id}`);
    return res;
  }
}

export default new WorkProvider();
