import { PriorityForm } from '@/types';
import { TaskStatus } from '../../store/task-status/types';
import BaseProvider from '../base-provider';

class TaskStatusProvider extends BaseProvider {
  async getTaskStatusList(page) {
    const res = await this.request('GET', `/taskstatus/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  /**
     * Получение всех статусов из API
     * @return {TaskStatus[]}
     */
  async getAllStatuses() {
    const result = await this.allPageReuest('GET', '/taskstatus/index');
    return result as TaskStatus[];
  }

  /**
     *
     * @param id
     * @return {TaskStatus}
     */
  async getStatus(id: number) {
    const result = await this.request('GET', `/taskstatus/view?id=${id}`);
    return result.data;
  }

  /**
   * Добавление нового статуса
   * @param  {TaskStatus} taskStatus
   * @return {TaskStatus} taskStatus
   */
  async addNewTaskStatus(taskStatus: TaskStatus) {
    const data = { TaskStatus: taskStatus };
    const result = await this.request('POST', '/taskstatus/add', data);
    return result.data;
  }

  /**
   * обновление имеющегося статуса задачи
   * @param  {number} taskStatusId
   * @param  {TaskStatus} taskStatus
   * @returns result
   */
  async updateTaskStatus(taskStatusId: number, taskStatus: TaskStatus) {
    const data = { TaskStatus: taskStatus };
    const result = await this.request('POST', `/taskstatus/update?id=${taskStatusId}`, data);
    return {
      data: result.data,
    };
  }

  /**
   * удаление имеющегося статуса задачи
   * @param  {number} taskStatusId
   * @returns result
   */
  async deleteTaskStatus(taskStatusId: number) {
    const result = await this.request('POST', `/taskstatus/delete?id=${taskStatusId}`);
    return {
      data: result.data,
    };
  }

  /**
   * изменяет приоритет у статуса задачи
   * @param  {PriorityForm} form
   * @returns result
   */
  async changeTaskStatusPriority(form: PriorityForm) {
    const data = { PriorityForm: form };
    const result = await this.request('POST', '/taskstatus/setpriority', data);
    return {
      data: result.data,
    };
  }
  // async sendCashType(item) {
  //     const data: object = { 'CashType': item };
  //     let res = await this.request('POST', `/cashtype/add`, data);
  //     return res;
  // }
  //
  // async changeCashType(item) {
  //     const data: object = { 'CashType' : item };
  //     let res = await this.request('POST', `/cashtype/update?id=${item.id}`, data);
  //     return res;
  // }
  //
  // async deleteCashType(id) {
  //     let res = await this.request('POST', `/cashtype/delete?id=${id}`);
  //     return res;
  // }
}

export default new TaskStatusProvider();
