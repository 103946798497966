import { GetterTree } from 'vuex';
import { StateTransfer } from './types';

const getters: GetterTree<StateTransfer, object> = {
  transfer: (state) => state.transfer,
  viewTransfer: (state) => state.viewTransfer,
  transferValid: (state) => state.transferValid,
  transferPageCount: (state) => state.transferPageCount,
  transferNewItemList: (state) => state.transferNewItemList,
};

export default getters;
