import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ViewDate from '@/components/forms/date';
import NumberPhone from '@/components/forms/number-phone';
import ViewUserName from '@/components/forms/user-name';

@Component({
  template: require('./index.html'),
  components: {
    'user-name': ViewUserName,
    date: ViewDate,
    phone: NumberPhone,
  },
})
export default class SmsTable extends Vue {
  @Prop() smsTableData;

  @Prop() smsTableLoading!: boolean;
}
