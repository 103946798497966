import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ViewDate from '../../forms/date';

export interface RepairDiscountAdditionRowVM {
  id: number;
  is_discount: boolean;
  name: string;
  type: number;
  size: number;
  sum: number;
}

export interface RepairPaymentRowVM {
  id: number;
  sum: number;
  cashbox_id: number;
  cashbox_name: string;
  is_cashbox_cashless: boolean;
  is_prepayment: boolean;
  created_at: number;
}

@Component({
  template: require('./payments.html'),
  components: {
    'view-date': ViewDate,
  },
})

export class RepairViewPayments extends Vue {
    @Getter user;

    @Prop() statusId!: number;

    @Prop() discountAdditionsList!: RepairDiscountAdditionRowVM[];

    @Prop() paymentsList!: RepairPaymentRowVM[];

    @Prop() onCreatePayment!: Function;

    @Prop() onCreatePayReturn!: Function;

    @Prop() onDeletePayment!: Function;

    @Prop() onCreateDiscount!: Function;

    @Prop() onCreateAddition!: Function;

    @Prop() onDeleteDiscount!: Function;

    @Prop() onDeleteAddition!: Function;

    @Action showSuccessPush;

    @Action showErrorPush;

    get rightsUserCanRemoveClosedRepairPayment(): boolean {
      return this.user.rights ? this.user.rights.can_remove_closed_repair_payment : false;
    }

    /**
     * Обработчик клика по кнопке добавления оплаты.
     * Пробрасывает вызов в родительский компонент.
     */
    createPaymentClick() {
      if (this.onCreatePayment !== null) {
        this.onCreatePayment();
      }
    }

    /**
     * Обработчик клика по кнопке добавления возврата.
     * Пробрасывает вызов в родительский компонент.
     */
    createReturnClick() {
      if (this.onCreatePayReturn !== null) {
        this.onCreatePayReturn();
      }
    }

    /**
     * Обработчик клика по кнопке удаления оплаты / возврата.
     * Пробрасывает вызов в родительский компонент.
     */
    deletePaymentClick(id: number) {
      if (this.onDeletePayment !== null) {
        this.onDeletePayment(id);
      }
    }

    /**
     * Обработчик клика по кнопке добавления скидки.
     * Пробрасывает вызов в родительский компонент.
     */
    createDiscountClick() {
      if (this.onCreateDiscount !== null) {
        this.onCreateDiscount();
      }
    }

    /**
     * Обработчик клика по кнопке добавления наценки.
     * Пробрасывает вызов в родительский компонент.
     */
    createAdditionClick() {
      if (this.onCreateAddition !== null) {
        this.onCreateAddition();
      }
    }

    /**
     * Обработчик клика по кнопке удаления скидки или наценки.
     * Пробрасывает вызов в родительский компонент.
     */
    deleteDiscountOrAdditionClick(isDiscount: boolean, id: number) {
      if (isDiscount) {
        if (this.onDeleteDiscount !== null) {
          this.onDeleteDiscount(id);
        }
      } else if (this.onDeleteAddition !== null) {
        this.onDeleteAddition(id);
      }
    }

    outputTitlePayments = (item: RepairPaymentRowVM) => {
      const title = item.is_prepayment ? 'Предоплата' : 'Оплата';
      return `${title}`;
    }
}
