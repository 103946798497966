import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  template: require('./index.html'),
})
export default class CashTypesPage extends Vue {
    @Getter btnloader;

    @Getter cashTypeUser;

    @Getter cashTypeDefault;

    @Getter cashTypePageCount;

    @Action addBreadcrumb;

    @Action getCashTypeList;

    @Action sendCashType;

    @Action changeCashType;

    @Action deleteCashType;

    loading = true;

    newItem: object = { name: '', increase: true };

    editItem: object = {};

    dialogEdit = false;

    dialogDelete = false;

    beforeMount() {
      this.getCashTypeList(1);
    }

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: '/settings' },
        { id: 2, section: 'Справочники', link: null },
        { id: 3, section: 'Кассовые операции', link: null },
      ]);
      document.title = 'Кассовые операции';
    }

    loadPage(val) {
      this.getCashTypeList(val);
    }

    clearForm() {
      this.newItem = { name: '', increase: true };
      this.editItem = {};
    }

    cashTypeAddFormReset() { (this.$refs.cashTypeAdd as Form).resetFields(); }

    cashTypeEditFormReset() {
      if (this.$refs.cashTypeEdit !== undefined) (this.$refs.cashTypeEdit as Form).resetFields();
    }

    onSendCashType(formName) {
      const refForm = this.$refs[formName] as Form;
      refForm.validate((valid) => (valid ? this.sendCashType(this.newItem) : false));
    }

    onChangeCashType(formName) {
      const refForm = this.$refs[formName] as Form;
      refForm.validate((valid) => (valid ? this.changeCashType(this.editItem) : false));
    }

    onDialogEdit(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.dialogEdit = !this.dialogEdit;
    }

    onDialogDelete(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.dialogDelete = !this.dialogDelete;
    }

    @Watch('cashTypeUser')
    onLoad() {
      this.$nextTick(() => {
        this.loading = false;
      });
      this.dialogEdit = false;
      this.clearForm();
      this.cashTypeAddFormReset();
      this.cashTypeEditFormReset();
    }
}
