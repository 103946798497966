import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { User, LogRequest } from '@/types';
import LogRequestProvider from '@/api/providers/log-request';
import UserInputAutocomplete from '@/components/user-input-autocomplete';
import { RepairClient } from '../../interface';

@Component({
  template: require('./index.html'),
  components: {
    UserInputAutocomplete,
  },
})

export default class RepairFormClient extends Vue {
    @Prop() client!: RepairClient;

    @Prop() clientCheckBlackList!: object[];

    @Getter user!: User;

    @Getter company;

    flags_dicsount_type = true;

    get canCreateClients() {
      return this.user.rights && this.user.rights.can_create_clients;
    }

    get repairClient() {
      return this.client;
    }

    get dataVerifyClientOnBlacklist() {
      return this.clientCheckBlackList;
    }

    get typeNameClient() {
      return this.repairClient.name === null || this.repairClient.name === '';
    }

    get nameClient() {
      if (this.typeNameClient) {
        const client = this.repairClient;

        const lastName: string = client.last_name !== null ? client.last_name : '';
        const firstName: string = client.first_name !== null ? client.first_name : '';
        const middleName: string = client.middle_name !== null ? client.middle_name : '';

        return `${lastName} ${firstName} ${middleName}`;
      }
      return this.repairClient.name;
    }

    get phoneClient() {
      return this.repairClient.contact_phone === null || this.repairClient.contact_phone === ''
        ? this.repairClient.phone
        : this.repairClient.contact_phone;
    }

    get statusClient() {
      if (this.repairClient.status !== null) {
        switch (this.repairClient.status) {
          case 1: return 'Рядовой клиент';
          case 2: return 'Ваш любимый клиент';
          default: return 'Клиент в чёрном списке';
        }
      }
      return '';
    }

    get typeDiscount() {
      const { repair_discount_type } = this.repairClient;
      const { repair_discount } = this.repairClient;

      if (repair_discount_type !== null && repair_discount !== 0) {
        switch (repair_discount_type) {
          case 1: return `Персональная скидка  на ремонт  ${this.repairClient.repair_discount}% от полной стоимости`;
          case 2: return `Персональная скидка на ремонт ${this.repairClient.repair_discount}% от стоимости услуг`;
          default: break;
        }
      } else {
        this.flags_dicsount_type = false;
      }
      return '';
    }

    sendLog(logName: string) {
      const now: number = new Date().getTime();
      const name = logName;
      const date = now;
      const payload = {};
      const userId = this.user.id ?? null;
      const serviceId = this.company.id ?? null;

      const log: LogRequest = {
        name,
        date,
        payload,
        userId,
        serviceId,
      };
      LogRequestProvider.req(log);
    }

    chooseAnotherUser() {
      const emptyClient: RepairClient = {
        name: '',
        contact_phone: null,
        contact_name: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        phone: null,
        status: null,
        address: null,
        repair_discount_type: 0,
        repair_discount: 0,
        id: null,
      };

      this.sendLog('CLICK_OPEN_EDIT_MODAL_CLIENT');

      this.$emit('updateClient', emptyClient);
    }

    openModalClientList() {
      const name = 'CLICK_OPEN_MODAL_CLIENT_LIST';
      this.sendLog(name);

      this.$emit('openModalClientList', true);
    }

    openModalCreateClient() {
      const name = 'CLICK_MODAL_CREATE_CLIENT';
      this.sendLog(name);

      this.$emit('openModalCreateClient', true);
    }

    editClient() {
      this.$emit('openModalEditClient', true);
    }

    @Watch('client')
    viewClients(client) {
      this.$emit('updateClient', client);
      this.$emit('openModalEditClient', false);
    }
}
