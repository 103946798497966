import { ActionTree } from 'vuex';
import { Point, Response } from '@/types';
import PointProvider from '../../api/providers/point';
import { StatePoint } from './types';

const actions: ActionTree<StatePoint, object> = {

  async getPointList({ commit }, item) {
    try {
      let results: Point[] = [];
      let { page } = item;
      const firstRespond = await PointProvider.getPointList(page);
      results = firstRespond.data as Point[];
      const { pageCount } = firstRespond;
      if (pageCount > 1) {
        page += 1;
        const promiseResults: object[] = [];

        for (page; page <= pageCount; page++) {
          promiseResults.push(PointProvider.getPointList(page));
        }

        await Promise.all(promiseResults)
          .then((Responses) => {
            (Responses as Response[]).forEach((res) => {
              results = [...results, ...res.data as Point[]];
            });
          });
      }

      commit('GET_POINT', {
        item: results,
        pageCount,
        pageCurrent: page,
      });
    } catch (err) {
      throw new Error(err);
    }
  },

  getPointListOnThePage({ commit }, item) {
    PointProvider.getPointList(item.page)
      .then((res) => commit('GET_POINT', {
        item: res.data,
        pageCount: res.pageCount,
        pageCurrent: res.currentPage,
      }))
      .catch((err) => console.error(err));
  },

  getViewPoint({ commit }, id) {
    PointProvider.getViewPoint(id)
      .then((res) => commit('GET_VIEW_POINT', { item: res.data }))
      .catch((err) => console.error(err));
  },

  sendPoint({ commit }, item) {
    commit('BTN_LOADER', true);
    PointProvider.sendPoint(item)
      .then((res) => {
        commit('INSERT_POINT', { item: res.data });
        commit('GET_VALID_POINT', { valid: [] });
        commit('PUSH_CALL_SUCCESS', { title: `Филиал «${item.name}» успешно добавлен`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('GET_VALID_POINT', { valid: err.response.data });
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  changePoint({ commit }, item) {
    commit('BTN_LOADER', true);
    PointProvider.changePoint(item.id, item)
      .then((res) => {
        commit('EDIT_POINT', { item: res.data });
        commit('GET_VALID_POINT', { valid: [] });
        commit('PUSH_CALL_SUCCESS', { title: `Филиал «${item.name}» успешно изменён`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('GET_VALID_POINT', { valid: err.response.data });
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  deletePoint({ commit }, id) {
    commit('BTN_LOADER', true);
    PointProvider.deletePoint(id)
      .then((res) => {
        commit('DELETE_POINT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Филиал «${res.data.name}» успешно удалён`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },
};

export default actions;
