import { MutationTree } from 'vuex';
import {
  AUTH_ERROR, AUTH_IN,
  AUTH_OUT,

  StateAuth,
} from './types';

const mutations: MutationTree<StateAuth> = {

  [AUTH_IN](state, { saveUser, auth, item }) {
    localStorage.setItem('access', item.access_token);
    localStorage.setItem('refresh', item.refresh_token);
    localStorage.setItem('expires_in', item.expiration_in);
    localStorage.setItem('lastSession', String(Math.round(new Date().getTime() / 1000)));
    if (saveUser) {
      localStorage.setItem('saveUser', 'true');
    } else {
      const intervalId = setInterval(() => {
        localStorage.setItem('lastSession', String(Math.round(new Date().getTime() / 1000)));
      }, 1000);
      localStorage.setItem('saveUser', 'false');
      localStorage.setItem('intervalId', String(intervalId));
    }
    state.auth = auth;
  },

  [AUTH_ERROR](state, { item }) {
    state.authError = item;
  },

  [AUTH_OUT](state, item) {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('saveUser');
    localStorage.removeItem('lastSession');
    localStorage.removeItem('intervalId');
    state.auth = item;
  },

};

export default mutations;
