import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CashProvider from '../../../../../../api/providers/cash';

@Component({
  template: require('./index.html'),
  components: {},
})

export default class FieldPrepayment extends Vue {
    @Prop({ required: true }) field!: object;

    @Prop({ required: true }) prepayment!: object;

    @Getter user;

    cash_list_page_count = 1;

    cash_list: object[] = [];

    local_prepayment: object = this.prepayment;

    // api

    getCashList() {
      CashProvider.getAllCashes()
        .then((res) => {
          this.cash_list = res;

          if (res.length !== 0) {
            const filterCashListOnPoint = res.find(
              (cash) => cash.point_id === this.user.point_id,
            );

            if (filterCashListOnPoint) {
              this.$root.$emit('idCashBox', filterCashListOnPoint.id);
            }
          }
        })
        .catch((err) => console.error(err));
    }

    mounted() {
      this.getCashList();
    }

    selectionAmountForPrepayment(prepayment) {
      this.$root.$emit('selectionAmountForPrepayment', prepayment);
    }

    loadingPrepaymentData(prepayment) {
      this.local_prepayment = prepayment;
    }
}
