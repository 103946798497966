import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb } from '@/types';
import ViewDate from '../forms/date';
import Numbers from '../forms/numbers';

@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    date: ViewDate,
  },
})
export default class Payments extends Vue {
    @Action getPaymentList;

    @Action addBreadcrumb;

    @Action addActionBtn;

    @Getter paymentList;

    @Getter paymentListPageCount;

    page = 1;

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Биллинг', link: null },
      { id: 3, section: 'Платежи', link: null },
    ];

    loading = true;

    mounted() {
      this.addBreadcrumb(this.breadcrumb);
      if (this.$route.params.page) {
        this.page = Number(this.$route.params.page);
      }
      this.loadPage(this.page);
      document.title = 'Платежи';
    }

    destroyed() {
      this.addActionBtn([]);
    }

    loadPage(val) {
      this.loading = true;
      this.$router.push(`/service/billing/payments/${val}`);
      this.getPaymentList(val);
    }

    @Watch('$route.params')
    reloadPage() {
      if (this.$route.params.page) {
        this.page = Number(this.$route.params.page);
        this.loadPage(this.page);
      }
    }

    @Watch('paymentList')
    onLoad() {
      window.scroll(0, 0);
      this.loading = false;
    }
}
