import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Numbers from '../forms/numbers';
import SalaryAmountAndPeriodType from '../salary-amount-and-period-type';
import SalaryByShares from '../salary-by-shares';

@Component({
  template: require('./index.html'),
  components: {
    SalaryAmountAndPeriodType,
    SalaryByShares,
    Numbers,
  },
})

export default class SalaryTable extends Vue {
    @Prop(Array) data;

    @Prop(Boolean) loading;

    onView(row) {
      if ((window.getSelection() as Selection).toString().trim().length !== 0) return;
      this.$router.push(`/salary/current/${row.id}/1`);
    }

    summaryMethod = ({ columns, data }) => {
      /* eslint-disable-next-line no-param-reassign */
      const total_sum = data.reduce((prev, curr) => prev += curr.current_salary_balance, 0);
      const result = new Intl.NumberFormat('ru-RU', {
        maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'RUB',
      })
        .format(Number(total_sum));
      const out: object = [];
      out[0] = 'Итого:';
      out[columns.length - 1] = result;
      return out;
    }
}
