import BaseProvider from '../base-provider';

class EquipmentOptionProvider extends BaseProvider {
  async getEquipmentOptionList(page) {
    const res = await this.request('GET', `/equipmentoption/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendEquipmentOptionList(item) {
    const data: object = { EquipmentOption: item };
    const res = await this.request('POST', '/equipmentoption/add', data);
    return res;
  }

  async sendEquipmentOptionSetPrioroty(item) {
    const data: object = { PriorityForm: item };
    const res = await this.request('POST', '/equipmentoption/setpriority', data);
    return res;
  }

  async changeEquipmentOptionList(item) {
    const data: object = { EquipmentOption: item };
    const res = await this.request('POST', `/equipmentoption/update?id=${item.id}`, data);
    return res;
  }

  async deleteEquipmentOptionList(item) {
    const data: object = { EquipmentOption: item };
    const res = await this.request('POST', `/equipmentoption/delete?id=${item.id}`, data);
    return res;
  }
}

export default new EquipmentOptionProvider();
