import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb, ActionBtn, User } from '@/types';
import ClientsProvider from '../../api/providers/clients';
import SellProvider from '../../api/providers/sell';
import StuffProvider from '../../api/providers/stuff';
import ErrorAccessRights from '../error-access-rights';
import Numbers from '../forms/numbers';

interface Search {
  id: string;
  seller_id: string;
  status_id: string;
  client_id: string;
  sold_at_start: string | number;
  sold_at_end: string | number;
  page: number;
  part_serial: string;
  'per-page': number;
}

@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    'error-access-rights': ErrorAccessRights,
  },
})

export default class Sell extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Getter user;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Магазин', link: null }, { id: 2, section: 'Продажи магазина', link: null }];

    actionBtn: ActionBtn[] = [{
      id: 1, type: 'add', title: 'Новая продажа', link: '/sell/add', icon: 'fa-plus',
    }];

    search: Search = {
      id: '',
      seller_id: '',
      status_id: '',
      client_id: '',
      sold_at_start: '',
      sold_at_end: '',
      page: 1,
      part_serial: '',
      'per-page': 20,
    };

    loading_clients = false;

    date_value: number[] = [];

    sell_list: object[] = [];

    sell_list_page_count: number | null = null;

    loading_sell_list = false;

    stuff_list: object[] = [];

    client: object = {};

    range_date: object = {
      shortcuts: [
        {
          text: 'За вчера',
          onClick(picker) {
            const end = new Date();
            end.setHours(23, 59, 59, 0);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: 'За неделю',
          onClick(picker) {
            const end = new Date();
            end.setHours(23, 59, 59, 0);
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: 'За месяц',
          onClick(picker) {
            const end = new Date();
            end.setHours(23, 59, 59, 0);
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: 'За 3 месяца',
          onClick(picker) {
            const end = new Date();
            end.setHours(23, 59, 59, 0);
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        },
      ],
    };

    // api

    filterClient(item) {
      ClientsProvider.filterClient(item.page, item)
        .then((res) => {
          this.loading_clients = false;
          this.client = res.data;
        }).catch((err) => console.error(err));
    }

    getSellList(item) {
      this.loading_sell_list = true;
      SellProvider.getSellList(item.page, item, item['per-page'])
        .then((res) => {
          this.loading_sell_list = false;
          this.sell_list = res.data;
          this.sell_list_page_count = Number(res.pageCount);
        }).catch((err) => {
          console.error(err);
          this.loading_sell_list = false;
        });
    }

    getStuffList() {
      StuffProvider.getFullStuffList()
        .then((res) => {
          this.stuff_list = res;
        }).catch((err) => console.error(err));
    }

    // end api

    get rightsUserCanCreateSell() {
      return this.user.rights ? this.user.rights.can_create_sell : false;
    }

    setMaxWidthScrollBar = () => {
      const dropdowns: HTMLElement[] = Array.from(document.getElementsByClassName('el-select-dropdown el-popper')) as HTMLElement[];
      dropdowns.forEach((element, i) => dropdowns[i].style['max-width'] = '100px');
    }

    formattingUsers = (user: User) => {
      if (user) {
        const lastName: string = user.last_name ? user.last_name : '';
        const firstName: string = user.first_name ? `${user.first_name.slice(0, 1).toUpperCase()}.` : '';
        const middleName: string = user.middle_name ? `${user.middle_name.slice(0, 1).toUpperCase()}.` : '';
        return `${lastName} ${firstName} ${middleName}`;
      }
      return '-';
    }

    queryNamesСlient(query) {
      this.loading_clients = true;
      this.setMaxWidthScrollBar();
      this.filterClient({ page: 1, name: query });
    }

    formatNameClients = (client) => (client.name ? client.name : `${client.last_name} ${client.first_name} ${client.middle_name}`)

    clearFilterSell() {
      this.search = {
        id: '',
        seller_id: '',
        status_id: '',
        client_id: '',
        sold_at_start: '',
        sold_at_end: '',
        page: 1,
        part_serial: '',
        'per-page': 20,
      };
      this.date_value = [];
      this.searchSell();
    }

    searchSell() {
      Object.keys(this.search).forEach((key) => {
        if (this.search[key] === null) this.search[key] = '';
      });
      if (this.date_value && this.date_value.length !== 0) {
        const dateStart = new Date(this.date_value[0]);
        dateStart.setHours(0, 0, 0, 0);
        this.search.sold_at_start = Math.round(dateStart.getTime() / 1000);
        const dateEnd = new Date(this.date_value[1]);
        dateEnd.setHours(23, 59, 59, 0);
        this.search.sold_at_end = Math.round(dateEnd.getTime() / 1000);
      }
      this.getSellList(this.search);
    }

    mounted() {
      document.title = 'Продажи магазина';
      this.getSellList(this.search);
      this.getStuffList();
      this.addBreadcrumb(this.breadcrumb);
      if (this.user.rights && this.user.rights.can_create_sell) this.addActionBtn(this.actionBtn);
      this.filterClient({ page: 1, name: '' });
    }

    destroyed() {
      this.addActionBtn([]);
    }

    get shopList() {
      return Array.isArray(this.sell_list) ? this.sell_list : [];
    }

    currentUser = (user) => {
      const lastName: string = user.last_name ? user.last_name : '';
      const firstName: string = user.first_name ? user.first_name : '';
      const middleName: string = user.middle_name ? user.middle_name : '';
      return `${lastName} ${firstName} ${middleName}`;
    }

    date = (value) => {
      const options: object = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
      };
      const date: string = new Date(value * 1000).toLocaleString('ru-RU', options).replace(',', '');
      return date;
    }

    storageName = (val) => {
      const obj = {};
      val.forEach((i) => {
        obj[i.storage.id] = i.storage.name;
      });
      return Object.keys(obj).map((i) => obj[i]);
    }

    paymentsSum = (val, key) => (val.length ? val.reduce((sum, item) => sum + item[key], 0) : 0)

    loadPage(val) {
      this.search.page = val;
      this.searchSell();
      window.scroll(0, 0);
    }

    onDetail(row, _, event) {
      if ((window.getSelection() as Selection).toString().trim().length !== 0) return;
      if (event.ctrlKey) {
        this.targetBlankDetail(row.id);
        return;
      }
      this.$router.push(`/sell/view/${row.id}`);
    }

    targetBlankDetail(sellId) {
      const route_data = this.$router.resolve({ name: 'sell-view', params: { id: sellId } });
      window.open(route_data.href, '_blank');
    }

    @Watch('user')
    meUser(user: User) {
      if (user.rights) {
        if (user.rights.can_create_sell) {
          this.addActionBtn(this.actionBtn);
        } else {
          this.addActionBtn([]);
        }
      } else {
        this.addActionBtn([]);
      }
    }
}
