import { MutationTree } from 'vuex';
import {
  EDIT_SMS_TEMPLATE, GET_SMS_TEMPLATE,

  StateSmsTemplate,
} from './types';

const mutations: MutationTree<StateSmsTemplate> = {

  [GET_SMS_TEMPLATE](state, item) {
    state.smsTemplate = item;
  },

  [EDIT_SMS_TEMPLATE](state, item) {
    state.smsTemplate = item;
  },

};

export default mutations;
