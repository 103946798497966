// Mutations Type

import { Cancel } from '@/types';

export const GET_CANCEL = 'GET_CANCEL';
export const GET_VIEW_CANCEL = 'GET_VIEW_CANCEL';
export const GET_PAGE_CANCEL = 'GET_PAGE_CANCEL';
export const CANCEL_REVERT_PART = 'CANCEL_REVERT_PART';
export const CANCEL_VALIDATION = 'CANCEL_VALIDATION';
export const SEND_CANCEL = 'SEND_CANCEL';
export const REVERT_CANCEL = 'REVERT_CANCEL';

export const SEND_CANCEL_ITEM = 'SEND_CANCEL_ITEM';
export const DELETE_CANCEL_ITEM = 'DELETE_CANCEL_ITEM';
export const CLEAR_CANCEL_ITEM = 'CLEAR_CANCEL_ITEM';
// Store

export interface StateCancel {
    cancel: object[];
    cancelNewItemList: Cancel[];
    revertCancel: object;
    applyCancel: object;
    cancelView: object;
    cancelPageCurrent: number;
    cancelPageCount: number;
    cancelValid: object[];
}
