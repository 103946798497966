const state = {
  company: {},
  billing: {},
  settings: {},

  countryId: [
    { id: 1, label: 'Россия' },
    { id: 2, label: 'Беларусь' },
    { id: 3, label: 'Украина' },
    { id: 4, label: 'Казахстан' },
    { id: 5, label: 'Грузия' },
    { id: 6, label: 'Армения' },
    { id: 7, label: 'Узбекистан' },
    { id: 8, label: 'Киргизия' },
    { id: 9, label: 'Латвия' },
    { id: 10, label: 'Литва' },
    { id: 11, label: 'Эстония' },
  ],
};

export default state;
