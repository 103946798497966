import { Form } from 'element-ui';
import Vue from 'vue';
import VueBarcode from 'vue-barcode';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  Breadcrumb, PartForm as PartFormType, Photo, Barcode, PartAmountCellForm,
} from '@/types';
import PartProvider from '@/api/providers/part';
import PartCellAmount from '@/components/part-cell-amount';
import PartForm from '../../components/part-form';
import PartLog from '../../components/part-log';

interface Image {
  part_id?;
  file?: File;
}
@Component({
  template: require('./index.html'),
  components: {
    'part-form': PartForm,
    'part-log': PartLog,
    'part-cell-amount': PartCellAmount,
    barcode: VueBarcode,
  },
})
export default class PartEditPage extends Vue {
    @Action addBreadcrumb;

    @Action getViewPart;

    @Action changePart;

    @Action sendPartImage;

    @Action deleteBarcode;

    @Action getBarcode;

    @Action sendBarcode;

    @Getter viewPart;

    @Getter btnloader;

    @Getter user;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Изменение товара', link: null }];

    validation: object = {};

    pic: Photo = {};

    loading = true;

    dialog = false;

    barcode: object = {};

    barcodes: Barcode[] = [];

    activeName = 'form';

    new_item: PartFormType = {};

    get rightsUserCanEditDeletePartsAndCategories() {
      return this.user.rights ? this.user.rights.can_edit_delete_parts_and_categories : false;
    }

    get isPartDeleted(): boolean {
      return this.new_item?.enabled === false;
    }

    mounted() {
      if (!this.rightsUserCanEditDeletePartsAndCategories) {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Вам не разрешено изменять и удалять товары', item: '' });
        this.$router.push({ name: 'nomenklatura' });
      }
      this.getViewPart(this.$route.params.id);
      this.addBreadcrumb(this.breadcrumb);
      if (this.$route.params.tab === 'logs') {
        this.activeName = 'logs';
      }
      document.title = 'Изменение товара';
    }

    formattingEmptyFields() {
      if (this.new_item.minimum_amount === undefined || this.new_item.minimum_amount === '') {
        delete this.new_item.minimum_amount;
        this.new_item.minimum_amount = null;
      }

      if (this.new_item.sell_reward_size === undefined || this.new_item.sell_reward_size === '') {
        delete this.new_item.sell_reward_size;
        this.new_item.sell_reward_size = null;
      }

      if (this.new_item.repair_reward_size === undefined || this.new_item.repair_reward_size === '') {
        delete this.new_item.repair_reward_size;
        this.new_item.repair_reward_size = null;
      }
    }

    edit() {
      ((this.$refs.form as PartForm).viewForm as Form).validate((valid) => {
        if (valid) {
          this.formattingEmptyFields();

          console.log(this.new_item);
          this.changePart(this.new_item)
            .then((res) => {
              const part_id = res.data.id;
              (this.$refs.partCells as PartCellAmount).partAmountTableItem.forEach((elem) => {
                const partAmount: PartAmountCellForm = {
                  part_id,
                  storage_id: elem.storage.id,
                  cell: elem.cell,
                };
                PartProvider.setPartAmount(partAmount);
              });

              if (Object.keys(this.pic).length) {
                const image: Image = {};
                image.part_id = res.data.id;
                image.file = this.pic.file;
                this.sendPartImage(image);
              }
              if (this.barcodes.length) this.checkBarcode();
              this.$router.back();
            })
            .catch((err) => {
              this.validation = err.response.data;
            });
          return true;
        }
        return false;
      });
    }

    async checkBarcode() {
      let i = 0;
      while (i < this.barcodes.length) {
        const item = this.barcodes[i];
        if (item.auto_generated) {
          this.autoGenerateBarcode();
          i += 1;
        } else {
          this.addBarcode(item.value);
          i += 1;
        }
      }
    }

    async autoGenerateBarcode() {
      const res = await this.getBarcode();
      await this.addBarcode(String(res.data));
    }

    async addBarcode(value) {
      const newItem: object = { part_id: this.viewPart.id, value };
      await this.sendBarcode(newItem);
    }

    onDialogDelete(item) {
      this.barcode = item;
      this.dialog = true;
    }

    @Watch('viewPart')
    onLoad(val: PartFormType) {
      this.new_item = val;
      if (val.minimum_amount === null || val.minimum_amount === '') this.new_item.minimum_amount = undefined;
      if (val.sell_reward_size === null || val.sell_reward_size === '') this.new_item.sell_reward_size = undefined;
      if (val.repair_reward_size === null || val.repair_reward_size === '') this.new_item.repair_reward_size = undefined;

      this.$nextTick(() => {
        this.loading = false;
      });
    }

    @Watch('activeName')
    changinTab() {
      if (this.activeName === 'logs') {
        this.$router.push({ name: 'part-edit', params: { tab: 'logs', id: this.$route.params.id } });
      } else {
        this.$router.push(`/part/edit/${this.$route.params.id}`);
      }
    }
}
