import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import Vue from 'vue';
import linkify from 'vue-linkify';
import vClickOutside from 'v-click-outside';

import VueTippy from 'vue-tippy';
import App from './App';
import routers from './router';
import store from './store';
// ** Styles **
import './style/main.scss';

Vue.directive('linkified', linkify);
Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(VueTippy, {
  arrow: true,
  animation: 'scale',
  size: 'small',
});
Vue.use(vClickOutside);
new Vue({
  el: '#app',
  store,
  router: routers,
  render: (h) => h(App),
}).$mount('#app');
