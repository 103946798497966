import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import PartProvider from '../../api/providers/part';

interface Filter {
  page: number;
  name: string;
  barcode: string;
  art: string;
  include_internal: boolean;
  only_positive: boolean;
  include_deleted: boolean;
}
@Component({
  template: require('./index.html'),
})

export default class PartFilter extends Vue {
    include_internal = false;

    only_positive = false;

    visible = false;

    include_deleted = false;

    loading = false;

    @Prop({ default: () => ({}) }) filter!: Filter;

    getPartList(item) {
      this.loading = true;

      PartProvider.getPartList(item.page, item, item['per-page'])
        .then((res) => {
          this.$root.$emit('filterParts', res.data);

          this.loading = false;
        }).catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }

    onFilter(loader) {
      this.loading = loader;
      this.filter.page = 1;
      this.getPartList(this.filter);

      const dataFilter = {
        name: this.filter.name,
        barcode: this.filter.barcode,
        art: this.filter.art,
        page: 1,
        include_internal: this.filter.include_internal,
        include_deleted: this.filter.include_deleted,
        only_positive: this.filter.only_positive,
      };
      this.$root.$emit('filterLoading', { loading: true, data: dataFilter });
    }

    mounted() {
      this.syncProp();
    }

    syncProp() {
      console.log(this.filter);
      this.only_positive = this.filter.only_positive;
      this.include_internal = this.filter.include_internal;
      this.include_deleted = this.filter.include_deleted;
    }

    onClearFilter() {
      this.filter.name = '';
      this.filter.barcode = '';
      this.filter.art = '';
      this.filter.include_internal = false;
      this.onFilter(false);
    }

    @Watch('include_internal')
    onChange(val: boolean) {
      this.filter.include_internal = val;
    }

    @Watch('only_positive')
    onChangePositive(val: boolean) {
      this.filter.only_positive = val;
    }

    @Watch('include_deleted')
    onChangeDeleted(val: boolean) {
      this.filter.include_deleted = val;
    }

  @Watch('filter.include_internal')
    onChangePositiveProp() {
      this.include_internal = this.filter.include_internal;
    }

  @Watch('filter.only_positive')
  onChangeDeletedProp() {
    this.only_positive = this.filter.only_positive;
  }

  @Watch('filter.include_deleted')
  onChangeFilterProp() {
    this.include_deleted = this.filter.include_deleted;
  }
}
