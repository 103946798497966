import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  User, Breadcrumb, ActionBtn, Part, NavigationPage,
} from '@/types';
import DocumentProvider from '../../api/providers/document';
import PartProvider from '../../api/providers/part';
import ErrorAccessRights from '../../components/error-access-rights';
import NomenklaturaItem from '../../components/nomenklatura-item';
import PartNav from '../../components/part-nav';
import Category from '../../components/__category';
import Content from './types';

@Component({
  template: require('./index.html'),
  components: {
    'category-tree': Category,
    'part-nav': PartNav,
    'nomenklatura-item': NomenklaturaItem,
    'error-access-rights': ErrorAccessRights,
  },
})

export default class NomenklaturaPage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action getPartCategory;

    @Action clearListCategories;

    @Getter categoryList;

    @Getter user!: User;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Номенклатура', link: null }];

    actionBtn: ActionBtn[] = [{
      id: 1, type: 'add', title: 'Новый товар', link: '/part/add', icon: 'fa-plus',
    }];

  content: Content = {
    page: 1,
    name: '',
    barcode: '',
    art: '',
    category_id: 1,
    include_internal: false,
    include_deleted: false,
    only_positive: false,
    amount_control: 0,
    expand: 'amounts,posting_price',
    'per-page': 20,
  };

    navigation_page: NavigationPage = {};

    loading_part = true;

    loading_page = false;

    loading_category = true;

    flag_active_collapse = true;

    visible_image_part_on_hover = false;

    private stickerDocumentId!: number;

    part_list: Part[] = [];

    part_list_page_count: number | null = null;

    part_list_page_current: number | null = null;

    reward_type_list: object[] = [];

    vat_list: object[] = [];

    head_nomenkl: object = {
      number: true,
      name: true,
      price: [
        { name: 'repair', title: 'Ремонт', active: true },
        { name: 'shop', title: 'Продажа', active: true },
      ],
      reward: [
        { name: 'repair', title: 'Ремонт', active: true },
        { name: 'shop', title: 'Продажа', active: true },
      ],
      barcode: true,
      article: true,
      vat: true,
    };

    partRaceCondition = 0;

    // api
    getPartList(item) {
      const raceCondition = this.getRandomRaceCondition();
      this.partRaceCondition = raceCondition;
      setTimeout(() => {
        if (this.partRaceCondition === raceCondition) {
          PartProvider.getPartList(item.page, item, item['per-page'])
            .then((res) => {
              this.part_list = res.data;
              this.part_list_page_count = Number(res.pageCount);
              this.part_list_page_current = Number(res.pageCurrent);

              this.loading_page = false;
              this.loading_part = false;

              const localStor = JSON.parse(localStorage.getItem('headNomenkl') as string);
              if (localStor) this.head_nomenkl = localStor;
            }).catch((err) => {
              console.error(err);
              this.loading_page = false;
              this.loading_part = false;
            });
        }
      }, 500);
    }

    getRandomRaceCondition() {
      let string = '';
      for (let i = 0; i < 10; i += 1) {
        string += this.getRandomString();
      }
      return Number(string);
    }

    getRandomString = () => {
      const random = `${Math.floor(Math.random() * 10)}`;
      return random;
    }

    getRewardTypeList() {
      PartProvider.getRewardTypeList()
        .then((res) => {
          const rewardTypeList: object[] = [];

          Object.keys(res.data).forEach((j, i) => {
            if (i) {
              rewardTypeList[Number(i) - 1] = { id: Number(i), name: res.data[i] };
            }
          });

          this.reward_type_list = rewardTypeList;
        })
        .catch((err) => console.error(err));
    }

    getVatList() {
      PartProvider.getVatList()
        .then((res) => {
          const vatlist: object[] = [];

          Object.keys(res.data).forEach((i) => {
            if (i) {
              vatlist[Number(i) - 1] = { id: Number(i), name: res.data[i] };
            }
          });

          this.vat_list = vatlist;
        }).catch((err) => console.error(err));
    }

    mounted() {
      const { query } = this.$route;

      document.title = 'Номенклатура';
      this.loadingPageByRoute();
      this.getRewardTypeList();
      this.getVatList();
      this.getPartCategory('part');

      if ('category_id' in query) {
        this.actionBtn[0].link = { path: '/part/add', query: { category_id: query.category_id } };
      }

      if (this.user.rights
        && this.user.rights.can_create_parts_and_categories) this.addActionBtn(this.actionBtn);
      this.addBreadcrumb(this.breadcrumb);

      this.changeCategory();
      this.filterLoading();

      DocumentProvider.getDocumentList(1, { type: 8 }, 20)
        .then((resp) => {
          if (resp.data && resp.data.length > 0) {
            this.stickerDocumentId = resp.data[0].id;
          } else {
            console.warn('Не нашли стикер для товара на сервере');
          }
        }).catch((err) => {
          console.error(err);
        });

      this.filterParts();
    }

    get rightsUser() {
      return this.user.rights ? this.user.rights.can_create_parts_and_categories : false;
    }

    get formatUrlWithCategory() {
      const { query } = this.$route;

      return 'category_id' in query ? { path: '/part/add', query: { category_id: query.category_id } } : '/part/add';
    }

    destroyed() {
      this.addActionBtn([]);
      this.content.page = 1;
      this.clearListCategories();
      this.$root.$off('categoryItemActive');
    }

    filterLoading() {
      this.$root.$on('filterLoading', ({ loading, data }) => {
        const empty_fields: string [] = [];
        this.loading_part = loading;
        Object.keys(data).forEach((key) => {
          if (data[key]) {
            this.navigation_page[key] = data[key];
          } else {
            delete this.navigation_page[key];
            empty_fields.push(key);
          }
        });

        this.methodAddRouteNavigation();
      });
    }

    copyPart(part, id) {
      const index = this.part_list.findIndex((item) => item.id === id);
      this.part_list.splice(index + 1, 0, part);
    }

    changePart(part) {
      const index = this.part_list.findIndex((i) => i.id === part.id);
      this.part_list.splice(index, 1, part);
    }

    filterParts() {
      this.$root.$on('filterParts', (parts) => {
        this.part_list = parts;
        this.loading_part = false;
      });
    }

    changeCategory() {
      this.$root.$on('categoryItemActive', (i) => {
        const val = this.content.category_id === i ? 1 : i;
        this.content.category_id = val;

        if (val === 1) {
          delete this.navigation_page.category_id;
        } else {
          this.navigation_page.category_id = val;
        }

        this.methodAddRouteNavigation();
      });
    }

    search() {
      this.loading_part = true;
      this.$emit('loadingTable', this.loading_part);
      this.getPartList(this.content);
    }

    methodAddRouteNavigation() {
      this.$router.push({ query: { ...this.navigation_page } });
    }

    loadingPageByRoute() {
      const query = Object.keys(this.$route.query);

      if (query.length !== 0) {
        query.forEach((key) => {
          this.content[key] = key === 'per-page' || key === 'category_id' ? Number(this.$route.query[key]) : this.$route.query[key];
        });
        this.navigation_page = { ...this.$route.query };

        this.content.include_internal = !!this.navigation_page.include_internal;

        this.content.only_positive = !!this.navigation_page.only_positive;

        this.content.include_deleted = !!this.navigation_page.include_deleted;
        this.search();
      } else {
        this.getPartList(this.content);
      }
    }

    columnActive = (props) => props.some((i) => i.active)

    typePerPage(count) {
      return count === this.content['per-page'] ? 'primary' : '';
    }

    changePage(val) {
      this.content.page = val;
      this.navigation_page.page = val;
      this.methodAddRouteNavigation();
      window.scroll(0, 0);
    }

    changePerPage(val) {
      this.content['per-page'] = val;
      this.navigation_page['per-page'] = val;
      this.methodAddRouteNavigation();
    }

    updatePartAtRemoval() {
      this.content.category_id = 1;
      delete this.navigation_page.category_id;
      this.methodAddRouteNavigation();
    }

    updateValueCategoryId(query) {
      if (query.category_id) {
        this.content.category_id = Number(query.category_id);
        this.navigation_page.category_id = Number(query.category_id);
      } else {
        this.content.category_id = 1;
        delete this.navigation_page.category_id;
      }
    }

    updateValuePage(query) {
      if (query.page) {
        this.content.page = Number(query.page);
        this.navigation_page.page = Number(query.page);
      } else {
        this.content.page = 1;
        delete this.navigation_page.page;
      }
    }

    updateValueSearchForm(query) {
      const form = {
        name: query.name,
        art: query.art,
        barcode: query.barcode,
      };

      const empty_fields: string [] = [];

      Object.keys(form).forEach((key) => {
        if (!form[key]) {
          delete this.navigation_page[key];
          this.content[key] = '';
          empty_fields.push(key);
        }
      });
      if (empty_fields.length === 3) {
        delete this.navigation_page.include_internal;
      }
    }

    openCategories(value) {
      this.flag_active_collapse = value.length !== 0;
    }

    printSticker(partId) {
      if (this.stickerDocumentId) {
        const domain = process.env.NODE_ENV === 'production' ? 'printing.servix.io' : 'printing-dev.servix.io';
        const token = DocumentProvider.access;
        window.open(`https://${domain}/print/document?token=${token}&documentId=${this.stickerDocumentId}&entityId=${partId}&pdf=1`, '_blank');
      } else {
        console.error('Отсутствует stickerDocumentId');
      }
    }

    @Watch('categoryList')
    listCategories() {
      this.loading_category = false;
    }

    @Watch('user')
    meUser(user: User) {
      if (user.rights) {
        if (user.rights.can_create_parts_and_categories) {
          this.addActionBtn(this.actionBtn);
        } else {
          this.addActionBtn([]);
        }
      }
    }

    @Watch('$route.query')
    viewRouteQuery(query) {
      this.updateValueCategoryId(query);
      this.updateValuePage(query);
      this.updateValueSearchForm(query);

      this.actionBtn[0].link = { path: '/part/add', query: { category_id: query.category_id } };
      if (this.user.rights
        && this.user.rights.can_create_parts_and_categories) this.addActionBtn(this.actionBtn);

      this.search();
    }
}
