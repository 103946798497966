import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Cashbox } from '@/types';
import CashProvider from '../../../../api/providers/cash';

interface FormType {
  from_cashbox_id?: number | null;
  cashbox_id?: number | null;
  comment: string;
}

@Component({
  template: require('./index.html'),
})
export default class DialogCashOperation extends Vue {
    @Getter user;

    @Getter cashTypeUserOn;

    @Getter cashTypeUserOff;

    @Prop() form!: FormType;

    @Prop() type_btn!: number;

    @Prop() title!: string;

    @Prop() cash_list!: Cashbox[];

    @Prop({ default: () => false }) view_dialog!: boolean;

    cashOperationForm: FormType = { ...this.form };

    keyForUpdate = 0;

    loading_btn = false;

    mounted() {
      this.cashOperationForm = { ...this.form };
    }

    /**
     * Возвращает список доступных для трансфера касс
     * @returns Cashbox[]
     */
    get tranferList(): Cashbox[] {
      return this.cash_list.filter(
        (cashbox) => cashbox.id !== this.cashOperationForm.from_cashbox_id,
      );
    }

    sendCashOperationIn(item) {
      this.loading_btn = true;
      CashProvider.sendCashOperationIn(item)
        .then((res) => {
          this.$emit('updateSumCashList', res.data);
          this.loading_btn = false;
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading_btn = false;
        });
    }

    sendCashOperationTransfer(item) {
      this.loading_btn = true;
      CashProvider.sendCashOperationTransfer(item)
        .then((res) => {
          this.$emit('updateSumCashList', res.data);
          this.loading_btn = false;
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading_btn = false;
        });
    }

    sendCashOperationOut(item) {
      this.loading_btn = true;
      CashProvider.sendCashOperationOut(item)
        .then((res) => {
          this.$emit('updateSumCashList', res.data);
          this.loading_btn = false;
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading_btn = false;
        });
    }

    requestOnOperationCash(formName) {
      (this.$refs[formName] as Form).validate((valid) => {
        if (valid) {
          switch (this.type_btn) {
            case 0: this.sendCashOperationIn(this.cashOperationForm); break;
            case 1: this.sendCashOperationTransfer(this.cashOperationForm); break;
            case 2: this.sendCashOperationOut(this.cashOperationForm); break;
            default: break;
          }
          return true;
        }
        return false;
      });
    }

    resetForm() {
      (this.$refs.formOperationCash as Form).resetFields();
    }

    validateSum = (rule, value, callback) => {
      if (value === null || value === undefined) {
        callback();
      } else {
        const floatVal = Number.parseFloat(value);
        if (floatVal < 0.01) {
          callback(new Error('Колличество не может быть меньше 0,01'));
        } else {
          callback();
        }
      }
    }

    updateForm = () => {
      this.keyForUpdate += 1;
    }

    @Watch('view_dialog')
    /**
     * Подгрузка данных формы
     */
    updatedPropForm() {
      this.cashOperationForm = { ...this.form };
    }
}
