import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
  props: ['type', 'value'],
})

export default class ViewDate extends Vue {
  getDateCurrent = new Date().toLocaleString('ru-RU', {
    year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
  }).replace(',', '');

  getDate = (value) => {
    if (value !== null) {
      const options: object = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
      };
      const date: string = new Date(value * 1000).toLocaleString('ru-RU', options).replace(',', '');
      return date === 'Invalid Date' ? 'Дата загружается...' : date;
    } return '—';
  }

  getShortDate = (value) => {
    if (!value) {
      return 'Не указано';
    }
    const date = new Date(value * 1000);
    const formatter = new Intl.DateTimeFormat('ru', {
      day: '2-digit',
      month: 'short',
      hour: 'numeric',
      minute: '2-digit',
    });
    return formatter.format(date);
  }

  getShortNumericDate = (value) => {
    if (!value) {
      return 'Не указано';
    }
    const date = new Date(value * 1000);
    const formatter = new Intl.DateTimeFormat('ru', {
      day: '2-digit',
      month: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
    return formatter.format(date);
  }
}
