import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
})
export default class ProviderForm extends Vue {
    @Prop({ default: () => ({}) }) item!: object;

    @Prop({ default: () => false }) loading!: boolean;

    @Prop() form!: string;

    newItem: object = {
      name: '', contact_name: '', phone: '', address: '', description: '', site: '',
    };

    onChangeProvider(formName) {
      (this.$refs[formName] as Form).validate((valid) => (valid ? this.$emit('changeProvider', this.item) : false));
    }

    resetFields() {
      (this.$refs.formProvider as Form).resetFields();
    }

    onSendProvider(formName) {
      (this.$refs[formName] as Form).validate((valid) => (valid ? this.$emit('sendProvider', this.item) : false));
    }
}
