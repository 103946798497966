import { ActionTree } from 'vuex';
import {
  StateBreadcrumb,
} from './types';

const actions: ActionTree<StateBreadcrumb, object> = {

  addBreadcrumb({ commit }, item) {
    commit('GET_BREADCRUMB', item);
  },

  addActionBtn({ commit }, item) {
    commit('GET_ACTION_BTN', item);
  },

  resetBreadcrumb({ commit }) {
    commit('GET_BREADCRUMB', []);
    commit('GET_ACTION_BTN', []);
  },
};
export default actions;
