import BaseProvider from '../base-provider';

class BugOptionProvider extends BaseProvider {
  async getBugOptionList(page) {
    const res = await this.request('GET', `/bugoption/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendBugOptionList(item) {
    const data: object = { BugOption: item };
    const res = await this.request('POST', '/bugoption/add', data);
    return res;
  }

  async sendBugOptionSetPrioroty(item) {
    const data: object = { PriorityForm: item };
    const res = await this.request('POST', '/bugoption/setpriority', data);
    return res;
  }

  async changeBugOptionList(item) {
    const data: object = { BugOption: item };
    const res = await this.request('POST', `/bugoption/update?id=${item.id}`, data);
    return res;
  }

  async deleteBugOptionList(item) {
    const data: object = { BugOption: item };
    const res = await this.request('POST', `/bugoption/delete?id=${item.id}`, data);
    return res;
  }
}

export default new BugOptionProvider();
