import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PointForm from '../../components/point-form';

@Component({
  template: require('./index.html'),
  components: {
    'point-form': PointForm,
  },
})
export default class PointEditPage extends Vue {
  @Getter viewPoint;

  @Getter changePoint;

  @Action getViewPoint;

  @Action addBreadcrumb;

  @Action addActionBtn;

  item: object = {};

  color = '';

  mounted() {
    this.getViewPoint(this.$route.params.id);
    document.title = 'Изменение филиала';
  }

  beforeDestroy() {
    this.addActionBtn();
  }

  resetForm() {
    (this.$refs.form as PointForm).resetForm();
  }

  @Watch('viewPoint')
  onChange() {
    this.addBreadcrumb([
      { id: 1, section: 'Компания', link: null },
      { id: 2, section: 'Настройки', link: null },
      { id: 3, section: 'Филиалы', link: '/settings/point' },
      { id: 4, section: `${this.viewPoint.prefix}-${this.viewPoint.name}`, link: null },
      { id: 5, section: 'Изменение', link: null },
    ]);
    this.item = this.viewPoint;
    this.color = this.viewPoint.color;
  }

  @Watch('changePoint')
  changeOldPoint() {
    this.resetForm();
    this.$router.push({ path: '/settings/point', name: 'point' });
  }
}
