import { Form as ElForm } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { User } from '@/types';
import CashProvider from '../../api/providers/cash';
import ViewUserName from '../forms/user-name';

interface Form {
  id;
  cashless;
  commission;
  name;
  point_id;
  users;
}
@Component({
  template: require('./cashbox-form.html'),
  components: {
    'user-name': ViewUserName,
  },
})
export default class CashboxForm extends Vue {
    @Getter btnloader;

    @Getter cashList;

    @Prop({ default: () => [] }) point!: object[];

    @Prop({ default: () => 'add' }) typeForm!: string;

    @Prop({ default: () => false }) viewDialog!: boolean;

    @Prop() stuffList!: User[];

    get sortedStuffList() {
      return this.stuffList.sort((a, b) => {
        const aFullName = `${a.last_name} ${a.first_name} ${a.middle_name}`;
        const bFullName = `${b.last_name} ${b.first_name} ${b.middle_name}`;
        return aFullName.localeCompare(bFullName);
      });
    }

    @Prop() form!: Form;

    cashboxAdd = false;

    modalUserTableData: CashboxModalUserTableRow[] = [];

    diffStuffList = (stuff_list1, stuff_list2) => stuff_list1.filter(
      (a1) => !stuff_list2.some(
        (a2) => a1.id === a2.id,
      ),
    ).filter((item) => item)

    mounted() {
      this.$root.$on('onDialog', () => {
        this.cashboxAdd = true;
        this.modalUserTableData = [];
        this.sortedStuffList.forEach((element) => {
          this.modalUserTableData.push({
            user: element,
            selected: false,
          });
        });
      });
    }

    requestOnEditCashbox(formName) {
      (this.$refs[formName] as ElForm).validate(async (valid) => {
        if (valid) {
          const cashboxItem = {
            id: this.form.id,
            cashless: this.form.cashless,
            commission: this.form.commission,
            name: this.form.name,
            point_id: this.form.point_id,
          };
          // сохраняем изменения кассы
          /**
                 * @var переменная в которую сохраним обновленную кассу из ответа API
                 */
          let apiCashbox;
          let cashboxUsers = [];
          if (this.typeForm === 'add') {
            try {
              apiCashbox = (await CashProvider.createCashbox(cashboxItem)).data;
            } catch (e) {
              console.error('Ошибка создания кассы', e);
              return false;
            }
          } else {
            try {
              apiCashbox = (await CashProvider.changeCashItem(cashboxItem)).data;
            } catch (e) {
              console.error('Ошибка изменения кассы', e);
              return false;
            }
            cashboxUsers = apiCashbox.users;
          }
          // формируем два списка пользователей. в одном будут новые пользователи кассы,
          //  в другом - удаленные
          const storageSelectedUsers = this.modalUserTableData.filter((x) => x.selected);
          const newUsersInCashbox = this.diffStuffList(
            storageSelectedUsers.map((x) => x.user),
            cashboxUsers,
          );
          const removedUsersInCashbox = this.diffStuffList(
            cashboxUsers,
            storageSelectedUsers.map((x) => x.user),
          );
          // проходимся по каждому элементу списков и сохраняем данные
          const apiUserPromises: object[] = [];
          newUsersInCashbox.forEach((x) => {
            try {
              apiUserPromises.push(CashProvider.CashboxAddUserForm(
                {
                  user_id: x.id,
                  cashbox_id: apiCashbox.id,
                },
              ));
            } catch (e) {
              console.error('Ошибка добавления сотрудника к кассе', e);
            }
          });
          removedUsersInCashbox.forEach((x) => {
            try {
              apiUserPromises.push(CashProvider.CashboxRemoveUserForm(
                {
                  user_id: x.id,
                  cashbox_id: apiCashbox.id,
                },
              ));
            } catch (e) {
              console.error('Ошибка отвязки сотрудника от кассы', e);
            }
          });
          await Promise.all(apiUserPromises);
          this.$emit('closeDialog');
          return true;
        }
        return false;
      });
    }

    close() {
      this.resetCashboxForm();
      this.$emit('closeDialog');
    }

    resetCashboxForm() {
      if (this.$refs.cashboxForm !== undefined) (this.$refs.cashboxForm as ElForm).resetFields();
    }

    @Watch('viewDialog')
    openWindow(view: boolean) {
      this.cashboxAdd = view;
      this.modalUserTableData = [];
      this.sortedStuffList.forEach((element) => {
        this.modalUserTableData.push({
          user: element,
          selected: this.form.users.some((x) => x.id === element.id),
        });
      });
    }

    @Watch('cashList')
    listCash() { this.resetCashboxForm(); }
}

interface CashboxModalUserTableRow {
    user: object;
    selected: boolean;
}
