import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { User, LogForm } from '@/types';

@Component({
  template: require('./index.html'),
})

export default class UserInputAutocomplateSuggest extends Vue {
  @Prop() user!: User;

  @Action sendLog;

  /**
   * начала передачи выбранного клиента
   */
  chooseUser() {
    this.logrequest();
    this.$emit('chooseUser', this.user);
  }

  logrequest() {
    const date: number = new Date().getTime();
    const name = 'CHOOSE_USER_FROM_AUTOCOMPLETE_SUGGEST';
    const payload = {};
    const logForm: LogForm = {
      name,
      date,
      payload,
    };
    this.sendLog(logForm);
  }
}
