import { MutationTree } from 'vuex';
import {
  CHANGE_POSTING_PART,

  CLEAR_POSTING_ITEM, DELETE_POSTING_ITEM,
  DELETE_POSTING_PART_ITEM, GET_POSTING,

  GET_POSTING_VIEW,

  POSTING_VALIDATION,

  REVERT_POSTING, REVERT_POSTING_ITEM, SEND_POSTING_ADD,

  SEND_POSTING_ITEM,

  StatePosting,
} from './types';

const mutations: MutationTree<StatePosting> = {
  [GET_POSTING](state, { item, pageCurrent, pageCount }) {
    state.posting = item;
    state.postingPageCurrent = pageCurrent;
    state.postingPageCount = pageCount;
  },

  [GET_POSTING_VIEW](state, { item }) {
    state.postingView = item;
    state.postingNewItemList = item.parts === null ? [] : item.parts;
  },

  [POSTING_VALIDATION](state, item) {
    state.postingValid = item;
  },

  [SEND_POSTING_ITEM](state, { item }) {
    state.postingNewItemList.push(item);
  },

  [SEND_POSTING_ADD](state, { item }) {
    state.postingAdd = item;
  },

  [REVERT_POSTING_ITEM](state, { item }) {
    const index = state.postingNewItemList.findIndex((i) => i.id === item.id);
    state.postingNewItemList.splice(index, 1);
  },

  [REVERT_POSTING](state, { item }) {
    state.postingRevert = item;
  },

  [CLEAR_POSTING_ITEM](state) {
    state.postingNewItemList = [];
  },

  [CHANGE_POSTING_PART](state, item) {
    state.postingChangePart = item;
  },

  [DELETE_POSTING_ITEM](state, item) {
    const index = state.postingNewItemList.findIndex((i) => i.id === item.id);
    state.postingNewItemList.splice(index, 1);
  },

  [DELETE_POSTING_PART_ITEM](state, item) {
    const index = state.postingNewItemList.findIndex((i) => i.part_id === item.id);
    state.postingNewItemList.splice(index, 1);
  },
};

export default mutations;
