import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  Repair, RepairStatus, User, Document as ApiDocument,
} from '@/types';
import ViewDate from '../../forms/date';
import Numbers from '../../forms/numbers';

@Component({
  template: require('./status-block.html'),
  components: {
    'repair-date': ViewDate,
    numbers: Numbers,
  },
})
export default class RepairViewStatusBlock extends Vue {
    @Prop() repair!: Repair;

    @Prop() user!: User;

    @Prop() allCustomStatuses!: Array<RepairStatus>;

    @Prop() papers!: Array<ApiDocument>;

    @Prop() masters!: Array<object>;

    @Prop() points!: Array<object>;

    @Prop() onChangeCustomStatus!: Function;

    @Prop() onChangeAgreement!: Function;

    @Prop() onStartWork!: Function;

    @Prop() onChangeMaster!: Function;

    @Prop() onChangePoint!: Function;

    @Prop() onPrintPaper!: Function;

    @Prop() onRepairDelete!: Function;

    @Prop() onPartsCome!: Function;

    @Prop() onReturnRepairToWork!: Function;

    @Prop() onRestartEjectedRepair!: Function;

    @Prop() onCloneRepair!: Function;

    @Prop() onTransferStart!: Function;

    @Prop() onTransferCancel!: Function;

    @Prop() onTransferComplete!: Function;

    @Prop() onRepairUpdate!: Function;

    get rightsUserCanRestartClosedRepair(): boolean {
      return this.user.rights ? this.user.rights.can_restart_closed_repair : false;
    }

    get master_id(): number | null {
      try {
        const master_id = this.repair.master_id ? this.repair.master_id : null;
        return master_id;
      } catch {
        return null;
      }
    }

    get point_id(): number | null {
      try {
        const current_point_id = this.repair.current_point_id ? this.repair.current_point_id : null;
        return current_point_id;
      } catch {
        return null;
      }
    }

    selectedPaperId: number | null= null;

    systemStatuses: RepairStatus[] = [
      { id: 1, name: 'Принято' },
      { id: 2, name: 'В работе' },
      { id: 3, name: 'Ожидает' },
      { id: 4, name: 'Готово' },
      { id: 5, name: 'Выдано' },
      { id: 6, name: 'На согласовании' },
    ];

    get clsIconStatus(): object {
      switch (this.repair.status_id) {
        case 1:
          return { 'fa-arrow-down': true };
        case 2:
          return { 'fa-wrench': true };
        case 3:
          return { 'fa-clock-o': true };
        case 4:
          return { 'fa-check': true };
        case 5:
          return { 'fa-plane': true };
        default:
          return { 'fa-comments': true };
      }
    }

    get styleStatus(): object {
      switch (this.repair.status_id) {
        case 1:
          return { 'status-accepted': true };
        case 2:
          return { 'status-work': true };
        case 3:
          return { 'status-wait': true };
        case 4:
          return { 'status-done': true };
        case 5:
          return { 'status-given': true };
        default:
          return { 'status-agreed': true };
      }
    }

    // Геттер для списка пользовательских статусов в селекторе.
    // Отдает только те статусы, которые соответствуют системному статусу заказа.
    get avaliableCustomRepairStatuses(): Array<object> {
      try {
        return this.allCustomStatuses
          .filter((x) => x.parent_id === this.repair.status_id)
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          });
      } catch (err) {
        console.error('Ошибка формирования списка пользовательских статусов: ', err);
        return [];
      }
    }

    /**
     * Геттер для списка вариантов статусов заказов.
     * Объединяет системный статус и соответствующие пользовательские в один массив.
     */
    get statusSelectOptions(): Array<object> {
      try {
        const systemStatus = this.systemStatuses.filter((x) => x.id === this.repair.status_id);
        if (systemStatus.length === 0) return [];
        const systemOption = [{ id: null, name: systemStatus[0].name }];
        return [...systemOption, ...this.avaliableCustomRepairStatuses];
      } catch (err) {
        console.error(err);
        return [];
      }
    }

    /**
     * Геттер для последнего трансфера заказа.
     * Используется для отрисовки кнопок продвинутого перемещения.
     */
    get transfer(): object | null {
      if (this.repair.repair_transfers && this.repair.repair_transfers.length > 0) {
        return this.repair.repair_transfers[this.repair.repair_transfers.length - 1];
      }
      return null;
    }

    get rightsUserCreateUpdateRepair() {
      return this.user.rights ? this.user.rights.can_create_update_repair : false;
    }

    get rightsUserCanDeleteRepair() {
      return this.user.rights ? this.user.rights.can_delete_repair : false;
    }

    get rightsUserCanApplyMasterRepair() {
      return this.user.rights ? this.user.rights.can_apply_master_on_repair : false;
    }

    get rightsUserCanWorkOnRepair() {
      return this.user.rights ? this.user.rights.can_work_on_repair : false;
    }

    get rightsUserCanTransferRepair() {
      return this.user.rights ? this.user.rights.can_transfer_repair : false;
    }

    changeCustomRepairStatus(item) {
      this.onChangeCustomStatus(item);
    }

    editRepair() {
      if (this.rightsUserCreateUpdateRepair) {
        this.onRepairUpdate();
      } else {
        console.error('Попытка перехода на редактирование заказа без необходимых прав.');
      }
    }

    workOnRepairClick() {
      this.onStartWork();
    }

    changeMaster(newId) {
      this.onChangeMaster(newId);
    }

    changePoint(newId) {
      this.onChangePoint(newId);
    }

    printDocument() {
      this.onPrintPaper(this.selectedPaperId);
    }

    deleteRepair() {
      this.onRepairDelete();
    }

    approveAgreement() {
      this.onChangeAgreement(true);
    }

    declineAgreement() {
      this.onChangeAgreement(false);
    }

    partsCome() {
      this.onPartsCome();
    }

    returnRepairToWork() {
      this.onReturnRepairToWork();
    }

    restartEjectedRepair() {
      this.onRestartEjectedRepair();
    }

    cloneRepair() {
      this.onCloneRepair();
    }

    startTransfer() {
      this.onTransferStart();
    }

    completeTransfer() {
      this.onTransferComplete();
    }

    cancelTransfer() {
      this.onTransferCancel();
    }

    updateRepairClick() {
      this.onRepairUpdate();
    }
}
