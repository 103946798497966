export default class ExportLogItemViewModel {
  private static STATUS_CREATED = 1;

  private static STATUS_IN_PROGRESS = 2;

  private static STATUS_DONE = 3;

  private static STATUS_FAILED = 4;

  private static STATUS_CANCELLED = 5;

  private static STATUS_SEND_ERROR = 6;

  private static TYPE_REPAIR = 1;

  private static TYPE_NOMENKLATURE = 2;

  private static TYPE_AMOUNT_PART = 3;

  private static TYPE_WORKS = 4;

  private static TYPE_CLIENTS = 5;

  private static TYPE_CASHOPERATION = 6;

  private static TYPE_SMS = 7;

  constructor(created_at: number, type: number, created_by: string, email: string, status: number) {
    this.created_at = created_at;
    this.created_by = created_by;
    this.email = email;

    switch (status) {
      case ExportLogItemViewModel.STATUS_CREATED: this.status = 'Создан'; break;
      case ExportLogItemViewModel.STATUS_IN_PROGRESS: this.status = 'В работе'; break;
      case ExportLogItemViewModel.STATUS_DONE: this.status = 'Выполнен'; break;
      case ExportLogItemViewModel.STATUS_FAILED: this.status = 'Завершен с ошибкой'; break;
      case ExportLogItemViewModel.STATUS_CANCELLED: this.status = 'Отменен'; break;
      case ExportLogItemViewModel.STATUS_SEND_ERROR: this.status = 'Ошибка отправки'; break;
      default: this.status = '(не определено)';
    }

    switch (type) {
      case ExportLogItemViewModel.TYPE_REPAIR: this.type = 'Заказы'; break;
      case ExportLogItemViewModel.TYPE_NOMENKLATURE: this.type = 'Номенклатура товаров'; break;
      case ExportLogItemViewModel.TYPE_AMOUNT_PART: this.type = 'Остатки товаров'; break;
      case ExportLogItemViewModel.TYPE_WORKS: this.type = 'Услуги'; break;
      case ExportLogItemViewModel.TYPE_CLIENTS: this.type = 'Клиенты'; break;
      case ExportLogItemViewModel.TYPE_CASHOPERATION: this.type = 'Кассовые операции'; break;
      case ExportLogItemViewModel.TYPE_SMS: this.type = 'SMS'; break;

      default: this.type = '(не определено)';
    }
  }

  public created_at: number;

  public type: string;

  public created_by: string;

  public email: string;

  public status: string;
}
