import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import NumberPhone from '../forms/number-phone';
import ViewUserName from '../forms/user-name';

@Component({
  template: require('./index.html'),
  components: {
    'user-name': ViewUserName,
    phone: NumberPhone,
  },
})
export default class ClientDuplicate extends Vue {
    @Prop({ default: () => [] }) duplicateClients!: object[];

    // methods

    targetBlankDetail(id) {
      const route_data = this.$router.resolve({ name: 'clients-view', params: { id } });
      window.open(route_data.href, '_blank');
    }
}
