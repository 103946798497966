import Vue from 'vue';
import { Form } from 'element-ui';
import { Component, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { Action, Getter } from 'vuex-class';
import TemplateFormProvider from '@/api/providers/template-form';
import { CustomField } from '@/types';

interface OneColItem {
  id?;
}
interface FormType {
  layout?;
}
@Component({
  template: require('./index.html'),
  components: {
    draggable,
  },
})

export default class ManagerFormViewPage extends Vue {
    @Action addBreadcrumb;

    @Getter btnloader;

    deleteForm: object = {};

    getFormList: object[] = [];

    formPageCount = 0;

    form: FormType = {};

    updateForm: object = {};

    newAddForm: object = {};

    oneCol: CustomField[] = [];

    twoCol: CustomField[] = [];

    threeCol: CustomField[] = [];

    fourCol: CustomField[] = [];

    formList: CustomField[] = [];

    modalEdit = false;

    modalRemove = false;

    modalRequiredFields = false;

    flagsControlButton = false;

    validateChecked = false;

    apiFLags = false;

    checked = false;

    loaderButton = false;

    getTemplateList = 1;

    formEdit: CustomField = {
      label: null,
      required: false,
      type: null,
      id: null,
    };

    get textButtonDialog() {
      return !this.apiFLags ? 'Изменить поле' : 'Добавить поле';
    }

    stylesForms = (element) => {
      element.forEach((el) => {
        const elCopy = el;
        if (elCopy.id >= 1000) {
          elCopy.styles = { 'form-item_success': true };
        } else if (el.id < 1000) {
          elCopy.styles = { 'form-item_primary': true };
        } else if (el.editable && el.system) {
          elCopy.styles = { 'form-item_static': true };
        }
      });
    }

    /**
     * Добавляет стили одной форме
     * @param  {} element
     * @returns element
     */
    styleOneItem = (element) => {
      const elCopy = element;
      if (elCopy.id >= 1000) {
        elCopy.styles = { 'form-item_success': true };
      } else if (element.id < 1000) {
        elCopy.styles = { 'form-item_primary': true };
      } else if (element.editable && element.system) {
        elCopy.styles = { 'form-item_static': true };
      }
      return elCopy;
    }

    sendAddField(event) {
      if (event.currentTarget.classList.contains('custom-form__btn-add')) {
        this.apiFLags = true;
        this.modalEdit = true;
      }
    }

    loadedDataInForm() {
      const item: CustomField | undefined = this.formList.find((el) => el.id === this.formEdit.id);
      if (item) {
        item.id = this.formEdit.id;
        item.label = this.formEdit.label;
        item.required = this.formEdit.required;
        item.type = this.formEdit.type;
      }
    }

    handleChangeForm() {
      this.loaderButton = true;
      this.loadedDataInForm();
      this.changeTemplateForm(this.formEdit);
    }

    handleChangeAndAdd(formName) {
      (this.$refs[formName] as Form).validate((valid) => {
        if (valid) {
          this.loaderButton = true;
          this.loadedDataInForm();
          if (!this.apiFLags) {
            this.changeTemplateForm(this.formEdit);
          } else {
            this.sendTemplateForm(this.formEdit);
          }
          return true;
        }
        return false;
      });
    }

    clearChangeTemplateForm() {
      this.resetForm();
    }

    resetForm() {
      if (this.$refs.formEdit !== undefined) (this.$refs.formEdit as Form).resetFields();
    }

    closeFormEdit() {
      this.formEdit = { label: null, required: false, type: null };
      this.resetForm();
    }

    onClickEdit(form) {
      this.formEdit.id = form.id;
      this.formEdit.label = form.label;
      this.formEdit.required = form.required;
      this.formEdit.type = form.type;
      if ('form-item_primary' in form.styles) {
        this.modalRequiredFields = true;
      } else if ('form-item_success' in form.styles) {
        this.modalEdit = true;
      }

      this.apiFLags = false;
    }

    onClickRemove(form) {
      this.formEdit.id = form.id;
      this.formEdit.label = form.name || form.label;

      if (form.type === 998 || form.type === 999) {
        this.formEdit.type = 'text';
      } else {
        this.formEdit.type = form.type;
      }

      this.modalRemove = true;
    }

    updateFormRealTime = (element, item) => {
      if (element.id === item.id) {
        const elementCopy = { ...element };
        elementCopy.label = item.label;
        elementCopy.required = item.required;
        elementCopy.type = item.type;
      }
    }

    onMouseMove(form) {
      this.flagsControlButton = form.id;
    }

    difference = (a, b) => a.filter((i) => b.indexOf(i) < 0)
      .concat(b.filter((i) => a.indexOf(i) < 0))

    onEnd() {
      const id = Number(this.$route.params.id);

      if (this.oneCol.length > 1) {
        const last_col_index = this.oneCol.findIndex((oneColItem) => oneColItem.id === 15);
        const last_col = this.oneCol.splice(last_col_index, 1);

        this.oneCol.unshift(last_col[0]);
      }

      const data = {
        id,
        form: { layout: [this.oneCol, this.twoCol, this.threeCol, this.fourCol] },
      };

      this.changeTemplateLayoutForm(data);
    }

    loadedFormList(el) {
      const formId: number[] = [];
      const valId: number [] = [];

      el.forEach((i) => valId.push(i.id));

      this.form.layout.forEach((column) => {
        column.forEach((element) => {
          formId.push(element.id);
        });
      });

      formId.sort((a, b) => a - b);
      valId.sort((a, b) => a - b);

      const result = this.difference(valId, formId);

      result.forEach((id) => {
        el.forEach((element) => {
          if (element.id === id) {
            if (!element.deleted) {
              this.formList.push(element);
            }
          }
        });
      });
      this.stylesForms(this.formList);
      this.formList.reverse();
    }

    getTemplateForm(id: string) {
      this.$store.commit('BTN_LOADER', true);
      TemplateFormProvider.getTemplateForm(id)
        .then((res) => {
          this.form = res.data;
          this.$store.commit('BTN_LOADER', false);
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('BTN_LOADER', false);
        });
    }

    changeTemplateLayoutForm(item) {
      TemplateFormProvider.changeTemplateLayoutForm(item.id, item.form)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Раскладка успешно сохранена', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    async getTemplateFormList(item: number) {
      await TemplateFormProvider.getTemplateFormList(item)
        .then((res) => {
          this.getFormList = res.data;
          this.formPageCount = res.pageCount;
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка: пользовательские поля не загружены', item: '' });
        });
    }

    deleteTemplateForm(id: number) {
      TemplateFormProvider.deleteTemplateForm(id)
        .then((res) => {
          this.deleteForm = res.data;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Поле успешно удалено', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка: поле не удалилось', item: '' });
        });
    }

    sendTemplateForm(item: object) {
      TemplateFormProvider.sendTemplateForm(item)
        .then((res) => {
          this.newAddForm = res.data;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Поле успешно добавлено', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка: поле не добавлено', item: '' });
        });
    }

    clearTempalteForm() {
      this.form.layout = [];
    }

    changeTemplateForm(item: CustomField) {
      TemplateFormProvider.changeTemplateForm(item.id, item)
        .then((res) => {
          this.updateForm = res.data;
          const formAddStyle: CustomField = this.styleOneItem(res.data);
          this.changeItemOnCol(formAddStyle);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Поле успешно изменено', item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка: поле не изменено', item: '' });
        });
    }

    /**
     * Проверяет нуждается ли одна из форм в обновлении
     * @param  {CustomField} field
     */
    changeItemOnCol(field: CustomField) {
      let foundedIndex = this.oneCol.findIndex((item) => item.id === field.id);
      if (foundedIndex === -1) {
        foundedIndex = this.twoCol.findIndex((item) => item.id === field.id);
        if (foundedIndex === -1) {
          foundedIndex = this.threeCol.findIndex((item) => item.id === field.id);
          if (foundedIndex === -1) {
            foundedIndex = this.fourCol.findIndex((item) => item.id === field.id);
            if (foundedIndex > -1) {
              this.fourCol[foundedIndex] = field;
            }
          } else {
            this.threeCol[foundedIndex] = field;
          }
        } else {
          this.twoCol[foundedIndex] = field;
        }
      } else {
        this.oneCol[foundedIndex] = field;
      }
    }

    @Watch('form')
    async onLoad(val) {
      // /customfield/template
      val.layout.forEach((element, index) => {
        this.stylesForms(element);
        switch (index) {
          case 0: this.oneCol = element.filter((el) => !el.deleted); break;
          case 1: this.twoCol = element.filter((el) => !el.deleted); break;
          case 2: this.threeCol = element.filter((el) => !el.deleted); break;
          default: this.fourCol = element.filter((el) => !el.deleted); break;
        }
      });
      await this.getTemplateFormList(1);
    }

    @Watch('updateForm')
    loadForm(val) {
      // customfield/update
      this.loaderButton = false;
      this.modalEdit = false;
      this.modalRequiredFields = false;

      this.oneCol.forEach((el) => this.updateFormRealTime(el, val));
      this.twoCol.forEach((el) => this.updateFormRealTime(el, val));
      this.threeCol.forEach((el) => this.updateFormRealTime(el, val));
      this.fourCol.forEach((el) => this.updateFormRealTime(el, val));
    }

    @Watch('getFormList')
    async loadFormList(val) {
      this.getTemplateList += 1;
      if (this.getTemplateList <= Number(this.formPageCount)) {
        this.getTemplateFormList(this.getTemplateList);
      }
      await this.loadedFormList(val);
    }

    @Watch('newAddForm')
    newForm(val) {
      this.loaderButton = false;
      this.modalEdit = false;
      const newForm = [val];
      this.stylesForms(newForm);
      this.formList.push(newForm[0]);
    }

    @Watch('deleteForm')
    delForm(val) {
      this.modalRemove = false;
      const index = this.formList.findIndex((el) => el.id === val.id);
      this.formList.splice(index, 1);
    }

    mounted() {
      const { id } = this.$route.params;
      this.getTemplateForm(id);
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: null },
        { id: 2, section: 'Настройки', link: null },
        { id: 3, section: 'Настройка форм', link: '/settings/manageform' },
        { id: 4, section: 'Форма приема', link: null },
      ]);
      document.title = 'Форма приема';
    }

    destroyed() { this.clearTempalteForm(); }
}
