import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

interface FormPay {
  cashbox_id: number | null;
  sum: number;
}
interface FlagsPayments {
  btn: string;
}
@Component({
  template: require('./payments.html'),
})
export default class DialogPayments extends Vue {
    @Prop() flagsPayments!: FlagsPayments;

    @Prop() defaultSum!: number;

    @Prop() savePayment!: Function;

    @Prop({ default: () => [] }) cashList!: object[];

    @Getter btnloader;

    @Getter user;

    formPay: FormPay = {
      cashbox_id: null,
      sum: this.defaultSum,
    };

    validateSum = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error('Значение «Сумма» должно быть не меньше 0.'));
      } else {
        callback();
      }
    }

    rulesPay: object = {
      sum: [
        { validator: this.validateSum, trigger: 'change', type: 'number' },
        { required: true, message: 'Необходимо заполнить поле «Сумма»' },
      ],
      cashbox_id: { required: true, message: 'Необходимо заполнить поле «Касса»' },
    };

    open() {
      this.formPay.sum = Math.abs(this.defaultSum);
    }

    close() {
      this.resetFormPayments();
    }

    resetFormPayments() {
      (this.$refs.formPayments as Form).resetFields();
    }

    sendPayment(formName) {
      const { formPay } = this;
      const sum = this.flagsPayments.btn === 'reset' ? Number(-this.formPay.sum)
        : Number(this.formPay.sum);

      const paymentForm = {
        cashbox_id: formPay.cashbox_id,
        sum,
        repair_id: Number(this.$route.params.id),
      };

      (this.$refs[formName] as Form).validate((valid) => {
        if (valid) {
          return this.savePayment(paymentForm);
        }
        return false;
      });
    }

    @Watch('flagsPayments')
    handler(value) {
      if (value.pay === true) {
        this.$nextTick(() => (this.$refs.formPayments as Form).clearValidate());
      }
    }

    @Watch('defaultSum')
    default(sum: number) {
      this.formPay.sum = Math.abs(sum);
    }

    @Watch('cashList')
    userMe(list) {
      if (list.length !== 0) {
        const filterCashListOnPoint = list.find((cash) => cash.point_id === this.user.point_id);

        this.formPay.cashbox_id = filterCashListOnPoint ? filterCashListOnPoint.id : null;
      }
    }
}
