import { MutationTree } from 'vuex';
import {
  DISMISS_INCOMING_FROM_NUMBER,
  SET_INCOMING,
  StateVOIP,
} from './types';

const mutations: MutationTree<StateVOIP> = {

  [SET_INCOMING](state, incomig) {
    if (state.incomingCall.filter((x) => x.callerNumber === incomig.callerNumber).length === 0) {
      state.incomingCall.push(incomig);
    }
  },

  [DISMISS_INCOMING_FROM_NUMBER](state, callerNumber) {
    state.incomingCall = state.incomingCall.filter((x) => x.callerNumber !== callerNumber);
  },
};

export default mutations;
