import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class PartItemStorageCell extends Vue {
  @Prop({ default: null }) minimumAmount!: number;

  @Prop({ default: null }) actualAmount!: number;

  get title() {
    if (this.minimumAmount === null) {
      return 'Без минимального остатка';
    }
    if (this.minimumAmount !== null && this.minimumAmount > (this.actualAmount as number)) {
      return 'Меньше минимального остатка';
    }
    if (this.minimumAmount !== null && this.minimumAmount < (this.actualAmount as number)) {
      return 'Больше минимального остатка';
    }
    return 'Минимальный остаток';
  }

  get innerHtml() {
    let html = `<span class="part__amount__actual">${this.actualAmount}</span>`;
    if (this.minimumAmount !== null) {
      html += `<span class="part__amount__minimal">мин: ${this.minimumAmount}</span>`;
    }
    return html;
  }

  render(createElement: Function) {
    return createElement(
      'div',
      {
        class: {
          part__amount: this.minimumAmount !== null || this.minimumAmount < this.actualAmount,
          part__amount_min: this.minimumAmount !== null && this.minimumAmount > this.actualAmount,
          part__amount_max: this.minimumAmount < this.actualAmount,
          part__amount_equal: this.minimumAmount !== null
            && this.minimumAmount === this.actualAmount,
        },
        attrs: {
          title: this.title,
        },
        directives: [
          {
            name: 'tippy',
          },
        ],
        domProps: {
          innerHTML: this.innerHtml,
        },
      },
    );
  }
}
