// Mutations Type

import {
  Sell, SellDiscount, SellPart, SellPayment, SellWork,
} from '@/types';

export const GET_SHOP = 'GET_SHOP';
export const GET_VIEW_SHOP = 'GET_VIEW_SHOP';
export const GET_PAGE_SHOP = 'GET_PAGE_SHOP';
export const SHOP_VALIDATION = 'SHOP_VALIDATION';

export const VIEW_SELL = 'VIEW_SELL';

export const ADD_SELL_PART = 'ADD_SELL_PART';
export const ADD_SELL_WORK = 'ADD_SELL_WORK';
export const ADD_SELL_PAYMENT = 'ADD_SELL_PAYMENT';

export const DEL_SELL_PART = 'DEL_SELL_PART';
export const DEL_SELL_WORK = 'DEL_SELL_WORK';
export const DEL_SELL_PAYMENT = 'DEL_SELL_PAYMENT';
export const DEL_SELL_DISCOUNT = 'DEL_SELL_DISCOUNT';

export const UPDATE_SELL_PART = 'UPDATE_SELL_PART';
export const UPDATE_SELL_WORK = 'UPDATE_SELL_WORK';

export const DEL_SELL = 'DEL_SELL';
export const SELL_REVERT = 'SELL_REVERT';
export const SELL_PART_REVERT = 'SELL_PART_REVERT';
export const SELL_CANCEL = 'SELL_CANCEL';
export const UPDATE_SELL = 'UPDATE_SELL';

export const ADD_SELL_DISCOUNT = 'ADD_SELL_DISCOUNT';

export const CLEAR_REVERT_LIST = 'CLEAR_REVERT_LIST';
export const CLEAR_PRODUCTS_LIST = 'CLEAR_PRODUCTS_LIST';
export const CLEAR_STATE_DELETE_PRODUCTS = 'CLEAR_STATE_DELETE_PRODUCTS';
export const CLEAR_STATE_PAYMENT_LIST = 'CLEAR_STATE_PAYMENT_LIST';
export const CLEAR_STATE_DISCOUNT_LIST = 'CLEAR_STATE_DISCOUNT_LIST';

// Store
export interface DataItem {
  date;
}

export interface StateShop {
    shop: object[];
    viewSell: Sell;
    shopPageCurrent: number;
    sellRevert: object;
    sellPartRevert: object;
    sellDelete: string | null;
    sellCancel: object;
    shopPageCount: number;
    shopValid: object[];
    sellPart: object;
    sellWork: object;
    sellDiscount: object;
    sellPartList: SellPart[];
    sellWorkList: SellWork[];
    sellDataList: object[];
    sellPaymentList: SellPayment[];
    sellDiscountList: SellDiscount[];
    sellUpdatePart: object;
    sellUpdateWork: object;
    sellPartRevertList: object[];
    sellWorkRevertList: object[];
}

// Models
