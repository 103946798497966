import Vue from 'vue';
import { Form } from 'element-ui';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PartProvider from '../../../api/providers/part';
import DialogsCategory from '../../__category/dialog';

interface Category {
  cat_id: number;
  item: {
    id: number;
  };
}
interface ViewDialogCategory {
  view: boolean;
  type: string;
}
@Component({
  template: require('./form.html'),
  components: {
    'dialog-add-category': DialogsCategory,
  },
})

export default class WorkForm extends Vue {
    @Prop() item!: Category;

    @Prop() validWork!: object;

    @Action getWorkCategory;

    @Action sendWorkCategory;

    @Getter categoriesMainList;

    @Getter user;

    @Getter newCategory;

    @Getter btnloader;

    newPhoto = '';

    barcodeItemId = 1;

    viewDialogCategory: ViewDialogCategory = { view: false, type: 'add' };

    dataCategory: object = { name: '' };

    vat_list: object[] = [];

    reward_type_list: object[] = [];

    warrant_type_list: object[] = [];

    validatePrice = (rule, value, callback) => {
      if (value === null || value === undefined) {
        callback();
      } else {
        const floatVal = Number.parseFloat(value);
        if (floatVal < 0) {
          callback(new Error('Цена не может быть меньше 0'));
        } else {
          callback();
        }
      }
    }

    validateReward = (rule, value, callback) => {
      if (value === null || value === undefined) {
        callback();
      } else {
        const floatVal = Number.parseFloat(value);
        if (floatVal < 0) {
          callback(new Error('Вознаграждение не может быть меньше 0'));
        } else {
          callback();
        }
      }
    }

    // api
    getVatList() {
      PartProvider.getVatList()
        .then((res) => {
          const vatlist: object[] = [];
          Object.keys(res.data).forEach((j, i) => {
            if (i) {
              vatlist[Number(i) - 1] = { id: Number(i), name: res.data[i] };
            }
          });
          this.vat_list = vatlist;
        }).catch((err) => console.error(err));
    }

    getRewardTypeList() {
      PartProvider.getRewardTypeList()
        .then((res) => {
          const rewardTypeList: object[] = [];
          Object.keys(res.data).forEach((j, i) => {
            if (i) {
              rewardTypeList[Number(i) - 1] = { id: Number(i), name: res.data[i] };
            }
          });
          this.reward_type_list = rewardTypeList;
        }).catch((err) => console.error(err));
    }

    getWarrantTypeList() {
      PartProvider.getWarrantTypeList()
        .then((res) => {
          const warrantTypeList: object[] = [];

          Object.keys(res.data).forEach((j, i) => {
            if (i) {
              warrantTypeList[Number(i) - 1] = { id: Number(i), name: res.data[i] };
            }
          });

          this.warrant_type_list = warrantTypeList;
        }).catch((err) => console.error(err));
    }

    // end api

    get viewForm() {
      return this.$refs.workForm;
    }

    get rightsUserCanManageWorksAndPartsRewards() {
      return this.user.rights ? this.user.rights.can_manage_works_and_parts_rewards : false;
    }

    categorySelectList() {
      return this.flattery(this.categoriesMainList);
    }

    /**
     * Преобразует массив объектов в плоский набор
     * @param parent Массив объектов категорий
     * @param previousRoute строка предыдущего пути
     */
    private flattery(parent, previousRoute = '') {
      const result: object[] = [];
      parent.forEach((element) => {
        const route = (previousRoute !== '') ? `${previousRoute} / ${element.title}` : element.title;
        result.push({ id: element.key, value: route });
        if (element.children.length > 0) {
          result.push(...this.flattery(element.children, route));
        }
      });
      return result;
    }

    mounted() {
      this.getVatList();
      this.getRewardTypeList();
      this.getWarrantTypeList();
      this.getWorkCategory('work');
    }

    requestOnAddCategory() {
      ((this.$refs.formCategory as DialogsCategory)
        .formCategory as Form)
        .validate((valid) => (valid ? this.sendWorkCategory(this.dataCategory) : false));
    }

    validate = (obj: object, key: string) => {
      if (Object.keys(obj).some((v) => v === key)) {
        return obj[key][0];
      }
      return null;
    }

    @Watch('newCategory')
    addNewCategory(category: Category) {
      this.viewDialogCategory.view = false;
      this.item.cat_id = category.item.id;
    }
}
