import Vue from 'vue';
import { Component } from 'vue-property-decorator';

const LAST_UPDATE_NUM = '77';
const LAST_UPDATE_LOCAL_STORAGE_KEY = 'LAST_SHOWN_SERVIX_UPDATE';
@Component({
  template: require('./index.html'),
  components: {

  },

})
export default class UpdateMessage extends Vue {
  shouldShowUpdateMessage = localStorage.getItem(LAST_UPDATE_LOCAL_STORAGE_KEY) !== LAST_UPDATE_NUM;

  updateNotificationTitle = `Нажмите здесь чтобы узнать что нового появилось в обновлении ${LAST_UPDATE_NUM}!`;

  openDetails = () => {
    window.open(`https://servix.io/blog/update${LAST_UPDATE_NUM}`, '_blank');
  }

  onClose = () => {
    localStorage.setItem(LAST_UPDATE_LOCAL_STORAGE_KEY, LAST_UPDATE_NUM);
  }
}
