import { GetterTree } from 'vuex';
import { StateCash } from './types';

const getters: GetterTree<StateCash, object> = {
  cashList: (state) => state.cashList,
  cashListPageCount: (state) => state.cashListPageCount,
  cashbox: (state) => state.cashbox,
  cashValidate: (state) => state.cashValidate,
  cashOperationsList: (state) => state.cashOperationsList,
  cashOperationPageCount: (state) => state.cashOperationPageCount,
  cashOperationCountIn: (state) => state.cashOperationCountIn,
  cashOperationCountOut: (state) => state.cashOperationCountOut,
  cashOperationTotalIn: (state) => state.cashOperationTotalIn,
  cashOperationTotalOut: (state) => state.cashOperationTotalOut,
  cashViewDetail: (state) => state.cashViewDetail,
  cashOperationIn: (state) => state.cashOperationIn,
  cashOperationOut: (state) => state.cashOperationOut,
  cashOperationTransfer: (state) => state.cashOperationTransfer,
  cashOperationDelete: (state) => state.cashOperationDelete,
};

export default getters;
