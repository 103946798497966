import axios from 'axios';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ViewDate from '../../forms/date';

@Component({
  template: require('./files.html'),
  components: {
    'file-date': ViewDate,
  },
})

export default class RepairViewFiles extends Vue {
  @Action getRepairFileList;

  @Getter repairFiles;

  @Getter repairAddFiles;

  fileList: object[] = [];

  uploadPercentage = 0;

  formatFile = '';

  loaded = false;

  get id() { return Number(this.$route.params.id); }

  handleChangeFiles(files) {
    const form = new FormData();
    const expand: string[] = ['user'];
    const access = localStorage.getItem('access');

    form.append('RepairFileAddForm[file]', files.file);
    form.append('RepairFileAddForm[repair_id]', String(this.id));

    this.formatFiles(files.file.name);

    const domain = process.env.NODE_ENV === 'production' ? 'api.servix.io' : 'api-dev.servix.io';
    axios.post(`https://${domain}/v1/repairfile/add?expand=${expand}`, form, {
      headers: {
        'x-access-token': access,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function uploading(this, progressEvent) {
        this.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (this.uploadPercentage === 100) this.loaded = false;
      }.bind(this),
    }).then((res) => {
      this.$store.commit('SEND_REPAIR_ADD_FILES', { item: res.data });
      this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Файл успешно загружен', item: '' });
    }).catch((err) => {
      console.error('ERROR ~', err);
      this.loaded = false;
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка!', item: '' });
    });
  }

  formatFiles(name) {
    const regexp = /([a-zA-Z\s_\\\-():])+$/ig;
    this.loaded = true;
    this.formatFile = name.match(regexp)[0].toUpperCase();
  }

  mounted() {
    this.getRepairFileList({ page: 1, data: { repair_id: this.id } });
  }

  @Watch('repairFiles')
  filesList(files) {
    this.fileList = files;
  }

  @Watch('repairAddFiles')
  newFiles(file) {
    this.fileList.unshift(file);
  }
}
