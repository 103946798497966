import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  Part, Storage, PartAmount,
} from '@/types';
import StorageProvider from '@/api/providers/storage';

interface PartAmountTableItem {
  storage: Storage;
  amount: number;
  cell: string;
}
interface Response {
  data: Storage[];
  pageCount: number;
}

@Component({
  template: require('./index.html'),
})
export default class PartCellAmount extends Vue {
  @Prop() item!: Part;

  partAmountTableItem: PartAmountTableItem[] = [];

  mounted() {
    this.checkingPartAmount();
  }

  async checkingPartAmount() {
    try {
      let page = 1;
      let allStorage: Storage[] = [];
      const firstRes = await StorageProvider.getStorageList(page) as Response;
      const { pageCount } = firstRes;
      allStorage = firstRes.data;

      if (pageCount > 1) {
        const promiseRequests: object[] = [];
        page += 1;
        for (page; page <= pageCount; page++) {
          promiseRequests.push(StorageProvider.getStorageList(page));
        }

        await Promise.all(promiseRequests)
          .then((results) => {
            (results as Response[]).forEach((elem) => {
              allStorage = [...allStorage, ...elem.data];
            });
          });
      }
      if (this.item.amounts) {
        if (this.item.amounts.length > 0) {
          this.partAmountTableItem = [];
          allStorage.forEach((elem) => {
            const findedAmountPart = (this.item.amounts as PartAmount[])
              .find((amount) => amount.storage_id === elem.id);
            if (findedAmountPart) {
              this.partAmountTableItem.push({
                storage: elem,
                amount: findedAmountPart.amount,
                cell: findedAmountPart.cell as string,
              });
            }
          });
          return;
        }
      }
      if (this.partAmountTableItem.length) {
        return;
      }
      this.partAmountTableItem = [];
      allStorage.forEach((storage) => {
        this.partAmountTableItem.push({
          storage,
          amount: 0,
          cell: '',
        });
      });
    } catch (err) {
      throw new Error(err);
    }
  }

  @Watch('item')
  setPhotoPreview() {
    this.checkingPartAmount();
  }
}
