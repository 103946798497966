import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb, ActionBtn, User } from '@/types';
import PostingFilterForm from '@/components/posting-filter-form';
import PostingProvider from '../../api/providers/posting';
import ErrorAccessRights from '../../components/error-access-rights';
import Numbers from '../../components/forms/numbers';
import ViewUserName from '../../components/forms/user-name';

@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    'user-name': ViewUserName,
    'error-access-rights': ErrorAccessRights,
    PostingFilterForm,
  },
})
export default class PostingPage extends Vue {
    @Action addActionBtn;

    @Action addBreadcrumb;

    @Getter user;

    loading = true;

    filterForm = null;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Поступления на склад', link: null }];

    actionBtn: ActionBtn[] = [{
      id: 1, type: 'add', title: 'Новое поступление', link: '/part/posting/add', icon: 'fa-plus',
    }];

    posting_list: object[] = [];

    posting_list_page_count: number | null = null;

    get rightsUser() {
      return this.user.rights ? this.user.rights.can_create_posting_parts : false;
    }

    getPostingList(page) {
      PostingProvider.getPostingList(page, this.filterForm)
        .then((res) => {
          this.posting_list = res.data;
          this.posting_list_page_count = Number(res.pageCount);

          this.loading = false;
        }).catch((err) => {
          this.loading = false;
          console.error(err);
        });
    }

    formattingUsers = (user: User) => {
      if (user) {
        const lastName: string = user.last_name ? user.last_name : '';
        const firstName: string = user.first_name ? `${user.first_name.slice(0, 1).toUpperCase()}.` : '';
        const middleName: string = user.middle_name ? `${user.middle_name.slice(0, 1).toUpperCase()}.` : '';
        return `${lastName} ${firstName} ${middleName}`;
      }
      return '-';
    }

    created() {
      this.getPostingList(1);
      document.title = 'Поступления на склад';
    }

    mounted() {
      this.addBreadcrumb(this.breadcrumb);
      if (this.user.rights
        && this.user.rights.can_create_posting_parts) this.addActionBtn(this.actionBtn);
    }

    destroyed() {
      this.addActionBtn([]);
    }

    loadPage(val) {
      this.loading = true;
      this.getPostingList(val);
      window.scroll(0, 0);
    }

    date = (value) => {
      const options: object = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
      };
      const date: string = new Date(value * 1000).toLocaleString('ru-RU', options).replace(',', '');
      return date;
    }

    onDetail(row, _, event) {
      if (window.getSelection()?.toString().trim().length !== 0) return;
      if (event.ctrlKey) {
        this.targetBlankDetail(row.id);
        return;
      }
      this.$router.push(`/part/posting/view/${row.id}`);
    }

    targetBlankDetail(postingId) {
      const route_data = this.$router.resolve({ name: 'view-posting', params: { id: postingId } });
      window.open(route_data.href, '_blank');
    }

    changeFilter(filterForm) {
      this.filterForm = { ...filterForm };
      this.getPostingList(1);
    }

    @Watch('user')
    meUser(user: User) {
      if (user.rights) {
        if (user.rights.can_create_posting_parts) {
          this.addActionBtn(this.actionBtn);
        } else {
          this.addActionBtn([]);
        }
      } else {
        this.addActionBtn([]);
      }
    }
}
