import Vue from 'vue';
import { Form } from 'element-ui';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ClientSource } from '@/types';
import ClientSourceProvider from '../../api/providers/client-source';

@Component({
  template: require('./index.html'),
})
export default class ClientSourcePage extends Vue {
    @Action addBreadcrumb;

    @Getter btnloader;

    loading = true;

    dialogEdit = false;

    dialogDelete = false;

    newItem: object = { name: '' };

    editItem: object = {};

    clientSource: ClientSource [] = [];

    clientSourcePageCount = 1;

    currentPage = 1;

    beforeMount() {
      this.getClientSourceList(1);
    }

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: '/settings' },
        { id: 2, section: 'Справочники', link: null },
        { id: 3, section: 'Источники клиентов', link: null },
      ]);
      document.title = 'Источники клиентов';
    }

    deleteClientSource(id: number) {
      ClientSourceProvider.deleteClientSource(id)
        .then((res) => {
          const index = this.clientSource.findIndex((i) => i.id === res.data.id);
          this.clientSource.splice(index, 1);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Успешно удалено ', item: res.data.name });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    getClientSourceList(page: number) {
      ClientSourceProvider.getClientSourceList(page)
        .then((res) => {
          this.clientSource = res.data;
          this.clientSourcePageCount = res.pageCount;
        })
        .catch((err) => console.error(err));
    }

    sendClientSource(item) {
      this.$store.commit('BTN_LOADER', true);
      ClientSourceProvider.sendClientSource(item)
        .then(() => {
          this.getClientSourceList(this.currentPage);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: item.name });
          this.$store.commit('BTN_LOADER', false);
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.$store.commit('BTN_LOADER', false);
        });
    }

    changeClientSource(item: object) {
      this.$store.commit('BTN_LOADER', true);
      ClientSourceProvider.changeClientSource(item)
        .then((res) => {
          const index = this.clientSource.findIndex((i) => i.id === res.data.id);
          this.clientSource.splice(index, 1);
          this.clientSource.splice(index, 0, res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Успешно изменено ', item: res.data.name });
          this.$store.commit('BTN_LOADER', false);
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.$store.commit('BTN_LOADER', false);
        });
    }

    onSendClientSource(formName) {
      const refForm = this.$refs[formName] as Form;
      refForm.validate((valid) => (valid ? this.sendClientSource(this.newItem) : false));
    }

    onChangeClientSource(formName) {
      const refForm = this.$refs[formName] as Form;
      refForm.validate((valid) => (valid ? this.changeClientSource(this.editItem) : false));
    }

    resetFormAddClient() {
      (this.$refs.formAddClientSource as Form).resetFields();
    }

    resetFormEditClient() {
      if (this.$refs.formEditClientSource !== undefined) {
        (this.$refs.formEditClientSource as Form).resetFields();
      }
    }

    clearForm() {
      this.newItem = { name: '' };
      this.editItem = {};
    }

    onDialogEdit(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.dialogEdit = !this.dialogEdit;
    }

    onDialogDelete(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.dialogDelete = !this.dialogDelete;
    }

    loadPage(val) {
      this.getClientSourceList(val);
    }

    @Watch('clientSource')
    onLoad() {
      this.$nextTick(() => {
        this.loading = false;
      });
      this.dialogEdit = false;
      this.clearForm();
      this.resetFormAddClient();
      this.resetFormEditClient();
    }
}
