import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AnalyticsWidget } from '@/types';
import AnalyticsProvider from '../../api/providers/analytics';
import ErrorAccessRights from '../../components/error-access-rights';
import SettingAnalyticsWidgets from '../../components/setting-analytics-widgets';

@Component({
  template: require('./index.html'),
  components: {
    'error-access-rights': ErrorAccessRights,
    'setting-analytics-widgets': SettingAnalyticsWidgets,
  },
})
export default class Analytics extends Vue {
    @Action addActionBtn;

    @Action addBreadcrumb;

    @Getter user;

    analytics_dashboard_widgets: AnalyticsWidget[] = [];

    analytics_all_widgets: AnalyticsWidget[] = [];

    view_settings_dashboard_widgets = false;

    loading_dashboard_widgets = false;

    loading_btn = false;

    get rightsUserCanManageAnalytics() {
      return this.user.rights ? this.user.rights.can_manage_analytics : false;
    }

    diffWidgetsDashboard = (widgets_in, widgets_out) => widgets_out.filter(
      (a1) => !widgets_in.some((a2) => a1.id === a2.id),
    ).filter((item) => item)

    getAnalyticsWidgets() {
      AnalyticsProvider.getFullgetAnalyticsWidgets()
        .then((data) => {
          this.analytics_all_widgets = this.diffWidgetsDashboard(
            this.analytics_dashboard_widgets,
            data,
          );
        }).catch((err) => console.error(err));
    }

    getAnalyticsDashboard(request_analytics_widget = true) {
      this.loading_dashboard_widgets = true;
      AnalyticsProvider.getFullAnalyticsDashboard()
        .then((data) => {
          this.analytics_dashboard_widgets = data;
          this.loading_dashboard_widgets = false;

          if (request_analytics_widget) this.getAnalyticsWidgets();

          (data as AnalyticsWidget[]).forEach((widget, i) => {
            const script = document.createElement('script');
            script.setAttribute('src', widget.script_url);
            script.setAttribute('async', '');
            script.setAttribute('type', 'application/javascript');

            setTimeout(() => {
              if (document.getElementsByClassName('widget_container').length) {
                document.getElementsByClassName('widget_container')[i].appendChild(script);
              }
            }, 500);
          });
        }).catch((err) => {
          this.loading_dashboard_widgets = false;

          if (request_analytics_widget) this.getAnalyticsWidgets();
          console.error(err);
        });
    }

    transferWidgetInDashboard(widgets) {
      this.analytics_all_widgets = this.analytics_all_widgets.filter((widget) => !widget.active);
      widgets.forEach((widget) => this.analytics_dashboard_widgets.push(widget));

      this.analytics_dashboard_widgets.forEach((widget, i) => {
        if (widget.active) delete this.analytics_dashboard_widgets[i].active;
      });
    }

    transferWidgetOutDashboard(widgets) {
      this.analytics_dashboard_widgets = this.analytics_dashboard_widgets.filter(
        (widget) => !widget.active,
      );
      widgets.forEach((widget) => this.analytics_all_widgets.push(widget));

      this.analytics_all_widgets.forEach((widget, i) => {
        if (widget.active) delete this.analytics_all_widgets[i].active;
      });
    }

    saveSettingsDashboardWidgets() {
      const ids: number[] = [];

      this.analytics_dashboard_widgets.forEach((widget) => ids.push(widget.id));

      this.loading_btn = true;

      AnalyticsProvider.updateDashboard({ widget_ids: ids })
        .then(() => {
          this.loading_btn = false;
          this.view_settings_dashboard_widgets = false;

          this.getAnalyticsDashboard(false);
        }).catch((err) => {
          this.loading_btn = false;
          this.view_settings_dashboard_widgets = false;
          console.log(err.response.data);
        });
    }

    mounted() {
      this.getAnalyticsDashboard();
      document.title = 'Аналитика';
      this.addBreadcrumb([{ id: 1, section: 'Аналитика', link: null }]);
      this.addActionBtn([{
        id: 1, type: 'edt', title: 'Настроить аналитику', link: 'onDialog', icon: 'fas fa-cogs',
      }]);
      this.$root.$on('onDialog', () => {
        this.view_settings_dashboard_widgets = true;
      });
    }

    beforeDestroy() {
      this.addActionBtn([]);
    }
}
