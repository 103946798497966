import { GetterTree } from 'vuex';
import { StateStuff } from './types';

const getters: GetterTree<StateStuff, object> = {
  stuff: (state) => state.stuff,
  viewStuff: (state) => state.viewStuff,
  validStuff: (state) => state.validStuff,
  stuffPageCount: (state) => state.stuffPageCount,
  stuffPageCurrent: (state) => state.stuffPageCurrent,
  salaryPeriodTypeList: (state) => state.salaryPeriodTypeList,
  sellPartSalaryTypeList: (state) => state.sellPartSalaryTypeList,
  repairPartSalaryType: (state) => state.repairPartSalaryType,
  fullEmployeeName: (state) => {
    const last_name = state.viewStuff.last_name || '';
    const first_name = state.viewStuff.first_name || '';
    const middle_name = state.viewStuff.middle_name || '';

    return `${last_name} ${first_name} ${middle_name}`;
  },
  shortEmployeeName: (state) => {
    const last_name = state.viewStuff.last_name || '';
    const first_name = state.viewStuff.first_name
      ? state.viewStuff.first_name[0] : '';
    const middle_name = state.viewStuff.middle_name
      ? state.viewStuff.middle_name[0] : '';

    return `${last_name} ${first_name}. ${middle_name}.`;
  },
};

export default getters;
