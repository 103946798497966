import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { PriceLevel } from '@/types';
import { PricesLevelRoundType } from '@/classes';
import PriceLevelProvider from '@/api/providers/price-level';
import ChangingInputNumber from '@/components/changing-input-number';
import ChangingInputSelection from '@/components/changing-input-selection';

@Component({
  template: require('./index.html'),
  components: {
    ChangingInputNumber,
    ChangingInputSelection,
  },
})
export default class PricesLevelTable extends Vue {
    loadingTableData = false;

    newItem: object = {};

    pricesLevelTableData: PriceLevel[] = [];

    levelRoundType: PricesLevelRoundType = new PricesLevelRoundType()

    choosedPricesLevelTableItem!: PriceLevel;

    choosedPricesLevelTableItemKey = '';

    mounted() {
      this.getAllPriceLevel();
    }

    getAllPriceLevel() {
      this.loadingTableData = true;
      PriceLevelProvider.viewAllPriceLevel()
        .then((res) => {
          this.pricesLevelTableData = res;
          this.loadingTableData = false;
        })
        .catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка загрузка данных', item: '' });
          this.loadingTableData = false;
          throw new Error(err);
        });
    }

    getPriceRoundType(id: number): string {
      const roundType = this.levelRoundType.priceRoundTypes.find((type) => type.id === id);
      if (roundType) {
        return roundType.label;
      }
      return 'Неизвестен';
    }

    getPriceRoundOrder(id: number): string {
      const roundType = this.levelRoundType.priceRoundOrder.find((type) => type.id === id);
      if (roundType) {
        return roundType.label;
      }
      return 'Неизвестен';
    }

    inputChoosed(pricesLevelTableItem: PriceLevel, key: string) {
      setTimeout(() => {
        this.choosedPricesLevelTableItem = pricesLevelTableItem;
        this.choosedPricesLevelTableItemKey = key;
      }, 100);
    }

    inputChanging(changingInputText: string) {
      const lastInputValue = this.choosedPricesLevelTableItem[this.choosedPricesLevelTableItemKey];
      this.choosedPricesLevelTableItem[this.choosedPricesLevelTableItemKey] = Number(
        changingInputText,
      );
      const choosedId = this.choosedPricesLevelTableItem.id as number;

      PriceLevelProvider.updatePriceLevel(choosedId, this.choosedPricesLevelTableItem)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Уровень наценки обновлен' });
          this.getAllPriceLevel();
        })
        .catch((err) => {
          this.choosedPricesLevelTableItem[this.choosedPricesLevelTableItemKey] = lastInputValue;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось обновить уровень наценки!' });
          throw new Error(err);
        });
    }

    handleDeletePriceLevelConfirm(index: number, row: PriceLevel) {
      this.$confirm('Вы точно хотите удалить уровень наценки?', 'Удаление уровеня наценки', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'error',
      }).then(() => {
        this.deletePriceLevel(index, row);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Удаление отменено',
        });
      });
    }

    deletePriceLevel(index: number, row: PriceLevel) {
      PriceLevelProvider.deletePriceLevel(row.id as number)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Уровень наценки успешно удален!' });
          this.deletePriceLevelFromTable(index);
        })
        .catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось удалить уровень наценки!' });
          throw new Error(err);
        });
    }

    deletePriceLevelFromTable(index: number) {
      this.pricesLevelTableData.splice(index, 1);
    }
}
