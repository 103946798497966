export const GET_STATEOPTION_LIST = 'GET_STATEOPTION_LIST';
export const SEND_STATEOPTION_LIST = 'SEND_STATEOPTION_LIST';
export const UPDATE_STATEOPTION_LIST = 'UPDATE_STATEOPTION_LIST';
export const DELETE_STATEOPTION_LIST = 'DELETE_STATEOPTION_LIST';
export const SEND_STATEOPTION_LIST_PRIORITY = 'SEND_STATEOPTION_LIST_PRIORITY';

// Store

export interface StateStateOption {
    stateoptionList: object[];
    addStateOptionList: object;
    updateStateOptionList: object;
    deleteStateOptionList: object;
    sendStateOptionPriority: object;
}
