import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ExportLogItemViewModel } from '@/classes';
import { User } from '@/types';
import ViewDate from '../forms/date';

@Component({
  template: require('./export-log.html'),
  components: {
    date: ViewDate,
  },
})
export default class ExportLog extends Vue {
  @Prop() activeTab!: string;

  @Prop() stuff!: User[];

  @Action getExportList;

  @Getter export;

  @Getter btnloader;

  @Getter user;

  @Getter exportPageCount;

  tableData: ExportLogItemViewModel[] = [];

  page = 1;

  totalPages = 0;

  mounted() {
    this.getExportList(this.page);
  }

  private buildTableData(response) {
    response.forEach((item) => {
      let createdBy = '';
      this.stuff.forEach((users) => {
        if (users.id === item.created_by && users.first_name && users.middle_name) {
          createdBy = `${users.last_name} ${users.first_name[0].toUpperCase()}. ${users.middle_name[0].toUpperCase()}.`;
        }
      });
      this.tableData.push(
        new ExportLogItemViewModel(
          item.created_at,
          item.type,
          createdBy,
          item.email,
          item.status,
        ),
      );
    });
  }

  search() {
    this.getExportList(this.page);
  }

  loadPage(page: number) {
    this.page = page;
    this.search();
  }

  @Watch('export')
  exportList(list: object[]) {
    this.tableData = [];
    this.buildTableData(list);
  }
}
