import { MutationTree } from 'vuex';
import {
  ADD_BARCODE_LIST,

  CLEAR_LIST,
  CLEAR_PRICETAG_ITEM, COPY_PART_ITEM,

  DELETE_BARCODE, DELETE_CATALOG_ITEM,

  DELETE_PART, DELETE_PRICETAG_ITEM, EDIT_PART, GET_GENERATION_BARCODE,

  GET_HEAD_PART, GET_LOGS, GET_PART,
  GET_PART_PAGE,

  GET_REWARD_TYPE_LIST,

  GET_VALID_PART, GET_VATLIST, GET_VIEW_PART,

  GET_WARRANT_TYPE_LIST,

  RELOAD_HEAD_NOMENKL, RELOAD_HEAD_PART, SEND_PRICETAG_ITEM,

  StateParts,
} from './types';

const mutations: MutationTree<StateParts> = {
  [GET_PART](state, {
    item, pageCount, pageCurrent, amountControl,
  }) {
    state.parts = item;
    state.partPageCount = pageCount;
    state.partPageCurrent = pageCurrent;
    state.partAmountControl = amountControl;

    const localStor = JSON.parse(localStorage.getItem('headNomenkl') as string);
    if (localStor) state.headNomenkl = localStor;
  },

  [GET_PART_PAGE](state, {
    item, pageCount, pageCurrent, amountControl,
  }) {
    state.parts = state.parts.concat(item);
    state.partPageCount = pageCount;
    state.partPageCurrent = pageCurrent;
    state.partAmountControl = amountControl;
  },

  [GET_VIEW_PART](state, { item }) {
    // После кореектной работы модального окна, добавить товар
    // state.parts.push({
    //     amount: 167651,
    //     part: item,
    //     part_id: 1,
    //     storage_id: 1,
    // });
    state.viewPart = item;
  },

  [GET_VATLIST](state, { item }) {
    const vatlist: object[] = [];
    Object.keys(item).forEach((i) => {
      if (i) {
        vatlist[Number(i) - 1] = { id: Number(i), name: item[i] };
      }
    });
    state.vatlist = vatlist;
  },

  [GET_WARRANT_TYPE_LIST](state, { item }) {
    const warrantTypeList: object[] = [];
    Object.keys(item).forEach((i) => {
      if (i) {
        warrantTypeList[Number(i) - 1] = { id: Number(i), name: item[i] };
      }
    });
    state.warrantTypeList = warrantTypeList;
  },

  [GET_REWARD_TYPE_LIST](state, { item }) {
    const rewardTypeList: object[] = [];
    Object.keys(item).forEach((i) => {
      if (i) {
        rewardTypeList[Number(i) - 1] = { id: Number(i), name: item[i] };
      }
    });
    state.rewardTypeList = rewardTypeList;
  },

  [GET_GENERATION_BARCODE](state, { item }) {
    state.barcode = item;
  },

  [ADD_BARCODE_LIST](state, { item }) {
    state.barcodeList.push(item);
  },

  [GET_VALID_PART](state, { valid }) {
    state.validPart = valid;
  },

  [EDIT_PART](state, { item }) {
    const index = state.parts.findIndex((i) => i.id === item.id);
    state.parts.splice(index, 1);
    state.parts.splice(index, 0, item);
  },

  [DELETE_CATALOG_ITEM](state, index) {
    state.catalogs.splice(index, 1);
  },

  [COPY_PART_ITEM](state, { item, index }) {
    state.parts.splice(index + 1, 0, item);
  },

  [CLEAR_LIST](state) {
    state.barcodeList = [];
    state.validPart = [];
  },

  [GET_LOGS](state, { item, pageCount }) {
    state.logs = item;
    state.logsPageCount = pageCount;
  },

  [GET_HEAD_PART](state, { storage }) {
    const localStor = JSON.parse(localStorage.getItem('headPart') as string);

    function genHead(stor, local) {
      if (stor.length > local.length) {
        const addArrItems = stor.filter(
          (storItem) => local.map(
            (localItem) => localItem.storage_id,
          ).indexOf(storItem.id) < 0,
        );
        addArrItems.forEach((item) => {
          local.push(
            { storage_id: item.id, name: item.name, active: true },
          );
        });
      } else {
        const delArrItems = local.filter(
          (localItem) => stor.map(
            (storItem) => storItem.id,
          ).indexOf(localItem.storage_id) < 0,
        );
        if (delArrItems.length) {
          delArrItems.forEach((item) => {
            const indx = local.indexOf(item);
            local.splice(indx, 1);
          });
          genHead(storage, localStor.amounts);
        }
      }
    }

    if (localStor) {
      genHead(storage, localStor.amounts);
      state.headPart = localStor;
    } else {
      const arr: object[] = [];
      storage.forEach((item) => {
        arr.push(
          { storage_id: item.id, name: item.name, active: true },
        );
      });
      state.headPart.amounts = arr;
    }
  },

  [DELETE_BARCODE](state, { item }) {
    if (state.viewPart.barcodes) {
      const index = state.viewPart.barcodes.findIndex((i) => i.id === item.id);
      state.viewPart.barcodes.splice(index, 1);
    }
  },

  [DELETE_PART](state, { item }) {
    const index = state.parts.findIndex((i) => i.id === item.id);
    state.parts.splice(index, 1);
  },

  [RELOAD_HEAD_PART](state, head) {
    localStorage.setItem('headPart', JSON.stringify(head));
    state.headPart = head;
  },

  [RELOAD_HEAD_NOMENKL](state, head) {
    localStorage.setItem('headNomenkl', JSON.stringify(head));
    state.headNomenkl = head;
  },

  // PRICE_TAG_LIST

  [SEND_PRICETAG_ITEM](state, { item }) {
    state.partPriceTagNewItem.push(item);
  },

  [CLEAR_PRICETAG_ITEM](state) {
    state.partPriceTagNewItem = [];
  },

  [DELETE_PRICETAG_ITEM](state, item) {
    const index = state.partPriceTagNewItem.findIndex(
      (i) => i.id === item.id || i.part_id === item.id,
    );
    state.partPriceTagNewItem.splice(index, 1);
  },

};

export default mutations;
