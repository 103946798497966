import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({
  template: require('./index.html'),
  components: {},
})

export default class SearchTransfersRepair extends Vue {
  @Action getRepairTransferList;

  @Prop() data!: object;

  statusTransfer: object[] = [
    { id: 1, name: 'Создано' },
    { id: 2, name: 'Начато' },
    { id: 3, name: 'Завершено' },
    { id: 4, name: 'Отменено' },
  ];
}
