import BaseProvider from '../base-provider';

class TemplateFormProvider extends BaseProvider {
  async getTemplateFormList(page) {
    const res = await this.request('GET', `/customfield/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getTemplateForm(id) {
    try {
      return await this.request('GET', `/customfield/template?formId=${id}`);
    } catch (err) {
      throw new Error(err);
    }
  }

  async sendTemplateForm(item) {
    const data: object = { CustomField: item };
    const res = await this.request('POST', '/customfield/add', data);
    return res;
  }

  async changeTemplateLayoutForm(id, item) {
    const data: object = { FormLayout: item };
    try {
      return await this.request('POST', `/customfield/updatetemplate?formId=${id}`, data);
    } catch (err) {
      throw new Error(err);
    }
  }

  async changeTemplateForm(id, item) {
    const data: object = { CustomField: item };
    const res = await this.request('POST', `/customfield/update?id=${id}`, data);
    return res;
  }

  async deleteTemplateForm(id) {
    const res = await this.request('POST', `/customfield/delete?id=${id}`);
    return res;
  }
}

export default new TemplateFormProvider();
