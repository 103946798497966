import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { SalaryOperation, User } from '@/types';
import SalaryTransferProvider from '../../api/providers/salarytransfer';

@Component({
  template: require('./index.html'),
})
export default class PayoutOperations extends Vue {
  @Prop() salaryOperations!: SalaryOperation[];

  @Prop() isPayoutDialogVisible!: boolean;

  @Getter cashList;

  @Action getFullCashList;

  btnloader = false;

  dialogFormPayment = {
    selectedCashBoxId: null,
  }

  get isSubmitTransferDisabled() {
    return !(this.salaryOperations?.length !== 0
      && this.dialogFormPayment.selectedCashBoxId !== null);
  }

  submitSalaryTransfer() {
    const allUsersId: number[] = [];
    const allUser: User[] = [];

    this.salaryOperations.forEach((salaryOperation) => {
      const foundedUserId = allUsersId.findIndex((userId) => userId === salaryOperation.user_id);
      if (foundedUserId === -1) {
        allUsersId.push(salaryOperation.user_id);
        allUser.push(salaryOperation.user as User);
      }
    });
    allUsersId.forEach(async (userId, index) => {
      const userFullName = `${allUser[index].last_name as string} ${
         allUser[index].first_name as string} ${
       allUser[index].middle_name as string}`;
      await this.submitSalaryTransferByOneUser(userId, userFullName);
    });
  }

  async submitSalaryTransferByOneUser(userId: number, userFullName: string) {
    const operationByUser = this.salaryOperations.filter(
      (salaryOperation) => salaryOperation.user_id === userId,
    );
    const form = {
      operation_ids: operationByUser.map((value) => value.id),
      cashbox_id: this.dialogFormPayment.selectedCashBoxId,
    };
    try {
      await this.addSalaryTransfer(form, userFullName);
      this.$emit('operationTransfered');
    } catch (err) {
      throw new Error(err);
    }
    this.closePayoutOperations();
  }

  async addSalaryTransfer(form, userFullName) {
    this.btnloader = true;
    try {
      await SalaryTransferProvider.salaryTransferAdd({
        form,
      });
      this.btnloader = false;
      this.$store.commit(
        'PUSH_CALL_SUCCESS',
        { title: `Выплата для ${userFullName} произошла успешно`, item: '' },
      );
    } catch (err) {
      this.btnloader = false;
      this.$store.commit('PUSH_CALL_ERROR', { title: `Выплата для ${userFullName} произошла c  ошибкой`, item: '' });
      throw (err);
    }
  }

  closePayoutOperations() {
    this.$emit('closePayoutOperations');
  }

  mounted() {
    this.getFullCashList(false);
  }
}
