import Vue from 'vue';
import { Form } from 'element-ui';
import isEqual from 'lodash/isEqual';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { SalaryItem } from '@/types';
import SalaryOperation from '../../../api/providers/salaryoperation';
import Numbers from '../../forms/numbers';
import SalaryOperationTable from '../salary-operatiorn-table';

interface DialogFormPayment {
  payoutSum: number;
  selectedCashBoxId: number | null;
}

interface Response {
  data: SalaryItem[];
  currentPage: number;
}

@Component({
  template: require('./index.html'),
  components: {
    SalaryOperationTable,
    Numbers,
  },
})

export default class SalaryViewCurrent extends Vue {
  @Getter viewStuff;

  @Getter shortEmployeeName;

  @Getter cashList;

  @Getter btnloader;

  @Getter user;

  @Action addSalaryTransfer;

  @Action getFullCashList;

  isPayoutDialogVisible = false;

  user_id = '';

  activeTab = 'current';

  loadingSalaryOperation = true;

  isDialogVisible = false;

  whichOperation = '';

  operationToBeUpdated = undefined;

  dialogFormModel = { sum: '', comment: '' };

  dialogFormPayment: DialogFormPayment = { payoutSum: 0, selectedCashBoxId: null };

  dialogFormValidationRules = {
    sum: [
      { required: true, message: 'Укажите сумму', trigger: 'change' },
      {
        type: 'number',
        message: 'Значение должно быть числом',
        trigger: 'change',
      },
      {
        type: 'number',
        min: 0.01,
        message: 'Число должно быть больше 0,01',
        trigger: 'change',
      },
    ],
  };

  selectedOperation: SalaryItem[] = [];

  startDate: Date | null = null;

  endDate: Date | null = null;

  pageStuffSalaryOperation = 1;

  cash_list_page_count = 1;

  group_salary_operation = 'Простой список';

  salaryOperationUnpaidListByUserId: SalaryItem[] = [];
  // hooks

  created() {
    this.user_id = this.$route.params.user_id;
    this.getAllSalaryOperationUnpaidListByUserId();
  }

  mounted() {
    this.getFullCashList(false);
  }

  destroyed() {
    this.clearSalaryOperation();
  }

  // computed

  get fullName() {
    return Object.keys(this.viewStuff).length !== 0 ? `${this.viewStuff.last_name} ${this.viewStuff.first_name} ${this.viewStuff.middle_name}` : '';
  }

  get post() {
    return Object.keys(this.viewStuff).length !== 0 ? this.viewStuff.post : '';
  }

  get rightsUserCanManageStuff() {
    return this.user.rights ? !this.user.rights.can_manage_stuff : false;
  }

  get dialogTitle() {
    switch (this.whichOperation) {
      case 'bonus':
        return `Премирование сотрудника ${this.shortEmployeeName}`;
      case 'penalty':
        return `Взыскание с сотрудника ${this.shortEmployeeName}`;
      case 'update':
        return 'Изменение начисления ЗП';
      default:
        return this.shortEmployeeName;
    }
  }

  // группировока начислений по списку

  get bonusOperation() {
    return this.salaryOperationUnpaidListByUserId.filter((operation) => operation.act_type === 1);
  }

  get selectedBonusOperation() {
    return this.selectedOperation.filter((operation) => operation.act_type === 1);
  }

  get salaryOperation() {
    return this.salaryOperationUnpaidListByUserId.filter((operation) => operation.act_type === 2);
  }

  get selectedSalaryOperation() {
    return this.selectedOperation.filter((operation) => operation.act_type === 2);
  }

  get agreedRepairOperation() {
    return this.salaryOperationUnpaidListByUserId.filter((operation) => operation.act_type === 3);
  }

  get selectedAgreedRepairOperation() {
    return this.selectedOperation.filter((operation) => operation.act_type === 3);
  }

  get usingPartsOfRepairOperation() {
    return this.salaryOperationUnpaidListByUserId.filter((operation) => operation.act_type === 4);
  }

  get selectedUsingPartsOfRepairOperation() {
    return this.selectedOperation.filter((operation) => operation.act_type === 4);
  }

  get transportOutRepairsOperation() {
    return this.salaryOperationUnpaidListByUserId.filter((operation) => operation.act_type === 5);
  }

  get selectedTransportOutRepairsOperation() {
    return this.selectedOperation.filter((operation) => operation.act_type === 5);
  }

  get sellPartOperation() {
    return this.salaryOperationUnpaidListByUserId.filter((operation) => operation.act_type === 6);
  }

  get selectedSellPartOperation() {
    return this.selectedOperation.filter((operation) => operation.act_type === 6);
  }

  get sellWorkOperation() {
    return this.salaryOperationUnpaidListByUserId.filter((operation) => operation.act_type === 7);
  }

  get selectedSellWorkOperation() {
    return this.selectedOperation.filter((operation) => operation.act_type === 7);
  }

  get penaltyOperation() {
    return this.salaryOperationUnpaidListByUserId.filter(
      (operation) => operation.act_type === -1,
    );
  }

  get selectedPenaltyOperation() {
    return this.selectedOperation.filter(
      (operation) => operation.act_type === -1,
    );
  }

  get cancelPartOperation() {
    return this.salaryOperationUnpaidListByUserId.filter(
      (operation) => operation.act_type === -2,
    );
  }

  get selectedCancelPartOperation() {
    return this.selectedOperation.filter((operation) => operation.act_type === -2);
  }

  // Полные суммы у сгруппировоанных начислений

  get totalAmountBonusOperation() {
    return this.bonusOperation.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.sum),
      0,
    );
  }

  get totalAmountSalaryOperation() {
    return this.salaryOperation.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.sum),
      0,
    );
  }

  get totalAmountAgreedRepairOperation() {
    return this.agreedRepairOperation.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.sum),
      0,
    );
  }

  get totalAmountUsingPartsOfRepairOperation() {
    return this.usingPartsOfRepairOperation.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.sum),
      0,
    );
  }

  get totalAmountTransportOutRepairsOperation() {
    return this.transportOutRepairsOperation.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.sum),
      0,
    );
  }

  get totalAmountSellPartOperation() {
    return this.sellPartOperation.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.sum),
      0,
    );
  }

  get totalAmountSellWorkOperation() {
    return this.sellWorkOperation.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.sum),
      0,
    );
  }

  get totalAmountPenaltyOperation() {
    return this.penaltyOperation.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.sum),
      0,
    );
  }

  get totalAmountCancelPartOperation() {
    return this.cancelPartOperation.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.sum),
      0,
    );
  }

  get totalSalaryOperationAmount() {
    let sums = 0;
    this.salaryOperationUnpaidListByUserId.forEach((operation) => {
      sums += operation.sum;
    });
    return sums;
  }

  /**
   * Суммирует два числа с устранением погрешности Float
   * Округляет значение до двух знаков после запятой
   */
  sumAsDecimals = (a: number, b: number): number => {
    const persision = 2;
    const intA = Math.round(a * (10 ** persision));
    const intB = Math.round(b * (10 ** persision));
    const intSum = intA + intB;
    return intSum / (10 ** persision);
  }

  /**
   * Вычитает из первого аргумента второй с устранением погрешности Float
   * Округляет значение до двух знаков после запятой
   */
  subtrackAsDecimals = (a: number, b: number): number => {
    const persision = 2;
    const intA = Math.round(a * (10 ** persision));
    const intB = Math.round(b * (10 ** persision));
    const intSum = intA - intB;
    return intSum / (10 ** persision);
  }

  get amountToBePayed() {
    return Number(
      this.selectedOperation.reduce(
        (prev, curr) => this.sumAsDecimals(prev, curr.sum), 0,
      ),
    );
  }

  get isPayoutModalCanBeShown() {
    return !(this.amountToBePayed > 0);
  }

  get isSubmitTransferDisabled() {
    return !(this.selectedOperation.length !== 0
      && this.dialogFormPayment.selectedCashBoxId !== null);
  }

  checkSalaryOperationPageCount() {
    this.clearSalaryOperation();
    this.getAllSalaryOperationUnpaidListByUserId();
  }

  // event handlers

  /**
   * Обработчик смены статуса галочкам выбора списка начислений
   *
   * @param id Идентификатор операции ЗП, которой сменили статус
   * @param is_selected Флаг, выбрана сейчас или нет эта операция
   */
  onChangeSalaryOperationSelection(id: number, is_selected: boolean) {
    if (is_selected) {
      const newSelectedOperation = this.salaryOperationUnpaidListByUserId.find(
        (x) => x.id === id,
      );
      if (newSelectedOperation && !this.selectedOperation.find((x) => x.id === id)) {
        this.selectedOperation.push(newSelectedOperation);
      }
    } else {
      this.selectedOperation = this.selectedOperation.filter((x) => x.id !== id);
    }
  }

  async onDeleteSalaryOperation(id) {
    this.$confirm('Вы уверены что хотите удалить начисление?', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Отмена',
      type: 'warning',
    }).then(async () => {
      this.pageStuffSalaryOperation = 0;
      await this.deleteSalaryOperation(id);
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Отмена удаления',
      });
    });
  }

  async onUpdateSalaryOperation({ id, sum, comment }) {
    this.operationToBeUpdated = id;
    this.dialogFormModel.sum = sum;
    this.dialogFormModel.comment = comment;
    this.openDialog({ whichOperation: 'update' });
  }

  async submitSalaryOperation() {
    (this.$refs.dialogForm as Form).validate(async (valid) => {
      if (valid) {
        try {
          await this.doSalaryOperation();
          if (this.whichOperation === 'update') {
            this.toggleRow(
              this.salaryOperationUnpaidListByUserId.find(
                (e) => e.id === this.operationToBeUpdated,
              ), false,
            );
            this.toggleRow(
              this.salaryOperationUnpaidListByUserId.find(
                (e) => e.id === this.operationToBeUpdated,
              ), true,
            );
            this.checkSalaryOperationPageCount();
          }
          this.updateDateRange();

          this.isPayoutDialogVisible = false;
          this.isDialogVisible = false;
          this.clearSalaryOperation();
          this.getAllSalaryOperationUnpaidListByUserId();
        } catch (err) {
          throw new Error(err);
        }
      }
    });
  }

  selectOperationsByDateRange() {
    if (!this.startDate || !this.endDate) return;

    const endDate = new Date(this.endDate);
    endDate.setDate(endDate.getDate() + 1);

    this.clearSelection();

    const sortedByDate = this.salaryOperationUnpaidListByUserId.filter(
      (elem) => new Date(elem.created_at * 1000) > (this.startDate as Date)
        && new Date(elem.created_at * 1000) < endDate,
    );

    sortedByDate.forEach((elem) => { this.selectedOperation.push(elem); });
  }

  clearDialogFormModel() {
    this.dialogFormModel = { sum: '', comment: '' };
    if (this.$refs.dialogForm) (this.$refs.dialogForm as Form).resetFields();
  }

  onBeforeCloseDialog(done) {
    this.clearDialogFormModel();
    done();
  }

  openDialog({ whichOperation }) {
    this.whichOperation = whichOperation;
    this.isDialogVisible = true;
  }

  async submitSalaryTransfer() {
    const form = {
      operation_ids: this.selectedOperation.map((value) => value.id),
      cashbox_id: this.dialogFormPayment.selectedCashBoxId,
    };

    if (this.dialogFormPayment.payoutSum !== this.amountToBePayed) {
      if (this.dialogFormPayment.payoutSum > this.amountToBePayed) {
        // устраняем ошибку погрешности Float
        const diff = this.subtrackAsDecimals(
          Number(this.dialogFormPayment.payoutSum), this.amountToBePayed,
        );
        try {
          // создаем премию
          const bonus = await SalaryOperation.salaryOperationBonus({
            form: {
              sum: diff,
              comment: 'Коррекционное начисление для выплаты более начисленной ЗП',
              user_id: this.user_id,
            },
          });
          // добавляем созданную приемию к выплате
          form.operation_ids.push(bonus.data.id);

          const status = await this.addSalaryTransfer({ form });
          if (status === 0) {
            this.selectedOperation.forEach(
              (value) => this.toggleRow(value, false),
            );
          }

          // создаем взыскание
          await SalaryOperation.salaryOperationPenalty({
            form: {
              sum: diff,
              comment: 'Коррекционное взыскание для компенсации выплаты более начисленной ЗП',
              user_id: this.user_id,
            },
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        const diff = this.amountToBePayed - Number(this.dialogFormPayment.payoutSum);

        try {
          // создаем взыскание
          const penalty = await SalaryOperation.salaryOperationPenalty({
            form: {
              sum: diff,
              comment: 'Коррекционное взыскание для компенсации выплаты более начисленной ЗП',
              user_id: this.user_id,
            },
          });

          // добавляем созданную приемию к выплате
          form.operation_ids.push(penalty.data.id);

          const status = await this.addSalaryTransfer({ form });
          if (status === 0) {
            this.selectedOperation.forEach(
              (value) => this.toggleRow(value, false),
            );
          }

          // создаем премию
          await SalaryOperation.salaryOperationBonus({
            form: {
              sum: diff,
              comment: 'Коррекционное начисление для выплаты более начисленной ЗП',
              user_id: this.user_id,
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      await this.addSalaryTransfer({ form });
    }

    this.clearSalaryOperation();
    this.getAllSalaryOperationUnpaidListByUserId();
    this.isPayoutDialogVisible = false;
  }

  @Watch('selectedOperation')
  onSelectedOperationChange(value, oldValue) {
    if (isEqual(value, oldValue)) {
      return;
    }
    this.updateDateRange();
  }

  // helpers

  updateDateRange() {
    if (this.selectedOperation.length === 0) {
      this.startDate = null;
      this.endDate = null;
      return;
    }

    const a = this.salaryOperationUnpaidListByUserId.slice(0)
      .sort((firstEl, secondEl) => secondEl.created_at - firstEl.created_at);
    const b = this.selectedOperation.slice(0)
      .sort((firstEl, secondEl) => secondEl.created_at - firstEl.created_at);
    const c = a.slice(
      a.findIndex((elem) => elem.id === b[0].id),
      a.findIndex((elem) => elem.id === b[b.length - 1].id) + 1,
    );

    if (!isEqual(b, c)) {
      this.startDate = null;
      this.endDate = null;
      return;
    }

    const lIndex = a.findIndex((elem) => elem.id === b[0].id);
    const rIndex = a.findIndex((elem) => elem.id === b[b.length - 1].id);

    let lDate = new Date(a[lIndex].created_at * 1000);
    lDate = new Date(
      lDate.getFullYear(),
      lDate.getMonth(),
      lDate.getDate(),
    );

    let rDate = new Date(a[rIndex].created_at * 1000);
    rDate = new Date(
      rDate.getFullYear(),
      rDate.getMonth(),
      rDate.getDate(),
    );

    if (lIndex !== 0) {
      let llDate = new Date(a[lIndex - 1].created_at * 1000);
      llDate = new Date(
        llDate.getFullYear(),
        llDate.getMonth(),
        llDate.getDate(),
      );

      if (llDate.getTime() === lDate.getTime()) {
        this.startDate = null;
        this.endDate = null;
        return;
      }
    }

    if (rIndex !== a.length - 1) {
      let rrDate = new Date(a[rIndex + 1].created_at * 1000);
      rrDate = new Date(
        rrDate.getFullYear(),
        rrDate.getMonth(),
        rrDate.getDate(),
      );

      if (rrDate.getTime() === rDate.getTime()) {
        this.startDate = null;
        this.endDate = null;
        return;
      }
    }

    this.startDate = rDate;
    this.endDate = lDate;
  }

  toggleRow(row, selected) {
    (this.$refs.salaryOperationTable as SalaryOperationTable).toggleRowSelection(row, selected);
  }

  clearSelection() {
    this.selectedOperation = [];
  }

  async doSalaryOperation() {
    const form = {
      sum: this.dialogFormModel.sum,
      comment: this.dialogFormModel.comment,
      user_id: this.user_id,
    };
    try {
      switch (this.whichOperation) {
        case 'bonus': return await this.bonusEmployee(form);
        case 'penalty': return await this.penaltyEmployee(form);
        case 'update':
          return await this.updateSalaryOperation({
            form,
            id: this.operationToBeUpdated,
          });
        default:
          return null;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  pickerOptionsStart() {
    return {
      disabledDate: (time) => {
        const end = this.endDate ? this.endDate.getTime() : Date.now();
        return time.getTime() > end;
      },
    };
  }

  pickerOptionsEnd() {
    return {
      disabledDate: (time) => {
        const start = this.startDate ? this.startDate : new Date(0);
        return time.getTime() > Date.now() || time.getTime() < start;
      },
    };
  }

  async getAllSalaryOperationUnpaidListByUserId() {
    this.loadingSalaryOperation = true;
    try {
      let page = 1;
      let salaryOperationUnpaidList: SalaryItem[] = [];
      const res = await SalaryOperation.salaryOperationIndex({
        form: {
          user_id: this.user_id,
          payed: '0',
        },
        vars: {
          page,
        },
      });
      salaryOperationUnpaidList = [...salaryOperationUnpaidList, ...res.data];
      const { pageCount } = res;
      if (pageCount > 1) {
        const promiseRequests: object[] = [];
        page += 1;
        for (page; page <= pageCount; page++) {
          promiseRequests.push(SalaryOperation.salaryOperationIndex({
            form: {
              user_id: this.user_id,
              payed: '0',
            },
            vars: {
              page,
            },
          }));
        }

        await Promise.all(promiseRequests)
          .then((results) => {
            (results as Response[]).sort((a, b) => a.currentPage - b.currentPage);
            (results as Response[]).forEach((result) => {
              salaryOperationUnpaidList = [...salaryOperationUnpaidList, ...result.data];
            });
          });
      }
      this.salaryOperationUnpaidListByUserId = salaryOperationUnpaidList;
      this.selectedOperation = this.salaryOperationUnpaidListByUserId;
    } catch (err) {
      console.error(err);
      throw (err);
    }
    this.loadingSalaryOperation = false;
  }

  clearSalaryOperation() {
    this.salaryOperationUnpaidListByUserId = [];
  }

  async deleteSalaryOperation(id: number) {
    try {
      await SalaryOperation.salaryOperationDelete({
        vars: {
          id,
        },
      });
      this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Операция успешно удалена', item: '' });
      this.clearSalaryOperation();
      this.getAllSalaryOperationUnpaidListByUserId();
    } catch (err) {
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      throw (err);
    }
  }

  async bonusEmployee(form) {
    this.$store.commit('BTN_LOADER', true);
    try {
      await SalaryOperation.salaryOperationBonus({
        form,
      });
      this.$store.commit('BTN_LOADER', false);
      this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Премия успешно добавлена!', item: '' });
      return 0;
    } catch (err) {
      this.$store.commit('BTN_LOADER', false);
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      throw (err);
    }
  }

  async penaltyEmployee(form) {
    this.$store.commit('BTN_LOADER', true);
    try {
      await SalaryOperation.salaryOperationPenalty({
        form,
      });
      this.$store.commit('BTN_LOADER', false);
      this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Взыскание успешно добавлено!', item: '' });
      return 0;
    } catch (err) {
      this.$store.commit('BTN_LOADER', false);
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      throw (err);
    }
  }

  async updateSalaryOperation({ form, id }) {
    this.$store.commit('BTN_LOADER', true);
    try {
      await SalaryOperation.salaryOperationUpdate({
        form,
        vars: {
          id,
        },
      });
      this.$store.commit('BTN_LOADER', false);
      this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Операция успешно изменена', item: '' });
      return 0;
    } catch (err) {
      this.$store.commit('BTN_LOADER', false);
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      throw (err);
    }
  }

  /**
   * Текстовое описание оклада
   */
  get personalSalaryDescription(): string {
    return this.viewStuff.salary_period === 1 ? `${this.viewStuff.salary_amount} в день` : `${this.viewStuff.salary_amount} в месяц`;
  }

  get personalRepairIncomeBonusDescription(): string {
    if (this.viewStuff.repair_income_salary_amount !== null) {
      switch (this.viewStuff.repair_income_salary_type) {
        case 0: return `${this.viewStuff.repair_income_salary_amount} ₽`;
        case 1: return `${this.viewStuff.repair_income_salary_amount}% от стоимости`;
        case 2: return `${this.viewStuff.repair_income_salary_amount}% от прибыли`;
        default: return `${this.viewStuff.repair_income_salary_amount} –`;
      }
    } else {
      return '&mdash;';
    }
  }

  get personalRepairPartBonusDescription(): string {
    if (this.viewStuff.repair_part_salary_amount !== null) {
      switch (this.viewStuff.repair_part_salary_type) {
        case 1: return `${this.viewStuff.repair_part_salary_amount}% от стоимости`;
        case 2: return `${this.viewStuff.repair_part_salary_amount}% от прибыли`;
        default: return `${this.viewStuff.repair_part_salary_amount} –`;
      }
    } else {
      return '&mdash;';
    }
  }

  get personalSellPartBonusDescription(): string {
    if (this.viewStuff.sell_part_salary_amount !== null) {
      switch (this.viewStuff.sell_part_salary_type) {
        case 1: return `${this.viewStuff.sell_part_salary_amount}% от стоимости`;
        case 2: return `${this.viewStuff.sell_part_salary_amount}% от прибыли`;
        default: return `${this.viewStuff.sell_part_salary_amount} –`;
      }
    } else {
      return '&mdash;';
    }
  }

  get personalRepairWorkBonusDescription(): string {
    return this.viewStuff.repair_work_salary_amount !== null ? `${this.viewStuff.repair_work_salary_amount}% от стоимости` : '&mdash;';
  }

  get personalSellWorkBonusDescription(): string {
    if (this.viewStuff.sell_work_salary_amount !== null) {
      return `${this.viewStuff.sell_work_salary_amount}% от стоимости`;
    }
    return '&mdash;';
  }

  get dialogBtnType() {
    switch (this.whichOperation) {
      case 'bonus': return 'success';
      case 'penalty': return 'danger';
      default: return 'primary';
    }
  }

  get dialogBtnText() {
    switch (this.whichOperation) {
      case 'bonus': return 'Премировать';
      case 'penalty': return 'Взыскать';
      default: return 'Сохранить';
    }
  }

  @Watch('cashList')
  userMe(list) {
    const filterCashListOnPoint = list.find((cash) => cash.point_id === this.user.point_id);

    this.dialogFormPayment.selectedCashBoxId = filterCashListOnPoint
      ? filterCashListOnPoint.id
      : null;
  }
}
