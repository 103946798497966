export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_VIEW_DOCUMENT = 'GET_VIEW_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const NEW_DOCUMENT = 'NEW_DOCUMENT';

// Store

export interface StateDocument {
  documentList: object[];
  pageDocumentList: number;
  document: object;
  newDocument: object;
  delDocument: object;
  editDocument: object;
  markersCompany: object[];
  markersOrder: object[];
  markersSales: object[];
  markersRepair: object[];
  markersAdditionally: object[];
  markersSoldProducts: object[];
  markersSoldWorks: object[];
  markersCustomFields: object[];
  markersSparePart: object[];
  markersServiceWork: object[];
}

// Models
