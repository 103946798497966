import { MutationTree } from 'vuex';
import {
  ADD_REPAIR_ADDITION, ADD_REPAIR_DISCOUNT,
  ADD_REPAIR_PAYMENT,

  ADD_REPAIR_TRANSFER,

  CLEAR_REPAIR_LIST, CLEAR_VALIDATE_REPAIR, DEL_REPAIR_PAYMENT, GET_REPAIR,

  GET_REPAIR_COMMENTS,

  GET_REPAIR_FILE, GET_REPAIR_LOG, GET_REPAIR_PAGE,

  GET_REPAIR_PAYMENT_LIST,

  GET_REPAIR_TABLE_LAYOUT, GET_REPAIR_TRANSFER, GET_REPAIR_VIEW,

  GET_VIEW_COMMENT,

  REPAIR_VALIDATION, SEND_CHANGE_STATUS_REPAIR_TRANSFER, SEND_REPAIR_ADD,

  SEND_REPAIR_ADDITION_DELETE,

  SEND_REPAIR_ADD_FILES, SEND_REPAIR_APPROVE_AGREEMENT, SEND_REPAIR_ASSIAGNMASTER,

  SEND_REPAIR_COMMENT,

  SEND_REPAIR_CONTINUE,
  SEND_REPAIR_DECLINE_AGREEMENT,

  SEND_REPAIR_DELAY, SEND_REPAIR_DELETE,

  SEND_REPAIR_DISCOUNT_DELETE,

  SEND_REPAIR_DISMISS_JOB,
  SEND_REPAIR_DONE,

  SEND_REPAIR_EJECT,
  SEND_REPAIR_RESTART, SEND_REPAIR_REVISION,

  SEND_REPAIR_START,

  SEND_REPAIR_TABLE_LAYOUT, SEND_REPAIR_TO_AGREEMENT, SEND_REPAIR_UPDATE,

  StateRepair,
} from './types';

const mutations: MutationTree<StateRepair> = {

  [GET_REPAIR](state, { item, pageCount, pageCurrent }) {
    state.repair = state.repair.concat(item);
    state.pageCountRepair = pageCount;
    state.pageCurrentRepair = pageCurrent;
  },

  [CLEAR_REPAIR_LIST](state) {
    state.repair = [];
  },

  [GET_REPAIR_PAGE](state, { item, pageCount, pageCurrent }) {
    state.repair = state.repair.concat(item);
    state.pageCountRepair = pageCount;
    state.pageCurrentRepair = pageCurrent;
  },

  [GET_REPAIR_VIEW](state, { item }) {
    state.viewRepair = item;
    item.repair_parts.forEach((element) => state.repairParts.push(element));
    item.repair_works.forEach((element) => state.repairParts.push(element));
  },

  [SEND_REPAIR_ASSIAGNMASTER](state, { item }) {
    state.repairAssignMaster = item;
  },

  [SEND_REPAIR_ADD](state, { item }) {
    state.addRepair = item;
  },

  [SEND_REPAIR_UPDATE](state, { item }) {
    state.repairUpdate = item;
  },

  [SEND_REPAIR_DELETE](state, { item }) {
    state.deleteRepair = item;
  },

  [SEND_REPAIR_COMMENT](state, { item }) {
    state.repairComment = item;
  },

  [GET_REPAIR_COMMENTS](state, { item }) {
    state.repairCommentsLog = item;
  },

  [GET_VIEW_COMMENT](state, { item }) {
    state.viewComment = item;
  },

  [GET_REPAIR_PAYMENT_LIST]() {
    // state.repairPaymentList = item;
  },

  [ADD_REPAIR_PAYMENT](state, { item }) {
    state.repairPaymentAdd = item;
  },

  [DEL_REPAIR_PAYMENT](state, { item }) {
    state.delPayment = item;
  },

  [ADD_REPAIR_DISCOUNT](state, { item }) {
    state.discount = item;
  },

  [SEND_REPAIR_DISCOUNT_DELETE](state, { item }) {
    state.delDiscount = item;
  },

  [ADD_REPAIR_ADDITION](state, { item }) {
    state.addition = item;
  },

  [SEND_REPAIR_ADDITION_DELETE](state, { item }) {
    state.delAddition = item;
  },

  // /repairtransfer

  [GET_REPAIR_TRANSFER](state, { item, pageCount, pageCurrent }) {
    state.repairTransfer = item;
    state.pageCountRepairTransfer = pageCount;
    state.pageCurrentRepairTransfer = pageCurrent;
  },

  [ADD_REPAIR_TRANSFER](state, { item }) {
    state.newRepairTransfer = item;
  },

  [SEND_CHANGE_STATUS_REPAIR_TRANSFER](state, { item }) {
    const indexTransferStart = state.repairTransfer.findIndex((element) => element.id === item.id);
    state.repairTransfer.splice(indexTransferStart, 1, item);
    state.updatedTransferRepair = item;
  },

  // repairstatus

  [SEND_REPAIR_TO_AGREEMENT](state, { item }) {
    state.viewRepair = item;
    state.repairAgreement = item;
  },

  [SEND_REPAIR_APPROVE_AGREEMENT](state, { item }) {
    state.repairApproveAgreement = item;
  },

  [SEND_REPAIR_DECLINE_AGREEMENT](state, { item }) {
    state.viewRepair = item;
    state.repairDeclineAgreement = item;
  },

  [SEND_REPAIR_DISMISS_JOB](state, { item }) {
    state.repairDismissJob = item;
  },

  [SEND_REPAIR_DONE](state, { item }) {
    state.repairDone = item;
  },

  [SEND_REPAIR_REVISION](state, { item }) {
    state.repairRevision = item;
  },

  [SEND_REPAIR_EJECT](state, { item }) {
    state.viewRepair = item;
    state.repairEject = item;
  },

  [SEND_REPAIR_RESTART](state, { item }) {
    state.viewRepair = item;
    state.repairRestart = item;
  },

  [SEND_REPAIR_DELAY](state, { item }) {
    state.repairDelay = item;
  },

  [SEND_REPAIR_START](state, { item }) {
    state.repairStart = item;
  },

  [SEND_REPAIR_CONTINUE](state, { item }) {
    state.repairContinue = item;
  },

  // /repairlog

  [GET_REPAIR_LOG](state, { item, pageCount }) {
    state.pageCountLogs = pageCount;
    state.repairLogs = item;
  },

  // /repairfile

  [GET_REPAIR_FILE](state, { item, pageCount }) {
    state.pageCountFiles = pageCount;
    state.repairFiles = item;
  },

  [SEND_REPAIR_ADD_FILES](state, { item }) {
    state.repairAddFiles = item;
  },

  // /repairtablelayout

  [GET_REPAIR_TABLE_LAYOUT](state, { item }) {
    state.ListRepairTableLayout = item;
    state.repairActiveColumn = JSON.parse(item.layout);
  },

  [SEND_REPAIR_TABLE_LAYOUT](state, { item }) {
    state.repairActiveColumn = JSON.parse(item.layout);
    state.newRepairLayout = JSON.parse(item.layout);
  },

  // validate

  [REPAIR_VALIDATION](state, item) {
    state.repairValid = item;
  },

  // repairstat

  [CLEAR_VALIDATE_REPAIR](state) {
    state.repairValid = {};
  },
};

export default mutations;
