import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ServiceSettings } from '@/store/company/types';
import ErrorAccessRights from '../../components/error-access-rights';

@Component({
  template: require('./index.html'),
  components: {
    'error-access-rights': ErrorAccessRights,
  },
})
export default class ManageFormPage extends Vue {
    @Action addBreadcrumb;

    @Action getSettings;

    @Action updateSettings;

    @Getter btnloader;

    @Getter settings;

    @Getter user;

    storedSettings: ServiceSettings = new ServiceSettings();

    async mounted() {
      await this.getSettings();
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: null },
        { id: 2, section: 'Настройки', link: null },
        { id: 3, section: 'Настройка форм', link: null },
      ]);
      document.title = 'Настройка форм';
    }

    get rightsUserCanManageForms() {
      return this.user.rights ? this.user.rights.can_manage_forms : false;
    }

    changeAllowClientAgreement(value: boolean) {
      const newSettings = { ...this.settings };
      newSettings.client_default_sms_agreement = value;
      this.updateSettings(newSettings);
    }

    changeAllowClientSource_required(value: boolean) {
      const newSettings = { ...this.settings };
      newSettings.is_client_source_required = value;
      this.updateSettings(newSettings);
    }

    @Watch('settings')
    updateStored(value) {
      this.storedSettings = { ...value };
    }
}
