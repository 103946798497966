import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

export const cash = {
  state,
  getters,
  mutations,
  actions,
};

export default cash;
