import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import SalaryOperation from '@/api/providers/salaryoperation';
import ViewUserName from '@/components/forms/user-name';
import PayoutOperations from '@/components/payout-operations';
import { SalaryOperation as SalaryOperationInterface } from '@/types';
import Numbers from '@/components/forms/numbers';

@Component({
  template: require('./index.html'),
  components: {
    ViewUserName,
    PayoutOperations,
    Numbers,
  },
})
export default class RepairViewSalary extends Vue {
  @Prop() repairId!: number;

  @Prop() totalPriceWithAdditionsAndDiscounts!: number;

  @Getter user;

  pageLoading = false;

  salaryOperationTable: SalaryOperationInterface[] = [];

  selectedSalaryOperations: SalaryOperationInterface[] = []

  isPayoutDialogVisible = false;

  get rightsUserCanManageStuff(): boolean {
    return this.user.rights ? this.user.rights.can_manage_stuff : false;
  }

  get allSumForStuff(): number {
    try {
      if (this.salaryOperationTable) {
        const arrayOfSum: number[] = [];
        this.salaryOperationTable.forEach((salaryOperation) => {
          arrayOfSum.push(salaryOperation.sum);
        });
        const reducer = arrayOfSum.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
        );
        return reducer;
      }
      return 0;
    } catch {
      return 0;
    }
  }

  get differenceBetweenSalaryAndRepair(): number {
    try {
      if (this.allSumForStuff && this.totalPriceWithAdditionsAndDiscounts) {
        let difference = this.allSumForStuff / this.totalPriceWithAdditionsAndDiscounts;
        difference = Math.floor(difference * 10000);
        difference /= 100;
        return difference;
      }
      return 0;
    } catch {
      return 0;
    }
  }

  get isButtonOfPayouDisabled(): boolean {
    try {
      return this.selectedSalaryOperations.length === 0;
    } catch {
      return true;
    }
  }

  mounted() {
    this.loadingSalaryOperation(this.repairId);
  }

  routerLinkTransferTitle = (salaryOperation: SalaryOperationInterface): string => {
    const title = `Выплата № ${salaryOperation?.transfer?.id}`;
    return title;
  }

  routerLinkTransferLink = (salaryOperation: SalaryOperationInterface): string => {
    const link = `/salary/transfer/${salaryOperation?.user_id}/${salaryOperation?.transfer?.id}`;
    return link;
  }

  closePayoutOperations() {
    this.isPayoutDialogVisible = false;
  }

  // Таблица
  canSelectRow(row) {
    return (this.rightsUserCanManageStuff && !row.payed);
  }

  handleSelectionChange(val) {
    this.selectedSalaryOperations = val;
  }

  @Watch('repairId')
  loadingSalaryOperation(repairId) {
    try {
      this.pageLoading = true;
      const expand = ['user', ['transfer']];
      const form = {
        repair_id: repairId,
      };
      SalaryOperation.salaryOperationIndex({
        form,
        expand,
      }).then((res) => {
        this.salaryOperationTable = !this.rightsUserCanManageStuff
          ? res.data.filter((item) => item.user.id === this.user.id)
          : res.data;
        this.pageLoading = false;
      }).catch((err) => {
        this.pageLoading = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка загрузки данных по зарплате', item: '' });
        throw new Error(err);
      });
    } catch (err) {
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка загрузки данных по зарплате', item: '' });
      this.pageLoading = false;
      throw new Error(err);
    }
  }
}
