import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  template: require('./index.html'),
})
export default class UserStatusAdd extends Vue {
  @Action sendUserStatus;

  @Getter userStatus;

  @Getter repairStatus;

  @Getter validStatus;

  @Getter btnloader;

  newItem: object = {
    parent_id: null,
    name: null,
    color: null,
  };

  validate = (obj: object, key: string) => {
    if (Object.keys(obj).some((v) => v === key)) {
      return obj[key][0];
    }
    return null;
  }

  resetForm() {
    (this.$refs.formStatusAdd as Form).resetFields();
  }

  addNewUserStatus(formName) {
    const refForm = this.$refs[formName] as Form;
    refForm.validate((valid) => (valid ? this.sendUserStatus(this.newItem) : false));
  }

  @Watch('validStatus')
  onChanged(val: object) {
    if (Object.keys(val).length === 0) this.resetForm();
  }
}
