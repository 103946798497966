import { MutationTree } from 'vuex';
import {
  GET_LICENSE_LIST,
  StateLicenses,
} from './types';

const mutations: MutationTree<StateLicenses> = {

  [GET_LICENSE_LIST](state, { item, pageCount }) {
    state.licensesList = item;
    state.licensesListPageCount = pageCount;
  },

};

export default mutations;
