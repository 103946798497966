import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import FeedbackProvider from '../../api/providers/feedback';
import { FEEDBACK_TIME_LOCAL_STORAGE_KEY } from '../repair/types';

enum REWIEW_MODAL_PAGE {
  FIRST,
  SECOND,
  THIRD,
}

interface Rewie {
  score: {
    general: number;
    function: number;
    interface: number;
  };
  recommend: boolean;
  comment: string;
}
interface FeedbackForm {
  overall_score: number;
  functionality_score: number;
  ui_score: number;
  recommend: boolean;
  comment: string;
}

@Component({
  template: require('./index.html'),
})
export default class ModalRewiew extends Vue {
    @Prop({ default: () => false }) isModalShow!: boolean;

    rewiew: Rewie = {
      score: {
        general: 5,
        function: 5,
        interface: 5,
      },
      recommend: true,
      comment: '',
    };

    reviewPage: REWIEW_MODAL_PAGE = REWIEW_MODAL_PAGE.FIRST;

    nextPage(page) {
      this.reviewPage = REWIEW_MODAL_PAGE[`${page}`];
    }

    saveFeedback() {
      const feedback: FeedbackForm = {
        overall_score: this.rewiew.score.general,
        functionality_score: this.rewiew.score.function,
        ui_score: this.rewiew.score.interface,
        recommend: this.rewiew.recommend,
        comment: this.rewiew.comment,
      };
      FeedbackProvider.saveFeedback(feedback)
        .then(() => {
          const now = (new Date().getTime() + (process.env.NODE_ENV === 'production' ? 3600000 * 24 * 30 : 180000)) / 1000;
          localStorage.setItem(FEEDBACK_TIME_LOCAL_STORAGE_KEY, String(now));
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Спасибо за отзыв', item: '' });
          this.$emit('closeEditModal');
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось сохранить отзыв', item: '' });
          this.$emit('closeEditModal');
        });
    }

    skipFeedback() {
      FeedbackProvider.skipFeedback()
        .then(() => {
          const now = (new Date().getTime() + (process.env.NODE_ENV === 'production' ? 3600000 * 24 * 30 : 180000)) / 1000; // должно быть 30 дней
          localStorage.setItem(FEEDBACK_TIME_LOCAL_STORAGE_KEY, String(now));
          this.$emit('closeEditModal');
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    delayFeedback() {
      FeedbackProvider.delayFeedback()
        .then(() => {
          const now = (new Date().getTime() + (process.env.NODE_ENV === 'production' ? 60000 * 15 * 30 : 60000)) / 1000; // должно быть 15 мин
          localStorage.setItem(FEEDBACK_TIME_LOCAL_STORAGE_KEY, String(now));
          this.$emit('closeEditModal');
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }
}
