import { GetterTree } from 'vuex';
import { StateUserStatus } from './types';

const getters: GetterTree<StateUserStatus, object> = {
  repairStatus: (state) => state.repairStatus,
  userStatus: (state) => state.userStatus.sort(
    (i, j) => (i.parent_id as number) - (j.parent_id as number),
  ),
  validStatus: (state) => state.validStatus,
  userStatusPageCount: (state) => state.userStatusPageCount,
  userStatusCurrentPage: (state) => state.userStatusCurrentPage,
};

export default getters;
