import { GetterTree } from 'vuex';
import { StatePush } from './types';

const getters: GetterTree<StatePush, object> = {
  error: (state) => state.error,
  success: (state) => state.success,
  warning: (state) => state.warning,
  info: (state) => state.info,
};

export default getters;
