import { Input } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Bug, DropDownItem } from '../../../interface';

@Component({
  template: require('./index.html'),
})
export default class FieldNote extends Vue {
    @Prop({ default: () => false }) notLoadValue!: boolean;

    @Prop({ required: true }) repair!: object;

    @Prop({ required: true }) noteList!: object[];

    note: Bug[] = [];

    note_drop_down: DropDownItem[] = [];

    note_add_items: Bug[] = [];

    note_window = false;

    note_search: object[] = [];

    save_add_item: Bug = {};

    value_note_item = '';

    focus_complete_fields_note = false;

    controlHiddenItem(item_fields, flag) {
      this.note_add_items.forEach((drop_down_item, i) => {
        if (drop_down_item.id === item_fields.id) this.note_add_items[i].classes['custom-fields-none'] = flag;
      });

      this.note.forEach((bug_item, i) => {
        if (bug_item.id === item_fields.id) this.note[i].styles['most-common__container-item_disabled'] = flag;
      });
    }

    handlerAddItems(e, item, index) {
      if (this.note_window && this.note_search.length !== 0) {
        const item_fields = { ...item };

        this.controlHiddenItem(item_fields, true);

        this.note_add_items.push(item_fields);
      } else {
        const copy_item = { ...item };

        this.note[index].styles['most-common__container-item_disabled'] = true;
        this.note_drop_down[index].classes['custom-fields-none'] = true;
        this.note_add_items.push(copy_item);
      }

      if (e.target.classList.contains('complete-fields')) {
        this.note_window = false;
      }

      this.value_note_item = '';
      this.save_add_item = {};
      this.$root.$emit('valueCustomFields', this.note_add_items, 'acceptor_notes');
    }

    removeFieldItem(item, index) {
      const note = this.note_add_items[index];

      this.$root.$emit('removeCustomFieldItem', item.text, 'acceptor_notes');
      this.note_add_items.splice(index, 1);

      this.controlHiddenItem(note, false);
    }

    handlerOpenFieldChange(e, index) {
      this.save_add_item = this.note_add_items[index] ? this.note_add_items[index] : {};

      this.focus_complete_fields_note = true;

      if (e.target.classList.contains('most-common__container-item')) {
        this.note_window = true;
        this.value_note_item = this.note_add_items[index].text;
        (this.$refs.fieldNote as Input).focus();
        this.filterCustomFields(this.value_note_item);
        this.note_add_items.splice(index, 1);
      }
    }

    clearNoteItem() {
      this.note_add_items = [];
      this.note_drop_down = [];
      this.note = [];
    }

    changeAddItem() {
      const item: Bug = {};

      item.text = this.value_note_item;

      if (this.note_add_items.length === 0) {
        item.id = 1;
        item.priority = 1;
      } else {
        const id = this.note_add_items.map((element) => element.id);
        const priority = this.note_add_items.map((element) => element.priority);

        item.id = Math.max(...id) + 1;
        item.priority = Math.max(...priority) + 1;
      }

      if (Object.keys(this.save_add_item).length !== 0) {
        this.save_add_item.text = this.value_note_item;
        this.note_add_items.push(this.save_add_item);
      } else {
        this.note_add_items.push(item);
      }

      this.note_window = false;
      this.value_note_item = '';
      this.$root.$emit('valueCustomFields', this.note_add_items, 'acceptor_notes');
    }

    filterCustomFields(queryString) {
      const filter = this.note.filter(
        (el) => el.text.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
        || el.text.toUpperCase().indexOf(queryString.toUpperCase()) !== -1,
      );

      this.note_search = filter;
    }

    changeUpdateItem(text) {
      this.value_note_item = text;

      if (text) {
        this.note_window = true;
        this.filterCustomFields(text);
      } else {
        this.note_window = false;
      }
    }

    focusCustomFieldsNote() {
      if (this.$refs.fieldNote) (this.$refs.fieldNote as Input).focus();
      this.focus_complete_fields_note = true;
    }

    unfocusCustomFieldsNote() {
      this.focus_complete_fields_note = false;
      this.value_note_item = '';
      this.note_window = false;
    }

    /* eslint-disable no-param-reassign */
    stylesDisabledCustomFields = (item) => {
      item.forEach((element) => {
        element.styles = { 'most-common__container-item_disabled': false };
        element.classes = { 'custom-fields-none': false };
      });
    }
    /* eslint-enable no-param-reassign */

    handlerBlurUpdateItem() {
      if (this.note_window && this.save_add_item) {
        this.$root.$emit('valueCustomFields', this.note_add_items, 'acceptor_notes');
      }
    }

    keyUpChangeUpdateItem() {
      if (this.value_note_item) {
        this.changeAddItem();
        this.note_window = false;
      }
    }

    loadingDataRepairInCustomField(new_val) {
      if (new_val.acceptor_notes && !this.notLoadValue) {
        new_val.acceptor_notes.split(',').forEach((element) => {
          this.note_add_items.push({ text: element.trim() });
        });
      }
    }

    @Watch('noteList')
    watchNoteList(list: DropDownItem[]) {
      this.stylesDisabledCustomFields(list);
      list.forEach((element) => {
        this.note.push(element);
        this.note_drop_down.push(element);
      });
    }

    @Watch('note_window')
    watchBugWindow(view) {
      if (!view) {
        if (this.note_add_items.length !== 0) {
          if (!this.focus_complete_fields_note) {
            const item = { ...this.save_add_item };

            this.controlHiddenItem(item, false);
          }
        } else {
          this.stylesDisabledCustomFields(this.note);
          this.stylesDisabledCustomFields(this.note_drop_down);
        }

        this.save_add_item = {};
      }
    }
}
