import { MutationTree } from 'vuex';
import {
  EDIT_BILLING, EDIT_COMPANY,

  EDIT_SETTINGS, GET_BILLING, GET_COMPANY,

  GET_SETTINGS, StateCompany,
} from './types';

const mutations: MutationTree<StateCompany> = {

  [GET_COMPANY](state, item) {
    state.company = item;
  },

  [EDIT_COMPANY](state, item) {
    state.company = {
      name: '',
      phone: '',
      address: '',
      countryId: null,
      currencyId: null,
      timeZoneId: null,
      formOwnershipId: null,
      nameOfficial: '',
    };
    state.company = item;
  },

  [GET_BILLING](state, item) {
    state.billing = item;
  },

  [EDIT_BILLING](state, item) {
    state.billing = item;
  },

  [GET_SETTINGS](state, item) {
    state.settings = item;
  },

  [EDIT_SETTINGS](state, item) {
    state.settings = item;
  },
};

export default mutations;
