import ActionBtn from './action-btn';
import AnalyticsWidget from './analytics-widgets';
import { Barcode } from './barcode';
import BillingInfo from './billing-info';
import Breadcrumb from './breadcrumb';
import Bug from './bug';
import BuyLicenseForm from './buy-license-form';
import Cancel from './cancel';
import CancelSearchForm from './cancel-search-form';
import CashboxSearchForm from './cashbox-search-form';
import CashTypeDefault from './cash-type-default';
import { Cashbox } from './cashbox';
import ChangePointForm from './change-point-form';
import Client from './client';
import ClientSource from './client-source';
import Config from './config';
import CountryPreset from './country-preset';
import { Currency } from './currency';
import CustomField from './custom-field';
import DeviceType from './device-type';
import DeviceVendor from './device-vendor';
import Document from './document';
import FormLayout from './form-layout';
import Image from './images';
import { ImportRowInfo, UploadForm } from './import-row';
import ImportRowAction from './import-row-action';
import InventorySearchForm from './inventory-search-form';
import LocalePreset from './locale-preset';
import LogForm from './log-form';
import LogRequest from './log-request';
import NavigationPage from './navigation-page';
import Part from './part';
import PartAmount from './part-amount';
import PartAmountCellForm from './part-amount-cell-form';
import PartBarcode from './part-barcode';
import PartForm from './part-form';
import PartImage from './part-image';
import PartBarcodeForm from './part-barcode-form';
import Photo from './photo';
import Point from './point';
import PointActionUserForm from './point-action-user-form';
import PointSearchForm from './point-search-form';
import Posting from './posting';
import PostingPart from './posting-part';
import PostingPaymentAddForm from './posting-payment-add-form';
import PostingSearchForm from './posting-search-form';
import PostingUpdateForm from './posting-update-form';
import PriceLevel from './price-level';
import Provider from './provider';
import Repair from './repair';
import RepairAddition from './repair-addition';
import RepairColumn from './repair-column';
import RepairCustomAttributeForm from './repair-custom-attribute-form';
import RepairDiscount from './repair-discount';
import RepairForm from './repair-form';
import RepairLayoutCell from './repair-layout-cell';
import RepairObjectForm from './repair-object-form';
import RepairPart from './repair-part';
import RepairPayment from './repair-payment';
import RepairPaymentAddForm from './repair-payment-add-form';
import RepairStatus from './repair-status';
import RepairTransfer from './repair-transfer';
import RepairWork from './repair-work';
import Response from './response';
import { Rights } from './rights';
import SalaryOperation from './salary-operation';
import { SalaryItem } from './salary-table-item';
import Sell from './sell';
import SellComment from './sell-comment';
import SellDiscount from './sell-discount';
import SellPart from './sell-part';
import SellPartRevert from './sell-part-revert';
import SellPayment from './sell-payment';
import SellWork from './sell-work';
import SellWorkRevert from './sell-work-revert';
import { Service } from './service';
import SmsProviderVm from './sms-provider-vm';
import SmsMessageSearchForm from './sms-message-search-form';
import StateOption from './state-option';
import Storage from './storage';
import StorageSearchForm from './storage-search-form';
import StorageTransferSearchForm from './storage-transfer-search-form';
import { TaskInterface } from './task-interface';
import TaskPriority from './task-priority';
import PriorityForm from './priority-form';
import TimeZonePreset from './time-zone-preset';
import User from './user';
import UserForm from './user-form';
import UserFullname from './user-fullname';
import Work from './work';

export {
  ActionBtn,
  AnalyticsWidget,
  Barcode,
  BillingInfo,
  Breadcrumb,
  Bug,
  BuyLicenseForm,
  Cancel,
  CancelSearchForm,
  CashTypeDefault,
  Cashbox,
  CashboxSearchForm,
  ChangePointForm,
  Client,
  ClientSource,
  Config,
  CountryPreset,
  Currency,
  CustomField,
  DeviceType,
  DeviceVendor,
  Document,
  FormLayout,
  Image,
  SalaryItem,
  ImportRowInfo,
  UploadForm,
  ImportRowAction,
  InventorySearchForm,
  LocalePreset,
  LogForm,
  LogRequest,
  NavigationPage,
  TimeZonePreset,
  Part,
  PartAmount,
  PartAmountCellForm,
  PartBarcode,
  PartForm,
  PartImage,
  PartBarcodeForm,
  Photo,
  Point,
  PointActionUserForm,
  PointSearchForm,
  PostingPaymentAddForm,
  Posting,
  PostingPart,
  PostingSearchForm,
  PostingUpdateForm,
  PriceLevel,
  Provider,
  Repair,
  RepairAddition,
  RepairColumn,
  RepairCustomAttributeForm,
  RepairDiscount,
  RepairForm,
  RepairLayoutCell,
  RepairObjectForm,
  RepairPart,
  RepairPayment,
  RepairPaymentAddForm,
  RepairStatus,
  RepairTransfer,
  RepairWork,
  Response,
  Rights,
  SalaryOperation,
  Sell,
  SellComment,
  SellDiscount,
  SellPart,
  SellPartRevert,
  SellPayment,
  SellWork,
  SellWorkRevert,
  StateOption,
  Service,
  SmsProviderVm,
  SmsMessageSearchForm,
  Storage,
  StorageSearchForm,
  StorageTransferSearchForm,
  TaskInterface,
  TaskPriority,
  PriorityForm,
  User,
  UserForm,
  UserFullname,
  Work,
};
