import RepairProvider from '../../../../api/providers/repair';
import { formatttingCustomAttrbiutesValueInString, formatttingFieldPlannedOutDate, formatttingFieldPlannedPrice } from '../methods/formattingFieldsFormRepair';

export default function handlerAddRepair(this) {
  this.$root.$on('handlerAddRepair', () => {
    const { name } = this.$route;
    if (this.balance.current_license === null && this.balance.payment_balance <= 0) {
      this.$alert(
        'У вас нет активных лицензий. Для приема заказа необходимо купить лицензию на программу.',
        'Нет лицензии',
        {
          type: 'error',
          confirmButtonText: 'Купить',
          confirmButtonClass: 'el-button el-button--default el-button--small el-button--success',
          callback: (action) => {
            if (action === 'confirm') {
              this.$router.push({ name: 'buy-balance' });
            }
          },
        },
      );
      this.$store.commit('BTN_LOADER', false);
      return;
    }
    if (this.data_repair.client_id) {
      const { planned_out_date } = this.data_repair;

      formatttingFieldPlannedOutDate.call(this);
      formatttingFieldPlannedPrice.call(this);
      formatttingCustomAttrbiutesValueInString.call(this);

      this.data_repair.type = name.search('repair') !== -1 ? 1 : 2;

      RepairProvider.sendRepairAdd(this.data_repair)
        .then((res) => {
          this.$router.push({ name: 'repair-view', params: { id: res.data.id } });

          if (res.data.type === 2) {
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Заказ сохранён', item: '' });
          } else {
            this.$store.commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} принят в ремонт`, item: '' });
          }

          this.error_repair_form = {};
          this.$store.commit('BTN_LOADER', false);

          if (name === 'repair-form-add') this.removeDraftRepair(1);
          if (name === 'refill-form-add') this.removeDraftRepair(2);
        }).catch((err) => {
          this.data_repair.planned_out_date = planned_out_date;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !', item: '' });
          this.error_repair_form = err.response.data;
          this.$store.commit('BTN_LOADER', false);
        });
    } else {
      this.$message({
        message: 'Чтобы добавить заказ, выберите пожалуйста клиента!',
        type: 'error',
      });
      this.$store.commit('BTN_LOADER', false);
    }
  });
}
