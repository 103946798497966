import { ActionTree } from 'vuex';
import TaskProvider from '../../api/providers/task';
import { BTN_LOADER } from '../preloader/types';
import { PUSH_CALL_ERROR, PUSH_CALL_SUCCESS } from '../push/types';
import {
  DELETE_TASK,
  EDIT_TASK,
  GET_TASK,
  GET_TASK_LIST,
  INSERT_TASK,
  TaskForm,
  TaskSearchApiForm,
  TaskState, UPDATE_LAST_SAVED_TASK_ID,
} from './types';

const actions: ActionTree<TaskState, object> = {

  getTaskList({ commit }, form: TaskSearchApiForm) {
    commit(BTN_LOADER, true);
    TaskProvider.getTaskList(form)
      .then((res) => {
        const resCopy = { ...res };
        resCopy.data = [];
        res.data.forEach((task) => {
          const taskCopy = { ...task };
          if (taskCopy.deadline) { taskCopy.deadline *= 1000; }
          if (taskCopy.completed_at) { taskCopy.completed_at *= 1000; }
          taskCopy.created_at *= 1000;
          resCopy.data = [...resCopy.data, taskCopy];
        });
        commit(BTN_LOADER, false);
        commit(
          GET_TASK_LIST,
          { item: resCopy.data, pageCount: res.pageCount, pageCurrent: form.page },
        );
      })
      .catch((err) => {
        console.error(err);
        commit(BTN_LOADER, false);
      });
  },

  getTask({ commit }, id) {
    TaskProvider.getTask(id)
      .then((res) => {
        const resCopy = { ...res };
        if (resCopy.deadline) { resCopy.deadline *= 1000; }
        if (resCopy.completed_at) { resCopy.completed_at *= 1000; }
        resCopy.created_at *= 1000;
        commit(GET_TASK, { item: resCopy });
      })
      .catch((err) => console.error(err));
  },

  addTask({ commit }, item: TaskForm) {
    commit(BTN_LOADER, true);
    TaskProvider.addTask(item)
      .then((res) => {
        commit(UPDATE_LAST_SAVED_TASK_ID, { id: res.data.id });
        commit(INSERT_TASK, { item: res.data });
        commit(PUSH_CALL_SUCCESS, { title: `${item.title} успешно добавлен`, item: '' });
        commit(BTN_LOADER, false);
        // todo commit('GET_VALID_STORAGE', { item: {} });
      })
      .catch((err) => {
        console.error(err);
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
        // todo commit('GET_VALID_STORAGE', { item: err.response.data });
      });
  },

  clearLastSavedTaskId({ commit }) {
    commit(UPDATE_LAST_SAVED_TASK_ID, { id: null });
  },

  changeTask({ commit }, { id, item }) {
    commit(BTN_LOADER, true);
    TaskProvider.changeTask(id, item)
      .then((res) => {
        commit(UPDATE_LAST_SAVED_TASK_ID, { id: res.data.id });
        commit(EDIT_TASK, { item: res.data });
        commit(PUSH_CALL_SUCCESS, { title: `Задача "${item.title}" успешно изменена`, item: '' });
        commit(BTN_LOADER, false);
        // todo commit('GET_VALID_STORAGE', { item: {} });
      })
      .catch((err) => {
        console.error(err);
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
        // todo commit('GET_VALID_STORAGE', { item: err.response.data });
      });
  },

  deleteTask({ commit }, id) {
    commit(BTN_LOADER, true);
    TaskProvider.deleteTask(id)
      .then((res) => {
        commit(DELETE_TASK, { item: res.data });
        commit(PUSH_CALL_SUCCESS, { title: 'Успешно удалено', item: '' });
        commit(BTN_LOADER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
      });
  },
};

export default actions;
