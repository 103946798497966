import { User } from '@/types';
import BaseProvider from '../base-provider';

class StuffProvider extends BaseProvider {
  async getStuffList(page?) {
    const expand: string[] = ['point', 'current_salary_balance'];
    let res;
    if (page) {
      res = await this.request(
        'GET', `/service/stuff?page=${page}&expand=${expand}`,
      );
    } else {
      res = await this.request(
        'GET', `/service/stuff?expand=${expand}`,
      );
    }
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getFullStuffList(page = 1) {
    let result: User[] = [];
    const pageResult = await this.getStuffList(page);
    result = result.concat(pageResult.data);
    if (pageResult.pageCount > page) {
      result = result.concat(await this.getFullStuffList(page + 1));
    }
    return result;
  }

  async getViewStuff(id) {
    const expand: string[] = ['rights', 'cashboxes', 'storages', 'points'];
    const res = await this.request('GET', `/service/employee?id=${id}&expand=${expand}`);
    return {
      data: res.data,
    };
  }

  async sendStuff(item) {
    const expand: string[] = ['rights', 'cashboxes', 'storages', 'points'];
    const data: object = { UserForm: item };
    const res = await this.request('POST', `/service/addemployee?expand=${expand}`, data);
    return res;
  }

  async changeStuff(id, item) {
    const expand: string[] = ['rights', 'cashboxes', 'storages', 'points'];
    const data: object = { UserForm: item };
    const res = await this.request('POST', `/service/updateemployee?id=${id}&expand=${expand}`, data);
    return res;
  }

  async deleteStuff(id) {
    const res = await this.request('POST', `/service/fireemployee?id=${id}`);
    return res;
  }

  getSalaryPeriodTypeList = {
    data: {
      1: 'дневной',
      2: 'месячный',
    },
  }

  getSellPartSalaryTypeList = {
    data: {
      1: '% от стоимости',
      2: '% от прибыли',
    },
  }

  getRepairPartSalaryType = {
    data: {
      1: '% от стоимости',
      2: '% от прибыли',
    },
  }
}

export default new StuffProvider();
