import { ActionBtn, Breadcrumb } from '@/types';

export const GET_BREADCRUMB = 'GET_BREADCRUMB';
export const GET_ACTION_BTN = 'GET_ACTION_BTN';

// Store

export interface StateBreadcrumb {
    breadcrumb: Breadcrumb[];
    actionBtn: ActionBtn[];
}
