import { MutationTree } from 'vuex';
import {
  DELETE_NOTEOPTION_LIST, GET_NOTEOPTION_LIST, NoteStateOption,
  SEND_NOTEOPTION_LIST,

  SEND_NOTEOPTION_LIST_PRIORITY, UPDATE_NOTEOPTION_LIST,
} from './types';

const mutations: MutationTree<NoteStateOption> = {
  [GET_NOTEOPTION_LIST](state, { item }) {
    state.noteoptionList = item;
  },

  [SEND_NOTEOPTION_LIST](state, { item }) {
    state.addNoteOptionList = item;
  },

  [SEND_NOTEOPTION_LIST_PRIORITY](state, { item }) {
    state.sendNoteOptionPriority = item;
  },

  [UPDATE_NOTEOPTION_LIST](state, { item }) {
    state.updateNoteOptionList = item;
  },

  [DELETE_NOTEOPTION_LIST](state, { item }) {
    state.deleteNoteOptionList = item;
  },
};

export default mutations;
