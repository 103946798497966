import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ViewDate from '../forms/date';
import Numbers from '../forms/numbers';
import SalaryAmountAndPeriodType from '../salary-amount-and-period-type';
import SalaryByShares from '../salary-by-shares';

@Component({
  template: require('./index.html'),
  components: {
    SalaryAmountAndPeriodType,
    SalaryByShares,
    Numbers,
    ViewDate,
  },
})

export default class SalaryTransferViewTable extends Vue {
    @Prop(Array) data;

    @Prop(Boolean) loading;

    summaryMethod({ columns }) {
      /* eslint-disable-next-line no-param-reassign */
      const total_sum = this.data.reduce((prev, curr) => prev += curr.sum, 0);
      const result = new Intl.NumberFormat('ru-RU', {
        maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'RUB',
      })
        .format(Number(total_sum));
      const out: object = [];
      out[0] = ['Итого:'];
      out[columns.length - 1] = result;
      return out;
    }
}
