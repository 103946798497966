export function formatttingFieldPlannedOutDate(this) {
  if (this.data_repair.planned_out_date) {
    this.data_repair.planned_out_date /= 1000;
  }
}

export function formatttingFieldPlannedPrice(this) {
  if (!Number.isNaN(this.data_repair.planned_price) && this.data_repair.planned_price) {
    this.data_repair.planned_price = Number(this.data_repair.planned_price);
  } else {
    this.data_repair.planned_price = null;
  }
}

export function formatttingCustomAttrbiutesValueInString(this) {
  if (this.data_repair.custom_attributes.length !== 0) {
    this.data_repair.custom_attributes.forEach((field, i) => {
      if (field.value === false) this.data_repair.custom_attributes[i].value = '';
      this.data_repair.custom_attributes[i].value = field.value !== null
        ? String(field.value)
        : null;
    });
  }
}
