export const GET_TASK_STATUS = 'GET_TASK_STATUS';
export const GET_VALID_TASK_STATUS = 'GET_VALID_TASK_STATUS';
export const EDIT_TASK_STATUS = 'EDIT_TASK_STATUS';
export const DELETE_TASK_STATUS = 'DELETE_TASK_STATUS';
export const INSERT_TASK_STATUS = 'INSERT_TASK_STATUS';

// Store

export interface TaskStatusState {
    statuses: TaskStatus[];
    validStatus: object;
    pageStatusCount: number;
    pageStatusCurrent: number;
}

// Models

export interface TaskStatusInterface {
    id?: number;
    name: string;
    color: string;
    completed?: boolean;
    deleted?: boolean;
    priority?: number;
}

export class TaskStatus implements TaskStatusInterface {
    id?: number;

    name!: string;

    color!: string;

    completed?: boolean;

    deleted?: boolean;

    priority?: number;

    /**
     * Метод уопрядочивает статусы по приоритету
     * @param {TaskStatus} a
     * @param {TaskStatus} b
     */
    public static taskStatusComparator(a, b) {
      if (a.priority > b.priority) {
        return 1;
      } if (a.priority < b.priority) {
        return -1;
      }
      return 0;
    }
}
