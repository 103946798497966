import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
})
export default class BillingPage extends Vue {
    link = '/settings/billing';

    mounted() {
      this.link = this.$route.path;
      this.updateTab();
    }

    changeLink(val: string) {
      this.$router.push(val);
    }

    updateTab() {
      switch (this.$route.name) {
        case 'payments': this.link = '/service/billing/payments'; break;
        case 'licenses': this.link = '/service/billing/licenses'; break;
        case 'balance': this.link = '/service/billing/balance/all'; break;
        default: this.link = '/service/billing/payments';
      }
    }

    @Watch('$route', { immediate: true })
    viewRoute() {
      this.updateTab();
    }
}
