import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import StuffProvider from '../../api/providers/stuff';
import TaskStatusProvider from '../../api/providers/task-status';
import { TaskStatus } from '../../store/task-status/types';
import { TaskForm } from '../../store/task/types';
import ViewDate from '../forms/date';
import ViewUserName from '../forms/user-name';
import TaskPriorityComponent from './priority';

@Component({
  template: require('./sideblock.html'),
  components: {
    'task-priority': TaskPriorityComponent,
    'user-name': ViewUserName,
    'view-date': ViewDate,
  },
})
export default class TaskSideBlock extends Vue {
    @Prop() task;

    stuff = [];

    creator = null;

    responsibleAccount = null;

    selectedTaskStatusId = null;

    taskStatuses: TaskStatus[] = [];

    @Watch('task')
    loadData() {
      Promise.all([
        this.loadCreator(),
        this.loadResponsibleAccount(),
        this.loadTaskStatuses(),
      ]).then(() => {
        this.selectedTaskStatusId = this.task.status_id;
      });
    }

    async loadCreator() {
      if (this.task && this.task.creator_id) {
        this.creator = await TaskSideBlock.findUser(this.task.creator_id);
      }
    }

    async loadResponsibleAccount() {
      if (!this.responsibleAccount && this.task && this.task.responsible_account_id) {
        this.responsibleAccount = await TaskSideBlock.findUser(this.task.responsible_account_id);
      }
    }

    async loadTaskStatuses() {
      this.taskStatuses = await TaskStatusProvider.getAllStatuses();
      this.checkIsStatusDeleted();
    }

    /**
     * Проверяет есть в статсуах удаленный статус, если есть то добавляет его
     */
    checkIsStatusDeleted() {
      const founded = this.taskStatuses.findIndex(
        (taskStatus) => taskStatus.id === this.task.status.id,
      );

      if (founded === -1) {
        this.taskStatuses.push(this.task.status);
      }
    }

    private static async findUser(id: number) {
      const user = await StuffProvider.getViewStuff(id);
      return user.data;
    }

    taskPriorityChangeHandler(priority: number) {
      const form = new TaskForm();
      form.priority = priority;
      this.$emit('taskChanged', form);
    }

    taskStatusChangeHandle(selectedStatus: number) {
      const form = new TaskForm();
      form.status_id = selectedStatus;
      this.$emit('taskChanged', form);
    }
}
