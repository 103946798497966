import { ActionTree } from 'vuex';
import WorkProvider from '../../api/providers/work';
import { StateWork } from './types';

const actions: ActionTree<StateWork, object> = {

  getWorkList({ commit }, item) {
    WorkProvider.getWorkList(item.page, item, item['per-page'])
      .then((res) => {
        commit('GET_WORK', { item: res.data, pageCount: res.pageCount, pageCurrent: res.pageCurrent });
      })
      .catch((err) => console.error(err));
  },

  getWorkSearchList({ commit }, item) {
    WorkProvider.getWorkSearchList(item.page, item, item['per-page'])
      .then((res) => {
        commit('GET_WORK', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  async sendWork({ commit }, item) {
    commit('BTN_LOADER', true);
    try {
      const res = await WorkProvider.sendWork(item);
      commit('GET_VIEW_WORK', { item: res.data });
      commit('PUSH_CALL_SUCCESS', { title: `${item.name} успешно добавлен`, item: '' });
      commit('BTN_LOADER', false);
      return res;
    } catch (err) {
      commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      commit('BTN_LOADER', false);
      throw err;
    }
  },

  getViewWork({ commit }, id) {
    WorkProvider.getViewWork(id)
      .then((res) => commit('GET_VIEW_WORK', { item: res.data }))
      .catch((err) => console.error(err));
  },

  changeWork({ commit }, item) {
    commit('BTN_LOADER', true);
    WorkProvider.changeWork(item)
      .then((res) => {
        commit('UPDATE_WORK', { item: res.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: `Работа ${res.data.name} успешно сохранена`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  deleteWork({ commit }, id) {
    WorkProvider.deleteWork(id)
      .then((res) => {
        commit('DELETE_WORK', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Работа ${res.data.name} успешно удалена`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

};

export default actions;
