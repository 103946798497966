import { ActionTree } from 'vuex';
import ServiceProvider from '../../api/providers/service';
import { StatePaymentList } from './types';

const actions: ActionTree<StatePaymentList, object> = {

  getPaymentList({ commit }, page) {
    ServiceProvider.getPaymentList(page)
      .then((res) => {
        commit('GET_PAYMENT_LIST', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },
};

export default actions;
