import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import virtualList from 'vue-virtual-scroll-list';
import { Action, Getter } from 'vuex-class';
import { ActionBtn, User, DeviceVendor } from '@/types';
import DeviceForm from '../../components/device-form';
import ErrorAccessRights from '../../components/error-access-rights';

interface SearchForm {
    type;
    vendor;
    model;
}

interface NewType {
  vendors: DeviceVendor[];
}

interface NewItem {
  id?: number;
  type_id?;
}
@Component({
  template: require('./index.html'),
  components: {
    'device-form': DeviceForm,
    'virtual-list': virtualList,
    'error-access-rights': ErrorAccessRights,
  },
})
export default class DevicePage extends Vue {
  @Action getDeviceTypeList;

  @Action getDeviceVendorList;

  @Action getDeviceModelList;

  @Action searchDeviceList;

  @Action deleteDeviceType;

  @Action deleteDeviceVendor;

  @Action deleteDeviceModel;

  @Action addBreadcrumb;

  @Action addActionBtn;

  @Getter deviceTypeList;

  @Getter deviceVendorList;

  @Getter deviceModelList;

  @Getter searchTypeList;

  @Getter searchVendorList;

  @Getter searchModelList;

  @Getter deviceType;

  @Getter deviceVendor;

  @Getter btnloader;

  @Getter user;

  typeList: object[] = [];

  vendorList: DeviceVendor[] = [];

  modelList: object[] = [];

  scrollVendor = false;

  scrollModel = false;

  dialogDelete = false;

  recursiveDelete = false;

  loading = true;

  searchForm: SearchForm = {
    type: '',
    vendor: '',
    model: '',
  };

  typeId: number| null = null;

  vendorId: number | null= null;

  actionBtn: ActionBtn[] = [{
    id: 1, type: 'add', title: 'Добавить устройство', link: 'onDialog', icon: 'fa-plus',
  }];

  newItem: NewItem = {};

  typeContent = '';

  typeDelete = '';

  loadingVendorList = false;

  loadingModelList = false;

  get rightsUserCanManageDevices() {
    return this.user.rights ? this.user.rights.can_manage_devices : false;
  }

  mounted() {
    this.getDeviceTypeList();
    if (this.user.rights && this.user.rights.can_manage_devices) this.addActionBtn(this.actionBtn);
    this.addBreadcrumb([
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Справочники', link: null },
      { id: 3, section: 'Устройства', link: null },
    ]);
    document.title = 'Устройства';
  }

  destroyed() {
    this.addActionBtn([]);
  }

  get getVendorList() {
    return this.searchForm.vendor.length
    || this.searchForm.model.length ? this.searchVendorList : this.vendorList;
  }

  get getModelList() {
    return this.searchForm.model.length ? this.searchModelList : this.modelList;
  }

  onDialog(elem, item) {
    this.newItem = item;
    this.typeContent = elem;
    if (elem === 'model') this.newItem.type_id = this.typeId;
    this.$root.$emit('onDialog', 'edit');
  }

  onDialogDelete(type, item) {
    this.newItem = item;
    this.recursiveDelete = false;
    this.typeDelete = type;
    this.dialogDelete = true;
  }

  closeDialogDevice() {
    this.newItem = {};
  }

  changeModeModel(mode) {
    this.typeContent = mode;
  }

  deleteItem() {
    const item = { id: this.newItem.id, recursive: this.recursiveDelete };
    switch (this.typeDelete) {
      case 'type':
        this.deleteDeviceType(item);
        this.vendorList = [];
        this.modelList = [];
        break;
      case 'vendor':
        this.deleteDeviceVendor(item);
        this.modelList = [];
        break;
      case 'model':
        this.deleteDeviceModel(this.newItem.id);
        break;
      default: break;
    }
  }

  typeActiveItem(item) {
    const modelCount: number = this.searchForm.model.length;
    const vendorCount: number = this.searchForm.vendor.length;
    if (!modelCount && !vendorCount) {
      this.typeId = this.typeId === item.id ? null : item.id;
      this.vendorList = [];
    }
    this.typeId = item.id;
    this.vendorId = null;
    this.loadingVendorList = true;
    this.getDeviceVendorList(item.id);
  }

  typeSelect(item) {
    this.typeActiveItem(item);
  }

  vendorSelect(item) {
    this.vendorActiveItem(item);
  }

  vendorActiveItem(item) {
    const modelCount: number = this.searchForm.model.length;
    if (!modelCount) this.vendorId = this.vendorId === item.id ? null : item.id;
    this.vendorId = item.id;
    this.loadingModelList = true;
    this.getDeviceModelList(item.id);
  }

  query() {
    this.searchDeviceList(this.searchForm);
  }

  heightVendorItem(item) {
    const height: number = this.searchForm.model.length ? item.devices.length * 38 : 38;
    return `${height}px`;
  }

  heightTypeItem(item) {
    const modelCount: number = this.searchForm.model.length;
    const vendorCount: number = this.searchForm.vendor.length;
    let height = 38;
    if (modelCount) {
      /* eslint-disable-next-line no-param-reassign */
      height = item.vendors.reduce((sum, current) => sum += current.devices.length, 0) * 38;
      this.scrollModel = true;
    } else if (vendorCount) {
      height = item.vendors.length * 38;
      this.scrollVendor = true;
    } else {
      this.scrollModel = false;
      this.scrollVendor = false;
    }
    return `${height}px`;
  }

  @Watch('searchTypeList')
  onChangeSearchTypeList() {
    this.typeList = this.searchTypeList;
    this.typeId = null;
    this.vendorId = null;
  }

  @Watch('deviceTypeList')
  onChangeDeviceTypeList(val: object[]) {
    this.typeList = val;
    this.$nextTick(() => {
      this.loading = false;
      this.dialogDelete = false;
    });
  }

  @Watch('deviceType')
  addNewType(type: NewType) {
    if (this.typeContent === 'vendor') {
      const newType: NewType = type;
      newType.vendors = this.vendorList.filter((vendor) => vendor.id === this.newItem.id);
      this.vendorList = this.vendorList.filter((vendor) => vendor.id !== this.newItem.id);
      this.typeList.push(newType);
    } else {
      this.typeList.push(type);
    }
  }

  @Watch('deviceVendor')
  addNewVendor(vendor: DeviceVendor) {
    this.vendorList.push(vendor);
  }

  @Watch('typeId')
  onChangeTypeId(val: number) {
    this.vendorId = null;
    if (val === null) this.vendorList = [];
  }

  @Watch('vendorId')
  onChangeVendorId(val: number) {
    if (val === null) this.modelList = [];
  }

  @Watch('deviceVendorList')
  onChangeDeviceVendorList(vendors: DeviceVendor[]) {
    this.vendorList = vendors;
    this.loadingVendorList = false;
    this.dialogDelete = false;
  }

  @Watch('deviceModelList')
  modelListLoading(model: object[]) {
    this.modelList = model;
    this.loadingModelList = false;
    this.dialogDelete = false;
  }

  @Watch('user')
  meUser(user: User) {
    if (user.rights) {
      if (user.rights.can_manage_devices) {
        this.addActionBtn(this.actionBtn);
      } else {
        this.addActionBtn([]);
      }
    } else {
      this.addActionBtn([]);
    }
  }
}
