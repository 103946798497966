import RepairProvider from '../../../api/providers/repair';

export default function getRepairStat(this) {
  RepairProvider.getRepairStat()
    .then((res) => {
      const stat = res.data;

      this.status_buttons.forEach((status) => {
        const status_all_amount = stat.statuses.find((item) => status.status === item.status_id);

        if (status_all_amount) {
          const new_status = status;
          const index_item_status = this.status_buttons.findIndex(
            (statusItem) => statusItem.status === new_status.status,
          );

          new_status.amount = status_all_amount.amount;

          this.status_buttons.splice(index_item_status, 1, new_status);
        }
      });

      const without_master = stat.masters.find((master) => master.master_id === null);
      if (without_master) this.amount_without_master = without_master.amount;

      this.masters.forEach((master) => {
        const master_all_amount = stat.masters.find((item) => master.id === item.master_id);

        if (master_all_amount) {
          const new_master = master;
          const index_item_master = this.masters.findIndex(
            (masterItem) => masterItem.id === new_master.id,
          );

          new_master.amount = master_all_amount.amount;
          this.masters.splice(index_item_master, 1, new_master);
        }
      });
    }).catch((err) => {
      console.error(err);
    });
}
