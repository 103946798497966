import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
})

export default class PartSettingTable extends Vue {
    @Prop() headNomenkl!: object;

    @Prop() headsPart!: object;

    all_reward = false;

    all_amounts = false;

    all_price = false;

    dialog_setting_table = false;

    heads: object = {};

    // api
    reloadHeadNomenkl(item) {
      localStorage.setItem('headNomenkl', JSON.stringify(item));
      this.$emit('reloadHeadNomenkl', item);
    }

    reloadHeadPart(item) {
      localStorage.setItem('headPart', JSON.stringify(item));
      this.$emit('reloadHeadPart', item);
    }

    get isPage() {
      return this.$route.name === 'part';
    }

    reset() {
      const head = this.isPage ? this.headsPart : this.headNomenkl;
      this.heads = JSON.parse(JSON.stringify(head));
    }

    /* eslint-disable no-param-reassign */
    edtItem = (props) => {
      if (props) {
        const active: boolean = props.every((i) => i.active);
        props.forEach((item) => {
          item.active = !active;
        });
      }
    }
    /* eslint-enable no-param-reassign */

    midcheck(props, type) {
      if (props) {
        const active: boolean = props.every((i) => i.active);
        this[type] = !!active;
        return !!(props.some((i) => i.active) && !active);
      }
      return false;
    }

    onDialogSettingTable() {
      if (!this.dialog_setting_table) this.reset();
      this.dialog_setting_table = !this.dialog_setting_table;
    }

    cancel() {
      this.reset();
      this.onDialogSettingTable();
    }

    save() {
      if (this.isPage) {
        this.reloadHeadPart(this.heads);
      } else {
        this.reloadHeadNomenkl(this.heads);
      }
      this.onDialogSettingTable();
    }
}
