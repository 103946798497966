import { MutationTree } from 'vuex';
import {
  EXPORT_VALIDATION, GET_EXPORT,

  StateExport,
} from './types';

const mutations: MutationTree<StateExport> = {
  [GET_EXPORT](state, { item, pageCount }) {
    state.export = item;
    state.exportPageCount = pageCount;
  },

  [EXPORT_VALIDATION](state, err) {
    state.exportValid = err;
  },

};

export default mutations;
