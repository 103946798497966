import { ActionTree } from 'vuex';
import ClientsProvider from '../../api/providers/clients';
import { StateClient } from './types';

const actions: ActionTree<StateClient, object> = {

  getClientList({ commit }, page) {
    ClientsProvider.getClientList(page)
      .then((res) => {
        commit('GET_CLIENT', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  getViewClient({ commit }, id) {
    ClientsProvider.getViewClient(id)
      .then((res) => {
        commit('GET_VIEW_CLIENT', { item: res.data });
      })
      .catch((err) => console.error(err));
  },

  getInnClient({ commit }, inn) {
    ClientsProvider.getInnClient(inn)
      .then((res) => {
        commit('GET_CLIENT_INN', { item: res.data });
      })
      .catch((err) => console.error(err));
  },

  filterClient({ commit }, item) {
    ClientsProvider.filterClient(item.page, item)
      .then((res) => {
        commit('GET_CLIENT', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  sendClientPerson({ commit }, item) {
    commit('BTN_LOADER', true);
    ClientsProvider.sendClientPerson(item)
      .then((res) => {
        commit('INSERT_CLIENT', { item: res.data });
        commit('GET_VALID_PERSON', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: `${res.data.last_name} ${res.data.first_name} ${res.data.middle_name}` });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  sendClientSetVip({ commit }, item) {
    ClientsProvider.sendClientSetVip(item)
      .then((res) => {
        commit('SEND_CLIENT_SET_VIP', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Клиент ${res.data.last_name} ${res.data.first_name} теперь Vip`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Статус клиента не обновлен', item: '' });
      });
  },

  sendClientSetRegular({ commit }, item) {
    ClientsProvider.sendClientSetRegular(item)
      .then((res) => {
        commit('SEND_CLIENT_SET_REGULAR', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Клиент ${res.data.last_name} ${res.data.first_name} теперь обычный клиент`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Статус клиента не обновлен', item: '' });
      });
  },

  sendClientSetBlacklist({ commit }, item) {
    commit('BTN_LOADER', true);
    ClientsProvider.sendClientSetBlacklist(item)
      .then((res) => {
        commit('SEND_CLIENT_SET_BLACKLIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Клиент ${res.data.last_name} ${res.data.first_name} теперь в чёрном списке`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Статус клиента не обновлен', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  sendClientCheckBlacklist({ commit }, item) {
    ClientsProvider.sendClientCheckBlacklist(item)
      .then((res) => { commit('SEND_CLIENT_CHECK_BLACKLIST', { item: res.data }); });
  },

  sendClientCompany({ commit }, item) {
    commit('BTN_LOADER', true);
    ClientsProvider.sendClientCompany(item)
      .then((res) => {
        commit('INSERT_CLIENT', { item: res.data });
        commit('GET_VALID_COMPANY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: res.data.name });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  changeClientPerson({ commit }, item) {
    commit('BTN_LOADER', true);
    ClientsProvider.changeClientPerson(item)
      .then((res) => {
        commit('EDIT_CLIENT', { item: res.data });
        commit('GET_VALID_PERSON', { item: {} });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно изменено', item: `${res.data.last_name} ${res.data.first_name} ${res.data.middle_name}` });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('GET_VALID_PERSON', { item: err });
        commit('BTN_LOADER', false);
      });
  },

  changeClientCompany({ commit }, item) {
    commit('BTN_LOADER', true);
    ClientsProvider.changeClientCompany(item)
      .then((res) => {
        commit('EDIT_CLIENT', { item: res.data });
        commit('GET_VALID_COMPANY', { item: {} });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно изменено', item: res.data.name });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('GET_VALID_COMPANY', { item: err.response.data });
        commit('BTN_LOADER', false);
      });
  },

  async deleteClient({ commit }, id) {
    commit('BTN_LOADER', true);
    try {
      const res = await ClientsProvider.deleteClient(id);
      commit(
        'PUSH_CALL_SUCCESS',
        { title: 'Успешно удалено', item: res.data.name === null ? `${res.data.last_name} ${res.data.first_name} ${res.data.middle_name}` : res.data.name },
      );
      commit('BTN_LOADER', false);
      return res;
    } catch (err) {
      commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      commit('BTN_LOADER', false);
      throw err;
    }
  },
};

export default actions;
