import { SmsMessageSearchForm } from '@/types';
import BaseProvider from '../base-provider';

class SmsProvider extends BaseProvider {
  async getSmsList(page, smsMessageSearchForm: SmsMessageSearchForm | null = null) {
    let res;
    if (smsMessageSearchForm) {
      const searchForm: string = this.buildUrl({ SmsMessageSearchForm: smsMessageSearchForm });
      res = await this.request('GET', `/sms/index?page=${page}&${searchForm}`);
    } else {
      res = await this.request('GET', `/sms/index?page=${page}`);
    }
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendSms(item: SendSmsForm) {
    const form = { SmsSendForm: item };
    try {
      return await this.request('POST', '/sms/send', form);
    } catch (err) {
      throw new Error(err);
    }
  }

  async getSmsTemplateList() {
    const res = await this.request('GET', '/service/smstemplates');
    return res;
  }

  async changeSmsTemplate(item) {
    const data: object = { SmsTemplateForm: item };
    const res = await this.request('POST', '/service/smstemplates', data);
    return res;
  }
}

export default new SmsProvider();

export interface SendSmsForm {
    client_id: number;
    text: string;
    method: string;
}
