import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  User, UserForm, Cashbox, Storage, CashboxSearchForm, Point, StorageSearchForm,
} from '@/types';
import StorageProvider from '@/api/providers/storage';
import PointProvieder from '@/api/providers/point';
import CashProvider from '../../api/providers/cash';
import StuffProvider from '../../api/providers/stuff';

interface CashboxAddUserForm {
  id: number | null;
}
interface Response {
  data;
}
@Component({
  template: require('./index.html'),
})
export default class StuffForm extends Vue {
    @Action getPointList;

    @Action getUserMe;

    @Getter point;

    @Prop() type!: string;

    @Prop() item!: User;

    newItem: object = {};

    copyPassword = '';

    period: object[] = [{ id: 1, name: 'Дневной' }, { id: 2, name: 'Месячный' }];

    percent: object[] = [{ id: 1, name: '% (от полной стоимости)' }, { id: 2, name: '% (от прибыли)' }];

    cash_list: Cashbox[] = [];

    storage_list: Storage[] = [];

    cashbox_add_user_form: CashboxAddUserForm = {
      id: null,
    };

    loading = false;

    valid_stuff: object = {};

    // Variables for checkboxes
    // Oreders
    get isIndeterminateOrders() {
      if (this.rights) {
        if (this.rights.can_create_update_repair
          && this.rights.can_see_foreign_repair
          && this.rights.can_edit_others_parts_works_repair
          && this.rights.can_transfer_repair
          && this.rights.can_update_repair_accepting
          && this.rights.can_see_others_repair
          && this.rights.can_work_on_repair
          && this.rights.can_apply_master_on_repair
          && this.rights.can_delete_repair
          && this.rights.can_restart_closed_repair
          && this.rights.can_remove_closed_repair_payment) {
          this.checkAllOrders = true;
          return false;
        }
        if (this.rights.can_create_update_repair
            || this.rights.can_see_foreign_repair
            || this.rights.can_edit_others_parts_works_repair
            || this.rights.can_transfer_repair
            || this.rights.can_update_repair_accepting
            || this.rights.can_see_others_repair
            || this.rights.can_work_on_repair
            || this.rights.can_apply_master_on_repair
            || this.rights.can_delete_repair
            || this.rights.can_restart_closed_repair
            || this.rights.can_remove_closed_repair_payment) {
          this.checkAllOrders = false;
          return true;
        }
      }
      return false;
    }

    checkAllOrders = false;

    // Parts
    get isIndeterminateParts() {
      if (this.rights) {
        if (this.rights.can_manage_storages
          && this.rights.can_create_parts_and_categories
          && this.rights.can_create_posting_parts
          && this.rights.can_create_works_and_categories
          && this.rights.can_manage_part_prices_while_distribute
          && this.rights.can_create_fill_inventory
          && this.rights.can_edit_delete_parts_and_categories
          && this.rights.can_edit_posting_parts
          && this.rights.can_edit_delete_works_and_categories
          && this.rights.can_manage_works_and_parts_rewards
          && this.rights.can_manage_work_prices_while_distribute
          && this.rights.can_apply_inventory
          && this.rights.can_revert_inventory
          && this.rights.can_create_cancel
          && this.rights.can_remove_cancel) {
          this.checkAllParts = true;
          return false;
        }
        if (this.rights.can_manage_storages
            || this.rights.can_create_parts_and_categories
            || this.rights.can_create_posting_parts
            || this.rights.can_create_works_and_categories
            || this.rights.can_manage_part_prices_while_distribute
            || this.rights.can_create_fill_inventory
            || this.rights.can_edit_delete_parts_and_categories
            || this.rights.can_edit_posting_parts
            || this.rights.can_edit_delete_works_and_categories
            || this.rights.can_manage_works_and_parts_rewards
            || this.rights.can_manage_work_prices_while_distribute
            || this.rights.can_apply_inventory
            || this.rights.can_revert_inventory
            || this.rights.can_create_cancel
            || this.rights.can_remove_cancel) {
          this.checkAllParts = false;
          return true;
        }
      }
      return false;
    }

    checkAllParts = false;

    // Sells
    get isIndeterminateSells() {
      if (this.rights) {
        if (this.rights.can_create_sell
          && this.rights.can_unapply_sell
          && this.rights.can_delete_sell
          && this.rights.can_revert_sell
          && this.rights.can_remove_applied_sell_payment) {
          this.checkAllSells = true;
          return false;
        }
        if (this.rights.can_create_sell
            || this.rights.can_unapply_sell
            || this.rights.can_delete_sell
            || this.rights.can_revert_sell
            || this.rights.can_remove_applied_sell_payment) {
          this.checkAllSells = false;
          return true;
        }
      }
      return false;
    }

    checkAllSells = false;

    // Finance
    get isIndeterminateFinance() {
      if (this.rights) {
        if (this.rights.can_cash_out
          && this.rights.can_delete_cash_operation
          && this.rights.can_see_posting_price
          && this.rights.can_cash_in
          && this.rights.can_cash_transfer
          && this.rights.can_manage_cashboxes) {
          this.checkAllFinance = true;
          return false;
        }
        if (this.rights.can_cash_out
            || this.rights.can_delete_cash_operation
            || this.rights.can_see_posting_price
            || this.rights.can_cash_in
            || this.rights.can_cash_transfer
            || this.rights.can_manage_cashboxes) {
          this.checkAllFinance = false;
          return true;
        }
      }
      return false;
    }

    checkAllFinance = false;

    // Company
    get isIndeterminateCompany() {
      if (this.rights) {
        if (this.rights.can_move_between_points
          && this.rights.can_create_clients
          && this.rights.can_manage_clients
          && this.rights.can_manage_papers
          && this.rights.can_manage_stuff
          && this.rights.can_update_company_details
          && this.rights.can_see_clients
          && this.rights.can_manage_points
          && this.rights.can_manage_devices
          && this.rights.can_manage_forms) {
          this.checkAllCompany = true;
          return false;
        }
        if (this.rights.can_move_between_points
            || this.rights.can_create_clients
            || this.rights.can_manage_clients
            || this.rights.can_manage_papers
            || this.rights.can_manage_stuff
            || this.rights.can_update_company_details
            || this.rights.can_see_clients
            || this.rights.can_manage_points
            || this.rights.can_manage_devices
            || this.rights.can_manage_forms) {
          this.checkAllCompany = false;
          return true;
        }
      }
      return false;
    }

    checkAllCompany = false;

    // Reports
    get isIndeterminateReports() {
      if (this.rights) {
        if (this.rights.can_build_reports
          && this.rights.can_export) {
          this.checkAllReports = true;
          return false;
        }
        if (this.rights.can_build_reports
          || this.rights.can_export) {
          this.checkAllReports = false;
          return true;
        }
      }
      return false;
    }

    checkAllReports = false;

    // Analytics
    get isIndeterminateAnalytics() {
      this.checkAllAnalytics = false;
      if (this.rights) {
        if (this.rights.can_manage_analytics) {
          this.checkAllAnalytics = true;
          return false;
        }
        if (this.rights.can_manage_analytics) {
          this.checkAllAnalytics = false;
          return true;
        }
      }
      return false;
    }

    checkAllAnalytics = false;

    // Tasks
    get isIndeterminateTasks() {
      if (this.rights) {
        if (this.rights.can_update_foreign_tasks
          && this.rights.can_delete_foreign_tasks) {
          this.checkAllTasks = true;
          return false;
        }
        if (this.rights.can_update_foreign_tasks
          || this.rights.can_delete_foreign_tasks) {
          this.checkAllTasks = false;
          return true;
        }
      }
      return false;
    }

    checkAllTasks = false;

    // Tasks
    get isIndeterminateComments() {
      if (this.rights) {
        if (this.rights.can_delete_foreign_comments
          && this.rights.can_delete_self_comments) {
          this.checkAllComments = true;
          return false;
        }
        if (this.rights.can_delete_foreign_comments
          || this.rights.can_delete_self_comments) {
          this.checkAllComments = false;
          return true;
        }
      }
      return false;
    }

    checkAllComments = false;

    // Cashboxes
    get isIndeterminateCashboxes() {
      const userAccesCashboxesAmount = this.item.cashboxes?.length;
      if (userAccesCashboxesAmount) {
        const allCashboxesAmount = this.cash_list.length;
        if (userAccesCashboxesAmount === allCashboxesAmount) {
          this.checkAllCashboxes = true;
          return false;
        }
        this.checkAllCashboxes = false;
        return true;
      }
      this.checkAllCashboxes = false;
      return false;
    }

    checkAllCashboxes = false;

    // Storages
    get isIndeterminateStorages() {
      const userAccesStorageAmount = this.item.storages?.length;
      if (userAccesStorageAmount) {
        const allStorageAmount = this.storage_list.length;
        if (allStorageAmount === userAccesStorageAmount) {
          this.checkAllStorages = true;
          return false;
        }
        this.checkAllStorages = false;
        return true;
      }
      this.checkAllStorages = false;
      return false;
    }

    checkAllStorages = false;

    // Points

    get isIndeterminatePoints() {
      const userAccesPointsAmount = this.item.points?.length;
      if (userAccesPointsAmount) {
        const allPointsAmount = this.point.length;
        if (allPointsAmount === userAccesPointsAmount) {
          this.checkAllPoints = true;
          return false;
        }
        this.checkAllPoints = false;
        return true;
      }
      this.checkAllPoints = false;
      return false;
    }

    checkAllPoints = false;

    get excludedCashbox() {
      if (this.cash_list && this.cash_list.length !== 0) {
        return this.diffCashboxList(this.cash_list, this.item.cashboxes ? this.item.cashboxes : []);
      }
      return [];
    }

    get typeForm() {
      return this.type === 'edit';
    }

    get rights() {
      return this.item.rights === undefined ? false : this.item.rights;
    }

    get checkPass() {
      return (this.item as UserForm).password === undefined || this.copyPassword === (this.item as UserForm).password ? '' : 'Пароль указан не верно';
    }

    canWorkWithItCashboxes(scope) {
      const cashboxRowId = scope.row.id;
      const found = this.item.cashboxes?.find((cashbox) => cashbox.id === cashboxRowId);
      if (found) {
        return true;
      }
      return false;
    }

    changeCashboxesAccess(cash: Cashbox) {
      const cashboxRowId = cash.id;
      const found = this.item.cashboxes?.find((cashbox) => cashbox.id === cashboxRowId);
      if (found) {
        this.removeUserTableRowClick(cashboxRowId);
      } else if (this.item.cashboxes) {
        this.item.cashboxes.push(cash);
      }
    }

    getCashList() {
      CashProvider.getAllCashes(true)
        .then((res) => {
          this.cash_list = res;
        })
        .catch((err) => console.error(err));
    }

    canWorkWithItStorage(scope) {
      const storageID = scope.row.id;
      const found = this.item.storages?.find((storage) => (storage as Storage).id === storageID);
      if (found) {
        return true;
      }
      return false;
    }

    canWorkWithItPoints(scope) {
      const pointID = scope.row.id;
      const found = this.item.points?.find((point) => (point as Point).id === pointID);
      if (found) {
        return true;
      }
      return false;
    }

    changeStorageAccess(stor: Storage) {
      const storageID = stor.id;
      const found = this.item.storages?.find((storage) => (storage as Storage).id === storageID);
      if (found) {
        this.removeStorageTableRowClick(storageID);
      } else if (this.item.storages) {
        this.item.storages.push(stor);
      }
    }

    changePointsAccess(point: Point) {
      const pointID = point.id;
      const found = this.item.points?.find((p) => (p as Point).id === pointID);
      if (found) {
        this.removePointsTableRowClick(pointID);
      } else if (this.item.points) {
        this.item.points.push(point);
      }
    }

    async getStorageList() {
      try {
        const search: StorageSearchForm = {
          ignore_visibility_filter: true,
        };
        await StorageProvider.getStorageList(1, search)
          .then(async (res) => {
            this.storage_list = res.data;
            if (res.pageCount > 1) {
              const requesPromise: object[] = [];
              let page = 2;
              for (page; page < res.pageCount; page++) {
                requesPromise.push(StorageProvider.getStorageList(page, search));
              }
              await Promise.all(requesPromise)
                .then((results) => {
                  (results as Response[]).forEach((result) => {
                    this.storage_list = [...this.cash_list, ...result.data];
                  });
                });
            }
          })
          .catch((err) => console.error(err));
      } catch (err) {
        throw new Error(err);
      }
    }

    diffCashboxList = (cashbox_list1, cashbox_list2) => cashbox_list1.filter(
      (a1) => !cashbox_list2.some(
        (a2) => a1.id === a2.id,
      ),
    ).filter((item) => item)

    diffStorageList = (storage_list1: Storage[], storage_list2: Storage[]) => storage_list1.filter(
      (a1) => !storage_list2.some(
        (a2) => a1.id === a2.id,
      ),
    ).filter((item) => item)

    diffPointsList = (points_list1: Point[], points_list2: Point[]) => points_list1.filter(
      (a1) => !points_list2.some(
        (a2) => a1.id === a2.id,
      ),
    ).filter((item) => item)

    removeUserTableRowClick(cashId) {
      if (this.item.cashboxes) {
        this.item.cashboxes = this.item.cashboxes.filter((x) => x.id !== cashId);
      }
    }

    removeStorageTableRowClick(storID: number) {
      if (this.item.storages) {
        this.item.storages = this.item.storages.filter(
          (storage) => (storage as Storage).id !== storID,
        );
      }
    }

    removePointsTableRowClick(pointID: number) {
      if (this.item.points) {
        this.item.points = this.item.points.filter(
          (point) => (point as Point).id !== pointID,
        );
      }
    }

    addCashboxUserBtnClick() {
      const userToBeAdded = this.cash_list.find((x) => x.id === this.cashbox_add_user_form.id);
      this.cashbox_add_user_form.id = null;
      if (!userToBeAdded) {
        console.error('Неправильное количество касс после фильтрации');
        console.error(userToBeAdded);
        return;
      }
      if (this.item.cashboxes) {
        this.item.cashboxes.push(userToBeAdded);
      }
    }

    capitalizeFirstLetter(word, str) {
      if (word) {
        this.item[str] = word[0].toUpperCase() + word.slice(1);
      }
    }

    validatingBeforeRequest(): boolean {
      const error: string[] = [];
      if (!this.item.points?.length) {
        error.push('Не выбран филиал');
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Выберите филиал для сотрудника', item: '' });
      }
      const isValid = !error.length;
      return isValid;
    }

    async requestOnEditStuff() {
      const isValid = this.validatingBeforeRequest();
      if (!isValid) {
        return;
      }
      /**
         * @var переменная в которую сохраним обновленного сотрудника из ответа API
         */
      let apiStuff;
      let userCashboxes = [];
      let userStorage = [];
      let userPoints = [];

      if (this.type === 'add') {
        this.loading = true;
        try {
          apiStuff = (await StuffProvider.sendStuff(this.item)).data;
          this.loading = false;
          this.valid_stuff = {};
        } catch (e) {
          this.valid_stuff = e.response.data;
          if (this.valid_stuff) {
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Заполниет все обязательные поля', item: '' });
            window.scroll(0, 0);
          } else {
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          }
          console.error('Ошибка создания сотрудника', e);
          this.loading = false;
          return;
        }
      } else {
        this.loading = true;
        try {
          apiStuff = (await StuffProvider.changeStuff(this.item.id, this.item)).data;
          this.loading = false;
          this.valid_stuff = {};
        } catch (e) {
          this.valid_stuff = e.response.data;
          if (this.valid_stuff) {
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Заполниет все обязательные поля', item: '' });
            window.scroll(0, 0);
          } else {
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          }
          console.error('Ошибка изменения сотрудника', e);
          this.loading = false;
          return;
        }
      }

      userCashboxes = apiStuff.cashboxes;
      userStorage = apiStuff.storages;
      userPoints = apiStuff.points;

      const newCashboxInUser = this.diffCashboxList(this.item.cashboxes, userCashboxes);
      const removedCashboxsInUser = this.diffCashboxList(userCashboxes, this.item.cashboxes);

      await newCashboxInUser.forEach(async (x) => {
        try {
          await CashProvider.CashboxAddUserForm({ user_id: apiStuff.id, cashbox_id: x.id });
        } catch (e) {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка добавления кассы к сотруднику', item: '' });
          console.error('Ошибка добавления кассы к сотруднику', e);
        }
      });
      await removedCashboxsInUser.forEach(async (x) => {
        try {
          await CashProvider.CashboxRemoveUserForm({ user_id: apiStuff.id, cashbox_id: x.id });
        } catch (e) {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка отвязки кассы от сотрудника', item: '' });
          console.error('Ошибка отвязки кассы от сотрудника', e);
        }
      });

      const newStorageInUser = this.diffStorageList((this.item.storages as Storage[]), userStorage);
      const removedStorageInUser = this.diffStorageList(
        userStorage,
        (this.item.storages as Storage[]),
      );

      await newStorageInUser.forEach(async (x) => {
        try {
          await StorageProvider.StorageAddUserForm({ user_id: apiStuff.id, storage_id: x.id });
        } catch (e) {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка добавления кассы к сотруднику', item: '' });
          console.error('Ошибка добавления кассы к сотруднику', e);
        }
      });
      await removedStorageInUser.forEach(async (x) => {
        try {
          await StorageProvider.StorageRemoveUserForm({ user_id: apiStuff.id, storage_id: x.id });
        } catch (e) {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка отвязки кассы от сотрудника', item: '' });
          console.error('Ошибка отвязки кассы от сотрудника', e);
        }
      });

      const newPointsInUser = this.diffPointsList((this.item.points as Point[]), userPoints);
      const removedPointsInUser = this.diffPointsList(
        userPoints,
        (this.item.points as Point[]),
      );

      await newPointsInUser.forEach(async (x) => {
        try {
          await PointProvieder.addUser({ user_id: apiStuff.id, point_id: x.id });
        } catch (e) {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка добавления филиала к сотруднику', item: '' });
          console.error('Ошибка добавления филиала к сотруднику', e);
        }
      });
      await removedPointsInUser.forEach(async (x) => {
        try {
          await PointProvieder.removeUser({ user_id: apiStuff.id, point_id: x.id });
        } catch (e) {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка отвязки филиала от сотрудника', item: '' });
          console.error('Ошибка отвязки кассы от сотрудника', e);
        }
      });

      if (this.type === 'add') {
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Сотрудник создан', item: '' });
      } else {
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Сотрудник обновлен', item: '' });
      }
    }

    mounted() {
      this.updatePointsList();
      this.getCashList();
      this.getStorageList();
    }

    updatePointsList() {
      this.getPointList({ page: 1 });
    }

    validate = (obj: object, key: string) => {
      if (Object.keys(obj).some((v) => v === key)) {
        return obj[key][0];
      }
      return null;
    }

    // checkbocks Oreders
    handleCheckAllOrdersChange(val: boolean) {
      if (this.rights) {
        this.rights.can_create_update_repair = val;
        this.rights.can_see_foreign_repair = val;
        this.rights.can_edit_others_parts_works_repair = val;
        this.rights.can_transfer_repair = val;
        this.rights.can_update_repair_accepting = val;
        this.rights.can_see_others_repair = val;
        this.rights.can_work_on_repair = val;
        this.rights.can_apply_master_on_repair = val;
        this.rights.can_delete_repair = val;
        this.rights.can_restart_closed_repair = val;
        this.rights.can_remove_closed_repair_payment = val;
      }
    }

    // Checkboxes Parts
    handleCheckAllPartsChange(val: boolean) {
      if (this.rights) {
        this.rights.can_manage_storages = val;
        this.rights.can_create_parts_and_categories = val;
        this.rights.can_create_posting_parts = val;
        this.rights.can_create_works_and_categories = val;
        this.rights.can_manage_part_prices_while_distribute = val;
        this.rights.can_create_fill_inventory = val;
        this.rights.can_edit_delete_parts_and_categories = val;
        this.rights.can_edit_posting_parts = val;
        this.rights.can_edit_delete_works_and_categories = val;
        this.rights.can_manage_works_and_parts_rewards = val;
        this.rights.can_manage_work_prices_while_distribute = val;
        this.rights.can_apply_inventory = val;
        this.rights.can_revert_inventory = val;
        this.rights.can_create_cancel = val;
        this.rights.can_remove_cancel = val;
      }
    }

    // CheckboxesSells
    handleCheckAllSellsChange(val: boolean) {
      if (this.rights) {
        this.rights.can_create_sell = val;
        this.rights.can_unapply_sell = val;
        this.rights.can_delete_sell = val;
        this.rights.can_revert_sell = val;
        this.rights.can_remove_applied_sell_payment = val;
      }
    }

    // CheckboxesFinance
    handleCheckAllFinanceChange(val: boolean) {
      if (this.rights) {
        this.rights.can_cash_out = val;
        this.rights.can_delete_cash_operation = val;
        this.rights.can_see_posting_price = val;
        this.rights.can_cash_in = val;
        this.rights.can_cash_transfer = val;
        this.rights.can_manage_cashboxes = val;
      }
    }

    // CheckboxesCompany
    handleCheckAllCompanyChange(val: boolean) {
      if (this.rights) {
        this.rights.can_move_between_points = val;
        this.rights.can_create_clients = val;
        this.rights.can_manage_clients = val;
        this.rights.can_manage_papers = val;
        this.rights.can_manage_stuff = val;
        this.rights.can_update_company_details = val;
        this.rights.can_see_clients = val;
        this.rights.can_manage_points = val;
        this.rights.can_manage_devices = val;
        this.rights.can_manage_forms = val;
      }
    }

    // Checkboxes Reports
    handleCheckAllReportsChange(val: boolean) {
      if (this.rights) {
        this.rights.can_build_reports = val;
        this.rights.can_export = val;
      }
    }

    // Checkboxes Analytics
    handleCheckAllAnalyticsChange(val: boolean) {
      if (this.rights) {
        this.rights.can_manage_analytics = val;
      }
    }

    // Checkboxes Tasks
    handleCheckAllTasksChange(val: boolean) {
      if (this.rights) {
        this.rights.can_update_foreign_tasks = val;
        this.rights.can_delete_foreign_tasks = val;
      }
    }

    // Checkboxes Tasks
    handleCheckAllCommentsChange(val: boolean) {
      if (this.rights) {
        this.rights.can_delete_foreign_comments = val;
        this.rights.can_delete_self_comments = val;
      }
    }

    // Checkboxes Cahshboxes
    handleCheckAllCashboxesChange(val: boolean) {
      this.item.cashboxes = [];
      if (val) {
        this.cash_list.forEach((cash) => {
          (this.item.cashboxes as Cashbox[]).push(cash);
        });
      }
    }

    // Checkboxes Storages
    handleCheckAllStoragesChange(val: boolean) {
      this.item.storages = [];
      if (val) {
        this.storage_list.forEach((storage) => {
          (this.item.storages as Storage[]).push(storage);
        });
      }
    }

    // Checkboxes Points
    handleCheckAllPointsChange(val: boolean) {
      this.item.points = [];
      if (val) {
        this.point.forEach((point) => {
          (this.item.points as Point[]).push(point);
        });
      }
    }

    @Watch('valid_stuff')
    onChangeValid(val: object) {
      if (!Object.keys(val).length) {
        this.getUserMe();
        this.$router.push('/stuff');
      }
    }

    @Watch('item.last_name')
    viewLastNameClient(last_name) {
      this.capitalizeFirstLetter(last_name, 'last_name');
    }

    @Watch('item.first_name')
    viewFirstNameClient(first_name) {
      this.capitalizeFirstLetter(first_name, 'first_name');
    }

    @Watch('item.middle_name')
    viewMiddleNameClient(middle_name) {
      this.capitalizeFirstLetter(middle_name, 'middle_name');
    }
}
