import { TaskForm, TaskSearchApiForm } from '../../store/task/types';
import BaseProvider from '../base-provider';

class TaskProvider extends BaseProvider {
  async getTaskList(form: TaskSearchApiForm) {
    const expand: string[] = ['status'];
    let urlString = `/task/index?page=${form.page}&expand=${expand}`;
    if (form !== null) {
      if (form.id !== undefined) urlString += `&TaskSearchForm[id]=${form.id}`;
      if (form.title !== undefined) urlString += `&TaskSearchForm[title]=${form.title}`;
      if (form.description !== undefined) urlString += `&TaskSearchForm[description]=${form.description}`;
      if (form.priority !== undefined) urlString += `&TaskSearchForm[priority]=${form.priority}`;
      if (form.status_id !== undefined) urlString += `&TaskSearchForm[status_id]=${form.status_id}`;
      if (form.creator_id !== undefined) urlString += `&TaskSearchForm[creator_id]=${form.creator_id}`;
      if (form.responsible_account_id !== undefined) urlString += `&TaskSearchForm[responsible_account_id]=${form.responsible_account_id}`;
      if (form.created_at_interval !== undefined) {
        urlString += `&TaskSearchForm[created_at_start]=${form.created_at_interval[0] / 1000}`;
        urlString += `&TaskSearchForm[created_at_end]=${form.created_at_interval[1] / 1000}`;
      }
      if (form.completed_at_interval !== undefined) {
        urlString += `&TaskSearchForm[completed_at_start]=${form.completed_at_interval[0] / 1000}`;
        urlString += `&TaskSearchForm[completed_at_end]=${form.completed_at_interval[1] / 1000}`;
      }
    }
    const res = await this.request('GET', urlString);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getTask(id: number) {
    const expand: string[] = ['status'];
    const res = await this.request('GET', `/task/view?id=${id}&expand=${expand}`);
    return res.data;
  }

  async addTask(item: TaskForm) {
    const data: object = { Task: item };
    const res = await this.request('POST', '/task/add', data);
    return res;
  }

  async changeTask(id: number, item: TaskForm) {
    const data: object = { Task: item };
    const res = await this.request('POST', `/task/update?id=${id}`, data);
    return res;
  }

  async deleteTask(id: number) {
    try {
      return await this.request('POST', `/task/delete?id=${id}`);
    } catch (err) {
      throw new Error(err);
    }
  }

  // async changeCashType(item) {
  //     const data: object = { 'CashType' : item };
  //     let res = await this.request('POST', `/cashtype/update?id=${item.id}`, data);
  //     return res;
  // }
  //
  // async deleteCashType(id) {
  //     let res = await this.request('POST', `/cashtype/delete?id=${id}`);
  //     return res;
  // }
}

export default new TaskProvider();
