import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  template: require('./breadcrumb.html'),
})
class BreadcrumbComponent extends Vue {
    @Getter actionBtn;

    @Getter breadcrumb;

    @Getter auth;

    @Getter user;

    get flagsActionBtn() {
      return this.actionBtn.length;
    }

    isAuthorized() {
      return Object.keys(this.user).length !== 0;
    }

    onDialog(type) {
      this.$root.$emit('onDialog', type);
    }

    isLink(link, type) {
      if (link === 'onDialog') {
        this.onDialog(type);
      } else {
        this.$router.push(link);
      }
    }

    formattingDisabled = (disabled) => (disabled !== undefined ? disabled : false)

    type = (type) => {
      let result = '';
      switch (type) {
        case 'add': result = 'success'; break;
        case 'edt': result = 'primary'; break;
        case 'del': result = 'danger'; break;
        default: break;
      }
      return result;
    }
}

export { BreadcrumbComponent as default };
