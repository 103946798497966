import BaseProvider from '../base-provider';

class CategoryProvider extends BaseProvider {
  async getPartCategory() {
    const expand: string[] = ['children'];
    const res = await this.request('GET', `/partcategory/getastree?expand=${expand}`);
    return {
      data: res.data,
    };
  }

  async getWorkCategory() {
    const expand: string[] = ['children'];
    const res = await this.request('GET', `/workcategory/getastree?expand=${expand}`);
    return {
      data: res.data,
    };
  }

  async sendPartCategory(item) {
    const data: object = { PartCategory: item };
    const res = await this.request('POST', '/partcategory/add', data);
    return res;
  }

  async changePartCategory(item) {
    const data: object = { PartCategory: item };
    const res = await this.request('POST', `/partcategory/update?id=${item.id}`, data);
    return res;
  }

  async deletePartCategory(id, deleteInternal) {
    const res = await this.request('POST', `/partcategory/delete?id=${id}&deleteInternal=${deleteInternal}`);
    return res;
  }

  async sendWorkCategory(item) {
    const data: object = { WorkCategory: item };
    const res = await this.request('POST', '/workcategory/add', data);
    return res;
  }

  async changeWorkCategory(item) {
    const data: object = { WorkCategory: item };
    const res = await this.request('POST', `/workcategory/update?id=${item.id}`, data);
    return res;
  }

  async deleteWorkCategory(id, deleteInternal) {
    const res = await this.request('POST', `/workcategory/delete?id=${id}&deleteInternal=${deleteInternal}`);
    return res;
  }
}

export default new CategoryProvider();
