import { User } from '@/types';

export const GET_USER_ME = 'GET_USER_ME';
export const ADD_REPAIR_ACTIVE_COLUMN = 'ADD_REPAIR_ACTIVE_COLUMN';
export const CLEAR_USER = 'CLEAR_USER';
export const GET_VALID_NEW_PASSWORD = 'GET_VALID_NEW_PASSWORD';
export const UPDATE_USER = 'UPDATE_USER';
// Store

export interface StateUser {
    user: User;
    validNewPassword: object;
    updateUser: object;
}
