import { GetterTree } from 'vuex';
import { StateCategory } from './types';

const getters: GetterTree<StateCategory, object> = {
  categoryList: (state) => state.categories,
  categoriesMainList: (state) => state.categoriesMainList,
  newCategory: (state) => state.newCategory,
  categoriesValidate: (state) => state.categoriesValidate,
};

export default getters;
