import { PointSearchForm, PointActionUserForm } from '@/types';
import BaseProvider from '../base-provider';

class PointProvider extends BaseProvider {
  async getPointList(
    page,
  ) {
    const search: PointSearchForm = {
    };
    const searchForm: string = this.buildUrl({ PointSearchForm: search });
    const expand: string[] = ['type_name, cash_sum', 'users'];
    const res = await this.request('GET', `/point/index?page=${page}&expand=${expand}${searchForm}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      currentPage: res.headers['x-pagination-current-page'],
    };
  }

  async getViewPoint(id) {
    const expand: string[] = ['users'];
    const res = await this.request('GET', `/point/view?id=${id}&expand=${expand}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendPoint(item) {
    const data: object = { Point: item };
    const expand: string[] = ['users'];
    const res = await this.request('POST', `/point/add?expand=${expand}`, data);
    return res;
  }

  async changePoint(id, item) {
    const data: object = { Point: item };
    const expand: string[] = ['users'];
    const res = await this.request('POST', `/point/update?id=${id}&expand=${expand}`, data);
    return res;
  }

  async deletePoint(id) {
    const res = await this.request('POST', `/point/delete?id=${id}`);
    return res;
  }

  async addUser(item: PointActionUserForm) {
    const body: object = { PointAddUserForm: item };
    const res = await this.request('POST', '/point/adduser', body);
    return res;
  }

  async removeUser(item: PointActionUserForm) {
    const body: object = { PointRemoveUserForm: item };
    const res = await this.request('POST', '/point/removeuser', body);
    return res;
  }
}

export default new PointProvider();
