import { SellComment } from '@/types';
import BaseProvider from '../base-provider';

class SellProvider extends BaseProvider {
  async getSellList(page, item, perPage) {
    const searchForm: string = this.buildUrl({ SellSearchForm: item });
    const expand: string[] = ['client', 'seller', 'payments',
      'parts_total', 'parts_count', 'parts_reverts', 'parts.storage',
      'works_total', 'works_count', 'works_reverts', 'to_pay',
      'last_comment.user'];

    const res = await this.request('GET', `/sell/index?page=${page}&expand=${expand}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-current-page'],
    };
  }

  async getViewSell(id) {
    const expand: string[] = [
      'client', 'seller', 'payments', 'payments.cashbox',
      'discounts', 'works', 'parts', 'parts_reverts.part',
      'parts_total', 'parts_count', 'parts_vat', 'parts.part',
      'works.work', 'works_reverts.work', 'parts.seller',
      'works.seller', 'discounts'];

    const res = await this.request('GET', `/sell/view?id=${id}&expand=${expand}`);
    return res;
  }

  async sendSell(item) {
    const expand: string[] = [
      'client', 'seller', 'payments',
      'payments.cashbox', 'discounts', 'works.work',
      'parts.storage', 'parts.part'];

    const data: object = { SellCreateForm: item };
    const res = await this.request('POST', `/sell/create?expand=${expand}`, data);
    return res;
  }

  async sendSellPart(item) {
    const expand: string[] = ['part', 'seller'];
    const data: object = { SellPart: item };
    const res = await this.request('POST', `/sellpart/add?expand=${expand}`, data);
    return res;
  }

  async sendDiscount(item) {
    const data: object = { SellDiscount: item };
    const res = await this.request('POST', '/selldiscount/add', data);
    return res;
  }

  async sendSellWork(id) {
    const expand: string[] = ['work', 'seller'];
    const data: object = { SellWork: id };
    const res = await this.request('POST', `/sellwork/add?expand=${expand}`, data);
    return res;
  }

  async sendSellApply(item) {
    const expand: string[] = [
      'client', 'seller', 'payments',
      'payments.cashbox', 'discounts',
      'works.work', 'parts.storage', 'parts.part',
      'parts.seller', 'works.seller', 'parts_reverts.part',
      'works_reverts.work'];

    const data: object = { SellApplyForm: { sell_id: item } };
    const res = await this.request('POST', `/sell/apply?expand=${expand}`, data);
    return res;
  }

  async sendSellPayment(item) {
    const data: object = { SellPayment: item };
    const expand: string[] = ['cashbox'];
    const res = await this.request('POST', `/sellpayment/add?expand=${expand}`, data);
    return res;
  }

  async changSellPart(id, item) {
    const data: object = { SellPart: item };
    const res = await this.request('POST', `/sellpart/update?id=${id}`, data);
    return res;
  }

  async changSellWork(id, item) {
    const data: object = { SellWork: item };
    const res = await this.request('POST', `/sellwork/update?id=${id}`, data);
    return res;
  }

  async changeSell(id, item) {
    const expand: string[] = [
      'client', 'seller', 'payments',
      'payments.cashbox', 'discounts', 'works.work',
      'parts.storage', 'parts.part', 'parts.seller',
      'works.seller', 'parts_reverts.part', 'works_reverts.work'];
    const data: object = { Sell: { seller_id: item.seller_id, client_id: item.client_id } };
    const res = await this.request('POST', `/sell/update?id=${id}&expand=${expand}`, data);
    return res;
  }

  async sendSellCancel(item) {
    const data: object = { SellCancelForm: { sell_id: item } };
    const res = await this.request('POST', '/sell/cancel', data);
    return res;
  }

  async sendSellPartRevert(item) {
    const expand: string[] = ['part'];
    const data: object = { SellPartRevertForm: item };
    const res = await this.request('POST', `/sellpart/revert?expand=${expand}`, data);
    return res;
  }

  async sendSellWorkRevert(item) {
    const expand: string[] = ['work'];
    const data: object = { SellWorkRevertForm: item };
    const res = await this.request('POST', `/sellwork/revert?expand=${expand}`, data);
    return res;
  }

  async sendSellPartUnrevert(id) {
    const res = await this.request('POST', `/sellpartrevert/unrevert?id=${id}`);
    return res;
  }

  async sendSellWorkUnrevert(id) {
    const res = await this.request('POST', `/sellworkrevert/unrevert?id=${id}`);
    return res;
  }

  async sendSellRevert(item) {
    const expand: string[] = [
      'client',
      'seller', 'payments', 'payments.cashbox',
      'discounts', 'works.work', 'parts.storage',
      'parts.part', 'parts.seller', 'works.seller',
      'parts_reverts.part', 'works_reverts.work'];

    const data: object = { SellRevertForm: item };
    const res = await this.request('POST', `/sell/revert?expand=${expand}`, data);
    return res;
  }

  async deleteSell(id) {
    const data: object = { SellDeleteForm: { sell_id: id } };
    const res = await this.request('POST', '/sell/delete', data);
    return res;
  }

  async deleteSellPart(id) {
    const res = await this.request('POST', `/sellpart/delete?id=${id}`);
    return res;
  }

  async deleteSellPayments(id) {
    const res = await this.request('POST', `/sellpayment/delete?id=${id}`);
    return res;
  }

  async deleteSellDiscount(id) {
    const res = await this.request('POST', `/selldiscount/delete?id=${id}`);
    return res;
  }

  async deleteSellWork(id) {
    const res = await this.request('POST', `/sellwork/delete?id=${id}`);
    return res;
  }

  async getSellComments(item: SellCommentSearchForm, page = 1) {
    const expand: string[] = ['user'];
    const searchForm: string = this.buildUrl({ SellCommentSearchForm: item });

    const res = await this.request('GET', `/sellcomment/index?page=${page}&expand=${expand}${searchForm}`);
    return {
      data: res.data as SellComment[],
      pageCount: Number(res.headers['x-pagination-page-count']),
    };
  }

  async sendSellComment(form: SellAddForm) {
    try {
      return await this.request('POST', '/sellcomment/add?expand=user', { SellComment: form });
    } catch (err) {
      throw new Error(err);
    }
  }

  async deleteSellComment(commentId: number) {
    const res = await this.request('POST', `/sellcomment/delete?id=${commentId}`);
    return res;
  }
}

export default new SellProvider();

export interface SellCommentSearchForm {
    sell_id: number;
    include_deleted: boolean;
}

export interface SellAddForm {
    sell_id: number;
    content: string;
}
