import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CashboxFilterForm } from './types';

@Component({
  template: require('./index.html'),
})
export default class CashViewFilter extends Vue {
    @Action getCashOperation;

    @Action getAllCashTypeList;

    @Action getStuffList;

    @Getter cashTypeDefault;

    @Getter user;

    @Getter cashTypeUser;

    @Getter stuff;

    @Getter cashTypeUserOn;

    @Getter cashTypeUserOff;

    @Getter btnloader;

    @Prop({ default: () => ({}) }) form!: CashboxFilterForm;

    notDeletedOptions = [
      { key: null, value: 'Отображать все' },
      { key: 1, value: 'Только не удаленные' },
      { key: 0, value: 'Только удаленные' },
    ];

    rangeDate: object = {
      shortcuts: [
        {
          text: 'За вчера',
          onClick(picker) {
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            start.setHours(0, 0, 0, 0);
            picker.$emit('pick', [start, start]);
          },
        }, {
          text: 'За неделю',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: 'За месяц',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: 'За 3 месяца',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            picker.$emit('pick', [start, end]);
          },
        },
      ],
    };

    date: number[] | null= [];

    mounted() {
      this.getStuffList(1);
      this.getAllCashTypeList();
    }

    clear() {
      this.date = null;
      this.form.created_at_start = null;
      this.form.created_at_end = null;
      this.form.sum_min = null;
      this.form.sum_max = null;
      this.form.act_type_id = null;
      this.form.custom_act_type_id = null;
      this.form.created_by = null;
      this.getCashOperation(this.form);
    }

    applyFilterCashOperation() {
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] === null) { this.form[key] = ''; }
      });
      this.getCashOperation(this.form);
    }

    userTypeActive = (form) => {
      const type: boolean = Number(form.act_type_id) === 1 || Number(form.act_type_id) === 7;
      return !form.act_type_id || !type;
    }

    get typeUser() {
      let type: object[] = [];
      switch (this.form.act_type_id) {
        case 1: type = this.cashTypeUserOn; break;
        case 7: type = this.cashTypeUserOff; break;
        default: this.form.created_by = null;
      }
      return type;
    }

    @Watch('date')
    change(val: number[]) {
      if (val) {
        const valCopy = [...val];
        valCopy[1] += 3600 * 1000 * 24 - 1;
        this.form.created_at_start = Math.floor(valCopy[0] / 1000);
        this.form.created_at_end = Math.floor(valCopy[1] / 1000);
      } else {
        this.form.created_at_start = null;
        this.form.created_at_end = null;
      }
    }

    @Watch('form.act_type_id')
    changeActTypeId(value) {
      if (value !== 1 && value !== 7 && Object.prototype.hasOwnProperty.call(this.form, 'custom_act_type_id')) {
        this.form.custom_act_type_id = null;
      }
    }
}
