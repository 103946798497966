import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  template: require('./index.html'),
})
export default class SettingsStorage extends Vue {
    @Action getSettings;

    @Action updateSettings;

    @Getter settings;

    loading = true;

    async mounted() {
      await this.getSettings();
      this.loading = false;
    }

    changeAllowNegativeStorageAmount(value) {
      const newSettings = { ...this.settings };
      newSettings.allow_negative_storage_amount = value;
      this.updateSettings(newSettings);
    }

    changeAllowNegativeProfitOperationsr(value) {
      const newSettings = { ...this.settings };
      newSettings.allow_negative_profit_operations = value;
      this.updateSettings(newSettings);
    }
}
