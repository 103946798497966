import { ActionTree } from 'vuex';
import ExportProvider from '../../api/providers/export';
import { StateExport } from './types';

const actions: ActionTree<StateExport, object> = {

  getExportList({ commit }, page) {
    commit('BTN_LOADER', true);
    ExportProvider.getExportList(page)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('GET_EXPORT', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        console.error(err);
      });
  },

  requestRepairExport({ commit }, item) {
    commit('BTN_LOADER', true);
    ExportProvider.requestRepairExport(item)
      .then(() => {
        commit('BTN_LOADER', false);
        commit('EXPORT_VALIDATION', []);
        commit('PUSH_CALL_SUCCESS', { title: 'Экспорт добавлен в очередь', item: '' });
      })
      .catch((err) => {
        commit('EXPORT_VALIDATION', err.response.data);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  requestClientExport({ commit }, item) {
    commit('BTN_LOADER', true);
    ExportProvider.requestClientExport(item)
      .then(() => {
        commit('BTN_LOADER', false);
        commit('EXPORT_VALIDATION', []);
        commit('PUSH_CALL_SUCCESS', { title: 'Экспорт добавлен в очередь', item: '' });
      })
      .catch((err) => {
        commit('EXPORT_VALIDATION', err.response.data);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  requestPartNomenklaturaExport({ commit }, item) {
    commit('BTN_LOADER', true);
    ExportProvider.requestPartNomenklaturaExport(item)
      .then(() => {
        commit('BTN_LOADER', false);
        commit('EXPORT_VALIDATION', []);
        commit('PUSH_CALL_SUCCESS', { title: 'Экспорт добавлен в очередь', item: '' });
      })
      .catch((err) => {
        commit('EXPORT_VALIDATION', err.response.data);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  requestWorkNomenklaturaExport({ commit }, item) {
    commit('BTN_LOADER', true);
    ExportProvider.requestWorkNomenklaturaExport(item)
      .then(() => {
        commit('BTN_LOADER', false);
        commit('EXPORT_VALIDATION', []);
        commit('PUSH_CALL_SUCCESS', { title: 'Экспорт добавлен в очередь', item: '' });
      })
      .catch((err) => {
        commit('EXPORT_VALIDATION', err.response.data);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  requestPartAmountExport({ commit }, item) {
    commit('BTN_LOADER', true);
    ExportProvider.requestPartAmountExport(item)
      .then(() => {
        commit('BTN_LOADER', false);
        commit('EXPORT_VALIDATION', []);
        commit('PUSH_CALL_SUCCESS', { title: 'Экспорт добавлен в очередь', item: '' });
      })
      .catch((err) => {
        commit('EXPORT_VALIDATION', err.response.data);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  requestСashoperationsExport({ commit }, item) {
    commit('BTN_LOADER', true);
    ExportProvider.requestСashoperationsExport(item)
      .then(() => {
        commit('BTN_LOADER', false);
        commit('EXPORT_VALIDATION', []);
        commit('PUSH_CALL_SUCCESS', { title: 'Экспорт добавлен в очередь', item: '' });
      })
      .catch((err) => {
        commit('EXPORT_VALIDATION', err.response.data);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  requestSmsExport({ commit }, item) {
    commit('BTN_LOADER', true);
    ExportProvider.requestSmsExport(item)
      .then(() => {
        commit('BTN_LOADER', false);
        commit('EXPORT_VALIDATION', []);
        commit('PUSH_CALL_SUCCESS', { title: 'Экспорт добавлен в очередь', item: '' });
      })
      .catch((err) => {
        commit('EXPORT_VALIDATION', err.response.data);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },
};

export default actions;
