import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import PartProvider from '../../api/providers/part';
import ViewDate from '../forms/date';

@Component({
  name: 'log',
  template: require('./index.html'),
  components: {
    'view-date': ViewDate,
  },
})

export default class PartLogTable extends Vue {
    @Prop() storageId!: number;

    tableData;

    totalPages!: number;

    data() {
      return {
        tableData: this.tableData,
        totalPages: this.totalPages,
      };
    }

    mounted() {
      this.loadPage(1);
    }

    async loadPage(val) {
      const item: object = { part: this.$route.params.id, storage: this.storageId, page: val };
      try {
        const logData = await PartProvider.getPartLogList(item);
        this.tableData = logData.data.sort((a, b) => b.datetime - a.datetime);
        this.totalPages = logData.pageCount;
      } catch (err) {
        console.error(err);
        throw err;
      }
    }

    buildActionName = (item) => {
      let name = '';
      switch (item.operation_type) {
        case 1:
          name = 'Поступление №';
          break;
        case 2:
          name = 'Продажа №';
          break;
        case 3:
          name = 'Использование в заказе №';
          break;
        case 4:
          name = 'Списание №';
          break;
        case 5:
          name = 'Возврат продажи №';
          break;
        case 6:
          name = 'Перемещение со склада №';
          break;
        case 7:
          name = 'Перемещение на склад №';
          break;
        default:
          return '(Неизвестная операция)';
      }
      name += `${item.operation_id}`;
      return name;
    }

    buildLinkToAction = (item) => {
      let path = '';
      switch (item.operation_type) {
        case 1:
          path = '/part/posting/view/';
          break;
        case 2:
          path = '/sell/view/';
          break;
        case 3:
          path = '/repair/view/';
          break;
        case 4:
          path = '/cancel/view/';
          break;
        case 5:
          path = '/sell/view/';
          break;
        case 6:
        case 7:
          return '/storage/transfer';
        default:
          return null;
      }
      path += `${item.operation_id}`;
      return path;
    }
}
