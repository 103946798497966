import BaseProvider from '../base-provider';

class AuthProvider extends BaseProvider {
  async signIn(item) {
    const data: object = { LoginForm: item };
    const expand: string[] = ['user'];
    const res = await this.request('POST', `/site/login?expand=${expand}`, data);
    return res;
  }
}

export default new AuthProvider();
