import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb, Storage } from '@/types';
import InventoryProvider, { InventoryForm, InventoryNotListedStrategies } from '../../api/providers/inventory';
import StorageProvider from '../../api/providers/storage';

@Component({
  template: require('./index.html'),
})
export default class InventoryAdd extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Getter user;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Инвентаризация', link: '/inventory/1' }, { id: 3, section: 'Создание инвентаризации', link: null }];

    optionsOfStorage: Storage[] = [];

    inventoryForm: InventoryForm = {
      storage_id: null,
      not_listed_strategy: InventoryNotListedStrategies.ZERO,
    };

    mounted() {
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Создание инвентаризации';
      this.getStorageList(1);
    }

    async getStorageList(page) {
      await StorageProvider.getStorageList(page)
        .then((res) => {
          this.optionsOfStorage = res.data;
          const { pageCount } = res;
          if (pageCount > 1) {
            for (let i = 2; i <= pageCount; i++) {
              StorageProvider.getStorageList(i)
                .then((getStorageListRes) => {
                  this.optionsOfStorage = this.optionsOfStorage.concat(getStorageListRes.data);
                }).catch((err) => {
                  throw (err);
                });
            }
          }
          this.optionsOfStorage.forEach((elem) => {
            if (elem.point_id === this.user.point_id) this.inventoryForm.storage_id = elem.id;
          });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные', item: '' });
        });
    }

    addInventory() {
      if (this.inventoryForm.storage_id === null) {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Выберите склад', item: '' });
      } else {
        InventoryProvider.addNewInventory(this.inventoryForm)
          .then((res) => {
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Инвентаризация создана', item: '' });
            this.$router.push(`/inventory/view/${res.data.id}`);
          })
          .catch((err) => {
            console.error(err);
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось создать инвентаризацию', item: '' });
          });
      }
    }

    destroyed() {
      this.addActionBtn([]);
    }
}
