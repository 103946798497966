export default class ExportType {
  public static EXPORT_REPAIR = 1;

  public static EXPORT_PARTS_NOMENKLATURE = 2;

  public static EXPORT_PARTS_AMOUNT = 3;

  public static EXPORT_WORKS = 4;

  public static EXPORT_CLIENTS = 5;

  public static EXPORT_CASH = 6;

  public static EXPORT_SMS = 7;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  id: number;

  name: string;
}
