export const GET_CASH_LIST = 'GET_CASH_LIST';
export const GET_CASHBOX_VIEW = 'GET_CASHBOX_VIEW';
export const CREATE_CASHBOX = 'CREATE_CASHBOX';
export const GET_CASHOPERATIONS_LIST = 'GET_CASHOPERATIONS_LIST';
export const UPDATE_CASH_ITEM = 'UPDATE_CASH_ITEM';
export const DELETE_CASH_ITEM = 'DELETE_CASH_ITEM';
export const DELETE_CASH_OPERATIONS = 'DELETE_CASH_OPERATIONS';
export const CLICK_DETAIL = 'CLICK_DETAIL';
export const CLEAR_CASH = 'CLEAR_CASH';

export const CASH_VALIDATE = 'CASH_VALIDATE';

export const SEND_CASHOPERATION_IN = 'SEND_CASHOPERATION_IN';
export const SEND_CASHOPERATION_OUT = 'SEND_CASHOPERATION_OUT';
export const SEND_CASHOPERATION_TRANSFER = 'SEND_CASHOPERATION_TRANSFER';
// Store

export interface StateCash {
    cashList: Cash[];
    cashbox: Cash;
    cashOperationsList: object[] | null;
    cashListPageCount: number | null;
    cashValidate: object;
    cashOperationPageCount: number | null;
    cashOperationCountIn: number | null;
    cashOperationCountOut: number | null;
    cashOperationTotalIn: number | null;
    cashOperationTotalOut: number | null;
    cashViewDetail: string;
    cashOperationIn: object;
    cashOperationOut: object;
    cashOperationTransfer: object;
    cashOperationDelete: object;
}

// Models

export interface Cash {
  id: number;

}
