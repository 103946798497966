import { MutationTree } from 'vuex';
import {
  ADD_DEVICE_MODEL, ADD_DEVICE_TYPE,
  ADD_DEVICE_VENDOR,

  CLEAR_STATE_DEVICE, DELETE_DEVICE_MODEL, DELETE_DEVICE_TYPE,
  DELETE_DEVICE_VENDOR,

  DEVICE_VALIDATION, EDIT_DEVICE_MODEL, EDIT_DEVICE_TYPE,
  EDIT_DEVICE_VENDOR, GET_DEVICE_MODEL, GET_DEVICE_TYPE,
  GET_DEVICE_VENDOR,

  SEARCH_DEVICE,

  StateDevice, VALIDATION_IMEI,
} from './types';

function formList(elem, list) {
  const l = list;
  let res = [];
  l.forEach((i) => {
    res = i[elem] ? res.concat(i[elem]) : [];
  });
  return res;
}

const mutations: MutationTree<StateDevice> = {

  [GET_DEVICE_TYPE](state, { item }) {
    state.deviceTypeList = item;
    state.deviceVendorList = [];
  },

  [GET_DEVICE_VENDOR](state, { item }) {
    state.deviceVendorList = item;
    const typeIndex = state.deviceTypeList.findIndex(
      (i) => { if (item.length !== 0) return i.id === item[0].type_id; return false; },
    );
    if (typeIndex !== -1) state.deviceTypeList[typeIndex].vendors = item;
    state.deviceModelList = [];
  },

  [GET_DEVICE_MODEL](state, { item }) {
    state.deviceModelList = item;
    const vendorIndex = state.deviceVendorList.findIndex(
      (i) => { if (item.length !== 0) return i.id === item[0].vendor_id; return false; },
    );
    const typeIndex = state.deviceTypeList.findIndex(
      (i) => {
        if (item.length !== 0) return i.id === state.deviceVendorList[vendorIndex].type_id;
        return false;
      },
    );
    if (vendorIndex !== -1
      && typeIndex !== -1) state.deviceTypeList[typeIndex].vendors[vendorIndex].devices = item;
  },

  [VALIDATION_IMEI](state, { item }) {
    const { type } = item.vendor;
    const { vendor } = item;
    const device = item;

    const indexType = state.deviceTypeList.findIndex((deviceType) => deviceType.id === type.id);
    if (indexType === -1) state.deviceTypeList.push(type);
    state.deviceType = type;

    const indexVendor = state.deviceVendorList.findIndex(
      (deviceType) => deviceType.id === vendor.id,
    );
    if (indexVendor === -1) state.deviceVendorList.push(vendor);
    state.deviceVendor = vendor;

    state.deviceModelList.push(device);
    state.deviceModel = device;
  },

  /* eslint-disable no-param-reassign */
  [SEARCH_DEVICE](state, { type, vendor, model }) {
    function search(array) {
      let result = array;
      if (type.length) {
        const query = type.toLowerCase();
        result = result.filter((resultItem) => resultItem.name.toLowerCase().indexOf(query) !== -1);
      }
      if (vendor.length) {
        const query = vendor.toLowerCase();
        result = result.filter((resultItem) => {
          if (resultItem.vendors) {
            resultItem.vendors = resultItem.vendors.filter(
              (vendorResultItem) => vendorResultItem.name.toLowerCase().indexOf(query) !== -1,
            );
            return resultItem.vendors;
          }
          return false;
        }).filter((resultVendor) => resultVendor.vendors.length);
      }
      if (model.length) {
        const query = model.toLowerCase();
        result = result.filter((resultType) => {
          if (resultType.vendors) {
            resultType.vendors = resultType.vendors.filter((resultTypevVendor) => {
              if (resultTypevVendor.devices) {
                resultTypevVendor.devices = resultTypevVendor.devices.filter(
                  (device) => device.name.toLowerCase().indexOf(query) !== -1,
                );
                return resultTypevVendor.devices;
              }
              return false;
            }).filter((dev) => dev.devices.length);
            return resultType.vendors;
          }
          return false;
        }).filter((resultVendor) => resultVendor.vendors.length);
      }
      return result;
    }
    /* eslint-enable no-param-reassign */

    const typeList = JSON.parse(JSON.stringify(state.deviceTypeList));
    const result = search(typeList);
    const resVendors = formList('vendors', result);
    const resDevices = formList('devices', resVendors);

    state.searchTypeList = result;
    state.searchVendorList = resVendors;
    state.searchModelList = resDevices;
  },

  [CLEAR_STATE_DEVICE](state, from) {
    if (from === 'model') {
      state.deviceModelList = [];
    } else {
      state.deviceVendorList = [];
      state.deviceModelList = [];
    }
  },

  [ADD_DEVICE_TYPE](state, { item }) {
    state.deviceType = item;
  },

  [ADD_DEVICE_VENDOR](state, { item }) {
    state.deviceVendorList.push(item);
    state.deviceVendor = item;
  },

  [ADD_DEVICE_MODEL](state, { item }) {
    if (state.deviceVendorList.length !== 0) {
      const vendorIndex = state.deviceVendorList.findIndex((i) => i.id === item.vendor_id);
      const typeIndex = state.deviceTypeList.findIndex(
        (i) => i.id === state.deviceVendorList[vendorIndex].type_id,
      );

      if (vendorIndex !== -1 && typeIndex !== -1) {
        state.deviceTypeList[typeIndex].vendors[vendorIndex].devices = [];
        state.deviceTypeList[typeIndex].vendors[vendorIndex].devices.push(item);
      }
    }
    state.deviceModelList.push(item);
    state.deviceModel = item;
  },

  [EDIT_DEVICE_TYPE](state, { item }) {
    const index = state.deviceTypeList.findIndex((i) => i.id === item.id);
    state.deviceTypeList.splice(index, 1, item);
  },

  [EDIT_DEVICE_VENDOR](state, { item }) {
    const typeIndex = state.deviceTypeList.findIndex((i) => i.id === item.type_id);
    state.changeDiviceVendor = item;

    if (state.deviceTypeList[typeIndex].vendors) {
      const vendorIndex = state.deviceTypeList[typeIndex].vendors.findIndex(
        (i) => i.id === item.id,
      );
      if (vendorIndex !== -1) {
        state.deviceTypeList[typeIndex].vendors.splice(vendorIndex, 1, item);
      } else {
        state.deviceTypeList[typeIndex].vendors.push(item);
      }
    } else {
      state.deviceTypeList[typeIndex].vendors = [];
      state.deviceTypeList[typeIndex].vendors.push(item);
      state.deviceVendorList.push(item);
    }
  },

  [EDIT_DEVICE_MODEL](state, { item }) {
    const vendorIndex = state.deviceVendorList.findIndex((i) => i.id === item.vendor_id);
    const typeIndex = state.deviceTypeList.findIndex(
      (i) => i.id === state.deviceVendorList[vendorIndex].type_id,
    );

    if (vendorIndex !== -1 && typeIndex !== -1) {
      if (state.deviceTypeList[typeIndex].vendors[vendorIndex].devices) {
        const indexModel = state.deviceTypeList[typeIndex].vendors[vendorIndex].devices.findIndex(
          (i) => i.id === item.id,
        );
        if (indexModel !== -1) {
          state.deviceTypeList[typeIndex].vendors[vendorIndex].devices.splice(indexModel, 1, item);
          state.deviceModelList = state.deviceTypeList[typeIndex].vendors[vendorIndex].devices;
        }
      } else {
        state.deviceTypeList[typeIndex].vendors[vendorIndex].devices = [];
        state.deviceTypeList[typeIndex].vendors[vendorIndex].devices.push(item);
        state.deviceModelList.push(item);
      }
    }
  },

  [DELETE_DEVICE_TYPE](state, { item }) {
    const index = state.deviceTypeList.findIndex((i) => i.id === item.id);
    state.deviceTypeList.splice(index, 1);
    state.deviceVendorList = [];
    state.deviceModelList = [];
  },

  [DELETE_DEVICE_VENDOR](state, { item }) {
    const typeIndex = state.deviceTypeList.findIndex((i) => i.id === item.type_id);
    const vendorIndex = state.deviceTypeList[typeIndex].vendors.findIndex((i) => i.id === item.id);

    state.deviceTypeList[typeIndex].vendors.splice(vendorIndex, 1);
    state.deviceModelList = [];
  },

  [DELETE_DEVICE_MODEL](state, { item }) {
    const vendorIndex = state.deviceVendorList.findIndex(
      (i) => { if (item.length !== 0) return i.id === item.vendor_id; return false; },
    );
    const typeIndex = state.deviceTypeList.findIndex(
      (i) => {
        if (item.length !== 0) return i.id === state.deviceVendorList[vendorIndex].type_id;
        return false;
      },
    );

    if (vendorIndex !== -1 && typeIndex !== -1) {
      const indexModel = state.deviceTypeList[typeIndex].vendors[vendorIndex].devices.findIndex(
        (i) => i.id === item.id,
      );
      if (indexModel !== -1) {
        state.deviceTypeList[typeIndex].vendors[vendorIndex].devices.splice(indexModel, 1);
      }
    }
  },

  [DEVICE_VALIDATION](state, item) {
    state.deviceValid = item;
  },
};

export default mutations;
