import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { RawLocation } from 'vue-router';
import {
  Breadcrumb, ActionBtn, Work as WorkType, User,
} from '@/types';
import DocumentProvider from '../../api/providers/document';
import WorkProvider from '../../api/providers/work';
import ErrorAccessRights from '../error-access-rights';
import Numbers from '../forms/numbers';
import Category from '../__category';

interface Content {
  page?: number;
  name?: string;
  category_id?: number;
  include_deleted?: boolean;
  'per-page'?: number;
}

@Component({
  template: require('./work.html'),
  components: {
    'category-tree': Category,
    numbers: Numbers,
    'error-access-rights': ErrorAccessRights,
  },
})

export default class Work extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action getWorkCategory;

    @Action clearListCategories;

    @Getter categoryList;

    @Getter categoriesMainList;

    @Getter user;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Работы и услуги', link: null }];

    actionBtn: ActionBtn[] = [{
      id: 1, type: 'add', title: 'Новая работа', link: '/work/add', icon: 'fa-plus',
    }];

    loading_work_list = true;

    work_list: WorkType[] = [];

    work_page_count: number | null = null;

    work_page_current: number | null = null;

    open = false;

    catalog_name = 'Каталог';

    dialog = false;

    content: Content = {
      page: 1,
      name: '',
      include_deleted: false,
      category_id: 1,
      'per-page': 20,
    };

    navigation_page: Content = {};

    loading_category = true;

    flag_active_collapse = true;

    private stickerDocumentId!: number;

    get rightsUserPage() {
      return this.user.rights ? this.user.rights.can_create_works_and_categories : false;
    }

    get rightsEditAndChange() {
      return this.user.rights ? this.user.rights.can_edit_delete_works_and_categories : false;
    }

    // api

    // Получение списка услуг
    getWorkList(data = this.content) {
      WorkProvider.getWorkList(data.page, data, data['per-page'])
        .then((res) => {
          this.work_list = res.data;
          this.work_page_count = Number(res.pageCount);
          this.work_page_current = Number(res.pageCurrent);

          this.loading_work_list = false;
        }).catch((err) => {
          this.loading_work_list = false;
          console.error(err);
        });
    }

    // удаление услуги
    deleteWork(id) {
      WorkProvider.deleteWork(id)
        .then((res) => {
          const index = this.work_list.findIndex((i) => i.id === res.data.id);
          if (this.content.include_deleted === true) {
            this.work_list[index].enabled = false;
          } else {
            this.work_list.splice(index, 1);
          }

          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Работа ${res.data.name} успешно удалена`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    // end api

    openCategory() {
      this.open = !this.open;
      this.catalog_name = this.open ? '' : 'Каталог';
    }

    mounted() {
      const { query } = this.$route;

      document.title = 'Работы и услуги';
      this.loadingPageByRoute();
      this.getWorkCategory('work');
      this.addBreadcrumb(this.breadcrumb);

      if ('category_id' in query) {
        this.actionBtn[0].link = { path: '/work/add', query: { category_id: query.category_id } };
      }
      if (this.user.rights
        && this.user.rights.can_create_works_and_categories) this.addActionBtn(this.actionBtn);

      this.changeCategory();
      this.$on('openCategory', this.openCategory);
      DocumentProvider.getDocumentList(1, { type: 9 }, 20)
        .then((resp) => {
          if (resp.data && resp.data.length > 0) {
            this.stickerDocumentId = resp.data[0].id;
          } else {
            console.warn('Не нашли стикер для товара на сервере');
          }
        }).catch((err) => {
          console.error(err);
        });
    }

    destroyed() {
      this.addActionBtn([]);
      this.clearListCategories();
      this.$root.$off('categoryItemActive');
    }

    workIsEnable = (work: WorkType) => work.enabled

    revertWork(id) {
      WorkProvider.revertWork(id)
        .then((res) => {
          const index = this.work_list.findIndex((i) => i.id === res.data.id);
          this.work_list[index].enabled = true;

          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Услуга ${res.data.name} успешно возвращен`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    methodAddRouteNavigation() {
      this.$router.push({ query: { ...this.navigation_page } } as RawLocation);
    }

    changeCategory() {
      this.$root.$on('categoryItemActive', (i) => {
        const val = this.content.category_id === i ? 1 : i;
        this.content.category_id = val;
        this.navigation_page.category_id = val;
        this.search();
      });
    }

    search(flagRoute = true) {
      this.loading_work_list = true;
      this.getWorkList(this.content);
      if (flagRoute) this.methodAddRouteNavigation();
    }

    loadingPageByRoute() {
      const query = Object.keys(this.$route.query);

      if (query.length !== 0) {
        query.forEach((key) => {
          this.content[key] = key === 'per-page' || key === 'category_id' ? Number(this.$route.query[key]) : this.$route.query[key];
        });
        this.navigation_page = { ...this.$route.query };
        this.search(false);
      } else {
        this.getWorkList(this.content);
      }
    }

    typePerPage(count) {
      return count === this.content['per-page'] ? 'primary' : '';
    }

    changePage(val) {
      this.content.page = val;
      this.navigation_page.page = val;
      this.search();
      window.scroll(0, 0);
    }

    changePerPage(val) {
      this.content['per-page'] = val;
      this.navigation_page['per-page'] = val;
      this.search();
    }

    methodDisabledButtons() {
      this.$notify.error({
        title: 'Вам не разрешено изменять/удалять услуги и категории услуг',
        message: '',
        position: 'bottom-right',
      });
    }

    updateWorkAtRemoval() {
      this.content.category_id = 1;
      this.navigation_page.category_id = 1;
      this.search();
    }

    handleSearchName(val: string) {
      if (val === '') {
        delete this.navigation_page.name;
      } else {
        this.navigation_page.name = val;
      }
      this.search();
    }

    openCategories(value) {
      this.flag_active_collapse = value.length !== 0;
    }

    printSticker(partId) {
      if (this.stickerDocumentId) {
        const domain = process.env.NODE_ENV === 'production' ? 'printing.servix.io' : 'printing-dev.servix.io';
        const token = DocumentProvider.access;
        window.open(`https://${domain}/print/document?token=${token}&documentId=${this.stickerDocumentId}&entityId=${partId}&pdf=1`, '_blank');
      } else {
        console.error('Отсутствует stickerDocumentId');
      }
    }

    @Watch('categoryList')
    listCategories() {
      this.loading_category = false;
    }

    @Watch('user')
    meUser(user: User) {
      if (user.rights) {
        if (user.rights.can_create_works_and_categories) {
          this.addActionBtn(this.actionBtn);
        } else {
          this.addActionBtn([]);
        }
      } else {
        this.addActionBtn([]);
      }
    }

    @Watch('$route.query')
    viewRouteQuery(query) {
      this.actionBtn[0].link = { path: '/work/add', query: { category_id: query.category_id } };
      if (this.user.rights
        && this.user.rights.can_create_works_and_categories) this.addActionBtn(this.actionBtn);
    }
}
