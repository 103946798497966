export const GET_SMS_OFFERS = 'GET_SMS_OFFERS';
export const GET_ACQUIRERS = 'GET_ACQUIRERS';

export const SEND_BUY_SMS = 'SEND_BUY_SMS';
export const SEND_BUY_LICENSE = 'SEND_BUY_LICENSE';
export const GET_TARIFF = 'GET_TARIFF';
export const GET_BALANCE = 'GET_BALANCE';

// Store

export interface StateBilling {
  smsOffers: object[];
  acquirers: object[];
  buySms: object;
  balance: object;
  tariffLicense: object;
  buyLicense: object;
}
// Models
