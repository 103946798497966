import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Image } from '@/types';
import PartProvider from '../../api/providers/part';
import Numbers from '../forms/numbers';
import LazyLoadImage from '../lazy-load-image';

let openPartPopover = false;

interface NewItem {
  sell_reward_size?: string | null;
  repair_reward_size?: string | null;
}

interface Item {
  id: number;
  images: Image;
}
@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    'lazy-load-image': LazyLoadImage,
  },
})

export default class NomenklaturaItem extends Vue {
    @Prop() item!: Item;

    @Prop() index!: number;

    @Prop() vatList!: object[];

    @Prop() rewardTypeList!: object[];

    @Prop() headNomenkl!: object;

    @Getter user;

    dialog = false;

    pop_name = false;

    pop_repair_price = false;

    pop_sell_price = false;

    pop_repair_reward = false;

    pop_sell_reward = false;

    pop_art = false;

    pop_vat = false;

    load = false;

    new_item: NewItem = {};

    type = '';

    view_image_on_hover = false;

    loading_form = false;

    // api

    sendCopyPart(item) {
      this.loading_form = true;
      PartProvider.sendCopyPart(item)
        .then((res) => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Копия создана успешно', item: '' });
          this.$emit('copyPart', res.data, this.index);

          this.dialog = false;
          this.loading_form = false;
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading_form = false;
        });
    }

    changePart(item) {
      this.loading_form = true;
      PartProvider.changePart(item)
        .then((res) => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `${item.name} успешно изменен`, item: '' });
          this.$emit('changePart', res.data);

          this.close();
          this.load = false;

          this.loading_form = false;
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading_form = false;
          this.load = false;
        });
    }

    get imagesItem() {
      return this.item.images ? this.item.images.small : null;
    }

    get rightsUser() {
      return this.user.rights ? this.user.rights.can_edit_delete_parts_and_categories : false;
    }

    copy() {
      const item = { part_id: this.item.id };
      this.sendCopyPart(item);
    }

    open(type, val) {
      openPartPopover = val;
      switch (type) {
        case 'name': this.pop_name = val; break;
        case 'price_repair': this.pop_repair_price = val; break;
        case 'price_sell': this.pop_sell_price = val; break;
        case 'reward_repair': this.pop_repair_reward = val; break;
        case 'reward_sell': this.pop_sell_reward = val; break;
        case 'art': this.pop_art = val; break;
        case 'vat': this.pop_vat = val; break;
        default: break;
      }
    }

    methodDisabledButtons() {
      this.$notify.error({
        title: 'Вам не разрешено изменять и удалять товары, услуги и категории',
        message: '',
        position: 'bottom-right',
      });
    }

    formattingEmptyFields() {
      if (this.new_item.sell_reward_size === '') {
        delete this.new_item.sell_reward_size;
        this.new_item.sell_reward_size = null;
      }

      if (this.new_item.repair_reward_size === '') {
        delete this.new_item.repair_reward_size;
        this.new_item.repair_reward_size = null;
      }
    }

    clickCell(type) {
      if (!this.user.rights.can_edit_delete_parts_and_categories) {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Недостаточно прав', item: 'У вас нет права изменять и удалять товары и категории' });
        return;
      }
      if (!this.user.rights.can_manage_works_and_parts_rewards && (type === 'reward_repair' || type === 'reward_sell')) {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Недостаточно прав', item: 'У вас нет права управлять параметрами вознаграждений товаров и услуг' });
        return;
      }
      if (!openPartPopover && this.type === '') {
        this.type = type;
        this.new_item = JSON.parse(JSON.stringify(this.item));
        this.open(type, true);
      }
    }

    close() {
      this.open(this.type, false);
      this.type = '';
    }

    save() {
      if (this.type) {
        this.formattingEmptyFields();
        this.load = true;
        this.changePart(this.new_item);
      }
    }

    sticker() {
      this.$emit('onStickerClick', this.item.id);
    }
}
