import RepairProvider from '../../../api/providers/repair';

export default function getRepairList(this, data) {
  this.loading_table_repair = true;
  RepairProvider.getRepairList(data.page, data, data['per-page'])
    .then((res) => {
      const list = res.data;
      const page_count = Number(res.pageCount);
      const page_current = Number(res.pageCurrent);

      if (list.length > 0) {
        list.forEach((item) => this.data_repair.push(item));
      }

      if (page_count !== 0 && page_count !== page_current) {
        const new_page_current = page_current + 1;
        getRepairList.call(this, {
          page: new_page_current, 'per-page': 20, client_id: Number(this.$route.params.id), status_ids: '1,2,3,4,5,6',
        });
      } else {
        const totalSum = this.data_repair.reduce((sum, sum2) => sum + sum2.total, 0);
        this.data_repair.push({ title: 'Итого:', total: totalSum });
        this.loading_table_repair = false;
      }
    }).catch((err) => {
      console.error(err);
      this.loading_table_repair = false;
    });
}
