import { MutationTree } from 'vuex';
import {
  EDIT_CLIENT, GET_CLIENT,
  GET_CLIENT_INN,

  GET_VALID_COMPANY, GET_VALID_PERSON,

  GET_VIEW_CLIENT,

  INSERT_CLIENT, SEND_CLIENT_CHECK_BLACKLIST,
  SEND_CLIENT_SET_BLACKLIST, SEND_CLIENT_SET_REGULAR, SEND_CLIENT_SET_VIP,

  StateClient,
} from './types';

const mutations: MutationTree<StateClient> = {

  [GET_CLIENT](state, { item, pageCount, pageCurrent }) {
    state.client = item;
    state.pageCount = pageCount;
    state.pageCurrent = pageCurrent;
  },

  [GET_VIEW_CLIENT](state, { item }) {
    state.viewClient = item;
  },

  [GET_VALID_PERSON](state, { item }) {
    state.clientPersonValid = item;
  },

  [GET_VALID_COMPANY](state, { item }) {
    state.clientCompanyValid = item;
  },

  [GET_CLIENT_INN](state, { item }) {
    state.clientInn = item;
  },

  [SEND_CLIENT_SET_VIP](state, { item }) {
    state.clientVip = item;
  },

  [SEND_CLIENT_SET_REGULAR](state, { item }) {
    state.clientRegular = item;
  },

  [SEND_CLIENT_SET_BLACKLIST](state, { item }) {
    state.clientBlackList = item;
  },

  [SEND_CLIENT_CHECK_BLACKLIST](state, { item }) {
    state.clientCheckBlackList = item;
  },

  [EDIT_CLIENT](state, { item }) {
    const index = state.client.findIndex((i) => i.id === item.id);
    state.client.splice(index, 1, item);
    state.viewClient = item;
  },

  [INSERT_CLIENT](state, { item }) {
    state.client.push(item);
  },

};

export default mutations;
