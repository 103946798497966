import BaseProvider from '../base-provider';

class TransferProvider extends BaseProvider {
  async getTransfer(page, form = null) {
    const expand: string[] = ['user', 'in_storage', 'out_storage'];
    let res;
    if (form) {
      const searchForm: string = this.buildUrl({ StorageTransferSearchForm: form });
      res = await this.request('GET', `/transfer/index?page=${page}&expand=${expand}&${searchForm}`);
    } else {
      res = await this.request('GET', `/transfer/index?page=${page}&expand=${expand}`);
    }
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getViewTransfer(id) {
    const expand: string[] = ['part_transfers.part', 'in_storage', 'out_storage', 'user'];
    const res = await this.request('GET', `/transfer/view?id=${id}&expand=${expand}`);
    return res;
  }

  async sendTransfer(item) {
    const data: object = { StorageTransferForm: item };
    const res = await this.request('POST', '/transfer/apply', data);
    return res;
  }

  async changeClientSource(item) {
    const data: object = { ClientSource: item };
    const res = await this.request('POST', `/clientsource/update?id=${item.id}`, data);
    return res;
  }

  async deleteClientSource(id) {
    const res = await this.request('POST', `/clientsource/delete?id=${id}`);
    return res;
  }
}

export default new TransferProvider();
