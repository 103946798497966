import Vue from 'vue';
import { AxiosResponse } from 'axios';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import IntegrationEditProntoSms from '@/components/integration-prontosms';
import CompanyProvider from '../../api/providers/company';
import IntegrationsProvider from '../../api/providers/integrations';
import IntegrationEditBsg from '../../components/integration-edit-bsg';
import IntegrationEditSmsintel from '../../components/integration-edit-smsintel';
import IntegrationEditSmsru from '../../components/integration-edit-smsru';
import IntegrationItem from '../../components/integration-item';

@Component({
  template: require('./index.html'),
  components: {
    'integration-item': IntegrationItem,
    'integration-edit-bsg': IntegrationEditBsg,
    'integration-edit-smsintel': IntegrationEditSmsintel,
    'integration-edit-smsru': IntegrationEditSmsru,
    'integration-edit-pronto-sms': IntegrationEditProntoSms,
  },
})
export class Integrations extends Vue {
    @Getter company;

    @Action addBreadcrumb;

    @Action getCompanyInfo;

    smsProviders: IntegrationItmeOfUser[] = [];

    itemsProviders: IntegrationListProviders[] = [
      {
        name: 'Servix', description: 'Встроенный шлюз отправки СМС. По-умолчанию сообщения отправляются от имени Servix.io. Вы можете заказать индивидуальное имя отправителя через техподдержку. Позволяет отправлять сообщения клиентам и мастерам, отслеживать доставку SMS.', logo_url: 'https://cdn1.vulcanm.ru/assets/sms_providers/sms.png', enabled: true, info_url: '', id: 'SERVIX',
      },
      {
        name: 'Автоматический звонок', description: 'Робот позвонит вашим клиентам и сообщит о готовности заказа. Клиент получит звонок с вашего номера. Отслеживается успешность звонка.', logo_url: 'https://cdn1.vulcanm.ru/assets/sms_providers/call.png', enabled: false, info_url: 'https://servix.io/spravka/golosovie-uvedomleniya', id: 'SERVIX',
      },
    ];

    editModalName = '';

    editModalList: string[] = [
      'bsg',
      'smsru',
      'smsintel',
      'pronto',
    ];

    async mounted() {
      document.title = 'Интеграции Servix.io';
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: null },
        { id: 2, section: 'Настройки', link: null },
        { id: 3, section: 'Интеграции', link: null },
      ]);
      await this.getCompanyInfo();
      this.getListProviders();
    }

    getListProviders() {
      IntegrationsProvider.getListProviders()
        .then((res) => {
          this.itemsProviders = this.itemsProviders.concat(res.data);
          this.getSmsSenders(1);
        })
        .catch((err) => {
          console.log('err', err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить список провайдеров', item: '' });
        });
    }

    getSmsSenders(page) {
      IntegrationsProvider.getSmsSenders(page)
        .then(async (res) => {
          let configedProviders = res.data;
          const { pageCount } = res;
          if (pageCount > 1) {
            const arrRequests: object[] = [];
            for (let i = 2; i <= pageCount; i++) {
              arrRequests.push(IntegrationsProvider.getSmsSenders(i));
            }
            await Promise.all(arrRequests)
              .then((results) => {
                results.forEach(
                  (elem) => {
                    configedProviders = configedProviders.concat((elem as AxiosResponse).data);
                  },
                );
              });
          }
          const newSmsProviders: IntegrationItmeOfUser[] = [];
          this.itemsProviders.forEach((itemProvider) => {
            const equalIndex = configedProviders.findIndex(
              (configedProvider) => itemProvider.id === configedProvider.provider,
            );
            if (equalIndex > -1) {
              newSmsProviders.push({
                name: itemProvider.name,
                description: itemProvider.description,
                logo_url: itemProvider.logo_url,
                info_url: itemProvider.info_url,
                provider: configedProviders[equalIndex].provider,
                id: configedProviders[equalIndex].id,
                sender_name: configedProviders[equalIndex].sender_name,
                config: configedProviders[equalIndex].config,
                active: configedProviders[equalIndex].active,
                created_at: configedProviders[equalIndex].created_at,
              });
            } else {
              newSmsProviders.push({
                name: itemProvider.name,
                description: itemProvider.description,
                logo_url: itemProvider.logo_url,
                info_url: itemProvider.info_url,
                provider: itemProvider.id,
                id: null,
                sender_name: null,
                config: null,
                active: null,
                created_at: null,
              });
            }
          });
          new Promise((resolve) => {
            CompanyProvider.getCompanyInfo().then((service) => {
              newSmsProviders[0].active = service.data.is_sms_enabled;
              newSmsProviders[1].active = service.data.is_sms_enabled;
              resolve();
            }).catch((err) => {
              console.log('err', err);
              this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о доступности наших служб', item: '' });
              resolve();
            });
          }).then(() => {
            this.smsProviders = newSmsProviders;
          });
        }).catch((err) => {
          console.log('err', err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить провайдеров', item: '' });
        });
    }

    activateSmsSenders(id) {
      IntegrationsProvider.activateSmsSenders(id)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Провайдер активирован', item: '' });
          this.getSmsSenders(1);
        })
        .catch((err) => {
          console.log('err', err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось активировать провайдеров', item: '' });
        });
    }

    deactivateSmsSenders(id) {
      IntegrationsProvider.deactivateSmsSenders(id)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Провайдер деактивирован', item: '' });
          this.getSmsSenders(1);
        })
        .catch((err) => {
          console.log('err', err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось деактивировать провайдеров', item: '' });
        });
    }

    editModalView(name) {
      if (this.editModalList.indexOf(name) > -1) {
        this.editModalName = name;
      } else {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'К сожалению, невозможно настроить провайдера', item: '' });
      }
    }
}

export interface IntegrationListProviders {
    name: string;
    description: string;
    logo_url: string;
    enabled: boolean;
    info_url: string;
    id: string;
}
interface IntegrationItmeOfUser {
    name: string;
    description: string;
    logo_url: string;
    info_url: string;
    provider: string;
    id: string | null;
    sender_name: string | null;
    config: string | null;
    active: boolean | null;
    created_at: string | null;
}
