import Editor from '@tinymce/tinymce-vue';
import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import TemplateFormProvider from '@/api/providers/template-form';

@Component({
  template: require('./index.html'),
  components: {
    'tinymce-editor': Editor,
  },
})
export default class ViewPapersPage extends Vue {
    @Action getViewDocument;

    @Action addBreadcrumb;

    @Action getDocumentList;

    @Action addDocument;

    @Action updateDocument;

    @Action deleteDocument;

    @Getter document;

    @Getter documentList;

    @Getter newDocument;

    @Getter delDocument;

    @Getter editDocument;

    @Getter markersCompany;

    @Getter markersOrder;

    @Getter markersSales;

    @Getter markersRepair;

    @Getter markersAdditionally;

    @Getter markersSoldProducts;

    @Getter markersSoldWorks;

    @Getter markersSparePart;

    @Getter markersServiceWork;

    @Getter btnloader;

    modelDocument: DocumentTemplate = {
      id: null, content: '', type: null, deleted: false, title: '', is_draft: false,
    };

    activeMarker = 'Компания';

    documnets: object = { 'per-page': 20, page: 1 };

    titleStickersAndTags = '';

    loader = false;

    loader_btn_remove = false;

    templatePageList = 1;

    markersCustomFields: object[] = [];

    rules: object = {
      title: { required: true, trigger: 'blur', message: 'Необходимо заполнить поле «Название».' },
      content: { required: true, trigger: 'blur', message: 'Необходимо заполнить поле «Содержимое».' },
    };

    getFormList: object[] = [];

    formPageCount = 1;

    mounted() {
      if (this.viewDocument) this.getViewDocument(this.$route.params.id);
      if (this.documentList.length === 0 && this.flagDocumentStickersAndTags) {
        this.getDocumentList(this.documnets);
      } else {
        this.methodViewDocumentStickersAndTags(this.documentList);
      }
      this.getTemplateFormList(1);
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: null },
        { id: 2, section: 'Шаблоны документов', link: '/service/papers' },
        { id: 3, section: 'Новый шаблон', link: null },
      ]);
      document.title = 'Редактирование шаблона';
    }

    get viewDocument() {
      return this.$route.name === 'edit-paper';
    }

    get flagDocumentStickersAndTags() {
      return this.$route.name !== 'edit-paper' && this.$route.name !== 'add-paper';
    }

    get pathDocumentsStickersAndTags() {
      return this.flagDocumentStickersAndTags ? this.$route.path : null;
    }

    get typeDocument() {
      return this.viewDocument && this.document
        ? this.document.type
        : Number(this.$route.params.type);
    }

    insertContent = (value) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).tinyMCE.activeEditor.insertContent(value);
    }

    methodViewDocumentStickersAndTags(documents) {
      if (this.flagDocumentStickersAndTags) {
        switch (this.pathDocumentsStickersAndTags) {
          case '/service/pricesticker': this.getViewDocument(documents.find((document) => document.type === 6).id); break;
          case '/service/repairsticker': this.getViewDocument(documents.find((document) => document.type === 7).id); break;
          case '/service/partsticker': this.getViewDocument(documents.find((document) => document.type === 8).id); break;
          case '/service/worksticker': this.getViewDocument(documents.find((document) => document.type === 9).id); break;
          default: break;
        }
      }
    }

    handleBreadCrumb(document) {
      if (this.flagDocumentStickersAndTags) {
        switch (document.type) {
          case 6: this.titleStickersAndTags = 'Шаблон ценника'; break;
          case 7: this.titleStickersAndTags = 'Шаблон наклейки для заказа'; break;
          case 8: this.titleStickersAndTags = 'Шаблон наклейки товара'; break;
          case 9: this.titleStickersAndTags = 'Шаблон наклейки услуги'; break;
          default: break;
        }

        this.addBreadcrumb([
          { id: 1, section: 'Компания', link: null },
          { id: 2, section: 'Шаблоны документов', link: '/service/papers' },
          { id: 3, section: this.titleStickersAndTags, link: null },
        ]);
      } else {
        this.addBreadcrumb([
          { id: 1, section: 'Компания', link: null },
          { id: 2, section: 'Шаблоны документов', link: '/service/papers' },
          { id: 3, section: `${document.title}`, link: null },
        ]);
      }
    }

    redirectAfterChangeDocument() {
      this.loader = false;
      this.$router.push({ name: 'papers' });
    }

    /**
     * @todo чем отличается вызов этого метода от вызова updateDocument(modelDocument),
     * loader = true в шаблоне на 203 строке?
     */
    totalHandlerChangeDocument(formName) {
      this.modelDocument.content = this.clearDocument(this.modelDocument.content);

      (this.$refs[formName] as Form).validate((valid) => {
        if (valid) {
          if (this.viewDocument || this.flagDocumentStickersAndTags) {
            this.updateDocument(this.modelDocument);
          } else {
            const type = Number(this.$route.params.type);

            this.modelDocument.type = type;
            this.addDocument(this.modelDocument);
          }

          this.loader = true;
          return true;
        }
        return false;
      });
    }

    /**
     * В API нужно отдавать контент между тегами <body></body> из шаблона
     * @param content текуший контент документа из Tinymce
     */
    clearDocument = (content: string): string => {
      const start = content.search('<body>') !== -1 ? content.search('<body>') + 6 : 0;
      const end = content.search('</body>') !== -1 ? content.search('</body>') : content.length;

      return content.substring(start, end);
    }

    async getTemplateFormList(page: number) {
      await TemplateFormProvider.getTemplateFormList(page)
        .then((res) => {
          this.getFormList = res.data;
          this.formPageCount = res.pageCount;
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка: пользовательские поля не загружены', item: '' });
        });
    }

    @Watch('document')
    view(document: object) {
      this.modelDocument = document as DocumentTemplate;
      this.handleBreadCrumb(document);
    }

    @Watch('documentList')
    documents(documents: object[]) {
      this.methodViewDocumentStickersAndTags(documents);
    }

    @Watch('editDocument')
    changeDocument() {
      this.redirectAfterChangeDocument();
    }

    @Watch('delDocument')
    delete() {
      this.loader_btn_remove = false;
      this.$router.push({ name: 'papers' });
    }

    @Watch('newDocument')
    add() {
      this.redirectAfterChangeDocument();
    }

    @Watch('getFormList')
    loadFormList(val) {
      this.templatePageList += 1;
      if (this.templatePageList <= Number(this.formPageCount)) {
        this.getTemplateFormList(this.templatePageList);
      }

      val.forEach((item) => {
        if (!item.system) {
          this.markersCustomFields.push({ id: item.id, label: item.label, value: `%%${item.name}%%` });
        }
      });
    }
}

/**
 * Интерфейс представляет объект шаблона документа.
 * Помогает с типизацией полей.
 */
interface DocumentTemplate {
    id: number | null;
    content: string;
    deleted: boolean;
    type: number | null;
    title: string;
    is_draft: boolean;
}
