import { ActionTree } from 'vuex';
import CompanyProvider from '../../api/providers/company';
import { SET_CURRENCIES, StateCurrency } from './types';

const actions: ActionTree<StateCurrency, object> = {

  getCurrencies({ commit }) {
    CompanyProvider.getCurrencies()
      .then((res) => {
        commit(SET_CURRENCIES, res.data);
      })
      .catch((err) => console.error(err));
  },
};

export default actions;
