import { ActionTree } from 'vuex';
import PostingProvider from '../../api/providers/posting';
import { StatePosting } from './types';

const actions: ActionTree<StatePosting, object> = {

  getPostingList({ commit }, page) {
    PostingProvider.getPostingList(page)
      .then((res) => commit('GET_POSTING', {
        item: res.data,
        pageCount: res.pageCount,
      }))
      .catch((err) => console.error(err));
  },

  getPostingView({ commit }, id) {
    PostingProvider.getPostingView(id)
      .then((res) => commit('GET_POSTING_VIEW', { item: res.data }))
      .catch((err) => console.error(err));
  },

  sendPosting({ commit }, item) {
    commit('BTN_LOADER', true);
    PostingProvider.sendPosting(item)
      .then((res) => {
        commit('SEND_POSTING_ADD', { item: res.data });
        commit('POSTING_VALIDATION', {});
        commit('PUSH_CALL_SUCCESS', { title: 'Принятие поступления прошло успешно', item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('POSTING_VALIDATION', err.response.data.parts);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  sendPostingPart({ commit }, item) {
    PostingProvider.sendPostingPart(item)
      .then((res) => {
        commit('SEND_POSTING_ITEM', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Товар добавлен в поступление', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  changePostingPart({ commit }, item) {
    PostingProvider.changePostingPart(item)
      .then(() => {
        commit('CHANGE_POSTING_PART', item);
        commit('PUSH_CALL_SUCCESS', { title: 'Товар в поступлении обновлён', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  clearPostingItem({ commit }) {
    commit('CLEAR_POSTING_ITEM');
  },

  sendPostingItem({ commit }, item) {
    commit('SEND_POSTING_ITEM', { item });
    commit('PUSH_CALL_SUCCESS', { title: 'Товар добавлен в поступление' });
  },

  deletePostingItem({ commit }, item) {
    commit('DELETE_POSTING_ITEM', item);
    commit('PUSH_CALL_SUCCESS', { title: 'Товар убран из поступления' });
  },

  revertPostingPart({ commit }, id) {
    PostingProvider.revertPostingPart(id)
      .then((res) => {
        commit('REVERT_POSTING_ITEM', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Товар убран из поступления', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  revertPosting({ commit }, id) {
    commit('BTN_LOADER', true);
    PostingProvider.revertPosting(id)
      .then((res) => {
        commit('REVERT_POSTING', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Поступление удалено', item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  deletePostingPartItem({ commit }, item) {
    commit('DELETE_POSTING_ITEM', item);
  },

  deletePostingModalPartItem({ commit }, item) {
    commit('DELETE_POSTING_PART_ITEM', item);
  },
};

export default actions;
