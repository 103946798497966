import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Breadcrumb, ActionBtn } from '@/types';
import StorageTransferFilterForm from '@/components/storage-transfer-filter-form';
import TransferProvider from '../../api/providers/transfer';
import ViewDate from '../../components/forms/date';
import Numbers from '../../components/forms/numbers';
import ViewUserName from '../../components/forms/user-name';
import TransferView from '../../components/transfer-view';

@Component({
  template: require('./index.html'),
  components: {
    'transfer-view': TransferView,
    numbers: Numbers,
    'user-name': ViewUserName,
    date: ViewDate,
    StorageTransferFilterForm,
  },
})
export default class TransferPage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    dialog_detail = false;

    loading = true;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Перемещения между складами', link: null }];

    filterForm = null;

    actionBtn: ActionBtn[] = [{
      id: 1, type: 'add', title: 'Новое перемещение', link: '/storage/transfer/add', icon: 'fa-plus',
    }];

    transfer_list: object[] = [];

    page_count_transfer_list: number | null = null;

    // api

    getTransfer(page) {
      TransferProvider.getTransfer(page, this.filterForm)
        .then((res) => {
          this.transfer_list = res.data;
          this.page_count_transfer_list = Number(res.pageCount);

          this.loading = false;
        }).catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }

    mounted() {
      document.title = 'Перемещения между складами';
      this.getTransfer(1);
      this.addBreadcrumb(this.breadcrumb);
      this.addActionBtn(this.actionBtn);
    }

    destroyed() {
      this.addActionBtn([]);
    }

    date = (value) => {
      const options: object = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
      };
      const date: string = new Date(value * 1000).toLocaleString('ru-RU', options).replace(',', '');
      return date;
    }

    loadPage(val) {
      this.loading = true;
      this.getTransfer(val);
      window.scroll(0, 0);
    }

    changeFilter(filterForm) {
      this.filterForm = { ...filterForm };
      this.getTransfer(1);
    }

    itemId = 1;

    itemDate = '';

    onDialogDetail(row) {
      if (window.getSelection()?.toString().trim().length !== 0) return;
      this.itemId = row.id;
      this.itemDate = this.date(row.created_at);
      this.dialog_detail = true;
    }
}
