import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { PriceLevel } from '@/types';
import { PricesLevelRoundType } from '@/classes';
import PriceLevelProvider from '@/api/providers/price-level';

interface PriceLevelErrors {
  posting_price_start?: string[];
  repair_price_gain?: string[];
  sell_price_gain?: string[];
  price_round_type?: string[];
  price_round_level?: string[];
}
@Component({
  template: require('./index.html'),

})
export default class PricesLevelForm extends Vue {
  newPriceLevel: PriceLevel = {
    posting_price_start: 0,
    repair_price_gain: 0,
    sell_price_gain: 0,
    price_round_type: 0,
    price_round_level: 1,
  }

  levelRoundType: PricesLevelRoundType = new PricesLevelRoundType();

  errors: PriceLevelErrors = {}

  getError(key) {
    switch (key) {
      case 'posting_price_start': {
        return this.errors.posting_price_start ? this.errors.posting_price_start[0] : '';
      }
      case 'repair_price_gain': {
        return this.errors.repair_price_gain ? this.errors.repair_price_gain[0] : '';
      }
      case 'sell_price_gain': {
        return this.errors.sell_price_gain ? this.errors.sell_price_gain[0] : '';
      }
      case 'price_round_type': {
        return this.errors.price_round_type ? this.errors.price_round_type[0] : '';
      }
      case 'price_round_level': {
        return this.errors.price_round_level ? this.errors.price_round_level[0] : '';
      }
      default: {
        return '';
      }
    }
  }

  createNewPriceLevel() {
    PriceLevelProvider.addPriceLevel(this.newPriceLevel)
      .then(() => {
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Уровень наценки успешно добавлен' });
        this.$emit('newPriceLevelAdded');
      })
      .catch((err) => {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось добавить уровень наценки' });
        this.errors = err.response.data;
        throw new Error(err);
      });
  }
}
