import Vue from 'vue';
import Component from 'vue-class-component';

const IntegrationItemProps = Vue.extend({
  props: {
    title: String,
    description: String,
    logoUrl: String,
    link: String,
    enabled: Boolean,
  },
});

@Component({
  template: require('./index.html'),
  components: {},
})
export default class IntegrationItem extends IntegrationItemProps {

}
