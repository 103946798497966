import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { User, TaskInterface } from '@/types';
import TaskStatusSelector from '@/components/task-extra/task-status-selector';
import TaskProvider from '@/api/providers/task';
import StuffProvider from '../../api/providers/stuff';
import TaskStatusProvider from '../../api/providers/task-status';
import ViewDate from '../../components/forms/date';
import TaskPriorityComponent from '../../components/task-extra/priority';
import TaskStatusBage from '../../components/task-extra/task-status-bage';
import TaskSearchForm from '../../components/task-search-form/task-search-form';
import { TaskStatus } from '../../store/task-status/types';
import { TaskSearchApiForm } from '../../store/task/types';

@Component({
  template: require('./index.html'),
  components: {
    'task-priority': TaskPriorityComponent,
    'view-date': ViewDate,
    'task-search-form': TaskSearchForm,
    'task-status-badge': TaskStatusBage,
    TaskStatusSelector,
  },
})
export default class TaskPage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Getter btnloader;

    @Action getTaskList;

    @Action deleteTask;

    @Getter taskList;

    @Getter user;

    form = new TaskSearchApiForm();

    taskStatuses: TaskStatus[] = [];

    loading = true;

    stuff: User[] = [];

    async mounted() {
      this.addActionBtn([{
        id: 1, type: 'add', title: 'Новая задача', link: '/task/add', icon: 'fa-plus',
      }]);
      this.addBreadcrumb([
        { id: 1, section: 'Задачи', link: null },
        { id: 2, section: 'Список задач', link: null },
      ]);
      document.title = 'Список задач';
      await this.loadAllStuff();
      await this.loadAllStatuses();
      await this.getTaskList(this.form);
      this.loading = false;
    }

    destroyed() {
      this.addActionBtn([]);
    }

    handleSearchFormSubmit(form: TaskSearchApiForm) {
      const formCopy = { ...form };
      Object.keys(form).forEach((key) => {
        if (form[key] === null) { delete formCopy[key]; }
      });

      this.getTaskList(formCopy);
    }

    async loadAllStatuses() {
      const result = await TaskStatusProvider.getAllStatuses();
      this.taskStatuses = result;
    }

    async loadAllStuff() {
      const result = await StuffProvider.getFullStuffList();
      this.stuff.push(...result);
    }

    userName(userId) {
      const user = this.stuff.filter((x) => x.id === userId);
      if (user.length > 0) {
        return `${user[0].first_name} ${user[0].middle_name} ${user[0].last_name}`;
      }
      return '...';
    }

    handleEditTask(id: number) {
      this.$router.push(`/task/edit/${id}`);
    }

    handleDeleteTask(id: number) {
      const task = this.taskList.filter((x) => x.id === id);
      if (task.length === 1) {
        this.$confirm(`Вы уверены что хотите удалить задачу ${task[0].title}`, 'Подтвердите удаление', {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(() => {
          this.deleteTask(task[0].id);
        }).catch((err) => {
          console.error(err);
        });
      }
    }

    /**
     * Меняет статус у задачи, а зетем загружает новые данные
     * @param  {TaskStatus} newStatus
     * @param  {TaskInterface} task
     */
    changeStatus = (newStatus: TaskStatus, task: TaskInterface) => {
      const newTask = { ...task };
      newTask.status_id = newStatus.id as number;
      if (task.deadline) {
        newTask.deadline = Math.round(task.deadline / 1000);
      }
      TaskProvider.changeTask(task.id, newTask)
        .then(async () => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Статус задачи изменен' });
          await this.getTaskList(this.form);
        })
        .catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось изменить статсу' });
          throw new Error(err);
        });
    }

    rowClickHandler(row) {
      this.$router.push(`/task/view/${row.id}`);
    }
}
