import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { RepairForm, FormLayout } from '@/types';

import ClientsProvider from '../../../../api/providers/clients';
import RepairProvider from '../../../../api/providers/repair';
// components
import ErrorAccessRights from '../../../error-access-rights';
// api
import requestOnTemplateForm from '../api/requestOnTemplateForm';
import RepairFormTemplate from '../components/template';
// interface
import { RepairClient } from '../interface';
import addCartridges from '../methods/addCartridges';
import clearValidateRepair from '../methods/clearValidateRepair';
import { formatttingCustomAttrbiutesValueInString, formatttingFieldPlannedOutDate, formatttingFieldPlannedPrice } from '../methods/formattingFieldsFormRepair';
import modelSelection from '../methods/modelSelection';
import removeCartridges from '../methods/removeCartridges';
import removeCustomFieldItem from '../methods/removeCustomFieldItem';
import selectionAmountForPrepayment from '../methods/selectionAmountForPrepayment';
// methods
import typeSelection from '../methods/typeSelection';
import updateClient from '../methods/updateClient';
import valueCustomFields from '../methods/valueCustomFields';
import vendorSelection from '../methods/vendorSelection';

@Component({
  template: require('./index.html'),
  components: {
    'repair-form-template': RepairFormTemplate,
    'error-access-rights': ErrorAccessRights,
  },
})
class RepairFormEdit extends Vue {
    @Action addBreadcrumb;

    @Getter user;

    repair_client: RepairClient = {
      name: '',
      contact_phone: null,
      contact_name: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      phone: null,
      status: null,
      address: null,
      repair_discount_type: 0,
      repair_discount: 0,
      id: null,
    };

    data_repair: RepairForm = {
      bug: '',
      state: '',
      equipment: '',
      acceptor_notes: '',
      objects: [{
        amount: 1, model: '', type: null, vendor: null, device_id: null,
      }],
      client_id: null,
      custom_attributes: [],
      prepayment: { cashbox_id: null, sum: null },
      serial: null,
      imei: null,
      warranty: false,
      master_id: null,
      outbound: false,
      planned_out_date: null,
      send_master_notification: false,
      maintenance: false,
      warrant_size: null,
      planned_price: null,
      express: false,
    };

    clone_repair: object = {};

    template_form: FormLayout = { layout: [] };

    loading_form = false;

    client_check_black_list: object[] = [];

    error_repair_form: object = {};

    // api
    sendClientCheckBlacklist(item) {
      ClientsProvider.sendClientCheckBlacklist(item)
        .then((res) => { this.client_check_black_list = res.data; });
    }

    get rightsUserCreateUpdateRepair() {
      return this.user.rights ? this.user.rights.can_create_update_repair : false;
    }

    mounted() {
      const { name, params } = this.$route;
      this.data_repair.type = (name as string).search('repair') !== -1 ? 1 : 2;

      requestOnTemplateForm.call(this, name, 'edit', Number(params.id));
      typeSelection.call(this);
      vendorSelection.call(this);
      modelSelection.call(this);
      addCartridges.call(this);
      removeCartridges.call(this);
      selectionAmountForPrepayment.call(this);
      valueCustomFields.call(this);
      removeCustomFieldItem.call(this);
      clearValidateRepair.call(this);
      this.handlerUpdateRepair();
    }

    destroyed() {
      this.$root.$off('requestOnTemplateForm');
      this.$root.$off('typeSelection');
      this.$root.$off('vendorSelection');
      this.$root.$off('modelSelection');
      this.$root.$off('addCartridges');
      this.$root.$off('removeCartridges');
      this.$root.$off('selectionAmountForPrepayment');
      this.$root.$off('valueCustomFields');
      this.$root.$off('removeCustomFieldItem');
      this.$root.$off('clearValidateRepair');
      this.$root.$off('handlerUpdateRepair');
    }

    updatingClient(client) {
      updateClient.call(this, client);
    }

    handlerUpdateRepair() {
      this.$root.$on('handlerUpdateRepair', () => {
        const { planned_out_date } = this.data_repair;

        formatttingFieldPlannedOutDate.call(this);
        formatttingFieldPlannedPrice.call(this);
        formatttingCustomAttrbiutesValueInString.call(this);

        this.data_repair.id = Number(this.$route.params.id);

        this.$store.commit('BTN_LOADER', true);
        RepairProvider.sendRepairUpdate(this.data_repair)
          .then((res) => {
            this.$router.push({ name: 'repair-view', params: { id: res.data.id } });

            this.$store.commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} изменён !`, item: '' });
            this.$store.commit('BTN_LOADER', false);
            this.error_repair_form = {};
          })
          .catch((err) => {
            this.data_repair.planned_out_date = planned_out_date;
            const response = err.response.data;
            this.pushingError(response);
            this.error_repair_form = err.response.data;
            this.$store.commit('BTN_LOADER', false);
          });
      });
    }

    pushingError(errors: object) {
      Object.keys(errors).forEach((item) => {
        if (item === 'missed_custom_attributes') {
          return;
        }
        if (item === 'objects') {
          this.pushingError(errors[item][0][0]);
          return;
        }
        let stringOfErrors = '';
        errors[item].forEach((error) => {
          stringOfErrors += `${error}`;
        });
        // детект системных полей. Для них не надо показывать название поля.
        if (RegExp('[a-z]+').test(item)) {
          setTimeout(() => {
            this.$store.commit('PUSH_CALL_ERROR', { title: `${stringOfErrors}`, item: '' });
          }, 10);
        } else {
          setTimeout(() => {
            this.$store.commit('PUSH_CALL_ERROR', { title: `${item}: ${stringOfErrors}`, item: '' });
          }, 10);
        }
      });
    }

    addBreadcrumbOnPageEditRepair(id, title) {
      this.addBreadcrumb([
        { id: 1, section: 'Ремонт', link: '/repair' },
        { id: 2, section: title, link: `/repair/view/${id}` },
        { id: 3, section: 'Редактирование', link: null },
      ]);
    }
}

export default RepairFormEdit;
