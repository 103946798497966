import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ViewDate from '../forms/date';
import SearchTransfersRepair from './__search';

interface Content {
  page: number;
  'per-page': number;
}
@Component({
  template: require('./transfers.html'),
  components: {
    date: ViewDate,
    'search-transfer': SearchTransfersRepair,
  },
})

export default class RepairTransfers extends Vue {
    @Action addBreadcrumb;

    @Action getRepairTransferList;

    @Action sendRepairTransferStart;

    @Action sendRepairTransferComplete;

    @Action sendRepairTransferCancel;

    @Action addActionBtn;

    @Getter repairTransfer;

    @Getter pageCountRepairTransfer;

    @Getter btnloader;

    content: Content = {
      page: 1,
      'per-page': 20,
    };

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Ремонт', link: '/repair' },
        { id: 2, section: 'Перемещения заказов', link: null },
      ]);
      this.getRepairTransferList(this.content);
    }

    destroyed() {
      this.addActionBtn([]);
    }

    formationUsersName = (row) => {
      const user_name = [row.last_name, row.first_name, row.middle_name]
        .filter((user) => !!user);
      if (user_name.length === 0) {
        return '-';
      }
      return user_name.length === 3 ? `${user_name[0]} ${user_name[1][0]}. ${user_name[2][0]}.` : user_name.join(' ');
    }

    formationStatusTransfer = (status_id) => {
      switch (status_id) {
        case 1: return { status: 'Создано', color: '#E6A23C' };
        case 2: return { status: 'Начато', color: '#1861da' };
        case 3: return { status: 'Завершено', color: '#3ba543' };
        case 4: return { status: 'Отменено', color: '#F56C6C' };
        default: return null;
      }
    }

    loadPage(val) {
      this.content.page = val;
      this.getRepairTransferList(this.content);
    }

    changeStatusInTransfer(status) {
      let title: string;
      switch (status.status_id) {
        case 1: title = 'начать перемещение'; break;
        case 2: title = 'подтвердить получение'; break;
        default: title = 'отменить перемещение';
      }
      this.$confirm(`Вы уверены что хотите ${title} заказа?`, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => {
        switch (status.status_id) {
          case 1: this.sendRepairTransferStart({ transfer_id: status.id }); break;
          case 2: this.sendRepairTransferComplete({ transfer_id: status.id }); break;
          default: this.sendRepairTransferCancel({ transfer_id: status.id }); break;
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Отмена',
        });
      });
    }
}
