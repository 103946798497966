export const SET_INCOMING = 'SET_INCOMING';
export const DISMISS_INCOMING_FROM_NUMBER = 'DISMISS_INCOMING_FROM_NUMBER';

// Store

export interface StateVOIP {
    incomingCall: Call[];
}

// Models

export interface Call {
    internalNumber: number;
    callerNumber: number;
}
