import Vue from 'vue';
import { Form } from 'element-ui';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb } from '@/types';
import WorkProvider from '../../../api/providers/work';
import WorkForm from '../form';

interface NewItem {
  cat_id: null | number;
  vat: number;
  sell_reward_type: number;
  repair_reward_type: number;
  warrant_type: number;
  repair_reward_size?;
  sell_reward_size?;
  enabled?: boolean;
}

@Component({
  template: require('./work.html'),
  components: { 'work-form': WorkForm },
})

export default class WorkView extends Vue {
    @Action addBreadcrumb;

    @Getter user;

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Склад', link: '/part' },
      { id: 2, section: 'Работы и услуги', link: '/work' },
      { id: 3, section: 'Новая работа', link: null }];

    new_item: NewItem = {
      cat_id: null,
      vat: 1,
      sell_reward_type: 1,
      repair_reward_type: 1,
      warrant_type: 1,
    };

    validation: object = {};

    loading_page = false;

    loading_btn = false;

    get id() {
      return this.$route.params.id;
    }

    get isWorkDeleted(): boolean {
      return this.new_item?.enabled === false;
    }

    // api
    getViewWork(id) {
      WorkProvider.getViewWork(id)
        .then((res) => {
          const work = res.data;

          this.viewBreadcrumb(work.name);
          this.new_item = work;
          this.loading_page = false;
          this.formattingDeleteFields();
        }).catch((err) => {
          this.loading_page = false;
          console.error(err);
        });
    }

    formattingDeleteFields() {
      if (this.new_item.repair_reward_size === null) {
        this.new_item.repair_reward_size = undefined;
      }

      if (this.new_item.sell_reward_size === null) {
        this.new_item.sell_reward_size = undefined;
      }
    }

    formattingEmptyFields() {
      if (this.new_item.repair_reward_size === undefined) {
        delete this.new_item.repair_reward_size;
        this.new_item.repair_reward_size = null;
      }

      if (this.new_item.sell_reward_size === undefined) {
        delete this.new_item.sell_reward_size;
        this.new_item.sell_reward_size = null;
      }
    }

    sendWork(item) {
      this.formattingEmptyFields();
      this.loading_btn = true;
      WorkProvider.sendWork(item)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `${item.name} успешно добавлен`, item: '' });
          this.loading_btn = false;
          this.$router.back();
        }).catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.validation = err.response.data;
          this.loading_btn = false;
        });
    }

    changeWork(item) {
      this.formattingEmptyFields();
      this.loading_btn = true;
      WorkProvider.changeWork(item)
        .then((res) => {
          this.$router.back();
          this.loading_btn = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `Работа ${res.data.name} успешно сохранена`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.loading_btn = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    get rightsUserCanCreateWorksAndCategories() {
      return this.user.rights ? this.user.rights.can_create_works_and_categories : false;
    }

    get rightsUserCanEditDeleteWorksAndCategories() {
      return this.user.rights ? this.user.rights.can_edit_delete_works_and_categories : false;
    }

    mounted() {
      if (!this.rightsUserCanCreateWorksAndCategories && !this.id) {
        this.$router.push({ name: 'work' });
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Вам не разрешено создавать работы и услуги', item: '' });
      }

      if (!this.rightsUserCanEditDeleteWorksAndCategories && this.id) {
        this.$router.push({ name: 'work' });
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Вам не разрешено изменять и удалять работы и услуги', item: '' });
      }
      document.title = 'Работа';
      if (this.id === undefined) {
        const { query } = this.$route;
        this.viewBreadcrumb();
        if ('category_id' in query) this.new_item.cat_id = Number(query.category_id);
      } else {
        this.loading_page = true;
        this.getViewWork(this.id);
      }
    }

    viewBreadcrumb(name = '') {
      this.addBreadcrumb([
        { id: 1, section: 'Склад', link: '/part' },
        { id: 2, section: 'Работы и услуги', link: '/work' },
        { id: 3, section: name === '' ? 'Новая работа' : name, link: null },
      ]);
    }

    addWork() {
      ((this.$refs.form as WorkForm)
        .viewForm as Form)
        .validate((valid) => {
          if (valid) {
            this.sendWork(this.new_item);
            return true;
          }
          return false;
        });
    }

    sendUpdateWork() {
      ((this.$refs.form as WorkForm)
        .viewForm as Form)
        .validate((valid) => (valid ? this.changeWork(this.new_item) : false));
    }
}
