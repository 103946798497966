import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Document } from '@/types';
import BaseProvider from '@/api/base-provider';
import ViewDate from '../forms/date';
import Numbers from '../forms/numbers';
import ViewUserName from '../forms/user-name';

@Component({
  template: require('./index.html'),
  components: {
    'user-name': ViewUserName,
    date: ViewDate,
    numbers: Numbers,
  },
})
export default class CashViewContent extends Vue {
    @Action deletedCashOperation;

    @Getter cashOperationPageCount;

    @Getter user;

    @Prop({ default: () => [] }) data!: Document[];

    @Prop({ default: () => false }) loading!: boolean;

    @Prop({ default: () => false }) showCashboxInfo!: boolean;

    token: string | null = null;

    mounted() {
      const baseProvider = new BaseProvider();
      baseProvider.checkExpiresIn()
        .then(() => {
          this.token = baseProvider.access;
        })
        .catch((err) => console.error(err));
    }

    get rightsUserCanDeleteCashOperation() {
      return this.user.rights ? this.user.rights.can_delete_cash_operation : false;
    }

    getItemDescription = (item) => (item.descriptions !== null ? item.descriptions : '&mdash;')

    removeCashOperation(row) {
      this.deletedCashOperation(row.id);
    }

    getLinkParams = (row) => {
      let type: string;
      if (row.act_type_id === 3 || row.act_type_id === 5 || row.act_type_id === 13) {
        type = 'ремонт';
      } else if (row.act_type_id === 4 || row.act_type_id === 9) {
        type = 'продажа';
      } else if (row.act_type_id === 10) {
        type = 'поступление';
      } else if (row.act_type_id === 6) {
        type = 'клиент';
      } else {
        type = 'выплаты';
      }

      switch (type) {
        case 'ремонт': return { name: 'repair-view', params: { id: row.act_id } };
        case 'продажа': return { name: 'sell-view', params: { id: row.act_id } };
        case 'поступление': return { name: 'view-posting', params: { id: row.act_id } };
        case 'клиент': return { name: 'clients-view', params: { id: row.act_id } };
        case 'выплаты': return `/salary/transfer/${row.salary_user_id}/${row.act_id}`;
        default: return '';
      }
    }

    printDocument(value) {
      const printOperation: Document | undefined = this.data.find((print) => print.id === value);
      if (printOperation) {
        const id = Number(this.$route.params.id);
        const domain = process.env.NODE_ENV === 'production' ? 'printing.servix.io' : 'printing-dev.servix.io';
        if (printOperation?.type === undefined) {
          window.open(`https://${domain}/print/cashoperation?token=${this.token}&operationId=${value}`);
        } else {
          window.open(`https://${domain}/print/document?token=${this.token}&documentId=${value}&entityId=${id}`);
        }
      } else {
        throw new Error('printOperation is undefined');
      }
    }
}
