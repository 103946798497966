import { Form } from 'element-ui';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PartProvider from '@/api/providers/part';
import InfoAboutPartOrWork from '@/components/info-about-part-or-work';
import { Cancel, Breadcrumb } from '@/types';
import CancelProvider, { CancelComment, CancelCommentAddForm } from '../../api/providers/cancel';
import ViewDate from '../forms/date';
import Numbers from '../forms/numbers';
import ViewUserName from '../forms/user-name';

@Component({
  template: require('./view.html'),
  components: {
    numbers: Numbers,
    date: ViewDate,
    'user-name': ViewUserName,
    InfoAboutPartOrWork,
  },
})
export class CancelView extends Vue {
    @Getter user;

    @Action addBreadcrumb;

    @Action getVatList;

    @Action getRewardTypeList;

    @Action getWarrantTypeList;

    @Action getPartCategory;

    showModalViewAboutWorkOrPart = false;

    btnloader = false;

    dataForModalView = {};

    titleInfoAboutPartOrWork = '';

    loading = true;

    dialog = false;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Списания', link: '/cancel' }, { id: 3, section: 'Списание №', link: null }];

    cancel: object = {};

    cancel_item_list: Cancel[] = [];

    loading_revert_cancel = false;

    cancel_comments: CancelComment[] = [];

    showDeletedComments = false;

    cancel_comment_form: CancelCommentForm = { content: '' };

    cancel_comment_form_rules = {
      content: [
        {
          required: true, message: 'Обязательно для заполнения', whitespace: true, trigger: 'change',
        },
      ],
    };

    get rightsUserCanRemoveCancel() {
      return this.user.rights ? this.user.rights.can_remove_cancel : false;
    }
    // api

    viewingPart(row) {
      try {
        this.btnloader = true;
        PartProvider.getViewPart(row.part.id)
          .then((res) => {
            this.titleInfoAboutPartOrWork = 'Информация о товаре';
            this.dataForModalView = res.data;
            this.btnloader = false;
            this.getVatList();
            this.getRewardTypeList();
            this.getWarrantTypeList();
            this.getPartCategory('part');

            this.showModalViewAboutWorkOrPart = true;
          })
          .catch((error) => {
            this.btnloader = false;
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о товаре', item: '' });
            throw new Error(error);
          });
      } catch (err) {
        this.btnloader = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        throw new Error(err);
      }
    }

    closeInfoAboutPartOrWork() {
      this.showModalViewAboutWorkOrPart = false;
    }

    getCancelView(id) {
      CancelProvider.getViewCancel(id)
        .then((res) => {
          this.cancel = res.data;
          if (res.data.parts) this.cancel_item_list = res.data.parts;
          this.loading = false;
          document.title = `Списание №${id}`;
        }).catch((err) => {
          this.loading = false;
          console.error(err);
        });
    }

    sendRevertPart(item) {
      CancelProvider.sendRevertPart(item)
        .then((res) => {
          const index = this.cancel_item_list.findIndex((i) => i.id === res.data.id);
          this.cancel_item_list.splice(index, 1);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Возврат товара прошел успешно', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    sendRevert(item) {
      this.loading_revert_cancel = true;
      CancelProvider.sendRevert(item)
        .then(() => {
          this.$router.push({ name: 'cancel' });
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Списание отменено', item: '' });
          this.loading_revert_cancel = false;
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading_revert_cancel = false;
        });
    }

    async getAllComments(page = 1) {
      const pack = await CancelProvider.getCancelComments(
        { cancel_id: this.id, include_deleted: true },
      );
      let result = pack.data;
      if (page < pack.pageCount) {
        const nextPack = await this.getAllComments(page + 1);
        result = result.concat(nextPack);
      }
      return result;
    }

    async sendCancelComment() {
      const form = {
        cancel_id: this.id,
        content: this.cancel_comment_form.content,
      } as CancelCommentAddForm;
      try {
        const result = await CancelProvider.sendCancelComment(form);
        this.cancel_comments = [(result.data as CancelComment), ...this.cancel_comments];
        this.cancel_comment_form.content = '';
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Комментарий успешно сохранен', item: '' });
      } catch (e) {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка сохранения комментария', item: '' });
      }
    }

    // end api

    get id() {
      return Number(this.$route.params.id);
    }

    get cancelCommentsFiltered() {
      return this.showDeletedComments
        ? this.cancel_comments
        : this.cancel_comments.filter((x) => !x.is_deleted);
    }

    /**
    * Может удалять чужие комментарии
    * @returns boolean
    */
    get canDeleteForeignComments(): boolean {
      return this.user?.rights?.can_delete_foreign_comments
        ? this.user?.rights?.can_delete_foreign_comments : false;
    }

    /**
    * Может удалять свои комментарии
    * @returns boolean
    */
    get canDeleteSelfComments(): boolean {
      return this.user?.rights?.can_delete_self_comments
        ? this.user?.rights?.can_delete_self_comments : false;
    }

    mounted() {
      this.getVatList();
      this.getRewardTypeList();
      this.getWarrantTypeList();
      this.getPartCategory('part');
      document.title = 'Списание';
      this.breadcrumb[2].section += this.$route.params.id;
      this.getCancelView(this.$route.params.id);
      this.addBreadcrumb(this.breadcrumb);
      this.getAllComments()
        .then((resp) => {
          this.cancel_comments = resp;
        })
        .catch((err) => console.error(err));
    }

    date = (value) => {
      const options: object = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
      };
      const date: string = new Date(value * 1000).toLocaleString('ru-RU', options).replace(',', '');
      return date;
    }

    currentUser = (user) => {
      if (user) {
        const lastName: string = user.last_name ? user.last_name : '';
        const firstName: string = user.first_name ? user.first_name : '';
        const middleName: string = user.middle_name ? user.middle_name : '';
        return `${lastName} ${firstName} ${middleName}`;
      }
      return false;
    }

    changeRevertCancel(part) {
      const cancelForm = { cancel_part_id: part.id, amount: part.amount };
      this.sendRevertPart(cancelForm);
    }

    submitCancelCommentForm() {
      (this.$refs.commentForm as Form).validate((valid) => {
        if (valid) {
          this.sendCancelComment();
          return true;
        }
        console.error('error submit!!');
        return false;
      });
    }

    canDeleteComment(comment: CancelComment) {
      if (comment.is_deleted) {
        return false;
      }
      if (comment.user_id === this.user.id) {
        return this.canDeleteSelfComments;
      }
      if (this.canDeleteForeignComments) {
        return true;
      }
      return false;
    }

    deleteComment(comment: CancelComment) {
      CancelProvider.deleteCancelComment(comment.id)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Комментарий успешно удален' });
          this.cancel_comments.forEach((c, index) => {
            if (c.id === comment.id) this.cancel_comments[index].is_deleted = true;
          });
        })
        .catch((res) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось удалить комментарий' });
          throw new Error(res);
        });
    }
}

export interface CancelCommentForm {
    content: string;
}
