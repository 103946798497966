import { AxiosResponse } from 'axios/index.d';
import RepairLog from '@/types/repair-log';
import BaseProvider from '../base-provider';

class RepairProvider extends BaseProvider {
  async getRepairList(page, item, perPage, expand: string[] = []) {
    const searchForm: string = this.buildUrl({ RepairSearchForm: item });
    const expand_def: string[] = expand.length ? expand : ['client.source', 'repair_objects',
      'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];
    const res = await this.request('GET', `/repair/index?page=${page}&expand=${expand_def}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-current-page'],
    };
  }

  async getViewRepair(id) {
    const expand: string[] = ['acceptor', 'master', 'accepted_point', 'repair_objects', 'client', 'repair_attributes.field',
      'repair_parts.part', 'repair_parts.part.amounts', 'repair_parts.master',
      'repair_comments.user', 'repair_works.work', 'repair_works.master',
      'repair_payments', 'repair_discounts', 'repair_additions', 'current_point',
      'repair_transfers'];

    const res = await this.request('GET', `/repair/view?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairAssignmaster(item) {
    const data: object = { RepairAssignMasterForm: item };
    const res = await this.request('POST', '/repair/assignmaster', data);
    return res;
  }

  async sendRepairAdd(item) {
    const data: object = { RepairForm: item };
    const res = await this.request('POST', '/repair/add', data);
    return res;
  }

  async sendRepairUpdateAccepting(item) {
    const data: object = { RepairUpdateAcceptingForm: item };
    const expand: string[] = ['objects', 'client.source', 'repair_objects',
      'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];

    const res = await this.request('POST', `/repair/updateaccepting?expand=${expand}`, data);
    return res;
  }

  async sendRepairStart(id) {
    const expand: string[] = ['objects', 'client.source', 'repair_objects',
      'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];

    const res = await this.request('POST', `/repair/start?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairUpdate(item) {
    const expand: string[] = ['accepted_point', 'repair_objects', 'client', 'repair_attributes.field',
      'repair_parts.part', 'repair_parts.part.amounts', 'repair_parts.master',
      'repair_comments.user', 'repair_works.work', 'repair_works.master',
      'repair_payments', 'repair_discounts', 'repair_additions', 'current_point',
      'repair_transfers'];
    const data: object = { RepairForm: item };
    const res = await this.request('POST', `/repair/update?id=${item.id}&expand=${expand}`, data);
    return res;
  }

  async sendRepairCustomStatus(item) {
    const expand: string[] = ['objects', 'client.source', 'repair_objects',
      'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];
    const data: object = { RepairCustomStatusForm: item };
    const res = await this.request('POST', `/repair/customstatus?expand=${expand}`, data);
    return res;
  }

  async sendRepairDelete(id) {
    const res = await this.request('POST', `/repair/delete?id=${id}`);
    return res;
  }

  // repaircomment
  /**
   * Загрузка всех комментариев заказа
   * @param  {number} repairId
   * @param  {} include_deleted=false
   */
  async getAllRepairComments(repairId: number, include_deleted = false) {
    let response: Array<ApiRepairComment> = [];
    let page = 1;
    let totalPages = 1;
    const result = await this.getRepairComments(repairId, page, include_deleted);
    response = [...response, ...result.data];
    totalPages = result.pageCount;
    page += 1;
    if (totalPages > 1) {
      const promiseRequests: object[] = [];
      for (page; page <= totalPages; page++) {
        promiseRequests.push(this.getRepairComments(repairId, page, include_deleted));
      }
      await Promise.all(promiseRequests)
        .then((results) => {
          (results as AxiosResponse[]).forEach((repairCommentsPage) => {
            response = [...response, ...repairCommentsPage.data];
          });
        });
    }
    return response;
  }

  async getRepairComments(
    repairId: number, page: number, include_deleted = false,
  ): Promise<ApiRepairCommentResponse> {
    const data: string = this.buildUrl(
      { RepairCommentSearchForm: { repair_id: repairId, include_deleted } },
    );
    const res = await this.request('GET', `/repaircomment/index?page=${page}${data}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getViewRepairComment(id) {
    const res = await this.request('GET', `/repaircomment/view?id=${id}`);
    return res;
  }

  async sendRepairComment(item) {
    const expand: string[] = ['user'];
    const data: object = { RepairComment: item };
    const res = await this.request('POST', `/repaircomment/add?expand=${expand}`, data);
    return res;
  }

  /**
   * Удаление комментария заказа
   * @param  {number} commentId
   */
  async deleteRepairComment(commentId: number) {
    const res = await this.request('POST', `/repaircomment/delete?id=${commentId}`);
    return res;
  }

  // repairpayment
  async getViewRepairPayments(id) {
    const expand: string[] = ['cash_operation'];
    const res = await this.request('GET', `/repairpayment/view?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairPayment(item) {
    const expand: string[] = ['custom_act_type', 'cash_operation'];
    const data: object = { RepairPayment: item };
    const res = await this.request('POST', `/repairpayment/add?expand=${expand}`, data);
    return res;
  }

  async deleteRepairPayments(id) {
    const res = await this.request('POST', `/repairpayment/delete?id=${id}`);
    return res;
  }

  // repairpart

  async sendRepairPart(item) {
    const expand: string[] = ['part', 'part.amounts', 'master'];
    const data: object = { RepairPartAddForm: item };
    const res = await this.request('POST', `/repairpart/add?expand=${expand}`, data);
    return res;
  }

  async sendRepairPartUpdate(item) {
    const expand: string[] = ['part', 'part.amounts', 'master'];
    const data: object = { RepairPartUpdateForm: item };
    const res = await this.request('POST', `/repairpart/update?id=${item.id}&expand=${expand}`, data);
    return res;
  }

  async deleteRepairPart(id) {
    const res = await this.request('POST', `/repairpart/delete?id=${id}`);
    return res;
  }

  // repairwork

  async sendRepairWork(item) {
    const expand: string[] = ['work', 'master'];
    const data: object = { RepairWorkAddForm: item };
    const res = await this.request('POST', `/repairwork/add?expand=${expand}`, data);
    return res;
  }

  async sendRepairWorkUpdate(item) {
    const expand: string[] = ['master', 'work'];
    const data: object = { RepairWorkUpdateForm: item };
    const res = await this.request('POST', `/repairwork/update?id=${item.id}&expand=${expand}`, data);
    return res;
  }

  async deleteRepairWork(id) {
    const res = await this.request('POST', `/repairwork/delete?id=${id}`);
    return res;
  }

  // /repairdiscount

  async getRepairDiscountList(page) {
    const res = await this.request('GET', `/repairdiscount/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendRepairDiscount(item) {
    const data: object = { RepairDiscount: item };
    const res = await this.request('POST', '/repairdiscount/add', data);
    return res;
  }

  async sendRepairDiscountUpdate(item) {
    const data: object = { RepairDiscount: item };
    const res = await this.request('POST', `/repairdiscount/update?id=${item.id}`, data);
    return res;
  }

  async deleteRepairDiscount(id) {
    const res = await this.request('POST', `/repairdiscount/delete?id=${id}`);
    return res;
  }

  // repairaddition

  async sendRepairAddition(item) {
    const data: object = { RepairAddition: item };
    const res = await this.request('POST', '/repairaddition/add', data);
    return res;
  }

  async sendRepairAdditionUpdate(item) {
    const data: object = { RepairAddition: item };
    const res = await this.request('POST', `/repairaddition/update?id=${item.id}`, data);
    return res;
  }

  async deleteRepairAddition(id) {
    const res = await this.request('POST', `/repairaddition/delete?id=${id}`);
    return res;
  }

  // /repairtransfer

  async getRepairTransferList(page, item, perPage) {
    const searchForm: string = this.buildUrl({ RepairTransferSearchForm: item });
    const expand: string[] = ['source_point', 'target_point', 'created_user'];
    const res = await this.request('GET', `/repairtransfer/index?page=${page}&expand=${expand}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-current-page'],
    };
  }

  async sendRepairTransfer(item) {
    const data: object = { RepairTransferForm: item };
    const expand: string[] = ['source_point', 'target_point', 'created_user'];
    const res = await this.request('POST', `/repairtransfer/add?expand=${expand}`, data);
    return res;
  }

  async sendRepairTransferStart(item) {
    const data: object = { RepairTransferStartForm: item };
    const expand: string[] = ['source_point', 'target_point', 'created_user'];
    const res = await this.request('POST', `/repairtransfer/start?expand=${expand}`, data);
    return res;
  }

  async sendRepairTransferComplete(item) {
    const data: object = { RepairTransferCompleteForm: item };
    const expand: string[] = ['source_point', 'target_point', 'created_user'];
    const res = await this.request('POST', `/repairtransfer/complete?expand=${expand}`, data);
    return res;
  }

  async sendRepairTransferCancel(item) {
    const data: object = { RepairTransferCancelForm: item };
    const expand: string[] = ['source_point', 'target_point', 'created_user'];
    const res = await this.request('POST', `/repairtransfer/cancel?expand=${expand}`, data);
    return res;
  }

  // repairstatus

  async sendRepairToAgreement(id) {
    const expand: string[] = ['repair_objects', 'client', 'repair_attributes.field',
      'repair_parts.part', 'repair_comments.user', 'repair_works.work',
      'repair_payments', 'repair_discounts', 'repair_additions', 'repair_transfers',
      'objects', 'client.source', 'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'custom_status'];

    const res = await this.request('POST', `/repair/toagreement?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairApproveAgreement(id) {
    const expand: string[] = ['repair_objects', 'client', 'repair_attributes.field',
      'repair_parts.part', 'repair_comments.user', 'repair_works.work',
      'repair_payments', 'repair_discounts', 'repair_additions', 'repair_transfers',
      'objects', 'client.source', 'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];

    const res = await this.request('POST', `/repair/approveagreement?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairDeclineAgreement(id) {
    const expand: string[] = ['repair_objects', 'client', 'repair_attributes.field',
      'repair_parts.part', 'repair_comments.user', 'repair_works.work',
      'repair_payments', 'repair_discounts', 'repair_additions', 'repair_transfers',
      'objects', 'client.source', 'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];

    const res = await this.request('POST', `/repair/declineagreement?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairDismissJob(id) {
    const res = await this.request('POST', `/repair/dismissjob?id=${id}`);
    return res;
  }

  async sendRepairDone(id: number, notify = 1) {
    const expand: string[] = ['objects', 'client.source', 'repair_objects',
      'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];

    const res = await this.request('POST', `/repair/done?id=${id}&notify=${notify}&expand=${expand}`);
    return res;
  }

  async sendRepairRevision(id) {
    const expand: string[] = ['objects', 'client.source', 'repair_objects',
      'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];

    const res = await this.request('POST', `/repair/revision?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairEject(id) {
    const expand: string[] = ['repair_objects', 'client', 'repair_attributes.field',
      'repair_parts.part', 'repair_comments.user', 'repair_works.work',
      'repair_payments', 'repair_discounts', 'repair_additions', 'repair_transfers',
      'objects', 'client.source', 'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'custom_status'];

    const res = await this.request('POST', `/repair/eject?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairRestart(id) {
    const expand: string[] = ['repair_objects', 'client', 'repair_attributes.field',
      'repair_parts.part', 'repair_comments.user', 'repair_works.work',
      'repair_payments', 'repair_discounts', 'repair_additions', 'repair_transfers',
      'objects', 'client.source', 'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'custom_status'];

    const res = await this.request('POST', `/repair/restart?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairDelay(id) {
    const expand: string[] = ['objects', 'client.source', 'repair_objects',
      'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];

    const res = await this.request('POST', `/repair/delay?id=${id}&expand=${expand}`);
    return res;
  }

  async sendRepairContinue(id) {
    const expand: string[] = ['objects', 'client.source', 'repair_objects',
      'master', 'acceptor', 'ejector', 'accepted_point',
      'current_point', 'repair_payments', 'custom_status'];

    const res = await this.request('POST', `/repair/continue?id=${id}&expand=${expand}`);
    return res;
  }

  // /repairlog

  async getRepairLogList(page, item) {
    const expand: string[] = ['user', 'html_comment'];
    const searchForm: string = this.buildUrl({ RepairLogSearchForm: item });
    const res = await this.request('GET', `/repairlog/index?page=${page}${searchForm}&expand=${expand}`);
    return {
      data: res.data as [RepairLog],
      pageCount: parseInt(res.headers['x-pagination-page-count'], 10),
      pageCurrent: parseInt(res.headers['x-pagination-page-current'], 10),
    };
  }

  // /repairfile

  async getRepairFileList(page, item) {
    const expand: string[] = ['user'];
    const searchForm: string = this.buildUrl({ RepairFileSearchForm: item });
    const res = await this.request('GET', `/repairfile/index?page=${page}${searchForm}&expand=${expand}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-page-current'],
    };
  }

  async sendRepairAddFiles(item) {
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/repairfile/add', item, contentType);
    return res;
  }

  // /repairtablelayout

  async getRepairTableLayout() {
    const res = await this.request('GET', '/repairtablelayout/index');
    return res;
  }

  async sendRepairTableLayout(item) {
    const data: object = { RepairTableLayout: item };
    const res = await this.request('POST', '/repairtablelayout/index', data);
    return res;
  }

  // repairStat

  async getRepairStat() {
    const res = await this.request('GET', '/repair/stat');
    return res;
  }
}

export default new RepairProvider();

export interface ApiRepairComment {
    content: string;
    created_at: number;
    is_deleted: boolean;
    id: number;
    repair_id: number;
    updated_at: number;
    user_id: number;
}
export interface ApiRepairCommentResponse {
    data: Array<ApiRepairComment>;
    pageCount: number;
}
