import Vue from 'vue';
import { Form } from 'element-ui';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  Breadcrumb, PartAmountCellForm, PartForm as PartFormType, Photo,
} from '@/types';
import PartCellAmount from '@/components/part-cell-amount';
import PartProvider from '@/api/providers/part';
import PartForm from '../../components/part-form';

interface PartAmountTableItem {
  storage: Storage;
  amount: number;
  cell: string;
}
interface Image {
  part_id?;
  file?;
}
@Component({
  template: require('./index.html'),
  components: {
    'part-form': PartForm,
    'part-cell-amount': PartCellAmount,
  },
})
export default class PartAddPage extends Vue {
    @Action addBreadcrumb;

    @Action sendPart;

    @Action sendPartImage;

    @Getter btnloader;

    @Getter user;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Добавление запчасти (товара)', link: null }];

    newItem: PartFormType = {
      cat_id: 1,
      vat: 1,
      sell_reward_type: 1,
      repair_reward_type: 1,
      warrant_type: 1,
      barcodes: [],
    };

    validation: object = {};

    pic: Photo = {};

    barcodes: object[] = [];

    get rightsUserCanCreatePartsAndCategories() {
      return this.user.rights ? this.user.rights.can_create_parts_and_categories : false;
    }

    mounted() {
      if (!this.rightsUserCanCreatePartsAndCategories) {
        this.$router.push({ name: 'nomenklatura' });
      }
      const { query } = this.$route;
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Новый товар';

      if ('category_id' in query) this.newItem.cat_id = Number(query.category_id);
    }

    formattingEmptyFields() {
      if (this.newItem.minimum_amount === undefined) {
        delete this.newItem.minimum_amount;
        this.newItem.minimum_amount = null;
      }

      if (this.newItem.sell_reward_size === undefined) {
        delete this.newItem.sell_reward_size;
        this.newItem.sell_reward_size = null;
      }

      if (this.newItem.repair_reward_size === undefined) {
        delete this.newItem.repair_reward_size;
        this.newItem.repair_reward_size = null;
      }
    }

    add() {
      ((this.$refs.form as PartForm).viewForm as Form).validate((valid) => {
        if (valid) {
          this.formattingEmptyFields();

          this.sendPart(this.newItem)
            .then((res) => {
              const part_id = res.data.id;
              (this.$refs.partCells as PartCellAmount).partAmountTableItem.forEach((elem) => {
                const partAmount: PartAmountCellForm = {
                  part_id,
                  storage_id: elem.storage.id,
                  cell: elem.cell,
                };
                PartProvider.setPartAmount(partAmount);
              });
              const image: Image = {};
              image.part_id = part_id;
              image.file = this.pic.file;
              this.sendPartImage(image);
              this.$router.back();
            })
            .catch((err) => {
              this.validation = err.response.data;
            });
          return true;
        }
        return false;
      });
    }
}
