import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ViewDialog } from '../types';

@Component({
  template: require('./dialog.html'),
  components: {},
})

class DialogsCategory extends Vue {
  @Prop() viewDialog!: ViewDialog;

    @Prop() data!: object;

    @Prop() categoriesMainList!: object[];

    @Action clearValidate;

    @Getter categoriesValidate;

    get formCategory() {
      return this.$refs.form;
    }

    get title() {
      if (this.viewDialog.type === 'add') {
        return 'Добавление категории';
      } if (this.viewDialog.type === 'edit') {
        return 'Изменение категории';
      }
      return 'Удаление категории';
    }

    validate = (obj: object, key: string) => {
      if (Object.keys(obj).some((v) => v === key)) {
        return obj[key];
      }
      return null;
    }

    resetFormCategory() {
      this.$emit('closeDialogChangeCategory');
      if (this.$refs.form !== undefined) {
        this.clearValidate();
        (this.$refs.form as Form).resetFields();
      }
    }
}

export { DialogsCategory as default };
