import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { User } from '../../store/stuff/types';
import TaskCommentForm from './task-comment-form';
import TaskCommentItem from './task-comment-item';
import { TaskComment } from './types';

@Component({
  template: require('./task-comment-container.html'),
  components: {
    'task-comment-item': TaskCommentItem,
    'task-comment-form': TaskCommentForm,
  },
})
export default class TaskCommentContainer extends Vue {
    @Prop() loading!: boolean;

    @Prop() taskId!: number;

    @Prop() users!: User[];

    @Prop() comments!: TaskComment[];

    @Prop() showDeleted!: boolean;

    @Prop() showDeletedChanged!: Function;

    /**
     * Удаление комментария
     * @returns Function
     */
    @Prop() deleteComment!: Function;

    onNewCommentSaved(comment: TaskComment) {
      this.comments.unshift(comment);
    }

    onCurrentPageChanged(page: number) {
      this.$emit('pageChanged', page);
    }

    /**
     *
     * @param id
     * @return User|null
     */
    getUserById(id: number) {
      const result = this.users.filter((x) => x.id === id);
      if (result.length > 0) {
        return result[0];
      }
      return null;
    }
}
