import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb } from '@/types';
import StuffForm from '../../components/stuff-form';

@Component({
  template: require('./index.html'),
  components: {
    'stuff-form': StuffForm,
  },
})
export default class StuffEditPage extends Vue {
    @Action addBreadcrumb;

    @Action getViewStuff;

    @Getter viewStuff;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Компания', link: '/settings' }, { id: 2, section: 'Сотрудники', link: '/stuff' }, { id: 3, section: 'Изменение', link: null }];

    loading = true;

    mounted() {
      this.getViewStuff(this.$route.params.id);
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Изменение сотрудника';
    }

    get view() {
      return this.viewStuff;
    }

    @Watch('viewStuff')
    onLoad() {
      this.$nextTick(() => {
        this.loading = false;
        document.title = `Изменение сотрудника ${this.viewStuff.last_name} ${this.viewStuff.first_name} ${this.viewStuff.middle_name}`;
      });
    }
}
