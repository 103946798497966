import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Part } from '@/types';
import PartProvider from '../../api/providers/part';
import PostingProvider from '../../api/providers/posting';
import RepairProvider from '../../api/providers/repair';
import SellProvider from '../../api/providers/sell';
import WorkProvider from '../../api/providers/work';

@Component({
  template: require('./index.html'),
  components: {

  },
})
export default class ExpressAddProducts extends Vue {
    @Prop({ required: true, default: () => false }) viewModal!: boolean;

    @Prop({ required: true }) form!: ExpressForm;

    @Prop() typeForm!: string;

    @Prop({ default: () => [] }) storageList!: object[];

    @Prop({ default: () => [] }) stuffList!: object[];

    @Getter user;

    @Getter settings;

    view = false;

    type_product = 'Товар';

    product: Part = {};

    loading_form = false;

    validExpressAdd: object[] = []

    validateNameProducts = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        callback(new Error('Необходимо заполнить поле «Название».'));
      }
    }

    rules: object = {
      name: {
        validator: this.validateNameProducts,
        trigger: 'change',
        required: true,
      },
    };

    mounted() {
      this.checkSettings();
      this.dataStorageList(this.storageList);
    }

    handleSearchProducts(name, cb) {
      if (name) {
        if (this.type_product === 'Товар') {
          PartProvider.getPartUniversalSearch(1, { query: name, category: 1 }, 20)
            .then((res) => cb(res.data))
            .catch((err) => {
              console.error(err);
              cb([]);
            });
        } else {
          WorkProvider.getWorkSearchList(1, { query: name, category: 1 }, 20)
            .then((res) => cb(res.data))
            .catch((err) => {
              console.error(err);
              cb([]);
            });
        }
      } else {
        this.product = {};
        cb([]);
      }
    }

    handleSelectProductsName(product) {
      this.product = product;
      this.$emit('handleSelectName', product.name);
    }

    validate = (obj: object, key: string) => {
      if (Object.keys(obj).some((v) => v === key)) {
        return obj[key][0];
      }
      return null;
    }

    checkForm() {
      if (this.form.posting_price === '' || this.form.posting_price === null) {
        this.form.posting_price = 0;
      }
    }

    changePostingPrice() {
      this.form.posting_price = (Math.floor(Number(this.form.posting_price) * 100)) / 100;
    }

    changePrice() {
      this.form.price = (Math.floor(Number(this.form.price) * 100)) / 100;
    }

    handleAddExpressProducts() {
      try {
        this.validExpressAdd = [];
        this.loading_form = true;
        (this.$refs.expressForm as Form).validate(async (valid) => {
          if (valid) {
            let apiProduct = this.product;

            this.checkForm();
            if (Object.keys(this.product).length !== 0) {
              if (this.type_product === 'Товар') {
                await PostingProvider.sendPosting(
                  {
                    parts: [{
                      id: this.product.id,
                      price: this.form.posting_price,
                      amount: this.form.amount,
                      part_id: this.product.id,
                    }],
                    storage_id: this.form.storage_id,
                  },
                ).catch((err) => {
                  this.errorAddExpressProducts();
                  throw new Error(err);
                });
              }
            } else if (this.type_product === 'Товар') {
              apiProduct = (await PartProvider.sendPart(
                {
                  name: this.form.name,
                  sell_price: this.form.price,
                  category_id: 1,
                },
              ).catch((err) => {
                this.errorAddExpressProducts();
                throw new Error(err);
              })).data;

              await PostingProvider.sendPosting(
                {
                  parts: [{
                    id: apiProduct.id,
                    price: this.form.posting_price,
                    amount: this.form.amount,
                    part_id: apiProduct.id,
                  }],
                  storage_id: this.form.storage_id,
                },
              ).catch((err) => {
                this.errorAddExpressProducts();
                throw new Error(err);
              });
            } else {
              apiProduct = (await WorkProvider.sendWork(
                {
                  name: this.form.name,
                  sell_price: this.form.price,
                  category_id: 1,
                },
              ).catch((err) => {
                this.errorAddExpressProducts();
                throw new Error(err);
              })).data;
            }

            const id = Number(this.$route.params.id);

            if (this.typeForm === 'sell') {
              if (this.$route.name === 'sell-add') {
                let sell;
                if (this.type_product === 'Товар') {
                  sell = (await SellProvider.sendSell(
                    {
                      parts: [
                        {
                          amount: this.form.amount,
                          id: apiProduct.id,
                          storage_id: this.form.storage_id,
                          price: this.form.price,
                          part_id: apiProduct.id,
                        },
                      ],
                    },
                  ).catch((err) => {
                    this.errorAddExpressProducts();
                    throw new Error(err);
                  })).data;
                } else if (this.type_product === 'Услуга') {
                  sell = (await SellProvider.sendSell(
                    {
                      works: [
                        {
                          amount: this.form.amount,
                          id: apiProduct.id,
                          storage_id: this.form.storage_id,
                          price: this.form.price,
                          work_id: apiProduct.id,
                        },
                      ],
                    },
                  ).catch((err) => {
                    this.errorAddExpressProducts();
                    throw new Error(err);
                  })).data;
                }

                this.$emit('updateSell', sell);
              } else if (this.type_product === 'Товар') {
                const sell_part = (await SellProvider.sendSellPart(
                  {
                    name: this.form.name,
                    price: this.form.price,
                    category_id: 1,
                    amount: this.form.amount,
                    sell_id: id,
                    part_id: apiProduct.id,
                    seller_id: this.form.seller_id,
                    storage_id: this.form.storage_id,
                  },
                ).catch((err) => {
                  this.validExpressAdd = err.response.data;
                  this.errorAddExpressProducts();
                  throw new Error(err);
                })).data;

                this.$emit('addPart', sell_part);
              } else {
                const sell_work = (await SellProvider.sendSellWork(
                  {
                    name: this.form.name,
                    price: this.form.price,
                    amount: this.form.amount,
                    sell_id: id,
                    seller_id: this.form.seller_id,
                    work_id: apiProduct.id,
                  },
                ).catch((err) => {
                  this.errorAddExpressProducts();
                  throw new Error(err);
                })).data;

                this.$emit('addWork', sell_work);
              }
            } else if (this.type_product === 'Товар') {
              const repair_part = (await RepairProvider.sendRepairPart(
                {
                  repair_id: id,
                  price: this.form.price,
                  amount: this.form.amount,
                  part_id: apiProduct.id,
                  master_id: this.form.seller_id,
                  storage_id: this.form.storage_id,
                },
              ).catch((err) => {
                this.errorAddExpressProducts();
                throw new Error(err);
              })).data;

              this.$emit('addPart', repair_part);
            } else {
              const repair_work = (await RepairProvider.sendRepairWork(
                {
                  price: this.form.price,
                  amount: this.form.amount,
                  repair_id: id,
                  master_id: this.form.seller_id,
                  work_id: apiProduct.id,
                },
              ).catch((err) => {
                this.errorAddExpressProducts();
                throw new Error(err);
              })).data;

              this.$emit('addWork', repair_work);
            }

            this.loading_form = false;
            this.$emit('closeExpressForm', false);
            this.$emit('clearExpressForm');
            if (this.type_product === 'Товар') {
              this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар добавлен', item: '' });
            } else if (this.type_product === 'Услуга') {
              this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Услуга добавлен', item: '' });
            }
            (this.$refs.expressForm as Form).resetFields();
            return true;
          }
          this.loading_form = false;
          return false;
        });
      } catch (err) {
        this.errorAddExpressProducts();
      }
    }

    errorAddExpressProducts() {
      this.loading_form = false;
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
    }

    @Watch('viewModal')
    handleViewModal(view: boolean) {
      this.view = view;
    }

    @Watch('storageList')
    dataStorageList(list) {
      if (list.length !== 0) {
        const default_storage = list.find((storage) => {
          if (storage.point_id) {
            return storage.point_id === this.user.point_id;
          }
          return undefined;
        });

        this.form.storage_id = default_storage ? default_storage.id : list[0].id;
      }
    }

    @Watch('settings')
    checkSettings() {
      if (this.settings.allow_part_auto_create !== undefined
        && this.settings.allow_part_auto_create !== true) {
        this.type_product = 'Услуга';
      }
    }

    @Watch('type_product')
    updateProductType() {
      this.product = {};
    }
}

interface ExpressForm {
    name: string;
    price: number;
    posting_price: number | string;
    amount: number;
    category_id: number;
    storage_id: number;
    seller_id: number;
}
