import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Breadcrumb } from '@/types';
import ProviderProvider from '../../api/providers/provider';
import ProviderForm from '../../components/provider-form';

@Component({
  template: require('./index.html'),
  components: {
    'provider-form': ProviderForm,
  },
})
export default class ProviderEditPage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    item: object = {};

    loading_page = false;

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Справочники', link: null },
      { id: 3, section: 'Поставщики', link: '/reference/provider' },
    ];

    loading_form = false;

    getViewProvider(id) {
      this.loading_page = true;
      ProviderProvider.getViewProvider(id)
        .then((res) => {
          this.breadcrumb.push({ id: 4, section: res.data.name, link: null });
          this.addBreadcrumb(this.breadcrumb);

          this.item = res.data;
          this.loading_page = false;
        })
        .catch((err) => {
          this.loading_page = false;
          console.error(err);
        });
    }

    changeProvider(item) {
      this.loading_form = true;
      ProviderProvider.changeProvider(item.id, item)
        .then((res) => {
          this.loading_form = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `${res.data.name} успешно изменён`, item: '' });

          this.$router.push({ path: '/reference/provider' });
        })
        .catch((err) => {
          console.error(err);
          this.loading_form = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    mounted() {
      this.getViewProvider(this.$route.params.id);
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Изменение поставщика';
    }

    destroyed() {
      this.addActionBtn([]);
    }
}
