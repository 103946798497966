import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { ChangePointForm, Point } from '@/types';

@Component({
  template: require('./index.html'),
})

export default class ModalPointPicker extends Vue {
  @Prop({ default: () => false }) isModalShow!: boolean;

  @Prop({ default: () => [] }) sortedPoints!: Point[]

  @Action sendUserChangePoint;

  @Getter user;

  /**
   * Закрытие модального окна
   * @param  {} view
   */
  closeModalPoints(view) {
    if (!view) this.$router.push('/repair');
    this.$emit('changeShowingModalPoint', false);
    this.$emit('tryShowFeedbackForm');
  }

  /**
   * Выбор нового филиала
   * @param  {} point
   */
  handleChangeNewPoint(point) {
    const new_user = { ...this.user };
    if (point === null) {
      this.$emit('changeShowingModalPoint', false);
    } else {
      new_user.point_id = point.id;

      const form: ChangePointForm = {
        point_id: point.id,
      };
      this.sendUserChangePoint(form);
    }

    this.$router.push('/repair');
    this.$emit('tryShowFeedbackForm');
  }
}
