import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import TaskStatusProvider from '../../api/providers/task-status';
import { TaskStatus } from '../../store/task-status/types';

@Component({
  template: require('./index.html'),
})
export default class TaskFormComponent extends Vue {
    @Getter btnloader;

    @Action getStuffList;

    @Getter stuff;

    @Prop() item;

    taskStatuses: TaskStatus[] = [];

    async mounted() {
      this.getStuffList();
      this.taskStatuses = await TaskStatusProvider.getAllStatuses();
      this.checkIsStatusDeleted();
    }

    checkIsStatusDeleted() {
      const founded = this.taskStatuses.findIndex(
        (taskStatus) => taskStatus.id === this.item.status.id,
      );

      if (founded === -1) {
        this.taskStatuses.push(this.item.status);
      }
    }

    validate = (obj: object, key: string) => {
      if (Object.keys(obj).some((v) => v === key)) {
        return obj[key][0];
      }
      return null;
    }

    get elementTaskForm() {
      return this.$refs.taskForm;
    }

    get orderedStuff() {
      // Проверить правильно ли работает сортировка: добавил if (a && b) и return 0
      return this.stuff.sort((a, b) => {
        if (a && b) {
          if (a.last_name < b.last_name) return -1;
          if (a.last_name > b.last_name) return 1;
          if (a.last_name === b.last_name) {
            if (a.first_name < b.first_name) return -1;
            if (a.first_name > b.first_name) return 1;
            if (a.first_name === b.first_name) {
              if (a.middle_name < b.middle_name) return -1;
              if (a.middle_name > b.middle_name) return 1;
              if (a.middle_name === b.middle_name) return 0;
            }
          }
        } return 0;
      });
    }
}
