import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import ErrorAccessRights from '../../components/error-access-rights';

@Component({
  template: require('./import.html'),
  components: {
    'error-access-rights': ErrorAccessRights,
  },
})
export default class Import extends Vue {
    @Action addBreadcrumb;

    @Getter user;

    get rightsUserCanExport() {
      return this.user.rights ? this.user.rights.can_export : false;
    }

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: '/settings/info' },
        { id: 2, section: 'Импорт', link: null },
      ]);
      document.title = 'Импорт';
    }
}
