const state = {
  taskList: [],
  currentTask: {},
  lastSavedTaskId: null,
  validTask: {},
  pageTaskCount: null,
  pageTaskCurrent: null,
};

export default state;
