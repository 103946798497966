import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
})
export default class ChangingInputNumber extends Vue {
  @Prop({ default: () => '' }) inputPropText!: string;

  @Prop({ default: () => 0 }) minInputValue;

  inputText = this.inputPropText;

  isInputActivate = false;

  deactivateInput() {
    this.isInputActivate = false;
    if (this.inputPropText !== this.inputText) {
      this.saveInputChanging();
    }
  }

  activateInput() {
    this.isInputActivate = true;
  }

  saveInputChanging() {
    if (this.inputText === '') {
      this.inputText = '0';
    }
    this.$emit('inputChanging', this.inputText);
  }

  @Watch('inputPropText')
  syncInputText() {
    this.inputText = this.inputPropText;
  }
}
