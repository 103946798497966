import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import LimitBalanceMessage from '@/components/limit-balance-message';
import Auth from './components/auth';
import BreadcrumbComponent from './components/breadcrumb';
import FooterComponent from './components/footer';
import LicensesMessage from './components/licenses-message';
// ** Components **
import NavbarComponent from './components/navbar';
import PushComponent from './components/push';
import SignalrManager from './components/signalr';
import UpdateMessage from './components/update-message';
import Voip from './components/voip';

@Component({
  template: require('./index.html'),
  components: {
    navbar: NavbarComponent,
    breadcrumb: BreadcrumbComponent,
    footbar: FooterComponent,
    push: PushComponent,
    auth: Auth,
    signalr: SignalrManager,
    voip: Voip,
    'licenses-message': LicensesMessage,
    'update-message': UpdateMessage,
    LimitBalanceMessage,
  },
})
export default class App extends Vue {
  @Action authRefreshToken;

  @Action signOut;

  @Getter auth;

  upVisible = false;

  get unauthorized() {
    return this.auth === false;
  }

  created() {
    if (localStorage.getItem('saveUser') === 'false') {
      if (localStorage.getItem('lastSession')) {
        const now = Math.round(new Date().getTime() / 1000);
        const lastSession = localStorage.getItem('lastSession');
        if (lastSession) {
          if (now - Number.parseFloat(lastSession) > 30) {
            const intervalId = Number(localStorage.getItem('intervalId'));
            window.clearInterval(intervalId);
            this.signOut(false);
            this.$router.push('/auth');
          }
        }
      }
      setInterval(() => {
        localStorage.setItem('lastSession', String(Math.round(new Date().getTime() / 1000)));
      }, 1000);
    }

    document.addEventListener('scroll', () => {
      this.upVisible = document.body.scrollTop > 200 || window.pageYOffset > 200;
    });
  }

  upTop = () => {
    let s = document.body.scrollTop || window.pageYOffset;
    const t = setInterval(() => {
      if (s > 0) {
        window.scroll(0, s -= 45);
      } else {
        clearInterval(t);
      }
    });
  }
}
