import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Breadcrumb, ActionBtn, Provider } from '@/types';
import ProviderProvider from '@/api/providers/provider';

@Component({
  template: require('./index.html'),
})
export default class ProviderPage extends Vue {
  @Action addBreadcrumb;

  @Action addActionBtn;

  provider: Provider[] = [];

  providerPageCurrent = 1;

  providerPageCount = 0;

  dialogDelete = false;

  dialogView = false;

  loading = false;

  providerView: object = {};

  breadcrumb: Breadcrumb[] = [
    { id: 1, section: 'Компания', link: '/settings' },
    { id: 2, section: 'Справочники', link: null },
    { id: 3, section: 'Поставщики', link: null },
  ];

  actionBtn: ActionBtn[] = [{
    id: 1, type: 'add', title: 'Новый поставщик', link: '/reference/provider/add', icon: 'fa-plus',
  }];

  mounted() {
    this.getProviderList(1);
    this.addBreadcrumb(this.breadcrumb);
    document.title = 'Поставщики';
    this.addActionBtn(this.actionBtn);
  }

  getProviderList(page: number) {
    ProviderProvider.getProviderList(page)
      .then((res) => {
        this.provider = res.data;
        this.providerPageCount = res.pageCount;
        this.providerPageCurrent = res.pageCurrent;
      })
      .catch((err) => console.error(err));
  }

  destroyed() {
    this.addBreadcrumb([]);
    this.addActionBtn([]);
  }

  indexProvierMethod(index) {
    return (Number(this.providerPageCurrent) - 1) * 20 + index + 1;
  }

  loadPage(page: number) {
    this.getProviderList(page);
  }

  deleteProvider(id: number) {
    ProviderProvider.deleteProvider(id)
      .then((res) => {
        const index = this.provider.indexOf(this.provider.find(
          (i) => i.id === res.data.id,
        ) as Provider);
        this.provider.splice(index, 1);
        this.$store.commit('PUSH_CALL_SUCCESS', { title: `${res.data.name} успешно удалён` });
      })
      .catch((err) => {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  }

  viewProvider(val, column) {
    if (column.type === 'default' || column.type === 'index') {
      this.providerView = val;
      this.dialogView = !this.dialogView;
    }
  }

  onDialogDelete(item) {
    this.providerView = item;
    this.dialogDelete = !this.dialogDelete;
  }

  @Watch('provider')
  onLoad() {
    this.$nextTick(() => {
      this.loading = false;
    });
  }
}
