import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { RepairDiscount } from '@/types';
import FormAddition from '../../../forms/addition';
import FormDiscount from '../../../forms/discount';

@Component({
  template: require('./discount.html'),
  components: {
    'form-addition': FormAddition,
    'form-discount': FormDiscount,
  },
})
export default class DialogDiscount extends Vue {
    @Prop() flagsDiscountOrAddition!: object;

    @Prop() sendDiscount!: Function;

    @Prop() sendAddition!: Function;

    @Getter btnloader;

    formDiscountAndAddition: RepairDiscount = {
      name: null,
      amount: null,
      type_id: 1,
    };

    close() {
      this.clear();
    }

    clear() {
      try {
        Object.keys(this.formDiscountAndAddition).forEach((key) => {
          if (key) {
            this.formDiscountAndAddition[key] = null;
          }
        });
      } catch (err) {
        throw new Error(err);
      }
    }

    @Watch('flagsDiscountOrAddition')
    openModal(flags) {
      if (flags.btn) {
        this.formDiscountAndAddition.repair_id = this.$route.params.id;
        this.$nextTick(() => {
          if (this.$refs.formAddition) {
            (this.$refs.formAddition as FormAddition).clearFormValidation();
          }
          if (this.$refs.formDiscount) {
            (this.$refs.formDiscount as FormDiscount).clearFormValidation();
          }
        });
      }
    }
}
