import Vue from 'vue';
import { Route } from 'vue-router/types/index.d';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  template: require('./setting.html'),
})
export default class Setting extends Vue {
    link = '/settings/info';

    mounted() {
      this.link = this.$route.path;
    }

    @Watch('link')
    onChange(val: string) {
      this.$router.push(val);
    }

    @Watch('$route', { immediate: true })
    viewRoute(route: Route) {
      this.link = route.path;
    }
}
