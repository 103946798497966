import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CashboxFilterForm } from '@/components/cash-view-filter/types';
import SalaryOperation from '@/api/providers/salaryoperation';
import { Breadcrumb } from '@/types';
import CashViewContent from '../../components/cash-view-content';
import CashViewFilter from '../../components/cash-view-filter';
import Numbers from '../../components/forms/numbers';
import DialogCashOperation from './dialog/cash-operation';

interface FormType {
  from_cashbox_id?: number | null;
  cashbox_id?: number | null;
  comment: string;
}

interface CashItem {
  id: number;
}
@Component({
  template: require('./index.html'),
  components: {
    'cash-filter': CashViewFilter,
    'cash-content': CashViewContent,
    numbers: Numbers,
    'dialog-cash-operation': DialogCashOperation,
  },
})
export default class CashViewPage extends Vue {
    @Action getCashOperation;

    @Action getFullCashList;

    @Action addBreadcrumb;

    @Action clearCashOperation;

    @Action getCashboxView;

    @Action sendCashOperationIn;

    @Action sendCashOperationOut;

    @Action sendCashOperationTransfer;

    @Getter cashbox;

    @Getter cashList;

    @Getter cashViewDetail;

    @Getter cashOperationsList;

    @Getter cashOperationCountIn;

    @Getter cashOperationCountOut;

    @Getter cashOperationTotalIn;

    @Getter cashOperationTotalOut;

    @Getter cashOperationPageCount;

    @Getter cashTypeUserOn;

    @Getter cashTypeUserOff;

    @Getter cashOperationIn;

    @Getter cashOperationOut;

    @Getter cashOperationTransfer;

    @Getter cashOperationDelete;

    @Getter btnloader;

    @Getter user;

    cashOperationsListWithOperation: object[] = [];

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Финансы', link: '/cash' },
      { id: 2, section: 'Кассы', link: '/cash' },
      { id: 3, section: 'Все кассы', link: null },
    ];

    id: number | null = null;

    loading = true;

    dialog = false;

    typeBtn: number | null= null;

    label = '';

    filterForm: CashboxFilterForm = {
      cashbox_id: null,
      created_at_start: null,
      created_at_end: null,
      sum_min: null,
      sum_max: null,
      act_type_id: null,
      custom_act_type_id: null,
      created_by: null,
      not_deleted: null,
      page: 1,
      perPage: 20,
    };

    form: FormType = {
      comment: '',
    };

    cash_list: object[] = [];

    get idCash() {
      if (this.$route.params.id === undefined) {
        return null;
      }
      return Number(this.$route.params.id);
    }

    showCashboxInfo = false;

    created() {
      this.updateBreadcrumb();
    }

    updateSumCashList() {
      this.closeDialogCash();
      this.search();
    }

    mounted() {
      document.title = 'Касса';
      this.getCashboxIdFromUrl();
    }

    /**
     * Получение информации о кассе при по парметрам роутера, загрузка данных
     * @returns void
     */
    getCashboxIdFromUrl(): void {
      if (this.$route.params.id === undefined) {
        this.filterForm.cashbox_id = null;
      } else {
        this.filterForm.cashbox_id = Number(this.$route.params.id);
        this.id = Number(this.$route.params.id);
        this.getCashboxView(this.filterForm.cashbox_id);
      }
      this.showCashboxInfo = !this.filterForm.cashbox_id;
      this.addBreadcrumb(this.breadcrumb);
      this.getCashOperation(this.filterForm);

      this.getFullCashList(false);
    }

    get total() {
      return Number(this.cashOperationTotalIn) + Number(this.cashOperationTotalOut);
    }

    get rightsUserCanCashOut() {
      return this.user.rights ? this.user.rights.can_cash_out : false;
    }

    get rightsUserCanCashIn() {
      return this.user.rights ? this.user.rights.can_cash_in : false;
    }

    get rightsUserCanCashTransfer() {
      return this.user.rights ? this.user.rights.can_cash_transfer : false;
    }

    onDialog(label, type) {
      this.typeBtn = type;
      this.label = label;
      switch (this.typeBtn) {
        case 1: this.form.from_cashbox_id = this.idCash; break;
        default: this.form.cashbox_id = this.idCash;
      }
      this.dialog = true;
    }

    methodDisabledButtons() {
      this.$notify.error({
        title: 'Вам не разрешено изымать средства из кассы',
        message: '',
        position: 'bottom-right',
      });
    }

    methodDisabledButtonsCashIn() {
      this.$notify.error({
        title: 'Вам не разрешено вносить средства в кассу',
        message: '',
        position: 'bottom-right',
      });
    }

    methodDisabledButtonsCashTransfer() {
      this.$notify.error({
        title: 'Вам не разрешено перемещать средства',
        message: '',
        position: 'bottom-right',
      });
    }

    closeDialogCash() {
      this.dialog = false;
    }

    resetForm() {
      (this.$refs.formOperationCash as Form).resetFields();
    }

    search() {
      this.getCashOperation(this.filterForm);
    }

    typePerPage(count) {
      return count === this.filterForm.perPage ? 'primary' : '';
    }

    changePage(val) {
      this.filterForm.page = val;
      this.search();
    }

    changePerPage(val) {
      this.filterForm.perPage = val;
      this.search();
    }

    updateBreadcrumb() {
      if (this.filterForm.cashbox_id) {
        this.breadcrumb[this.breadcrumb.length - 1].section = this.cashViewDetail;
      } else {
        this.breadcrumb[this.breadcrumb.length - 1].section = 'Все кассы';
      }
    }

    /**
     * Изменяет заголовок документа
     */
    changeDocumentTitle() {
      if (this.filterForm.cashbox_id) {
        document.title = `Касса ${this.cashbox?.name}`;
      } else {
        document.title = 'Все кассы';
      }
    }

    /**
     * Обновляет URL в зависимсоти от выбранной кассы
     * вызывает обновление закголовка документа или изменение выбранной кассы
     * @returns void
     */
    changeUrl(): void {
      let cashboxId = '';

      if (this.filterForm.cashbox_id) {
        cashboxId = String(this.filterForm.cashbox_id);
        this.getCashboxView(this.filterForm.cashbox_id);
      } else {
        this.listCash(this.cashList);
        this.changeDocumentTitle();
      }
      this.changeDocumentTitle();
      this.$router.push(`/cash/view/${cashboxId}`);
    }

    /**
   * При получении списка операций проверяет, есть ли среди них "Выплата ЗП",
   *  если есть, то добавляет поле salary_user_id - айди сотрудника которому выплатили ЗП
   * @param  {} 'cashOperationsList'
   */
    async addSalaryUserId() {
      this.showCashboxInfo = !this.filterForm.cashbox_id;
      const operationList = [...this.cashOperationsList];

      const requestOperation: object[] = [];
      operationList.forEach(async (operation) => {
        if (operation.act_type_id === 12) {
          const form = {
            transfer_id: operation.act_id,
          };
          requestOperation.push(SalaryOperation.salaryOperationIndex({ form })
            .then((salRes) => {
              if (salRes.data[0]) {
                const { user_id } = salRes.data[0];
                // eslint-disable-next-line no-param-reassign
                operation.salary_user_id = user_id;
              }
            })
            .catch((err) => {
              console.error(err);
            }));
        }
      });
      await Promise.all(requestOperation);
      this.$nextTick(() => {
        this.cashOperationsListWithOperation = operationList;
      });
    }

    @Watch('cashList')
    /**
     * Меняет массив для целевых касс
     * @param  {CashItem[]} list
     */
    listCash(list: CashItem[]) {
      if (this.filterForm?.cashbox_id) {
        this.cash_list = list.filter((item) => item.id !== this.cashbox.id);
      } else {
        this.cash_list = list;
      }
    }

    @Watch('cashOperationsList')
    async onLoad() {
      this.changeUrl();
      await this.addSalaryUserId();
      this.$nextTick(() => {
        this.loading = false;
      });
    }

    @Watch('cashbox')
    onCashboxLoad() {
      this.$nextTick(() => {
        this.listCash(this.cashList);
        this.breadcrumb[this.breadcrumb.length - 1].section = this.cashbox.name;
        this.changeDocumentTitle();
      });
    }

    @Watch('cashOperationIn')
    cashoperationIn() {
      this.dialog = false;
      this.search();
    }

    @Watch('cashOperationOut')
    cashoperationOut() {
      this.dialog = false;
      this.search();
    }

    @Watch('cashOperationTransfer')
    cashoperationTransfer() {
      this.dialog = false;
      this.search();
    }

    @Watch('cashOperationDelete')
    cashoperationDelete() { this.search(); }

    beforeDestroy() { this.clearCashOperation(); }
}
