import ClientsProvider from '../../../api/providers/clients';

export default function getViewClient(this, id) {
  ClientsProvider.getViewClient(id)
    .then((res) => {
      this.updateClient(res.data);
    }).catch((err) => {
      console.error(err);
    });
}
