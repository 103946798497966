export const GET_REPORTS = 'GET_REPORTS';

// Store

export interface StateReport {
    reports: Report[];
}

// Models

export interface Report {
    id: number;
    title: string;
    description: string;
    enabled: boolean;
}
