import BaseProvider from '../base-provider';

class CashTypeProvider extends BaseProvider {
  async getCashTypeList(page) {
    const res = await this.request('GET', `/cashtype/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendCashType(item) {
    const data: object = { CashType: item };
    const res = await this.request('POST', '/cashtype/add', data);
    return res;
  }

  async changeCashType(item) {
    const data: object = { CashType: item };
    const res = await this.request('POST', `/cashtype/update?id=${item.id}`, data);
    return res;
  }

  async deleteCashType(id) {
    const res = await this.request('POST', `/cashtype/delete?id=${id}`);
    return res;
  }
}

export default new CashTypeProvider();
