import { MutationTree } from 'vuex';
import {
  CLEAR_TRANSFER_ITEM, DELETE_TRANSFER_ITEM, GET_PAGE_TRANSFER, GET_TRANSFER,

  GET_VALID_TRANSFER, GET_VIEW_TRANSFER,

  SEND_TRANSFER_ITEM,

  StateTransfer,
} from './types';

const mutations: MutationTree<StateTransfer> = {
  [GET_TRANSFER](state, { item, pageCount }) {
    state.transfer = item;
    state.transferPageCount = pageCount;
  },

  [GET_VIEW_TRANSFER](state, { item }) {
    state.viewTransfer = item;
  },

  [GET_PAGE_TRANSFER](state, { item, pageCurrent, pageCount }) {
    state.transfer = state.transfer.concat(item);
    state.transferPageCurrent = pageCurrent;
    state.transferPageCount = pageCount;
  },

  [SEND_TRANSFER_ITEM](state, { item }) {
    state.transferNewItemList.push(item);
  },

  [GET_VALID_TRANSFER](state, item) {
    state.transferValid = item;
  },

  [CLEAR_TRANSFER_ITEM](state) {
    state.transferNewItemList = [];
  },

  [DELETE_TRANSFER_ITEM](state, item) {
    const index = state.transferNewItemList.findIndex((i) => i.id === item.id);
    state.transferNewItemList.splice(index, 1);
  },
};

export default mutations;
