import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Cancel, Breadcrumb } from '@/types';
import CancelProvider from '../../api/providers/cancel';
import StorageProvider from '../../api/providers/storage';
import StuffProvider from '../../api/providers/stuff';
import Numbers from '../forms/numbers';
import ModalCatalog from '../modal-catalog';
import TableStorage from '../table-storage';
import WarehouseTable from '../warehouse-table';

interface NewForm {
  storage_id;
  penalty_user_id;
  parts;
}
@Component({
  template: require('./cancel-add.html'),
  components: {
    'table-storage': TableStorage,
    'modal-catalog': ModalCatalog,
    'warehouse-table': WarehouseTable,
    numbers: Numbers,
  },
})
export default class CancelAdd extends Vue {
    @Action addBreadcrumb;

    @Getter user;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Списания', link: '/cancel' }, { id: 3, section: 'Новое списание', link: null }];

    new_form: NewForm = {
      storage_id: 1,
      penalty_user_id: null,
      parts: [],
    };

    loading = false;

    stuff_list: object[] = [];

    cancel_item_list: Cancel[] = [];

    cancel_parts_list: object[] = [];

    storages_list: object[] = [];

    loading_save_cancel = false;

    // api

    getStorageList(page) {
      StorageProvider.getStorageList(page)
        .then((res) => {
          this.storages_list = res.data;

          const default_storage = res.data.find((storage) => {
            if (storage.point_id) {
              return storage.point_id === this.user.point_id;
            }
            return undefined;
          });

          if (default_storage) this.new_form.storage_id = default_storage.id;
        }).catch((err) => console.error(err));
    }

    getStuffList() {
      StuffProvider.getFullStuffList()
        .then((res) => {
          this.stuff_list = res;
        }).catch((err) => console.error(err));
    }

    sendCancelItem(item) {
      this.cancel_item_list.push(item);
    }

    deleteCancelItem(item: Cancel) {
      const index = this.cancel_item_list.findIndex((i) => i.id === item.id);
      this.cancel_item_list.splice(index, 1);
    }

    sendCancel(item) {
      this.loading_save_cancel = true;
      const isValid = this.validatingCancel();
      if (isValid) {
        this.loading_save_cancel = false;
        return;
      }
      CancelProvider.sendCancel(item)
        .then((res) => {
          this.$router.push({ name: 'cancel-view', params: { id: res.data.id } });
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Списание прошло успешно', item: '' });
          this.loading_save_cancel = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading_save_cancel = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    validatingCancel(): boolean {
      let haveError = false;
      if (this.new_form.parts.length === 0) {
        haveError = true;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Добавьте товар', item: '' });
      }
      if (!this.new_form.storage_id) {
        haveError = true;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Выберите склад', item: '' });
      }
      return haveError;
    }

    // end api

    mounted() {
      if (this.user.rights) {
        if (!this.user.rights.can_create_cancel) {
          this.$router.push({ name: 'cancel' });
        }
      }
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Новое списание';
      this.getStorageList(1);
      this.getStuffList();
    }

    get sum() {
      /* eslint-disable-next-line no-param-reassign */
      return this.new_form.parts.reduce((sum, current) => sum += current.price * current.amount, 0);
    }

    date = new Date().toLocaleString('ru-RU', {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
    }).replace(',', '');

    loadingPartsList(partsList) {
      this.cancel_parts_list = partsList.map((i) => ({
        id: i.id,
        name: i.name,
        part_id: i.id,
        amount: 1,
        art: i.art,
        price: i.posting_price === null ? 0 : i.posting_price,
        amounts: i.amounts,
      }));
    }

    currentUser = (user) => {
      const lastName: string = user.last_name ? user.last_name : '';
      const firstName: string = user.first_name ? user.first_name : '';
      const middleName: string = user.middle_name ? user.middle_name : '';
      return `${lastName} ${firstName} ${middleName}`;
    }

    @Watch('cancel_item_list')
    addNewParts(newPart) {
      this.new_form.parts = newPart;
    }
}
