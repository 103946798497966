import { Table } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { SalaryItem } from '@/types';
import ViewDate from '../../forms/date/index';
import Numbers from '../../forms/numbers';

@Component({
  template: require('./index.html'),
  components: {
    Numbers,
    ViewDate,
  },
})

export default class SalaryOperationTable extends Vue {
  @Prop(Array) data;

  @Prop({ default: () => [], type: Array }) selectedRows!: Array<SalaryItem>;

  @Prop(Number) total!: number;

  @Prop(Boolean) loading!: boolean;

  @Prop(Boolean) disabledActions!: boolean;

  @Prop(String) emptyText!: string;

  // флаг отправки изменений в родительский компонент
  // используется чтобы не создавать бесконечный цикл
  // когда реактивность изменит selectedRows и мы
  // будем руками выбирать строки
  transferSelectionToParent = true;

  /**
   * Wath'ер на изменения списка выбранных элементов.
   * Список выбранных элементов хранится в родителе,
   * он передает его через проп. При изменении массива
   * выбранных нам нужно изменить выделения в текущей таблице,
   * но при этом не нужно отправлять изменения чекбоксов родителю,
   * иначе будет бесконечный вызов
   * изменение -> отправка родителю -> родитель меняет проп
   */
  @Watch('selectedRows')
  updateRowsSelection() {
    this.transferSelectionToParent = false;
    this.clearSelection();
    this.data.forEach((element) => {
      if (this.selectedRows.find((x) => x.id === element.id)) {
        this.toggleRowSelection(element, true);
      } else {
        this.toggleRowSelection(element, false);
      }
    });
    this.transferSelectionToParent = true;
  }

  toggleRowSelection(row, selected) {
    (this.$refs.multipleSelectionTable as Table).toggleRowSelection(row, selected);
  }

  clearSelection() {
    (this.$refs.multipleSelectionTable as Table).clearSelection();
  }

  summaryMethod({ columns }) {
    const total: string = this.total.toFixed(2);
    const sum = new Intl.NumberFormat('ru-RU', {
      maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'RUB',
    })
      .format(Number(total));
    const out: object = [];
    out[1] = ['Итого:'];
    out[columns.length - 2] = sum;
    return out;
  }

  rowKey = (row) => row.id

  handleGoProducts(row) {
    if (row.act_type === 3 || row.act_type === 4 || row.act_type === 5) {
      const route = this.$router.resolve(`/repair/view/${row.related_record_id}`);
      window.open(route.href, '_blank');
    }
    if (row.act_type === 6 || row.act_type === 7) {
      const route = this.$router.resolve(`/sell/view/${row.related_record_id}`);
      window.open(route.href, '_blank');
    }
  }

  /**
   * Обработчик для смены чекбоксов таблицы.
   *
   * @param rows Массив выбранных элементов
   */
  handleSelectionChange(rows) {
    if (!this.transferSelectionToParent) return;
    rows.forEach((element) => { // отправляем родителю выбранные элементы
      this.$emit('update-operation-selection', element.id, true);
    });
    // отправляем родителю невыбранные элементы т.к. могло быть снятие галочки
    this.selectedRows.forEach((element) => {
      if (!rows.find((x) => x.id === element.id)) {
        this.$emit('update-operation-selection', element.id, false);
      }
    });
  }
}
