import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CountryPreset, LocalePreset, TimeZonePreset } from '@/types';
import CompanyProvider from '../../api/providers/company';
import ErrorAccessRights from '../../components/error-access-rights';

interface NewCompany {
  phone_mask?;
}
@Component({
  template: require('./index.html'),
  components: {
    'error-access-rights': ErrorAccessRights,
  },
})
export default class CompanyPage extends Vue {
    @Getter company!: CountryPreset;

    @Getter billing;

    @Getter settings;

    @Getter country;

    @Getter btnloader;

    @Getter user;

    @Action addBreadcrumb;

    @Action changeCompanyInfo;

    @Action updateSettings;

    @Action getCompanyInfo;

    @Action getBilling;

    @Action updateBilling;

    @Action showErrorPush;

    newcompany: NewCompany = {};

    newBilling: object = {};

    loading = true;

    loading_info_company = false;

    locales: LocalePreset[] = [];

    currencies = [];

    time_zone: TimeZonePreset[] = [];

    private companyFormRules = {
      name: [{ required: true, message: 'Поле обязательно для заполнения' }],
      phone: [{ required: true, message: 'Поле обязательно для заполнения' }],
    };

    private billingFormFirstRules = {
      org_type: [{ required: true, message: 'Поле обязательно для заполнения' }],
      inn: [{ required: true, message: 'Поле обязательно для заполнения' }],
      ogrn: [{ required: true, message: 'Поле обязательно для заполнения' }],
      bank_account: [{ required: true, message: 'Поле обязательно для заполнения' }],
      correspond_account: [{ required: true, message: 'Поле обязательно для заполнения' }],
      official_name: [{ required: true, message: 'Поле обязательно для заполнения' }],
      kpp: [{ required: true, message: 'Поле обязательно для заполнения' }],
      bik: [{ required: true, message: 'Поле обязательно для заполнения' }],
      bank_name: [{ required: true, message: 'Поле обязательно для заполнения' }],
    };

    private billingFormSecondRules = {
      org_type: [{ required: true, message: 'Поле обязательно для заполнения' }],
      inn: [{ required: true, message: 'Поле обязательно для заполнения' }],
      bank_account: [{ required: true, message: 'Поле обязательно для заполнения' }],
      correspond_account: [{ required: true, message: 'Поле обязательно для заполнения' }],
      official_name: [{ required: true, message: 'Поле обязательно для заполнения' }],
      kpp: [{ required: true, message: 'Поле обязательно для заполнения' }],
      bik: [{ required: true, message: 'Поле обязательно для заполнения' }],
      bank_name: [{ required: true, message: 'Поле обязательно для заполнения' }],
    };

    get billingFormRules() {
      switch (this.billing.org_type) {
        case 1: return this.billingFormFirstRules;
        case 2: return this.billingFormFirstRules;
        default: return this.billingFormSecondRules;
      }
    }

    get rightsUserCanUpdateCompanyDetails() {
      return this.user.rights ? this.user.rights.can_update_company_details : false;
    }

    get timeZone() {
      return this.time_zone;
    }

    async mounted() {
      this.loading = true;
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: null },
        { id: 2, section: 'Настройки', link: null },
        { id: 3, section: 'Информация о компании', link: null },
      ]);
      document.title = 'Информация о компании';
      await this.getCompanyInfo();
      await this.getBilling();
      this.locales = (await CompanyProvider.getLocalePresets()).data;
      this.currencies = (await CompanyProvider.getCurrencies()).data;
      this.changeCountryCode(this.locales, this.company.country_code);
      this.loading = false;
    }

    buildTimeZoneList() {
      if (this.locales.length === 0) return [];
      return this.locales[0].countries[0].time_zones;
    }

    getTimeZoneDisplayName = (timezone) => `${timezone.current_time} - ${timezone.id}`

    getCurrencyDisplayName = (currency) => `${currency.unicode_char} ${currency.national_name}`

    changeCountryCode(company, country_code) {
      const allCountries: CountryPreset[] = [].concat(company[0].countries, company[1].countries);
      const FinedCountries = allCountries.find(
        (countries) => countries.country_code === country_code,
      );
      if (FinedCountries) {
        this.time_zone = FinedCountries.time_zones;
      }
    }

    async onFormSubmit(form_name) {
      const companyForm = this.$refs.companyForm as Form;
      const billingForm = this.$refs.billingForm as Form;

      let valid = false;
      try {
        valid = form_name === 'infoCompany' ? await companyForm.validate() : await billingForm.validate();
      } catch (e) {
        // empty
      }
      if (valid) {
        if (form_name === 'infoCompany') {
          this.newcompany = { ...this.company } as object;
          delete this.newcompany.phone_mask;
          this.loading_info_company = true;
          await this.changeCompanyInfo(this.newcompany);
          await this.updateSettings({ phone_mask: this.settings.phone_mask });
        } else {
          this.newBilling = this.billing;
          await this.updateBilling(this.newBilling);
        }
      } else {
        this.showErrorPush({ title: 'Не удалось сохранить данные.', item: '' });
      }
    }

    @Watch('company')
    infoCompany() {
      this.loading_info_company = false;
    }
}
