import { ActionTree } from 'vuex';
import StuffProvider from '../../api/providers/stuff';
import {
  GET_REPAIR_PART_SALARY_TYPE, GET_SALARY_PERIOD_TYPE_LIST,
  GET_SELL_PART_SALARY_TYPE_LIST,

  StateStuff,
} from './types';

const actions: ActionTree<StateStuff, object> = {

  getStuffList({ commit }) {
    StuffProvider.getFullStuffList()
      .then((res) => commit('GET_STUFF', {
        item: res,
        pageCount: 1,
      }))
      .catch((err) => console.error(err));
  },

  getViewStuff({ commit }, id) {
    StuffProvider.getViewStuff(id)
      .then((res) => commit('GET_VIEW_STUFF', { item: res.data }))
      .catch((err) => console.error(err));
  },

  sendStuff({ commit }, item) {
    commit('BTN_LOADER', true);
    StuffProvider.sendStuff(item)
      .then((res) => {
        commit('INSERT_STORAGE', { item: res.data });
        commit('GET_VALID_STUFF', { item: {} });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: `${res.data.last_name} ${res.data.first_name} ${res.data.middle_name}` });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('GET_VALID_STUFF', { item: err.response.data });
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  changeStuff({ commit }, item) {
    commit('BTN_LOADER', true);
    StuffProvider.changeStuff(item.id, item)
      .then((res) => {
        commit('EDIT_STUFF', { item: res.data });
        commit('GET_VALID_STUFF', { item: {} });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно изменено', item: `${res.data.last_name} ${res.data.first_name} ${res.data.middle_name}` });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('GET_VALID_STUFF', { item: err.response.data });
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  deleteStuff({ commit }, id) {
    StuffProvider.deleteStuff(id)
      .then((res) => {
        commit('DELETE_STUFF', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно удалено', item: `${res.data.last_name} ${res.data.first_name} ${res.data.middle_name}` });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  getSalaryPeriodTypeList({ commit }) {
    const res = StuffProvider.getSalaryPeriodTypeList;
    commit(GET_SALARY_PERIOD_TYPE_LIST, { item: res.data });
  },

  getSellPartSalaryTypeList({ commit }) {
    const res = StuffProvider.getSellPartSalaryTypeList;
    commit(GET_SELL_PART_SALARY_TYPE_LIST, { item: res.data });
  },

  getRepairPartSalaryType({ commit }) {
    const res = StuffProvider.getRepairPartSalaryType;
    commit(GET_REPAIR_PART_SALARY_TYPE, { item: res.data });
  },

};

export default actions;
