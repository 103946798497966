import { GetterTree } from 'vuex';
import { SalaryState } from './types';

const getters: GetterTree<SalaryState, object> = {
  salaryTransferListByUserId: (state) => state.salaryTransferListByUserId,
  salaryTransferListPageCount: (state) => state.salaryTransferListPageCount,
  salaryOperationListByTransferId:
   (state) => state.salaryOperationListByTransferId,
  stuffWithSalaryBalance: (state) => state.stuffWithSalaryBalance,
};

export default getters;
