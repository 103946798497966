import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PartLogTable from '../part-log-table';

@Component({
  name: 'log',
  template: require('./index.html'),
  components: {
    'part-log-table': PartLogTable,
  },
})

export default class PartLog extends Vue {
    @Getter storages;

    @Action getStorageList;

    activeStorage!: number;

    mounted() {
      this.getStorageList(1);
    }
}
