import { MutationTree } from 'vuex';
import {
  CASH_VALIDATE,

  CLEAR_CASH, CLICK_DETAIL, CREATE_CASHBOX,

  DELETE_CASH_ITEM,
  DELETE_CASH_OPERATIONS, GET_CASHBOX_VIEW,
  GET_CASHOPERATIONS_LIST, GET_CASH_LIST,

  SEND_CASHOPERATION_IN,
  SEND_CASHOPERATION_OUT,
  SEND_CASHOPERATION_TRANSFER,
  StateCash, UPDATE_CASH_ITEM,
} from './types';

const mutations: MutationTree<StateCash> = {

  [GET_CASH_LIST](state, { item, pageCount }) {
    state.cashList = item;
    state.cashListPageCount = pageCount;
  },

  [GET_CASHBOX_VIEW](state, item) {
    state.cashbox = item;
  },

  [CREATE_CASHBOX](state, item) {
    state.cashList.push(item);
  },

  [GET_CASHOPERATIONS_LIST](state, {
    item, pageCount, countIn, countOut, totalIn, totalOut,
  }) {
    state.cashOperationsList = item;
    state.cashOperationPageCount = pageCount;
    state.cashOperationCountIn = countIn;
    state.cashOperationCountOut = countOut;
    state.cashOperationTotalIn = totalIn;
    state.cashOperationTotalOut = totalOut;
  },

  [UPDATE_CASH_ITEM](state, { item }) {
    const index = state.cashList.findIndex((i) => i.id === item.id);
    state.cashList.splice(index, 1, item);
  },

  [DELETE_CASH_ITEM](state, { item }) {
    const index = state.cashList.findIndex((i) => i.id === item.id);
    state.cashList.splice(index, 1);
  },

  [SEND_CASHOPERATION_IN](state, { item }) {
    state.cashOperationIn = item;
  },

  [SEND_CASHOPERATION_OUT](state, { item }) {
    state.cashOperationOut = item;
  },

  [SEND_CASHOPERATION_TRANSFER](state, { item }) {
    state.cashOperationTransfer = item;
  },

  [DELETE_CASH_OPERATIONS](state, { item }) {
    // const index = state.cashOperationsList.findIndex( i => i['id'] === item.id);
    // state.cashOperationsList.splice(index, 1);
    state.cashOperationDelete = item;
  },

  [CASH_VALIDATE](state, { item }) {
    state.cashValidate = item;
  },

  [CLICK_DETAIL](state, item) {
    state.cashViewDetail = item;
  },

  [CLEAR_CASH](state) {
    state.cashOperationsList = null;
    state.cashOperationPageCount = null;
    state.cashOperationCountIn = null;
    state.cashOperationCountOut = null;
    state.cashOperationTotalIn = null;
    state.cashOperationTotalOut = null;
  },

};

export default mutations;
