import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
  props: ['value'],
})

export default class NumberPhone extends Vue {

}
