/* eslint @typescript-eslint/camelcase: "error" */
/* eslint global-require: "error" */
/* eslint no-return-assign: "error" */
/* eslint max-len: "error" */

import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Breadcrumb } from '@/types';
import PartamountfixProvider from '@/api/providers/partamountfix';
import FixAmountBatch from '@/types/fix-amount-batch';
import FixAmountItem from '@/types/fix-amount-item';
import PartAmountProblem from '@/types/part-amount-problem';
import PartAmountCheckStatus from '@/types/part-amount-check-status';
import PartAmountCheckState from '@/types/part-amount-check-state';
import ViewDate from '../../components/forms/date';

@Component({
  /* eslint-disable-next-line global-require */
  template: require('./index.html'),
  components: {
    date: ViewDate,
  },
})
export default class PartAmountProblemsFixPage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    error = '';

    checkStatus: PartAmountCheckStatus|null = null;

    checkStatusRetryInterval = 10;

    checkStatusRetryIn = 0;

    checkStatusRetryTimer: NodeJS.Timer|null = null;

    problems: Array<PartAmountProblemTableRow>|null = null;

    totalProblems = 0;

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Исправление несоответствий остатков', link: null },
    ];

    mounted() {
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Исправление несоответствий остатков';
      this.addActionBtn([]);
      this.loadData();
    }

    async loadData() {
      if (this.checkStatusRetryTimer !== null) {
        clearTimeout(this.checkStatusRetryTimer);
      }
      try {
        // нужно для реактивной отрисовки блока "Идет загрузка данных"
        this.checkStatus = null;
        this.checkStatus = await PartamountfixProvider.getCheckStatus();
      } catch (error) {
        console.error(error);
        this.error = 'Не удалось получить статус проверки остатков';
        this.checkStatus = null;
        this.problems = null;
        return;
      }

      if (this.checkStatus.status === PartAmountCheckState.COMPLETED) {
        try {
          const checkResult = await PartamountfixProvider
            .getPartAmountProblemsList();
          this.totalProblems = checkResult.errors.length;
          const sortedCheckResult = checkResult.errors
            .slice(0, 100)
            .sort((a, b) => {
              if (a.storage?.name === undefined
                || b.storage?.name === undefined) {
                return 0;
              }
              if (a.storage.name > b.storage.name) return 1;
              if (a.storage.name < b.storage.name) return -1;
              if (a.part?.name === undefined
                || b.part?.name === undefined) {
                return 0;
              }
              if (a.part.name > b.part.name) return 1;
              if (a.part.name < b.part.name) return -1;
              return 0;
            });
          this.problems = [];
          for (let i = 0; i < sortedCheckResult.length; i++) {
            this.problems.push({
              problem: sortedCheckResult[i],
              selectedAmount: sortedCheckResult[i].log_amount,
            } as PartAmountProblemTableRow);
          }
        } catch (error) {
          console.error(error);
          this.error = 'Не удалось получить список ошибок остатков';
        }
      } else if (this.checkStatus.status === PartAmountCheckState.PENDING
        || this.checkStatus.status === PartAmountCheckState.RUNNING) {
        this.checkStatusRetryIn = 10;
        this.checkStatusRetryTimer = setInterval(() => {
          this.checkStatusRetryIn -= 1;
          if (this.checkStatusRetryIn <= 0) {
            this.loadData();
          }
        }, 1000);
      }
    }

    clickStartBtn() {
      this.startCheck();
    }

    clickRefreshBtn() {
      this.loadData();
    }

    clickFixProblems() {
      this.sendFixes();
    }

    async startCheck() {
      try {
        this.checkStatus = await PartamountfixProvider.sendStartCheck();
        await this.loadData();
      } catch (error) {
        console.error(error);
      }
    }

    async sendFixes() {
      try {
        const batchFixRequest = {
          items: [],
        } as FixAmountBatch;

        /* eslint-disable @typescript-eslint/camelcase */
        if (Array.isArray(this.problems)) {
          for (let i = 0; i < this.problems?.length; i++) {
            batchFixRequest.items.push({
              part_id: this.problems[i].problem.part_id,
              storage_id: this.problems[i].problem.storage_id,
              amount: this.problems[i].selectedAmount,
            } as FixAmountItem);
          }
        }
        /* eslint-enable @typescript-eslint/camelcase */

        const result = await PartamountfixProvider
          .sendPartAmountProblemsFixes(batchFixRequest);
        if (result.status === 200) {
          this.checkStatus = null;
          this.problems = null;
          await this.loadData();
        } else {
          this.error = 'Произошла непредвиденная ошибка при исправлении '
            + 'остатков. Запустите проверку повторно или обратитесь в '
            + 'техподдержку для уточнения деталей.';
        }
      } catch (error) {
        console.error(error);
        this.error = 'Не удалось запустить проверку. '
          + 'Обратитесь в техподдержку для уточнения деталей.';
      }
    }

    destroyed() {
      this.addActionBtn([]);
    }
}

interface PartAmountProblemTableRow {
  problem: PartAmountProblem;
  selectedAmount: number;
}
