import { GetterTree } from 'vuex';
import { StateCancel } from './types';

const getters: GetterTree<StateCancel, object> = {
  cancel: (state) => state.cancel,
  cancelValid: (state) => state.cancelValid,
  cancelPageCount: (state) => state.cancelPageCount,
  cancelView: (state) => state.cancelView,
  cancelNewItemList: (state) => state.cancelNewItemList,
  applyCancel: (state) => state.applyCancel,
  revertCancel: (state) => state.revertCancel,
};

export default getters;
