import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Numbers from '../forms/numbers';

@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
  },
})

export default class SalaryAmountAndPeriodType extends Vue {
    @Prop() stuffItem;

    @Getter salaryPeriodTypeList;
}
