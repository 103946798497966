/* eslint @typescript-eslint/camelcase: "error" */
/* eslint global-require: "error" */
/* eslint no-return-assign: "error" */
/* eslint max-len: "error" */

import FixAmountBatch from '@/types/fix-amount-batch';
import PartAmountCheckStatus from '@/types/part-amount-check-status';
import PartAmountProblemFixRequestBody from
  '@/types/part-amount-problem-fix-request-body';
import PartAmountProblemsList from '@/types/part-amount-problems-list';
import BaseProvider from '../base-provider';

class PartamountfixProvider extends BaseProvider {
  async getCheckStatus() {
    const res = await this.request('GET', '/partamountproblem/checkstatus');
    return res.data as PartAmountCheckStatus;
  }

  async sendStartCheck() {
    const res = await this.request('POST', '/partamountproblem/startcheck');
    return res.data as PartAmountCheckStatus;
  }

  async getPartAmountProblemsList() {
    const res = await this.request('GET',
      '/partamountproblem/index?expand=part.category_tree,storage');
    return res.data as PartAmountProblemsList;
  }

  async sendPartAmountProblemsFixes(fixes: FixAmountBatch) {
    const data: PartAmountProblemFixRequestBody = {
      FixAmountBatch: fixes,
    };
    const res = await this.request('POST', '/partamountproblem/fix', data);
    return res;
  }
}

export default new PartamountfixProvider();
