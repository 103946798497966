import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import {
  ImportRowAction, ImportRowInfo, UploadForm, Storage,
} from '@/types/';
import ImportProvider from '../../api/providers/import';
import StorageProvider from '../../api/providers/storage';
import ErrorAccessRights from '../../components/error-access-rights';

@Component({
  template: require('./import-inventory.html'),
  components: {
    'error-access-rights': ErrorAccessRights,
  },
})
export default class ImportInventory extends Vue {
    @Action addBreadcrumb;

    @Action showErrorPush;

    @Action showSuccessPush;

    @Getter user;

    get rightsUserCanExport() {
      return this.user.rights ? this.user.rights.can_export : false;
    }

    step = 0;

    globalError = false;

    globalErrorText = '';

    storageList: Storage[] = [];

    uploadForm: UploadForm = {
      file: null,
      storageId: null,
      not_listed_strategy: 0,
    };

    validationResult: ImportRowInfo[] = [];

    showValidationDetails = false;

    get resultErrorsCount() {
      return Array.isArray(this.validationResult)
        ? this.validationResult.filter(
          (x) => x.is_success === false,
        ).length
        : 0;
    }

    get resultInsetrsCount() {
      return Array.isArray(this.validationResult)
        ? this.validationResult.filter(
          (x) => x.is_success && x.action === ImportRowAction.CREATE,
        ).length
        : 0;
    }

    get resultUpdatesCount() {
      return Array.isArray(this.validationResult)
        ? this.validationResult.filter(
          (x) => x.is_success && x.action === ImportRowAction.UPDATE,
        ).length
        : 0;
    }

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: '/settings/info' },
        { id: 2, section: 'Импорт', link: '/service/import' },
        { id: 3, section: 'Импорт инвентаризации из Excel', link: null },
      ]);
      document.title = 'Импорт инвентаризации из Excel';
      this.fetchAllStorages()
        .then((storages: Storage[]) => {
          this.storageList = storages.sort((a, b) => a.name.localeCompare(b.name));
          if (this.storageList.length === 0) {
            this.globalErrorText = 'Не удалось получить список складов';
            this.globalError = true;
            return;
          }
          const currentPointStorages = this.storageList.filter(
            (x) => x.point_id === this.user.point_id,
          );
          if (currentPointStorages.length > 0) {
            this.uploadForm.storageId = currentPointStorages[0].id;
          } else {
            this.uploadForm.storageId = this.storageList[0].id;
          }
        })
        .catch((err) => { this.globalErrorText = err; this.globalError = true; });
    }

    async fetchAllStorages(page = 1) {
      let result = [];
      try {
        const response = await StorageProvider.getStorageList(page);
        result = response.data;
        if (page <= response.pageCount) {
          result.concat(await this.fetchAllStorages(page + 1));
        }
      } catch (e) {
        console.error(e);
      }
      return result;
    }

    onValidate() {
      if ((this.$refs['file-input'] as HTMLInputElement).value.length === 0) {
        this.showErrorPush({ title: 'Не выбран файл для импорта' });
        return;
      }
      this.step = 1;
      this.sendValidationRequest()
        .then((resp) => { this.validationResult = resp?.data as ImportRowInfo[]; })
        .catch((err) => {
          if (typeof (err?.response?.data) === 'object') {
            this.validationResult = err?.response?.data as ImportRowInfo[];
          } else {
            if (typeof (err?.response?.data) === 'string') this.globalErrorText = err.response.data;
            this.globalError = true;
            console.error(err);
          }
        });
    }

    onBackToUpload() {
      this.step = 0;
      this.validationResult = [];
    }

    onImport() {
      this.step = 2;
      this.sendImportRequest()
        .then(() => {
          this.showSuccessPush({ title: 'Файл успешно импортирован.' });
          this.step = 3;
        })
        .catch((err) => {
          console.error(err);
          this.showErrorPush({ title: 'Не удалось выполнить импорт файла.' });
        });
    }

    fileInputChanged(val) {
      this.uploadForm.file = val;
    }

    async sendValidationRequest() {
      const fileInput = this.$refs['file-input'] as HTMLInputElement;
      if (fileInput.files) {
        if (!(this.uploadForm.storageId as number < 0)) {
          if (!(this.uploadForm.not_listed_strategy as number < 0)) {
            try {
              return await ImportProvider.sendInventoryValidation(
                fileInput.files[0],
                (this.uploadForm.storageId as number),
                (this.uploadForm.not_listed_strategy as number),
              );
            } catch (err) {
              const response = err?.response?.data;
              if (response) {
                this.globalErrorText = '';
                if (Array.isArray(response)) {
                  this.globalError = true;
                  response.forEach((res) => {
                    res.errors.forEach((error) => {
                      this.globalErrorText += `${error}`;
                    });
                  });
                } else if ((typeof response === 'object') && response !== null) {
                  Object.values(response).forEach((key) => {
                    (key as string[]).forEach((mess) => {
                      this.globalErrorText += `${mess}`;
                    });
                  });
                  this.globalError = true;
                } else if (typeof response === 'string') {
                  this.globalErrorText = response;
                  this.globalError = true;
                }
              }
              throw new Error(err);
            }
          }
          throw new Error('this.uploadForm.not_listed_strategy is null');
        }
        throw new Error('this.uploadForm.not_listed_strategy is null');
      }
      throw new Error('filesInput is null');
    }

    async sendImportRequest() {
      const fileInput = this.$refs['file-input'] as HTMLInputElement;
      if (fileInput.files) {
        if (this.uploadForm.storageId) {
          if (this.uploadForm.not_listed_strategy) {
            try {
              return await ImportProvider.sendInventoryImport(
                fileInput.files[0],
                this.uploadForm.storageId,
                this.uploadForm.not_listed_strategy,
              );
            } catch (err) {
              throw new Error(err);
            }
          }
          throw new Error('this.uploadForm.not_listed_strategy is null');
        }
        throw new Error('this.uploadForm.not_listed_strategy is null');
      }
      throw new Error('filesInput is null');
    }
}
