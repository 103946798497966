import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import TaskFormComponent from '../../components/task-form';
import { TaskForm } from '../../store/task/types';

@Component({
  template: require('./index.html'),
  components: {
    'task-form': TaskFormComponent,
  },
})
export default class TaskEditPage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action changeTask;

    @Action getTask;

    @Action addTask;

    @Action clearLastSavedTaskId;

    @Getter btnloader;

    @Getter currentTask;

    @Getter lastSavedTaskId;

    loading = true;

    async mounted() {
      this.addActionBtn([]);
      await this.getTask(this.$route.params.id);
      this.addBreadcrumb([
        { id: 1, section: 'Задачи', link: '/task' },
        { id: 2, section: 'Список задач', link: '/task' },
        { id: 3, section: 'Изменение задачи', link: null },
      ]);
      document.title = `Изменение задачи ${this.$route.params.id}`;
    }

    submitTask() {
      ((this.$refs.form as TaskFormComponent).elementTaskForm as Form).validate((valid) => {
        if (valid) {
          const taskFormModel = new TaskForm();
          taskFormModel.title = this.currentTask.title;
          taskFormModel.description = this.currentTask.description;
          taskFormModel.responsible_account_id = this.currentTask.responsible_account_id;
          taskFormModel.priority = this.currentTask.priority;
          taskFormModel.status_id = this.currentTask.status_id;
          if (this.currentTask.deadline) {
            taskFormModel.deadline = Math.round(this.currentTask.deadline / 1000);
          }
          this.changeTask({ id: this.$route.params.id, item: taskFormModel });
          return true;
        }
        return false;
      });
    }

    @Watch('lastSavedTaskId')
    redirect() {
      if (this.lastSavedTaskId !== null) {
        this.$router.replace({ name: 'task-view', params: { id: this.lastSavedTaskId } });
        this.clearLastSavedTaskId();
      }
    }
}
