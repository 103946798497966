import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ApiRepairComment } from '@/api/providers/repair';
import ViewDate from '../../forms/date';
import ViewUserName from '../../forms/user-name';

@Component({
  template: require('./comments.html'),
  components: {
    'comment-date': ViewDate,
    'user-name': ViewUserName,
  },
})
export default class RepairViewComments extends Vue {
  @Getter btnloader;

  @Getter stuff;

  @Getter user;

  formComment = { content: '' };

  isShowDeleted = false;

  @Prop({ default: () => [] }) comments!: Array<ApiRepairComment>;

  /**
   * Добавление комментария
   * @returns Function
   */
  @Prop() onSendRepairComment!: Function;

  /**
  * Удаление комментария
  * @returns Function
  */
  @Prop() onDeleteComment!: Function;

  /**
  * Изменение флага показа комментарий
  * @returns Function
  */
  @Prop() onToogleFlagIncludeDeletedComments!: Function;

  /**
   * Может удалять чужие комментарии
   * @returns boolean
   */
  get canDeleteForeignComments(): boolean {
    return this.user?.rights?.can_delete_foreign_comments
      ? this.user?.rights?.can_delete_foreign_comments : false;
  }

  /**
  * Может удалять свои комментарии
  * @returns boolean
  */
  get canDeleteSelfComments(): boolean {
    return this.user?.rights?.can_delete_self_comments
      ? this.user?.rights?.can_delete_self_comments : false;
  }

  addComment() {
    (this.$refs.form as Form).validate((valid) => (valid
      ? this.onSendRepairComment(this.formComment.content)
      : false));
  }

  resetFormComment() {
    (this.$refs.form as Form).resetFields();
  }

  canDeleteComment(comment: ApiRepairComment) {
    if (comment.is_deleted) {
      return false;
    }
    if (comment.user_id === this.user.id) {
      return this.canDeleteSelfComments;
    }
    if (this.canDeleteForeignComments) {
      return true;
    }
    return false;
  }

  formattingComment(comment) {
    return this.stuff.find((item) => item.id === comment.user_id);
  }
}
