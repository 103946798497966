import BaseProvider from '../base-provider';

class FeedbackProvider extends BaseProvider {
  async readyForFeedback() {
    const res = await this.request('GET', '/feedback/info');
    return res.data;
  }

  async saveFeedback(data) {
    const feedback = { FeedbackForm: data };
    const res = await this.request('POST', '/feedback/save', feedback);
    return res;
  }

  async delayFeedback() {
    const res = await this.request('POST', '/feedback/delay');
    return res;
  }

  async skipFeedback() {
    const res = await this.request('POST', '/feedback/skip');
    return res;
  }
}
export default new FeedbackProvider();
