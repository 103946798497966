const state = {
  cancel: [],
  cancelNewItemList: [],
  cancelPageCurrent: null,
  cancelPageCount: null,
  cancelValid: {},
  cancelView: {},
  applyCancel: {},
  revertCancel: {},
};

export default state;
