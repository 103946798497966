import { MutationTree } from 'vuex';
import {
  DELETE_EQUIPMENTOPTION_LIST, GET_EQUIPMENT_LIST,
  SEND_EQUIPMENTOPTION_LIST,

  SEND_EQUIPMENTOPTION_LIST_PRIORITY, StateEquipmentOption,

  UPDATE_EQUIPMENTOPTION_LIST,
} from './types';

const mutations: MutationTree<StateEquipmentOption> = {
  [GET_EQUIPMENT_LIST](state, { item }) {
    state.equipmentoptionList = item;
  },

  [SEND_EQUIPMENTOPTION_LIST](state, { item }) {
    state.addEquipmentOptionList = item;
  },

  [SEND_EQUIPMENTOPTION_LIST_PRIORITY](state, { item }) {
    state.sendEquipmentOptionPriority = item;
  },

  [UPDATE_EQUIPMENTOPTION_LIST](state, { item }) {
    state.updateEquipmentOptionList = item;
  },

  [DELETE_EQUIPMENTOPTION_LIST](state, { item }) {
    state.deleteEquipmentOptionList = item;
  },
};

export default mutations;
