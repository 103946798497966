import { GetterTree } from 'vuex';
import { StateClient } from './types';

const getters: GetterTree<StateClient, object> = {
  client: (state) => state.client,
  clientInn: (state) => state.clientInn,
  clientPersonValid: (state) => state.clientPersonValid,
  clientCompanyValid: (state) => state.clientCompanyValid,
  viewClient: (state) => state.viewClient,
  pageCount: (state) => state.pageCount,
  pageCurrent: (state) => state.pageCurrent,
  clientVip: (state) => state.clientVip,
  clientRegular: (state) => state.clientRegular,
  clientBlackList: (state) => state.clientBlackList,
  clientCheckBlackList: (state) => state.clientCheckBlackList,
};

export default getters;
