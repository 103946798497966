import { ActionTree } from 'vuex';
import UserStatusProvider from '../../api/providers/user-status';
import { StateUserStatus } from './types';

const actions: ActionTree<StateUserStatus, object> = {

  getUserStatusList({ commit }, page) {
    UserStatusProvider.getUserStatusList(page)
      .then((res) => commit('GET_USER_STATUS', {
        item: res.data,
        pageCount: res.pageCount,
        currentPage: res.currentPage,
      }))
      .catch((err) => console.error(err));
  },

  sendUserStatus({ commit, getters }, item) {
    commit('BTN_LOADER', true);
    UserStatusProvider.sendUserStatus(item)
      .then((res) => {
        const currentPage = getters.userStatusCurrentPage;
        this.dispatch('getUserStatusList', currentPage);
        commit('GET_VALID_USER_STATUS', { item: [] });
        commit('PUSH_CALL_SUCCESS', { title: `Пользовательский статус «${res.data.name}» успешно добавлен`, item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('GET_VALID_USER_STATUS', { item: err.response.data });
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  changeUserStatus({ commit }, item) {
    UserStatusProvider.changeUserStatus(item)
      .then((res) => {
        commit('EDIT_USER_STATUS', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Пользовательский статус «${item.name}» успешно изменён`, item: '' });
        commit('GET_VALID_USER_STATUS', { item: [] });
      })
      .catch((err) => {
        commit('GET_VALID_USER_STATUS', { item: err.response.data });
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  deleteUserStatus({ commit }, id) {
    UserStatusProvider.deleteUserStatus(id)
      .then((res) => {
        commit('DELETE_USER_STATUS', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Пользовательский статус «${res.data.name}» успешно удалён`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

};

export default actions;
