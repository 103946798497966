import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import TaskProvider from '../../api/providers/task';
import TaskCommentHoc from '../../components/task-comment/task-comment-hoc';
import TaskSideBlock from '../../components/task-extra/sideblock';
import { TaskForm } from '../../store/task/types';

@Component({
  template: require('./index.html'),
  components: {
    'task-sideblock': TaskSideBlock,
    'task-components-hoc': TaskCommentHoc,
  },
})
export default class TaskViewPage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action getTask;

    @Getter btnloader;

    @Getter currentTask;

    @Getter user;

    loading = true;

    htmlDescription = '';

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Задачи', link: '/task' },
        { id: 2, section: 'Список задач', link: '/task' },
        { id: 3, section: '...', link: null },
      ]);
      document.title = 'Задача';
      this.getTask(this.$route.params.id);
    }

    @Watch('currentTask')
    loadTask() {
      this.addBreadcrumb([
        { id: 1, section: 'Задачи', link: '/task' },
        { id: 2, section: 'Список задач', link: '/task' },
        { id: 3, section: this.currentTask.title, link: null },
      ]);
      document.title = `Задача ${this.currentTask.title}`;
      if (this.user?.rights?.can_update_foreign_tasks
        || this.currentTask.creator_id === this.user.id) {
        this.addActionBtn([{
          id: 1,
          type: 'edt',
          title: 'Редактировать',
          link: `/task/edit/${this.$route.params.id}`,
          icon: 'fa-edit',
        }]);
      }
      if (this.currentTask.description !== null) {
        this.htmlDescription = String(this.currentTask.description)
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/"/g, '&quot;')
          .replace(/\n/g, '<br/>');
      }
      this.loading = false;
    }

    onTaskChanged(form: TaskForm) {
      this.loading = true;
      TaskProvider.changeTask(this.currentTask.id, form)
        .then(
          () => {
            this.getTask(this.$route.params.id);
            this.loading = false;
          },
        )
        .catch((e) => console.error(e));
    }
}
