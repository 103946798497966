import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { SalaryState } from './types';

export const state: SalaryState = {
  salaryTransferListByUserId: [],
  salaryTransferListPageCount: 0,
  salaryOperationListByTransferId: [],

  stuffWithSalaryBalance: [],
};

export const salary = {
  state,
  getters,
  actions,
  mutations,
};
