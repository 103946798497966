import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { User } from '@/types';
import PointProvieder from '@/api/providers/point';
import ViewUserName from '@/components/forms/user-name';

interface NewItem {
    id: string;
    prefix: string;
    color: string;
    name: string;
    type: string;
    address: string;
    phone: string;
    users: User[];
}
@Component({
  template: require('./index.html'),
  components: {
    ViewUserName,
  },
})
export default class PointForm extends Vue {
  @Getter btnloader;

  @Getter typePoint;

  @Getter stuff;

  @Getter point;

  @Getter validPoint;

  @Action getStuffList

  @Action sendPoint;

  @Action changePoint;

  @Prop() item!: NewItem;

  @Prop() color!: string;

  @Prop() form!: string;

  newItem: NewItem = {
    id: '', prefix: '', color: '', name: '', type: '', address: '', phone: '', users: [],
  };

  get isIndeterminateUsers() {
    const userAccesUsersAmount = this.newItem.users?.length;
    if (userAccesUsersAmount) {
      const allUsersAmount = this.stuff.length;
      if (userAccesUsersAmount === allUsersAmount) {
        this.checkAllUsers = true;
        return false;
      }
      this.checkAllUsers = false;
      return true;
    }
    this.checkAllUsers = false;
    return false;
  }

  checkAllUsers = false;

  mounted() {
    this.getStuffList();
  }

  handleCheckAllUsershange(val: boolean) {
    this.newItem.users = [];
    if (val) {
      this.stuff.forEach((user) => {
        (this.newItem.users as User[]).push(user);
      });
    }
  }

  changeUserAccess(user: User) {
    const userID = user.id;
    const found = this.newItem.users?.find((u) => (u as User).id === userID);
    if (found) {
      this.removeUserTableRowClick(userID as number);
    } else if (this.newItem.users) {
      this.newItem.users.push(user);
    }
  }

  removeUserTableRowClick(userID: number) {
    if (this.newItem.users) {
      this.newItem.users = this.newItem.users.filter(
        (user) => (user as User).id !== userID,
      );
    }
  }

  canWorkWithItUser(scope) {
    const userRowId = scope.row.id;
    const found = this.newItem.users?.find((user) => user.id === userRowId);
    if (found) {
      return true;
    }
    return false;
  }

  sendColor(val) {
    if (val !== 'transparent') this.newItem.color = `#${this.rgbToFlex(val)}`;
  }

  validate = (obj: object, key: string) => {
    if (Object.keys(obj).some((v) => v === key)) {
      return obj[key][0];
    }
    return null;
  }

  resetForm() {
    (this.$refs.pointForm as Form).resetFields();
  }

  rgbToFlex = (val) => {
    let color = val.replace(/\s/g, '');
    const aRGB = color.match(/^rgb\((\d{1,3}[%]?),(\d{1,3}[%]?),(\d{1,3}[%]?)\)$/i);
    if (aRGB) {
      color = '';
      for (let i = 1; i <= 3; i++) {
        const round: number = (aRGB[i][aRGB[i].length - 1] === '%' ? 2.55 : 1) * parseInt(aRGB[i], 10);
        color += Math.round(round).toString(16).replace(/^(.)$/, '0$1');
      }
    } else {
      color = color.replace(/^#?([\da-f])([\da-f])([\da-f])$/i, '$1$1$2$2$3$3');
    }
    return color;
  }

  diffUserList = (user_list1, user_list2) => user_list1.filter(
    (a1) => !user_list2.some(
      (a2) => a1.id === a2.id,
    ),
  ).filter((item) => item)

  requestOnNewPoint(formName) {
    (this.$refs[formName] as Form).validate(async (valid) => {
      if (valid) {
        let pointUsers = [];
        let apiPoint;

        if (this.form === 'add') {
          try {
            apiPoint = (await PointProvieder.sendPoint(this.newItem)).data;
          } catch (e) {
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
            console.error('Ошибка создания филиала', e);
            return false;
          }
        } else {
          try {
            apiPoint = (await PointProvieder.changePoint(this.newItem.id, this.newItem)).data;
          } catch (e) {
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
            console.error('Ошибка изменения филиала', e);
            return false;
          }
        }
        pointUsers = apiPoint.users;

        const newUsersInPoint = this.diffUserList(this.newItem.users, pointUsers);
        const removedUsersInPoint = this.diffUserList(pointUsers, this.newItem.users);

        await newUsersInPoint.forEach(async (x) => {
          try {
            await PointProvieder.addUser({ user_id: x.id, point_id: apiPoint.id });
          } catch (e) {
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка добавления филиала к сотруднику', item: '' });
            console.error('Ошибка добавления филиала к сотруднику', e);
          }
        });
        await removedUsersInPoint.forEach(async (x) => {
          try {
            await PointProvieder.removeUser({ user_id: x.id, point_id: apiPoint.id });
          } catch (e) {
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка отвязки филиала от сотрудника', item: '' });
            console.error('Ошибка отвязки кассы от сотрудника', e);
          }
        });

        if (this.form === 'add') {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Филиал создан', item: '' });
        } else {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Филиал обновлен', item: '' });
        }
        this.$router.push({ path: '/settings/point' });
        return true;
      } return false;
    });
  }

  @Watch('item')
  onChange() {
    if (this.item) this.newItem = this.item;
  }
}
