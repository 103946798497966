import { MutationTree } from 'vuex';
import {
  GET_ACQUIRERS,

  GET_BALANCE, GET_SMS_OFFERS,

  GET_TARIFF,
  SEND_BUY_LICENSE, SEND_BUY_SMS,

  StateBilling,
} from './types';

const mutations: MutationTree<StateBilling> = {

  [GET_SMS_OFFERS](state, { item }) {
    state.smsOffers = item;
  },

  [GET_ACQUIRERS](state, { item }) {
    state.acquirers = item;
  },

  [GET_BALANCE](state, { item }) {
    state.balance = item;
  },

  [GET_TARIFF](state, { item }) {
    state.tariffLicense = item;
  },

  [SEND_BUY_SMS](state, { item }) {
    state.buySms = item;
  },

  [SEND_BUY_LICENSE](state, { item }) {
    state.buyLicense = item;
  },

};

export default mutations;
