import { LogRequest } from '@/types';
import LogProvide from '../log-provider';

class LogRequestProvider extends LogProvide {
  async req(item: LogRequest) {
    const res = await this.request('POST', item);
    return res;
  }
}

export default new LogRequestProvider();
