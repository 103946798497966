const state = {
  documentList: [],
  document: {},
  newDocument: {},
  editDocument: {},
  delDocument: {},
  pageDocumentList: null,

  markersCompany: [
    { label: 'Название', value: '%%НАЗВАНИЕ_ОРГАНИЗАЦИИ%%', id: 1 },
    { label: 'Официальное название', value: '%%ОФИЦИАЛЬНОЕ_НАЗВАНИЕ%%', id: 2 },
    { label: 'Адрес', value: '%%АДРЕС_ОРГАНИЗАЦИИ%%', id: 3 },
    { label: 'Логотип', value: '%%ЛОГОТИП%%', id: 4 },
    { label: 'Телефон', value: '%%ТЕЛЕФОН_ОГРАНИЗАЦИИ%%', id: 5 },
    { label: 'ИНН', value: '%%ИНН%%', id: 6 },
    { label: 'КПП', value: '%%КПП%%', id: 7 },
    { label: 'ОГРН', value: '%%ОГРН%%', id: 8 },
  ],
  markersOrder: [
    { label: '№ заказа', value: '%%ИД_ЗАКАЗА%%', id: 1 },
    { label: 'Ссылка проверки статуса', value: '%%ССЫЛКА_ПРОВЕРКИ%%', id: 2 },
    { label: 'QR для проверки статуса', value: '%%QR_ПРОВЕРКИ%%', id: 2000 },
    { label: 'Штрих-код', value: '%%ШТРИХ_КОД%%', id: 3 },
    { label: 'Штрих-код (увеличенный)', value: '%%ШТРИХ_КОД2%%', id: 4 },
    { label: 'ФИО клиента', value: '%%ФИО_КЛИЕНТА%%', id: 5 },
    { label: 'Инициалы клиента', value: '%%ИНИЦ_КЛИЕНТА%%', id: 6 },
    { label: 'Телефон клиента', value: '%%ТЕЛЕФОН_КЛИЕНТА%%', id: 7 },
    { label: 'Адрес клиента', value: '%%АДРЕС_КЛИЕНТА%%', id: 8 },
    { label: 'ИНН клиента', value: '%%ИНН_КЛИЕНТА%%', id: 9 },
    { label: 'КПП клиента', value: '%%КПП_КЛИЕНТА%%', id: 10 },
    { label: 'ОГРН клиента', value: '%%ОГРН_КЛИЕНТА%%', id: 11 },
    { label: 'БИК банка клиента', value: '%%БИК_КЛИЕНТА%%', id: 12 },
    { label: 'Название банка клиента', value: '%%БАНК_КЛИЕНТА%%', id: 13 },
    { label: 'Р/С клиента', value: '%%РАССЧЕТ_КЛИЕНТА%%', id: 14 },
    { label: 'К/С клиента', value: '%%КОРСЧЕТ_КЛИЕНТА%%', id: 15 },
    { label: 'Тип устройства', value: '%%ТИП_УСТРОЙСТВА%%', id: 16 },
    { label: 'Произоводитель устройства', value: '%%ПРОИЗВОДИТЕЛЬ%%', id: 17 },
    { label: 'Модель устройства', value: '%%МОДЕЛЬ%%', id: 18 },
    { label: 'Срочный заказ', value: '%%СРОЧНЫЙ_ЗАКАЗ%%', id: 19 },
    { label: 'Гарантийный заказ', value: '%%ГАРАНТИЙНЫЙ_ЗАКАЗ%%', id: 20 },
    { label: 'ФИО приемщика', value: '%%ФИО_ПРИЕМЩИКА%%', id: 21 },
    { label: 'Инициалы приемщика', value: '%%ИНИЦ_ПРИЕМЩИКА%%', id: 22 },
    { label: 'Заметки приема', value: '%%ЗАМЕТКИ_ПРИЕМА%%', id: 23 },
    { label: 'Название пункта приема', value: '%%ПУНКТ_ПРИЕМА%%', id: 24 },
    { label: 'Телефон пункта приема', value: '%%ТЕЛЕФОН_ПУНКТА_ПРИЕМА%%', id: 25 },
    { label: 'Адрес пункта приема', value: '%%АДРЕС_ПУНКТА_ПРИЕМА%%', id: 26 },
    { label: 'Неисправность', value: '%%НЕИСПРАВНОСТЬ%%', id: 27 },
    { label: 'Время приема', value: '%%ВРЕМЯ_ПРИЕМА%%', id: 28 },
    { label: 'Дата приема', value: '%%ДАТА_ПРИЕМА%%', id: 29 },
    { label: 'Дата и время приема', value: '%%ДАТА_И_ВРЕМЯ_ПРИЕМА%%', id: 30 },
    { label: 'Состояние', value: '%%СОСТОЯНИЕ%%', id: 31 },
    { label: 'Серийный номер (IMEI)', value: '%%СЕРИЙНЫЙ_НОМЕР%%', id: 32 },
    { label: 'Штрих-код серийного номера', value: '%%ШТРИХ_КОД_СЕРИЙНЫЙ%%', id: 44 },
    { label: 'Комплектация', value: '%%КОМПЛЕКТАЦИЯ%%', id: 33 },
    { label: 'Предоплата (числом)', value: '%%АВАНС%%', id: 34 },
    { label: 'Предоплата (прописью)', value: '%%АВАНС_ТЕКСТ%%', id: 35 },
    { label: 'Ожидаемая дата готовности', value: '%%ОЖИД_ДАТА_ОКОНЧ_РАБОТ%%', id: 36 },
    { label: 'Ожидаемая стоимость', value: '%%ОЖИД_СТОИМОСТЬ%%', id: 37 },
    { label: 'Тип ремонта', value: '%%ТИП_РЕМОНТА%%', id: 38 },
    { label: 'Перемещение - Откуда', value: '%%ПЕРЕМЕЩЕНИЕ_ОТКУДА%%', id: 39 },
    { label: 'Перемещение - Куда', value: '%%ПЕРЕМЕЩЕНИЕ_КУДА%%', id: 40 },
    { label: 'Перемещение - Создал', value: '%%ПЕРЕМЕЩЕНИЕ_СОЗДАЛ%%', id: 41 },
    { label: 'Перемещение - Дата', value: '%%ПЕРЕМЕЩЕНИЕ_ДАТА%%', id: 42 },
    { label: 'Перемещение - Комментарий', value: '%%ПЕРЕМЕЩЕНИЕ_КОММЕНТ%%', id: 43 },

  ],
  markersRepair: [
    { label: 'Обнаруженная неисправность', value: '%%ОБНАРУЖ_НЕИСПР%%', id: 1 },
    { label: 'ФИО мастера', value: '%%ФИО_МАСТЕРА%%', id: 2 },
    { label: 'Инициалы мастера', value: '%%ИНИЦ_МАСТЕРА%%', id: 3 },
    { label: 'ФИО выдал', value: '%%ФИО_ВЫДАЛ%%', id: 4 },
    { label: 'Инициалы выдал', value: '%%ИНИЦ_ВЫДАЛ%%', id: 5 },
    { label: 'Дата готовности', value: '%%ДАТА_ГОТОВО%%', id: 6 },
    { label: 'День выдачи', value: '%%ДАТА_ВЫДАЧИ%%', id: 7 },
    { label: 'Срок гарантии', value: '%%СРОК_ГАРАНТИИ%%', id: 8 },
    { label: 'До какого числа гарантия', value: '%%ГАРАНТИЯ_ДО%%', id: 9 },
    { label: 'Таблица гарантии на запчасти', value: '%%ГАРАНТИЯ_ЗАПЧАСТИ%%', id: 10 },
    { label: 'Таблица гарантии на работы', value: '%%ГАРАНТИЯ_РАБОТЫ%%', id: 11 },
    { label: 'Таблица работ', value: '%%ТАБЛИЦА_РАБОТ%%', id: 12 },
    { label: 'Таблица работ (без цен)', value: '%%ТАБЛИЦА_РАБОТ_БЕЗЦЕН%%', id: 13 },
    { label: 'Таблица запчастей', value: '%%ТАБЛИЦА_ЗАПЧАСТЕЙ%%', id: 14 },
    { label: 'Таблица запчастей (без цен)', value: '%%ТАБЛИЦА_ЗАПЧАСТЕЙ_БЕЗЦЕН%%', id: 15 },
    { label: 'Итог (запчасти)', value: '%%ИТОГО_ЗАПЧАСТИ%%', id: 16 },
    { label: 'Итог (запчасти с НДС)', value: '%%ИТОГО_ЗАПЧАСТИ_С_НДС%%', id: 17 },
    { label: 'Итог (запчасти) (прописью)', value: '%%ИТОГО_ЗАПЧАСТИ_ПРОПИСЬЮ%%', id: 18 },
    { label: 'Итог (запчасти с НДС) (прописью)', value: '%%ИТОГО_ЗАПЧАСТИ_ПРОПИСЬЮ_С_НДС%%', id: 19 },
    { label: 'Итог (работы)', value: '%%ИТОГО_РАБОТЫ%%', id: 20 },
    { label: 'Итог (работы с НДС)', value: '%%ИТОГО_РАБОТЫ_С_НДС%%', id: 21 },
    { label: 'Итог (работы) (прописью)', value: '%%ИТОГО_РАБОТЫ_ПРОПИСЬЮ%%', id: 22 },
    { label: 'Итог (работы с НДС) (прописью)', value: '%%ИТОГО_РАБОТЫ_ПРОПИСЬЮ_С_НДС%%', id: 23 },
    { label: 'Итог (полный)', value: '%%ПОЛНЫЙ_ИТОГ%%', id: 24 },
    { label: 'Итог (полный) (прописью)', value: '%%ИТОГО_ПРОПИСЬЮ%%', id: 25 },
    { label: 'Сумма НДС (общая)', value: '%%СУММА_НДС_ОБЩАЯ%%', id: 26 },
    { label: 'Сумма НДС (работы)', value: '%%СУММА_НДС_РАБОТЫ%%', id: 27 },
    { label: 'Сумма НДС (запчасти)', value: '%%СУММА_НДС_ЗАПЧАСТИ%%', id: 28 },
    { label: 'Сумма скидки', value: '%%СУММА_СКИДКИ%%', id: 29 },
    { label: 'Таблица работ (БУ)', value: '%%ТАБЛИЦА_РАБОТ_БУХ%%', id: 30 },
  ],
  markersSales: [
    { label: '№ продажи', value: '%%ИД_ПРОДАЖИ%%', id: 1 },
    { label: 'Дата продажи', value: '%%ДАТА_ПРОДАЖИ%%', id: 2 },
    { label: 'ФИО продавца', value: '%%ФИО_ПРОДАВЦА%%', id: 3 },
    { label: 'Инициалы продавца', value: '%%ИНИЦ_ПРОДАВЦА%%', id: 4 },
    { label: 'Название филиала продажи', value: '%%ФИЛИАЛ_ПРОДАЖИ_НАЗВАНИЕ%%', id: 39 },
    { label: 'Телефон филиала продажи', value: '%%ФИЛИАЛ_ПРОДАЖИ_ТЕЛЕФОН%%', id: 40 },
    { label: 'Адрес филиала продажи', value: '%%ФИЛИАЛ_ПРОДАЖИ_АДРЕС%%', id: 41 },
    { label: 'ФИО клиента', value: '%%ФИО_КЛИЕНТА%%', id: 5 },
    { label: 'Инициалы клиента', value: '%%ИНИЦ_КЛИЕНТА%%', id: 6 },
    { label: 'Телефон клиента', value: '%%ТЕЛЕФОН_КЛИЕНТА%%', id: 7 },
    { label: 'Адрес клиента', value: '%%АДРЕС_КЛИЕНТА%%', id: 8 },
    { label: 'ИНН клиента', value: '%%ИНН_КЛИЕНТА%%', id: 9 },
    { label: 'КПП клиента', value: '%%КПП_КЛИЕНТА%%', id: 10 },
    { label: 'ОГРН клиента', value: '%%ОГРН_КЛИЕНТА%%', id: 11 },
    { label: 'БИК банка клиента', value: '%%БИК_КЛИЕНТА%%', id: 12 },
    { label: 'Название банка клиента', value: '%%БАНК_КЛИЕНТА%%', id: 13 },
    { label: 'Р/С клиента', value: '%%РАССЧЕТ_КЛИЕНТА%%', id: 14 },
    { label: 'К/С клиента', value: '%%КОРСЧЕТ_КЛИЕНТА%%', id: 15 },
    { label: 'Таблица товаров', value: '%%ТАБЛИЦА_ТОВАРОВ%%', id: 16 },
    { label: 'Таблица услуг', value: '%%ТАБЛИЦА_УСЛУГ%%', id: 17 },
    { label: 'Количество позиций товаров', value: '%%КОЛИЧ_ПОЗИЦ_ТОВАРОВ%%', id: 18 },
    { label: 'Стоимость товаров (с НДС)', value: '%%СТОИМОСТЬ_ТОВАРОВ_С_НДС%%', id: 19 },
    { label: 'Стоимость товаров (с НДС) прописью', value: '%%СТОИМОСТЬ_ТОВАРОВ_С_ПРОПИСЬЮ%%', id: 20 },
    { label: 'Стоимость товаров (без НДС)', value: '%%СТОИМОСТЬ_ТОВАРОВ_БЕЗ_НДС%%', id: 21 },
    { label: 'Стоимость товаров (без НДС) прописью', value: '%%СТОИМОСТЬ_ТОВАРОВ_БЕЗ_НДС_ПРОПИСЬЮ%%', id: 22 },
    { label: 'Сумма НДС товаров', value: '%%СУММА_НДС_ТОВАРЫ%%', id: 23 },
    { label: 'Количество позиций услуг', value: '%%КОЛИЧ_ПОЗИЦ_УСЛУГ%%', id: 24 },
    { label: 'Стоимость услуг (с НДС)', value: '%%СТОИМОСТЬ_УСЛУГ_С_НДС%%', id: 25 },
    { label: 'Стоимость услуг (с НДС)', value: '%%СТОИМОСТЬ_УСЛУГ_С_НДС_ПРОПИЬСЮ%%', id: 26 },
    { label: 'Стоимость услуг (без НДС) прописью', value: '%%СТОИМОСТЬ_УСЛУГ_БЕЗ_НДС%%', id: 27 },
    { label: 'Стоимость услуг (без НДС) прописью', value: '%%СТОИМОСТЬ_УСЛУГ_БЕЗ_НДС_ПРОПИСЬЮ%%', id: 28 },
    { label: 'Сумма НДС услуг', value: '%%СУММА_НДС_УСЛУГИ%%', id: 29 },
    { label: 'Количество наименований', value: '%%КОЛИЧ_НАИМЕН%%', id: 30 },
    { label: 'Общий итог (без НДС)', value: '%%ОБЩИЙ_ИТОГ_БЕЗ_НДС%%', id: 31 },
    { label: 'Общий итог (без НДС) прописью', value: '%%ОБЩИЙ_ИТОГ_БЕЗ_НДС_ПРОПИСЬЮ%%', id: 32 },
    { label: 'Общий итог (с НДС)', value: '%%ОБЩИЙ_ИТОГ_С_НДС%%', id: 33 },
    { label: 'Общий итог (с НДС) прописью', value: '%%ОБЩИЙ_ИТОГ_С_НДС%%_ПРОПИСЬЮ', id: 34 },
    { label: 'Сумма НДС общая', value: '%%СУММА_НДС_ОБЩАЯ%%', id: 35 },
    { label: 'Сумма НДС (общая)', value: '%%СУММА_НДС_ОБЩАЯ%%', id: 36 },
    { label: 'Сумма НДС (работы)', value: '%%СУММА_НДС_РАБОТЫ%%', id: 37 },
    { label: 'Сумма НДС (запчасти)', value: '%%СУММА_НДС_ЗАПЧАСТИ%%', id: 38 },
    { label: 'Сумма скидки', value: '%%СУММА_СКИДКИ%%', id: 39 },
    { label: 'К оплате', value: '%%К_ОПЛАТЕ%%', id: 40 },
    { label: 'К оплате прописью', value: '%%К_ОПЛАТЕ_ПРОПИСЬЮ%%', id: 41 },
  ],
  markersAdditionally: [
    { label: 'Дата печати', value: '%%ДАТА_ПЕЧАТИ%%', id: 1 },
    { label: 'День печати', value: '%%ДАТА_ПЕЧАТИ_ДЕНЬ%%', id: 2 },
    { label: 'Месяц печати (числом)', value: '%%ДАТА_ПЕЧАТИ_МЕСЯЦ%%', id: 3 },
    { label: 'Месяц печати (прописью)', value: '%%ДАТА_ПЕЧАТИ_МЕСЯЦ_ТЕКСТ%%', id: 4 },
    { label: 'Год печати', value: '%%ДАТА_ПЕЧАТИ_ГОД%%', id: 5 },
  ],
  markersSoldProducts: [
    {
      label: 'Наименование', value: '%%НАИМЕНОВАНИЕ%%', tooltip: 'USB Кабель', id: 1,
    },
    {
      label: 'Серийный номер', value: '%%СЕРИЙНЫЙ_НОМЕР%%', tooltip: 'S3R1AL_N0M', id: 2,
    },
    {
      label: 'Цена', value: '%%ЦЕНА%%', tooltip: '500', id: 3,
    },
    {
      label: 'Количество', value: '%%КОЛИЧЕСТВО%%', tooltip: '2', id: 4,
    },
    {
      label: 'Стоимость (без НДС)', value: '%%СТОИМОСТЬ_БЕЗ_НДС%%', tooltip: '820', id: 5,
    },
    {
      label: 'Стоимость (без НДС) прописью', value: '%%СТОИМОСТЬ_БЕЗ_НДС_ПРОПИСЬЮ%%', tooltip: 'Восемьсот двадцать рублей 00 копеек', id: 6,
    },
    {
      label: 'Стоимость (с НДС)', value: '%%СТОИМОСТЬ_С_НДС%%', tooltip: '1000', id: 7,
    },
    {
      label: 'Стоимость (с НДС) прописью', value: '%%СТОИМОСТЬ_С_НДС_ПРОПИСЬЮ%%', tooltip: 'Одна тысяча рублей 00 копеек', id: 8,
    },
    {
      label: 'Текст НДС', value: '%%НДС_ТЕКСТ%%', tooltip: '20% в т.ч.', id: 9,
    },
    {
      label: 'Сумма НДС', value: '%%НДС_СУММА%%', tooltip: '180', id: 10,
    },
    {
      label: 'Описание', value: '%%ОПИСАНИЕ%%', tooltip: 'Кабель USB универсальный 1м черный', id: 11,
    },
    {
      label: 'ШТРИХ-КОД', value: '%%ШТРИХКОД%%', tooltip: '2000000000001', id: 12,
    },
    {
      label: 'Артикул', value: '%%АРТИКУЛ%%', tooltip: 'FM0229', id: 13,
    },
  ],
  markersSoldWorks: [
    {
      label: 'Наименование', value: '%%НАИМЕНОВАНИЕ%%', tooltip: 'Чистка ПК', id: 1,
    },
    {
      label: 'Цена', value: '%%ЦЕНА%%', tooltip: '500', id: 2,
    },
    {
      label: 'Количество', value: '%%КОЛИЧЕСТВО%%', tooltip: '2', id: 3,
    },
    {
      label: 'Стоимость (без НДС)', value: '%%СТОИМОСТЬ_БЕЗ_НДС%%', tooltip: '820', id: 4,
    },
    {
      label: 'Стоимость (без НДС) прописью', value: '%%СТОИМОСТЬ_БЕЗ_НДС_ПРОПСИЬЮ%%', tooltip: 'Восемьсот двадцать рублей 00 копеек', id: 5,
    },
    {
      label: 'Стоимость (с НДС)', value: '%%СТОИМОСТЬ_С_НДС%%', tooltip: '1000', id: 6,
    },
    {
      label: 'Стоимость (с НДС) прописью', value: '%%СТОИМОСТЬ_С_НДС_ПРОПИСЬЮ%%', tooltip: 'Одна тысяча рублей 00 копеек', id: 7,
    },
    {
      label: 'Текст НДС', value: '%%НДС_ТЕКСТ%%', tooltip: '20% в т.ч.', id: 8,
    },
    {
      label: 'Сумма НДС', value: '%%НДС_СУММА%%', tooltip: '180', id: 9,
    },
    {
      label: 'ШТРИХ-КОД', value: '%%ШТРИХКОД%%', tooltip: '2000000000001', id: 10,
    },
  ],
  markersSparePart: [
    { label: 'Название (до 40 знаков)', value: '%%НАИМЕНОВАНИЕ%%', id: 1 },
    { label: 'Название (полное)', value: '%%НАИМЕНОВАНИЕ_ПОЛНОЕ%%', id: 2 },
    { label: 'Цена продажи', value: '%%ЦЕНА_ПРОДАЖИ%%', id: 3 },
    { label: 'Цена продажи (целое)', value: '%%ЦЕНА_ПРОДАЖИ_ЦЕЛ%%', id: 4 },
    { label: 'Цена в ремонт', value: '%%ЦЕНА_РЕМОНТ%%', id: 5 },
    { label: 'Цена в ремонт (целое)', value: '%%ЦЕНА_РЕМОНТ_ЦЕЛ%%', id: 6 },
    { label: 'Описание', value: '%%ОПИСАНИЕ%%', id: 7 },
    {
      label: 'Артикул', value: '%%АРТИКУЛ%%', tooltip: '1000', id: 8,
    },
    { label: 'ШТРИХ-КОД', value: '%%ШТРИХКОД%%', id: 9 },
    { label: 'ШТРИХ-КОД (уменьшенный)', value: '%%ШТРИХ_МАЛ%%', id: 10 },
    { label: 'ШТРИХ-КОД (для наклеек 30*20)', value: '%%ШТРИХКОД30%%', id: 11 },
    { label: 'НДС', value: '%%НДС%%', id: 12 },
  ],
  markersServiceWork: [
    { label: 'Название', value: '%%НАИМЕНОВАНИЕ%%', id: 1 },
    { label: 'Цена продажи', value: '%%ЦЕНА_ПРОДАЖИ%%', id: 2 },
    { label: 'Цена продажи (целое)', value: '%%ЦЕНА_ПРОДАЖИ_ЦЕЛ%%', id: 3 },
    { label: 'Цена в ремонт', value: '%%ЦЕНА_РЕМОНТ%%', id: 4 },
    { label: 'Цена в ремонт (целое)', value: '%%ЦЕНА_РЕМОНТ_ЦЕЛ%%', id: 5 },
    { label: 'ШТРИХ-КОД', value: '%%ШТРИХКОД%%', id: 6 },
    { label: 'ШТРИХ-КОД (для наклеек 30*20)', value: '%%ШТРИХКОД30%%', id: 7 },
    { label: 'НДС', value: '%%НДС%%', id: 8 },
  ],
  markersCustomFields: [],
};

export default state;
