import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import RepairLog from '@/types/repair-log';
import RepairProvider from '../../../api/providers/repair';
import LogItem from './__log-item';

@Component({
  template: require('./log.html'),
  components: {
    'log-item': LogItem,
  },
})
export default class RepairVeiwLog extends Vue {
  logs: RepairLog[] = [];

  get id() { return Number(this.$route.params.id); }

  get logsRepair() {
    if (this.logs.length !== 0) return this.logs;
    return [];
  }

  mounted() {
    this.loadAllLogs();
  }

  async loadAllLogs() {
    const firstPage = await RepairProvider.getRepairLogList(1, { repair_id: this.id });
    const otherPagesPromises: Promise<{
      data: [RepairLog];
      pageCount: number;
      pageCurrent: number;
    }>[] = [];
    for (let i = 2; i <= firstPage.pageCount; i++) {
      otherPagesPromises.push(RepairProvider.getRepairLogList(i, { repair_id: this.id }));
    }
    const otherPages = await Promise.all(otherPagesPromises);
    const logs = firstPage.data;
    otherPages.forEach((x) => {
      logs.concat(x.data);
    });
    this.logs = logs;
  }
}
