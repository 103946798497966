export const GET_BUGOPTION_LIST = 'GET_BUGOPTION_LIST';
export const SEND_BUGOPTION_LIST = 'SEND_BUGOPTION_LIST';
export const UPDATE_BUGOPTION_LIST = 'UPDATE_BUGOPTION_LIST';
export const DELETE_BUGOPTION_LIST = 'DELETE_BUGOPTION_LIST';
export const SEND_BUGOPTION_LIST_PRIORITY = 'SEND_BUGOPTION_LIST_PRIORITY';

// Store

export interface StateBugOption {
    bugoptionList: object[];
    addBugOptionList: object;
    updateBugOptionList: object;
    deleteBugOptionList: object;
    sendBugOptionPriority: object;
}
