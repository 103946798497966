import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { User } from '@/types';
import ViewDate from '../forms/date';
import { TaskComment } from './types';

@Component({
  template: require('./task-comment-item.html'),
  components: {
    date: ViewDate,
  },
})
export default class TaskCommentItem extends Vue {
    @Prop() comment!: TaskComment;

    @Prop() user!: User;

    /**
    * Удаление комментария
    * @returns Function
    */
    @Prop() deleteComment!: Function;

    /**
    * Может удалять чужие комментарии
    * @returns boolean
    */
    get canDeleteForeignComments(): boolean {
      return this.user?.rights?.can_delete_foreign_comments
        ? this.user?.rights?.can_delete_foreign_comments : false;
    }

    /**
    * Может удалять свои комментарии
    * @returns boolean
    */
    get canDeleteSelfComments(): boolean {
      return this.user?.rights?.can_delete_self_comments
        ? this.user?.rights?.can_delete_self_comments : false;
    }

    canDeleteComment() {
      if (this.comment.is_deleted) {
        return false;
      }
      if (this.comment.user_id === this.user.id) {
        return this.canDeleteSelfComments;
      }
      if (this.canDeleteForeignComments) {
        return true;
      }
      return false;
    }
}
