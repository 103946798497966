import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { PushInterface } from '../../store/push/types';

interface NotificationOptions {
  title: string;
  item: string;
}
@Component({
  template: require('./push.html'),
})
export default class PushComponent extends Vue {
    @Getter error!: PushInterface[];

    @Getter success!: PushInterface[];

    @Getter warning!: PushInterface[];

    @Getter info!: PushInterface[];

    @Watch('error')
    onChangeError(val: NotificationOptions) {
      this.$notify.error({
        title: val.title,
        message: val.item,
        position: 'bottom-right',
      });
    }

    @Watch('success')
    onChangeSuccess(val: NotificationOptions) {
      this.$notify.success({
        title: val.title,
        message: val.item,
        position: 'bottom-right',
      });
    }

    @Watch('warning')
    onChangeWarning(val: NotificationOptions) {
      this.$notify.warning({
        title: val.title,
        message: val.item,
        position: 'bottom-right',
      });
    }

    @Watch('info')
    onChangeInfo(val: NotificationOptions) {
      this.$notify.info({
        title: val.title,
        message: val.item,
        position: 'bottom-right',
      });
    }
}
