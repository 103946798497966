import { CashboxSearchForm } from '@/types';
import BaseProvider from '../base-provider';

class CashProvider extends BaseProvider {
  async getAllCashes(ignore_visibility_filter: boolean | null = null) {
    let cashboxes: Array<ApiCashbox> = [];
    let page = 1;
    let pageCount = 1;
    const pageResult = await this.getCashList(page, { ignore_visibility_filter });
    pageCount = pageResult.pageCount;
    cashboxes = [...cashboxes, ...pageResult.data];
    page += 1;
    if (pageCount > 1) {
      const promiseResults: object[] = [];
      for (page; page <= pageCount; page++) {
        promiseResults.push(this.getCashList(page, { ignore_visibility_filter }));
      }
      await Promise.all(promiseResults)
        .then((results) => {
          (results as ApiCashboxResponse[]).forEach((cashPage) => {
            cashboxes = [...cashboxes, ...cashPage.data];
          });
        });
    }
    return cashboxes;
  }

  async getCashList(
    page,
    search: CashboxSearchForm = {
      ignore_visibility_filter: null,
    },
  ): Promise<ApiCashboxResponse> {
    const expand: string[] = ['sum', 'point', 'users'];
    const searchForm: string = this.buildUrl({ CashboxSearchForm: search });
    const res = await this.request('GET', `/cashbox/index?page=${page}&expand=${expand}${searchForm}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getCash(id, expand = []) {
    const res = await this.request('GET', `/cashbox/view?id=${id}&expand=${expand}`);
    return { data: res.data };
  }

  async getCashOperation(page, item, perPage) {
    const expand: string[] = ['cashbox_sum_after', 'creator', 'cashbox', 'act_type_name', 'custom_act_type', 'deleter'];
    const searchForm: string = this.buildUrl({ CashOperationSearchForm: item });
    const res = await this.request('GET', `/cashoperation/index?page=${page}&expand=${expand}${searchForm}&per-page=${perPage}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      countIn: res.headers['x-cashoperations-count-in'],
      countOut: res.headers['x-cashoperations-count-out'],
      totalIn: res.headers['x-cashoperations-total-in'],
      totalOut: res.headers['x-cashoperations-total-out'],
    };
  }

  async CashboxAddUserForm(item: ApiCashboxUserActionForm) {
    const data: object = { CashboxAddUserForm: item };
    const res = await this.request('POST', '/cashbox/adduser', data);
    return res;
  }

  async CashboxRemoveUserForm(item: ApiCashboxUserActionForm) {
    const data: object = { CashboxRemoveUserForm: item };
    const res = await this.request('POST', '/cashbox/removeuser', data);
    return res;
  }

  async sendCashOperationIn(item) {
    const data: object = { CashInForm: item };
    const expand: string[] = ['cashbox_sum_after', 'creator', 'cashbox', 'act_type_name'];
    const res = await this.request('POST', `/cashoperation/in?expand=${expand}`, data);
    return res;
  }

  async sendCashOperationOut(item) {
    const data: object = { CashOutForm: item };
    const expand: string[] = ['cashbox_sum_after', 'creator', 'cashbox', 'act_type_name'];
    const res = await this.request('POST', `/cashoperation/out?expand=${expand}`, data);
    return res;
  }

  async sendCashOperationTransfer(item) {
    const data: object = { CashTransferForm: item };
    const expand: string[] = ['cashbox_sum_after', 'creator', 'cashbox', 'act_type_name'];
    const res = await this.request('POST', `/cashoperation/transfer?expand=${expand}`, data);
    return res;
  }

  async createCashbox(item) {
    const data: object = { Cashbox: item };
    const expand: string[] = ['sum', 'point'];
    const res = await this.request('POST', `/cashbox/add?expand=${expand}`, data);
    return res;
  }

  async changeCashItem(item) {
    const data: object = { Cashbox: item };
    const expand: string[] = ['sum', 'point', 'users'];
    const res = await this.request('POST', `/cashbox/update?id=${item.id}&expand=${expand}`, data);
    return res;
  }

  async deleteCashItem(id) {
    const res = await this.request('POST', `/cashbox/delete?id=${id}`);
    return res;
  }

  async deletedCashOperation(id) {
    const res = await this.request('POST', `/cashoperation/delete?id=${id}`);
    return res;
  }
}

export default new CashProvider();

export interface ApiCashbox {
    cashless: boolean;
    commission: number;
    enabled: boolean;
    id: number;
    name: string;
    point: object; // todo: описать объект point
    point_id: number;
    sum: number;
}
export interface ApiCashboxResponse {
    data: Array<ApiCashbox>;
    pageCount: number;
}

export interface ApiCashboxUserActionForm {
    user_id: number;
    cashbox_id: number;
}
