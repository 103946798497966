import BaseProvider from '../base-provider';

class CompanyProvider extends BaseProvider {
  async getCompanyInfo() {
    const res = await this.request('GET', '/service/index');
    return {
      data: res.data,
    };
  }

  async changeCompanyInfo(item) {
    const data: object = { Service: item };
    const res = await this.request('POST', '/service/update', data);
    return res;
  }

  async getBilling() {
    const res = await this.request('GET', '/billing/companyinfo');
    return {
      data: res.data,
    };
  }

  async changeBilling(item) {
    const data: object = { BillingInfo: item };
    const res = await this.request('POST', '/billing/companyinfo', data);
    return res;
  }

  async getLocalePresets() {
    try {
      return await this.request('GET', '/service/localepresets');
    } catch (err) {
      throw new Error(err);
    }
  }

  async getCurrencies() {
    try {
      return await this.request('GET', '/service/currencies');
    } catch (err) {
      throw new Error(err);
    }
  }

  async getSettings() {
    try {
      return await this.request('GET', '/service/settings');
    } catch (err) {
      throw new Error(err);
    }
  }

  async updateSettings(item) {
    const data: object = { ServiceSettings: item };
    const res = await this.request('POST', '/service/updatesettings', data);
    return res;
  }
}

export default new CompanyProvider();
