import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { User } from '../../store/stuff/types';
import { TaskStatus } from '../../store/task-status/types';
import { TaskSearchApiForm } from '../../store/task/types';

@Component({
  template: require('./task-search-form.html'),
})
export default class TaskSearchForm extends Vue {
    @Prop() taskStatuses!: TaskStatus[];

    @Prop() stuff!: User[];

    @Prop() form!: TaskSearchApiForm;

    orderedStuff: User [] = [];

    @Watch('stuff')
    sortStuff() {
      if (this.stuff === undefined) return;
      this.orderedStuff = this.stuff
        .map((x) => x)
        // Проверить правильно ли работает сортировка: добавил if (a && b) и return 0
        .sort((a, b) => {
          if (a && b) {
            if (a.last_name < b.last_name) return -1;
            if (a.last_name > b.last_name) return 1;
            if (a.last_name === b.last_name) {
              if (a.first_name < b.first_name) return -1;
              if (a.first_name > b.first_name) return 1;
              if (a.first_name === b.first_name) {
                if (a.middle_name < b.middle_name) return -1;
                if (a.middle_name > b.middle_name) return 1;
                if (a.middle_name === b.middle_name) return 0;
              }
            }
          } return 0;
        });
    }

    onSearch() {
      this.form.page = 1;
      this.$emit('onFormSubmit', this.form);
    }
}
