import isEqual from 'lodash/isEqual';
import { debounce } from 'throttle-debounce';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  Breadcrumb, Cashbox, Posting, Provider,
} from '@/types';
import CashProvider from '../../api/providers/cash';
import PostingProvider from '../../api/providers/posting';
import ProviderProvider from '../../api/providers/provider';
import StorageProvider from '../../api/providers/storage';
import ViewDate from '../../components/forms/date';
import Numbers from '../../components/forms/numbers';
import ViewUserName from '../../components/forms/user-name';
import ModalCatalog from '../../components/modal-catalog';
import ProviderForm from '../../components/provider-form';
import TableStorage from '../../components/table-storage';
import WarehouseTable from '../../components/warehouse-table';

interface FormPosting {
  storage_id?;
  provider_id;
  cash_id;
  parts;
}

@Component({
  template: require('./index.html'),
  components: {
    date: ViewDate,
    'user-name': ViewUserName,
    numbers: Numbers,
    'warehouse-table': WarehouseTable,
    'modal-catalog': ModalCatalog,
    'table-storage': TableStorage,
    'provider-form': ProviderForm,
  },
})

export default class PostingAddPage extends Vue {
    @Action addBreadcrumb;

    @Getter user;

    form: FormPosting = {
      storage_id: null, parts: [], cash_id: null, provider_id: null,
    };

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Поступления на склад', link: '/part/posting' }, { id: 3, section: 'Новое поступление', link: null }];

    loading = false;

    loading_save_posting = false;

    provider_name = '';

    cash_name = '';

    cash_list: Cashbox[] = [];

    provider_list: Provider[] = [];

    provider_list_page_count: number | null = null;

    page_api_provider = 1;

    posting_item_list: Posting[] = [];

    posting_parts_list: object[] = [];

    storage_list: object[] = [];

    loading_form_provider = false;

    form_provider: object = {};

    view_form_provider = false;

    flag_for_draft_form = true;

    view_modal_catalog = true;

    // api

    sendProvider(item) {
      this.loading_form_provider = true;
      ProviderProvider.sendProvider(item)
        .then((res) => {
          this.form_provider = {};
          this.loading_form_provider = false;
          this.provider_list.push(res.data);
          this.view_form_provider = false;

          this.form.provider_id = res.data.id;
          this.provider_name = res.data.name;

          this.$store.commit('PUSH_CALL_SUCCESS', { title: `${res.data.name} успешно добавлен`, item: '' });
        })
        .catch((err) => {
          console.error(err);
          this.form_provider = {};
          this.loading_form_provider = false;
          this.view_form_provider = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    async getStorageList(page) {
      await StorageProvider.getStorageList(page)
        .then((res) => {
          this.storage_list = res.data;

          const default_storage = res.data.find((storage) => {
            if (storage.point_id) {
              return storage.point_id === this.user.point_id;
            }
            return undefined;
          });

          if (default_storage) {
            this.form.storage_id = default_storage.id;
          } else {
            this.form.storage_id = res.data[0].id;
          }
        }).catch((err) => console.error(err));
    }

    sendPostingPart(item) {
      PostingProvider.sendPostingPart(item)
        .then((res) => {
          this.posting_item_list.push(res.data);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар добавлен в поступление', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    sendPostingItem(item) {
      this.posting_item_list.push(item);
      this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар добавлен в поступление' });
    }

    deletePostingItem(item) {
      const index = this.posting_item_list.findIndex((i) => i.id === item.id);
      this.posting_item_list.splice(index, 1);

      this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар убран из поступления' });
    }

    sendPosting(item) {
      this.loading_save_posting = true;
      const isValid = this.validatingPosting();
      if (isValid) {
        this.loading_save_posting = false;
        return;
      }
      PostingProvider.sendPosting(item)
        .then((res) => {
          this.loading_save_posting = false;
          this.$router.push({ name: 'view-posting', params: { id: res.data.id } });
          localStorage.removeItem('form_posting');
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Принятие поступления прошло успешно', item: '' });
        }).catch((err) => {
          console.error(err);
          this.loading_save_posting = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    validatingPosting(): boolean {
      let haveError = false;
      if (this.form.parts.length === 0) {
        haveError = true;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Добавьте товар', item: '' });
      }
      if (!this.form.storage_id) {
        haveError = true;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Выберите склад', item: '' });
      }
      return haveError;
    }

    async getCashList() {
      await CashProvider.getAllCashes()
        .then((res) => {
          this.cash_list = res;
          const filterCashListOnPoint = res.find(
            (cash) => cash.point_id === this.user.point_id,
          );

          if (filterCashListOnPoint) {
            this.form.cash_id = filterCashListOnPoint.id;
            this.cash_name = filterCashListOnPoint.name;
          } else {
            this.form.cash_id = null;
            this.cash_name = '';
          }
        }).catch((err) => console.error(err));
    }

    getProviderList(page) {
      ProviderProvider.getProviderList(page)
        .then((res) => {
          this.provider_list_page_count = Number(res.pageCount);

          if (this.page_api_provider <= this.provider_list_page_count) {
            this.page_api_provider += 1;
            this.getProviderList(this.page_api_provider);
            res.data.forEach((provider) => this.provider_list.push(provider));
          }
        }).catch((err) => console.error(err));
    }

    created() {
      this.checkSaveDraftPostingForm();
    }
    // end api

    mounted() {
      this.getProviderList(1);
      this.addBreadcrumb(this.breadcrumb);
      // это для того, чтобы user быстрее загрузился
      (debounce(500, false, () => {
        Promise.all(
          [
            this.getCashList(),
            this.getStorageList(1),
          ],
        )
          .then(() => { this.flag_for_draft_form = false; })
          .catch((e) => console.error(e));
      }))();
      document.title = 'Новое поступление';
    }

    get sum() {
      /* eslint-disable-next-line no-param-reassign */
      return this.form.parts.reduce((sum, current) => sum += current.price * current.amount, 0);
    }

    createFilter = (queryString) => (link) => (link.name.toLowerCase()
      .indexOf(queryString.toLowerCase()) !== -1)

    checkSaveDraftPostingForm() {
      const form: FormPosting = JSON.parse(localStorage.getItem('form_posting') as string);

      if (form) {
        if (isEqual(form, this.form)) {
          localStorage.removeItem('form_posting');
          return;
        }
        this.view_modal_catalog = false;

        this.$confirm('У вас есть несохраненный черновик заполнения формы. Загрузить данные из черновика?', {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'info',
        }).then(() => {
          this.form = { ...form };
          this.posting_item_list = form.parts;
          const provider = this.provider_list.find((item) => form.provider_id === item.id);
          const cash = this.cash_list.find((item) => form.cash_id === item.id);
          if (provider && cash) {
            this.provider_name = provider.name;
            this.cash_name = cash.name;
          } else {
            throw new Error('provider or cash is undefined');
          }
        }).catch(() => {
          localStorage.removeItem('form_posting');
        });
      }
    }

    loadingPartsList(parts) {
      this.posting_parts_list = parts.map((i) => ({
        id: i.id,
        name: i.name,
        part_id: i.id,
        amount: 1,
        art: i.art,
        price: i.posting_price === null ? 0 : i.posting_price,
      }));
    }

    addNewPart(part) {
      const new_part = {
        id: part.id,
        name: part.name,
        part_id: part.id,
        amount: 1,
        art: part.art,
        price: part.posting_price === null ? 0 : part.posting_price,
      };

      this.sendPostingItem(new_part);
    }

    searchProvider(queryString, cb) {
      const items = this.provider_list;
      const results = queryString ? items.filter(this.createFilter(queryString)) : items;
      cb(results);
    }

    searchCash(queryString, cb) {
      const items = this.cash_list;
      const results = queryString ? items.filter(this.createFilter(queryString)) : items;
      cb(results);
    }

    providerSelect(item) {
      this.form.provider_id = item.id;
      this.provider_name = item.name;
    }

    cashSelect(item) {
      this.form.cash_id = item.id;
      this.cash_name = item.name;
    }

    @Watch('posting_item_list')
    onChangeItem(val: object[]) {
      this.form.parts = val;
    }

    @Watch('cash_name')
    cashSelects(item) {
      if (!item) this.form.cash_id = null;
    }

    @Watch('provider_name')
    providerSelects(item) {
      if (!item) this.form.provider_id = null;
    }

    @Watch('form', { deep: true })
    viewFormPosging(form) {
      if (!this.flag_for_draft_form) localStorage.setItem('form_posting', JSON.stringify({ ...form }));
    }
}
