import { Bug } from '@/types';

export const GET_BUG = 'GET_BUG';
export const EDIT_BUG = 'EDIT_BUG';
export const DELETE_BUG = 'DELETE_BUG';
export const INSERT_BUG = 'INSERT_BUG';

// Store

export interface StateBug {
    bug: Bug[];
}

// Models
