import Vue from 'vue';
import { Action } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import PartProvider from '@/api/providers/part';
import WorkProvider from '@/api/providers/work';
import InfoAboutPartOrWork from '@/components/info-about-part-or-work';
import { RepairWork, RepairPart, User } from '@/types';

@Component({
  template: require('./work.html'),
  components: {
    InfoAboutPartOrWork,
  },
})
export default class RepairViewWork extends Vue {
    @Prop({ default: () => [] }) works!: RepairWork[];

    @Prop({ default: () => [] }) parts!: RepairPart[];

    @Action getVatList;

    @Action getRewardTypeList;

    @Action getWarrantTypeList;

    @Action getPartCategory;

    btnloader = false;

    dataForModalView = {};

    showModalViewAboutWorkOrPart = false;

    titleInfoAboutPartOrWork = '';

    get totalSumWorks() {
      return this.works.reduce((total, work) => total + (work.price * work.amount), 0).toFixed(2);
    }

    get totalSumParts() {
      return this.parts.reduce((total, part) => total + (part.price * part.amount), 0).toFixed(2);
    }

    get vatWorks() {
      return this.works.reduce((sum, works) => {
        switch (works.work.vat) {
          case 1: return sum + 0;
          case 2: return sum + 0;
          case 3: return sum + (((works.price * works.amount) / 1.1) * 0.1);
          case 4: return sum + (((works.price * works.amount) / 1.2) * 0.2);
          case 5: return sum + (works.price * 0.1 * works.amount);
          case 6: return sum + (works.price * 0.2 * works.amount);
          default: return sum + 0;
        }
      }, 0).toFixed(2);
    }

    get vatParts() {
      return this.parts.reduce((sum, parts) => {
        switch (parts.part.vat) {
          case 1: return sum + 0;
          case 2: return sum + 0;
          case 3: return sum + (((parts.price * parts.amount) / 1.1) * 0.1);
          case 4: return sum + (((parts.price * parts.amount) / 1.2) * 0.2);
          case 5: return sum + (parts.price * 0.1 * parts.amount);
          case 6: return sum + (parts.price * 0.2 * parts.amount);
          default: return sum + 0;
        }
      }, 0).toFixed(2);
    }

    get totalPartsWithVat() {
      return this.parts.reduce((sum, parts) => {
        switch (parts.part.vat) {
          case 5: return sum + (parts.price * 1.1 * parts.amount);
          case 6: return sum + (parts.price * 1.2 * parts.amount);
          default: return sum + (parts.price * parts.amount);
        }
      }, 0).toFixed(2);
    }

    get totalWorksWithVat() {
      return this.works.reduce((sum, works) => {
        switch (works.work.vat) {
          case 5: return sum + (works.price * 1.1 * works.amount);
          case 6: return sum + (works.price * 1.2 * works.amount);
          default: return sum + (works.price * works.amount);
        }
      }, 0).toFixed(2);
    }

    mounted() {
      this.getVatList();
      this.getRewardTypeList();
      this.getWarrantTypeList();
    }

    viewingWork(row) {
      try {
        this.btnloader = true;
        WorkProvider.getViewWork(row.work.id)
          .then((res) => {
            this.titleInfoAboutPartOrWork = 'Информация об услуге';
            this.dataForModalView = res.data;
            this.btnloader = false;

            this.getVatList();
            this.getRewardTypeList();
            this.getWarrantTypeList();
            this.getPartCategory('work');

            this.showModalViewAboutWorkOrPart = true;
          })
          .catch((error) => {
            this.btnloader = false;
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные об услуге', item: '' });
            throw new Error(error);
          });
      } catch (err) {
        this.btnloader = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        throw new Error(err);
      }
    }

    viewingPart(row) {
      try {
        this.btnloader = true;
        PartProvider.getViewPart(row.part.id)
          .then((res) => {
            this.titleInfoAboutPartOrWork = 'Информация о товаре';
            this.dataForModalView = res.data;
            this.btnloader = false;
            this.getVatList();
            this.getRewardTypeList();
            this.getWarrantTypeList();
            this.getPartCategory('part');

            this.showModalViewAboutWorkOrPart = true;
          })
          .catch((error) => {
            this.btnloader = false;
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о товаре', item: '' });
            throw new Error(error);
          });
      } catch (err) {
        this.btnloader = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        throw new Error(err);
      }
    }

    closeInfoAboutPartOrWork() {
      this.showModalViewAboutWorkOrPart = false;
    }

    formattingStuff = (stuff: User) => {
      if (stuff !== undefined) {
        const lastName: string = stuff.last_name ? stuff.last_name : '';
        const firstName: string = stuff.first_name ? `${stuff.first_name.slice(0, 1).toUpperCase()}.` : '';
        const middleName: string = stuff.middle_name ? `${stuff.middle_name.slice(0, 1).toUpperCase()}.` : '';
        return `${lastName} ${firstName} ${middleName}`;
      }
      return '-';
    }
}
