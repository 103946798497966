import { TaskPriority } from '@/types';

export default class TaskForm {
  title!: string;

  description!: string;

  priority!: TaskPriority;

  status_id!: number;

  creator_id!: number;

  deadline!: number;

  responsible_account_id!: number;
}
