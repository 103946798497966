import BaseProvider from '../base-provider';

class NoteOptionProvider extends BaseProvider {
  async getNoteOptionList(page) {
    const res = await this.request('GET', `/noteoption/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendNoteOptionList(item) {
    const data: object = { NoteOption: item };
    const res = await this.request('POST', '/noteoption/add', data);
    return res;
  }

  async sendNoteOptionSetPrioroty(item) {
    const data: object = { PriorityForm: item };
    const res = await this.request('POST', '/noteoption/setpriority', data);
    return res;
  }

  async changeNoteOptionList(item) {
    const data: object = { NoteOption: item };
    const res = await this.request('POST', `/noteoption/update?id=${item.id}`, data);
    return res;
  }

  async deleteNoteOptionList(item) {
    const data: object = { NoteOption: item };
    const res = await this.request('POST', `/noteoption/delete?id=${item.id}`, data);
    return res;
  }
}

export default new NoteOptionProvider();
