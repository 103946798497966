import BaseProvider from '../base-provider';

class BillingProvider extends BaseProvider {
  async getSmsOffers() {
    const res = await this.request('GET', '/billing/smsoffers');
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getBalance() {
    const res = await this.request('GET', '/billing/balance');
    return res;
  }

  async getBillingAcquirers() {
    const res = await this.request('GET', '/billing/acquirers');
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getBillingTariff() {
    const res = await this.request('GET', '/billing/tariff');
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendBuySms(item) {
    const data: object = { BuySmsForm: item };
    const res = await this.request('POST', '/billing/buysms', data);
    return res;
  }

  async sendBuyLicense(item) {
    const data: object = { BuyLicenseForm: item };
    const res = await this.request('POST', '/billing/buylicense', data);
    return res;
  }

  async sendBalancePayment(sum: number, acquirer_id: number) {
    const data: object = {
      BalancePaymentForm: { sum, acquirer_id },
    };
    const res = await this.request('POST', '/billing/balancepayment', data);
    return res;
  }

  async getBalanceTransactions(page = 1) {
    const res = await this.request('GET', `/billing/transactionslist?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getBalanceTransactionsWithFilter(form, page) {
    const searchForm: string = this.buildUrl({ BalanceTransactionSearchForm: form });
    const res = await this.request('GET', `/billing/transactionslist?page=${page}&${searchForm}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }
}

export default new BillingProvider();
