import { SalaryOperation } from '@/types';

export const SET_SALARY_OPERATION_UNPAID_LIST_BY_USER_ID = 'SET_SALARY_OPERATION_UNPAID_LIST_BY_USER_ID';
export const SET_SALARY_TRANSFER_LIST_BY_USER_ID = 'SET_SALARY_TRANSFER_LIST_BY_USER_ID';
export const SET_SALARY_OPERATION_LIST_BY_TRANSFER_ID = 'SET_SALARY_OPERATION_LIST_BY_TRANSFER_ID';
export const SET_STUFF_WITH_SALARY_BALANCE = 'SET_STUFF_WITH_SALARY_BALANCE';
export const CHANGE_DETAIL_SALARY_OPERATION = 'CHANGE_DETAIL_SALARY_OPERATION';
export const SET_SALARY_TRANSFER_LIST_PAGE_COUNT = 'SET_SALARY_TRANSFER_LIST_PAGE_COUNT';

export interface SalaryState {
    salaryTransferListByUserId: SalaryTransfer[];
    salaryTransferListPageCount: number;
    salaryOperationListByTransferId: SalaryOperation[];

    stuffWithSalaryBalance: UserWithSalaryBalance[];
}

export interface SalaryTransfer {
    id: number;
    user_id: number;
    comment: string;
    created_at: number;
    total_sum: number;
    user: object;
}

export interface UserWithSalaryBalance {
    salaryBalance: string;
    id?;
}
