import BaseProvider from '../base-provider';

class ExportProvider extends BaseProvider {
  async getExportList(page) {
    const res = await this.request('GET', `/export/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async requestRepairExport(item) {
    const data: object = { RepairExportForm: item };
    const res = await this.request('POST', '/export/repairs', data);
    return res;
  }

  async requestClientExport(item) {
    const data: object = { ClientExportForm: item };
    const res = await this.request('POST', '/export/clients', data);
    return res;
  }

  async requestPartNomenklaturaExport(item) {
    const data: object = { PartNomenklaturaExportForm: item };
    const res = await this.request('POST', '/export/partnomenklatura', data);
    return res;
  }

  async requestWorkNomenklaturaExport(item) {
    const data: object = { WorkNomenklaturaExportForm: item };
    const res = await this.request('POST', '/export/worknomenclatura', data);
    return res;
  }

  async requestPartAmountExport(item) {
    const data: object = { PartAmountExportForm: item };
    const res = await this.request('POST', '/export/partamount', data);
    return res;
  }

  async requestСashoperationsExport(item) {
    const data: object = { CashOperationExportForm: item };
    const res = await this.request('POST', '/export/cashoperations', data);
    return res;
  }

  async requestSmsExport(item) {
    const data: object = { SmsExportForm: item };
    const res = await this.request('POST', '/export/sms', data);
    return res;
  }
}

export default new ExportProvider();
