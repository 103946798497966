import { GetterTree } from 'vuex';
import { TaskStatusState } from './types';

const getters: GetterTree<TaskStatusState, object> = {
  taskStatuses: (state) => state.statuses,
  validTaskStatus: (state) => state.validStatus,
  pageStatusCount: (state) => state.pageStatusCount,
  pageStatusCurrent: (state) => state.pageStatusCurrent,
};

export default getters;
