import BaseProvider from '../base-provider';

class AnalyticsProvider extends BaseProvider {
  async getAnalyticsWidgets(page) {
    const res = await this.request('GET', `/analytics/widgets?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getAnalyticsDashboard(page) {
    const res = await this.request('GET', `/analytics/dashboard?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async updateDashboard(item) {
    const data: object = { DashboardForm: item };
    const res = await this.request('POST', '/analytics/updatedashboard', data);
    return res;
  }

  async getFullgetAnalyticsWidgets(page = 1) {
    let result = [];
    const pageResult = await this.getAnalyticsWidgets(page);
    result = result.concat(pageResult.data);
    if (pageResult.pageCount > page) {
      result = result.concat(await this.getFullgetAnalyticsWidgets(page + 1));
    }
    return result;
  }

  async getFullAnalyticsDashboard(page = 1) {
    let result = [];
    const pageResult = await this.getAnalyticsDashboard(page);
    result = result.concat(pageResult.data);
    if (pageResult.pageCount > page) {
      result = result.concat(await this.getFullAnalyticsDashboard(page + 1));
    }
    return result;
  }
}

export default new AnalyticsProvider();
