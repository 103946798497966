import Vue from 'vue';
import { Form } from 'element-ui';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  Barcode, Photo, Part, PartImage,
} from '@/types';
import DialogsCategory from '../__category/dialog';

interface ViewDialogCategory {
  view: boolean;
  type: string;
}
interface NewCategory {
  item: {
    id: number;
  };
}

@Component({
  template: require('./index.html'),
  components: {
    'dialog-add-category': DialogsCategory,
  },
})
export default class PartForm extends Vue {
    @Prop() item!: Part;

    @Prop() validPart!: object;

    @Prop() photo!: Photo;

    @Prop() barcodes!: Barcode[];

    @Action getVatList;

    @Action getRewardTypeList;

    @Action getWarrantTypeList;

    @Action getPartCategory;

    @Action sendPartCategory;

    @Getter vatlist;

    @Getter warrantTypeList;

    @Getter rewardTypeList;

    @Getter categoriesMainList;

    @Getter categoryList;

    @Getter btnloader;

    @Getter newCategory;

    @Getter user;

    photoPreview = '';

    photoError = '';

    newPhoto = '';

    barcodeItemId = 1;

    viewDialogCategory: ViewDialogCategory = { view: false, type: 'add' };

    dataCategory: object = { name: '' };

    get viewForm() {
      return this.$refs.partForm;
    }

    get rightsUserCanManageWorksAndPartsRewards() {
      return this.user.rights ? this.user.rights.can_manage_works_and_parts_rewards : false;
    }

    get isPartDeleted(): boolean {
      return this.item.enabled === false;
    }

    categorySelectList() {
      return this.flattery(this.categoriesMainList);
    }

    /**
     * Преобразует массив объектов в плоский набор
     * @param parent Массив объектов категорий
     * @param previousRoute строка предыдущего пути
     */
    private flattery(parent, previousRoute = '') {
      const result: object[] = [];
      parent.forEach((element) => {
        const route = (previousRoute !== '') ? `${previousRoute} / ${element.title}` : element.title;
        result.push({ id: element.key, value: route });
        if (element.children.length > 0) {
          result.push(...this.flattery(element.children, route));
        }
      });
      return result;
    }

    mounted() {
      this.getVatList();
      this.getRewardTypeList();
      this.getWarrantTypeList();
      this.getPartCategory('part');
    }

    showFileInput = () => {
      const fileInput: HTMLElement | null = document.getElementById('file-load');
      if (fileInput !== null) {
        fileInput.click();
      }
    }

    requestOnAddCategory() {
      ((this.$refs.formCategory as DialogsCategory).formCategory as Form).validate(
        (valid) => (valid ? this.sendPartCategory(this.dataCategory) : false),
      );
    }

    addImages(value) {
      const file = value.target.files[0];
      if (!this.photoTypeCheck(file)) {
        this.photoError = 'Неверный формат изображения';
      } else if (!this.photoSizeCheck(file)) {
        this.photoError = 'Размер изображения не должен превышать 5мб';
      } else {
        const reader = new FileReader();
        const preview = (res) => {
          this.photoPreview = res;
        };
        reader.onload = function onloading(event) {
          if (event.target) {
            preview(event.target.result);
          } else {
            throw new Error('event.target is null or undefined');
          }
        };
        reader.readAsDataURL(file);
        this.photoError = '';
        this.photo.file = file;
      }
    }

    photoSizeCheck = (img) => img.size < 5242880

    photoTypeCheck = (img) => {
      let type = false;
      switch (img.type) {
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
          type = true;
          break;
        default: break;
      }
      return type;
    }

    addNewBarcodeItem() {
      const item = { id: this.barcodeItemId, value: '', auto_generated: true };
      this.barcodes.push(item);
      this.barcodeItemId += 1;
    }

    deleteNewBarcodeItem(index) {
      this.barcodes.splice(index, 1);
    }

    checkControlNumber(index) {
      const { value } = this.barcodes[index];
      let even = 0;
      let notEven = 0;
      for (let i = 1; i < value.length - 1; i += 2) {
        even += Number(value[i]);
        notEven += Number(value[i - 1]);
      }
      const sum = even * 3 + notEven;
      const res = Math.ceil(sum / 10) * 10 - sum;
      if (value.length === 13 && res !== Number(value[12])) {
        return 'Неверное контрольное число';
      } if (value.length && !Number(value)) {
        return 'Штрих-код должен быть числом';
      }
      return '';
    }

    validate = (obj: object, key: string) => {
      if (Object.keys(obj).some((v) => v === key)) {
        return obj[key][0];
      }
      return null;
    }

    validatePrice = (rule, value, callback) => {
      if (value === null || value === undefined) {
        callback();
      } else {
        const floatVal = Number.parseFloat(value);
        if (floatVal < 0) {
          callback(new Error('Цена не может быть меньше 0'));
        } else {
          callback();
        }
      }
    }

    validateReward = (rule, value, callback) => {
      if (value === null || value === undefined) {
        callback();
      } else {
        const floatVal = Number.parseFloat(value);
        if (floatVal < 0) {
          callback(new Error('Вознаграждение не может быть меньше 0'));
        } else {
          callback();
        }
      }
    }

    validateBarcodes(obj, index) {
      if (Object.keys(obj).some((v) => v === 'barcodes')) {
        if (typeof (obj.barcodes[0][index]) !== 'boolean' && obj.barcodes[0][index] !== undefined) {
          return obj.barcodes[0][index].value[0];
        }
      } else {
        return this.checkControlNumber(index);
      }
      return false;
    }

    @Watch('barcodes')
    viewBarcodes(barcodes) {
      this.item.barcodes = barcodes;
    }

    @Watch('newCategory')
    addNewCategory(category: object) {
      this.viewDialogCategory.view = false;
      this.item.cat_id = (category as NewCategory).item.id;
    }

    @Watch('item')
    setPhotoPreview() {
      if (this.item !== undefined && Object.prototype.hasOwnProperty.call(this.item, 'images') && this.item.images !== null && this.photoPreview === '') {
        this.photoPreview = (this.item.images as PartImage).medium;
      }
    }
}
