import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
})
export default class ChangingInputSelection extends Vue {
  @Prop({ default: () => '' }) selectPropText!: string;

  @Prop({ default: () => 0 }) selectPropValue!: number;

  @Prop({ default: () => [] }) selectOptions;

  selectValue = this.selectPropValue;

  isInputActivate = false;

  deactivateInput() {
    this.isInputActivate = false;
    if (this.selectPropValue !== this.selectValue) {
      this.saveInputChanging();
    }
  }

  activateInput() {
    this.isInputActivate = true;
  }

  saveInputChanging() {
    this.$emit('inputSelectChanging', this.selectValue);
  }

  @Watch('selectPropValue')
  syncInputText() {
    this.selectValue = this.selectPropValue;
  }
}
