import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { User, Point } from '@/types';
import ErrorAccessRights from '../../components/error-access-rights';
import NumberPhone from '../../components/forms/number-phone';
import Numbers from '../../components/forms/numbers';

@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    phone: NumberPhone,
    'error-access-rights': ErrorAccessRights,
  },
})
export default class PointPage extends Vue {
  @Getter point;

  @Getter typePoint;

  @Getter pointPageCount;

  @Getter user;

  @Action getPointListOnThePage;

  @Action addBreadcrumb;

  @Action addActionBtn;

  @Action deletePoint;

  dialogView = false;

  dialogDelete = false;

  pointView: object = {};

  loading = true;

  get pointHasAccess() {
    return this.point.filter((p) => p.has_access);
  }

  get rightsUserCanManagePoints() {
    return this.user.rights ? this.user.rights.can_manage_points : false;
  }

  beforeMount() {
    this.getPointListOnThePage({ page: 1 });
  }

  mounted() {
    this.addBreadcrumb([
      { id: 1, section: 'Компания', link: null },
      { id: 2, section: 'Настройки', link: null },
      { id: 3, section: 'Филиалы', link: null },
    ]);
    document.title = 'Филиалы';
    if (this.user.rights && this.user.rights.can_manage_points) {
      this.addActionBtn([
        {
          id: 1, type: 'add', title: 'Новый филиал', link: '/settings/point/add', icon: 'fa-plus',
        },
      ]);
    }
  }

  destroyed() {
    this.addBreadcrumb([]);
    this.addActionBtn([]);
  }

  loadPage(val) {
    this.getPointListOnThePage({ page: val });
  }

  canDeletePoint(pointView: Point) {
    if (pointView.users?.length) {
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Невозможно удалить филиал с сотрудниками', item: '' });
    } else {
      this.deletePoint(pointView.id);
    }
    this.dialogDelete = false;
  }

  viewPoint(val, column) {
    if (column.type !== 'actions') {
      this.pointView = val;
      this.dialogView = !this.dialogView;
    }
  }

  onDialogDelete(item) {
    this.pointView = item;
    this.dialogDelete = !this.dialogDelete;
  }

  @Watch('point')
  onLoad() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.loading = false;
    });
  }

  @Watch('user')
  meUser(user: User) {
    if (user.rights) {
      if (user.rights.can_manage_points) {
        this.addActionBtn([
          {
            id: 1, type: 'add', title: 'Новый филиал', link: '/settings/point/add', icon: 'fa-plus',
          },
        ]);
      } else {
        this.addActionBtn([]);
      }
    }
  }
}
