import { GetterTree } from 'vuex';
import { StateCompany } from './types';

const getters: GetterTree<StateCompany, object> = {
  company: (state) => state.company,
  billing: (state) => state.billing,
  settings: (state) => state.settings,
  country: (state) => state.country,
};

export default getters;
