import Vue from 'vue';
import VueRouter from 'vue-router';
import CashListPage from '@/views/cash';
import CashViewPage from '@/views/cash-view';
// Components
import Licenses from '@/components/licenses';
import Payments from '@/components/payments';
import BalanceList from '@/components/balance-list';
import Error404Component from '../components/404';
import AboutComponent from '../components/about';
// Авторизация
import Auth from '../components/auth';
import Bug from '../components/bug';
import Cancel from '../components/cancel';
import CancelAdd from '../components/cancel-add';
import { CancelView } from '../components/cancel-view';
import Inventory from '../components/inventory';
import InventoryAdd from '../components/inventory-add';
import InventoryView from '../components/inventory-view';
// Раздел компания - справочники
import ReferenceComponent from '../components/reference';
// Раздел Ремонт
import RepairPage from '../views/repair-page';
import RepairTransfers from '../components/repair-transfers';
import RepairView from '../components/repair-view';
import RepairWorkrepair from '../components/repair-workrepair';
import RepairFormAdd from '../components/repair/form/add';
import RepairFormClone from '../components/repair/form/clone';
import RepairFormEdit from '../components/repair/form/edit';
import Sell from '../components/sell';
// Раздел компания - настройки
import Setting from '../components/setting';
import Work from '../components/work';
import WorkView from '../components/work/view';
import state from '../store/auth/state';
// Раздел "Аналитика"
import Analytics from '../views/analytics';
import BalanceBuy from '../views/balance-buy';
import CashTypesPage from '../views/cash-types';
import ClientSourcePage from '../views/client-source';
import ClientsPage from '../views/clients';
import ClientsAddPage from '../views/clients-add';
import ClientsEditPage from '../views/clients-edit';
import ClientsViewPage from '../views/clients-view';
import CompanyPage from '../views/company';
import CustomFieldUpdate from '../views/custom-field-update';
import DevicePage from '../views/device';
import Export from '../views/export';
import Import from '../views/import';
import ImportClients from '../views/import-clients';
import ImportInventory from '../views/import-inventory';
import ImportParts from '../views/import-parts';
import ImportPosting from '../views/import-posting';
import ImportWorks from '../views/import-works';
import { Integrations } from '../views/integrations';
import BillingPage from '../views/billing';
import ManageFormPage from '../views/manageform';
import ManagerFormViewPage from '../views/manager-form-view';
// Раздел Склад
import NomenklaturaPage from '../views/nomenklatura';
import PapersPage from '../views/papers';
import ViewPapersPage from '../views/papers/__view';
import PartPage from '../views/part';
import PartAddPage from '../views/part-add';
import PartBuildPrice from '../views/part-buildprice';
import PartEditPage from '../views/part-edit';
// Смена пароля
import PasswordChange from '../views/password-change';
import PointPage from '../views/point';
import PointAddPage from '../views/point-add';
import PointEditPage from '../views/point-edit';
import PostingPage from '../views/posting';
import PostingAddPage from '../views/posting-add';
import PostingViewPage from '../views/posting-view';
import PricesPage from '../views/prices';
import ProviderPage from '../views/provider';
import ProviderAddPage from '../views/provider-add';
import ProviderEditPage from '../views/provider-edit';
import RepairCreateTransfer from '../views/repair-createtransfer';
import RepairsPage from '../views/repairs';
// Раздел Отчеты
// import  Report  from './../components/report';
// import  ReportCurrent  from './../components/report/current';
// import  ReportEconomy  from './../components/report/economy';
// import  ReportStuff  from './../components/report/stuff';
// import  ReportPoint  from './../components/report/point';
// import  ReportCashPage  from './../views/report-cash/index';
// import  ReportPartuse  from './../components/report/partuse';
// import  ReportMarketing  from './../components/report/marketing';
// import  Feedback  from './../components/feedback';
import ReportViewPage from '../views/report-view';
// Финансы
import SalaryPage from '../views/salary';
import SalaryTransferViewPage from '../views/salary-transfer-view';
import SalaryViewPage from '../views/salary-view';
import { SellAddPage } from '../views/sell-add';
// Раздел компания
import SmsPages from '../views/sms';
import SmsTemplatesPage from '../views/sms-templates';
import StoragePage from '../views/storage';
import StuffPage from '../views/stuff';
import StuffAddPage from '../views/stuff-add';
import StuffEditPage from '../views/stuff-edit';
import TaskStatuses from '../views/task-statuses';
// Раздел "Задачи"
import TaskPage from '../views/task';
import TaskAddPage from '../views/task-add';
import TaskEditPage from '../views/task-edit';
import TaskViewPage from '../views/task-view';
import TransferPage from '../views/transfer';
import TransferAddPage from '../views/transfer-add';
import UserStatusPage from '../views/user-status';

import PartAmountProblemsFixPage from '../views/partamountproblemfix';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(
  location,
  onResolve = undefined,
  onReject = undefined,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location, onResolve, (err) => err);
};

const routers = new VueRouter({
  base: __dirname,
  routes: [

    // 404
    { path: '*', component: Error404Component },

    // Авторизация
    { path: '/', component: AboutComponent, redirect: '/repair' },
    {
      path: '/auth',
      component: Auth,
      beforeEnter: (to, from, next) => {
        if (state.auth) {
          next('/');
        } else {
          next();
        }
      },
    },

    { path: '/password-change', name: 'password-change', component: PasswordChange },

    // Заказы

    { path: '/repair', name: 'repair', component: RepairPage },

    // Добавление заказов

    {
      path: '/repair/form/add/', name: 'repair-form-add', component: RepairFormAdd, props: true,
    },
    { path: '/refill/form/add', name: 'refill-form-add', component: RepairFormAdd },

    // Редактирование
    { path: '/repair/form/:id', name: 'repair-form-edit', component: RepairFormEdit },
    { path: '/refill/form/:id', name: 'refill-form-edit', component: RepairFormEdit },

    // Клонирование формы заказа
    { path: '/repair/clone/form', name: 'repair-form-clone', component: RepairFormClone },
    { path: '/refill/clone/form', name: 'refill-form-clone', component: RepairFormClone },

    { path: '/repair/view/:id', name: 'repair-view', component: RepairView },

    { path: '/repair/workrepair/:id', name: 'repair-workrepair', component: RepairWorkrepair },
    { path: '/repair/transfers', name: 'repair-transfers', component: RepairTransfers },
    { path: '/repair/createtransfer', name: 'repair-createtransfer', component: RepairCreateTransfer },

    { path: '/:field/index', name: 'custom-field-update', component: CustomFieldUpdate },

    { path: '/refill/create', name: 'refill-create', component: RepairView },

    { path: '/bug', name: 'bug', component: Bug },

    { path: '/task', name: 'task', component: TaskPage },
    { path: '/task/add', name: 'task-add', component: TaskAddPage },
    { path: '/task/edit/:id', name: 'task-edit', component: TaskEditPage },
    { path: '/task/view/:id', name: 'task-view', component: TaskViewPage },

    { path: '/part', name: 'part', component: PartPage },
    { path: '/part/buildprice', name: 'part-buildprice', component: PartBuildPrice },
    { path: '/part/buildstickers', name: 'part-buildstickers', component: PartBuildPrice },
    {
      path: '/part/edit/:id/:tab?', name: 'part-edit', props: true, component: PartEditPage,
    },
    { path: '/part/add', name: 'part-add', component: PartAddPage },
    { path: '/part/nomenklatura', name: 'nomenklatura', component: NomenklaturaPage },
    { path: '/part/posting', name: 'posting', component: PostingPage },
    { path: '/part/posting/add', name: 'posting-add', component: PostingAddPage },
    { path: '/part/posting/view/:id', name: 'view-posting', component: PostingViewPage },

    { path: '/storage/transfer', name: 'transfer', component: TransferPage },
    { path: '/storage/transfer/add', name: 'transfer-add', component: TransferAddPage },

    { path: '/clients', name: 'clients', component: ClientsPage },
    { path: '/clients/view/:id', name: 'clients-view', component: ClientsViewPage },
    { path: '/clients/add', name: 'clients-add', component: ClientsAddPage },
    { path: '/clients/edit/:id', name: 'clients-edit', component: ClientsEditPage },

    { path: '/work', name: 'work', component: Work },
    { path: '/work/view/:id', name: 'work-view', component: WorkView },
    { path: '/work/add', name: 'work-add', component: WorkView },

    { path: '/cancel', name: 'cancel', component: Cancel },
    { path: '/cancel/add', name: 'cancel-add', component: CancelAdd },
    { path: '/cancel/view/:id', name: 'cancel-view', component: CancelView },

    { path: '/inventory/view/:id/', name: 'inventory-view', component: InventoryView },
    { path: '/inventory/create', name: 'inventory-add', component: InventoryAdd },
    { path: '/inventory/:page', name: 'inventory', component: Inventory },

    { path: '/sell', name: 'sell', component: Sell },
    {
      path: '/sell/add', name: 'sell-add', component: SellAddPage, props: true,
    },
    { path: '/sell/view/:id', name: 'sell-view', component: SellAddPage },

    {
      path: '/reference',
      name: 'reference',
      component: ReferenceComponent,
      redirect: '/reference/device',
      children: [
        { path: 'device', name: 'device', component: DevicePage },
        { path: 'provider', name: 'provider', component: ProviderPage },
        { path: 'cash-types', name: 'cash-types', component: CashTypesPage },
        { path: 'sources', name: 'sources', component: ClientSourcePage },
        { path: 'user-status', name: 'set-user-status', component: UserStatusPage },
        { path: 'task-statuses', name: 'task-statuses', component: TaskStatuses }],
    },
    { path: '/reference/provider/edit/:id', name: 'provider-edit', component: ProviderEditPage },
    { path: '/reference/provider/add', name: 'provider-add', component: ProviderAddPage },
    {
      path: '/settings',
      name: 'settings',
      component: Setting,
      redirect: '/settings/info',
      children: [
        { path: 'info', name: 'set-info', component: CompanyPage },
        { path: 'point', name: 'point', component: PointPage },
        { path: 'storage', name: 'set-storage', component: StoragePage },
        { path: 'repairs', name: 'set-repairs', component: RepairsPage },
        { path: 'prices', name: 'set-prices', component: PricesPage },
        { path: 'notification-templates', name: 'set-sms', component: SmsTemplatesPage },
        { path: 'manageform', name: 'set-manageform', component: ManageFormPage },
        { path: 'integrations', name: 'integrations', component: Integrations },
      ],
    },

    { path: '/settings/manageform/view/:id', name: 'manager-form-view', component: ManagerFormViewPage },
    { path: '/settings/point/edit/:id', name: 'point-edit', component: PointEditPage },
    { path: '/settings/point/add', name: 'point-add', component: PointAddPage },

    { path: '/service/sms', component: SmsPages, name: 'sms' },

    { path: '/service/balance/pay', component: BalanceBuy, name: 'buy-balance' },

    { path: '/service/papers', component: PapersPage, name: 'papers' },
    { path: '/service/papersettings', component: PapersPage, name: 'paper-settings' },
    { path: '/service/editpaper/:id', component: ViewPapersPage, name: 'edit-paper' },
    { path: '/service/addpaper/:type', component: ViewPapersPage, name: 'add-paper' },

    { path: '/service/pricesticker', component: ViewPapersPage, name: 'edit-paper-pricesticker' },
    { path: '/service/repairsticker', component: ViewPapersPage, name: 'edit-paper-repairsticker' },
    { path: '/service/partsticker', component: ViewPapersPage, name: 'edit-paper-partsticker' },
    { path: '/service/worksticker', component: ViewPapersPage, name: 'edit-paper-worksticker' },

    {
      path: '/service/billing',
      component: BillingPage,
      name: 'billing',
      redirect: '/service/billing/payments',
      children: [
        { path: 'payments/:page?', name: 'payments', component: Payments },
        { path: 'licenses/:page?', name: 'licenses', component: Licenses },
        { path: 'balance/:tab/:page?', name: 'balance', component: BalanceList },
      ],
    },
    {
      path: '/service',
      name: 'service',
      component: ReferenceComponent,
      children: [
        { path: 'settings', component: DevicePage, name: 'service-settings' },
      ],
    },
    { path: '/service/export/:type', component: Export, name: 'export' },
    { path: '/service/import', name: 'import', component: Import },
    { path: '/service/import/clients-from-excel', name: 'clients-from-excel', component: ImportClients },
    { path: '/service/import/nomenklature-from-excel', name: 'nomenklature-from-excel', component: ImportParts },
    { path: '/service/import/works-from-excel', name: 'works-from-excel', component: ImportWorks },
    { path: '/service/import/posting-from-excel', name: 'posting-from-excel', component: ImportPosting },
    { path: '/service/import/inventory-from-excel', name: 'inventory-from-excel', component: ImportInventory },
    { path: '/report/view/:report_id', component: ReportViewPage },
    // {
    //     path: '/report',
    //     name: 'report',
    //     component: Report,
    //     redirect: '/report/current',
    //     children: [{
    //         path: 'current',
    //         component: ReportCurrent,
    //         name: 'report-current'
    //     }, {
    //         path: 'economy',
    //         component: ReportEconomy,
    //         name: 'report-economy'
    //     }, {
    //         path: 'stuff',
    //         component: ReportStuff,
    //         name: 'repost-stuff'
    //     }, {
    //         path: 'point',
    //         component: ReportPoint,
    //         name: 'report-point'
    //     }, {
    //         path: 'cash',
    //         component: ReportCashPage,
    //         name: 'report-cash'
    //     }, {
    //         path: 'partuse',
    //         component: ReportPartuse,
    //         name: 'report-partuse'
    //     }, {
    //         path: 'marketing',
    //         component: ReportMarketing,
    //         name: 'report-marketing'
    //     }, {
    //         path: 'feedback',
    //         component: Feedback,
    //         name: 'report-feedback'
    //     }]
    // },
    {
      path: '/salary',
      name: 'salary',
      component: SalaryPage,
    },
    {
      path: '/salary/transfer/:employee_id/:transfer_id',
      name: 'salary-transfer-view',
      component: SalaryTransferViewPage,
    },
    {
      path: '/salary/:tab/:user_id/:page?',
      name: 'salary-view',
      component: SalaryViewPage,
    },
    { path: '/cash', name: 'cash-list', component: CashListPage },
    { path: '/cash/view/:id?', name: 'cash-view', component: CashViewPage },
    { path: '/stuff', name: 'stuff', component: StuffPage },
    { path: '/stuff/add', name: 'stuff-add', component: StuffAddPage },
    { path: '/stuff/edit/:id', name: 'stuff-edit', component: StuffEditPage },

    { path: '/analytics', name: 'analytics', component: Analytics },

    { path: '/partamountproblemfix', name: 'partamountproblemfix', component: PartAmountProblemsFixPage },
  ],
});

routers.beforeEach((to, from, next) => {
  if (to.path !== '/auth') {
    if (!state.auth) {
      next('/auth');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default routers;
