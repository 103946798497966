import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import billing from './billing';
import breadcrumb from './breadcrumb';
// import { provider } from './provider';
// import { form } from './generator-form';
import bug from './bug';
import bugoption from './bugoption';
import cancel from './cancel';
import { cash } from './cash';
import cashTypes from './cash-types';
import category from './category';
import company from './company';
import client from './client';
// import { clientSource } from './clientSource';
import currency from './currency';
import device from './device';
import document from './document';
import equipment from './equipment';
import exports from './exports';
import licenses from './licenses';
import logRequest from './log-request';
import noteoption from './noteoption';
import parts from './part';
import payments from './payments';
import point from './point';
import posting from './posting';
import preloader from './preloader';
import push from './push';
import repair from './repair';
import report from './report';
import { salary } from './salary';
import service from './service';
import shop from './shop';
import sms from './sms';
import smsTemplate from './sms-template';
import stateoption from './stateoption';
import storages from './storage';
import stuff from './stuff';
import task from './task';
import taskStatus from './task-status';
import transfer from './transfer';
import user from './user';
import userStatus from './user-status';
import voip from './voip';
import work from './work';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    breadcrumb,
    parts,
    category,
    push,
    preloader,
    client,
    // clientSource,
    currency,
    // provider,
    // form,
    bug,
    stuff,
    company,
    point,
    storages,
    userStatus,
    sms,
    smsTemplate,
    repair,
    user,
    posting,
    cashTypes,
    device,
    transfer,
    cancel,
    work,
    shop,
    payments,
    licenses,
    logRequest,
    cash,
    salary,
    bugoption,
    stateoption,
    noteoption,
    equipment,
    taskStatus,
    task,
    voip,
    report,
    document,
    exports,
    billing,
    service,
  },
});

export default store;
