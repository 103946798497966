import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { UserFullname, Breadcrumb, ActionBtn } from '@/types';
import CancelFilterForm from '@/components/cancel-filter-form';
import CancelProvider from '../../api/providers/cancel';
import Numbers from '../forms/numbers';

@Component({
  template: require('./index.html'),
  components: {
    numbers: Numbers,
    CancelFilterForm,
  },
})
export default class Cancel extends Vue {
    @Getter user;

    @Action addBreadcrumb;

    @Action addActionBtn;

    loading = true;

    filterForm = null;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Списания', link: null }];

    cancel_list: object[] = [];

    page_count_cancel_list: number | null = null;

    // api
    getCancelList(page) {
      CancelProvider.getCancelList(page, this.filterForm)
        .then((res) => {
          this.cancel_list = res.data;
          this.page_count_cancel_list = Number(res.pageCount);

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    }

    formattingUsers = (user: UserFullname) => {
      if (user) {
        const lastName: string = user.last_name ? user.last_name : '';
        const firstName: string = user.first_name ? `${user.first_name.slice(0, 1).toUpperCase()}.` : '';
        const middleName: string = user.middle_name ? `${user.middle_name.slice(0, 1).toUpperCase()}.` : '';
        return `${lastName} ${firstName} ${middleName}`;
      }
      return '';
    }

    mounted() {
      document.title = 'Списания товаров';
      this.getCancelList(1);
      this.addBreadcrumb(this.breadcrumb);

      if (this.user.rights) {
        if (this.user.rights.can_create_cancel) {
          const actionBtn: ActionBtn[] = [{
            id: 1, type: 'add', title: 'Новое списание', link: '/cancel/add', icon: 'fa-plus',
          }];
          this.addActionBtn(actionBtn);
        }
      }
    }

    destroyed() {
      this.addActionBtn([]);
    }

    loadPage(val) {
      this.loading = true;
      this.getCancelList(val);
      window.scroll(0, 0);
    }

    changeFilter(filterForm) {
      this.filterForm = { ...filterForm };
      this.getCancelList(1);
    }

    date = (value) => {
      const options: object = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
      };
      const date: string = new Date(value * 1000).toLocaleString('ru-RU', options).replace(',', '');
      return date;
    }

    onDetail(row, _, event) {
      if ((window.getSelection() as Selection).toString().trim().length !== 0) return;
      if (event.ctrlKey) {
        this.targetBlankDetail(row.id);
        return;
      }
      this.$router.push(`/cancel/view/${row.id}`);
    }

    targetBlankDetail(cancelId) {
      const route_data = this.$router.resolve({ name: 'cancel-view', params: { id: cancelId } });
      window.open(route_data.href, '_blank');
    }
}
