import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

const category = {
  state,
  getters,
  mutations,
  actions,
};

export default category;
