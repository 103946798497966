import { Input } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Bug, DropDownItem } from '../../../interface';

@Component({
  template: require('./index.html'),
})
export default class FieldBug extends Vue {
    @Prop({ default: () => false }) notLoadValue!: boolean;

    @Prop({ required: true }) repair!: object;

    @Prop({ required: true }) bugList!: object[];

    bug: Bug[] = [];

    bug_drop_down_items: DropDownItem[] = [];

    bug_add_items: Bug [] = [];

    bug_window = false;

    bug_search: object[] = [];

    save_add_item: Bug = {};

    value_bug_item = '';

    focus_complete_field_bug = false;

    controlHiddenItem(item_fields, flag) {
      this.bug_drop_down_items.forEach((drop_down_item, i) => {
        if (drop_down_item.id === item_fields.id) this.bug_drop_down_items[i].classes['custom-fields-none'] = flag;
      });

      this.bug.forEach((bug_item, i) => {
        if (bug_item.id === item_fields.id) this.bug[i].styles['most-common__container-item_disabled'] = flag;
      });
    }

    handlerAddItems(e, item, index) {
      if (this.bug_window && this.bug_search.length !== 0) {
        const item_fields: Bug = { ...item };

        this.controlHiddenItem(item_fields, true);

        this.bug_add_items.push(item_fields);
      } else {
        const copy_item = { ...item };

        this.bug[index].styles['most-common__container-item_disabled'] = true;
        this.bug_drop_down_items[index].classes['custom-fields-none'] = true;
        this.bug_add_items.push(copy_item);
      }

      if (e.target.classList.contains('complete-fields')) {
        this.bug_window = false;
      }

      this.value_bug_item = '';
      this.save_add_item = {};
      this.$root.$emit('valueCustomFields', this.bug_add_items, 'bug');
    }

    removeFieldItem(item, index) {
      const bug = this.bug_add_items[index];

      this.$root.$emit('removeCustomFieldItem', item.text, 'bug');
      this.bug_add_items.splice(index, 1);

      this.controlHiddenItem(bug, false);
    }

    handlerOpenFieldChange(e, index) {
      this.save_add_item = this.bug_add_items[index] ? this.bug_add_items[index] : {};

      this.focus_complete_field_bug = true;

      if (e.target.classList.contains('most-common__container-item')) {
        this.bug_window = true;
        this.value_bug_item = this.bug_add_items[index].text;
        (this.$refs.fieldBug as Input).focus();
        this.filterCustomFields(this.value_bug_item);
        this.bug_add_items.splice(index, 1);
      }
    }

    clearBugItem() {
      this.bug_add_items = [];
      this.bug_drop_down_items = [];
      this.bug = [];
    }

    changeAddItem() {
      const item: Bug = {};

      item.text = this.value_bug_item;

      if (this.bug_add_items.length === 0) {
        item.id = 1;
        item.priority = 1;
      } else {
        const id = this.bug_add_items.map((element) => element.id);
        const priority = this.bug_add_items.map((element) => element.priority);

        item.id = Math.max(...id) + 1;
        item.priority = Math.max(...priority) + 1;
      }

      if (Object.keys(this.save_add_item).length !== 0) {
        this.save_add_item.text = this.value_bug_item;
        this.bug_add_items.push(this.save_add_item);
      } else {
        this.bug_add_items.push(item);
      }

      this.bug_window = false;
      this.value_bug_item = '';
      this.$root.$emit('valueCustomFields', this.bug_add_items, 'bug');
    }

    filterCustomFields(queryString) {
      const filter = this.bug.filter(
        (el) => el.text.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
        || el.text.toUpperCase().indexOf(queryString.toUpperCase()) !== -1,
      );

      this.bug_search = filter;
    }

    changeUpdateItem(text) {
      this.value_bug_item = text;

      if (text) {
        this.bug_window = true;
        this.filterCustomFields(text);
      } else {
        this.bug_window = false;
      }
    }

    focusCustomFieldsBug() {
      if (this.$refs.fieldBug) (this.$refs.fieldBug as Input).focus();
      this.focus_complete_field_bug = true;
    }

    unfocusCustomFieldsBug() {
      this.focus_complete_field_bug = false;
      this.value_bug_item = '';
      this.bug_window = false;
    }

    /* eslint-disable no-param-reassign */
    stylesDisabledCustomFields = (item) => {
      item.forEach((element) => {
        element.styles = { 'most-common__container-item_disabled': false };
        element.classes = { 'custom-fields-none': false };
      });
    }
    /* eslint-enable no-param-reassign */

    handlerBlurUpdateItem() {
      if (this.bug_window && this.save_add_item) {
        this.$root.$emit('valueCustomFields', this.bug_add_items, 'bug');
      }
    }

    keyUpChangeUpdateItem() {
      if (this.value_bug_item) {
        this.changeAddItem();
        this.bug_window = false;
      }
    }

    loadingDataRepairInCustomField(new_val) {
      if (new_val.bug && !this.notLoadValue) {
        new_val.bug.split(',').forEach((element) => {
          this.bug_add_items.push({ text: element.trim() });
        });
      }
    }

    @Watch('bugList')
    watchBugList(list: DropDownItem[]) {
      this.stylesDisabledCustomFields(list);
      list.forEach((element) => {
        this.bug.push(element);
        this.bug_drop_down_items.push(element);
      });
    }

    @Watch('bug_window')
    watchBugWindow(view) {
      if (!view) {
        if (this.bug_add_items.length !== 0) {
          if (!this.focus_complete_field_bug) {
            const item = { ...this.save_add_item };

            this.controlHiddenItem(item, false);
          }
        } else {
          this.stylesDisabledCustomFields(this.bug);
          this.stylesDisabledCustomFields(this.bug_drop_down_items);
        }

        this.save_add_item = {};
      }
    }
}
