import { MutationTree } from 'vuex';
import {
  GET_ACTION_BTN, GET_BREADCRUMB,

  StateBreadcrumb,
} from './types';

const mutations: MutationTree<StateBreadcrumb> = {

  [GET_BREADCRUMB](state, item) {
    state.breadcrumb = item;
  },

  [GET_ACTION_BTN](state, item) {
    state.actionBtn = item;
  },

};

export default mutations;
