import { Task, TaskForm, TaskSearchApiForm } from '@/classes';
import { TaskPriority } from '@/types';

export const GET_TASK_LIST = 'GET_TASK_LIST';
export const GET_TASK = 'GET_TASK';
export const GET_VALID_TASK = 'GET_VALID_TASK';
export const EDIT_TASK = 'EDIT_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const INSERT_TASK = 'INSERT_TASK';
export const UPDATE_LAST_SAVED_TASK_ID = 'UPDATE_LAST_SAVED_TASK_ID';

// Store

export interface TaskState {
    taskList: Task[];
    currentTask: Task;
    lastSavedTaskId: number;
    validTask: object;
    pageTaskCount: number;
    pageTaskCurrent: number;
}

export {
  Task, TaskForm, TaskSearchApiForm, TaskPriority,
};
