import BaseProvider from '../base-provider';

class StateOptionProvider extends BaseProvider {
  async getStateOptionList(page) {
    const res = await this.request('GET', `/stateoption/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async sendStateOptionList(item) {
    const data: object = { StateOption: item };
    const res = await this.request('POST', '/stateoption/add', data);
    return res;
  }

  async sendStateOptionSetPrioroty(item) {
    const data: object = { PriorityForm: item };
    const res = await this.request('POST', '/stateoption/setpriority', data);
    return res;
  }

  async changeStateOptionList(item) {
    const data: object = { StateOption: item };
    const res = await this.request('POST', `/stateoption/update?id=${item.id}`, data);
    return res;
  }

  async deleteStateOptionList(item) {
    const data: object = { StateOption: item };
    const res = await this.request('POST', `/stateoption/delete?id=${item.id}`, data);
    return res;
  }
}

export default new StateOptionProvider();
