import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Config } from '@/types';
import IntegrationsProvider from '../../api/providers/integrations';

interface ConfigProntoSms {
  login: string;
  password: string;
}
@Component({
  template: require('./index.html'),
})
export default class IntegrationEditProntoSms extends Vue {
    @Prop() provider!: object;

    newConfig: Config = {};

    login = '';

    password = '';

    validation = {};

    mounted() {
      this.newConfig = { ...this.provider };
      if (this.newConfig.config !== '"null"') {
        if (this.newConfig.config) {
          this.newConfig.config = JSON.parse(this.newConfig.config);
          this.login = (this.newConfig.config as unknown as ConfigProntoSms).login;
          this.password = (this.newConfig.config as unknown as ConfigProntoSms).password;
        }
      }
      delete this.newConfig.description;
      delete this.newConfig.info_url;
      delete this.newConfig.logo_url;
      delete this.newConfig.name;
    }

    validate = (obj: object, key: string) => {
      if (Object.keys(obj).some((v) => v === key)) {
        return obj[key][0];
      }
      return null;
    }

    saveProviderConfig() {
      this.validation = {};
      if (this.newConfig.sender_name === '' || this.newConfig.sender_name === null) {
        this.validation = Object.assign(this.validation, {
          sender_name: ['Введите Имя Отправителя'],
        });
      }
      if (this.login === '' || this.login === null) {
        this.validation = Object.assign(this.validation, {
          login: ['Введите Логин'],
        });
      }
      if (this.password === '' || this.password === null) {
        this.validation = Object.assign(this.validation, {
          password: ['Введите Пароль'],
        });
      }
      if (JSON.stringify(this.validation) !== '{}') {
        // нужно для срабатывания валидации в верстке
        const changeText = this.login;
        this.login = '1';
        this.login = changeText;
        return;
      }
      this.newConfig.config = JSON.stringify(
        {
          login: this.login,
          password: this.password,
        },
      );
      if (this.newConfig.id === null) {
        IntegrationsProvider.addConfig(this.newConfig)
          .then(() => {
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Провайдер настрон', item: '' });
            this.$emit('updateList');
            this.$emit('closeEditModal');
          })
          .catch((err) => {
            console.error('err', err);
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось настроить провайдера', item: '' });
          });
      } else {
        IntegrationsProvider.updateConfig(this.newConfig)
          .then(() => {
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Провайдер обновлен', item: '' });
            this.$emit('updateList');
            this.$emit('closeEditModal');
          })
          .catch((err) => {
            console.error('err', err);
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось обновить настройки провайдера', item: '' });
          });
      }
    }
}
