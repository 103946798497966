// eslint-disable-next-line import/prefer-default-export
export const FEEDBACK_TIME_LOCAL_STORAGE_KEY = 'next_feedback_time';

export enum REPAIR_TABLE_SORT {
  INCOME_ASK = 'income_date',
  INCOME_DESC = '-income_date',
  DONE_ASK = 'done_at',
  DONE_DESC = '-done_at',
  OUT_ASK = 'out_date',
  OUT_DESC = '-out_date',
  PLANNED_OUT_ASK = 'planned_out_date',
  PLANNED_OUT_DESC = '-planned_out_date',
  CLIENT_NAME_UP = 'client_name',
  CLIENT_NAME_DOWN = '-client_name',
}
export interface Content {
  'per-page': number;
  accepted_point_id: string | number;
  current_point_id: string | number;
  acceptor_id: string | number;
  search_string: string | number;
  master_id: string | number;
  status_ids: string | number;
  created_at_start: string | number;
  created_at_end: string | number;
  done_at_start: string | number;
  done_at_end: string | number;
  ejected_at_start: string | number;
  ejected_at_end: string | number;
  planned_out_date_start: string | number;
  planned_out_date_end: string | number;
  sort: REPAIR_TABLE_SORT;
}

export interface FilterQuery {
  status?: string;
  master?: number;
}

export interface StatusButtons {
  name: string;
  icon: string;
  status: number | null;
  modiff: string;
  active?: boolean;
}
