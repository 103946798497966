import { ActionTree } from 'vuex';
import BugOptionProvider from '../../api/providers/bugoption';
import { StateBugOption } from './types';

const actions: ActionTree<StateBugOption, object> = {

  getBugOptionList({ commit }, page) {
    BugOptionProvider.getBugOptionList(page)
      .then((res) => {
        commit('GET_BUGOPTION_LIST', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  sendBugOptionList({ commit }, item) {
    BugOptionProvider.sendBugOptionList(item)
      .then((res) => {
        commit('SEND_BUGOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendBugOptionSetPrioroty({ commit }, item) {
    BugOptionProvider.sendBugOptionSetPrioroty(item)
      .then((res) => {
        commit('SEND_BUGOPTION_LIST_PRIORITY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Раскладка успешно сохранена', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  changeBugOptionList({ commit }, item) {
    BugOptionProvider.changeBugOptionList(item)
      .then((res) => {
        commit('UPDATE_BUGOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно обновлен', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  deleteBugOptionList({ commit }, item) {
    BugOptionProvider.deleteBugOptionList(item)
      .then((res) => {
        commit('DELETE_BUGOPTION_LIST', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно удалено', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

};

export default actions;
