import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
  components: {},
})

export default class FieldVendor extends Vue {
    @Prop() device!: object;
}
