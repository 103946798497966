import { ActionTree } from 'vuex';
import SmsProvider from '../../api/providers/sms';
import { StateSms } from './types';

const actions: ActionTree<StateSms, object> = {

  getSmsList({ commit }, page) {
    SmsProvider.getSmsList(page)
      .then((res) => commit('GET_SMS', {
        item: res.data,
        pageCount: res.pageCount,
      }))
      .catch((err) => console.error(err));
  },

};

export default actions;
