import RepairProvider from '../../../api/providers/repair';

export default async function getRepairList(this, content, idempotenceKey) {
  this.$store.commit('BTN_LOADER', true);
  await this.distributionExpandsRepairs();
  this.repairLoadPromise = RepairProvider.getRepairList(content.page, content, content['per-page'], this.expands_repairs)
    .then((res) => {
      if (this.repairLoadPromiseId !== idempotenceKey) {
        return;
      }
      this.data_repairs = this.data_repairs.concat(res.data);
      this.page_count_repair = Number(res.pageCount);
      this.page_current = Number(res.pageCurrent);

      this.$store.commit('BTN_LOADER', false);

      setTimeout(() => {
        this.$emit('viewModal');
      }, 1000);

      if (this.page_count_repair > 1) {
        this.$nextTick(() => {
          this.scroll_active = true;
        });
      }
    }).catch((err) => {
      console.error(err);
      this.$store.commit('BTN_LOADER', false);
    });

  this.expands_repairs = ['accepted_point', 'current_point', 'custom_status', 'client', 'last_transfer'];
}
