import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { TaskInterface, User } from '@/types';
import StuffProvider from '../../api/providers/stuff';
import TaskCommentProvider from '../../api/providers/task-comment';
import TaskCommentContainer from './task-comment-container';
import { TaskComment } from './types';

@Component({
  template: require('./task-comment-hoc.html'),
  components: {
    'task-comment-container': TaskCommentContainer,
  },
})
export default class TaskCommentHoc extends Vue {
    @Prop() task!: TaskInterface;

    loading = true;

    showDeleted = false;

    stuff: User[] = [];

    rawComments: TaskComment[] = [];

    get comments() {
      return this.showDeleted ? this.rawComments : this.rawComments.filter((x) => !x.is_deleted);
    }

    showDeletedChanged(newVal: boolean) {
      this.showDeleted = newVal;
    }

    @Watch('task')
    loadData() {
      Promise.all(
        [
          this.loadAllStuff(),
          this.loadComments(),
        ],
      )
        .then(() => { this.loading = false; })
        .catch((e) => console.error(e));
    }

    async loadAllStuff() {
      const result = await StuffProvider.getFullStuffList();
      this.stuff.push(...result);
    }

    deleteComment(comment: TaskComment) {
      TaskCommentProvider.deleteComment(comment.id)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Комментарий успешно удален' });
          this.rawComments.forEach((c, index) => {
            if (c.id === comment.id) this.rawComments[index].is_deleted = true;
          });
        })
        .catch((res) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось удалить комментарий' });
          throw new Error(res);
        });
    }

    async loadComments() {
      if (this.task === undefined) {
        return;
      }
      const result = await TaskCommentProvider.getAllTaskCommentsForTaskId(this.task.id);
      this.rawComments = result;
    }
}
