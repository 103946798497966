import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TaskInterface } from '@/types/';
import TaskStatusBage from './task-status-bage';
import { TaskStatusInterface } from '../../store/task-status/types';

@Component({
  template: require('./task-status-selector.html'),
  components: {
    TaskStatusBage,
  },
})

export default class TaskStatusSelector extends Vue {
  @Prop() choosedTask!: TaskInterface;

  @Prop() allStatuses!: TaskStatusInterface[];

  /**
   * Передает событие на изменение нового статуса
   * @param  {TaskStatusInterface} newStatus
   */
  changeStatus(newStatus: TaskStatusInterface) {
    this.$emit('changeStatus', newStatus, this.choosedTask);
  }
}
