import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { TaskPriority } from '@/types';

@Component({
  template: require('./priority.html'),
})
export default class TaskPriorityComponent extends Vue {
    @Prop() priority!: TaskPriority;

    @Prop() editable!: boolean;

    baseClass = 'task-priority';

    lowPriorityClass = 'task-priority_low';

    normalPriorityClass = 'task-priority_normal';

    highPriorityClass = 'task-priority_high';

    editablePriorityClass = 'task-priority_editable';

    get allClasses() {
      const result = [this.baseClass];
      if (this.editable) result.push(this.editablePriorityClass);
      switch (this.priority) {
        case TaskPriority.LOW:
          result.push(this.lowPriorityClass);
          break;
        case TaskPriority.NORMAL:
          result.push(this.normalPriorityClass);
          break;
        case TaskPriority.HIGH:
          result.push(this.highPriorityClass);
          break;
        default: break;
      }
      return result;
    }

    get priorityName() {
      switch (this.priority) {
        case TaskPriority.LOW:
          return 'Низкий';
        case TaskPriority.NORMAL:
          return 'Обычный';
        case TaskPriority.HIGH:
          return 'Высокий';
        default:
          return '???';
      }
    }

    getStarClass(i: number) {
      const classes = ['fa-star'];
      if (i > this.priority) {
        classes.push('far');
      } else {
        classes.push('fas');
      }
      return classes;
    }

    checkStarShouldBeRendered(i: number) {
      return this.editable || i <= this.priority;
    }

    starClickHandle(i: number) {
      if (this.editable) {
        this.$emit('taskPriorityChanged', i);
      }
    }
}
