import Vue from 'vue';
import { Route } from 'vue-router';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ErrorAccessRights from '../../components/error-access-rights';
import ExportForm from '../../components/export-form';
import ExportLog from '../../components/export-log';

@Component({
  template: require('./index.html'),
  components: {
    'log-table': ExportLog,
    'export-form': ExportForm,
    'error-access-rights': ErrorAccessRights,
  },
})
export default class Export extends Vue {
  @Action addBreadcrumb;

  @Action getStuffList;

  @Getter stuff;

  @Getter user;

  tabs = '';

  get viewExportContent() {
    return this.$route.params.type;
  }

  get rightsUserCanExport() {
    return this.user.rights ? this.user.rights.can_export : false;
  }

  mounted() {
    this.addBreadcrumb([
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Экспорт', link: null },
    ]);
    document.title = 'Экспорт';
    this.getStuffList(1);
  }

  switchHistory(active) {
    this.tabs = active;
  }

  @Watch('tabs')
  switchTabs(tab: string) {
    switch (Number(tab)) {
      case 0: this.$router.push({ name: 'export', params: { type: 'request' } }); break;
      case 1: this.$router.push({ name: 'export', params: { type: 'log' } }); break;
      default: break;
    }
  }

  @Watch('$route', { immediate: true })
  viewRouter(route: Route) {
    this.tabs = route.params.type === 'request' ? '0' : '1';
  }
}
