const state = {
  posting: [],
  postingAdd: {},
  postingView: {},
  postingPageCurrent: null,
  postingPageCount: null,
  postingValid: [],
  postingNewItemList: [],
  postingChangePart: {},
  postingRevert: {},
};

export default state;
