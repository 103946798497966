import { TaskComment } from '@/components/task-comment/types';
import BaseProvider from '../base-provider';

class TaskCommentProvider extends BaseProvider {
  async getAllTaskCommentsForTaskId(taskId: number): Promise<TaskComment[]> {
    const { data, pageCount } = await this.getTaskCommentsForTaskId(taskId, 1);
    let comments = data;
    if (pageCount > 1) {
      const nextPages: Promise<{ data: TaskComment[]; pageCount: number }>[] = [];
      for (let i = 0; i < pageCount; i++) {
        nextPages.push(this.getTaskCommentsForTaskId(taskId, i));
      }
      const results = await Promise.all(nextPages);
      results.forEach((batch) => {
        comments += batch.data;
      });
    }
    return comments;
  }

  async getTaskCommentsForTaskId(taskId: number, page: number) {
    const res = await this.request('GET', `/taskcomment/index?page=${page}&TaskCommentSearchForm[task_id]=${taskId}&TaskCommentSearchForm[include_deleted]=1`);
    return {
      data: res.data,
      pageCount: Number.parseInt(res.headers['x-pagination-page-count'], 10),
    };
  }

  async sendTaskComment(taskId: number, content: string) {
    const data: object = { TaskComment: { task_id: taskId, content } };
    const res = await this.request('POST', '/taskcomment/add', data);
    return res;
  }

  async deleteComment(commentId: number) {
    const res = await this.request('POST', `/taskcomment/delete?id=${commentId}`);
    return res;
  }
}

export default new TaskCommentProvider();
