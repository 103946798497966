import { ActionTree } from 'vuex';
import RepairProvider from '../../api/providers/repair';
import { StateRepair } from './types';

const actions: ActionTree<StateRepair, object> = {

  getRepairList({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.getRepairList(item.page, item, item['per-page'])
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('GET_REPAIR', {
          item: res.data,
          pageCount: res.pageCount,
          pageCurrent: res.pageCurrent,
        });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
      });
  },

  clearRepairList({ commit }) {
    commit('CLEAR_REPAIR_LIST');
  },

  getRepairPage({ commit }, item) {
    RepairProvider.getRepairList(item.page, item, item['per-page'])
      .then((res) => commit('GET_REPAIR_PAGE', {
        item: res.data,
        pageCount: res.pageCount,
        pageCurrent: res.pageCurrent,
      }))
      .catch((err) => console.error(err));
  },

  getViewRepair({ commit }, id) {
    commit('BTN_LOADER', true);
    RepairProvider.getViewRepair(id)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('GET_REPAIR_VIEW', {
          item: res.data,
        });
      }).catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
      });
  },

  sendRepairAssignmaster({ commit }, item) {
    RepairProvider.sendRepairAssignmaster(item)
      .then((res) => {
        commit('SEND_REPAIR_ASSIAGNMASTER', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Новый мастер успешно установлен', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Мастер не установлен', item: '' });
      });
  },

  sendRepairStart({ commit }, id) {
    RepairProvider.sendRepairStart(id)
      .then((res) => {
        commit('SEND_REPAIR_START', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Заказ переведён в статус "В работе"', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !', item: '' });
      });
  },

  async sendRepairUpdate({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairUpdate(item)
      .then((res) => {
        commit('SEND_REPAIR_UPDATE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} изменён !`, item: '' });
        commit('REPAIR_VALIDATION', {});
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('PUSH_CALL_ERROR', { title: 'Заказ не обновлен', item: '' });
        commit('REPAIR_VALIDATION', err.response.data);
        commit('BTN_LOADER', false);
      });
  },

  sendRepairAdd({ commit }, { item, typeSuccess }) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairAdd(item)
      .then((res) => {
        commit('SEND_REPAIR_ADD', { item: res.data });
        if (typeSuccess === 'refill') {
          commit('PUSH_CALL_SUCCESS', { title: 'Заказ сохранён', item: '' });
        } else {
          commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} принят в ремонт`, item: '' });
        }
        commit('REPAIR_VALIDATION', {});
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !', item: '' });
        commit('REPAIR_VALIDATION', err.response.data);
        commit('BTN_LOADER', false);
      });
  },

  sendRepairCustomStatus({ commit }, item) {
    RepairProvider.sendRepairCustomStatus(item)
      .then((res) => {
        commit('SEND_REPAIR_CUSTOM_STATUS', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Статус успешно обновлен', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка - статус не обновлен!', item: '' });
      });
  },

  sendRepairDelete({ commit }, id) {
    RepairProvider.sendRepairDelete(id)
      .then((res) => {
        commit('SEND_REPAIR_DELETE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Заказ успешно удалён', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка! Заказ не удалён', item: '' });
      });
  },

  // repaircomment
  getViewRepairComment({ commit }, id) {
    RepairProvider.getViewRepairComment(id)
      .then((res) => {
        commit('GET_VIEW_COMMENT', { item: res.data });
      })
      .catch((err) => console.error(err));
  },

  sendRepairComment({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairComment(item)
      .then((res) => {
        commit('SEND_REPAIR_COMMENT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Комментарий успешно добавлен', item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка! Комментарий не добавлен', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  // repairpayment

  getViewRepairPayments({ commit }, id) {
    RepairProvider.getViewRepairPayments(id)
      .then((res) => commit('GET_REPAIR_VIEW_PAYMENTS', {
        item: res.data,
      }))
      .catch((err) => console.error(err));
  },

  sendRepairPayment({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairPayment(item)
      .then((res) => {
        commit('ADD_REPAIR_PAYMENT', { item: res.data });
        if (res.data.sum >= 0) {
          commit('PUSH_CALL_SUCCESS', { title: 'Оплата успешно добавлена', item: '' });
        } else {
          commit('BTN_LOADER', false);
        }
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка оплаты', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  deleteRepairPayments({ commit }, id) {
    RepairProvider.deleteRepairPayments(id)
      .then((res) => {
        commit('DEL_REPAIR_PAYMENT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Оплата успешно удалена', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Удалить оплату не удалось', item: '' });
      });
  },

  // repairdiscount

  sendRepairDiscount({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairDiscount(item)
      .then((res) => {
        commit('ADD_REPAIR_DISCOUNT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Скидка успешно добавлена', item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка добавления скидки', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  sendRepairDiscountUpdate({ commit }, item) {
    RepairProvider.sendRepairDiscountUpdate(item)
      .then((res) => {
        commit('ADD_REPAIR_DISCOUNT_UPDATE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Скидка успешно обновлена', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка обновления скидки', item: '' });
      });
  },

  deleteRepairDiscount({ commit }, id) {
    RepairProvider.deleteRepairDiscount(id)
      .then((res) => {
        commit('SEND_REPAIR_DISCOUNT_DELETE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Скидка успешно удалёна', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка! Скидка не удалёна', item: '' });
      });
  },

  // repairaddition

  sendRepairAddition({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairAddition(item)
      .then((res) => {
        commit('ADD_REPAIR_ADDITION', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Наценка успешно добавлена', item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка добавления наценки', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  sendRepairAdditionUpdate({ commit }, item) {
    RepairProvider.sendRepairAdditionUpdate(item)
      .then((res) => {
        commit('ADD_REPAIR_ADDITION_UPDATE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Наценка успешно обновлена', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка обновления наценки', item: '' });
      });
  },

  deleteRepairAddition({ commit }, id) {
    RepairProvider.deleteRepairAddition(id)
      .then((res) => {
        commit('SEND_REPAIR_ADDITION_DELETE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Наценка успешно удалёна', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка! Наценка не удалёна', item: '' });
      });
  },

  // /repairtransfer

  getRepairTransferList({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.getRepairTransferList(item.page, item, item['per-page'])
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('GET_REPAIR_TRANSFER', {
          item: res.data,
          pageCount: res.pageCount,
          pageCurrent: res.pageCurrent,
        });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
      });
  },

  sendRepairTransfer({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairTransfer(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('ADD_REPAIR_TRANSFER', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Заказ успешно перемещён', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Ошибка перемещения заказа', item: '' });
      });
  },

  sendRepairTransferStart({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairTransferStart(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('SEND_CHANGE_STATUS_REPAIR_TRANSFER', { item: res.data });
        // commit('PUSH_CALL_SUCCESS', { title: 'Заказ успешно перемещён', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendRepairTransferComplete({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairTransferComplete(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('SEND_CHANGE_STATUS_REPAIR_TRANSFER', { item: res.data });
        // commit('PUSH_CALL_SUCCESS', { title: 'Заказ успешно перемещён', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  sendRepairTransferCancel({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairTransferCancel(item)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('SEND_CHANGE_STATUS_REPAIR_TRANSFER', { item: res.data });
        // commit('PUSH_CALL_SUCCESS', { title: 'Заказ успешно перемещён', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  // repairstatus

  sendRepairToAgreement({ commit }, id) {
    RepairProvider.sendRepairToAgreement(id)
      .then((res) => {
        commit('SEND_REPAIR_TO_AGREEMENT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} отправлен на соглосвание`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  sendRepairApproveAgreement({ commit }, id) {
    RepairProvider.sendRepairApproveAgreement(id)
      .then((res) => {
        commit('SEND_REPAIR_APPROVE_AGREEMENT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} согласован`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  sendRepairDeclineAgreement({ commit }, id) {
    RepairProvider.sendRepairDeclineAgreement(id)
      .then((res) => {
        commit('SEND_REPAIR_DECLINE_AGREEMENT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} переведён в статус "Готово" (отказ клиент)`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  sendRepairDismissJob({ commit }, id) {
    RepairProvider.sendRepairDismissJob(id)
      .then((res) => {
        commit('SEND_REPAIR_DISMISS_JOB', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Мастер снят с заказа', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  sendRepairDone({ commit }, id) {
    RepairProvider.sendRepairDone(id)
      .then((res) => {
        commit('SEND_REPAIR_DONE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} переведён в статус "Готово"`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  sendRepairRevision({ commit }, id) {
    RepairProvider.sendRepairRevision(id)
      .then((res) => {
        commit('SEND_REPAIR_REVISION', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} возвращён в статус "В работе"`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  sendRepairEject({ commit }, id) {
    RepairProvider.sendRepairEject(id)
      .then((res) => {
        commit('SEND_REPAIR_EJECT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} выдан`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  sendRepairRestart({ commit }, id) {
    RepairProvider.sendRepairRestart(id)
      .then((res) => {
        commit('SEND_REPAIR_RESTART', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} возвращён в работу`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  sendRepairDelay({ commit }, id) {
    RepairProvider.sendRepairDelay(id)
      .then((res) => {
        commit('SEND_REPAIR_DELAY', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} ожидает запчасти`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  sendRepairContinue({ commit }, id) {
    RepairProvider.sendRepairContinue(id)
      .then((res) => {
        commit('SEND_REPAIR_CONTINUE', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: `Поступили запчасти для заказа ${res.data.title}`, item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  // /repairlog

  getRepairLogList({ commit }, item) {
    RepairProvider.getRepairLogList(item.page, item.data)
      .then((res) => commit('GET_REPAIR_LOG', {
        item: res.data,
        pageCount: Number(res.pageCount),
      }))
      .catch((err) => console.error(err));
  },

  // /repairfile

  getRepairFileList({ commit }, item) {
    RepairProvider.getRepairFileList(item.page, item.data)
      .then((res) => commit('GET_REPAIR_FILE', {
        item: res.data,
        pageCount: Number(res.pageCount),
      }))
      .catch((err) => console.error(err));
  },

  sendRepairAddFiles({ commit }, item) {
    RepairProvider.sendRepairAddFiles(item)
      .then((res) => {
        commit('SEND_REPAIR_ADD_FILES', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Файл успешно загружен !', item: '' });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  },

  // /repairtablelayout

  async getRepairTableLayout({ commit }) {
    await RepairProvider.getRepairTableLayout()
      .then((res) => commit('GET_REPAIR_TABLE_LAYOUT', {
        item: res.data,
      }))
      .catch((err) => console.error(err));
  },

  sendRepairTableLayout({ commit }, item) {
    commit('BTN_LOADER', true);
    RepairProvider.sendRepairTableLayout(item)
      .then((res) => {
        commit('SEND_REPAIR_TABLE_LAYOUT', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Настройки таблицы обновлены!', item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка!', item: '' });
        commit('BTN_LOADER', false);
      });
  },

  clearValidateRepair({ commit }) {
    commit('CLEAR_VALIDATE_REPAIR');
  },

};

export default actions;
