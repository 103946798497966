import BaseProvider from '../base-provider';

class InventoryProvider extends BaseProvider {
  async getInventoryList(page = 1, form = null, perPage = 10) {
    const expand: string[] = ['creator', 'applier', 'storage', 'last_comment.user'];
    let res;
    if (form) {
      const searchForm: string = this.buildUrl({ InventorySearchForm: form });
      res = await this.request('GET', `/inventory/index?expand=${expand}&page=${page}&per-page=${perPage}&${searchForm}`);
    } else {
      res = await this.request('GET', `/inventory/index?expand=${expand}&page=${page}&per-page=${perPage}`);
    }
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getInventory(id: number) {
    const expand: string[] = ['creator', 'storage', 'applier'];
    const res = await this.request('GET', `/inventory/view?expand=${expand}&id=${id}`);
    return {
      data: res.data,
    };
  }

  async getInventoryComments(inventoryId: number) {
    const form: object = {
      inventory_id: inventoryId,
    };
    const expand: string[] = ['user'];
    const searchForm: string = this.buildUrl({ InventoryCommentSearchForm: form });
    const res = await this.request('GET', `/inventorycomment/index?${searchForm}&expand=${expand}`);
    return {
      data: res.data,
    };
  }

  async getListOfInventorypart(form: InventoryPartSearchForm, page, perPage) {
    const searchForm: string = this.buildUrl({ InventoryPartSearchForm: form });
    const expand: string[] = ['part.barcodes'];
    const res = await this.request('GET', `/inventorypart/index?page=${page}${searchForm}&per-page=${perPage}&expand=${expand}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-current-page'],
    };
  }

  async updateInventory(inventoryId: number, inventory: InventoryForm) {
    const data: object = {
      InventoryForm: inventory,
    };
    const res = await this.request('POST', `/inventory/update?id=${inventoryId}`, data);
    return {
      data: res.data,
    };
  }

  async applyInventory(inventoryId: number) {
    const expand = ['storage'];
    const res = await this.request('POST', `/inventory/apply?id=${inventoryId}&expand=${expand}`);
    return {
      data: res.data,
    };
  }

  async revertInventory(inventoryId: number) {
    const res = await this.request('POST', `/inventory/revert?id=${inventoryId}`);
    return {
      data: res.data,
    };
  }

  async addNewInventory(inventory: InventoryForm) {
    const data: object = {
      InventoryForm: inventory,
    };
    const res = await this.request('POST', '/inventory/add', data);
    return {
      data: res.data,
    };
  }

  async addNewInventoryComment(inventoryId: number, content: string) {
    const form: object = {
      inventory_id: inventoryId,
      content,
    };
    const expand: string[] = ['user'];
    const data: object = {
      InventoryComment: form,
    };
    const res = await this.request('POST', `/inventorycomment/add?expand=${expand}`, data);
    return {
      data: res.data,
    };
  }

  async addNewInventoryPart(form: InventoryPartForm) {
    const data: object = { InventoryPart: form };
    const expand: string[] = ['part.barcodes'];
    const res = await this.request('POST', `/inventorypart/add?expand=${expand}`, data);
    return {
      data: res.data,
    };
  }

  async updateInventoryPart(inventoryPartId: number, form: InventoryPartForm) {
    const data: object = { InventoryPart: form };
    const expand: string[] = ['part.barcodes'];
    const res = await this.request('POST', `/inventorypart/update?expand=${expand}&id=${inventoryPartId}`, data);
    return {
      data: res.data,
    };
  }

  async deleteInventory(inventoryId: number) {
    const res = await this.request('POST', `/inventory/delete?id=${inventoryId}`);
    return {
      data: res.data,
    };
  }

  async deleteInventoryPart(inventoryPartId: number) {
    const res = await this.request('POST', `/inventorypart/delete?id=${inventoryPartId}`);
    return {
      data: res.data,
    };
  }
}
export default new InventoryProvider();

export interface Inventory {
    id: number;
    status: InventoryStatuses;
    storage_id: number;
    not_listed_strategy: InventoryNotListedStrategies;
    creator_id: number;
    created_at: number;
    applier_id: number|null;
    applied_at: number|null;
    posting_id: number|null;
    cancel_id: number|null;
    count_parts_item: number;
    total_parts_amount: number;
    last_comment: InventoryComment;
    applier: object|null;
    creator: object|null;
    storage: object|null;
}

export interface InventoryComment {
    id: number;
    inventrory_id: number;
    user_id: number;
    content: string;
    created_at: number;
    user: object|null;
}

export enum InventoryNotListedStrategies {
    ZERO,
    IGNORE
}

export enum InventoryStatuses {
    DRAFT,
    APPLIED
}

export interface InventoryForm {
    storage_id: number | null;
    not_listed_strategy: InventoryNotListedStrategies;
}

export interface InventoryPartForm {
    inventory_id: number;
    part_id: number;
    number?: number;
}

export interface InventoryPartSearchForm {
    inventory_id: number;
    part_id: number | null;
}
