import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import RepairTableCell from '@/components/repair-table-cell';

@Component({
  template: require('./index.html'),
  components: {
    RepairTableCell,
  },
})

export default class RepairTableRow extends Vue {
  @Prop() active_column;

  @Prop() index;

  @Prop() item;

  @Prop() dropdown_for_custom_status_name_1;

  @Prop() dropdown_for_custom_status_name_2;

  @Prop() dropdown_for_custom_status_name_3;

  @Prop() dropdown_for_custom_status_name_4;

  @Prop() dropdown_for_custom_status_name_5;

  @Prop() dropdown_for_custom_status_name_6;

  @Prop() handlerSelectedStatus!: Function;

  @Prop() handleSelectedCustomStatus!: Function;

  buildTableColumnClass = (active_column) => {
    let className = '';

    if (active_column.value === 'phone') {
      className += 'td_nowrap';
    }
    return className;
  }
}
