import { Menu } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ViewUserName from '../forms/user-name';
import RightSliding from '../right-sliding';
import Link from './link';

@Component({
  template: require('./navbar.html'),
  components: {
    'right-sliding': RightSliding,
    'user-name': ViewUserName,
  },
})
export default class NavbarComponent extends Vue {
    @Action getReports;

    @Action getSettings;

    @Action getUserMe;

    @Action getPointList;

    @Action getBalance;

    @Action getCurrencies;

    @Action getCompanyInfo;

    @Getter auth;

    @Getter user;

    @Getter reports;

    @Getter settings;

    reportsArray: Link[] = [];

    isCollapse = false;

    sliding = false;

    @Watch('auth')
    loadReports() {
      if (this.auth) {
        this.getReports();
        this.getSettings();
        this.getUserMe();
        this.getPointList({ page: 1 });
        this.getBalance();
        this.getCompanyInfo();
        this.getCurrencies();
        this.balanceUpdating();
      } else {
        this.deleteBalanceUpdating();
        this.reportsArray = [];
      }
    }

    @Watch('reports')
    populateReportsArray() {
      this.reports.forEach((report) => {
        this.reportsArray.push(new Link(report.title, `/report/view/${report.id}`));
      });
    }

    get isAuthorized() {
      return Object.keys(this.user).length !== 0;
    }

    get rightsUserCanManageAnalytics() {
      return this.user.rights ? this.user.rights.can_manage_analytics : false;
    }

    deleteBalanceUpdating = () => {
      const intervalId = Number(localStorage.getItem('updatingBalanseId'));
      localStorage.removeItem('updatingBalanseId');
      window.clearInterval(intervalId);
    }

    balanceUpdating() {
      const intervalId = setInterval(() => {
        this.getBalance();
      }, 1000 * 60 * 5);
      localStorage.setItem('updatingBalanseId', String(intervalId));
    }

    selectMenu() {
      // Стоит посмотреть эту строчку потом
      (this.$refs.mobileMenu as Menu & { openedMenus: object[]}).openedMenus = [];
    }

    isSliding() {
      this.sliding = !this.sliding;
    }

    closeSliding() {
      this.sliding = false;
    }

    mounted() {
      this.$root.$on('rightSliding', this.closeSliding);
      this.loadReports();
    }

    repear: Link[] = [
      new Link('Принять в ремонт', '/repair/form/add'),
      new Link('Принять картриджи', '/refill/form/add'),
      new Link('hr', ''),
      new Link('Все заказы', '/repair'),
      new Link('Принято', '/repair?status=1'),
      new Link('В работе', '/repair?status=2'),
      new Link('Ожидает', '/repair?status=3'),
      new Link('Готово', '/repair?status=4'),
      new Link('Выдано', '/repair?status=5'),
      new Link('hr', ''),
      new Link('Перемещения', '/repair/transfers'),
    ];

    task: Link[] = [
      new Link('Список задач', '/task'),
    ];

    part: Link[] = [
      new Link('Номенклатура', '/part/nomenklatura'),
      new Link('Остатки товаров', '/part'),
      new Link('Поступления на склад', '/part/posting'),
      new Link('Перемещения между складами', '/storage/transfer'),
      new Link('Списания', '/cancel'),
      new Link('Инвентаризация', '/inventory/1'),
      new Link('hr', ''),
      new Link('Работы и услуги', '/work'),
    ];

    shop: Link[] = [
      new Link('Новая продажа', '/sell/add'),
      new Link('hr', ''),
      new Link('Все продажи', '/sell'),
    ];

    cache: Link[] = [
      new Link('Кассы', '/cash'),
      new Link('Операции', '/cash/view/'),
      new Link('Зарплата', '/salary'),
    ];

    report: Link[] = [
      new Link('Текущий отчет', '/report/current'),
      new Link('Финансовый отчет', '/report/economy'),
      new Link('Отчет о работе сотрудников', '/report/stuff'),
      new Link('Отчет о работе филиалов', '/report/point'),
      new Link('Отчет о кассовых операциях', '/report/cash'),
      new Link('Отчет о расходе запчастей', '/report/partuse'),
      new Link('Маркетинговый отчет', '/report/marketing'),
      new Link('Отзывы', '/report/feedback'),
    ];

    company: Link[] = [
      new Link('Настройки', '/settings'),
      new Link('Справочники', '/reference/device'),
      new Link('Биллинг', '/service/billing'),
      new Link('Шаблоны документов', '/service/papers'),
      new Link('SMS', '/service/sms'),
      new Link('Клиенты', '/clients'),
      new Link('Сотрудники', '/stuff'),
      // new Link('Партнёрка', '/partner'),
      // new Link('Виджет', '/integration/widget'),
      // new Link('API', '/apidoc'),
      new Link('Экспорт', '/service/export/request'),
      new Link('Импорт', '/service/import'),
    ];

    profile: Link[] = [
      new Link('Настройки пользователя', ''),
      new Link('Сменить пароль', ''),
      new Link('Выйти', ''),
    ];
}
