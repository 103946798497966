import BaseProvider from '../base-provider';

class ImportProvider extends BaseProvider {
  async sendClientsValidation(file: Blob) {
    const data = new FormData();
    data.append('ClientImportForm[file]', file);
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/validateclients', data, contentType);
    return res;
  }

  async sendClientsImport(file: Blob) {
    const data = new FormData();
    data.append('ClientImportForm[file]', file);
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/importclients', data, contentType);
    return res;
  }

  async sendPartsValidation(file: Blob) {
    const data = new FormData();
    data.append('PartImportForm[file]', file);
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/validateparts', data, contentType);
    return res;
  }

  async sendPartsImport(file: Blob) {
    const data = new FormData();
    data.append('PartImportForm[file]', file);
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/importparts', data, contentType);
    return res;
  }

  async sendWorksValidation(file: Blob) {
    const data = new FormData();
    data.append('WorkImportForm[file]', file);
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/validateworks', data, contentType);
    return res;
  }

  async sendWorksImport(file: Blob) {
    const data = new FormData();
    data.append('WorkImportForm[file]', file);
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/importworks', data, contentType);
    return res;
  }

  async sendPostingValidation(file: Blob, storageId: number) {
    const data = new FormData();
    data.append('PostingImportForm[file]', file);
    data.append('PostingImportForm[storageId]', storageId.toString());
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/validateposting', data, contentType);
    return res;
  }

  async sendPostingImport(file: Blob, storageId: number) {
    const data = new FormData();
    data.append('PostingImportForm[file]', file);
    data.append('PostingImportForm[storageId]', storageId.toString());
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/importposting', data, contentType);
    return res;
  }

  async sendInventoryValidation(file: Blob, storageId: number, notListedStrategy: number) {
    const data = new FormData();
    data.append('InventoryImportForm[file]', file);
    data.append('InventoryImportForm[storageId]', storageId.toString());
    data.append('InventoryImportForm[notListedStrategy]', notListedStrategy.toString());
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/validateinventory', data, contentType);
    return res;
  }

  async sendInventoryImport(file: Blob, storageId: number, notListedStrategy: number) {
    const data = new FormData();
    data.append('InventoryImportForm[file]', file);
    data.append('InventoryImportForm[storageId]', storageId.toString());
    data.append('InventoryImportForm[notListedStrategy]', notListedStrategy.toString());
    const contentType = 'multipart/form-data';
    const res = await this.request('POST', '/import/importinventory', data, contentType);
    return res;
  }
}

export default new ImportProvider();
