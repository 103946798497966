import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ProviderForm from '@/components/provider-form';
import Provider from '@/types/provider';
import ProviderProvider from '@/api/providers/provider';

@Component({
  template: require('./index.html'),
  components: {
    ProviderForm,
  },
})

export default class ChooseAnotherProvider extends Vue {
  @Prop({ default: () => false }) isLoading?: boolean

  providerName = '';

  providerId = -1;

  providerList: Provider[] = [];

  formProvider: Provider | object = {}

  isModalAddProvider = false;

  isLoadingFormProvider = false;

  mounted() {
    this.getFullProviderList();
  }

  /**
   * Поиск провайдера для autocomplete
   * @param  {} queryString{string}
   * @param  {} cb{Function}
   */
  searchProvider(queryString: string, cb: Function) {
    const items = this.providerList;
    const results = queryString ? items.filter(this.createFilter(queryString)) : items;
    cb(results);
  }

  /**
   * Фильтр для резултатов searchProvider
   * @param  {} queryString
   * @param  {} link
   */
  createFilter = (queryString) => (link) => (link.name.toLowerCase()
    .indexOf(queryString.toLowerCase()) !== -1)

  /**
   * Получает список всех поставщиков
   */
  getFullProviderList() {
    ProviderProvider.getFullProviderList()
      .then((res) => {
        this.providerList = res;
      }).catch((err) => {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось поулчить список поставщиков' });
        throw Error(err);
      });
  }

  /**
   * Выбор поставщика из выподающего меню
   * @param  {Provider} item
   */
  providerSelect(item: Provider) {
    this.providerId = item.id;
    this.providerName = item.name;
  }

  /**
   * Добавлдение нового поставщика
   * @param  {Provider} item
   */
  sendProvider(item: Provider) {
    this.isLoadingFormProvider = true;
    ProviderProvider.sendProvider(item)
      .then((res) => {
        this.formProvider = {};
        this.isLoadingFormProvider = false;
        this.providerList.push(res.data);

        this.providerName = res.data.name;
        this.providerId = res.data.id;

        this.handlerToggleViewAddProvider();

        this.$store.commit('PUSH_CALL_SUCCESS', { title: `${res.data.name} успешно добавлен`, item: '' });
      })
      .catch((err) => {
        this.formProvider = {};
        this.isLoadingFormProvider = false;
        this.handlerToggleViewAddProvider();
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        console.error(err);
      });
  }

  /**
   * Передача родительскому компоненту события
   */
  updateProvider() {
    this.$emit('updateProvider', this.providerId);
  }

  /**
   * Показ/скрытие модального окна создания провайдера
   */
  handlerToggleViewAddProvider() {
    this.isModalAddProvider = !this.isModalAddProvider;
  }
}
