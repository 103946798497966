export const GET_LICENSE_LIST = 'GET_LICENSE_LIST';

// Store

export interface StateLicenses {
    licensesList: Licenses;
    licensesListPageCount: null;
}

// Models

export interface Licenses {
    id: number;
    tariff_id: number;
    payment_id: number;
    created_at: number;
    activated_at: number;
    exprired_at: number;
    // Tariff
    tariff: object;
    // Payment
    payment: object;
}
