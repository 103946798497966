import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Currency } from '@/types';

@Component({
  template: require('./index.html'),
  props: ['type', 'value'],
})

export default class Numbers extends Vue {
  dummyCurrency: Currency = {
    id: 0,
    name: '',
    country_code: '',
    national_name: '',
    short_name: '',
    iso_code: 'USD',
    unicode_char: '-',
  };

  @Getter company;

  @Getter currencies;

  get selectedCurrency() {
    const currencies = this.currencies as Currency[];
    const selectedCurrencyId = typeof (this.company.currency_id) !== 'undefined' ? this.company.currency_id as number : 0;
    const selectedCurrency = currencies.find((x) => x.id === selectedCurrencyId);
    return selectedCurrency || this.dummyCurrency;
  }

  get selectedCurrencyCode() {
    return this.selectedCurrency.iso_code;
  }

  getNumber = (region, value) => {
    if (value === null) return '–';

    const res = new Intl.NumberFormat(region, {
      maximumFractionDigits: 2,
    });
    return res.format(Number(value));
  }

  getMoney(region, currency, value) {
    if (value === null) return '–';

    const res = new Intl.NumberFormat(region, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'decimal',
    });
    return `${res.format(Number(value))} ${this.selectedCurrency.unicode_char}`;
  }
}
