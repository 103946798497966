import Vddl from 'vddl';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { Getter } from 'vuex-class';
import { Item } from '../types';

Vue.use(Vddl);

@Component({
  name: 'category-tree',
  template: require('./child.html'),
  components: {
    draggable,
  },
})

class Child extends Vue {
    @Prop() item!: Item;

    // @Prop() active: boolean;
    // @Prop() child: boolean;
    // @Prop() disable: boolean;
    @Prop() paddingItem!: number;

    @Prop() dialogEdit!: boolean;

    @Prop() dialogDelete!: boolean;

    @Prop() itemActive!: number;

    @Prop() offButton!: boolean;

    @Prop({ default: () => 'part' }) typeCategory!: string;

    @Getter user;

    get isPaddingItem() {
      const padding = this.paddingItem + 20;
      return padding;
    }

    toggle = false;

    active = false;

    get rightsUser() {
      if (this.user.rights) {
        return this.typeCategory === 'part' ? this.user.rights.can_edit_delete_parts_and_categories
          : this.user.rights.can_edit_delete_works_and_categories;
      }
      return true;
    }

    isItemActive(key) {
      this.$root.$emit('categoryItemActive', key);
    }

    isDialogAdd(item) {
      this.$root.$emit('dialogAddCategory', item);
    }

    isDialogEdit(item) {
      this.$root.$emit('dialogEditCategory', item);
    }

    isDialogDelete(item) {
      this.$root.$emit('dialogDeleteCategory', item);
    }

    methodDisabledButtons() {
      const title = this.typeCategory === 'part' ? 'Вам не разрешено изменять и удалять товары, услуги и категории'
        : 'Вам не разрешено изменять/удалять услуги и категории услуг';

      this.$notify.error({
        title,
        message: '',
        position: 'bottom-right',
      });
    }

    @Watch('item.expanded', { deep: true })
    category(expanded: boolean) {
      if (!this.offButton && expanded) {
        this.item.expanded = false;
        this.toggle = true;
      }
    }
}

export { Child as default };
