import Vue from 'vue';
import { Form } from 'element-ui';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb, Part, Storage } from '@/types';
import StorageProvider from '../../api/providers/storage';
import TransferProvider from '../../api/providers/transfer';
import Numbers from '../../components/forms/numbers';
import ModalCatalog from '../../components/modal-catalog';
import TableStorage from '../../components/table-storage';
import WarehouseTable from '../../components/warehouse-table';

interface NewForm {
  from_storage_id;
  to_storage_id;
  parts;
}
@Component({
  template: require('./index.html'),
  components: {
    'modal-catalog': ModalCatalog,
    'warehouse-table': WarehouseTable,
    'table-storage': TableStorage,
    numbers: Numbers,
  },
})
export default class TransferAddPage extends Vue {
    @Action addBreadcrumb;

    @Getter user;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Перемещения между складами', link: '/storage/transfer' }, { id: 3, section: 'Новое перемещение', link: null }];

    new_form: NewForm = {
      from_storage_id: null,
      to_storage_id: null,
      parts: [],
    };

    tranfser_item_list: Part[] = [];

    transfer_parts_list: object[] = [];

    storage_list: Storage[] = [];

    loading = false;

    loading_save_tranfser = false;

    rules: object = {
      from_storage_id: [{ validator: this.validateFromStorage, trigger: 'change' }],
      to_storage_id: [{ validator: this.validateToStorage, trigger: 'change' }],

    };

    // api

    getStorageList(page) {
      StorageProvider.getStorageList(page)
        .then((res) => {
          this.storage_list = res.data;
          const { pageCount } = res;
          if (pageCount > 1) {
            for (let i = 2; i <= pageCount; i++) {
              StorageProvider.getStorageList(i)
                .then((resOfgetStorageList) => {
                  this.storage_list = this.storage_list.concat(resOfgetStorageList.data);
                }).catch((err) => {
                  throw (err);
                });
            }
          }
          const default_storage: Storage | undefined = (res.data as Storage[]).find((storage) => {
            if (storage.point_id) {
              return storage.point_id === this.user.point_id;
            }
            return undefined;
          });
          if (default_storage) {
            this.new_form.from_storage_id = default_storage.id;
          } else {
            this.new_form.from_storage_id = res.data[0].id;
          }

          const default_storage_to = this.storage_list.find((storage) => {
            if (storage.id) {
              return storage.id !== this.new_form.from_storage_id;
            }
            return undefined;
          });
          if (default_storage_to) {
            this.new_form.to_storage_id = default_storage_to.id;
          }
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные', item: '' });
        });
    }

    sendTransferItem(item) {
      this.tranfser_item_list.push(item);
    }

    deleteTransferItem(item) {
      const index = this.tranfser_item_list.findIndex((i) => i.id === item.id);
      this.tranfser_item_list.splice(index, 1);
    }

    sendTransfer(item) {
      this.loading_save_tranfser = true;
      TransferProvider.sendTransfer(item)
        .then(() => {
          this.loading_save_tranfser = false;
          this.$router.push({ name: 'transfer' });
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: '' });
        })
        .catch((err) => {
          console.warn(err);
          this.loading_save_tranfser = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    // end api

    date = new Date().toLocaleString('ru-RU', {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
    }).replace(',', '');

    get sum() {
      /* eslint-disable-next-line no-param-reassign */
      return this.new_form.parts.reduce((sum, current) => sum += current.price * current.amount, 0);
    }

    mounted() {
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Новое перемещение';
      this.getStorageList(1);
    }

    loadingPartsList(parts) {
      this.transfer_parts_list = parts.map((i) => ({
        id: i.id,
        name: i.name,
        part_id: i.id,
        amount: 1,
        art: i.art,
        price: i.posting_price === null ? 0 : i.posting_price,
        amounts: i.amounts,
      }));
    }

    validateFromStorage(rule, value, callback) {
      if (value === this.new_form.to_storage_id) {
        callback(new Error('Склады должны отличаться!'));
      } else {
        callback();
      }
    }

    validateToStorage(rule, value, callback) {
      if (value === this.new_form.from_storage_id) {
        callback(new Error('Склады должны отличаться!'));
      } else {
        callback();
      }
    }

    addTransfer(formName: string) {
      const FormValidate = this.$refs[formName] as Form;
      const isValid = this.validatingTransfer();
      FormValidate.validate((valid) => (
        (valid && isValid) ? this.sendTransfer(this.new_form) : false));
    }

    validatingTransfer(): boolean {
      let isValid = true;
      if (this.new_form.parts.length === 0) {
        isValid = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Добавьте товар', item: '' });
      }
      return isValid;
    }

    currentUser = (user) => {
      const lastName: string = user.last_name ? user.last_name : '';
      const firstName: string = user.first_name ? user.first_name : '';
      const middleName: string = user.middle_name ? user.middle_name : '';
      return `${lastName} ${firstName} ${middleName}`;
    }

    @Watch('tranfser_item_list')
    addNewParts(part) {
      this.new_form.parts = part;
    }
}
