export default class PricesLevelRoundType {
  priceRoundTypes: RoundTypes[] = [
    { id: 0, label: 'Не округлять' },
    { id: 1, label: 'Округлять математически' },
    { id: 2, label: 'Округлять в большую сторону' },
    { id: 3, label: 'Округлять в меньшую сторону' },
  ];

  priceRoundOrder: RoundTypes[] = [
    { id: 1, label: 'Округлять до целого' },
    { id: 10, label: 'Округлять до 10' },
    { id: 100, label: 'Округлять до 100' },
  ];
}

interface RoundTypes {
  id: number;
  label: string;
}
