export const GET_NOTEOPTION_LIST = 'GET_NOTEOPTION_LIST';
export const SEND_NOTEOPTION_LIST = 'SEND_NOTEOPTION_LIST';
export const UPDATE_NOTEOPTION_LIST = 'UPDATE_NOTEOPTION_LIST';
export const DELETE_NOTEOPTION_LIST = 'DELETE_NOTEOPTION_LIST';
export const SEND_NOTEOPTION_LIST_PRIORITY = 'SEND_NOTEOPTION_LIST_PRIORITY';
// Store

export interface NoteStateOption {
    noteoptionList: object[];
    addNoteOptionList: object;
    updateNoteOptionList: object;
    deleteNoteOptionList: object;
    sendNoteOptionPriority: object;
}
