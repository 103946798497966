import { GetterTree } from 'vuex';
import { StateDocument } from './types';

const getters: GetterTree<StateDocument, object> = {
  documentList: (state) => state.documentList,
  pageDocumentList: (state) => state.pageDocumentList,
  document: (state) => state.document,
  newDocument: (state) => state.newDocument,
  delDocument: (state) => state.delDocument,
  editDocument: (state) => state.editDocument,
  markersCompany: (state) => state.markersCompany,
  markersOrder: (state) => state.markersOrder,
  markersSales: (state) => state.markersSales,
  markersRepair: (state) => state.markersRepair,
  markersAdditionally: (state) => state.markersAdditionally,
  markersSoldProducts: (state) => state.markersSoldProducts,
  markersSoldWorks: (state) => state.markersSoldWorks,
  markersCustomFields: (state) => state.markersCustomFields,
  markersSparePart: (state) => state.markersSparePart,
  markersServiceWork: (state) => state.markersServiceWork,

};

export default getters;
