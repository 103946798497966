import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { ImportRowAction, ImportRowInfo } from '@/types/';
import ImportProvider from '../../api/providers/import';
import ErrorAccessRights from '../../components/error-access-rights';

@Component({
  template: require('./import-parts.html'),
  components: {
    'error-access-rights': ErrorAccessRights,
  },
})
export default class ImportParts extends Vue {
    @Action addBreadcrumb;

    @Action showErrorPush;

    @Action showSuccessPush;

    @Getter user;

    get rightsUserCanExport() {
      return this.user.rights ? this.user.rights.can_export : false;
    }

    step = 0;

    globalError = false;

    globalErrorText = '';

    uploadForm = {
      file: null,
    };

    validationResult: ImportRowInfo[] = [];

    showValidationDetails = false;

    get resultErrorsCount() {
      return this.validationResult.filter((x) => x.is_success === false).length;
    }

    get resultInsetrsCount() {
      return this.validationResult.filter(
        (x) => x.is_success && x.action === ImportRowAction.CREATE,
      ).length;
    }

    get resultUpdatesCount() {
      return this.validationResult.filter(
        (x) => x.is_success && x.action === ImportRowAction.UPDATE,
      ).length;
    }

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Компания', link: '/settings/info' },
        { id: 2, section: 'Импорт', link: '/service/import' },
        { id: 3, section: 'Импорт номенклатуры из Excel', link: null },
      ]);
      document.title = 'Импорт номенклатуры из Excel';
    }

    onValidate() {
      if ((this.$refs['file-input'] as HTMLInputElement).value.length === 0) {
        this.showErrorPush({ title: 'Не выбран файл для импорта' });
        return;
      }
      this.step = 1;
      this.sendValidationRequest()
        .then((resp) => { this.validationResult = resp?.data as ImportRowInfo[]; })
        .catch((err) => {
          if (typeof (err?.response?.data) === 'object') {
            this.validationResult = err?.response?.data as ImportRowInfo[];
          } else {
            if (typeof (err?.response?.data) === 'string') this.globalErrorText = err.response.data;
            this.globalError = true;
            console.error(err);
          }
        });
    }

    onBackToUpload() {
      this.step = 0;
      this.validationResult = [];
    }

    onImport() {
      this.step = 2;
      this.sendImportRequest()
        .then(() => {
          this.showSuccessPush({ title: 'Файл успешно импортирован.' });
          this.step = 3;
        })
        .catch((err) => {
          console.error(err);
          this.showErrorPush({ title: 'Не удалось выполнить импорт файла.' });
        });
    }

    fileInputChanged(val) {
      this.uploadForm.file = val;
    }

    async sendValidationRequest() {
      const fileInput = this.$refs['file-input'] as HTMLInputElement;
      if (fileInput.files) {
        try {
          return await ImportProvider.sendPartsValidation(fileInput.files[0]);
        } catch (err) {
          const response = err?.response?.data;
          if (response) {
            this.globalErrorText = '';
            if (Array.isArray(response)) {
              this.globalError = true;
              response.forEach((res) => {
                res.errors.forEach((error) => {
                  this.globalErrorText += `${error}`;
                });
              });
            } else if ((typeof response === 'object') && response !== null) {
              Object.values(response).forEach((key) => {
                (key as string[]).forEach((mess) => {
                  this.globalErrorText += `${mess}`;
                });
              });
              this.globalError = true;
            } else if (typeof response === 'string') {
              this.globalErrorText = response;
              this.globalError = true;
            }
          }
          throw new Error(err);
        }
      }
      throw new Error('fileInput is null');
    }

    async sendImportRequest() {
      const fileInput = this.$refs['file-input'] as HTMLInputElement;
      if (fileInput.files) {
        try {
          return await ImportProvider.sendPartsImport(fileInput.files[0]);
        } catch (err) {
          throw new Error(err);
        }
      }
      throw new Error('fileInput is null');
    }
}
