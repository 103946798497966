import { MutationTree } from 'vuex';
import {
  SalaryState,

  SET_SALARY_OPERATION_LIST_BY_TRANSFER_ID,

  SET_SALARY_TRANSFER_LIST_BY_USER_ID,

  SET_SALARY_TRANSFER_LIST_PAGE_COUNT, SET_STUFF_WITH_SALARY_BALANCE,
} from './types';

const mutations: MutationTree<SalaryState> = {
  [SET_SALARY_TRANSFER_LIST_BY_USER_ID](state, { data }) {
    state.salaryTransferListByUserId = data.data;
    state.salaryTransferListPageCount = data.pageCount;
  },
  [SET_SALARY_OPERATION_LIST_BY_TRANSFER_ID](state, { data }) {
    state.salaryOperationListByTransferId = data;
  },
  [SET_STUFF_WITH_SALARY_BALANCE](state, { data }) {
    state.stuffWithSalaryBalance = data;
  },

  [SET_SALARY_TRANSFER_LIST_PAGE_COUNT](state, { data }) {
    state.salaryTransferListPageCount = data;
  },

};

export default mutations;
