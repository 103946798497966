import { ActionTree } from 'vuex';
import { CashboxFilterForm } from '@/components/cash-view-filter/types';
import CashProvider from '../../api/providers/cash';
import { BTN_LOADER } from '../preloader/types';
import { PUSH_CALL_ERROR, PUSH_CALL_SUCCESS } from '../push/types';
import {
  CASH_VALIDATE,
  CLEAR_CASH,
  CLICK_DETAIL, CREATE_CASHBOX,
  DELETE_CASH_ITEM,
  GET_CASHBOX_VIEW,
  GET_CASHOPERATIONS_LIST, GET_CASH_LIST,

  SEND_CASHOPERATION_IN,
  SEND_CASHOPERATION_OUT,
  SEND_CASHOPERATION_TRANSFER, StateCash,
  UPDATE_CASH_ITEM,
} from './types';

const actions: ActionTree<StateCash, object> = {

  getCashList({ commit }, page) {
    CashProvider.getCashList(page)
      .then((res) => {
        commit(GET_CASH_LIST, { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  getFullCashList({ commit }, ignore_visibility_filter = true) {
    CashProvider.getAllCashes(ignore_visibility_filter)
      .then((res) => {
        commit(GET_CASH_LIST, { item: res });
      })
      .catch((err) => console.error(err));
  },

  getCashListWithIgnore({ commit }, page) {
    CashProvider.getCashList(page, { ignore_visibility_filter: true })
      .then((res) => {
        commit(GET_CASH_LIST, { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  getCashboxView({ commit }, id) {
    CashProvider.getCash(id)
      .then((res) => {
        commit(GET_CASHBOX_VIEW, res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          commit(PUSH_CALL_ERROR, { title: 'Запрет доступа', item: err.response.data.message });
        }
        console.log(err.response);
      });
  },

  getCashOperation({ commit }, item: CashboxFilterForm) {
    commit(BTN_LOADER, true);
    CashProvider.getCashOperation(item.page, item, item.perPage)
      .then((res) => {
        commit(BTN_LOADER, false);
        commit(GET_CASHOPERATIONS_LIST, {
          item: res.data,
          pageCount: res.pageCount,
          countIn: res.countIn,
          countOut: res.countOut,
          totalIn: res.totalIn,
          totalOut: res.totalOut,
        });
      })
      .catch((err) => {
        console.error(err);
        commit(BTN_LOADER, false);
      });
  },

  createCashbox({ commit }, item) {
    commit(BTN_LOADER, true);
    CashProvider.createCashbox(item)
      .then((res) => {
        commit(CREATE_CASHBOX, res.data);
        commit(PUSH_CALL_SUCCESS, { title: `Касса «${res.data.name}» создана`, item: '' });
        commit(BTN_LOADER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
      });
  },

  sendCashOperationIn({ commit }, item) {
    commit(BTN_LOADER, true);
    CashProvider.sendCashOperationIn(item)
      .then((res) => {
        commit(SEND_CASHOPERATION_IN, { item: res.data });
        // commit(PUSH_CALL_SUCCESS, { title: ``, item: ''});
        commit(BTN_LOADER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
      });
  },

  sendCashOperationOut({ commit }, item) {
    commit(BTN_LOADER, true);
    CashProvider.sendCashOperationOut(item)
      .then((res) => {
        commit(SEND_CASHOPERATION_OUT, { item: res.data });
        // commit(PUSH_CALL_SUCCESS, { title: ``, item: ''});
        commit(BTN_LOADER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
      });
  },

  sendCashOperationTransfer({ commit }, item) {
    commit(BTN_LOADER, true);
    CashProvider.sendCashOperationTransfer(item)
      .then((res) => {
        commit(SEND_CASHOPERATION_TRANSFER, { item: res.data });
        // commit(PUSH_CALL_SUCCESS, { title: ``, item: ''});
        commit(BTN_LOADER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
      });
  },

  changeCashItem({ commit }, item) {
    commit(BTN_LOADER, true);
    CashProvider.changeCashItem(item)
      .then((res) => {
        commit(UPDATE_CASH_ITEM, { item: res.data });
        commit(CASH_VALIDATE, { item: {} });
        commit(PUSH_CALL_SUCCESS, { title: `Касса «${item.name}» изменена`, item: '' });
        commit(BTN_LOADER, false);
      })
      .catch((err) => {
        commit(CASH_VALIDATE, { item: err.response.data });
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
      });
  },

  deleteCashItem({ commit }, id) {
    commit(BTN_LOADER, true);
    CashProvider.deleteCashItem(id)
      .then((res) => {
        commit(DELETE_CASH_ITEM, { item: res.data });
        commit(PUSH_CALL_SUCCESS, { title: `Касса «${res.data.name}» удалена`, item: '' });
        commit(BTN_LOADER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
      });
  },

  deletedCashOperation({ commit }, id) {
    commit(BTN_LOADER, true);
    CashProvider.deletedCashOperation(id)
      .then((res) => {
        commit('DELETE_CASH_OPERATIONS', { item: res.data });
        commit(PUSH_CALL_SUCCESS, { title: 'Кассовая операция удалена', item: '' });
        commit(BTN_LOADER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PUSH_CALL_ERROR, { title: 'Произошла ошибка', item: '' });
        commit(BTN_LOADER, false);
      });
  },

  clickDetail({ commit }, item) {
    commit(CLICK_DETAIL, item);
  },

  clearCashOperation({ commit }) {
    commit(CLEAR_CASH);
  },
};

export default actions;
