import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ServiceSettings } from '../../store/company/types';

@Component({
  template: require('./index.html'),
})
export default class RepairsPage extends Vue {
    @Getter settings;

    @Getter btnloader;

    @Action getSettings;

    @Action updateSettings;

    @Action addBreadcrumb;

    loading = true;

    storedSettings: ServiceSettings = new ServiceSettings();

    validateDeadline = (rule, value, callback) => {
      if (value < 1) {
        callback(new Error('Значение «Deadline» должно быть не меньше 1'));
      } else {
        callback();
      }
    }

    validateWarrantSize = (rule, value, callback) => {
      if (value < 1) {
        callback(new Error('Значение «Срок гарантии» должно быть не меньше 1'));
      } else {
        callback();
      }
    }

    validateRepairDuration = (rule, value, callback) => {
      if (value < 1) {
        callback(new Error('Значение «Продолжительность работы» должно быть не меньше 1'));
      } else {
        callback();
      }
    }

    validateExpressAddition = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error('Значение «Наценка за срочность» должно быть не меньше 0'));
      } else {
        callback();
      }
    }

    rules: object = {
      express_addition: [{ validator: this.validateExpressAddition, trigger: 'blur' }],
      default_repair_duration: [{ validator: this.validateRepairDuration, trigger: 'blur' }],
      default_warrant_size: [{ validator: this.validateWarrantSize, trigger: 'blur' }],
      deadline: [{ validator: this.validateDeadline, trigger: 'blur' }],
    };

    async mounted() {
      await this.getSettings();
      await this.addBreadcrumb([
        { id: 1, section: 'Компания', link: null },
        { id: 2, section: 'Настройки', link: null },
        { id: 3, section: 'Заказы', link: null },
      ]);
      document.title = 'Заказы';
      this.loading = false;
    }

    @Watch('settings')
    updateStored(value) {
      this.storedSettings = { ...value };
    }

    submitHandler(formName) {
      const formValid = this.$refs[formName] as Form;
      formValid.validate((valid) => (valid ? this.updateSettings(this.storedSettings) : false));
    }
}
