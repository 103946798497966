// eslint-disable-next-line max-classes-per-file
import Vue from 'vue';
import { Form } from 'element-ui';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import TaskCommentProvider from '../../api/providers/task-comment';
import { TaskComment } from './types';

@Component({
  template: require('./task-comment-form.html'),
  components: {
  },
})
export default class TaskCommentForm extends Vue {
  @Prop() taskId!: number;

  @Prop() showDeleted!: boolean;

  @Prop() showDeletedChanged!: Function;

  @Getter btnloader;

  comment: TaskComment = new class implements TaskComment {
    content!: string;

    id!: number;

    task_id!: number;

    user_id!: number;

    created_at!: number;

    is_deleted!: false;
  }();

  showDeletedSwitchValue = false;

  showDeletedSwitchValueChanged(newValue: boolean) {
    this.showDeletedChanged(newValue);
  }

  mounted() {
    this.showDeletedSwitchValue = this.showDeleted;
  }

  resetForm() {
    (this.$refs.formContents as Form).resetFields();
  }

  onCommentSubmit(formName) {
    (this.$refs[formName] as Form).validate((valid) => {
      if (valid) {
        this.$store.commit('BTN_LOADER', true);
        TaskCommentProvider.sendTaskComment(this.taskId, this.comment.content)
          .then((response) => {
            this.$store.commit('BTN_LOADER', false);
            this.comment.content = '';
            this.$emit('onNewCommentSaved', response.data);
            this.resetForm();
          })
          .catch((err) => {
            console.error(err);
            this.$store.commit('BTN_LOADER', false);
          });
        return true;
      }
      return false;
    });
  }
}
