import { ChangePointForm } from '@/types';
import BaseProvider from '../base-provider';

class UserProvider extends BaseProvider {
  async getUserMe() {
    const expand: string[] = ['point', 'service', 'current_salary_balance', 'cashboxes'];
    const res = await this.request('GET', `/user/me?expand=${expand}`);
    return res;
  }

  async sendUserUpdate(item) {
    const data: object = { User: item };
    const res = await this.request('POST', `/user/update?id=${item.id}`, data);
    return res;
  }

  async sendUserUpdatePassword(item) {
    const data: object = { UpdatePasswordForm: item };
    const res = await this.request('POST', '/user/updatepassword', data);
    return res;
  }

  async sendUserChangePoint(item: ChangePointForm) {
    const data: object = { ChangePointForm: item };
    const res = await this.request('POST', '/user/changepoint', data);
    return res;
  }
}

export default new UserProvider();
