import axios, { Method } from 'axios';

class LogProvider {
  host;

  constructor() {
    this.host = 'https://app1.chatix.io:8100/log/index';
  }

  async request(method = 'POST', data = {}, contentType = 'application/json') {
    try {
      const headers: object = {
        'content-type': contentType,
      };

      let formattedData: object | null = data;

      if (contentType === 'application/json') {
        formattedData = JSON.parse(JSON.stringify(data));
      }

      const res = await axios({
        method: method as Method,
        headers,
        url: `${this.host}`,
        data: formattedData,
      });
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export default LogProvider;
