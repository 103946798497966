import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { TaskStatusInterface } from '../../store/task-status/types';

interface Style {
  backgroundColor?;
  color?;
  name?;
  borderColor?;
}
@Component({
  template: require('./task-status-bage.html'),
})
export default class TaskStatusBage extends Vue {
    @Prop() status!: TaskStatusInterface;

    @Prop({ default: () => false }) isArrowIcon!: boolean;

    @Watch('status')
    updateTaskStatus() {
      this.taskStatus = this.status;
    }

    mounted() {
      this.updateTaskStatus();
    }

    taskStatus: TaskStatusInterface | null = null;

    get styleObject() {
      const styleObject: Style = {};
      if (this.taskStatus !== null) {
        if (this.taskStatus?.completed) {
          styleObject.backgroundColor = this.taskStatus.color;

          // Определяем насколько темный цвет используется и ставим правильный цвет текста.
          // Чтобы на блоке текст выделялся на фоне
          const c = this.taskStatus.color.substring(1);
          const rgb = parseInt(c, 16);
          /* eslint-disable no-bitwise */
          const r = (rgb >> 16) & 0xff;
          const g = (rgb >> 8) & 0xff;
          const b = (rgb >> 0) & 0xff;
          /* eslint-enable no-bitwise */
          const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // ITU-R BT.709
          if (luma < 75) {
            styleObject.color = '#FFFFFF';
          } else {
            styleObject.color = '#000000';
          }
        } else {
          styleObject.color = this.taskStatus?.color;
        }
        styleObject.borderColor = this.taskStatus?.color;
      }
      return styleObject;
    }

    get statusName() {
      if (this.taskStatus !== null) {
        return this.taskStatus?.name;
      }
      return '...';
    }
}
