import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { FormLayout } from '@/types';
import ClientsProvider from '../../../../api/providers/clients';
// components
import ErrorAccessRights from '../../../error-access-rights';
import handlerAddRepair from '../api/handlerAddRepair';
// api
import requestOnTemplateForm from '../api/requestOnTemplateForm';
import RepairFormTemplate from '../components/template';
// interface
import { Repair, RepairClient } from '../interface';
// methods
import addBreadcrumbFormAdd from '../methods/addBreadcrumbFormAdd';
import addCartridges from '../methods/addCartridges';
import clearValidateRepair from '../methods/clearValidateRepair';
import modelSelection from '../methods/modelSelection';
import removeCartridges from '../methods/removeCartridges';
import removeCustomFieldItem from '../methods/removeCustomFieldItem';
import selectionAmountForPrepayment from '../methods/selectionAmountForPrepayment';
import typeSelection from '../methods/typeSelection';
import updateClient from '../methods/updateClient';
import valueCustomFields from '../methods/valueCustomFields';
import vendorSelection from '../methods/vendorSelection';

@Component({
  template: require('./index.html'),
  components: {
    'repair-form-template': RepairFormTemplate,
    'error-access-rights': ErrorAccessRights,
  },
})

class RepairFormClone extends Vue {
    @Action addBreadcrumb;

    @Action getBalance;

    @Getter balance;

    @Getter user;

    repair_client: RepairClient = {
      name: '',
      contact_phone: null,
      contact_name: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      phone: null,
      status: null,
      address: null,
      repair_discount_type: 0,
      repair_discount: 0,
      id: null,
    };

    data_repair: Repair = {
      bug: '',
      state: '',
      equipment: '',
      acceptor_notes: '',
      objects: [{
        amount: 1, model: '', type: null, vendor: null, device_id: null,
      }],
      client_id: null,
      custom_attributes: [],
      prepayment: { cashbox_id: null, sum: null },
      serial: null,
      imei: null,
      warranty: false,
      master_id: null,
      outbound: false,
      planned_out_date: null,
      send_master_notification: false,
      maintenance: false,
      warrant_size: null,
      planned_price: null,
      express: false,
    };

    clone_repair: object = {};

    template_form: FormLayout = { layout: [] };

    loading_form = false;

    client_check_black_list: object[] = [];

    error_repair_form: object = {};

    // api

    sendClientCheckBlacklist(item) {
      ClientsProvider.sendClientCheckBlacklist(item)
        .then((res) => { this.client_check_black_list = res.data; });
    }

    mounted() {
      this.getBalance();
      const id = Number(this.$route.query.id);
      const { name } = this.$route;

      this.addBreadcrumb(addBreadcrumbFormAdd(name));

      requestOnTemplateForm.call(this, name, 'clone', id);
      typeSelection.call(this);
      vendorSelection.call(this);
      modelSelection.call(this);
      addCartridges.call(this);
      removeCartridges.call(this);
      selectionAmountForPrepayment.call(this);
      valueCustomFields.call(this);
      removeCustomFieldItem.call(this);
      handlerAddRepair.call(this);
      clearValidateRepair.call(this);
      this.idCashBox();
    }

    destroyed() {
      this.$root.$off('requestOnTemplateForm');
      this.$root.$off('typeSelection');
      this.$root.$off('vendorSelection');
      this.$root.$off('modelSelection');
      this.$root.$off('addCartridges');
      this.$root.$off('removeCartridges');
      this.$root.$off('selectionAmountForPrepayment');
      this.$root.$off('valueCustomFields');
      this.$root.$off('removeCustomFieldItem');
      this.$root.$off('handlerAddRepair');
      this.$root.$off('clearValidateRepair');
      this.$root.$off('idCashBox');
    }

    get rightsUserCreateUpdateRepair() {
      return this.user.rights ? this.user.rights.can_create_update_repair : false;
    }

    updatingClient(client) {
      updateClient.call(this, client);
    }

    idCashBox() {
      this.$root.$on('idCashBox', (id) => {
        this.data_repair.prepayment.cashbox_id = id;
      });
    }
}

export default RepairFormClone;
