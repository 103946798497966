import { MutationTree } from 'vuex';
import {
  SET_CURRENCIES,
  StateCurrency,
} from './types';

const mutations: MutationTree<StateCurrency> = {
  [SET_CURRENCIES](state, items) {
    state.currencies = items;
  },
};

export default mutations;
