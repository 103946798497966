import { MutationTree } from 'vuex';
import {
  DELETE_STATEOPTION_LIST, GET_STATEOPTION_LIST,
  SEND_STATEOPTION_LIST,

  SEND_STATEOPTION_LIST_PRIORITY, StateStateOption,

  UPDATE_STATEOPTION_LIST,
} from './types';

const mutations: MutationTree<StateStateOption> = {
  [GET_STATEOPTION_LIST](state, { item }) {
    state.stateoptionList = item;
  },

  [SEND_STATEOPTION_LIST](state, { item }) {
    state.addStateOptionList = item;
  },

  [SEND_STATEOPTION_LIST_PRIORITY](state, { item }) {
    state.sendStateOptionPriority = item;
  },

  [UPDATE_STATEOPTION_LIST](state, { item }) {
    state.updateStateOptionList = item;
  },

  [DELETE_STATEOPTION_LIST](state, { item }) {
    state.deleteStateOptionList = item;
  },
};

export default mutations;
