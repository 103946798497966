import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb, ActionBtn } from '@/types';
import InventoryFilterForm from '@/components/inventory-filter-form';
import InventoryProvider from '../../api/providers/inventory';
import ViewDate from '../forms/date';
import ViewUserName from '../forms/user-name';

@Component({
  template: require('./index.html'),
  components: {
    date: ViewDate,
    'user-name': ViewUserName,
    InventoryFilterForm,
  },
})
export default class Inventory extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    load_page = false;

    @Getter user;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }, { id: 2, section: 'Инвентаризация', link: null }];

    actionBtn: ActionBtn[] = [{
      id: 1, type: 'add', title: 'Создать инвентаризацию', link: '/inventory/create', icon: 'fa-plus',
    }];

    cancel_list: object[] = [];

    page_count_cancel_list: number | null = null;

    filterForm = null;

    tableData: object[] = [];

    get rightsUserCanCreateAndFill() {
      return this.user.rights ? this.user.rights.can_create_fill_inventory : false;
    }

    pageCount = 1;

    currentPage = 1;

    mounted() {
      document.title = 'Инвентаризация';
      this.addBreadcrumb(this.breadcrumb);
      if (this.rightsUserCanCreateAndFill) {
        this.addActionBtn(this.actionBtn);
      }
      const page = Number(this.$route.params.page);
      this.changePage(page);
    }

    changeFilter(filterForm) {
      this.filterForm = { ...filterForm };
      this.changePage(1);
    }

    changePage(page: number) {
      this.load_page = true;
      InventoryProvider.getInventoryList(page, this.filterForm).then((res) => {
        this.tableData = res.data;
        this.pageCount = res.pageCount;
        this.load_page = false;
      }).catch((err) => {
        this.load_page = false;
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные', item: '' });
      });
      this.currentPage = page;
      this.$router.push(`/inventory/${page}`);
      window.scroll(0, 0);
    }

    destroyed() {
      this.addActionBtn([]);
    }
}
