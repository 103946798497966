import { GetterTree } from 'vuex';
import { NoteStateOption } from './types';

const getters: GetterTree<NoteStateOption, object> = {
  noteoptionList: (state) => state.noteoptionList,
  addNoteOptionList: (state) => state.addNoteOptionList,
  updateNoteOptionList: (state) => state.updateNoteOptionList,
  deleteNoteOptionList: (state) => state.deleteNoteOptionList,
  sendNoteOptionPriority: (state) => state.sendNoteOptionPriority,
};

export default getters;
