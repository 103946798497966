const state = {
  bug: [
    { id: 1, label: 'Утопили' },
    { id: 2, label: 'Ответ' },
    { id: 3, label: 'Убит' },
    { id: 4, label: 'тест' },
    { id: 5, label: 'Не включается' },
    { id: 6, label: 'Разбит дисплей' },
  ],
};

export default state;
