import { ActionTree } from 'vuex';
import { Response } from '@/types';
import CashTypeProvider from '../../api/providers/cash-type';
import { StateCashTypes } from './types';

const actions: ActionTree<StateCashTypes, object> = {

  getCashTypeList({ commit }, page) {
    CashTypeProvider.getCashTypeList(page)
      .then((res) => {
        commit('GET_CASH_TYPES', { item: res.data, pageCount: res.pageCount });
      })
      .catch((err) => console.error(err));
  },

  async getAllCashTypeList({ commit }) {
    let currentPage = 1;
    let allCashTypeList: object[] = [];
    try {
      const firstRes = await CashTypeProvider.getCashTypeList(currentPage);
      const { pageCount } = firstRes;
      allCashTypeList = firstRes.data;

      if (pageCount > 1) {
        currentPage += 1;
        const promiseResponse: object[] = [];

        for (currentPage; currentPage <= pageCount; currentPage++) {
          promiseResponse.push(CashTypeProvider.getCashTypeList(currentPage));
        }

        await Promise.all(promiseResponse)
          .then((results) => {
            (results as Response[]).forEach((result) => {
              allCashTypeList = [...allCashTypeList, ...result.data as object[]];
            });
          });
      }

      commit('GET_CASH_TYPES', { item: allCashTypeList, pageCount });
    } catch (err) {
      throw new Error(err);
    }
  },

  sendCashType({ commit }, item) {
    commit('BTN_LOADER', true);
    CashTypeProvider.sendCashType(item)
      .then((res) => {
        commit('ADD_CASH_TYPES', { item: res.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно добавлено', item: item.name });
      })
      .catch((err) => {
        console.error(err);
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  changeCashType({ commit }, item) {
    commit('BTN_LOADER', true);
    CashTypeProvider.changeCashType(item)
      .then((res) => {
        commit('EDIT_CASH_TYPES', { item: res.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно изменено', item: item.name });
      })
      .catch((err) => {
        commit('GET_VALID_CASH_TYPES', { valid: err.response.data });
        commit('BTN_LOADER', false);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },

  deleteCashType({ commit }, id) {
    CashTypeProvider.deleteCashType(id)
      .then((res) => {
        commit('DELETE_CASH_TYPES', { item: res.data });
        commit('PUSH_CALL_SUCCESS', { title: 'Успешно удалено', item: res.data.name });
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      });
  },
};

export default actions;
