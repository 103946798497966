import BaseProvider from '@/api/base-provider';
import { PriceLevel } from '@/types';

class PriceLevelProvider extends BaseProvider {
  async viewAllPriceLevel() {
    const res = await this.allPageReuest('GET', '/pricelevel/index');
    return res as PriceLevel[];
  }

  async addPriceLevel(item: PriceLevel) {
    const data: object = { PriceLevel: item };
    const res = await this.request('POST', '/pricelevel/add', data);
    return res;
  }

  async deletePriceLevel(id: number) {
    const res = await this.request('POST', `/pricelevel/delete?id=${id}`);
    return res;
  }

  async updatePriceLevel(id: number, item: PriceLevel) {
    const data: object = { PriceLevel: item };
    const res = await this.request('POST', `/pricelevel/update?id=${id}`, data);
    return res;
  }
}

export default new PriceLevelProvider();
