export const GET_COMPANY = 'GET_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const GET_BILLING = 'GET_BILLING';
export const EDIT_BILLING = 'EDIT_BILLING';
export const GET_SETTINGS = 'GET_SETTINGS';
export const EDIT_SETTINGS = 'EDIT_SETTINGS';

// Store

export interface StateCompany {
    company: Company;
    billing: Billing;
    settings: ServiceSettingsInterface;
    country: object[];
}

// Models

export interface Company {
    name: string;
    phone: string;
    address: string;
    countryId: number | null;
    currencyId: number | null;
    timeZoneId: number | null;
    formOwnershipId: number | null;
    nameOfficial: string;
}

export interface Billing {
    inn: string;
    kpp: string;
    ogrn: string;
    okpo: string;
    paymentAccount: string;
    bik: string;
    correctionalAccount: string;
    nameBank: string;
}

export class ServiceSettings implements ServiceSettingsInterface {
    allow_negative_storage_amount!: boolean;

    allow_part_auto_create!: boolean;

    allow_work_auto_create!: boolean;

    deadline!: number;

    default_repair_duration!: number;

    default_warrant_size!: number;

    easy_repair_transfer!: boolean;

    express_addition!: number;

    price_round_level!: number;

    price_round_type!: number;

    refill!: boolean;

    client_default_sms_agreement!: boolean;

    repair_price_gain!: number;

    sell_price_gain!: number;

    send_done_sms!: boolean;

    send_feedback_sms!: boolean;

    send_income_sms!: boolean;

    send_master_sms!: boolean;
}

export interface ServiceSettingsInterface {
    price_round_type: number;
    repair_price_gain: number;
    sell_price_gain: number;
    price_round_level: number;
    deadline: number;
    refill: boolean;
    default_warrant_size: number;
    default_repair_duration: number;
    allow_part_auto_create: boolean;
    allow_work_auto_create: boolean;
    allow_negative_storage_amount: boolean;
    easy_repair_transfer: boolean;
    send_income_sms: boolean;
    send_done_sms: boolean;
    send_feedback_sms: boolean;
    send_master_sms: boolean;
    express_addition: number;
}
