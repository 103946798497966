import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import PartProvider from '@/api/providers/part';
import InfoAboutPartOrWork from '@/components/info-about-part-or-work';
import Numbers from '../forms/numbers';

@Component({
  template: require('./table.html'),
  components: {
    InfoAboutPartOrWork,
    numbers: Numbers,
  },
})

export default class TableStorage extends Vue {
    @Prop() data!: object[];

    @Prop() deleteItem!: Function;

    @Prop() storage!: number;

    @Prop({ default: () => '' }) hiddenColumn!: string;

    @Prop({ default: () => false }) viewAmountsParts!: boolean;

    @Prop({ default: () => false }) flagsPriceColumnChanges!: boolean;

    @Action getVatList;

    @Action getRewardTypeList;

    @Action getWarrantTypeList;

    @Action getPartCategory;

    dataForModalView = {};

    showModalViewAboutWorkOrPart = false;

    titleInfoAboutPartOrWork = '';

    value_amounts: number | null = null;

    get storage_id() {
      if (this.storage) return this.storage;
      return null;
    }

    btnloader = false;

    mounted() {
      this.getVatList();
      this.getRewardTypeList();
      this.getWarrantTypeList();
    }

    viewingPart(row) {
      try {
        this.btnloader = true;
        PartProvider.getViewPart(row.part_id)
          .then((res) => {
            this.titleInfoAboutPartOrWork = 'Информация о товаре';
            this.dataForModalView = res.data;
            this.btnloader = false;
            this.getVatList();
            this.getRewardTypeList();
            this.getWarrantTypeList();
            this.getPartCategory('part');

            this.showModalViewAboutWorkOrPart = true;
          })
          .catch((error) => {
            this.btnloader = false;
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о товаре', item: '' });
            throw new Error(error);
          });
      } catch (err) {
        this.btnloader = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        throw new Error(err);
      }
    }

    closeInfoAboutPartOrWork() {
      this.showModalViewAboutWorkOrPart = false;
    }

    amounts(amounts) {
      const amounts_filters = amounts.filter((amount) => amount.storage_id === this.storage_id)[0];
      return amounts_filters.amount;
    }
}
