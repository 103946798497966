import UserStatusProvider from '../../../api/providers/user-status';

export default function getUserStatusList(this, page) {
  UserStatusProvider.getUserStatusList(page)
    .then((res) => {
      const user_status = res.data;

      user_status.forEach((el) => {
        switch (el.parent_id) {
          case 1: this.dropdown_for_custom_status_name_1.unshift(el); break;
          case 2: this.dropdown_for_custom_status_name_2.unshift(el); break;
          case 3: this.dropdown_for_custom_status_name_3.unshift(el); break;
          case 4: this.dropdown_for_custom_status_name_4.unshift(el); break;
          case 5: this.dropdown_for_custom_status_name_5.unshift(el); break;
          case 6: this.dropdown_for_custom_status_name_6.unshift(el); break;
          default: break;
        }
      });

      this.page_user_status_list += 1;

      if (this.page_user_status_list <= Number(res.pageCount)) {
        getUserStatusList.call(this, this.page_user_status_list);
      }
    }).catch((err) => {
      console.error(err);
    });
}
