import { PostingUpdateForm } from '@/types';
import BaseProvider from '../base-provider';

class PostingProvider extends BaseProvider {
  async getPostingList(page, form = null) {
    const expand: string[] = [
      'total_sum', 'total_count', 'user',
      'storage', 'provider', 'parts', 'to_pay',
      'payments.cashoperation.cashbox', 'last_comment.user'];
    let res;
    if (form) {
      const searchForm: string = this.buildUrl({ PostingSearchForm: form });
      res = await this.request('GET', `/posting/index?page=${page}&expand=${expand}&${searchForm}`);
    } else {
      res = await this.request('GET', `/posting/index?page=${page}&expand=${expand}`);
    }
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
    };
  }

  async getPostingView(id) {
    const expand: string[] = ['parts', 'user', 'storage', 'provider', 'parts.part.name', 'payments.cashoperation.cashbox', 'payments.creator'];
    const res = await this.request('GET', `/posting/view?id=${id}&expand=${expand}`);
    return res;
  }

  async sendPosting(item) {
    const data: object = { PostingForm: item };
    const res = await this.request('POST', '/posting/create', data);
    return res;
  }

  async addPaymentsPosting(item) {
    const expand: string[] = ['cashoperation.cashbox', 'creator'];
    const data: object = { PostingPaymentAddForm: item };
    const res = await this.request('POST', `/postingpayment/add?expand=${expand}`, data);
    return res;
  }

  async addPaymentReturnPosting(item) {
    const expand: string[] = ['cashoperation.cashbox', 'creator'];
    const data: object = { PostingPaymentReturnForm: item };
    const res = await this.request('POST', `/postingpayment/addreturn?expand=${expand}`, data);
    return res;
  }

  async deletePaymentsPosting(id) {
    try {
      return await this.request('POST', `/postingpayment/delete?id=${id}`);
    } catch (err) {
      throw new Error(err);
    }
  }

  async sendPostingPart(item) {
    const expand: string[] = ['part', 'user', 'storage', 'provider', 'parts.part.name'];
    const data: object = { PostingPart: item };
    const res = await this.request('POST', `/posting/addpart?expand=${expand}`, data);
    return res;
  }

  async changePostingPart(item) {
    const expand: string[] = ['part.name'];
    const data: object = { PostingPart: item };
    const res = await this.request('POST', `/posting/updatepart?id=${item.id}&expand=${expand}`, data);
    return res;
  }

  async revertPosting(id: number) {
    try {
      return await this.request('POST', `/posting/revert?id=${id}`);
    } catch (err) {
      throw new Error(err);
    }
  }

  async revertPostingPart(id: number) {
    try {
      return await this.request('POST', `/posting/revertpart?id=${id}`);
    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Обновляет поступление
   * @param  {PostingUpdateForm} form
   */
  async updatePosting(form: PostingUpdateForm) {
    try {
      const data: object = { PostingUpdateForm: form };

      const res = await this.request('POST', '/posting/update', data);
      return {
        data: res.data,
      };
    } catch (err) {
      throw new Error(err);
    }
  }

  async getPostingComments(item: PostingCommentSearchForm, page = 1) {
    const expand: string[] = ['user'];
    const searchForm: string = this.buildUrl({ PostingCommentSearchForm: item });

    const res = await this.request('GET', `/postingcomment/index?page=${page}&expand=${expand}${searchForm}`);
    return {
      data: res.data as PostingComment[],
      pageCount: Number(res.headers['x-pagination-page-count']),
    };
  }

  async sendPostingComment(form: PostingAddForm) {
    try {
      return await this.request('POST', '/postingcomment/add?expand=user', { PostingCommentForm: form });
    } catch (err) {
      throw new Error(err);
    }
  }

  async deletePostingComment(id: number) {
    try {
      return await this.request('POST', `/postingcomment/delete?id=${id}`);
    } catch (err) {
      throw new Error(err);
    }
  }
}

export default new PostingProvider();

export interface PostingCommentSearchForm {
    posting_id: number;
    include_deleted: boolean;
}
export interface PostingComment {
    id: number;
    posting_id: number;
    user_id: number;
    comment: string;
    created_at: number;
    is_deleted: boolean;
    user: object | null;
}

export interface PostingAddForm {
    posting_id: number;
    content: string;
}
