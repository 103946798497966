import Vue from 'vue';
import { ElNotificationComponent } from 'element-ui/types/notification.d';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Client } from '@/types';
import ClientsProvider from '../../api/providers/clients';

@Component({
  template: require('./index.html'),
})
export default class Voip extends Vue {
    @Action setIncoming;

    @Action removeIncomingByPhone;

    @Getter incomingCall;

    client: Client | null = null;

    notificationComponent: ElNotificationComponent | null = null;

    @Watch('incomingCall')
    async loadClientData() {
      if (this.incomingCall.length === 0) {
        if (this.notificationComponent !== null) {
          this.notificationComponent.close();
          this.notificationComponent = null;
        }
        return;
      }
      try {
        const lastCall = this.incomingCall[this.incomingCall.length - 1];
        const clientList = (
          await ClientsProvider.filterClient(1, { phone: lastCall.callerNumber })
        ).data;
        if (clientList.length > 0) {
          const client = clientList[0];
          this.client = client;
          this.notificationComponent = this.$notify({
            iconClass: 'fas fa-phone-alt fa-2x',
            customClass: 'incoming-call-container',
            title: 'Входящий звонок',
            dangerouslyUseHTMLString: true,
            message: this.notificationHtmlString,
            duration: 10000,
            position: 'bottom-left',
            onClose: () => {
              this.removeIncomingByPhone(lastCall.callerNumber);
            },
            onClick: () => {
              this.removeIncomingByPhone(lastCall.callerNumber);
              if (this.client?.id) {
                this.$router.push({ name: 'clients-view', params: { id: this.client.id?.toString() } });
              }
            },
          });
        } else {
          this.notificationComponent = this.$notify({
            iconClass: 'fas fa-phone-alt fa-2x',
            customClass: 'incoming-call-container',
            title: 'Входящий звонок',
            dangerouslyUseHTMLString: true,
            message: this.notificationHtmlString,
            duration: 10000,
            position: 'bottom-left',
            onClose: () => {
              this.removeIncomingByPhone(lastCall.callerNumber);
            },
            onClick: () => {
              this.removeIncomingByPhone(lastCall.callerNumber);
            },
          });
        }
      } catch (e) {
        console.error('Voip component', e);
        this.removeIncomingByPhone(this.incomingCall[this.incomingCall.length - 1].callerNumber);
      }
    }

    get notificationHtmlString() {
      return `
            <div class="incoming-call">
            <div>${this.callerPhone}</div>
            <div><strong>${this.callerName}</strong></div>
            ${

  (this.client !== null) ? '<div><small>(нажмите чтобы перейти к карточке клиента)</small></div>' : ''

}</div>`;
    }

    get callerName(): string {
      if (this.client === null) {
        return '(клиент не найден)';
      }
      if (this.client.type === 1) {
        return `${this.client.first_name} ${this.client.middle_name} ${this.client.last_name}`;
      } if (this.client.type === 2) {
        return this.client.name as string;
      }
      return '(не удалось определить клиента)';
    }

    get callerPhone(): string {
      if (this.incomingCall.length > 0) {
        const lastCall = this.incomingCall[this.incomingCall.length - 1];
        return lastCall.callerNumber;
      }
      return '';
    }
}
