import { TaskPriority } from '@/types';

export default class TaskSearchApiForm {
  id!: number;

  title!: string;

  description!: string;

  priority!: TaskPriority;

  status_id!: number;

  creator_id!: number;

  responsible_account_id!: number;

  created_at_interval!: number[];

  completed_at_interval!: number[];

  page = 1;
}
