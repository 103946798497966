import { MutationTree } from 'vuex';
import {
  PUSH_CALL_ERROR,

  PUSH_CALL_INFO, PUSH_CALL_SUCCESS,
  PUSH_CALL_WARNING,

  StatePush,
} from './types';

const mutations: MutationTree<StatePush> = {

  [PUSH_CALL_ERROR](state, { title, item }) {
    const newItem: object = { title, item };
    state.error = newItem;
  },
  [PUSH_CALL_SUCCESS](state, { title, item }) {
    const newItem: object = { title, item };
    state.success = newItem;
  },
  [PUSH_CALL_WARNING](state, { title, item }) {
    const newItem: object = { title, item };
    state.warning = newItem;
  },
  [PUSH_CALL_INFO](state, { title, item }) {
    const newItem: object = { title, item };
    state.info = newItem;
  },
};

export default mutations;
