import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import PartProvider from '../../api/providers/part';
import WorkProvider from '../../api/providers/work';
import PartForm from '../part-form';
import WorkForm from '../work/form';

interface Image {
  part_id?: number;
  file?: File;
}
@Component({
  template: require('./modal.html'),
  components: {
    'modal-product-form': PartForm,
    'modal-work-form': WorkForm,
  },
})

export default class ModalAddProduct extends Vue {
    @Action sendPartImage;

    @Prop({ default: () => 'parts' }) tabsProducts!: string;

    form: object = {
      name: '',
      warrant_type: 1,
      amount: 1,
    };

    validation: object = {};

    pic: Image = {};

    barcodes: object[] = [];

    loading = false;

    // api

    sendPart(item) {
      this.loading = true;
      PartProvider.sendPart(item)
        .then((res) => {
          const image: Image = {};
          image.part_id = res.data.id;
          image.file = this.pic.file;
          if (image.file) {
            this.sendPartImage(image);
          }
          this.closeDialogProduct();
          this.clearItem();

          this.$emit('newPart', res.data);

          this.barcodes = [];
          this.$store.commit('PUSH_CALL_SUCCESS', { title: `${item.name} успешно добавлен`, item: '' });
          this.loading = false;
        }).catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.validation = err.response.data;
          this.loading = false;
        });
    }

    sendWork(item) {
      this.loading = true;
      WorkProvider.sendWork(item)
        .then((res) => {
          this.clearItem();
          this.closeDialogProduct();

          this.$emit('newWork', res.data);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: `${item.name} успешно добавлен`, item: '' });
          this.loading = false;
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading = false;
        });
    }

    clearItem() {
      this.form = { name: '', warrant_type: 1, amount: 1 };
    }

    close() {
      this.clearItem();
    }

    closeDialogProduct() {
      this.$emit('dialogFlags', false);
    }

    add() {
      switch (this.tabsProducts) {
        case 'parts':
          ((this.$refs.partForm as PartForm).viewForm as Form).validate((valid) => {
            if (valid) {
              this.sendPart(this.form);
              return true;
            }
            return false;
          });
          break;
        default:
          ((this.$refs.workForm as PartForm).viewForm as Form).validate(
            (valid) => (valid ? this.sendWork(this.form) : false),
          );
      }
    }
}
