import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Breadcrumb, Posting } from '@/types';
import PostingProvider from '../../api/providers/posting';
import Numbers from '../../components/forms/numbers';
import ModalCatalog from '../../components/modal-catalog';
import TableStorage from '../../components/table-storage';
import WarehouseTable from '../../components/warehouse-table';

enum TYPE_OF_BUILD {
  price = 'price',
  sticker = 'sticker'
}

interface NewForm {
  parts;
  storage_id;
}
@Component({
  template: require('./index.html'),
  components: {
    'warehouse-table': WarehouseTable,
    'modal-catalog': ModalCatalog,
    'table-storage': TableStorage,
    numbers: Numbers,
  },
})
export default class PartBuildPrice extends Vue {
    @Action addBreadcrumb;

    newForm: NewForm = { storage_id: 1, parts: [] };

    loading = false;

    posting_new_item_list: object[] = [];

    part_price_tag_new_item: Posting[] = [];

    parts_price_tag_list: object[] = [];

    // api
    type: TYPE_OF_BUILD = TYPE_OF_BUILD.price;

    getPostingView(id) {
      PostingProvider.getPostingView(id)
        .then((res) => {
          if (res.data.parts) this.posting_new_item_list = res.data.parts;
        })
        .catch((err) => console.error(err));
    }

    sendPriceTagItem(item) {
      this.part_price_tag_new_item.push(item);
    }

    deletePriceTagItem(item) {
      const index = this.part_price_tag_new_item.findIndex(
        (i) => i.id === item.id || i.part_id === item.id,
      );
      this.part_price_tag_new_item.splice(index, 1);
    }

    // end api

    mounted() {
      const { query } = this.$route;
      const breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Склад', link: '/part' }];
      if (this.$route.name === 'part-buildstickers') {
        this.type = TYPE_OF_BUILD.sticker;
      }
      if (this.type === 'sticker') {
        breadcrumb.push({ id: 2, section: 'Печать этикеток', link: null });
        document.title = 'Печать этикеток';
      } else {
        breadcrumb.push({ id: 2, section: 'Печать ценников', link: null });
        document.title = 'Печать ценников';
      }
      this.addBreadcrumb(breadcrumb);
      this.createForm();

      if (Object.prototype.hasOwnProperty.call(query, 'posting')) {
        this.getPostingView(Number(query.posting));
      }
    }

    loadingPartsList(parts) {
      this.parts_price_tag_list = parts.map((i) => ({
        id: i.id,
        name: i.name,
        part_id: i.id,
        amount: 1,
        price: i.sell_price === null ? 0 : i.sell_price,
        amounts: i.amounts,
      }));
    }

    createForm() {
      const domain = process.env.NODE_ENV === 'production' ? 'printing.servix.io' : 'printing-dev.servix.io';
      const form = document.createElement('form');
      form.setAttribute('method', 'post');

      if (this.type === 'sticker') {
        form.setAttribute('action', `https://${domain}/print/partstickers?token=${localStorage.access}`);
      } else {
        form.setAttribute('action', `https://${domain}/print/partprices?token=${localStorage.access}`);
      }
      form.setAttribute('target', '_blank');

      document.getElementsByClassName('build-price')[0].appendChild(form);
    }

    get sum() {
      /* eslint-disable-next-line no-param-reassign */
      return this.newForm.parts.reduce((sum, current) => sum += current.price * current.amount, 0);
    }

    printPriceTage() {
      const form = document.getElementsByTagName('form')[0];

      if (this.newForm.parts.length !== 0) {
        this.newForm.parts.forEach((item, index) => {
          const input = document.createElement('input');
          const input_2 = document.createElement('input');
          const input_3 = document.createElement('input');

          input.setAttribute('name', `PartPrices[items][${index}][part_id]`);
          input.setAttribute('type', 'hidden');
          input.setAttribute('value', item.part_id);

          input_2.setAttribute('name', `PartPrices[items][${index}][amount]`);
          input_2.setAttribute('type', 'hidden');
          input_2.setAttribute('value', item.amount);

          input_3.setAttribute('name', `PartPrices[items][${index}][price]`);
          input_3.setAttribute('type', 'hidden');
          input_3.setAttribute('value', item.price);

          form.appendChild(input);
          form.appendChild(input_2);
          form.appendChild(input_3);
        });

        form.submit();
      } else {
        this.$message({
          message: 'Заполните таблицу "Ценники в печать"',
          type: 'warning',
        });
      }
    }

    @Watch('part_price_tag_new_item')
    onChangeItem(val: object[]) {
      this.newForm.parts = [];
      this.newForm.parts = [...val];
    }

    @Watch('posting_new_item_list')
    postingItem(item) {
      item.forEach((elem) => {
        this.sendPriceTagItem({
          part_id: elem.part_id,
          id: elem.id,
          amount: elem.amount,
          name: elem.part.name,
          price: elem.part.sell_price,
          posting_id: elem.posting_id,
        });
      });
    }
}
