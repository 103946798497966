import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  Breadcrumb, Repair, Client,
} from '@/types';
import ClientType from '@/types/client-type';
import ClientsProvider from '../../api/providers/clients';
import SmsProvider, { SendSmsForm } from '../../api/providers/sms';
import ViewDate from '../../components/forms/date';
import NumberPhone from '../../components/forms/number-phone';
import Numbers from '../../components/forms/numbers';
import ViewUserName from '../../components/forms/user-name';
import changeClientCompany from './api/changeClientCompany';
import changeClientPerson from './api/changeClientPerson';
import getRepairList from './api/getRepairList';
import getSellList from './api/getSellList';
import getViewClient from './api/getViewClient';

interface SendSmsError {
  text?: string;
  method?: string;
  client_id?: string;
}

@Component({
  template: require('./index.html'),
  components: {
    'user-name': ViewUserName,
    phone: NumberPhone,
    'total-price': Numbers,
    'sell-date': ViewDate,
  },
})
export default class ClientsViewPage extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action showErrorPush;

    @Action showSuccessPush;

    @Getter btnloader;

    @Getter user;

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Клиенты', link: '/clients' },
    ];

    loading = true;

    loading_table_repair = false;

    loading_table_sell = false;

    dialog_delete = false;

    pop_sell = false;

    pop_repair = false;

    new_item: object = {};

    client: Client | null = null;

    data_feedback: object[] = [];

    data_repair: Repair[] = [];

    badClientReasons: string[] = [];

    data_sell: Repair[] = [];

    page_current_sell_list: number | null= null;

    page_count_sell_list: number | null = null;

    page_current_repair_list: number | null = null;

    page_count_repair_list: number | null = null;

    // Флаг отображения модалки отправки СМС
    showSendSmsModal = false;

    // текст смс для формы
    sendSmsText = '';

    // ошибка отправки смс
    sendSmsError: SendSmsError = {};

    sendSmsMethod = 'auto';

    refreshForm = ''

    sendSmsMethodOptions = [{
      value: 'auto',
      label: 'Автоматически',
    }, {
      value: 'sms',
      label: 'SMS',
    }, {
      value: 'voice',
      label: 'Голосовой звонок',
    }];

    get sortedDataRepair() {
      return this.data_repair.map((x) => x).sort((a, b) => {
        if (a.income_date > b.income_date) return -1;
        if (a.income_date < b.income_date) return 1;
        return 0;
      });
    }

    get sortedDataSell() {
      return this.data_sell.map((x) => x).sort((a, b) => {
        if (a.created_at > b.created_at) return -1;
        if (a.created_at < b.created_at) return 1;
        return 0;
      });
    }

    get userCanManageClients() {
      return this.user.rights && this.user.rights.can_manage_clients;
    }

    mounted() {
      const data_repair = {
        page: 1, 'per-page': 20, client_id: Number(this.$route.params.id), status_ids: '1,2,3,4,5,6',
      };
      const data_sell = { page: 1, 'per-page': 20, client_id: Number(this.$route.params.id) };

      this.resetActionBtns();
      getViewClient.call(this, this.$route.params.id);
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'Клиент';
      this.$root.$on('onDialog', () => {
        this.dialog_delete = true;
      });
      getRepairList.call(this, data_repair);
      getSellList.call(this, data_sell);
    }

    destroyed() {
      this.addActionBtn([]);
    }

    checkItem = (item) => !!(item !== null && item !== '')

    onItem() {
      this.new_item = JSON.parse(JSON.stringify(this.client));
    }

    checkRepairObject = (object) => {
      const str: string [] = [];

      if (object) {
        if (object.length !== 0) {
          object.forEach((device) => str.push(`${device.vendor} ${device.model}`));
          return str.join(',');
        }
      }
      return '–';
    }

    formationUsersName = (row) => {
      if (row) {
        const user_name = [row.last_name, row.first_name, row.middle_name]
          .filter((user) => !!user);
        if (user_name.length === 0) {
          return '-';
        }
        return user_name.length === 3 ? `${user_name[0]} ${user_name[1][0]}. ${user_name[2][0]}.` : user_name.join(' ');
      }
      return '-';
    }

    onDelete(item) {
      this.deleteClient(item.id).then(() => {
        this.dialog_delete = false;
        this.$router.push('/clients');
      });
    }

    async deleteClient(id: number) {
      this.$store.commit('BTN_LOADER', true);
      try {
        const res = await ClientsProvider.deleteClient(id);
        this.$store.commit(
          'PUSH_CALL_SUCCESS',
          { title: 'Успешно удалено', item: res.data.name === null ? `${res.data.last_name} ${res.data.first_name} ${res.data.middle_name}` : res.data.name },
        );
        this.$store.commit('BTN_LOADER', false);
        return res;
      } catch (err) {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        this.$store.commit('BTN_LOADER', false);
        throw err;
      }
    }

    handleRowRepair(row) {
      if (row.id) this.$router.push(`/repair/view/${row.id}`);
    }

    handleRowSell(row) {
      if (row.seller_id) this.$router.push(`/sell/view/${row.id}`);
    }

    lastBreadCrumb(item) {
      if (this.breadcrumb.length > 2) this.breadcrumb.pop();
      this.breadcrumb.push(item);
      this.addBreadcrumb(this.breadcrumb);
    }

    disabledActions(client) {
      if (client.enabled === false) {
        this.addActionBtn([
          {
            id: 1, type: 'edt', title: 'Изменить', link: `/clients/edit/${this.$route.params.id}`, icon: 'fa-edit', disabled: true,
          },
          {
            id: 2, type: 'del', title: 'Удалить', link: 'onDialog', icon: 'fa-times', disabled: true,
          },
        ]);
      }
    }

    updateClient(client) {
      this.client = client;

      this.data_feedback = client.feedback;
      const name = client.type === 1 ? `${client.last_name} ${client.first_name} ${client.middle_name}` : client.name;
      const item = { id: 3, section: name, link: null };

      ClientsProvider.sendClientCheckBlacklist({ phone: `${this.client?.unrestricted_phone}` })
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.badClientReasons = response.data.map((x) => x.reason);
          }
        })
        .catch((err) => {
          console.error('Не удалось проверить по базе ненадежных клиентов', err);
        });

      this.lastBreadCrumb(item);
      document.title = `Клиент ${name}`;
      this.disabledActions(client);
      this.$nextTick(() => {
        this.loading = false;
      });
    }

    editDiscount() {
      if (this.client?.type === ClientType.PERSONAL) {
        changeClientPerson.call(this, this.new_item);
      } else if (this.client?.type === ClientType.COMPANY) {
        changeClientCompany.call(this, this.new_item);
      }
      this.pop_repair = false;
      this.pop_sell = false;
    }

    @Watch('user')
    resetActionBtns() {
      if (this.userCanManageClients) {
        this.addActionBtn([
          {
            id: 1, type: 'edt', title: 'Изменить', link: `/clients/edit/${this.$route.params.id}`, icon: 'fa-edit',
          },
          {
            id: 2, type: 'del', title: 'Удалить', link: 'onDialog', icon: 'fa-times',
          },
        ]);
      } else {
        this.addActionBtn([]);
      }
    }

    /**
     * Метод открытия модалки отправки смс
     */
    openSmsModal() {
      this.showSendSmsModal = true;
    }

    /**
     * Метод отправки SMS
     */
    sendSms() {
      if (!this.client?.id) {
        this.$store.commit('PUSH_CALL_ERROR', { tittle: 'Не указан клиент', item: '' });
        this.refreshForm += '1';
        return;
      }
      if (!this.client?.sms_agreement) {
        this.$store.commit('PUSH_CALL_ERROR', { tittle: 'Клиент не давал согласия на получение SMS', item: '' });
        this.refreshForm += '1';
        return;
      }
      if (this.sendSmsText.trim().length === 0) {
        this.sendSmsError.text = 'Введите текст';
        this.refreshForm += '1';
        return;
      }
      const form: SendSmsForm = {
        client_id: this.client?.id,
        text: this.sendSmsText,
        method: this.sendSmsMethod,
      };
      this.loading = true;
      SmsProvider.sendSms(form)
        .then((res) => {
          if (res.status === 204) {
            this.showSuccessPush({ title: 'SMS отправлена' });
            this.loading = false;
            this.showSendSmsModal = false;
            this.sendSmsText = '';
          }
        })
        .catch((err) => {
          const errorData = err?.response?.data;
          this.loading = false;
          console.log(err.response);
          if (err.response.data) {
            if (errorData.message) {
              this.$store.commit('PUSH_CALL_ERROR', { tittle: errorData.message, item: '' });
            } else if (err.response.status && err.response.status === 422) {
              if (err.response.data.client_id) {
                this.$store.commit('PUSH_CALL_ERROR', { tittle: errorData.client_id[0], item: '' });
              }
              if (err.response.data.text) {
                [this.sendSmsError.text] = errorData.text;
              }
              if (err.response.data.method) {
                [this.sendSmsError.method] = err.response.data.method;
              }
            }
          }
        });
    }
}
