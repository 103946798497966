import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import SalaryOperation from '../../api/providers/salaryoperation';
import SalaryTable from '../../components/salary-table';
import SalaryTransferViewTable from '../../components/salary-transfer-view-table';

@Component({
  template: require('./index.html'),
  components: {
    SalaryTransferViewTable,
    SalaryTable,
  },
})

export default class SalaryTransferViewPage extends Vue {
    @Action deleteSalaryTransfer;

    @Getter viewStuff;

    @Getter fullEmployeeName;

    @Action getViewStuff;

    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action resetBreadcrumb;

    transferId!: number;

    breadcrumbTextTransferId = '';

    breadcrumbTextEmployeeName = '';

    salaryOperations: SalaryOperation[] = [];

    loadingError: string|null = null;

    loading = false;

    loadingProgress = 0;

    created() {
      this.transferId = Number(this.$route.params.transfer_id);
      this.getViewStuff(this.$route.params.employee_id);
      this.setBreadcrumb();
      this.loadOperations();
    }

    async loadOperations() {
      this.salaryOperations = [];
      this.loading = true;
      this.loadingError = null;
      this.loadingProgress = 0;
      let totalPages = 1;
      const transfer_id = this.transferId;
      try {
        const res = await SalaryOperation.salaryOperationIndex({
          form: {
            transfer_id,
          },
          collectable: {
            pageNum: 1,
          },
        });
        totalPages = res.pageCount;

        const pageOperations = res.data as SalaryOperation[];
        pageOperations.forEach((x) => {
          this.salaryOperations.push(x);
        });
        if (totalPages > 1) {
          const promiseRequest: object[] = [];

          for (let i = 2; i <= totalPages; i++) {
            promiseRequest.push(SalaryOperation.salaryOperationIndex({
              form: {
                transfer_id,
              },
              collectable: {
                pageNum: i,
              },
            }).then((resFromPage) => {
              this.loadingProgress = Math.round((resFromPage.currentPage / totalPages) * 100);
              return resFromPage.data;
            }));
          }

          await Promise.all(promiseRequest)
            .then((resultes) => {
              resultes.forEach((result) => {
                (result as SalaryOperation[]).forEach((element) => {
                  this.salaryOperations.push(element);
                });
              });
              this.salaryOperations.sort((a, b) => b.created_at - a.created_at);
            });
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.loadingError = err;
      }
    }

    async onDeleteSalaryTransfer() {
      const status = await this.deleteSalaryTransfer({
        id: this.$route.params.transfer_id,
      });
      if (status === 0) {
        this.$router.back();
      }
    }

    @Watch('viewStuff')
    updateBreadcrumb() {
      this.breadcrumbTextEmployeeName = this.fullEmployeeName;
      this.breadcrumbTextTransferId = `Информация о выплате №${this.$route.params.transfer_id}`;
      this.setBreadcrumb();
    }

    // helpers

    setBreadcrumb() {
      this.addActionBtn([]);
      this.addBreadcrumb([
        {
          id: 1,
          section: 'Финансы',
          link: '/salary',
          textContent: 'Информация о заработной плате',
        },
        { id: 2, section: this.breadcrumbTextEmployeeName, link: null },
        { id: 3, section: this.breadcrumbTextTransferId, link: null }]);
      document.title = `Выплата заработной платы №${this.breadcrumbTextTransferId}`;
    }
}

export interface SalaryOperation {
    act_id: number|null;
    act_type: number;
    comment: string|null;
    created_at: number;
    id: number;
    payed: boolean;
    sum: number|null;
    system_info: string|null;
    user_id: number;
}
