import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionBtn, Breadcrumb } from '@/types';
import BillingProvider from '../../api/providers/billing';
import ViewDate from '../forms/date';

enum ActTypeTab {
  all,
  posting,
  replenishment,
  voice,
  cancel,
  sms,
  license
}
@Component({
  template: require('./index.html'),
  components: {
    date: ViewDate,
  },
})
export default class BalanceList extends Vue {
    @Action addBreadcrumb;

    @Action addActionBtn;

    @Action getSettings;

    @Action updateSettings;

    @Getter btnloader;

    @Getter settings

    @Getter user;

    actType = 0;

    actionBtn: ActionBtn[] = [{
      id: 1, type: 'add', title: 'Пополнить баланс', link: '/service/balance/pay', icon: 'fa-plus',
    }];

    page = 1;

    totalPages = 1;

    transactions = [];

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Биллинг', link: null },
      { id: 3, section: 'Баланс', link: null },
    ];

    isBalanceNotificationLimit = false;

    balanceNotificationLimit = 0;

    /** геттер может ли сотрудник управлять компанией
     * @returns boolean
     */
    get isUserCanUpdateCompanyDetails(): boolean {
      return this?.user?.rights?.can_update_company_details
        ? this.user.rights.can_update_company_details : false;
    }

    /**
     * геттер активен ли инпут лимита баланса
     * @returns boolean
     */
    get isNotificationInputDisabled(): boolean {
      return !this?.user.rights?.can_update_company_details || !this.isBalanceNotificationLimit;
    }

    mounted() {
      this.getSettings();
      this.balanceNotificationLimitWasChanged();
      this.addBreadcrumb(this.breadcrumb);
      this.addActionBtn(this.actionBtn);
      document.title = 'Баланс';
      this.checkPage();
      this.onPageChanged();
    }

    destroyed() {
      this.addActionBtn([]);
    }

    changeActType() {
      const act_type = this.actType;
      this.page = 1;
      switch (act_type) {
        case 0: this.load(); break;
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6: this.loadWithFilter(act_type, 1); break;
        default: {
          this.actType = 0;
          this.load();
        }
      }
    }

    checkPage() {
      if (this.$route.params.page) {
        this.page = Number(this.$route.params.page);
      }
      if (this.$route.params.tab) {
        const { tab } = this.$route.params;
        switch (tab) {
          case 'all': this.actType = 0; break;
          case 'posting': this.actType = 1; break;
          case 'replenishment': this.actType = 2; break;
          case 'voice': this.actType = 3; break;
          case 'cancel': this.actType = 4; break;
          case 'sms': this.actType = 5; break;
          case 'license': this.actType = 6; break;
          default: this.actType = 0;
        }
      }
    }

    loadWithFilter(act_type: number, page = 1) {
      this.page = page;
      this.$route.params.page = String(this.page);
      const form = {
        act_type,
      };
      BillingProvider.getBalanceTransactionsWithFilter(form, this.page)
        .then((res) => {
          this.changeUrl();
          this.transactions = res.data;
          this.totalPages = Number(res.pageCount);
          window.scroll({ top: 0 });
        })
        .catch((err) => console.error(err));
    }

    load() {
      this.$route.params.page = String(this.page);
      BillingProvider.getBalanceTransactions(this.page)
        .then((resp) => {
          this.changeUrl();
          this.transactions = resp.data;
          this.totalPages = Number(resp.pageCount);
          window.scroll({ top: 0 });
        })
        .catch((err) => console.error(err));
    }

    @Watch('$route.params')
    reloadPage() {
      this.checkPage();
      this.onPageChanged();
    }

    changeUrl() {
      const tab = ActTypeTab[this.actType];
      if (this.$route.path !== `/service/billing/balance/${tab}/${this.page}`) {
        this.$router.replace(`/service/billing/balance/${tab}/${this.page}`);
      }
    }

    onPageChanged() {
      const act_type = this.actType;
      switch (act_type) {
        case 0: this.load(); break;
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6: this.loadWithFilter(act_type, this.page); break;
        default: {
          this.actType = 0;
          this.load();
        }
      }
    }

    /**
     * Меняет isBalanceNotificationLimit в зависимости от данных со стора
     */
    @Watch('settings.balance_notification_limit')
    balanceNotificationLimitWasChanged() {
      this.balanceNotificationLimit = this.settings.balance_notification_limit;
      if (this.settings.balance_notification_limit
        || this.settings.balance_notification_limit === 0) {
        this.isBalanceNotificationLimit = true;
      } else {
        this.isBalanceNotificationLimit = false;
      }
    }

    /**
     * обновляет значение balance_notification_limit у компании
     */
    changeBalanceNotificationLimit() {
      const newSetting = { ...this.settings };
      if (this.isBalanceNotificationLimit) {
        newSetting.balance_notification_limit = this.balanceNotificationLimit;
      } else {
        newSetting.balance_notification_limit = null;
        this.balanceNotificationLimit = 0;
      }
      this.updateSettings(newSetting);
    }
}
