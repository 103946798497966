import { MutationTree } from 'vuex';
import {
  CLEAR_USER, GET_USER_ME,

  GET_VALID_NEW_PASSWORD,

  StateUser, UPDATE_USER,
} from './types';

function setChatixUser(me) {
  if (window) if (me === null) window.chatix.setVisitor({});
  if (window && window.chatix) {
    const user = {
      name: `${me.last_name} ${me.first_name} ${me.middle_name}`,
      email: me.email,
      fields: {
        Сервис: `${me.service_id}: ${me.service.name}`,
        'Имя пользователя': me.username,
        ID: me.id,
      },
    };
    if (window.chatix.getIsConnected()) {
      window.chatix.setVisitor(user);
    } else {
      window.chatixWidget.onConnected = () => {
        window.chatix.setVisitor(user);
      };
    }
  }
}

const mutations: MutationTree<StateUser> = {

  [GET_USER_ME](state, item) {
    state.user = item;
    setChatixUser(item);
  },

  [UPDATE_USER](state, item) {
    state.updateUser = item;
  },

  [CLEAR_USER](state) {
    state.user = {};
  },

  [GET_VALID_NEW_PASSWORD](state, { item }) {
    state.validNewPassword = item;
  },
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chatix: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chatixWidget: any;
  }
}

export default mutations;
