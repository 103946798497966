import { MutationTree } from 'vuex';
import {
  GET_TASK_STATUS,

  TaskStatusState,
} from './types';

const mutations: MutationTree<TaskStatusState> = {

  [GET_TASK_STATUS](state, { item, pageCount, pageCurrent }) {
    state.statuses = item;
    state.pageStatusCount = pageCount;
    state.pageStatusCurrent = pageCurrent;
  },

  // [GET_VALID_STORAGE](state, { item }) {
  //     state.validStorage = item;
  // },
  //
  // [INSERT_STORAGE](state, { item }) {
  //     state.storages.push(item);
  // },
  //
  // [EDIT_STORAGE](state, { item }) {
  //     const index = state.storages.findIndex( (i) => i['id'] === item.id);
  //     state.storages.splice(index, 1);
  //     state.storages.splice(index, 0, item);
  // },
  //
  // [DELETE_STORAGE](state, { item }) {
  //     const index = state.storages.findIndex( (i) => i['id'] === item.id);
  //     state.storages.splice(index, 1);
  // }

};

export default mutations;
