import { GetterTree } from 'vuex';
import { StateSms } from './types';

const getters: GetterTree<StateSms, object> = {
  sms: (state) => state.sms,
  smsPageCurrent: (state) => state.smsPageCurrent,
  smsPageCount: (state) => state.smsPageCount,
};

export default getters;
