import { GetterTree } from 'vuex';
import { StateRepair } from './types';

const getters: GetterTree<StateRepair, object> = {
  repair: (state) => state.repair,
  repairTransfer: (state) => state.repairTransfer,
  pageCurrentRepairTransfer: (state) => state.pageCurrentRepairTransfer,
  pageCountRepair: (state) => state.pageCountRepair,
  pageCurrentRepair: (state) => state.pageCurrentRepair,
  pageCountRepairTransfer: (state) => state.pageCountRepairTransfer,
  updatedTransferRepair: (state) => state.updatedTransferRepair,
  newRepairTransfer: (state) => state.newRepairTransfer,
  newRepairWork: (state) => state.newRepairWork,
  viewRepair: (state) => state.viewRepair,
  addRepair: (state) => state.addRepair,
  repairAssignMaster: (state) => state.repairAssignMaster,
  deleteRepair: (state) => state.deleteRepair,
  repairComment: (state) => state.repairComment,
  repairCommentsLog: (state) => state.repairCommentsLog,
  viewComment: (state) => state.viewComment,
  repairUpdate: (state) => state.repairUpdate,
  repairPaymentAdd: (state) => state.repairPaymentAdd,
  delPayment: (state) => state.delPayment,
  repairParts: (state) => state.repairParts,
  discount: (state) => state.discount,
  addition: (state) => state.addition,
  delDiscount: (state) => state.delDiscount,
  delAddition: (state) => state.delAddition,
  repairAgreement: (state) => state.repairAgreement,
  repairApproveAgreement: (state) => state.repairApproveAgreement,
  repairDeclineAgreement: (state) => state.repairDeclineAgreement,
  repairDismissJob: (state) => state.repairDismissJob,
  repairDone: (state) => state.repairDone,
  repairRevision: (state) => state.repairRevision,
  repairEject: (state) => state.repairEject,
  repairRestart: (state) => state.repairRestart,
  repairDelay: (state) => state.repairDelay,
  repairStart: (state) => state.repairStart,
  repairContinue: (state) => state.repairContinue,
  repairLogs: (state) => state.repairLogs,
  pageCountLogs: (state) => state.pageCountLogs,
  repairFiles: (state) => state.repairFiles,
  pageCountFiles: (state) => state.pageCountFiles,
  repairAddFiles: (state) => state.repairAddFiles,

  repairValid: (state) => state.repairValid,

  ListRepairTableLayout: (state) => state.ListRepairTableLayout,

  repairActiveColumn: (state) => {
    const active: object[] = [];
    state.repairActiveColumn.forEach(
      (i) => active.push(state.repairAllColumn.find((j) => j.value === i) as object),
    );
    return active;
  },
  repairAllColumn: (state) => state.repairAllColumn,
  repairNoActiveColumn: (state) => state.repairAllColumn.filter(
    (i) => state.repairActiveColumn.indexOf(i.value) < 0,
  ),
  newRepairLayout: (state) => state.newRepairLayout,
};

export default getters;
