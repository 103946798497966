import { RepairColumn, RepairTransfer } from '@/types';

export const GET_REPAIR = 'GET_REPAIR';
export const GET_REPAIR_PAGE = 'GET_REPAIR_PAGE';
export const GET_REPAIR_VIEW = 'GET_REPAIR_VIEW';
export const GET_REPAIR_TRANSFER = 'GET_REPAIR_TRANSFER';
export const GET_REPAIR_STAT = 'GET_REPAIR_STAT';

export const SEND_REPAIR_ASSIAGNMASTER = 'SEND_REPAIR_ASSIAGNMASTER';
export const SEND_REPAIR_DELETE = 'SEND_REPAIR_DELETE';
export const SEND_REPAIR_ADD = 'SEND_REPAIR_ADD';
export const SEND_REPAIR_UPDATE = 'SEND_REPAIR_UPDATE';

export const SEND_REPAIR_COMMENT = 'SEND_REPAIR_COMMENT';
export const GET_REPAIR_COMMENTS = 'GET_REPAIR_COMMENTS';
export const GET_VIEW_COMMENT = 'GET_VIEW_COMMENT';

export const ADD_REPAIR_PAYMENT = 'ADD_REPAIR_PAYMENT';
export const DEL_REPAIR_PAYMENT = 'DEL_REPAIR_PAYMENT';
export const GET_REPAIR_PAYMENT_LIST = 'GET_REPAIR_PAYMENT_LIST';

export const GET_REPAIR_DISCOUNT_LIST = 'GET_REPAIR_DISCOUNT_LIST';
export const ADD_REPAIR_DISCOUNT = 'ADD_REPAIR_DISCOUNT';
export const ADD_REPAIR_DISCOUNT_UPDATE = 'ADD_REPAIR_DISCOUNT_UPDATE';
export const SEND_REPAIR_DISCOUNT_DELETE = 'SEND_REPAIR_DISCOUNT_DELETE';

export const ADD_REPAIR_ADDITION = 'ADD_REPAIR_ADDITION';
export const SEND_REPAIR_ADDITION_DELETE = 'SEND_REPAIR_ADDITION_DELETE';

export const ADD_REPAIR_TRANSFER = 'ADD_REPAIR_TRANSFER';
export const REPAIR_PARTS_CLEAR = 'REPAIR_PARTS_CLEAR';
export const SEND_CHANGE_STATUS_REPAIR_TRANSFER = 'SEND_CHANGE_STATUS_REPAIR_TRANSFER';

export const SEND_REPAIR_TO_AGREEMENT = 'SEND_REPAIR_TO_AGREEMENT';
export const SEND_REPAIR_APPROVE_AGREEMENT = 'SEND_REPAIR_APPROVE_AGREEMENT';
export const SEND_REPAIR_DECLINE_AGREEMENT = 'SEND_REPAIR_DECLINE_AGREEMENT';
export const SEND_REPAIR_DISMISS_JOB = 'SEND_REPAIR_DISMISS_JOB';
export const SEND_REPAIR_DONE = 'SEND_REPAIR_DONE';
export const SEND_REPAIR_REVISION = 'SEND_REPAIR_REVISION';
export const SEND_REPAIR_RESTART = 'SEND_REPAIR_RESTART';
export const SEND_REPAIR_DELAY = 'SEND_REPAIR_DELAY';
export const SEND_REPAIR_EJECT = 'SEND_REPAIR_EJECT';
export const SEND_REPAIR_START = 'SEND_REPAIR_START';
export const SEND_REPAIR_CONTINUE = 'SEND_REPAIR_CONTINUE';

export const GET_REPAIR_LOG = 'GET_REPAIR_LOG';

export const GET_REPAIR_FILE = 'GET_REPAIR_FILE';
export const SEND_REPAIR_ADD_FILES = 'SEND_REPAIR_ADD_FILES';

export const GET_REPAIR_TABLE_LAYOUT = 'GET_REPAIR_TABLE_LAYOUT';
export const SEND_REPAIR_TABLE_LAYOUT = 'SEND_REPAIR_TABLE_LAYOUT';

export const REPAIR_VALIDATION = 'REPAIR_VALIDATION';
export const SEND_REPAIR_CUSTOM_STATUS = 'SEND_REPAIR_CUSTOM_STATUS';
export const CLEAR_REPAIR_LIST = 'CLEAR_REPAIR_LIST';

export const CLEAR_VALIDATE_REPAIR = 'CLEAR_VALIDATE_REPAIR';

export interface StateRepair {
    repair: object[];
    repairTransfer: RepairTransfer[];
    repairUpdate: object;
    pageCountRepair: number;
    pageCurrentRepair: number;
    pageCountRepairTransfer: number;
    pageCurrentRepairTransfer: number;
    updatedTransferRepair: object;
    newRepairTransfer: object;
    newRepairWork: object;
    addRepair: object;
    viewRepair: object;
    repairCommentsLog: object[];
    repairAssignMaster: object;
    deleteRepair: object;
    repairComment: object;
    viewComment: object;
    repairPaymentAdd: object;
    delPayment: object;
    repairParts: object[];
    discount: object;
    addition: object;
    delDiscount: object;
    delAddition: object;
    repairAgreement: object;
    repairApproveAgreement: object;
    repairDeclineAgreement: object;
    repairDismissJob: object;
    repairDone: object;
    repairRevision: object;
    repairEject: object;
    repairRestart: object;
    repairDelay: object;
    repairStart: object;
    repairContinue: object;
    repairLogs: object[];
    repairFiles: object;
    pageCountLogs: number;
    pageCountFiles: number;
    repairAddFiles: object;
    ListRepairTableLayout: object;
    repairAllColumn: RepairColumn[];
    repairActiveColumn: RepairColumn[];
    newRepairLayout: object;
    repairValid: object;
}
