import { GetterTree } from 'vuex';
import { StateStateOption } from './types';

const getters: GetterTree<StateStateOption, object> = {
  stateoptionList: (state) => state.stateoptionList,
  addStateOptionList: (state) => state.addStateOptionList,
  updateStateOptionList: (state) => state.updateStateOptionList,
  deleteStateOptionList: (state) => state.deleteStateOptionList,
  sendStateOptionPriority: (state) => state.sendStateOptionPriority,
};

export default getters;
