const state = {
  deviceValid: {},
  validImei: {},

  deviceType: [],
  deviceVendor: [],
  deviceModel: [],

  deviceTypeList: [],
  deviceVendorList: [],
  deviceModelList: [],

  searchTypeList: [],
  searchVendorList: [],
  searchModelList: [],

  changeDiviceVendor: {},

};
export default state;
