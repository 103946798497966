import { Provider } from '@/types';
import BaseProvider from '../base-provider';

class ProviderProvider extends BaseProvider {
  async getProviderList(page) {
    const res = await this.request('GET', `/provider/index?page=${page}`);
    return {
      data: res.data,
      pageCount: res.headers['x-pagination-page-count'],
      pageCurrent: res.headers['x-pagination-current-page'],
    };
  }

  async getFullProviderList() {
    const res = await this.allPageReuest('GET', '/provider/index');
    return res as Provider[];
  }

  async getViewProvider(id) {
    const res = await this.request('GET', `/provider/view?id=${id}`);
    return res;
  }

  async sendProvider(item) {
    const data: object = { Provider: item };
    const res = await this.request('POST', '/provider/add', data);
    return res;
  }

  async changeProvider(id, item) {
    const data: object = { Provider: item };
    const res = await this.request('POST', `/provider/update?id=${id}`, data);
    return res;
  }

  async deleteProvider(id) {
    const res = await this.request('POST', `/provider/delete?id=${id}`);
    return res;
  }
}

export default new ProviderProvider();
