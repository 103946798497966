import Vue from 'vue';
import { Form } from 'element-ui';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PartProvider from '@/api/providers/part';
import InfoAboutPartOrWork from '@/components/info-about-part-or-work';
import ChooseAnotherProvider from '@/components/choose-another-provider';
import {
  Posting, Cashbox, RepairPayment, PostingPart, PostingUpdateForm,
} from '@/types';
import CashProvider from '../../api/providers/cash';
import PostingProvider, { PostingAddForm, PostingComment } from '../../api/providers/posting';
import ViewDate from '../../components/forms/date';
import Numbers from '../../components/forms/numbers';
import ViewUserName from '../../components/forms/user-name';
import ModalCatalog from '../../components/modal-catalog';
import WarehouseTable from '../../components/warehouse-table';
import DialogEditPart from './dialog-edit-part';

interface Pay {
  cashbox_id: null | number;
  posting_id: null | number;
  sum: number;
  comment: string;
}

interface ProviderReturn {
    cashbox_id;
    posting_id;
    sum;
    comment;
}
@Component({
  template: require('./index.html'),
  components: {
    InfoAboutPartOrWork,
    date: ViewDate,
    'user-name': ViewUserName,
    numbers: Numbers,
    'warehouse-table': WarehouseTable,
    'modal-catalog': ModalCatalog,
    'dialog-edit-part': DialogEditPart,
    ChooseAnotherProvider,
  },
})
export default class PostingViewPage extends Vue {
    @Action addBreadcrumb;

    @Action getVatList;

    @Action getRewardTypeList;

    @Action getWarrantTypeList;

    @Action getPartCategory;

    @Getter user;

    dataForModalView = {};

    titleInfoAboutPartOrWork = '';

    is_returns_popover_visible = false;

    is_payment_popover_visible = false;

    open_modal_edit_part = false;

    posting_part: object = {};

    loading = false;

    load_page = false;

    posting: Posting = {};

    posting_item_list: PostingPart[] = [];

    posting_parts_list: object[] = [];

    posting_payments_list: RepairPayment[] = [];

    posting_comments: PostingComment[] = [];

    posting_comment_form: PostingCommentForm = { content: '' };

    showDeletedComments = false;

    showModalViewAboutWorkOrPart = false;

    showModalViewProviderForm = false;

    btnloader = false;

    posting_comment_form_rules = {
      content: [
        {
          required: true, message: 'Обязательно для заполнения', whitespace: true, trigger: 'change',
        },
      ],
    };

    loading_revert_posting = false;

    providerReturn: ProviderReturn = {
      cashbox_id: null,
      posting_id: null,
      sum: 0,
      comment: '',
    };

    pay: Pay = {
      cashbox_id: null,
      posting_id: null,
      sum: 0,
      comment: '',
    };

    isLoadingPostingProvider = false;

    validateSum = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error('Значение «Сумма» должно быть не меньше 0.'));
      } else {
        callback();
      }
    }

    viewingPart(row) {
      try {
        this.btnloader = true;
        PartProvider.getViewPart(row.part.id)
          .then((res) => {
            this.titleInfoAboutPartOrWork = 'Информация о товаре';
            this.dataForModalView = res.data;
            this.btnloader = false;
            this.getVatList();
            this.getRewardTypeList();
            this.getWarrantTypeList();
            this.getPartCategory('part');

            this.showModalViewAboutWorkOrPart = true;
          })
          .catch((error) => {
            this.btnloader = false;
            this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о товаре', item: '' });
            throw new Error(error);
          });
      } catch (err) {
        this.btnloader = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        throw new Error(err);
      }
    }

    closeInfoAboutPartOrWork() {
      this.showModalViewAboutWorkOrPart = false;
    }

    rules_pay: object = {
      sum: [
        { validator: this.validateSum, trigger: 'change', type: 'number' },
        { required: true, message: 'Необходимо заполнить поле «Сумма оплаты»' },
      ],
      cashbox_id: { required: true, message: 'Необходимо заполнить поле «Выберите кассу»' },
    };

    cash_list: Cashbox[] = [];

    loading_add_payments = false;

    count_cash = 1;

    // api

    getCashList() {
      CashProvider.getAllCashes()
        .then((res) => {
          this.cash_list = res;
          const filterCashListOnPoint = res.find(
            (cash) => cash.point_id === this.user.point_id,
          );

          this.pay.cashbox_id = filterCashListOnPoint ? filterCashListOnPoint.id : null;
        })
        .catch((err) => console.error(err));
    }

    async getAllComments(page = 1) {
      const pack = await PostingProvider.getPostingComments(
        { posting_id: this.id, include_deleted: true },
      );
      let result = pack.data;
      if (page < pack.pageCount) {
        const nextPack = await this.getAllComments(page + 1);
        result = result.concat(nextPack);
      }
      return result;
    }

    async sendPostingComment() {
      const form = {
        posting_id: this.id,
        content: this.posting_comment_form.content,
      } as PostingAddForm;
      try {
        const result = await PostingProvider.sendPostingComment(form);
        this.posting_comments = [(result.data as PostingComment), ...this.posting_comments];
        this.posting_comment_form.content = '';
        (this.$refs.commentForm as Form).resetFields();
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Комментарий успешно сохранен', item: '' });
      } catch (e) {
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка сохранения комментария', item: '' });
      }
    }

    addPaymentsPosting(item) {
      this.loading_add_payments = true;
      PostingProvider.addPaymentsPosting(item)
        .then((res) => {
          this.posting_payments_list.push(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Оплата успешно добавлена', item: '' });

          this.pay.sum = this.recalculatingSumOnPayments();
          this.pay.comment = '';
          this.loading_add_payments = false;
          this.is_payment_popover_visible = false;
        }).catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка оплаты', item: '' });
          this.loading_add_payments = false;
          this.is_payment_popover_visible = false;
          console.error(err);
        });
    }

    addPaymentReturnPosting(item) {
      this.loading_add_payments = true;
      PostingProvider.addPaymentReturnPosting(item)
        .then((res) => {
          this.posting_payments_list.push(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Возврат успешно добавлен', item: '' });

          this.pay.sum = this.recalculatingSumOnPayments();
          this.pay.comment = '';
          this.loading_add_payments = false;
          this.is_returns_popover_visible = false;
        }).catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка возврата', item: '' });
          this.loading_add_payments = false;
          this.is_returns_popover_visible = false;
          console.error(err);
        });
    }

    deletePaymentsPosting(id) {
      PostingProvider.deletePaymentsPosting(id)
        .then((res) => {
          const index = this.posting_payments_list.findIndex(
            (element) => element.id === res.data.id,
          );
          this.posting_payments_list.splice(index, 1);

          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Оплата успешно удалена', item: '' });
          this.pay.sum = this.recalculatingSumOnPayments();
        }).catch((err) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Удалить оплату не удалось', item: '' });
          console.error(err);
        });
    }

    getPostingView(id) {
      this.load_page = true;
      PostingProvider.getPostingView(id)
        .then((res) => {
          this.posting = res.data;
          this.posting_payments_list = res.data.payments;

          if (res.data.parts) {
            this.posting_item_list = res.data.parts;
          }

          this.pay.sum = this.recalculatingSumOnPayments();
          this.load_page = false;
          document.title = `Поступление №${this.posting.id}`;
        }).catch((err) => {
          this.load_page = false;
          console.error(err);
        });
    }

    revertPosting(id) {
      this.loading_revert_posting = true;
      PostingProvider.revertPosting(id)
        .then(() => {
          this.loading_revert_posting = false;
          this.$router.push({ name: 'posting' });
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Поступление удалено', item: '' });
        }).catch((err) => {
          console.error(err);
          this.loading_revert_posting = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    sendPostingPart(item) {
      PostingProvider.sendPostingPart(item)
        .then((res) => {
          this.posting_item_list.push(res.data);

          this.pay.sum = this.recalculatingSumOnPayments();
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар добавлен в поступление', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    revertPostingPart(id) {
      PostingProvider.revertPostingPart(id)
        .then((res) => {
          const index = this.posting_item_list.findIndex((i) => i.id === res.data.id);
          this.posting_item_list.splice(index, 1);

          this.pay.sum = this.recalculatingSumOnPayments();
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар убран из поступления', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    // end api

    get id() {
      return Number(this.$route.params.id);
    }

    get sortedCashList() {
      return this.cash_list.map((x) => x).sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
    }

    get rightsUser() {
      return this.user.rights ? this.user.rights.can_edit_posting_parts : false;
    }

    get sum() {
      return this.posting_item_list.reduce(
        (totalPrice, parts) => totalPrice + (parts.amount * parts.price),
        0,
      );
    }

    get toPay() {
      return Math.round((this.sum - this.sumPostingPayments) * 100) / 100;
    }

    get sumPostingPayments() {
      return this.posting_payments_list.reduce(
        (totalPrice, payment) => totalPrice + payment.sum,
        0,
      );
    }

    get postingProviderName() {
      if (this.posting.provider) return this.posting.provider.name;
      return '-';
    }

    get postingStorageName() {
      if (this.posting.storage) return this.posting.storage.name;
      return '-';
    }

    get postingCommentsFiltered() {
      return this.showDeletedComments
        ? this.posting_comments
        : this.posting_comments.filter((x) => !x.is_deleted);
    }

    /**
    * Может удалять чужие комментарии
    * @returns boolean
    */
    get canDeleteForeignComments(): boolean {
      return this.user?.rights?.can_delete_foreign_comments
        ? this.user?.rights?.can_delete_foreign_comments : false;
    }

    /**
    * Может удалять свои комментарии
    * @returns boolean
    */
    get canDeleteSelfComments(): boolean {
      return this.user?.rights?.can_delete_self_comments
        ? this.user?.rights?.can_delete_self_comments : false;
    }

    mounted() {
      this.addBreadcrumb([
        { id: 1, section: 'Склад', link: '/part' },
        { id: 2, section: 'Поступления на склад', link: '/part/posting' },
        { id: 3, section: `Поступление № ${this.id}`, link: null }]);
      document.title = `Поступление № ${this.id}`;
      this.getPostingView(this.id);
      this.getCashList();
      this.getVatList();
      this.getRewardTypeList();
      this.getWarrantTypeList();
      this.getPartCategory('part');
      this.getAllComments()
        .then((resp) => {
          this.posting_comments = resp;
        })
        .catch((err) => console.error(err));
    }

    recalculatingSumOnPayments() {
      return Math.round((this.sum - this.sumPostingPayments) * 100) / 100;
    }

    loadingPartsList(parts) {
      this.posting_parts_list = parts.map((i) => ({
        id: i.id,
        name: i.name,
        part_id: i.id,
        amount: 1,
        art: i.art,
        price: i.posting_price === null ? 0 : i.posting_price,
      }));
    }

    loadingNewPosting() {
      this.open_modal_edit_part = false;
    }

    handleDialogEditPart(part: object) {
      this.open_modal_edit_part = true;
      this.posting_part = part;
    }

    addReturnInPosting() {
      (this.$refs.formReturn as Form).validate((valid) => {
        this.providerReturn.posting_id = Number(this.$route.params.id);
        return valid ? this.addPaymentReturnPosting(this.providerReturn) : false;
      });
    }

    addPaymentInPosting() {
      (this.$refs.formPay as Form).validate((valid) => {
        this.pay.posting_id = Number(this.$route.params.id);
        return valid ? this.addPaymentsPosting(this.pay) : false;
      });
    }

    methodDisabledButtons() {
      this.$notify.error({
        title: 'У вас нет права редактировать поступления',
        message: '',
        position: 'bottom-right',
      });
    }

    closeDialogEditPart(value: boolean) {
      this.open_modal_edit_part = value;
    }

    addNewPart(part) {
      if (this.posting.storage) {
        const posting_part: object = {
          id: part.id,
          name: part.name,
          part_id: part.id,
          amount: 1,
          price: part.posting_price === null ? 0 : part.posting_price,
          posting_id: this.id,
          storage_id: this.posting.storage.id,
        };
        this.sendPostingPart(posting_part);
      } else {
        throw new Error('this.posting.storage is null or undefined');
      }
    }

    getCashboxName = (payment) => {
      if (payment.cashoperation === null) return '(без кассы)';
      if (payment.cashoperation.cashbox === null) return '(недоступная касса)';
      return payment.cashoperation.cashbox.name;
    }

    submitPostingCommentForm() {
      (this.$refs.commentForm as Form).validate((valid) => {
        if (valid) {
          this.sendPostingComment();
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    }

    /**
     * Обновления поступления по провайдеру
     * @param  {} providerId
     */
    updatePostingProvider(providerId) {
      if (providerId > -1) {
        const form: PostingUpdateForm = {
          id: this.id,
          provider_id: providerId,
        };
        this.isLoadingPostingProvider = true;
        PostingProvider.updatePosting(form)
          .then(() => {
            this.isLoadingPostingProvider = false;
            this.handlerToggleShowModalViewProviderForm();
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Поступление обновлено' });
            this.getPostingView(this.id);
          })
          .catch((err) => {
            this.isLoadingPostingProvider = false;
            this.handlerToggleShowModalViewProviderForm();
            this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Не удалось обновить поступление' });
            throw new Error(err);
          });
      } else {
        this.handlerToggleShowModalViewProviderForm();
      }
    }

    handlerToggleShowModalViewProviderForm() {
      this.showModalViewProviderForm = !this.showModalViewProviderForm;
    }

    canDeleteComment(comment: PostingComment) {
      if (comment.is_deleted) {
        return false;
      }
      if (comment.user_id === this.user.id) {
        return this.canDeleteSelfComments;
      }
      if (this.canDeleteForeignComments) {
        return true;
      }
      return false;
    }

    deleteComment(comment: PostingComment) {
      PostingProvider.deletePostingComment(comment.id)
        .then(() => {
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Комментарий успешно удален' });
          this.posting_comments.forEach((c, index) => {
            if (c.id === comment.id) this.posting_comments[index].is_deleted = true;
          });
        })
        .catch((res) => {
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось удалить комментарий' });
          throw new Error(res);
        });
    }
}

export interface PostingCommentForm {
    content: string;
}
