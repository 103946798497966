import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb } from '@/types';
import ViewDate from '../../components/forms/date';
import NumberPhone from '../../components/forms/number-phone';
import ViewUserName from '../../components/forms/user-name';

@Component({
  template: require('./index.html'),
  components: {
    'user-name': ViewUserName,
    date: ViewDate,
    phone: NumberPhone,
  },
})
export default class SmsPages extends Vue {
    @Action getSmsList;

    @Action addBreadcrumb;

    @Getter sms;

    @Getter smsPageCount;

    loading = true;

    breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Компания', link: '/settings' }, { id: 2, section: 'SMS-сообщения и уведомления', link: null }];

    mounted() {
      this.getSmsList(1);
      this.addBreadcrumb(this.breadcrumb);
      document.title = 'SMS-сообщения и уведомления';
    }

    loadPage(val) {
      this.loading = true;
      this.getSmsList(val);
    }

    @Watch('sms')
    onLoad() {
      window.scroll(0, 0);
      this.loading = false;
    }
}
