import { GetterTree } from 'vuex';
import { StatePoint } from './types';

const getters: GetterTree<StatePoint, object> = {
  point: (state) => state.point,
  changePoint: (state) => state.changePoint,
  newPoint: (state) => state.newPoint,
  typePoint: (state) => state.typePoint,
  viewPoint: (state) => state.viewPoint,
  validPoint: (state) => state.validPoint,
  pointPageCount: (state) => state.pointPageCount,
  pointPageCurrent: (state) => state.pointPageCurrent,
};

export default getters;
