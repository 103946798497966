import { GetterTree } from 'vuex';
import { StateBilling } from './types';

const getters: GetterTree<StateBilling, object> = {
  smsOffers: (state) => state.smsOffers,
  acquirers: (state) => state.acquirers,
  buySms: (state) => state.buySms,
  buyLicense: (state) => state.buyLicense,
  tariffLicense: (state) => state.tariffLicense,
  balance: (state) => state.balance,
};

export default getters;
