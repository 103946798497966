export default function selectionAmountForPrepayment(this) {
  this.$root.$on('selectionAmountForPrepayment', (prepayment) => {
    const { sum, cashbox_id } = prepayment;

    if (sum) {
      this.data_repair.prepayment.sum = sum === 0 ? null : sum;
    }

    if (cashbox_id) {
      this.data_repair.prepayment.cashbox_id = cashbox_id;
    }
  });
}
