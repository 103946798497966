const state = {
  work: [],
  viewWork: {},
  workPageCurrent: null,
  workPageCount: null,
  workValid: {},
  updateWork: {},
};

export default state;
