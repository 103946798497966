/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import PostingProvider from '../../../api/providers/posting';

@Component({
  template: require('./index.html'),
})

export default class DialogEditPart extends Vue {
    @Prop({ default: () => false }) openModalEdit!: boolean;

    @Prop(Object) postingPart;

    view_modal_edit = false;

    posting: object = {};

    validatePrice = (rule, value, callback) => {
      if (value === null || value === undefined) {
        callback();
      } else {
        const floatVal = Number.parseFloat(value);
        if (floatVal < 0) {
          callback(new Error('Сумма не может быть меньше 0'));
        } else {
          callback();
        }
      }
    }

    validateAmount = (rule, value, callback) => {
      if (value === null || value === undefined) {
        callback();
      } else {
        const floatVal = Number.parseFloat(value);
        if (floatVal < 1) {
          callback(new Error('Колличество не может быть меньше 1'));
        } else {
          const fractional = floatVal - Math.floor(floatVal);
          if (fractional !== 0) {
            callback(new Error('Колличесвто должно быть целым числом'));
          }
          callback();
        }
      }
    }

    formRules = {
      price: [
        { required: true, message: 'Укажите сумму', trigger: 'change' },
        {
          validator: this.validatePrice,
          trigger: 'change',
        },
      ],
      amount: [
        { required: true, message: 'Укажите колличисвто', trigger: 'change' },
        {
          validator: this.validateAmount,
          trigger: 'change',
        },
      ],
    };

    // api

    changePostingPart(item) {
      PostingProvider.changePostingPart(item)
        .then((res) => {
          this.posting = res.data;
          this.$emit('loadingNewPosting', res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар в поступлении обновлён', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    sendEditPostingPart() {
      const part = {
        ...this.postingPart,
        posting_id: Number(this.$route.params.id),
      };

      this.changePostingPart(part);
    }

    @Watch('openModalEdit', { deep: true })
    openModal(value: boolean) {
      this.view_modal_edit = value;
    }

    @Watch('view_modal_edit')
    viewEditPart(value: boolean) {
      if (!value) this.$emit('closeDialogEditPart', value);
    }
}
