import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  template: require('./index.html'),
  components: {},
})

export default class ErrorAccessRights extends Vue {
  @Prop() title!: string;
}
