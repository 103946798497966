import Vue from 'vue';
import { Form } from 'element-ui';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { Breadcrumb, Client } from '@/types';

import ClientUserForm from '../../components/client-user-form';
import ClientCompanyForm from '../../components/client-company-form';

import ClientsProvider from '../../api/providers/clients';
import ClientSourceProvider from '../../api/providers/client-source';
import ErrorAccessRights from '../../components/error-access-rights';

@Component({
  template: require('./index.html'),
  components: {
    'client-user-form': ClientUserForm,
    'client-company-form': ClientCompanyForm,
    'error-access-rights': ErrorAccessRights,
  },
})
export default class ClientsEditPage extends Vue {
    @Prop({ default: () => false }) cancelGo!: boolean;

    @Prop({ default: () => false }) activeBreadcrumb!: boolean;

    @Prop({ default: () => false }) flagsLocalDataCLients!: boolean;

    @Prop({ default: null }) client!: Client;

    @Action addBreadcrumb;

    @Getter settings;

    @Getter user;

    client_source_list: object[] = [];

    view_client: object = {};

    breadcrumb: Breadcrumb[] = [
      { id: 1, section: 'Компания', link: '/settings' },
      { id: 2, section: 'Клиенты', link: '/clients' },
      { id: 3, section: 'Изменение', link: null },
    ];

    loading = true;

    loading_form_client = false;

    get rightsUserCanManageClients() {
      return this.user.rights ? this.user.rights.can_manage_clients : false;
    }

    // api

    getClientSourceList(page) {
      ClientSourceProvider.getClientSourceList(page)
        .then((res) => {
          this.client_source_list = res.data;
        })
        .catch((err) => console.error(err));
    }

    changeClientPerson(item) {
      this.loading_form_client = true;
      ClientsProvider.changeClientPerson(item)
        .then((res) => {
          if (!this.cancelGo) {
            if (Object.keys(res.data).length) this.$router.go(-2);
          }

          this.$emit('closeModal', false);
          this.$emit('updateClient', res.data);

          this.loading_form_client = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Успешно изменено', item: `${res.data.last_name} ${res.data.first_name} ${res.data.middle_name}` });
        })
        .catch((err) => {
          console.error(err);
          this.loading_form_client = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        });
    }

    changeClientCompany(item) {
      this.loading_form_client = true;
      ClientsProvider.changeClientCompany(item)
        .then((res) => {
          if (!this.cancelGo) {
            if (Object.keys(res.data).length) this.$router.push('/clients');
          }

          this.$emit('closeModal', false);
          this.$emit('updateClient', res.data);

          this.loading_form_client = false;
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Успешно изменено', item: res.data.name });
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
          this.loading_form_client = false;
        });
    }

    getViewClient(id) {
      ClientsProvider.getViewClient(id)
        .then((res) => {
          this.view_client = res.data;
          this.loading = false;
          document.title = `Изменение клиента ${id}`;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }

    mounted() {
      document.title = 'Изменение клиента';
      if (this.$route.params.id && this.client === null) {
        this.getViewClient(this.$route.params.id);
      } else {
        this.getViewClient(this.client.id);
      }

      this.getClientSourceList(1);
      if (!this.activeBreadcrumb) this.addBreadcrumb(this.breadcrumb);
    }

    requestOnChangeUser() {
      ((this.$refs.user as ClientUserForm).clientUserForm as Form).validate((valid) => {
        if (valid) {
          if (this.flagsLocalDataCLients) {
            this.changeClientPerson(this.client);
          } else {
            this.changeClientPerson(this.view_client);
          }
          return true;
        }
        return false;
      });
    }

    requestOnChangeCompany() {
      ((this.$refs.company as ClientCompanyForm).clientUserCompany as Form).validate((valid) => {
        if (valid) {
          if (this.flagsLocalDataCLients) {
            this.changeClientCompany(this.client);
          } else {
            this.changeClientCompany(this.view_client);
          }
          return true;
        }
        return false;
      });
    }
}
