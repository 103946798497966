import { MutationTree } from 'vuex';
import {
  DELETE_WORK, GET_VIEW_WORK, GET_WORK,

  StateWork, UPDATE_WORK,
} from './types';

const mutations: MutationTree<StateWork> = {
  [GET_WORK](state, { item, pageCurrent, pageCount }) {
    state.work = item;
    state.workPageCurrent = pageCurrent;
    state.workPageCount = pageCount;
  },

  [GET_VIEW_WORK](state, { item }) {
    state.viewWork = item;
  },

  [DELETE_WORK](state, { item }) {
    const index = state.work.findIndex((i) => i.id === item.id);
    state.work.splice(index, 1);
  },

  [UPDATE_WORK](state, { item }) {
    state.updateWork = item;
  },
  // [GET_VIEW_TRANSFER](state, { item }) {
  //     state.viewTransfer = item;
  // },

  // [GET_PAGE_TRANSFER](state, { item, pageCurrent, pageCount }) {
  //     state.transfer = state.transfer.concat(item);
  //     state.transferPageCurrent = pageCurrent;
  //     state.transferPageCount = pageCount;
  // },

  // [TRANSFER_VALIDATION](state, item) {
  //     state.transferValid = item;
  // }
};

export default mutations;
