import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

interface Item {
  vendor_id?;
  name?;
  type_id?;
  id?;
}
@Component({
  template: require('./index.html'),
})
export default class DeviceForm extends Vue {
  @Action sendDeviceType;

  @Action sendDeviceVendor;

  @Action sendDeviceModel;

  @Action changeDeviceType;

  @Action changeDeviceVendor;

  @Action changeDeviceModel;

  @Action getDeviceVendorList;

  @Action getDeviceModelList;

  @Getter deviceTypeList;

  @Getter deviceVendorList;

  @Getter deviceModelList;

  @Getter deviceValid;

  @Getter deviceType;

  @Getter deviceVendor;

  @Getter deviceModel;

  @Getter btnloader;

  @Getter changeDiviceVendor;

  @Prop({ default: () => ({}) }) item!: Item;

  @Prop({ default: () => 'add' }) typeContent!: string;

  dialogAdd = false;

  popoverType = false;

  popoverVendor = false;

  newItem: Item = {};

  value_name = '';

  type_name = '';

  vendor_name = '';

  type = 'add';

  elem = 'model';

  vendorList: object[] = [];

  flags_type_id = true;

  flags_vendor_id = true;

  mounted() {
    this.$root.$on('onDialog', (i) => {
      this.type = i;
      if (i === 'add') {
        this.elem = 'model';
        this.type_name = '';
        this.vendor_name = '';
        this.$emit('changeModeModel', 'model');
      }
      this.dialogAdd = true;
    });
  }

  get inputName() {
    let name = '';
    switch (this.elem) {
      case 'type': name = 'Тип'; break;
      case 'vendor': name = 'Производитель'; break;
      case 'model': name = 'Модель'; break;
      default: break;
    }
    return name;
  }

  searchType(queryString, cb) {
    const items = this.deviceTypeList;
    const results = queryString ? items.filter(this.createFilter(queryString)) : items;
    this.popoverType = !!(!results.length && queryString.length);
    cb(results);
  }

  searchVendor(queryString, cb) {
    // if (queryString.length && this.type === 'edit') {
    //     this.vendorList = this.deviceVendorList.filter( i => i.id === this.newItem['type_id']);
    // }
    // let items = this.vendorList;
    // const results = queryString ? items.filter(this.createFilter(queryString)) : items;
    // this.popoverVendor = !results.length && queryString.length ? true : false;
    // cb(results);

    const items = this.deviceVendorList;
    const results = queryString ? items.filter(this.createFilter(queryString)) : items;
    this.popoverVendor = !!(!results.length && queryString.length);
    cb(results);
  }

  typeSelect(item) {
    this.popoverType = false;
    this.dataDistributionType(item);
    this.$emit('typeSelect', item);
    // this.getDeviceVendorList(item.id);
  }

  dataDistributionType(item: Item) {
    this.flags_type_id = false;
    this.type_name = item.name;
    this.newItem.type_id = item.id;
    this.item.type_id = item.id;
  }

  vendorSelect(item) {
    this.flags_vendor_id = false;
    this.vendor_name = item.name;
    this.newItem.vendor_id = item.id;
    this.$emit('vendorSelect', item);
    this.popoverVendor = false;
  }

  createFilter = (queryString) => (link) => (link.name.toLowerCase()
    .indexOf(queryString.toLowerCase()) === 0)

  blurType() {
    const items = this.deviceTypeList;
    const query = this.type_name;
    const results = items.filter((i) => i.name === query);
    this.popoverType = !!(!results.length && query.length);
  }

  add(type) {
    let item: object = {};
    switch (type) {
      case 'type':
        item = { name: this.type_name };
        this.sendDeviceType(item);
        break;
      case 'vendor':
        item = { type_id: this.newItem.type_id, name: this.vendor_name };
        this.sendDeviceVendor(item);
        break;
      case 'model':
        item = { vendor_id: this.newItem.vendor_id, name: this.newItem.name };
        this.sendDeviceModel(item);
        break;
      default: break;
    }
    this.popoverType = false;
    this.popoverVendor = false;
  }

  resetFormDevice() {
    (this.$refs.formDevice as Form).resetFields();
  }

  edit(type) {
    switch (type) {
      case 'type':
        this.changeDeviceType(this.newItem);
        break;
      case 'vendor':
        this.changeDeviceVendor(this.newItem);
        break;
      case 'model':
        this.changeDeviceModel(this.newItem);
        break;
      default: break;
    }
    this.closePopover();
  }

  validate = (obj: object, key: string) => {
    if (Object.keys(obj).some((v) => v === key)) {
      return obj[key][0];
    }
    return null;
  }

  closePopover() {
    this.popoverType = false;
    this.popoverVendor = false;
  }

  closeDialog() {
    this.$emit('closeDialogDevice');
    this.resetFormDevice();
    this.closePopover();
    this.flags_type_id = true;
    this.flags_vendor_id = true;
  }

  /* eslint-disable no-param-reassign */
  @Watch('item')
  onChange(val: Item) {
    const typelist = JSON.parse(JSON.stringify(this.deviceTypeList));
    this.newItem = {};

    if (val.vendor_id) {
      const list = typelist.filter(
        (i) => i.vendors = this.deviceVendorList.filter(
          (vendor) => vendor.id === val.vendor_id,
        ),
      ).filter(
        (vendor) => vendor.vendors.length,
      );
      this.newItem.vendor_id = val.vendor_id;
      this.vendorList = list[0].vendors;
      this.vendor_name = list[0].vendors[0].name;
      this.flags_vendor_id = false;
    }

    if (val.type_id) {
      const list = typelist.filter((i) => i.id === val.type_id);
      this.newItem.type_id = val.type_id;
      this.type_name = list[0].name;
      this.flags_type_id = false;
    }

    this.newItem.id = val.id;
    this.newItem.name = val.name;
    this.value_name = val.name;
  }
  /* eslint-enable no-param-reassign */

  @Watch('deviceType')
  onChangeDeviceType(val: object) {
    this.dataDistributionType(val);
    // if (this.newItem['vendor_id'] && this.newItem['type_id']) {
    //   const vendor = this.vendorList.filter(vendor => vendor['id']
    //  === this.newItem['vendor_id'])[0];
    //   let item = { type_id: this.newItem['type_id'], name: vendor['name'], id: vendor['id']};
    //   this.getDeviceVendorList(this.newItem['type_id']);
    //   this.changeDeviceVendor(item);
    // }
  }

  @Watch('deviceVendor')
  onChangeDeviceVendor(val: Item) {
    Promise.all([this.typeSelect({ id: val.type_id, name: this.type_name })])
      .then(() => this.vendorSelect(val))
      .catch((error) => console.error('error ~', error));
  }

  @Watch('typeContent')
  onChangeTypeContent(val: string) {
    this.elem = val;
  }

  @Watch('deviceValid')
  onChangeDeviceValid(val: object) {
    if (Object.keys(val).length === 0) this.dialogAdd = false;
  }

  @Watch('value_name', { deep: true })
  changeTypeValue(value: string) {
    this.newItem.name = value;
  }

  @Watch('changeDiviceVendor')
  changeVendor(vendor: Item) {
    if (!(this.newItem.vendor_id && this.newItem.type_id)) this.typeSelect({ id: vendor.type_id });
  }
}
