import Vue from 'vue';
import { Form } from 'element-ui';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { RepairPayment } from '@/types';
import FormAddition from '../../forms/addition';
import FormDiscount from '../../forms/discount';
import Numbers from '../../forms/numbers';
import FormPayments from '../../forms/payments';
import { RepairDiscountAdditionRowVM } from '../__payments';

@Component({
  template: require('./dialog.html'),
  components: {
    'form-addition': FormAddition,
    'form-discount': FormDiscount,
    'form-payments': FormPayments,
    numbers: Numbers,
  },
})

export default class DialogCloseOrder extends Vue {
  @Getter user;

  @Prop() view!: boolean;

  @Prop() title!: string;

  @Prop({ default: () => 0 }) priceWithVat!: number;

  @Prop() paymentsList!: RepairPayment[];

  @Prop() resultPay!: number;

  @Prop() sumWorks!: number;

  @Prop() cashboxes!: Array<object>;

  @Prop() additions!: Array<RepairDiscountAdditionRowVM>;

  @Prop() discounts!: Array<RepairDiscountAdditionRowVM>;

  @Prop() onCreateRepairAddition!: Function;

  @Prop() onDeleteRepairAddition!: Function;

  @Prop() onCreateRepairDiscount!: Function;

  @Prop() onDeleteRepairDiscount!: Function;

  @Prop() onCreateRepairPayment!: Function;

  @Prop() onEjectRepair!: Function;

  formAddition: object = { name: null, amount: null, type_id: null };

  formDiscount: object = { name: null, amount: null, type_id: null };

  formPayment: FormPayment = { cashbox_id: null, sum: null };

  get id() { return Number(this.$route.params.id); }

  get resultSumWorks() {
    if (this.sumWorks !== undefined) return this.sumWorks;
    return 0;
  }

  get viewScoreAdditionAndDiscounts() {
    const score: string [] = [];
    const payments = [...this.additions, ...this.discounts];

    if (payments.length !== 0) {
      payments.forEach((item) => {
        switch (item.type) {
          case 1: score.push(` ${!item.is_discount ? '+' : '-'} (${this.priceWithVat} * ${Number(item.size) / 100})`); break;
          case 2: score.push(` ${!item.is_discount ? '+' : '-'} ${Number(item.size)}`); break;
          case 3: score.push(` ${!item.is_discount ? '+' : '-'} (${this.resultSumWorks} * ${Number(item.size) / 100})`); break;
          default: break;
        }
      });
      return score.join('');
    }
    return '';
  }

  get payments() {
    const score: string[] = [];

    if (this.paymentsList !== undefined) {
      this.paymentsList.forEach((item) => (Number(item.sum) < 0 ? score.push(` + ${Math.abs(item.sum)}`) : score.push(` - ${item.sum}`)));
      return score.join('');
    }
    return '';
  }

  createAddition() {
    this.onCreateRepairAddition(this.formAddition);
  }

  removeAddition(index) {
    this.onDeleteRepairAddition(this.additions[index].id);
  }

  createDiscount() {
    this.onCreateRepairDiscount(this.formDiscount);
  }

  removeDiscount(index) {
    this.onDeleteRepairDiscount(this.discounts[index].id);
  }

  sendPayment() {
    const formPayments = this.$refs.formPayments as FormPayments;
    (formPayments.refPayments as Form).validate((valid) => {
      if (valid) {
        if (this.formPayment.sum !== undefined && this.formPayment.sum !== null) {
          if (this.formPayment.sum > 0) {
            this.onCreateRepairPayment(this.formPayment);
          }
        }
        this.onEjectRepair(this.id);
        return true;
      }
      return false;
    });
  }

  /**
   * Здесь все-таки нужен watcher, чтобы мы могли обновлять
   * поле формы, при изменении суммы к оплате.
   * Геттером (вычисляемым свойством) не решить, т.к. для поля нужен и геттер и сеттер
   */
  @Watch('resultPay')
  updateToPayFormField() {
    this.formPayment.sum = this.resultPay;
  }

  @Watch('cashboxes')
  userMe(list) {
    if (list.length !== 0) {
      const filterCashListOnPoint = list.find((cash) => cash.point_id === this.user.point_id);

      this.formPayment.cashbox_id = filterCashListOnPoint ? filterCashListOnPoint.id : null;
    }
  }
}

interface FormPayment {
  cashbox_id: number|null;
  sum: number|null;
}
