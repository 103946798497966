import { ActionTree } from 'vuex';
import SmsProvider from '../../api/providers/sms';
import { StateSmsTemplate } from './types';

const actions: ActionTree<StateSmsTemplate, object> = {

  getSmsTemplateList({ commit }) {
    SmsProvider.getSmsTemplateList()
      .then((res) => commit('GET_SMS_TEMPLATE', res.data))
      .catch((err) => console.error(err));
  },

  changeSmsTemplate({ commit }, item) {
    commit('BTN_LOADER', true);
    SmsProvider.changeSmsTemplate(item)
      .then(() => {
        commit('PUSH_CALL_SUCCESS', { title: 'Настройки смс сохранены', item: '' });
        commit('BTN_LOADER', false);
      })
      .catch((err) => {
        console.error(err);
        commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
        commit('BTN_LOADER', false);
      });
  },

};

export default actions;
