import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  template: require('./footer.html'),
})
export default class FooterComponent extends Vue {
    nameCompany = '© ООО Совинфсистемы 2017 г.';

    time = '0.0885';
}
