import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ChangePointForm } from '@/types';
import Numbers from '../forms/numbers';
import ViewUserName from '../forms/user-name';

@Component({
  template: require('./right-sliding.html'),
  components: {
    'user-name': ViewUserName,
    numbers: Numbers,
  },

})
export default class RightSliding extends Vue {
    @Getter point;

    @Getter user;

    @Getter pointPageCount;

    @Getter pointPageCurrent;

    @Getter balance;

    @Getter updateUser;

    @Action signOut;

    @Action sendUserChangePoint;

    @Prop() active!: boolean;

    pagePoint = 1;

    points: object[] = [];

    view_drawer = false;

    get isSmsBalancePositive(): boolean {
      try {
        if (this.balance.sms_balance) {
          return this.balance.sms_balance > 0;
        }
        return false;
      } catch {
        return false;
      }
    }

    get pointHasAccess() {
      return this.point.filter((p) => p.has_access);
    }

    get isAuthorized() {
      return Object.keys(this.user).length !== 0;
    }

    get rightsUserCanMoveBettweenPoints() {
      return this.user.rights ? this.user.rights.can_move_between_points : false;
    }

    get nameCompany() {
      return this.user.service ? this.user.service.name : '–';
    }

    get currentLicense() {
      return this.balance.current_license
        ? this.balance.current_license
        : '–';
    }

    get reserverdLicense() {
      return this.balance.reserved_license
        ? this.balance.reserved_license
        : [];
    }

    get haveNotCurrentLicense(): boolean {
      return this?.balance?.current_license === null;
    }

    get pendingDaysString(): string {
      let pendingDaysString = `Хватит на ${this.balance.pending_days} `;
      switch (this.balance.pending_days) {
        case 1: pendingDaysString += 'день'; break;
        case 2:
        case 3:
        case 4: pendingDaysString += 'дня'; break;
        default: pendingDaysString += 'дней'; break;
      }
      return pendingDaysString;
    }

    get paymentsBalance(): number {
      return this.balance.payment_balance;
    }

    async isSliding() {
      this.$root.$emit('rightSliding');
    }

    async isAuthOut() {
      await this.isSliding();
      this.signOut(false);
      this.$router.push('/auth');
    }

    selectionPoint(point) {
      const newUser = { ...this.user };
      newUser.point_id = point.id;

      if (this.rightsUserCanMoveBettweenPoints) {
        if (this.user.point_id === point.id) {
          this.$notify.info({
            title: 'Этот филиал является активным',
            message: '',
            position: 'bottom-right',
          });
        } else {
          const form: ChangePointForm = {
            point_id: point.id,
          };
          this.sendUserChangePoint(form);
        }
      }
    }

    @Watch('point')
    dataPoints() {
      if (this.pagePoint <= this.pointPageCount) {
        // this.pagePoint = this.pagePoint + 1;
        // this.getPointList(this.pagePoint);
        // point.forEach(point => this.points.push(point));
      }
    }

    @Watch('updateUser')
    newUser = () => {
      setTimeout(() => window.location.reload(), 500);
    }

    @Watch('active')
    openDrawer(value: boolean) {
      this.view_drawer = value;
    }
}
