import { ActionTree } from 'vuex';
import DocumentProvider from '../../api/providers/document';
import { StateDocument } from './types';

const actions: ActionTree<StateDocument, object> = {

  getDocumentList({ commit }, item) {
    commit('BTN_LOADER', true);
    DocumentProvider.getDocumentList(item.page, item, item['per-page'])
      .then((res) => {
        commit('GET_DOCUMENTS', { item: res.data, pageCount: res.pageCount });
        commit('BTN_LOADER', false);
      }).catch((err) => {
        commit('BTN_LOADER', false);
        console.error(err);
      });
  },

  getViewDocument({ commit }, id) {
    commit('BTN_LOADER', true);
    DocumentProvider.getViewDocument(id)
      .then((res) => {
        commit('BTN_LOADER', false);
        commit('GET_VIEW_DOCUMENT', { item: res.data });
      })
      .catch((err) => {
        commit('BTN_LOADER', false);
        console.error(err);
      });
  },

  addDocument({ commit }, data) {
    DocumentProvider.addDocument(data)
      .then((res) => {
        commit('NEW_DOCUMENT', { item: res.data });
      })
      .catch((err) => {
        console.error(err);
      });
  },

  updateDocument({ commit }, data) {
    DocumentProvider.updateDocument(data)
      .then((res) => {
        commit('GET_VIEW_DOCUMENT', { item: res.data });
        commit('UPDATE_DOCUMENT', { item: res.data });
      })
      .catch((err) => {
        console.error(err);
      });
  },

  deleteDocument({ commit }, id) {
    DocumentProvider.deleteDocument(id)
      .then((res) => {
        commit('DELETE_DOCUMENT', { item: res.data });
      })
      .catch((err) => {
        console.error(err);
      });
  },

};

export default actions;
