/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PartProvider from '@/api/providers/part';
import WorkProvider from '@/api/providers/work';
import {
  Repair, RepairPart, RepairWork, RepairPayment, User, Work, Part,
} from '@/types';
import InfoAboutPartOrWork from '@/components/info-about-part-or-work';
import RepairProvider from '../../api/providers/repair';
import StorageProvider from '../../api/providers/storage';
import StuffProvider from '../../api/providers/stuff';
import ExpressAddProducts from '../express-add-products';
import ViewDate from '../forms/date';
import NumberPhone from '../forms/number-phone';
import Numbers from '../forms/numbers';
import ViewUserName from '../forms/user-name';
import ModalAddPart from '../modal-add-part';
import RepairPrice from '../repair-price';

interface ExpressForm {
  name;
  price;
  posting_price;
  amount;
  category_id;
  storage_id;
  seller_id;
  sell_price;
}

@Component({
  template: require('./workrepair.html'),
  components: {
    'repair-date': ViewDate,
    'modal-add-part': ModalAddPart,
    numbers: Numbers,
    'repair-price': RepairPrice,
    'user-name': ViewUserName,
    phone: NumberPhone,
    'express-add-products': ExpressAddProducts,
    InfoAboutPartOrWork,
  },
})
export default class RepairWorkrepair extends Vue {
  @Action addBreadcrumb;

  @Getter settings;

  @Action getVatList;

  @Action getRewardTypeList;

  @Action getWarrantTypeList;

  @Action getPartCategory;

  @Getter user;

  repair: Repair = {};

  repair_products: (RepairWork | RepairPart)[] = [];

  storage_list: object[] = [];

  loading_page = false;

  check_update_work: object = {};

  name_client = '';

  phone = '';

  status_style = '';

  parts: object[] = [];

  works: object[] = [];

  masters: User[] = [];

  new_product: Repair = {
    master_id: null,
  };

  status_class = '';

  status_content = '';

  open_modal_part = false;

  view_modal_edit_master = false;

  loading_save_master = false;

  view_modal_express_form = false;

  express_form: ExpressForm = {
    name: '',
    price: 0,
    posting_price: null,
    amount: 1,
    category_id: 1,
    storage_id: null,
    seller_id: null,
    sell_price: 0,
  };

  titleInfoAboutPartOrWork = '';

  btnloader = false;

  showModalViewAboutWorkOrPart = false;

  dataForModalView: Work | Part | null = null;

  get id() { return this.$route.params.id; }

  get rightsUserCanEditPartsWorksRepair() {
    return this.user.rights ? this.user.rights.can_edit_others_parts_works_repair : false;
  }

  get client_id() {
    if (this.repair.client) return this.repair.client.id;
    return null;
  }

  get getStatusStyle() { return this.status_style; }

  get getStatusClass() { return this.status_class; }

  get unvisibleBtnStatus() {
    if (this.repair.status_id) return this.repair.status_id !== 6 && this.repair.status_id !== 3;
    return false;
  }

  get comparisonMasters() {
    return this.repair.master_id !== this.user.id;
  }

  get statusWait() { return this.repair.status_id === 3; }

  // Рассчеты для блока 'Итогов'

  get listPayments(): RepairPayment[] {
    try {
      return this.repair.repair_payments
        ? this.repair.repair_payments
        : [];
    } catch {
      return [];
    }
  }

  get isFastInsertAllowed() {
    return this.settings.allow_part_auto_create || this.settings.allow_work_auto_create;
  }

  get totalProductsPrice() {
    let result = 0;
    if (Array.isArray(this.repair_products)) {
      result = this.repair_products.reduce((sum, part) => sum + (part.price * part.amount), 0);
    }
    return Math.round(result * 100) / 100;
  }

  get toPaySum() {
    let result = Number(this.totalPriceWithAdditionsAndDiscounts);

    if (Array.isArray(this.listPayments)) {
      this.listPayments.forEach((item) => {
        result -= item.sum;
      });
      return Math.round(result * 100) / 100;
    }
    return 0;
  }

  get totalPriceWithAdditionsAndDiscounts() {
    const vat = Number(this.totalProductsWithVat);
    const costWorks = Number(this.totalWorksWithVat);
    let discount = 0;
    let addition = 0;
    if (Array.isArray(this.repair.repair_discounts)) {
      discount = this.repair.repair_discounts
        // Добавил default: return 0;
        .reduce((sum, item) => {
          switch (item.type_id) {
            case 1: return sum + (vat * (Number(item.amount) / 100));
            case 2: return sum + Number(item.amount);
            case 3: return sum + (costWorks * (Number(item.amount) / 100));
            default: return 0;
          }
        }, 0);
    }

    if (Array.isArray(this.repair.repair_additions)) {
      addition = this.repair.repair_additions
        // Добавил default: return 0;
        .reduce((sum, item) => {
          switch (item.type_id) {
            case 1: return sum + (vat * (Number(item.amount) / 100));
            case 2: return sum + Number(item.amount);
            default: return 0;
          }
        }, 0);
    }
    const result = (vat - discount + addition);
    return Math.round(result * 100) / 100;
  }

  get totalProductsWithVat() {
    if (this.repair_products.length !== 0) {
      const price = this.repair_products.reduce((sum, part) => {
        const product = 'work' in part ? part.work.vat : part.part.vat;

        switch (product) {
          case 5: return sum + (part.price * 1.1 * part.amount);
          case 6: return sum + (part.price * 1.2 * part.amount);
          default: return sum + (part.price * part.amount);
        }
      }, 0);
      return Math.round(price * 100) / 100;
    }
    return 0;
  }

  get totalWorksWithVat() {
    if (this.repair_products.length !== 0) {
      const allWorks = this.repair_products.filter((item) => 'work' in item);
      // Конец рассчетов для блока 'Итогов'
      const resultSumWorks = allWorks.reduce((sum, works) => {
        switch ((works as RepairWork).work.vat) {
          case 5: return sum + (works.price * 1.1 * works.amount);
          case 6: return sum + (works.price * 1.2 * works.amount);
          default: return sum + (works.price * works.amount);
        }
      }, 0);

      return Math.round(resultSumWorks * 100) / 100;
    }
    return 0;
  }

  get totalProductsVat() {
    if (this.repair_products.length !== 0) {
      const result = this.repair_products.reduce((sum, part) => {
        const product = 'work' in part ? part.work.vat : part.part.vat;

        switch (product) {
          case 1: return sum + 0;
          case 2: return sum + 0;
          case 3: return sum + ((part.price * part.amount) / (1.1 * 0.1));
          case 4: return sum + ((part.price * part.amount) / (1.2 * 0.1));
          case 5: return sum + (part.price * 0.1 * part.amount);
          case 6: return sum + (part.price * 0.2 * part.amount);
          default: return sum + 0;
        }
      }, 0);
      return Math.round(result * 100) / 100;
    }
    return 0;
  }

  get userCanSeeClients() {
    try {
      if (this.user.rights) {
        return this.user.rights.can_see_clients;
      }
      return false;
    } catch {
      return false;
    }
  }

  // Конец рассчетов для блока 'Итогов'
  // api

  // Список хранилища
  getStorageList() {
    StorageProvider.getStorageList(1)
      .then((res) => {
        this.storage_list = res.data;
      }).catch((err) => console.error(err));
  }

  // получение заказа
  getViewRepair(id) {
    this.loading_page = true;

    RepairProvider.getViewRepair(id)
      .then((res) => {
        this.repair = res.data;

        if (this.repair.status_id !== 2) {
          this.$message.error('Работать над заказом можно только с заказами в статусе "В работе".');
          this.$router.push({ name: 'repair-view', params: { id: this.repair.id } });
        }

        if (this.repair.repair_parts
          && this.repair.repair_works
          && this.repair.repair_additions
          && this.repair.repair_discounts) {
          this.repair.repair_parts.forEach((element) => this.repair_products.push(element));
          this.repair.repair_works.forEach((element) => this.repair_products.push(element));

          this.client(this.repair);
          this.phoneClient(this.repair);
          this.statusClient(this.repair);
          this.breadcrumb(this.repair.title);

          if (this.repair.repair_additions.length !== 0
            || this.repair.repair_discounts.length !== 0) {
            this.repair.repair_additions.forEach((_item, i) => {
              if (this.repair.repair_additions) {
                this.repair.repair_additions[i].type = 'addition';
              }
            });
            this.repair.repair_discounts.forEach((_item, i) => {
              if (this.repair.repair_discounts) {
                this.repair.repair_discounts[i].type = 'discount';
              }
            });
          }
        }

        this.loading_page = false;
      }).catch((err) => {
        console.error(err);
        this.loading_page = false;
      });
  }

  // обновление заказа
  updateRepair(item) {
    this.loading_page = true;

    RepairProvider.sendRepairUpdate(item)
      .then((res) => {
        this.repair = res.data;
        this.loading_page = false;
        this.$store.commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} изменён !`, item: '' });
      }).catch((err) => {
        console.error(err);
        this.loading_page = false;
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Заказ не обновлен', item: '' });
      });
  }

  // удаление товаров или услуг в заказе
  deleteRepairProducts(id, type) {
    if (type === 'part') {
      RepairProvider.deleteRepairPart(id)
        .then((res) => {
          this.removeProduct(res.data.id);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар успешно удалён', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка! Товар не удалён', item: '' });
        });
    } else {
      RepairProvider.deleteRepairWork(id)
        .then((res) => {
          this.removeProduct(res.data.id);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Услуга успешно удалёна', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка! Услуга не удалёна', item: '' });
        });
    }
  }

  removeProduct(id) {
    const index = this.repair_products.findIndex((i) => i.id === id);
    this.repair_products.splice(index, 1);
  }

  // изменение товаров или услуг
  editRepairProducts(item, type) {
    if (type === 'part') {
      RepairProvider.sendRepairPartUpdate(item)
        .then((res) => {
          this.editProduct(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар успешно обновлен', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка обновления товара', item: '' });
        });
    } else {
      RepairProvider.sendRepairWorkUpdate(item)
        .then((res) => {
          this.check_update_work = res.data;
          this.editProduct(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Услуга успешно обновлена', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка обновления услуги', item: '' });
        });
    }
  }

  editProduct(item) {
    const index = this.repair_products.findIndex((i) => i.id === item.id);
    this.repair_products.splice(index, 1, item);
  }

  // получение инженеров
  getStuffList() {
    StuffProvider.getFullStuffList()
      .then((res) => {
        this.masters = [];

        (res as User[]).forEach((master) => {
          if (master.rights) {
            if (master.rights.can_work_on_repair) this.masters.push(master);
          }
        });

        this.masters = this.masters.sort((a, b) => {
          const nameA = (this.formattingStuff(a) as string).toLowerCase();
          const nameB = (this.formattingStuff(b) as string).toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      }).catch((err) => {
        console.log(err.response.data);
      });
  }

  // Отправка на соглосование
  sendRepairToAgreement(id) {
    RepairProvider.sendRepairToAgreement(id)
      .then((res) => {
        this.repair = res.data;
        this.$router.push({ name: 'repair-view', params: { id: this.id } });
        this.$store.commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} отправлен на соглосование`, item: '' });
      }).catch((err) => {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  }

  sendRepairDoneDropDownHandler(command) {
    this.sendRepairDone(command.id, command.notify);
  }

  // Переведен в статус Готово
  sendRepairDone(id: number, notify = 1) {
    RepairProvider.sendRepairDone(id, notify)
      .then((res) => {
        this.$router.push({ name: 'repair-view', params: { id: this.id } });
        this.$store.commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} переведён в статус "Готово"`, item: '' });
      }).catch((err) => {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  }

  // Отправка в ожидания запчастей
  sendRepairDelay(id) {
    RepairProvider.sendRepairDelay(id)
      .then((res) => {
        this.$router.push({ name: 'repair-view', params: { id: this.id } });
        this.$store.commit('PUSH_CALL_SUCCESS', { title: `Заказ ${res.data.title} ожидает запчасти`, item: '' });
      }).catch((err) => {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  }

  breadcrumb(title) {
    this.addBreadcrumb([
      { id: 1, section: 'Ремонт', link: '/repair' },
      { id: 3, section: title, link: `/repair/view/${this.id}` },
      { id: 4, section: 'Работа над заказом', link: null },
    ]);
    document.title = `${title} - Работа над заказом`;
  }

  mounted() {
    this.getViewRepair(this.id);
    this.getStuffList();
    this.getStorageList();
    this.addNewRepairPart();
    this.addNewRepairWork();
    this.delRepairPartList();

    this.getVatList();
    this.getRewardTypeList();
    this.getWarrantTypeList();
  }

  destroyed() {
    this.$root.$off('addInNewPartList');
    this.$root.$off('addInNewWorkList');
    this.$root.$off('delInNewPartList');
  }

  viewingWork(row) {
    try {
      this.btnloader = true;
      WorkProvider.getViewWork(row.work.id)
        .then((res) => {
          this.titleInfoAboutPartOrWork = 'Информация об услуге';
          this.dataForModalView = res.data;
          this.btnloader = false;

          this.getVatList();
          this.getRewardTypeList();
          this.getWarrantTypeList();
          this.getPartCategory('work');

          this.showModalViewAboutWorkOrPart = true;
        })
        .catch((error) => {
          this.btnloader = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные об услуге', item: '' });
          throw new Error(error);
        });
    } catch (err) {
      this.btnloader = false;
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      throw new Error(err);
    }
  }

  viewingPart(row) {
    try {
      this.btnloader = true;
      PartProvider.getViewPart(row.part.id)
        .then((res) => {
          this.titleInfoAboutPartOrWork = 'Информация о товаре';
          this.dataForModalView = res.data;
          this.btnloader = false;
          this.getVatList();
          this.getRewardTypeList();
          this.getWarrantTypeList();
          this.getPartCategory('part');

          this.showModalViewAboutWorkOrPart = true;
        })
        .catch((error) => {
          this.btnloader = false;
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Не удалось загрузить данные о товаре', item: '' });
          throw new Error(error);
        });
    } catch (err) {
      this.btnloader = false;
      this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка', item: '' });
      throw new Error(err);
    }
  }

  closeInfoAboutPartOrWork() {
    this.showModalViewAboutWorkOrPart = false;
  }

  // Снять мастера с заказа
  sendRepairDismissJob(id) {
    RepairProvider.sendRepairDismissJob(id)
      .then(() => {
        this.$router.push({ name: 'repair-view', params: { id: this.id } });
        this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Мастер снят с заказа', item: '' });
      }).catch((err) => {
        console.error(err);
        this.$store.commit('PUSH_CALL_ERROR', { title: 'Произошла ошибка !!', item: '' });
      });
  }

  // end api

  removeSpareWorks(row) {
    if (!this.rightsUserCanEditPartsWorksRepair && this.comparisonMasters) {
      this.methodDisabledButtons();
    } else if ('part' in row) {
      this.deleteRepairProducts(row.id, 'part');
    } else {
      this.deleteRepairProducts(row.id, 'work');
    }
  }

  clearExpressForm() {
    this.express_form.name = '';
    this.express_form.price = 0;
    this.express_form.sell_price = 0;
    this.express_form.posting_price = null;
    this.express_form.amount = 1;
    this.express_form.category_id = 1;
  }

  client(item) {
    this.name_client = item.client;
  }

  amounts(part, storage) {
    if (this.settings?.allow_negative_storage_amount) {
      return Infinity;
    }
    if (part.part) {
      const { amounts } = part.part;
      const amounts_filters = amounts.filter((amount) => amount.storage_id === storage)[0];
      if (part.maxAmount) {
        return part.maxAmount;
      }
      part.maxAmount = amounts_filters ? part.amount + amounts_filters.amount : 0;
      return part.maxAmount;
    }
    if (part.work) {
      return Infinity;
    }
    return 0;
  }

  phoneClient(item) {
    this.phone = item.client.phone === null ? item.client.contact_phone : item.client.phone;
  }

  changeItem(row) {
    if (row.price < 0) {
      row.price = Math.abs(row.price);
    }
    if (row.amount < 0) {
      row.amount = 1;
    }
    const item = row;

    if ('part' in item) {
      const {
        price, master_id, id, repair_id,
      } = item;

      let { amount } = item;
      if (amount > row.maxAmount) {
        amount = row.maxAmount;
      }

      this.editRepairProducts({
        price, amount, master_id, id, repair_id,
      }, 'part');
    } else {
      this.editRepairProducts(item, 'work');
    }
  }

  changeMasters(id) {
    const master = this.masters.find((item) => item.id === id);
    this.new_product.master = master;
  }

  formattingStuff = (stuff: User) => {
    if (stuff) {
      const lastName: string = stuff.last_name ? stuff.last_name : '';
      const firstName: string = stuff.first_name ? `${stuff.first_name}.` : '';
      const middleName: string = stuff.middle_name ? `${stuff.middle_name}.` : '';
      return `${lastName} ${firstName} ${middleName}`;
    }
    return '-';
  }

  methodDisabledButtons() {
    this.$notify.error({
      title: 'У вас нет права редактировать запчасти других инженеров',
      message: '',
      position: 'bottom-right',
    });
  }

  statusClient(item) {
    switch (item.client.status) {
      case 1:
        this.status_style = 'color: #1861da';
        this.status_content = 'Рядовой клиент';
        break;
      case 2:
        this.status_style = 'color: #3ba543';
        this.status_content = 'Ваш любимый клиент';
        break;
      default:
        this.status_style = 'color: #000';
        this.status_content = 'Клиент в чёрном списке';
        break;
    }

    this.status_class = item.client.status === 3 ? 'fa fa-user-times' : 'fa fa-user';
  }

  handleSaveRepair() {
    const { repair, id } = this;
    const newRepair = {
      id,
      warrant_size: repair.warrant_size,
      master_notes: repair.master_notes,
    };
    this.updateRepair(newRepair);
  }

  handleOpenModalEditMaster(row) {
    if (!this.rightsUserCanEditPartsWorksRepair && this.comparisonMasters) {
      this.methodDisabledButtons();
    } else {
      this.view_modal_edit_master = true;
      this.new_product = row;
    }
  }

  handleSaveNewMaster() {
    this.loading_save_master = true;
    if ('part_id' in this.new_product) {
      this.editRepairProducts(this.new_product, 'part');
    } else {
      this.editRepairProducts(this.new_product, 'work');
    }
  }

  addNewRepairPart() {
    this.$root.$on('addInNewPartList', (part) => {
      const parts = {
        ...part,
        master_id: this.repair.master_id,
        repair_id: Number(this.id),
      };

      RepairProvider.sendRepairPart(parts)
        .then((res) => {
          this.repair_products.push(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Товар успешно добавлен', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка добавления товара', item: '' });
        });
    });
  }

  addNewRepairWork() {
    this.$root.$on('addInNewWorkList', (work) => {
      const works = {
        ...work,
        master_id: this.repair.master_id,
        repair_id: Number(this.id),
      };

      RepairProvider.sendRepairWork(works)
        .then((res) => {
          this.repair_products.push(res.data);
          this.$store.commit('PUSH_CALL_SUCCESS', { title: 'Услуга успешно добавлена', item: '' });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('PUSH_CALL_ERROR', { title: 'Ошибка добавления услуги', item: '' });
        });
    });
  }

  delRepairPartList() {
    this.$root.$on('delInNewPartList', ({ item }) => {
      if ('part_id' in item) {
        const part = this.repair_products.find((element) => element.part_id === item.part_id);
        this.deleteRepairProducts((part as RepairWork).id, 'part');
      } else {
        const work = this.repair_products.find(
          (element) => (element as RepairWork).work_id === item.work_id,
        );
        this.deleteRepairProducts((work as RepairWork).id, 'work');
      }
    });
  }

  @Watch('repair_products')
  repairPartsAndWorks(item) {
    this.parts = item.filter((element) => {
      const amounts = this.amounts(element, element.storage_id);
      /* eslint-disable-next-line no-param-reassign */
      element.remain = amounts;
      return 'part' in element;
    });
    this.works = item.filter((element) => 'work' in element);
    this.view_modal_edit_master = false;
    this.loading_save_master = false;
  }

  @Watch('check_update_work')
  newWork() {
    this.view_modal_edit_master = false;
    this.loading_save_master = false;
  }

  @Watch('user', { immediate: true })
  onLoadUser(val: User) {
    if (Object.keys(val).length !== 0) {
      this.express_form.seller_id = val.id;
    }
  }
}
