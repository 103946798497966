import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  template: require('./iphone.html'),
})
export default class Iphone extends Vue {
    @Prop() msg!: object[];

    @Prop() sender!: string;
}
