import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ClientsProvider, { ClientUniversalSearch } from '@/api/providers/clients';
import { User } from '@/types';
import UserInputAutocomplateSuggest from '../user-input-autocomplete-suggest';

@Component({
  template: require('./index.html'),
  components: {
    UserInputAutocomplateSuggest,
  },
})

export default class UserInputAutocomplete extends Vue {
  queryStringInput = ''

  suggestedUsers: User[] = []

  isRespondeEmpty = false;

  /**
   * отправляет запрос на поиск клиента
   */
  requestOfSuggest() {
    this.isRespondeEmpty = false;
    if (this.queryStringInput === '') {
      this.suggestedUsers = [];
      return;
    }
    const stringForRestCondition = this.queryStringInput;
    const form: ClientUniversalSearch = {
      query: this.queryStringInput,
    };

    ClientsProvider.universalSearch(1, form)
      .then((res) => {
        if (stringForRestCondition === this.queryStringInput) {
          this.suggestedUsers = res.data.slice(0, 5);
          if (this.suggestedUsers.length === 0) {
            this.isRespondeEmpty = true;
          }
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  /**
   * эмитит выбранного клиента
   * @param  {User} choosedUser
   */
  chooseUser(choosedUser: User) {
    this.suggestedUsers = [];
    this.queryStringInput = '';
    this.$emit('chooseClient', choosedUser);
  }
}
