import { Form } from 'element-ui';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Breadcrumb } from '@/types';

interface FormType {
  current: string;
  new: string;
  checkPass: string;
}

@Component({
  template: require('./index.html'),
  components: {
  },
})
export default class PasswordChange extends Vue {
  @Action addBreadcrumb;

  @Action sendUserUpdatePassword;

  @Action signOut;

  @Getter btnloader;

  @Getter validNewPassword;

  breadcrumb: Breadcrumb[] = [{ id: 1, section: 'Пользователь', link: null }, { id: 2, section: 'Смена пароля', link: null }];

  form: FormType = {
    current: '',
    new: '',
    checkPass: '',
  };

  validate = (obj: object, key: string) => {
    if (Object.keys(obj).some((v) => v === key)) {
      return obj[key][0];
    }
    return null;
  }

  validatePass = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('Необходимо заполнить «Старый пароль».'));
    } else {
      callback();
    }
  }

  validateNewPass = (rule, value, callback) => {
    const password = value.replace(/[^0-9]/g, '');

    if (value === '') {
      callback(new Error('Необходимо заполнить «Новый пароль».'));
    } else if (value.length >= 6) {
      if (password.length > 0) {
        callback();
      } else {
        callback(new Error('Введите как минимум 1 цифру в поле «Новый пароль».'));
      }
    } else {
      callback(new Error('Значение «Новый пароль» должно содержать минимум 6 символа.'));
    }
  }

  validateCheckPass(rule, value, callback) {
    if (value === '') {
      callback(new Error('Необходимо заполнить «Новый пароль (еще раз)».'));
    } else if (value.length >= 6) {
      if (value !== this.form.new) {
        callback(new Error('Новый пароль повторен не правильно.'));
      } else {
        callback();
      }
    } else {
      callback(new Error('Значение «Новый пароль (еще раз)» должно содержать минимум 6 символа.'));
    }
  }

  rules: object = {
    current: [{ validator: this.validatePass, trigger: 'blur' }],
    new: [{ validator: this.validateNewPass, trigger: 'blur' }],
    checkPass: [{ validator: this.validateCheckPass, trigger: 'blur' }],
  };

  mounted() {
    this.addBreadcrumb(this.breadcrumb);
  }

  saveChangePass(formName) {
    (this.$refs[formName] as Form).validate(
      (valid) => (valid ? this.sendUserUpdatePassword(
        {
          current: this.form.current,
          new: this.form.new,
        },
      ) : false),
    );
  }

  resetForm() {
    (this.$refs.formChangePass as Form).resetFields();
  }

  @Watch('validNewPassword')
  onChangeValid(val: object) {
    if (!Object.keys(val).length) {
      this.signOut(false);
      this.$router.push('/auth');
    }
  }
}
