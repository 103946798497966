import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Bug as BugInterface } from '@/types';
import Fault from '../__fault';

@Component({
  template: require('./bug.html'),
  components: {
    fault: Fault,
  },
})
class Bug extends Vue {
    newfault = { label: null };

    bug: BugInterface[] = [];

    getBugList(item: BugInterface[]) {
      this.bug = item;
    }

    sendBug(item) {
      this.bug.push(item);
    }

    changeBug(item: BugInterface) {
      const index = this.bug.findIndex((i) => i.id === item.id);
      this.bug[index].label = item.label;
    }

    deleteBug(item: BugInterface) {
      const index = this.bug.indexOf(item);
      this.bug.splice(index, 1);
    }

    mounted() {
      this.$root.$on('addItemBug', (item) => {
        this.getBugList(item);
        this.newfault = { label: null };
      });
      this.$root.$on('editItemBug', (item) => {
        this.changeBug(item);
      });
      this.$root.$on('delItemBug', (item) => {
        this.deleteBug(item);
      });
      document.title = 'Неисправность';
    }
}

export default Bug;
