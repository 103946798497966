import { MutationTree } from 'vuex';
import {
  CANCEL_REVERT_PART,
  CANCEL_VALIDATION,

  CLEAR_CANCEL_ITEM, DELETE_CANCEL_ITEM, GET_CANCEL,
  GET_VIEW_CANCEL,

  REVERT_CANCEL, SEND_CANCEL, SEND_CANCEL_ITEM,

  StateCancel,
} from './types';

const mutations: MutationTree<StateCancel> = {
  [GET_CANCEL](state, { item, pageCurrent, pageCount }) {
    state.cancel = item;
    state.cancelPageCurrent = pageCurrent;
    state.cancelPageCount = pageCount;
  },

  [GET_VIEW_CANCEL](state, { item }) {
    state.cancelView = item;
    state.cancelNewItemList = item.parts === null ? [] : item.parts;
  },

  [SEND_CANCEL_ITEM](state, { item }) {
    state.cancelNewItemList.push(item);
  },

  [SEND_CANCEL](state, { item }) {
    state.applyCancel = item;
  },

  [DELETE_CANCEL_ITEM](state, item) {
    const index = state.cancelNewItemList.findIndex((i) => i.id === item.id);
    state.cancelNewItemList.splice(index, 1);
  },

  [CANCEL_REVERT_PART](state, { item }) {
    const index = state.cancelNewItemList.findIndex((i) => i.id === item.id);
    state.cancelNewItemList.splice(index, 1);
  },

  [CLEAR_CANCEL_ITEM](state) {
    state.cancelNewItemList = [];
  },

  [REVERT_CANCEL](state, { item }) {
    state.revertCancel = item;
  },

  [CANCEL_VALIDATION](state, item) {
    state.cancelValid = item;
  },
};

// {"cancel_part_id":29,"amount":1}
export default mutations;
